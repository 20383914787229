import { ComponentType } from 'react';
import PhoneConnection, {
  PhoneConnectionType,
} from '~/components/Blocks/Templates/Pab2c/ContractState/Components/PhoneConnection';
import WaitingConnection from '~/components/Blocks/Templates/Pab2c/ContractState/Components/WaitingConnection';
import AppointedConnection, {
  AppointedConnectionProps,
} from '~/components/Blocks/Templates/Pab2c/ContractState/Components/AppointedConnection';
import PrepaymentConnection, {
  PrepaymentConnectionProps,
} from '~/components/Blocks/Templates/Pab2c/ContractState/Components/PrepaymentConnection';
import Serviced from '~/components/Blocks/Templates/Pab2c/ContractState/Components/Serviced';
import Suspended from '~/components/Blocks/Templates/Pab2c/ContractState/Components/Suspended';
import Debt from '~/components/Blocks/Templates/Pab2c/ContractState/Components/Debt';
import Stray from '~/components/Blocks/Templates/Pab2c/ContractState/Components/Stray';
import CloseToStray from './Components/CloseToStray';
import { ZombieInfoProps } from '../Subscriptions/interfaces';

export enum PHONE_TEXT {
  CONNECTION = 'обращайтесь по\u00A0вопросам подключения',
  SUSPENDED = 'обращайтесь за\u00A0дополнительной информацией',
  APPOINTED = 'обращайтесь, если ваши планы поменяются',
}

export enum CONTRACT_STATE {
  // Ожидание аванса
  WAITING_PAYMENT,
  // Недостаточно аванса
  NOT_ENOUGH_PAYMENT,
  // Ожидание подключения
  WAITING_CONNECTION,
  // Назначена дата подключения
  APPOINTED_CONNECTION,
  // Подключение приостановлено
  SUSPENDED_CONNECTION,
  // Обслуживается
  SERVICED,
  // Запланирована приостановка
  SUSPENDED_PLANNED,
  // Каникулы
  VACATION,
  // Запланированы каникулы
  VACATION_PLANNED,
  // Приостановлено клиентом
  DIVING,
  // Приостановлено оператором
  DEBT,
  // Близок к зомби
  CLOSE_TO_STRAY,
  // Зомби
  STRAY,
  // Расторгнут
  DREGS,
}

type ConfigType = {
  [key in CONTRACT_STATE]: {
    headerText: key extends CONTRACT_STATE.SUSPENDED_PLANNED
      ? (date: string) => string
      : string;
    backgroundUrl: string;
    backgroundUrlMobile?: string;
    component: ComponentType<
      | PrepaymentConnectionProps
      | PhoneConnectionType
      | AppointedConnectionProps
      | ZombieInfoProps
    >;
  };
};

const CONFIG: ConfigType = {
  [CONTRACT_STATE.WAITING_PAYMENT]: {
    headerText: 'Подключение\nне\u00A0завершено',
    backgroundUrl: '/static/images/contractState/connectionNotCompleted.png',
    backgroundUrlMobile:
      '/static/images/contractState/СonnectionNotCompletedMobile.png',
    component: PrepaymentConnection,
  },
  [CONTRACT_STATE.NOT_ENOUGH_PAYMENT]: {
    headerText: 'Подключение\nне\u00A0завершено',
    backgroundUrl: '/static/images/contractState/connectionNotCompleted.png',
    backgroundUrlMobile:
      '/static/images/contractState/СonnectionNotCompletedMobile.png',
    component: PrepaymentConnection,
  },
  [CONTRACT_STATE.WAITING_CONNECTION]: {
    headerText: 'Подключение\nне\u00A0завершено,\nдоговор заключён',
    backgroundUrl: '/static/images/contractState/connectionNotCompleted.png',
    backgroundUrlMobile:
      '/static/images/contractState/СonnectionNotCompletedMobile.png',
    component: WaitingConnection,
  },
  [CONTRACT_STATE.APPOINTED_CONNECTION]: {
    headerText: 'Подключение\nне\u00A0завершено',
    backgroundUrl: '/static/images/contractState/connectionNotCompleted.png',
    component: AppointedConnection,
  },
  [CONTRACT_STATE.SUSPENDED_CONNECTION]: {
    headerText: 'Процесс подключения\nприостановлен',
    backgroundUrl: '/static/images/contractState/connectionNotCompleted.png',
    component: PhoneConnection,
  },
  [CONTRACT_STATE.SERVICED]: {
    headerText: 'Договор обслуживается',
    backgroundUrl: '/static/images/contractState/serviced.png',
    component: Serviced,
  },
  [CONTRACT_STATE.SUSPENDED_PLANNED]: {
    headerText: (date) => `Приостановка обслуживания\nс ${date}`,
    backgroundUrl: '/static/images/contractState/serviced.png',
    component: null,
  },
  [CONTRACT_STATE.VACATION]: {
    headerText: 'Подключена услуга\nКаникулы',
    backgroundUrl: '/static/images/contractState/vacation.png',
    component: Suspended,
  },
  [CONTRACT_STATE.VACATION_PLANNED]: {
    headerText: 'Заказана услуга\nКаникулы',
    backgroundUrl: '/static/images/contractState/vacation.png',
    component: Suspended,
  },
  [CONTRACT_STATE.DIVING]: {
    headerText: 'Обслуживание\nприостановлено\nклиентом',
    backgroundUrl: '/static/images/contractState/diving.png',
    component: Suspended,
  },
  [CONTRACT_STATE.DEBT]: {
    headerText: 'Обслуживание\nприостановлено\nоператором',
    backgroundUrl: '/static/images/contractState/debt.png',
    component: Debt,
  },
  [CONTRACT_STATE.CLOSE_TO_STRAY]: {
    headerText: 'Обслуживание\nприостановлено\nоператором',
    backgroundUrl: '/static/images/contractState/debt.png',
    component: CloseToStray,
  },
  [CONTRACT_STATE.STRAY]: {
    headerText: 'Договор\nне\u00A0обслуживается',
    backgroundUrl: '/static/images/contractState/stray.png',
    component: Stray,
  },
  [CONTRACT_STATE.DREGS]: {
    headerText: 'Договор\nне\u00A0обслуживается',
    backgroundUrl: '/static/images/contractState/stray.png',
    component: null,
  },
};

export default CONFIG;
