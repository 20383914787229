/** libraries */
import styled from '@emotion/styled';

/** constants */
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

interface StyledMenuPopupProps extends React.HTMLProps<HTMLDivElement> {
  open?: boolean;
}

/** стилизованный блок popup с мобильным меню */
export const StyledMenuPopupMobile = styled.div<StyledMenuPopupProps>`
  ${({ open }): string => `
    height: 100%;
    overflow-x: auto;
    position: fixed;
    display: ${open ? 'flex' : 'none'};
    justify-content: center;
    left: 0;
    width: 100%;
    padding: 13px 23px 37px 32px;
    background: ${defaultTheme.colors.white};
    z-index: 101;

    .menu-popup-container {
      width: 100%;
      max-width: ${desktop940}px;
    }

    .menu-line-mobile {
      height: 1px;
      margin-left: -32px;
      margin-right: -23px;
      background: ${defaultTheme.colors.disable};
    }
  `}
`;

export const StyledPopupHeaderMobile = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

/** стилизованный блок с контентом */
export const StyledPopupContent = styled.div`
  position: relative;
  display: flex;
`;

/** стилизованный блок-колонка мобильного меню */
export const StyledContentColumnMobile = styled.div`
  width: 100%;
  flex: 1 1 auto;
  margin-bottom: 23px;

  .column-title-mobile {
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      & button:last-child {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      & button:first-of-type {
        margin-right: 14px;
      }
    }
  }

  ul {
    list-style-type: none;
    li + li {
      margin-top: 16px;
    }
  }

  .column-links-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.column-title {
      padding: 22px 0 20px;
      cursor: pointer;
      outline: none;
    }
  }

  .column-title-buttons {
    margin-top: 30px;

    // TODO: разобраться с отступами в кнопках в ДС.
    button {
      padding: 12px 15px;
      & svg {
        stroke: ${defaultTheme.colors.planeta};
        fill: none;
      }
    }
  }

  .column-block {
    margin-top: 32px;
  }
`;

/** цвет логотипа для мобильного меню */
export const StyledMobileLogo = styled.img`
  cursor: pointer;
`;
