/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  SidePage,
  Icon,
  Icons,
  H2,
  H3,
  Text,
  Input,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledAllowAccess } from './styles';
/** constants */
import { DEFAULT_ERROR } from '../../constants';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import AllowAccessWithoutPasswordFooter from './AllowAccessWithoutPasswordFooter';
/** stores */
import useSettingsStore from '../../store/useSettingsStore';

const AllowAccessWithoutPasswordSidePage: FC = () => {
  const {
    allowAccessWithoutPasswordStore: {
      isShowAllowAccessWizard,
      isLoginWithoutPassword,
      resetState,
      password,
      setPassword,
      passwordError,
      setPasswordError,
      result,
      headerText,
    },
  } = useSettingsStore();

  /** Изменение input */
  const onChangePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
  };
  /** Событие фокуса на текущем пароле */
  const onFocusPassword = () => {
    setPasswordError('');
  };

  // Вычисление ширины экрана
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <SidePage
      show={isShowAllowAccessWizard}
      headerText={headerText}
      onCloseClick={resetState}
      footerContainer={!result.isResult && <AllowAccessWithoutPasswordFooter />}
      isOnlyMobileFooter
      removeScrollBar
    >
      {result.isResult && result.isCorrect && (
        <StyledAllowAccess>
          <div className="result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? (
            <H2>
              Доступ без пароля{' '}
              {isLoginWithoutPassword ? 'разрешён' : 'запрещён'}
            </H2>
          ) : (
            <H3>
              Доступ без пароля{' '}
              {isLoginWithoutPassword ? 'разрешён' : 'запрещён'}
            </H3>
          )}
        </StyledAllowAccess>
      )}

      {result.isResult && !result.isCorrect && (
        <StyledAllowAccess>
          <div className="result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </StyledAllowAccess>
      )}

      {!result.isResult && (
        <StyledAllowAccess>
          {isLoginWithoutPassword ? (
            <>
              <div className="allow-access__info">
                <Text lineHeight="24px" fontWeightBold>
                  Вход без пароля доступен только из&nbsp;дома.
                </Text>
                <Text lineHeight="24px">
                  Для авторизации используется ip-адрес вашего договора. Вам
                  будет доступна информация о&nbsp;состоянии договора, просмотр
                  статистики и&nbsp;пополнение Счета при помощи банковской
                  карты.
                </Text>
              </div>
              <div className="allow-access__warning">
                <Text lineHeight="24px">
                  Для любых изменений состава услуг, смены продукта потребуется
                  подтверждение при помощи ввода пин-кода или пароля.
                </Text>
              </div>
            </>
          ) : (
            <>
              <div className="allow-access__info ban">
                <Text lineHeight="24px" fontWeightBold>
                  Всегда требовать ввод пароля для входа в&nbsp;раздел «Моя
                  Планета».
                </Text>
                <Text lineHeight="24px">
                  В&nbsp;том числе и&nbsp;при подключении из&nbsp;дома.
                </Text>
              </div>
            </>
          )}
          <div className="allow-access__input">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Пароль раздела «Моя Планета»
            </Text>
            <Input
              className="allow-access__input__inlet"
              type="password"
              placeholder="Введите пароль"
              value={password}
              error={passwordError}
              onChange={onChangePassword}
              onFocus={onFocusPassword}
            />
            {!passwordError && (
              <Text
                className="allow-access__input__info"
                color={defaultTheme.colors.shadow}
                lineHeight="24px"
              >
                Укажите для подтверждения
              </Text>
            )}
          </div>
        </StyledAllowAccess>
      )}
    </SidePage>
  );
};

export default observer(AllowAccessWithoutPasswordSidePage);
