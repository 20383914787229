/** библиотеки */
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { differenceInHours, format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react';

/** Компоненты библиотеки */
import {
  Icon,
  Icons,
  LeadingText,
  Loader,
  Text,
  Link,
} from 'cordis-core-ui-planeta';

/** утилиты */
import { pluralizeAll } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** Стили */
import {
  OperatingStateWizardContainer,
  StyledOperatingStateError,
} from './OperatingStateWizard.style';

/** Интерфейсы */
import { OperatingStateWizardProps } from './OperatingStateWizard.types';

/** constants */
import { DAYS } from '~/components/Blocks/Templates/Pab2c/Contract/constants';
import { VIEW_TYPES } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/constants';
import { PLANETA_URL } from '~/constants/common';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Форма смены состояния договора.
 */
const OperatingStateWizard: FC<OperatingStateWizardProps> = ({
  viewType,
  error,
  agreement,
  errorSuspendService,
}: OperatingStateWizardProps) => {
  const {
    contractStateStore: { suspendCondition },
  } = useRootStore();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [diffDays, setDiffDays] = useState<number>(0);

  useEffect(() => {
    setIsDataLoading(false);
    setDiffDays(diffInDays(suspendCondition?.availableDt));
  }, []);

  const diffInDays = (date: string): number => {
    const now = new Date();
    const end = new Date(date);

    return differenceInHours(end, now) / 24;
  };

  /** дата */
  const parseDate = (date: string, formatDate = 'd MMMM в HH:mm') => {
    return format(parseISO(date), formatDate, {
      locale: ru,
    });
  };

  /** Приостановка обслуживания недоступна */
  if (errorSuspendService) {
    return (
      <StyledOperatingStateError>
        <Icon icon={<Icons.NotOkBigIcon />} />
        <Text lineHeight="24px" fontWeightBold>
          {errorSuspendService}
        </Text>
      </StyledOperatingStateError>
    );
  }

  return (
    <>
      <OperatingStateWizardContainer>
        {isDataLoading && <Loader />}
        {!isDataLoading && (
          <form className="wizard">
            <div className="wizard__wrapper">
              <div className="wizard__content">
                {(viewType === VIEW_TYPES.RESUME_SUCCESS ||
                  viewType === VIEW_TYPES.PAUSE_SUCCESS) && (
                  <div className="wizard__content-success">
                    <div className="success-icon">
                      <Icon icon={<Icons.SuccessIconAnimated />} />
                    </div>

                    {viewType === VIEW_TYPES.PAUSE_SUCCESS && (
                      <h2>Запланирована приостановка обслуживания</h2>
                    )}

                    {viewType === VIEW_TYPES.RESUME_SUCCESS && (
                      <h2>Обслуживание возобновлено!</h2>
                    )}
                  </div>
                )}

                {(viewType === VIEW_TYPES.RESUME_ERROR ||
                  viewType === VIEW_TYPES.PAUSE_ERROR) && (
                  <div className="wizard__content-error">
                    <div className="error-icon">
                      <Icon icon={<Icons.NotOkBigIcon />} />
                    </div>

                    {viewType === VIEW_TYPES.PAUSE_ERROR && (
                      <h2>Обслуживание не приостановлено</h2>
                    )}

                    {viewType === VIEW_TYPES.RESUME_ERROR && (
                      <h2>Обслуживание не возобновлено!</h2>
                    )}
                    <p>{error}</p>
                  </div>
                )}

                {viewType === VIEW_TYPES.RESUME && (
                  <>
                    <p>
                      Предоставление Услуг будет восстановлено в&nbsp;течение
                      60&nbsp;минут.
                    </p>
                    <div className="warning">
                      Перерасчёт ежемесячной платы за&nbsp;использование
                      Продукта будет выполнен в&nbsp;соответствии с&nbsp;
                      <Link
                        href={`${PLANETA_URL}/static/upload-files/documents/topicals/ktc.dogovor.pdf`}
                        target="_blank"
                      >
                        Договором.
                      </Link>
                    </div>
                  </>
                )}
                {viewType === VIEW_TYPES.PAUSE && (
                  <>
                    <p>Приостановка обслуживания произойдёт</p>
                    <LeadingText className="wizard__content-date">
                      {parseDate(suspendCondition?.availableDt)}
                    </LeadingText>
                  </>
                )}
                {viewType === VIEW_TYPES.PAUSE && (
                  <div className="warning">
                    {diffDays > 1 && (
                      <p>
                        С&nbsp;начала обслуживания или возобновления
                        обслуживания не&nbsp;прошло 30&nbsp;дней. Услуга может
                        быть предоставлена через&nbsp;
                        {pluralizeAll(Math.ceil(diffDays), DAYS)}.
                      </p>
                    )}
                    <p>
                      Возобновить обслуживание вы&nbsp;сможете в&nbsp;разделе
                      «Моя Планета» после начала действия Услуги, предоставление
                      Услуг будет восстановлено в&nbsp;течение 60&nbsp;минут.
                    </p>
                    <p>
                      Временная приостановка не&nbsp;означает прекращение
                      договорных отношений между Оператором и&nbsp;Клиентом. Для
                      закрытия контракта вам необходимо обратиться в&nbsp;любой
                      из&nbsp;
                      <Link href="/contacts" target="_blank">
                        офисов
                      </Link>
                      &nbsp;Планеты.
                    </p>
                  </div>
                )}
                {viewType === VIEW_TYPES.PAUSE_SUCCESS && (
                  <div className="warning">
                    <p>
                      Функция возобновления обслуживания будет доступна
                      в&nbsp;разделе «Моя Планета». Следующий заказ Услуги будет
                      возможен не&nbsp;ранее, чем через 30&nbsp;дней
                      с&nbsp;момента завершения приостановки по данной заявке.
                      Списания за&nbsp;стороннее программное обеспечение будут
                      продолжаться при наличии на&nbsp;Лицевом Счёте договора
                      достаточной для списания суммы.
                    </p>
                    <p>
                      Временная приостановка не&nbsp;означает прекращение
                      договорных отношений между Оператором и&nbsp;Клиентом. Для
                      закрытия договора вам необходимо обратиться в&nbsp;любой
                      из&nbsp;
                      <Link href="/contacts" target="_blank">
                        офисов
                      </Link>
                      &nbsp;Планеты.
                    </p>
                  </div>
                )}
                {viewType === VIEW_TYPES.PAUSE && agreement && (
                  <div className="wizard__agreement">
                    <Text lineHeight="24px" fontWeightBold>
                      Согласие на временную приостановку обслуживания
                    </Text>
                    <Text className="wizard__agreement__text" lineHeight="24px">
                      {parseHtml(agreement)}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </OperatingStateWizardContainer>
    </>
  );
};

export default observer(OperatingStateWizard);
