import { Block } from '~/interfaces/BlockInterface';

/** свойства компонента */
export interface AnnouncementsContentProps {
  content: AnnouncementsProps;
}

/** контент блока */
export interface AnnouncementsProps extends Block {
  fields: AnnouncementsFields;
}

/** поля блока */
export interface AnnouncementsFields {
  type: AnnouncementsType;
  tags?: Tags;
  announcements: AnnouncementFieldsContentProps;
}

/** Теги */
export interface Tags {
  [key: string]: string;
}

/** тип блока с анонсами */
export enum AnnouncementsType {
  SHORT = 'short',
  FULL = 'full',
}

// Значения тегов
export enum AnnouncementsTags {
  ALL = 'Все',
  NEWS = 'новости',
}

/** Теги по городам */
export interface AnnouncementsTagsByCity {
  tags: Tags[];
}

export interface AnnouncementFieldsContentProps {
  /** Флаг последней страницы новостей */
  isFinished: boolean;
  /** Новости */
  announcements: AnnouncementContentProps[];
}

/** поля анонса */
export interface AnnouncementContentProps {
  publishedDt: string;
  title: string;
  subTitle?: string;
  imgLink: string;
  tag: string;
  fullSlug: string;
  cities: string[];
  tagUrl: string;
  rssDesc?: string;
}

/** Поля массива тегов */
export interface TagsFields {
  tag: string;
  hash: string;
}
