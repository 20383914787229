import { MenuInfo } from '~/components/Feed/FeedPage.types';

/* Возможные стили для мобильного и десктопного меню.
 *  Используется при появлении и сокрытии меню при скроллинге.
 * */
export enum MenuStyles {
  small = 'small',
  smallMobile = 'small__mobile',
  hidden = 'hidden',
}

export interface HeaderProps {
  menu: MenuInfo;
}
