/** interfaces */
import { ChannelPackage, Highlightable } from '../../../interfaces';

/** поля блока */
export interface ChannelPackageFieldsProps extends Highlightable {
  packet: ChannelPackage;
  onClick?: (packet: ChannelPackage) => void;
  /** Пакеты включённые в продукт на витрине */
  packagesIncludedInProduct?: ChannelPackage[];
  /** Трансформер в блоке Телевидение */
  isTransformer?: boolean;
}

export enum PackageConnectionStates {
  // не подключён
  NotConnected,
  // Подключён
  Connected,
  // Оформлен отказ
  RefusalIssued,
  // Включён в продукт
  IncludedInTheProduct,
  // Добавлен в трансформер
  AddedToTransformer,
  // Убран из трансформера
  RemovedFromTheTransformer,
}
