import styled from '@emotion/styled';

export const StyledOptionBlock = styled.div`
  min-width: 27%;

  &:nth-of-type(n + 2) {
    padding: 0 0 0 2rem;
  }
  > div > a {
    display: flex;
    align-items: center;
    & svg {
      margin: 0 0.5rem 0 0;
    }
  }
  & strong {
    font-weight: 700;
    font-size: 28px;
    line-height: 31px;
  }
`;

export const StyledOptionBlockValue = styled.div`
  margin-top: 4px;

  h3 {
    font-weight: 700;
    margin-bottom: 0;
  }
`;
