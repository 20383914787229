/** libraries */
import { FC, useMemo } from 'react';
import { H3, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledCloseToZombie } from './styles';
/** utils */
import { pluralizeAll } from '~/utils/utils';
import { getHeaderPhone } from '~/components/Header/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** interfaces */
import { ZombieInfoProps } from '../../interfaces';

interface CloseToZombieProps {
  zombieInfo: ZombieInfoProps;
}
/** Демонтаж оборудования Оператора */
const CloseToZombie: FC<CloseToZombieProps> = ({
  zombieInfo,
}: CloseToZombieProps) => {
  const {
    cityStore: { city, contactPhones },
  } = useRootStore();
  // Номер телефона
  const phone = useMemo(() => {
    return getHeaderPhone(contactPhones, city);
  }, [contactPhones, city]);

  return (
    <StyledCloseToZombie>
      <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
        Демонтаж произойдёт через
      </Text>
      <H3>{pluralizeAll(zombieInfo.daysLeft, ['день', 'дня', 'дней'])}</H3>
      <Text className="zombie__text" lineHeight="24px">
        Оператор высвобождает своё оборудование через{' '}
        <Text lineHeight="24px" fontWeightBold>
          {pluralizeAll(zombieInfo.daysMax, ['день', 'дня', 'дней'])}
        </Text>{' '}
        после приостановки обслуживания за неуплату для возможности подключения
        других клиентов.
      </Text>
      <Text lineHeight="24px">
        В случае демонтажа, ваше обслуживание может быть восстановлено в течение{' '}
        <Text lineHeight="24px" fontWeightBold>
          20 дней
        </Text>{' '}
        после обращения в наш офис по телефону{' '}
        <Text lineHeight="24px" fontWeightBold>
          {phone}
        </Text>
        .
      </Text>
    </StyledCloseToZombie>
  );
};

export default observer(CloseToZombie);
