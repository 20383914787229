import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, defaultTheme } from 'cordis-core-ui-planeta';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
  z-index: 2;

  p {
    margin-bottom: 32px;
    font-size: 21px;
    line-height: 25px;
    font-weight: 500;
  }
`;

export const Header = styled.span`
  margin-bottom: 17px;
  z-index: 2;
`;

export const StyledImageWrapper = styled.div`
  position: absolute;
  bottom: -75px;
  z-index: 1;
`;

export const StyledButton = styled(Button)`
  z-index: 2;
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'space-between' : 'flex-start'};
  width: 100%;
  position: relative;

  .block {
    display: inline-grid;
  }

  ${StyledImageWrapper} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            bottom: 160px;
            left: -53px;
          `
        : css`
            bottom: -75px;
            left: auto;
          `}
  }

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            text-align: left;
            margin-bottom: 32px;

            p {
              margin: 32px 0 0 0;
              font-size: 16px;
              line-height: 25px;
              font-weight: 400;
            }
          `
        : css`
            margin-bottom: 46px;
          `}
  }

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          button {
            margin-bottom: 28px;
            border: 2px solid ${defaultTheme.colors.white};
          }
        `
      : ''}
`;
