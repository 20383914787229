/** libraries */
import { FC, useMemo } from 'react';
import {
  defaultTheme,
  LeadingText,
  Text,
  ColorsType,
} from 'cordis-core-ui-planeta';
import { nanoid } from 'nanoid';
/** styles */
import { StyledMobileTable } from './styles';
/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { dateString } from '../../utils';
/** constants */
import { CHATLE } from '../../constants';

interface MobileTableProps {
  products?: DefaultProductProps[];
  content: LinesWithHeader;
}

interface DefaultProductProps {
  date: string;
  name: string;
}

interface LinesWithHeader {
  header?: string;
  lines?: LineProps[];
}

interface LineProps {
  info?: React.ReactNode;
  infoColor?: ColorsType;
  date?: string;
  isFormatDate?: boolean;
  amount?: number;
  amountColor?: ColorsType;
  balance?: number;
  comment?: string;
  commentColor?: ColorsType;
  lineClick?: () => void;
}

const MobileTable: FC<MobileTableProps> = ({
  products,
  content,
}: MobileTableProps) => {
  const lines = content?.lines?.length
    ? content.lines.filter((item) => item)
    : [];

  /** Генерация ключей для списка */
  const productIds = useMemo(
    () => (products?.length ? products.map(() => nanoid(5)) : []),
    [products],
  );
  const lineIds = useMemo(
    () => (lines?.length ? lines.map(() => nanoid(5)) : []),
    [lines],
  );

  return (
    <StyledMobileTable>
      {products?.length > 0 && (
        <div className="table__products">
          {products.map((item, index) => {
            return (
              <Text
                className="table__product"
                color={defaultTheme.colors.gray}
                lineHeight="24px"
                key={productIds[index]}
              >
                {`Продукт «${item.name}»`}
                <br />
                {`действует с${'\u00A0'}${dateString(
                  item.date,
                  'dd MMM yyyy',
                )}`}
              </Text>
            );
          })}
        </div>
      )}
      <div className="table__border">
        {content?.header && (
          <Text
            className="table__border__header"
            lineHeight="24px"
            fontWeightBold
          >
            {content.header}
          </Text>
        )}
      </div>
      {lines.length > 0 && (
        <>
          {lines.map((item, index) => {
            return (
              <div className="table__line-block" key={lineIds[index]}>
                <div className="table__line-block__date-block">
                  {item.date && (
                    <Text
                      className="table__line-block__date"
                      color={defaultTheme.colors.shadow}
                      lineHeight="24px"
                    >
                      {item?.isFormatDate
                        ? dateString(item.date, 'dd MMM yyyy')
                        : item.date}
                    </Text>
                  )}
                  {item?.comment && (
                    <Text
                      className="table__line-block__comment"
                      color={item?.commentColor}
                      lineHeight="24px"
                    >
                      {item.comment}
                    </Text>
                  )}
                </div>
                {item.amount !== undefined && (
                  <LeadingText
                    className="table__line-block__price"
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      item?.amountColor
                        ? item.amountColor
                        : item.amount < 0
                        ? defaultTheme.colors.down
                        : defaultTheme.colors.green
                    }
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      item.amount === 0
                        ? '0 ₽'
                        : item?.balance
                        ? item.amount
                        : `${formatNumber(item.amount)} ₽`
                    }
                  </LeadingText>
                )}
                <Text
                  color={
                    item.lineClick
                      ? defaultTheme.colors.planeta
                      : item?.infoColor
                  }
                  lineHeight="24px"
                  onClick={item.lineClick}
                >
                  {item.info}
                </Text>
                {item?.balance && (
                  <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                    Баланс: {pluralizeAll(item.balance, CHATLE)}
                  </Text>
                )}
              </div>
            );
          })}
        </>
      )}
    </StyledMobileTable>
  );
};

export default MobileTable;
