import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** style */
import { StyledFooter } from '../../style';
/** store */
import { useRootStore } from '~/stores/RootStore';
import useSettingsStore from '../../store/useSettingsStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
/** constants */
import { SIDE_PAGES } from '~/components/AuthWizard/constants';

const PinFooter = (): JSX.Element => {
  const {
    authStore: { isTemporaryTokenAuth, isAuth },
  } = useRootStore();
  const {
    pinStore: { code, password, isDisableButton, changePinCode },
  } = useSettingsStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  useEffect(() => {
    if (!isAuth && !code && !password) return;
    if (openSPAfterAuthorizationState === SIDE_PAGES.CHANGE_PIN_ACTION) {
      changePinCode(setOpenSPAfterAuthorizationState);
    }
  }, [isAuth]);

  return (
    <StyledFooter>
      <Button
        onClick={() => changePinCode(setOpenSPAfterAuthorizationState)}
        disabled={isDisableButton || isTemporaryTokenAuth}
      >
        Изменить
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(PinFooter);
