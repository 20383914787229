import { FC } from 'react';
import { Text, defaultTheme } from 'cordis-core-ui-planeta';
import { StyledTh } from './styles';

interface ThProps {
  children: React.ReactNode;
  width?: number;
  textAlign?: string;
}

const Th: FC<ThProps> = ({ children, width, textAlign }: ThProps) => {
  return (
    <StyledTh width={width} textAlign={textAlign}>
      <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
        {children}
      </Text>
    </StyledTh>
  );
};

export default Th;
