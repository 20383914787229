/** библиотеки */
import React, { useState, useEffect } from 'react';
import { VKShareButton, OKShareButton } from 'react-share';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** стили */
import {
  StyledFooter,
  StyledMyStoriesContest,
  StyledTags,
} from './MyStoriesContest.style';

/** компоненты библиотеки */
import {
  Text,
  H2,
  SwipeIndicator,
  LeadingText,
  defaultTheme,
  Button,
  ButtonStyleTypes,
  TagButton,
  Textarea,
  Icons,
  H3,
  Snoska,
} from 'cordis-core-ui-planeta';

/** константы */
import { IMAGES, ERRORS, SOCIAL_DATA, MOUNTHS } from './constants';
import {
  desktop400,
  desktop900,
  desktop940,
  desktop1100,
} from '~/components/Grid/constants';
import { BLOCK_CONTRACT_ERRORS } from '../../Shared/CreatePassword/constants';

/** api */
import { getPlanetaHistoryInfo, setAnswer, setReposted } from '~/api/api';

/** типы */
import { MyStoriesContestProps } from './MyStoriesContest.types';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';
import { removeLeadingZero } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const MyStoriesContest: React.FC<MyStoriesContestProps> = ({
  content,
}: MyStoriesContestProps) => {
  /** Поля */
  const {
    tags,
    dateResult,
    rulesLink,
    winnersLink,
    benefitsLink,
  } = content.fields;
  const { size } = content;

  const {
    authStore: { isAuth, auth, isTemporaryTokenAuth },
  } = useRootStore();
  const { toggleVisible } = useMakeAuthStore();

  /** Дата результатов розыгрыша */
  const [day, setDay] = useState<string>('');
  const [mounth, setMounth] = useState<string>('');

  /** Теги */
  const tagsKeys = Object.keys(tags);

  /** Активный тег */
  const [activeTag, setActiveTag] = useState<string>('');

  /** Страница ответа */
  const [isAnswer, setIsAnswer] = useState<boolean>(false);

  /** Флаг repost */
  const [isReposted, setIsReposted] = useState<boolean>(false);

  /** Значение textarea */
  const [textareaValue, setTextareaValue] = useState<string>('');

  /** Флаг редактирования textarea */
  const [isEdited, setIsEdited] = useState<boolean>(false);

  /** Текст ошибки textarea */
  const [errorTextarea, setErrorTextarea] = useState<string>(
    !textareaValue && ERRORS.EmptyField,
  );

  // Вычисление ширины экрана
  const isMinDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });
  const isMinDesktop900 = useMediaQuery({
    query: `(min-width: ${desktop900}px)`,
  });
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isMinDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Информация о конкурсе пользователя */
  const getStoriesInfo = async () => {
    try {
      const data = await getPlanetaHistoryInfo();
      setIsAnswer(data.status !== 204);
      if (data.status !== 204) {
        const res = await data.json();
        setIsReposted(res.isReposted);
      }
    } catch (e) {
      console.error('storiesInfoError', e);
    }
  };

  /** Установка флага true для поля is_reposted */
  const reposted = async () => {
    try {
      await setReposted();
    } catch (e) {
      console.error('repostedError', e);
    }
  };

  /** Кнопка "отправить ответ" */
  const submitReply = async () => {
    validation(textareaValue);
    if (!errorTextarea) {
      try {
        const res = await setAnswer(textareaValue);
        if (res.status === 400) setErrorTextarea(ERRORS.Error);
        else await getStoriesInfo();
      } catch (e) {
        const error = JSON.parse(e.errorMessage);
        if (error?.Type === BLOCK_CONTRACT_ERRORS.ContractNotServicedException)
          setErrorTextarea(ERRORS.NotServiced);
      }
    }
  };

  /** Текст для textarea */
  const textareaDefaultValue = () => {
    if (isEdited || !tags) return;
    const defaultValue = Object.keys(tags)
      .map((tag) => {
        return activeTag === tag ? tags[tag] : null;
      })
      .filter((item) => item);
    setTextareaValue(defaultValue.toString());
  };

  /** Валидация textarea */
  const validation = (text) => {
    if (!text) setErrorTextarea(ERRORS.EmptyField);
    else if (text.length >= 400) setErrorTextarea(ERRORS.Over400Characters);
    else setErrorTextarea('');
  };

  /** Обработчик textarea */
  const handleChangeTextarea = (e) => {
    validation(e.target.value);
    setTextareaValue(e.target.value);
    setIsEdited(!!e.target.value);
  };

  /** Дата результатов розыгрыша */
  const getDateResult = () => {
    const date = new Date(dateResult);
    setDay(date.getDate().toString());
    setMounth(MOUNTHS[date.getMonth()]);
  };

  useEffect(() => {
    if (dateResult) getDateResult();
  }, []);

  useEffect(() => {
    if (isAuth) getStoriesInfo();
  }, [isAuth]);

  useEffect(() => {
    textareaDefaultValue();
  }, [activeTag]);

  /** первый слайд свайп индикатора */
  const swipeIndicatorFirst = (
    <div className="swipe-indicator">
      <span className="swipe-indicator__circle_img swipe-indicator__circle" />
      <img
        className="swipe-indicator__image"
        src={IMAGES.marusyaMini}
        alt="MarusyaMini"
      />
      {((size === 1 && (!isMinDesktop940 || isMinDesktop1100)) || size !== 1) &&
        isMinDesktop400 && (
          <Text
            className="swipe-indicator__text"
            lineHeight="24px"
            color={defaultTheme.colors.white}
          >
            Капсула Мини с&nbsp;Марусей
          </Text>
        )}
    </div>
  );

  /** второй слайд свайп индикатора */
  const swipeIndicatorSecond = (
    <div className="swipe-indicator">
      <img
        className="swipe-indicator__circle_img"
        src={IMAGES.planeta}
        alt="planeta"
      />
      <H2
        className="swipe-indicator__text_on_circle"
        isStories
        color={defaultTheme.colors.white}
      >
        1 год
      </H2>
      {((size === 1 && (!isMinDesktop940 || isMinDesktop1100)) || size !== 1) &&
        isMinDesktop400 && (
          <Text
            className="swipe-indicator__text"
            lineHeight="24px"
            color={defaultTheme.colors.white}
          >
            Год интернета и&nbsp;ТВ&nbsp;от&nbsp;Планеты
          </Text>
        )}
    </div>
  );

  /** третий и четвёртый слайд свайп индикатора */
  const swipeIndicatorThirdAndFourth = (text, description) => (
    <div className="swipe-indicator">
      <img
        className="swipe-indicator__circle_img"
        src={IMAGES.hexagon}
        alt="planeta"
      />
      <H2
        className="swipe-indicator__text_on_circle"
        isStories
        color={defaultTheme.colors.white}
      >
        {text}
      </H2>
      {((size === 1 && (!isMinDesktop940 || isMinDesktop1100)) || size !== 1) &&
        isMinDesktop400 && (
          <Text
            className="swipe-indicator__text"
            lineHeight="24px"
            color={defaultTheme.colors.white}
          >
            {description}
          </Text>
        )}
    </div>
  );

  /** слайды для индикатора */
  const swipeItems = [
    swipeIndicatorFirst,
    swipeIndicatorSecond,
    swipeIndicatorThirdAndFourth(
      '1000',
      `1000 бонусных рублей${'\u00A0'}на${'\u00A0'}счёт в${'\u00A0'}Планете`,
    ),
    swipeIndicatorThirdAndFourth(
      '500',
      `500 бонусных рублей${'\u00A0'}на${'\u00A0'}счёт в${'\u00A0'}Планете`,
    ),
  ];

  /** Ссылки */
  const links = (): JSX.Element => {
    return (
      <>
        {rulesLink && (
          <LinkWrapper href={rulesLink}>
            <Text lineHeight="24px" color={defaultTheme.colors.pink}>
              Правила розыгрыша
            </Text>
          </LinkWrapper>
        )}
        {winnersLink && (
          <LinkWrapper href={winnersLink}>
            <Text lineHeight="24px" color={defaultTheme.colors.pink}>
              Победители
            </Text>
          </LinkWrapper>
        )}
        {benefitsLink && (
          <LinkWrapper href={benefitsLink}>
            <Text lineHeight="24px" color={defaultTheme.colors.pink}>
              Призы
            </Text>
          </LinkWrapper>
        )}
      </>
    );
  };

  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledMyStoriesContest size={size}>
        <div className="my-stories-contest__block-x1">
          <div className="my-stories-contest__tabs">
            {size === 1 ? (
              <Text
                lineHeight="24px"
                color={defaultTheme.colors.white}
                fontWeightBold
              >
                Розыгрыш
              </Text>
            ) : (
              links()
            )}
          </div>
          {size === 1 && isMinDesktop940 && (
            <H3 isStories color={defaultTheme.colors.white}>
              Планета&nbsp;&mdash; это твоя история
            </H3>
          )}
          {(size !== 1 || (size === 1 && !isMinDesktop940)) && (
            <H2 isStories color={defaultTheme.colors.white}>
              Планета&nbsp;&mdash; это твоя история
            </H2>
          )}
          <div
            className={`my-stories-contest__wrapper-swipe-indicator ${
              !isMinDesktop900 &&
              'my-stories-contest__wrapper-swipe-indicator-mobile'
            }`}
          >
            <SwipeIndicator
              swipeItems={swipeItems}
              swipeMainColor={defaultTheme.colors.white}
              swipeChildColor={defaultTheme.colors.pink}
              indicator={!isMinDesktop900 && size !== 1}
              auto={isMinDesktop900}
              loop={isMinDesktop900}
            />
          </div>
          <LeadingText
            className="my-stories-contest__text"
            color={defaultTheme.colors.white}
          >
            Ответь на&nbsp;вопрос и&nbsp;выиграй крутые призы от&nbsp;Планеты
          </LeadingText>
        </div>

        {size !== 1 && (
          <div className="my-stories-contest__block-x2">
            {!isAuth && (
              <div className="my-stories-contest__auth">
                <LeadingText
                  className="my-stories-contest__auth__text"
                  color={defaultTheme.colors.white}
                >
                  Чтобы участвовать в&nbsp;розыгрыше призов, авторизуйся
                  по&nbsp;номеру договора
                </LeadingText>
                <Button
                  onClick={() => toggleVisible()}
                  styleType={ButtonStyleTypes.SECONDARY}
                >
                  Войти
                </Button>
              </div>
            )}

            {isAuth && !isAnswer && (
              <div className="my-stories-contest__answer">
                <Text lineHeight="24px" color={defaultTheme.colors.white}>
                  {removeLeadingZero(auth.contractName)}
                </Text>
                {isMinDesktop900 ? (
                  <H2 color={defaultTheme.colors.light}>
                    О&nbsp;чём твоя история с&nbsp;Планетой?
                  </H2>
                ) : (
                  <H3 color={defaultTheme.colors.light}>
                    О&nbsp;чём твоя история с&nbsp;Планетой?
                  </H3>
                )}
                <StyledTags>
                  {tagsKeys.map((item) => {
                    return (
                      <TagButton
                        className="tag-button"
                        key={item}
                        onChange={() => {
                          setActiveTag(item);
                          setErrorTextarea('');
                        }}
                        checked={item === activeTag}
                      >
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </TagButton>
                    );
                  })}
                </StyledTags>
                <div className="my-stories-contest__answer__textareaWrapper">
                  <Textarea
                    resize="none"
                    onChange={(e) => handleChangeTextarea(e)}
                    value={textareaValue}
                    maxLength={400}
                  />
                  {errorTextarea && (
                    <div className="my-stories-contest__answer__textareaWrapper__textareaError">
                      {errorTextarea}
                    </div>
                  )}
                </div>
                <StyledFooter>
                  <Button
                    onClick={submitReply}
                    styleType={ButtonStyleTypes.SECONDARY}
                    disabled={isTemporaryTokenAuth}
                  >
                    Ответить
                  </Button>
                  {isTemporaryTokenAuth && (
                    <Snoska
                      className="snoska"
                      color={defaultTheme.colors.white}
                    >
                      Действие доступно только клиенту
                    </Snoska>
                  )}
                </StyledFooter>
              </div>
            )}

            {isAuth && isAnswer && (
              <div className="my-stories-contest__repost">
                <Text lineHeight="24px" color={defaultTheme.colors.white}>
                  {removeLeadingZero(auth.contractName)}
                </Text>
                <H2 color={defaultTheme.colors.light}>
                  👌 Спасибо за&nbsp;ответ!
                </H2>
                <LeadingText
                  className="my-stories-contest__repost__text"
                  color={defaultTheme.colors.white}
                >
                  Результаты розыгрыша будут опубликованы {day} {mounth}.
                  <br />
                  <br />
                  Поделись этой страничкой в&nbsp;соцсетях и&nbsp;получи 100
                  бонусных рублей на&nbsp;счёт в&nbsp;Планете. Бонусы начислим
                  в&nbsp;течение 7&nbsp;дней.
                </LeadingText>
                <Text lineHeight="24px" color={defaultTheme.colors.white}>
                  Поделиться:
                </Text>
                <div className="my-stories-contest__repost__icons">
                  <VKShareButton
                    url={SOCIAL_DATA.shareUrl}
                    title={`${SOCIAL_DATA.title}`}
                    image={IMAGES.shearing}
                  >
                    <div
                      onClick={async () => {
                        if (!isReposted) {
                          await reposted();
                          await getStoriesInfo();
                        }
                      }}
                      className="my-stories-contest__repost__icons__icon my-stories-contest__repost__icons__vk"
                    >
                      <Icons.VKIcon />
                    </div>
                  </VKShareButton>
                  <OKShareButton
                    url={SOCIAL_DATA.shareUrl}
                    title={`${SOCIAL_DATA.title}`}
                    image={IMAGES.shearing}
                  >
                    <div
                      onClick={async () => {
                        if (!isReposted) {
                          await reposted();
                          await getStoriesInfo();
                        }
                      }}
                      className="my-stories-contest__repost__icons__icon my-stories-contest__repost__icons__ok"
                    >
                      <Icons.ClassmatesIcon />
                    </div>
                  </OKShareButton>
                </div>
              </div>
            )}
          </div>
        )}
      </StyledMyStoriesContest>
    </LinkWrapper>
  );
};

export default observer(MyStoriesContest);
