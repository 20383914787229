/** библиотеки */
import { FC, useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';

import Image from 'next/image';

/** размеры экрана */
import { defaultTheme, H2, H3, Text } from 'cordis-core-ui-planeta';
import { desktop940, desktop1100 } from '~/components/Grid/constants';

/** компоненты библиотеки */
import Word from './Word';
import LinkWrapper from '~/components/LinkWrapper';

/** стилевые компоненты */
import { StyledVoiceControl } from './VoiceControlBanner.style';

/** константы */
import { IMAGES } from '../constants';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

const blockTextList = [
  'Найди боевики 2019',
  'Включи первый канал',
  'Добавь канал в избранное',
  'Найди фильмы с Ди Каприо',
  'Включи ТНТ',
  'Перемотай на 10 минут назад',
  'Найди Симпсонов',
];

const VoiceControlBanner: FC<AdvantagesContentProps> = ({ content }) => {
  const [next, setNext] = useState<number>(0);
  const [wordCount, setWordCount] = useState<number>(-1);
  /* Создаёт массив из слов */
  const blockText = (): string[] => {
    const text = blockTextList[next].split(' ').map((word, index) => {
      if (index > 0) return ` ${word}`;
      return word;
    });
    return text;
  };

  /** Вычисление ширины экрана */
  const is1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const is940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const TextComponents = {
    H2,
    H3,
  };

  const TextComponent =
    !is1100 && is940 ? TextComponents.H3 : TextComponents.H2;

  useEffect(() => {
    let timerId;
    if (wordCount === -1) {
      // Задержка для подсветки первого слова после обновления фразы
      timerId = setTimeout(() => {
        setWordCount(wordCount + 1);
      }, 300);
    } else if (
      wordCount >= 0 &&
      wordCount < blockTextList[next].split(' ').length
    ) {
      timerId = setTimeout(() => {
        setWordCount(wordCount + 1);
      }, 300);
    } else {
      // Задержка для подсветки последнего слова после обновления фразы
      timerId = setTimeout(() => {
        setNext((next + 1) % blockTextList.length);
        setWordCount(-1);
      }, 1000);
    }
    return (): void => {
      clearTimeout(timerId);
    };
  }, [next, wordCount]);

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledVoiceControl>
        <div>
          <Image
            loader={imageLoader}
            src={IMAGES.mic}
            alt="mic"
            width={48}
            height={48}
            quality={100}
          />
          <div className="voiceControl__animation-block">
            <TextComponent color={defaultTheme.colors.white}>
              {blockText().map((item, index) => (
                <Word key={String(index)} isActive={index <= wordCount}>
                  {item}
                </Word>
              ))}
            </TextComponent>
          </div>
        </div>
        <div>
          <H3 color={defaultTheme.colors.white}>Говорите!</H3>
          <Text
            className="voiceControl__text"
            color={defaultTheme.colors.white}
          >
            Планета представляет голосовое управление интерфейсом цифрового
            телевидения
          </Text>
        </div>
      </StyledVoiceControl>
    </LinkWrapper>
  );
};

export default VoiceControlBanner;
