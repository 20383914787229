/** библиотеки */
import React, { FC, useState } from 'react';
import {
  defaultTheme,
  PriceTag,
  PriceTagBackgroundColor,
  Tabs,
  TabsStyleTypes,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';

/** типы */
import { ActiveTab, DeviceTabsProps } from './interfaces';

/** константы */
import { TABS_PERIOD } from './constants';

/** utils */
import { formatNumber } from '~/utils/utils';

/** styles */
import { StyledToggleTabs } from './styles';
import { desktop940 } from '~/components/Grid/constants';

/**
 * Компонент с переключением таба
 */
const ToggleTabs: FC<DeviceTabsProps> = ({
  cost,
  additionally,
}: DeviceTabsProps) => {
  // Возвращает массив табов
  const generateTabs = (): TABS_PERIOD[] | [] => {
    const newTabs = [];

    if (cost !== null) {
      newTabs.push(TABS_PERIOD.DAY);
      newTabs.push(TABS_PERIOD.MONTH);
    }

    return newTabs;
  };

  /* Событие при изменении таба */
  const onChangeTab = (index: number, value: string) => {
    setActiveTab({ index, value });
  };

  // Список табов
  const tabs = generateTabs();

  // Исходный активный таб
  const initialActiveTab = {
    index: 0,
    value: tabs[0],
  };

  // Активный таб
  const [activeTab, setActiveTab] = useState<ActiveTab>(initialActiveTab);

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledToggleTabs isAdditionally={!!additionally}>
      <div className="toggle-tabs__tabs">
        <Tabs
          value={tabs}
          styleType={TabsStyleTypes.SECONDARY}
          onChange={onChangeTab}
          activeTabIndex={activeTab.index}
        />
      </div>

      {activeTab.value === TABS_PERIOD.DAY && (
        <PriceTag
          value={`${formatNumber(cost)} ₽`}
          subscription={
            additionally
              ? `Сверх лимита${isDesktop940 ? '\u000A' : ' '}${formatNumber(
                  additionally,
                )} ₽ в${'\u00A0'}день`
              : undefined
          }
          backgroundColor={
            additionally ? PriceTagBackgroundColor.WARNING : undefined
          }
          fontColor={additionally ? defaultTheme.colors.warning : undefined}
          headerType="H3"
        />
      )}
      {activeTab.value === TABS_PERIOD.MONTH && (
        <PriceTag
          value={`${formatNumber(30 * cost)} ₽`}
          subscription={
            additionally
              ? `Сверх лимита${isDesktop940 ? '\u000A' : ' '}${formatNumber(
                  30 * additionally,
                )} ₽ в${'\u00A0'}месяц`
              : undefined
          }
          backgroundColor={
            additionally ? PriceTagBackgroundColor.WARNING : undefined
          }
          fontColor={additionally ? defaultTheme.colors.warning : undefined}
          headerType="H3"
        />
      )}
    </StyledToggleTabs>
  );
};

export default ToggleTabs;
