/** libraries */
import { FC } from 'react';
import { defaultTheme, Input, Text } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** utils */
import { maskPhone } from '~/utils/utils';
/** styles */
import { StyledAddingContact } from './styles';
/** stores */
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';

const ConfirmationCode: FC = () => {
  const {
    seconds,
    startTimer,
    setTimerIsOn,
    addingContactStore: {
      contactData,
      phoneNumber,
      setCode,
      setCodeError,
      code,
      codeError,
      sendingCode,
    },
  } = useContactsAndNotificationsStore();

  /** Изменение input */
  const onChangeCode = (event) => {
    setCode(event.target.value.replace(/[^0-9]/g, ''));
  };
  /** Фокус на input code */
  const onFocusCode = () => {
    setCodeError('');
  };

  return (
    <StyledAddingContact>
      <div className="confirmation-code__info">
        <Text lineHeight="24px">
          Номер телефона{' '}
          <Text lineHeight="24px" fontWeightBold>
            {phoneNumber.value.length < 16
              ? maskPhone(contactData.value)
              : phoneNumber.value}
          </Text>{' '}
          добавлен. На него отправлено сообщение с проверочным кодом.
        </Text>
      </div>
      <div className="confirmation-code__input">
        <div className="confirmation-code__input__block">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Код
          </Text>
          <Input
            className="confirmation-code__input__block__code"
            type="text"
            placeholder="Введите код"
            value={code}
            error={codeError}
            onChange={onChangeCode}
            onFocus={onFocusCode}
            isFocus
          />
        </div>
        <div className="confirmation-code__action">
          {seconds !== 0 && (
            <Text
              className="confirmation-code__action__timer-text"
              lineHeight="24px"
              color={defaultTheme.colors.shadow}
            >
              Выслать ещё код можно через {seconds} секунд
            </Text>
          )}
          {seconds === 0 && (
            <div className="confirmation-code__action__block">
              <Text
                onClick={() => {
                  startTimer();
                  setTimerIsOn(true);
                  sendingCode();
                }}
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
              >
                Выслать ещё код
              </Text>
            </div>
          )}
        </div>
      </div>
    </StyledAddingContact>
  );
};

export default observer(ConfirmationCode);
