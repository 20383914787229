/* Библиотеки */
import * as React from 'react';

/* Типы */
import { CallToActionInternetProps } from './CallToActionInternet.types';
import { ConnectionMethod } from '~/constants/common';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
import { Text, H3, Icons, defaultTheme, Tag } from 'cordis-core-ui-planeta';

/* Стили */
import {
  StyledLeftColumn,
  StyledRightColumn,
} from './CallToActionInternet.style';

/* Утилиты */
import { formatNumber } from '~/utils/utils';

/* Дочерний компонент "Скорость интернета", компонента CallToAction */
const CallToActionInternet: React.FC<CallToActionInternetProps> = ({
  speedBaseText,
  connectionMethod,
  connection,
  finishDt,
  isAction,
}: CallToActionInternetProps) => {
  // Период действия акции
  /* yaspeller ignore:start */
  const promoText: string = finishDt ? `\nдо\u0020${finishDt}` : '';
  /* yaspeller ignore:end */
  // Флаг оптического подключения
  const isFTTH: boolean = connectionMethod === ConnectionMethod.FTTH;

  return (
    <CallToActionRow title={<Text>Интернет</Text>}>
      <StyledLeftColumn>
        <H3>{isFTTH ? 'Гигабит' : speedBaseText}</H3>
        {/* yaspeller ignore:start */}
        <span>
          <Text color={defaultTheme.colors.shadow}>
            {isFTTH &&
              (connection > 0
                ? `Стоимость подключения\nк оптической сети ${formatNumber(
                    connection,
                  )}\u20bd${promoText}`
                : `Бесплатное подключение${promoText}`)}
            {isFTTH && connection === 0 && !isAction && <sup>&nbsp;*</sup>}
          </Text>
          {isAction && (
            <Tag
              color={defaultTheme.colors.planeta}
              colorTag={defaultTheme.colors.pink}
            >
              АКЦИЯ
            </Tag>
          )}
        </span>
        {/* yaspeller ignore:end */}
        <div>
          <Icons.OkIcon />
          <Text color={defaultTheme.colors.shadow}>Белый IP-адрес</Text>
        </div>
      </StyledLeftColumn>
      <StyledRightColumn>
        <Icons.OkIcon />
        <Text color={defaultTheme.colors.shadow}>
          Белый
          <br />
          IP-адрес
        </Text>
      </StyledRightColumn>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionInternet);
