/** библиотеки */
import styled from '@emotion/styled';

/** константы */
import { desktop475, desktop940 } from '~/components/Grid/constants';

export const StyledPromo = styled.div`
  .promo {
    &__content {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__checkbox {
      width: 100%;
      margin-bottom: 32px;
    }

    &__sbp-button {
      width: 100%;
      padding: 4px 38px;
      margin: 0 16px 16px 0;
    }

    &__card-button {
      width: 100%;
    }
  }

  @media (min-width: ${desktop940}px) {
    .promo {
      &__sbp-button {
        width: auto;
        margin: 0 16px 0 0;
      }

      &__card-button {
        width: auto;
      }
    }
  }
`;

export const StyledCashbackBottoms = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button {
    margin: 0 16px 16px 0;
    width: 100%;
    height: 76px;
    white-space: pre-line;
  }

  @media (min-width: ${desktop475}px) {
    > button {
      white-space: normal;
      height: auto;
    }
  }

  @media (min-width: ${desktop940}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > button {
      margin: 0 16px 0 0;
      padding: 14px 16px;
      width: auto;
      height: 76px;
      white-space: pre;
    }
  }
`;
