/** libraries */
import { FC } from 'react';
import { StyledComment } from './styles';
import { ColorsType, Text } from 'cordis-core-ui-planeta';

interface CommentProps {
  comment: string;
  backgroundColor: ColorsType;
  isClient: boolean;
}

/** Комментарий */
const Comment: FC<CommentProps> = ({
  comment,
  backgroundColor,
  isClient,
}: CommentProps) => {
  return (
    <StyledComment backgroundColor={backgroundColor} isClient={isClient}>
      <div>
        <Text lineHeight="24px">{comment}</Text>
      </div>
    </StyledComment>
  );
};

export default Comment;
