/** библиотеки */
import React, { FC } from 'react';
import { H2, Icons, SidePage, Text, Snoska } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** стилевые компоненты */
import {
  StyledResult,
  StyledLegalBlockDevice,
  StyledSidePageFooter,
  StyledSP,
} from '../../Templates/Devices/style';

/** компоненты */
import DeviceDetailedCard from './DeviceDetailedCard';

/* Утилиты */
import { parseHtml } from '../../Templates/Legal/parser';

/** константы */
import { DEVICE_PLURAL, RESULT_TEXT } from '../../Templates/Devices/constants';

/** stores */
import useDeviceDetailedCardStore from './store/useDeviceDetailedCardStore';

/**
 * Сайдпейдж с детальной информацией о оборудовании
 */
const DeviceDetailedCardSP: FC = () => {
  const {
    isDetailedDeviceShow,
    detailedDeviceData,
    result,
    phone,
    resetDetailedDeviceStore,
  } = useDeviceDetailedCardStore();
  return (
    <StyledSP>
      <SidePage
        show={isDetailedDeviceShow}
        headerText={
          detailedDeviceData && !result.isResult
            ? `${detailedDeviceData.typeName} ${detailedDeviceData.vendorName} ${detailedDeviceData.name}`
            : ''
        }
        onCloseClick={resetDetailedDeviceStore}
        footerContainer={
          !result.isResult && (
            <StyledSidePageFooter>
              <StyledLegalBlockDevice>
                <Snoska>{parseHtml(detailedDeviceData?.footer)}</Snoska>
              </StyledLegalBlockDevice>
            </StyledSidePageFooter>
          )
        }
        removeScrollBar
      >
        {result.isResult ? (
          <StyledResult>
            <Icons.OkBigIcon />
            <H2>{RESULT_TEXT.SUCCESS}</H2>
            <Text lineHeight="24px">
              Ваша заявка на заказ{' '}
              <Text lineHeight="24px" fontWeightBold>
                {DEVICE_PLURAL[detailedDeviceData.type.toLowerCase()] ?? ''}{' '}
                {detailedDeviceData.vendorName} {detailedDeviceData.name}
              </Text>{' '}
              принята.
            </Text>
            <br />
            <br />
            <Text lineHeight="24px">
              Мы свяжемся с вами, чтобы уточнить детали заказа по телефону{' '}
              <Text lineHeight="24px" fontWeightBold>
                {phone}
              </Text>
              .
            </Text>
          </StyledResult>
        ) : (
          <DeviceDetailedCard />
        )}
      </SidePage>
    </StyledSP>
  );
};

export default observer(DeviceDetailedCardSP);
