/** interfaces */
import { Channel, Highlightable } from '../../../interfaces';

/** поля блока */
export interface ChannelCardFieldsProps extends Highlightable {
  channel: Channel;
  onClick?: (channel: Channel) => void;
  isConnection?: boolean;
  disabled?: boolean;
  /** Каналы включённые в продукт */
  allChannelsIncludedInProduct?: Channel[];
  /** Трансформер в блоке Телевидение */
  isTransformer?: boolean;
}

export enum PictureClarityFormat {
  SD = 'SD',
  HD = 'HD',
  FourK = 'UHD',
}
