/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** Стили */
import { StyledFooter } from './styles';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useAnnualProductRenewalStore from './store/useAnnualProductRenewalStore';

const AnnualProductRenewalWizardFooter: FC = () => {
  const {
    pab2cBalanceStore: { chatleBudgetSaldo },
    authStore: { auth, isTemporaryTokenAuth },
    prolongationStore: {
      prolongationInfo,
      isLoadingProlongate,
      agreement,
      prolongateProduct,
    },
    contractInfoStore: { getWeburgMeContractInfo },
  } = useRootStore();

  const { seriesName, setResult } = useAnnualProductRenewalStore();

  /** На балансе достаточно денег для продления годового продукта */
  const isAmountToRenew =
    auth.balance + chatleBudgetSaldo - prolongationInfo?.prolongation?.price >=
    0;

  return (
    <StyledFooter>
      <Button
        type="button"
        onClick={() =>
          prolongateProduct(setResult, seriesName, getWeburgMeContractInfo)
        }
        disabled={!agreement || !isAmountToRenew || isTemporaryTokenAuth}
        loading={isLoadingProlongate}
      >
        Продлить продукт
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(AnnualProductRenewalWizardFooter);
