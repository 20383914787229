/** libraries */
import { types, Instance } from 'mobx-state-tree';

const VacationStateModel = types
  .model({
    isChecked: (types.boolean, false),
    isVacationConnectionOpen: (types.boolean, false),
    isVacationShutdownOpen: (types.boolean, false),
  })
  .actions((self) => {
    return {
      setIsChecked: (checked) => {
        self.isChecked = checked;
      },
      setIsVacationConnectionOpen: (isVacationConnectionOpen) => {
        self.isVacationConnectionOpen = isVacationConnectionOpen;
      },
      setIsVacationShutdownOpen: (isVacationShutdownOpen) => {
        self.isVacationShutdownOpen = isVacationShutdownOpen;
      },
    };
  });

export default VacationStateModel;
export type IVacationStateStore = Instance<typeof VacationStateModel>;
