import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledAllowAccess = styled.div`
  .allow-access {
    &__info {
      margin-bottom: 16px;
      > span {
        width: 100%;
      }
      &.ban {
        margin-bottom: 24px;
      }
    }

    &__warning {
      background: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 32px;
    }

    &__input {
      display: flex;
      flex-direction: column;
      &__inlet {
        margin: 8px 0 0;
        input {
          width: 100%;
        }
        > div[data-test-error] {
          > span {
            line-height: 24px;
          }
        }
      }

      &__info {
        margin-top: 16px;
      }
    }
  }

  .result-icon {
    svg {
      margin-bottom: 26px;
    }
  }

  @media (min-width: ${desktop940}px) {
    .allow-access {
      &__info {
        &.ban {
          margin-bottom: 32px;
        }
      }
      &__input {
        &__inlet {
          width: 330px;
          input {
            width: 330px;
          }
        }
      }
    }
  }
`;
