/** Библиотеки */
import React, { FC } from 'react';

/** Компоненты */
import { H3, Icon, Icons } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledCallbackWizardSuccess } from './CallbackWizardSuccess.style';

/**
 * Блок об успешном заказе обратного звонка
 * @returns {React.FC}
 */
const CallbackWizardSuccess: FC = () => (
  <StyledCallbackWizardSuccess>
    <div className="callbackWizardSuccess__icon">
      <Icon icon={<Icons.SuccessIconAnimated />} />
    </div>
    <H3>Спасибо, заявка принята.</H3>
    <H3> Мы свяжемся с вами в ближайшее время.</H3>
  </StyledCallbackWizardSuccess>
);

export default CallbackWizardSuccess;
