import styled from '@emotion/styled';
import { defaultTheme, Switcher } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop400,
  desktop500,
  desktop650,
  desktop750,
  desktop940,
} from '~/components/Grid/constants';
import { StyledFillableScale } from '~/components/Blocks/Shared/FillableScale/style';

export const StyledTelevision = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    min-height: 640px;
    height: 660px;
    overflow-x: hidden;
    background: ${defaultTheme.colors.white};
    padding: 32px 32px 0;
    overflow-x: hidden;
  }

  > div::-webkit-scrollbar {
    width: 5px;
  }

  > div::-webkit-scrollbar-track {
    background-color: ${defaultTheme.colors.white};
    border-radius: 10px;
  }

  > div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${defaultTheme.colors.lightGrey};
  }

  .television {
    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
      }

      &__search {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 32px;
      }

      &__switcher {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        > span {
          font-weight: 500;
        }
      }
    }

    &__search-icon {
      height: 32px;
      cursor: pointer;
    }

    &__text {
      width: 438px;
      margin-top: 7px;
    }

    &__underhead {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 32px;
      &__tabs {
        width: 100%;
        margin-bottom: 16px;
        > div {
          width: 100%;
          & > div {
            width: 100%;
          }
          .active {
            background-color: ${defaultTheme.colors.black};
            > span {
              color: ${defaultTheme.colors.white} !important;
            }
          }
        }
      }

      &__checkbox {
        width: 261px;
        .checkMark {
          height: 24px;
          width: 24px;
        }
      }
    }

    &__hidden {
      display: none;
      height: 0;
      overflow: hidden;
    }
  }

  .my-tv {
    &__tab-block {
      margin-top: 32px;
      &__block {
        display: flex;
        flex-direction: column;

        &__count {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 16px;
        }

        &__category {
          font-size: 20px;
          line-height: 24px;
          margin-right: 16px;
        }

        &__cards {
          &.viewport {
            > div:first-of-type,
            > div:last-of-type {
              position: absolute;
            }
          }

          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr;
          flex-wrap: wrap;
          margin-bottom: 36px;
          width: auto;
        }

        &__not-found {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          &__first-text {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 16px;
          }

          &__second-text {
            width: 360px;
          }

          &__third-text {
            width: 457px;
          }

          &__transformer-text {
            width: auto;
          }
        }

        &__transformer {
          display: flex;
          flex-direction: column;
          margin-bottom: 32px;

          &__block {
            display: flex;
            flex-direction: column;
            width: auto;
            margin: 0 0 16px 0;
          }

          &__header {
            font-size: 20px;
            margin-bottom: 7px;
          }

          button {
            margin-right: 16px;
            padding: 12px 13px;
          }

          a {
            letter-spacing: -0.03em;
          }
        }
      }
    }

    &__search {
      margin-top: 32px;
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 38px;

        &__close-icon {
          cursor: pointer;
          margin-left: 24px;
        }
      }
    }
  }

  @media (min-width: ${desktop400}px) {
    .television {
      &__header {
        &__switcher {
          width: auto;
          justify-content: flex-start;
        }
      }
    }
  }

  @media (min-width: ${desktop500}px) {
    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            grid-template-columns: 1fr 1fr 1fr;
          }
          &__transformer {
            button {
              padding: 12px 38px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop650}px) {
    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop750}px) {
    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .television {
      &__header {
        flex-wrap: nowrap;

        &__search {
          margin-bottom: 0;
        }
      }

      &__underhead {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 54px;
        margin-top: 24px;

        &__tabs {
          width: 247px;
          margin: 0 41px 0 0;
          > div {
            > div:first-of-type {
              padding: 10px 16px 10px 32px;
            }
            > div:nth-of-type(2) {
              padding: 10px 32px 10px 16px;
            }
            .active {
              padding: 10px 32px !important;
              > span {
              }
            }
          }
        }

        &__select {
          width: 208px;
          margin: 0 24px 0 26px;
          .select-wrapper {
            width: 208px;
          }
          .select-overlay {
            width: 100% !important;
          }
        }
      }
    }

    .my-tv {
      &__tab-block {
        max-height: 420px;
        overflow-x: hidden;
        margin-right: -25px;
        margin-top: 34px;

        &__block {
          &__count {
            margin-bottom: 24px;
          }

          &__cards {
            display: flex;
            gap: 24px;
            > div {
              width: 264px;

              .channel-package-card {
                width: 264px;
              }
              .channel-package-card__duplicate-wrapper {
                width: 264px;
                .channel-package-card__duplicate {
                  width: 264px;
                }
              }
            }
          }

          &__transformer {
            flex-direction: row;
            margin-bottom: 40px;

            &__block {
              width: 260px;
              margin: 0 24px 0 0;
            }

            ${StyledFillableScale} {
              width: 264px;
            }
          }

          &__not-found {
            &__transformer-text {
              width: 700px;
            }
          }
        }
      }

      &__tab-block::-webkit-scrollbar {
        width: 5px;
      }

      &__tab-block::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 10px;
      }

      &__tab-block::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${defaultTheme.colors.lightGrey};
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .television {
      &__header {
        h2 {
          font-size: 32px;
          line-height: 40px;
        }
      }

      &__underhead {
        &__tabs {
          width: 255px;
          margin: 0 93px 0 0;
        }
        &__select {
          width: 268px;
          margin: 0 24px 0 86px;
          .select-wrapper {
            width: 268px;
          }
        }
      }
    }

    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            > div {
              width: 324px;
              .channel-package-card {
                width: 324px;
              }
              .channel-package-card__duplicate-wrapper {
                width: 324px;
                .channel-package-card__duplicate {
                  width: 324px;
                }
              }
            }
          }

          &__transformer {
            &__block {
              width: 324px;
            }

            ${StyledFillableScale} {
              width: 324px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .television {
      &__underhead {
        &__tabs {
          width: 247px;
          margin: 0 59px 0 0;
        }
        &__select {
          width: 226px;
          margin: 0 24px 0 350px;
          .select-wrapper {
            width: 226px;
          }
        }
      }
    }

    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            > div {
              width: 282px;
              .channel-package-card {
                width: 282px;
              }
              .channel-package-card__duplicate-wrapper {
                width: 282px;
                .channel-package-card__duplicate {
                  width: 282px;
                }
              }
            }
          }

          &__transformer {
            &__block {
              width: 282px;
            }

            ${StyledFillableScale} {
              width: 282px;
            }
          }
        }
      }
    }
  }
`;

export const StyledSwitcher = styled(Switcher)`
  cursor: default;
  pointer-events: none;

  .wrapper {
    height: 30px;
    width: 46px;
    margin: 0;

    .slider {
      cursor: pointer;
      &__button {
        height: 24px;
        width: 24px;
        background-color: ${defaultTheme.colors.planeta};
      }
    }

    input {
      &:disabled ~ .slider .slider__button {
        background-color: ${defaultTheme.colors.planeta};
      }
      &:checked ~ .slider .slider__button > span {
        visibility: hidden;
      }
    }
  }
`;

export const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 32px;
  margin: 0 3px;
  border-radius: 50px;

  @media (min-width: ${desktop400}px) {
    margin: 0 16px;
  }

  @media (min-width: ${desktop940}px) {
    margin: 0 24px;
  }
`;
