/** библиотеки */
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { deviceDetect, isIOS, isAndroid } from 'react-device-detect';
import { observer } from 'mobx-react';

/** компоненты */
import SEO from '~/components/Providers/SEO';
import FeedPage from '~/components/Feed/FeedPage';
import Auth from '~/components/Auth';

/** api */
import {
  getCities,
  getContactPhones,
  getFeedBySlug,
  getFeeds,
  getMenu,
  getAnnouncementsByCount,
} from '~/api/api';

/** утилиты */
import { getEnhancedInfo } from '~/components/Blocks/BlockSelector.utils';
import { generateRSSFeed } from '~/utils/utils';
import winkDeviceRedirect from '~/utils/winkDeviceRedirect';

/** интерфейсы */
import { PageProps } from '~/interfaces/Page.interface';
import { ProviderData } from '~/components/Providers/ProviderData.types';
import { FeedInfo } from '~/components/Feed/FeedPage.types';
import { OAuthServices } from '~/components/Auth/interfaces';
/** constants */
import {
  AUTH_SLUG,
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  ERROR_SLUG,
  IGROVOY_SLUG,
  Pab2cSlugs,
  WINK_REDIRECT_SLUG,
} from '~/constants/common';
import { DEMAND_QP } from '~/components/Blocks/Templates/Pab2c/Help/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
/** hooks */
import { useAuthDataFetching } from '~/components/Feed/useAuthDataFetching/useAuthDataFetching';

export const Page: NextPage<PageProps> = ({
  feed,
  menu,
  preview,
  error,
  errorSlug,
  slug,
  pab2cSlugs,
  providerData,
  suggestedCity,
}: PageProps) => {
  useAuthDataFetching();
  const {
    authStore: { auth, isOAuth, isAuth },
    cityStore: { city, setCities, setPhones, cityUpdate },
  } = useRootStore();
  const { isVisible, toggleVisible } = useMakeAuthStore();
  useEffect(() => {
    setCities(providerData.cities);
    setPhones(providerData.contactPhones);
    cityUpdate(suggestedCity, auth.cityId);
  }, [providerData, suggestedCity, auth.cityId]);

  const router = useRouter();

  if (slug === WINK_REDIRECT_SLUG) {
    winkDeviceRedirect(deviceDetect().model, isIOS, isAndroid);
    return <></>;
  }

  if (!isAuth && router?.query?.[DEMAND_QP] && !isVisible) {
    toggleVisible();
  }

  if (slug === AUTH_SLUG) {
    return <Auth />;
  }

  const showErrorPage =
    feed.status ??
    error ??
    (feed.cities?.length && !feed.cities.includes(city.id)) ??
    (slug === IGROVOY_SLUG && !isOAuth.get(OAuthServices.IGROVOY));

  const cleanSlug = slug.replace(/%2F/g, '/');

  const isPab2cSlug = new RegExp(
    `^${Pab2cSlugs.MAIN_SLUG}((?!\/plug[/]?$)(\/[\\w\\d?]*)+[^/]?[/]?)?$`,
    'g',
  ).test(cleanSlug);

  const getFeedContent = (): FeedInfo => {
    if (showErrorPage) return errorSlug;
    if (isPab2cSlug)
      return isAuth ? pab2cSlugs[cleanSlug] : pab2cSlugs[Pab2cSlugs.PLUG_SLUG];
    return feed;
  };

  const feedContent = getFeedContent();

  const title = showErrorPage
    ? feedContent.fields?.title ?? DEFAULT_TITLE
    : feedContent.announcement?.title ??
      feedContent.fields?.title ??
      DEFAULT_TITLE;

  const description = showErrorPage
    ? feedContent.fields?.description ?? DEFAULT_DESCRIPTION
    : feedContent.announcement?.description ??
      feedContent.fields?.description ??
      DEFAULT_DESCRIPTION;

  return (
    <>
      <SEO title={title} description={description} />
      <FeedPage feed={feedContent} menu={menu} preview={preview} />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const originalFeeds = await getFeeds();

  if (!originalFeeds) return { paths: [], fallback: true };

  const paths = originalFeeds.map(({ slug }) => ({
    params: { slug: slug.split('/') },
  }));

  /** Генерация RSS-потока */
  const articles = await getAnnouncementsByCount();
  generateRSSFeed(articles.announcements);

  return { paths, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps = async ({
  params,
  preview = null,
}) => {
  const isIndexSlug = params.slug?.toString() === 'index';

  const slug = params.slug === undefined ? 'index' : params.slug;

  const fullSlug = Array.isArray(slug)
    ? slug.map((s) => encodeURIComponent(s)).join('/')
    : encodeURIComponent(slug);

  let enhancedFeed = {};
  const providerData: ProviderData = {};

  const pab2cPromises = [Pab2cSlugs.PLUG_SLUG, fullSlug as string].map((item) =>
    getFeedBySlug(item, preview),
  );

  const [errorSlug, pab2cPlugSlug, pab2cSlug] = await Promise.all([
    getFeedBySlug(ERROR_SLUG, preview),
    ...pab2cPromises,
  ]);

  const enhancedFeedPub2cSlug = await getEnhancedInfo(pab2cSlug);

  const pab2cSlugs = {
    [Pab2cSlugs.PLUG_SLUG]: pab2cPlugSlug,
    [fullSlug]: enhancedFeedPub2cSlug,
  };

  try {
    if (!isIndexSlug) {
      const originalFeed = await getFeedBySlug(fullSlug, preview);
      enhancedFeed = await getEnhancedInfo(originalFeed, preview);
    }
    const [cities, contactPhones] = await Promise.all([
      getCities(),
      getContactPhones(),
    ]);

    providerData.cities = cities.map((item) => {
      let satellite = null;
      switch (item.name) {
        case 'Нижний Тагил':
          satellite = 1;
          break;
        case 'Екатеринбург':
          satellite = -1;
          break;
        case 'Каменск-Уральский':
          satellite = 2;
          break;
        default:
          break;
      }
      return {
        ...item,
        satellite,
      };
    });
    providerData.contactPhones = contactPhones;

    const menu = await getMenu(preview);
    const enhancedMenu = await getEnhancedInfo(menu);

    return {
      revalidate: Number(process.env.REVALIDATE_STATIC_PERIOD),
      props: {
        feed: enhancedFeed,
        slug: fullSlug,
        providerData,
        menu: enhancedMenu,
        preview,
        error: null,
        errorSlug,
        pab2cSlugs,
      },
    };
  } catch (e) {
    return {
      revalidate: Number(process.env.REVALIDATE_STATIC_PERIOD),
      props: {
        feed: enhancedFeed,
        slug: fullSlug,
        providerData,
        menu: {},
        preview,
        error: e ?? null,
        errorSlug,
        pab2cSlugs,
      },
    };
  }
};

export default observer(Page);
