/** Библиотеки */
import * as React from 'react';
import Link from 'next/link';
/** Типы */
import { Link as CordisLink, defaultTheme } from 'cordis-core-ui-planeta';
import { SubFolder } from '../MenuPopup.types';

/** утилиты */
import { isExternal } from '~/utils/utils';

/**
 * Компонент создаёт ссылку из списка подраздела
 * @returns {React.FC}
 */
const SubFolderItem: React.FC<SubFolder> = (subFolder: SubFolder) => {
  return isExternal(subFolder.href) ? (
    <CordisLink
      color={defaultTheme.colors.black}
      href={decodeURIComponent(subFolder.href)}
      target="_blank"
    >
      {subFolder.name}
    </CordisLink>
  ) : (
    <Link href={subFolder.href}>
      <span>
        <CordisLink
          color={defaultTheme.colors.black}
          href={decodeURIComponent(subFolder.href)}
          onClick={subFolder.toggleHandler}
        >
          {subFolder.name}
        </CordisLink>
      </span>
    </Link>
  );
};

export default SubFolderItem;
