import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1280,
  desktop1100,
  desktop940,
} from '~/components/Grid/constants';

export const StyledForwarding = styled.div`
  .forwarding {
    &__not-found {
      > span {
        display: block;
        margin-bottom: 16px;
      }
    }
    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__error,
    &__not-found,
    &__loader {
      margin-top: 24px;
    }

    &__description {
      margin: 1px 0 39px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      &__line {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid ${defaultTheme.colors.line};
        padding-bottom: 24px;
        margin-bottom: 24px;
        > div {
          margin-bottom: 16px;
        }
        &__column {
          display: flex;
          flex-direction: column;
        }
        &__row {
          display: flex;
          align-items: center;
        }
        &__number {
          font-weight: 500;
          margin-bottom: 4px;
        }
      }
    }

    &__block::-webkit-scrollbar {
      width: 2px;
    }

    &__block::-webkit-scrollbar-track {
      background-color: ${defaultTheme.colors.white};
      border-radius: 6px;
    }

    &__block::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: ${defaultTheme.colors.disable};
    }

    &__pointer {
      cursor: pointer;
    }
  }

  @media (min-width: ${desktop940}px) {
    .forwarding {
      &__loader {
        height: 255px;
      }

      &__block {
        max-height: 194px;
        overflow-x: hidden;
        margin-right: -8px;
        padding-right: 8px;
        &__line {
          display: grid;
          gap: 24px;
          grid-template-columns: 236px 160px 84px;
          padding-bottom: 12px;
          margin-bottom: 12px;
          > div {
            margin-bottom: 0;
          }
          &__number {
            margin-bottom: 1px;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .forwarding {
      &__block {
        &__line {
          gap: 40px;
          grid-template-columns: 314px 160px 84px;
          &__number {
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .forwarding {
      &__block {
        &__line {
          gap: 40px;
          grid-template-columns: 434px 160px 84px;
          &__number {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
`;
