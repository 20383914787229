import { ReactNode, useContext, useLayoutEffect } from 'react';
import {
  createProductSwitcherStore,
  IProductSwitcherStore,
} from './ProductSwitcherStore';
import { MobXProviderContext, Provider, useLocalObservable } from 'mobx-react';
import { ProductSwitcherFields } from '../interfaces';
import { DefaultImgs } from '../../Device/Device.types';

interface DeviceProviderProps {
  children: ReactNode;
  fields: ProductSwitcherFields;
  defaultImgs: DefaultImgs[];
}

export const ProductSwitcherStoreProvider = ({
  children,
  fields,
  defaultImgs,
}: DeviceProviderProps): JSX.Element => {
  const store = useLocalObservable(() =>
    createProductSwitcherStore(fields, defaultImgs),
  );

  /** Обновить стор при изменении шаблона блока */
  useLayoutEffect(() => {
    store.setFields(fields);
  }, [fields.sample]);

  return <Provider ProductSwitcherStore={store}>{children}</Provider>;
};

const useProductSwitcherStore = (): IProductSwitcherStore => {
  const { ProductSwitcherStore } = useContext(MobXProviderContext);
  return ProductSwitcherStore;
};

export default useProductSwitcherStore;
