import styled from '@emotion/styled';
import { desktop1100 } from '~/components/Grid/constants';
import { PlayerCircle } from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/AudioPlayer/style';

export default styled.div`
  width: 360px;
  background: transparent;
  padding: 41px 47px 41px 38px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  audio {
    position: relative;
    left: -22px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 466px;
  }
`;

export const Header = styled.span`
  z-index: 2;
  margin: 24px 0 32px 0;
`;

export const VideoPlayer = styled.div`
  position: relative;

  ${PlayerCircle} {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 21px);
    left: calc(50% - 21px);
    z-index: 999;
  }
`;
