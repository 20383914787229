/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop940,
  desktop900,
  desktop520,
  desktop500,
  desktop350,
} from '~/components/Grid/constants';

/** типы */
import { StyledDeviceProps } from './Device.types';

/** стилевой компонент оборудования */
export const StyledDevice = styled.div<StyledDeviceProps>`
  ${({ small = false, background, backgroundMobile }): string => `
    padding: 32px 32px 16px 32px;
    background-image: url(${backgroundMobile});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    background-position: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media(min-width: ${desktop900}px) { 
      background-image: url(${background});
      align-items: flex-start;
    }

    @media(min-width: ${desktop940}px) {
      height: 400px;
    }

    .header {
      text-align: center;
      max-width: 410px;

      @media(min-width: ${desktop900}px) {
        text-align: left;
      }
    }

    .device {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      &__text {
        text-align: center;
      }
      
      @media(min-width: ${desktop900}px) {
        align-items: flex-start;      
        text-align: left;  

        &__text {
          text-align: left;
        }
      }

      &__preview {
        margin-bottom: 16px;


        &__imageWrapper {
          width: 120px;
          height: 110px;
          position: relative;

        }
      }
    }
    .rate {
      margin-bottom: 6px;
      width: 220px;
      ${small ? 'display: flex;' : ''}
      ${small ? 'flex-direction: column;' : ''}

      @media(min-width: ${desktop350}px) {
        width: 300px;
       }
      @media(min-width: ${desktop500}px) {
        width: 400px;
       }
      @media(min-width: ${desktop900}px) {
        width: ${small ? '400px' : '900px'}
       }

      .value,
      &__star {
        margin-bottom: 0;
        color: ${defaultTheme.colors.white};
        text-align: center;
        
        @media(min-width: ${desktop900}px) {
          text-align: left;
        }
      }

      &__description {
        color: ${defaultTheme.colors.white};
        opacity: 0.4;
        text-align: center;
        width: 100%;
        
        @media(min-width: ${desktop900}px) {
          text-align: left;
        }
      }

      &__wrapper {
        min-height: 32px;
      }

      &__tabs {
        position: relative;
        height: 22px;
        overflow: hidden;
        margin-bottom: 8px;
        left: 0;

        @media(min-width: ${desktop500}px) {
          left: 60px;
        }
        @media(min-width: ${desktop900}px) {
          left: 0;
        }

        /* переопределяет стили для стандартных цветов вкладок */
        span {
          color: ${defaultTheme.colors.white};
          opacity: 0.4;
          white-space: nowrap;
        }

        & > div {
          position: absolute;
          left: 0;
          transition: left .5s ease;
        }
  
        div {
          ${small ? 'justify-content: center;' : ''};
          justify-content: center;
          
          div:hover:not(.active) span {
            opacity: 0.7;
            color: ${defaultTheme.colors.white};
          }
          
          @media(min-width: ${desktop520}px) {
            justify-content: flex-start;
          }
          
          @media(min-width: ${desktop940}px) {
            justify-content: center;
          }
        }

        .active {
          span {
            opacity: 1;
          }
        }
      }

      &__star {
        h3 {
          display: inline-block;
        }
        .star {
          vertical-align: top;
        }
      }
    }
  `}
`;
