/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop475,
  desktop750,
  desktop880,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

import { IMAGES } from '../constants';

export const StyledBonusProgramm = styled.div`
  min-height: 400px;
  padding: 32px;
  padding-top: 28px;
  background: ${defaultTheme.colors.snow} no-repeat;
  background-image: url(${IMAGES.pluses475});
  background-position: bottom;
  background-size: 475px;

  .title {
    margin-bottom: 16px;
    max-width: 157px;
    font-weight: 700;
  }

  .title__wide {
    margin-bottom: 26px;
  }

  .text__wide {
    max-width: 384px;
  }

  .text__240 {
    display: inline-block;
    max-width: 176px;
    font-weight: 500;
  }

  .text,
  .title__wide,
  .text__wide {
    display: none;
  }

  @media (min-width: ${desktop475}px) {
    .title {
      max-width: unset;
    }
    background-image: url(${IMAGES.plusesMbWide});
    background-size: auto;
  }

  @media (min-width: ${desktop750}px) {
    .title,
    .text,
    .text__240 {
      display: none;
    }

    .title__wide {
      display: block;
    }

    .text__wide {
      display: inline-block;
    }
  }

  @media (min-width: ${desktop880}px) {
    background-position: right bottom;
  }

  @media (min-width: ${desktop940}px) {
    .title__wide,
    .text__wide {
      display: none;
    }

    .title {
      display: block;
      margin-bottom: 10px;
    }

    .text {
      display: inline-block;
    }

    background-image: url(${IMAGES.pluses});
  }

  @media (min-width: ${desktop1100}px) {
    .title {
      margin-bottom: 16px;
    }
    background: url(${IMAGES.pluses340}) left bottom no-repeat;
  }

  @media (min-width: ${desktop1280}px) {
    background: url(${IMAGES.pluses400}) left bottom no-repeat;
  }
`;
