import styled from '@emotion/styled';

export const StyledSoftlineUnsubscribeSuccess = styled.div`
  margin-top: -51px;

  h2 {
    margin-bottom: 32px;
  }

  .unsubscribe {
    &__content-icon {
      margin-bottom: 24px;
    }
  }
`;
