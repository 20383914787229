/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop440,
  desktop900,
  desktop1280,
  desktop400,
  desktop940,
} from '~/components/Grid/constants';

export const StyledMyStoriesContest = styled.div<{ size: number }>`
  ${({ size }): string => {
    return `
      display: flex;
      flex-direction: column;
      height: auto;
      min-height: 400px;
      width: 100%;
      background: #9a2358;
      border-radius: 50px;

      .my-stories-contest {
        &__block-x1 {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding: 32px 32px 30px;

          h2 {
            margin: 24px 0;
          }

          h3 {
            margin: 16px 0;
          }
        }

        &__tabs {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > div {
            margin-right: 8px;
          }
        }

        &__wrapper-swipe-indicator {
          width: 100%;
          height: 96px;
          ${size !== 1 && 'order: 2'};
          ${size === 1 && 'margin-bottom: 16px'};
        }

        &__wrapper-swipe-indicator-mobile {
          height: ${size !== 1 ? '152px' : '96px'};
        }

        &__text {
          ${size !== 1 && 'order: 1'};
          margin-bottom: 36px;
        }

        &__block-x2 {
          height: 100%;
          max-width: 432px;
          padding: 32px 32px 30px 32px;
        }

        &__auth {
          display: flex;
          flex-direction: column;

          > button {
            width: 134px;
            margin-top: 32px;
          }
        }

        &__answer {
          display: flex;
          flex-direction: column;

          > h2 {
            margin: 16px 0;
          }

          > h3 {
            max-width: 400px;
            margin-top: 32px;
            margin-bottom: 24px;
          }

          &__textareaWrapper {
            position: relative;
            span {
              width: 100%;
            }
            textarea {
              width: 100%;
              height: 145px;
              margin: 8px 0 0 0;
            }

            &__textareaError {
              position: absolute;
              color: ${defaultTheme.colors.white};
            }
          }

          button {
            width: 240px;
            margin-top: 50px;
            letter-spacing: -0.03em;
          }
        }

        &__repost {
          display: flex;
          flex-direction: column;

          h2 {
            margin: 16px 0;
          }

          &__text {
            margin-bottom: 32px;
          }

          &__icons {
            display: flex;
            margin-top: 16px;

            &__icon {
              position: relative;
              width: 48px;
              height: 48px;
              border-radius: 50px;
              cursor: pointer;

              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            &__vk {
              background: #3076f6;
              margin-right: 16px;
            }

            &__ok {
              background: #dd7940;
            }
          }
        }
      }

      .swipe-indicator {
        position: relative;
        display: flex;
        height: inherit;
        align-items: center;

        &__circle_img {
          position: absolute;
          left: 0;
        }

        &__circle {
          display: inline-block;
          background: ${defaultTheme.colors.pink};
          opacity: 0.5;
          width: 96px;
          height: 96px;
          border-radius: 50px;
        }

        &__image {
          position: absolute;
          left: 14px;
          top: 17px;
        }

        &__text_on_circle {
          position: absolute;
          left: 25px;
        }

        &__text {
          position: absolute;
          left: 167px;
          top: 24px;
          width: 125px;
        }
      }

      @media (min-width: ${desktop400}px) {
        .my-stories-contest {
          &__wrapper-swipe-indicator {
            width: 400px;
          }
        }
      }

      @media (min-width: ${desktop440}px) {
        .my-stories-contest {
          &__block-x1 {
            width: ${size === 1 ? '100%' : '400px'};
          }

          &__tabs {
            flex-wrap: nowrap;
          }

          &__answer {
            button {
              margin-top: 32px;
            }
          }
        }
      }

      @media (min-width: ${desktop900}px) {
        height: ${size === 1 ? '400px' : '500px'};
        flex-direction: row;

        .my-stories-contest {
          &__block-x1 {
            h2 {
              margin: 16px 0;
            }
          }
          &__block-x2 {
            max-width: none;
            width: 100%;
            padding: 32px 0 30px 32px;
          }
          &__wrapper-swipe-indicator {
            order: inherit;
            margin-bottom: 16px;
          }

          &__text {
            order: inherit;
            margin-bottom: 0;
          }

          &__auth {
            &__text {
              margin-top: 40px;
            }
          }

          &__answer {
            &__textareaWrapper {
              textarea {
                width: 400px;
                height: 80px;
              }
            }
          }

          &__repost {
            padding-right: 32px;
          }
        }
      }

      @media (min-width: ${desktop1280}px) {
        height: 400px;
        .my-stories-contest {
          &__answer {
            &__textareaWrapper {
              textarea {
                margin: 32px 0 0;
              }
            }
          }
        }
      }
    `;
  }};
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: center;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }

    .snoska {
      margin-top: 25px;
    }
  }
`;

export const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tag-button {
    margin-right: 8px;
    margin-bottom: 16px;

    .tagButton__text {
      color: ${defaultTheme.colors.pink};
      border: 2px solid ${defaultTheme.colors.pink};
      padding: 8px 16px;
      border-radius: 20px;
      display: inline-flex;
    }
    .tagButton__checkMark {
      display: none;
    }

    .tagButton__text:hover {
      color: ${defaultTheme.colors.pink};
      border: 2px solid ${defaultTheme.colors.pink};
    }

    input: checked~.tagButton__text {
      background-color: ${defaultTheme.colors.white};
      border: 2px solid ${defaultTheme.colors.white};
    }

    input: checked~.tagButton__text:hover {
      color: ${defaultTheme.colors.planeta};
      background-color: ${defaultTheme.colors.white};
      border: 2px solid ${defaultTheme.colors.white};
    }
  }

  @media (min-width: ${desktop900}px) {
    margin-right: 32px;
  }

  @media (min-width: ${desktop1280}px) {
    .tag-button {
      margin-bottom: 0;
    }
  }
`;
