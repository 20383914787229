import React, { ReactNode, useContext } from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { Provider, MobXProviderContext } from 'mobx-react';
import createStore, { IPab2cSoftlineStore } from './Pab2cSoftlineStore';
import { FieldsProps } from '../interfaces';

interface Pab2cSoftlineProps {
  children: ReactNode;
  fields: FieldsProps;
}

export const Pab2cSoftlineProvider = ({
  children,
  fields,
}: Pab2cSoftlineProps): JSX.Element => {
  const store = useLocalStore<IPab2cSoftlineStore>(() => createStore(fields));
  return <Provider Pab2cSoftlineStore={store}>{children}</Provider>;
};

export const usePab2cSoftlineStore = () => {
  const { Pab2cSoftlineStore } = useContext(MobXProviderContext);
  return Pab2cSoftlineStore;
};
