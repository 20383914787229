import { PLANETA_URL } from '~/constants/common';

export const IMAGES = {
  sharing: `${PLANETA_URL}/static/images/sendwish-sharing.jpg`,
  background: `${PLANETA_URL}/static/images/sendwish-back.png`,
};

export enum ERRORS {
  Over400Characters = 'Пожалуйста, не более 400 символов',
  Error = 'Что-то пошло не так...',
  NotServiced = 'Договор не обслуживается',
}

/* Данные для шаринга в социальных сетях */
export const SOCIAL_DATA = {
  shareUrl: `${PLANETA_URL}/happynewyear`,
  title: 'Делись теплом и получай приятные подарки от Планеты',
};
