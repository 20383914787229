import { FC } from 'react';
/** типы */
import { TextHTMLProps } from './TextHtml.types';
/** утилиты */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** стили */
import { StyledTextHtml } from './TextHtml.style';

const TextHtml: FC<TextHTMLProps> = ({ content }: TextHTMLProps) => {
  const { fields } = content;
  return (
    <StyledTextHtml id={fields.id}>{parseHtml(fields.content)}</StyledTextHtml>
  );
};

export default TextHtml;
