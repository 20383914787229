/** libraries */
import React, { FC } from 'react';
import { Button, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** style */
import { StyledFooter } from './styles';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface CancelingNumberDeactivationFooterProps {
  disable: () => Promise<void>;
  isLoading: boolean;
}

const CancelingNumberDeactivationFooter: FC<CancelingNumberDeactivationFooterProps> = ({
  disable,
  isLoading,
}: CancelingNumberDeactivationFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  if (isTemporaryTokenAuth) {
    return (
      <StyledFooter>
        <Button disabled>Отменить</Button>
        <Text
          style={{ marginLeft: '10px' }}
          color={defaultTheme.colors.warning}
        >
          Действие доступно только клиенту
        </Text>
      </StyledFooter>
    );
  }
  return (
    <StyledFooter>
      <Button onClick={disable} loading={isLoading}>
        Отменить отключение
      </Button>
    </StyledFooter>
  );
};

export default observer(CancelingNumberDeactivationFooter);
