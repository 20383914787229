import { Instance, types } from 'mobx-state-tree';
import { MY_APPEALS_STATE } from '~/components/Blocks/Templates/Pab2c/Help/interfaces';

const MyAppealsModel = types
  .model('MyAppeals', {
    isShowMyAppeals: (types.boolean, false),
    /** Клиент прочитал новые комментарии */
    isRead: (types.boolean, false),
    typeOfApplication: types.maybeNull(types.string),
    state: (types.string, MY_APPEALS_STATE.MAIN),
  })
  .actions((self) => ({
    toggleMyAppealsSP: () => {
      self.isShowMyAppeals = !self.isShowMyAppeals;
    },
    setIsShowMyAppeals: (isShowMyAppeals) => {
      self.isShowMyAppeals = isShowMyAppeals;
    },
    setIsRead: (isRead) => {
      self.isRead = isRead;
    },
    setTypeOfApplication: (typeOfApplication) => {
      self.typeOfApplication = typeOfApplication;
    },
    setState: (state) => {
      self.state = state;
    },
  }));

export default MyAppealsModel;
export type IMyAppealsStore = Instance<typeof MyAppealsModel>;
