/** libraries */
import { FC } from 'react';
import {
  LeadingText,
  Snoska,
  Tag,
  defaultTheme,
  Text,
  Icons,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledAppeal } from './styles';
/** interfaces */
import { APPEAL_STATUS } from '../../interfaces';
/** utils */
import { getDate, parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { pluralizeAll } from '~/utils/utils';
/** constants */
import { NEW } from '../../constants';

interface AppealProps {
  number: number;
  status: string;
  date: string;
  text: string;
  unreadCommentCount: number;
}

/** Карточка обращения */
const AppealCard: FC<AppealProps> = ({
  number,
  status,
  date,
  text,
  unreadCommentCount,
}: AppealProps) => {
  const statusBackColor = () => {
    if (unreadCommentCount > 0) return defaultTheme.colors.green;
    switch (status) {
      case APPEAL_STATUS.IN_WORK:
        return defaultTheme.colors.green;
      case APPEAL_STATUS.RAW:
        return defaultTheme.colors.planeta;
      case APPEAL_STATUS.CLOSED:
        return defaultTheme.colors.light;
      case APPEAL_STATUS.REJECT:
        return defaultTheme.colors.light;
      default:
        return defaultTheme.colors.white;
    }
  };
  const statusColor = () => {
    if (unreadCommentCount > 0) return defaultTheme.colors.white;
    switch (status) {
      case APPEAL_STATUS.IN_WORK:
        return defaultTheme.colors.green;
      case APPEAL_STATUS.CLOSED:
        return defaultTheme.colors.shadow;
      case APPEAL_STATUS.REJECT:
        return defaultTheme.colors.shadow;
      default:
        return defaultTheme.colors.white;
    }
  };
  const statusName = () => {
    if (unreadCommentCount > 0) return 'Получен ответ';
    switch (status) {
      case APPEAL_STATUS.IN_WORK:
        return 'В работе';
      case APPEAL_STATUS.CLOSED:
        return 'Закрыто';
      case APPEAL_STATUS.REJECT:
        return 'Закрыто';
      case APPEAL_STATUS.RAW:
        return 'Не обработано';
      default:
        return 'Получен ответ';
    }
  };

  const commentText = () => {
    return unreadCommentCount > 0
      ? `${pluralizeAll(unreadCommentCount, NEW, null)}`
      : 'нет новых комментариев';
  };

  return (
    <StyledAppeal>
      <div className="appeal__header">
        <LeadingText
          className="appeal__header__number"
          color={defaultTheme.colors.black}
        >
          № {number}
        </LeadingText>
        <Tag
          color={statusColor()}
          colorTag={statusBackColor()}
          backgroundColor={
            !(status === APPEAL_STATUS.IN_WORK && unreadCommentCount <= 0) &&
            statusBackColor()
          }
        >
          {statusName()}
        </Tag>
      </div>
      <Snoska className="appeal__date" color={defaultTheme.colors.gray}>
        создано {getDate(date)} г. в {getDate(date, 'HH:mm')}
      </Snoska>
      <Text className="appeal__text" lineHeight="24px">
        {parseHtml(text)}
      </Text>
      <div className="appeal__comments">
        <Icons.SmallChatIcon />
        <Snoska color={defaultTheme.colors.shadow}>{commentText()}</Snoska>
      </div>
    </StyledAppeal>
  );
};

export default AppealCard;
