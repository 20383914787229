/** libraries */
import { FC, useMemo, useState } from 'react';
import {
  Button,
  H3,
  Icon,
  Icons,
  LeadingText,
  OptionProp,
  Select,
  Switcher,
  TagButton,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledCostByDestination } from './styles';
/** constants */
import { FOREIGN, LOCAL } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** utils */
import { formatNumber } from '~/utils/utils';
import { outsideClickHelper } from '~/utils/outsideClickHelper';
/** stores */
import useVoiceStore from '../../store/useVoiceStore';

const CostByDestination: FC = () => {
  const { prices } = useVoiceStore();
  const [isForeign, setIsForeign] = useState<boolean>(false);
  /** Местные номера */
  const localPrices = useMemo(() => {
    return prices
      .filter((item) => item.groupCode === LOCAL)
      .map((item) => {
        return {
          label: item.name,
          value: item,
        };
      });
  }, []);
  /** Другие страны */
  const foreignPrices = useMemo(() => {
    return prices
      .filter((item) => item.groupCode === FOREIGN)
      .map((item) => {
        return {
          label: item.name,
          value: item,
        };
      });
  }, []);
  /** Выбранная цена */
  const [selectedPrice, setSelectedPrice] = useState<OptionProp>(
    localPrices[0],
  );
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  /** Вид блока с select в мобильной версии */
  const [isMobileSelectOpen, setIsMobileSelectOpen] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый select начала периода в мобильной версии
  const [candidateForSelect, setCandidateForSelect] = useState<OptionProp>(
    selectedPrice,
  );
  const applyMobileSelect = () => {
    setSelectedPrice(candidateForSelect);
    setIsMobileSelectOpen(false);
  };
  /** Мобильный select */
  const mobileSelect = () => {
    return (
      <StyledCostByDestination>
        <div className="cost__mobile-select">
          <div className="cost__mobile-select__header">
            <LeadingText color={defaultTheme.colors.black}>
              Группа направлений
            </LeadingText>
            <Icon
              icon={<Icons.CloseIcon />}
              onClick={() => {
                setIsMobileSelectOpen(!isMobileSelectOpen);
                setCandidateForSelect(selectedPrice);
              }}
              highlight
            />
          </div>
          {!isForeign &&
            localPrices.map((item) => {
              return (
                <TagButton
                  key={item.label}
                  className="cost__mobile-select__tag-button"
                  onChange={() => setCandidateForSelect(item)}
                  checked={item.label === candidateForSelect.label}
                >
                  {item.label}
                </TagButton>
              );
            })}
          {isForeign &&
            foreignPrices.map((item) => {
              return (
                <TagButton
                  key={item.label}
                  className="cost__mobile-select__tag-button"
                  onChange={() => setCandidateForSelect(item)}
                  checked={item.label === candidateForSelect.label}
                >
                  {item.label}
                </TagButton>
              );
            })}
          <Button onClick={applyMobileSelect}>Применить</Button>
        </div>
      </StyledCostByDestination>
    );
  };

  const selectData = () => {
    return isForeign ? foreignPrices : localPrices;
  };

  const content = () => {
    if (!isDesktop940) {
      return (
        <StyledCostByDestination>
          <div className="cost__block">
            <div className="cost__block__tabs">
              <Text
                className="cost__block__select__header"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Группа направлений
              </Text>
              <div
                className="cost__block__select"
                onClick={() => {
                  if (!isDesktop940) {
                    setIsMobileSelectOpen(true);
                  }
                }}
              >
                <Select
                  onOptionClick={(option) => {
                    setSelectedPrice(option);
                  }}
                  value={selectedPrice ? selectedPrice.value : ''}
                  outsideClickHelper={outsideClickHelper}
                  data={selectData()}
                  width="100%"
                />
              </div>
            </div>
            <div className="cost__block__info">
              {isDesktop940 ? (
                <H3 className="cost__block__info__header">
                  {formatNumber(selectedPrice.value.price)}&nbsp;₽ в&nbsp;минуту
                </H3>
              ) : (
                <Text className="cost__block__info__header" lineHeight="20px">
                  {formatNumber(selectedPrice.value.price)}&nbsp;₽ в&nbsp;минуту
                </Text>
              )}
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                {selectedPrice.value.mem}
              </Text>
            </div>
          </div>
        </StyledCostByDestination>
      );
    }
    return (
      <StyledCostByDestination>
        <div className="cost__block">
          <div className="cost__block__tabs">
            {!isForeign ? (
              <>
                {localPrices.map((item) => {
                  const isSelected = selectedPrice.label === item.label;
                  return (
                    <div
                      className="cost__block__tabs__line"
                      onClick={() => setSelectedPrice(item)}
                      key={item.label}
                    >
                      <Text
                        lineHeight="24px"
                        color={
                          isSelected
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.shadow
                        }
                        fontWeightBold={isSelected}
                      >
                        {item.label}
                      </Text>
                      <Text
                        lineHeight="24px"
                        color={
                          isSelected
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.shadow
                        }
                        fontWeightBold={isSelected}
                      >
                        {formatNumber(item.value.price)}&nbsp;₽
                      </Text>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {foreignPrices.map((item) => {
                  const isSelected = selectedPrice.label === item.label;
                  return (
                    <div
                      className="cost__block__tabs__line"
                      onClick={() => setSelectedPrice(item)}
                      key={item.label}
                    >
                      <Text
                        lineHeight="24px"
                        color={
                          isSelected
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.shadow
                        }
                        fontWeightBold={isSelected}
                      >
                        {item.label}
                      </Text>
                      <Text
                        lineHeight="24px"
                        color={
                          isSelected
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.shadow
                        }
                        fontWeightBold={isSelected}
                      >
                        {item.value.price}&nbsp;₽
                      </Text>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="cost__block__info">
            {isDesktop940 ? (
              <H3 className="cost__block__info__header">
                {formatNumber(selectedPrice.value.price)}&nbsp;₽ в&nbsp;минуту
              </H3>
            ) : (
              <Text className="cost__block__info__header" lineHeight="20px">
                {formatNumber(selectedPrice.value.price)}&nbsp;₽ в&nbsp;минуту
              </Text>
            )}
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              {selectedPrice.value.mem}
            </Text>
          </div>
        </div>
      </StyledCostByDestination>
    );
  };

  if (isMobileSelectOpen && !isDesktop940) {
    return mobileSelect();
  }

  return (
    <StyledCostByDestination>
      <div className="cost__switcher">
        <Text className="cost__switcher__text">Россия</Text>
        <Switcher
          checked={isForeign}
          onClick={() => {
            setSelectedPrice(isForeign ? localPrices[0] : foreignPrices[0]);
            setIsForeign(!isForeign);
          }}
        >
          <Text className="cost__switcher__text">Другие страны</Text>
        </Switcher>
      </div>
      {content()}
    </StyledCostByDestination>
  );
};

export default observer(CostByDestination);
