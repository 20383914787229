export const IMAGES = {
  mic: `${process.env.STATIC_SERVER}/static/advantages/mic.png`,
  plus: `${process.env.STATIC_SERVER}/static/advantages/plus.png`,
  womanPlus: `${process.env.STATIC_SERVER}/static/advantages/womanPlus.png`,
  pluses: `${process.env.STATIC_SERVER}/static/images/pluses.png`,
  pluses340: `${process.env.STATIC_SERVER}/static/images/pluses340.png`,
  pluses400: `${process.env.STATIC_SERVER}/static/images/pluses400.png`,
  pluses475: `${process.env.STATIC_SERVER}/static/images/pluses475.png`,
  plusesMbWide: `${process.env.STATIC_SERVER}/static/images/plusesMbWide.png`,
  pluses880: `${process.env.STATIC_SERVER}/static/images/pluses880.png`,
  timeshiftBg240: `${process.env.STATIC_SERVER}/static/images/timeshiftBg240.png`,
  timeshiftBg280: `${process.env.STATIC_SERVER}/static/images/timeshiftBg280.png`,
  timeshiftBg340: `${process.env.STATIC_SERVER}/static/images/timeshiftBg340.png`,
  timeshiftBg400: `${process.env.STATIC_SERVER}/static/images/timeshiftBg400.png`,
  timeshiftBg880: `${process.env.STATIC_SERVER}/static/images/timeshiftBg880.png`,
  timeshiftArrow: `${process.env.STATIC_SERVER}/static/images/timeshiftArrow.png`,
  gadgets: `${process.env.STATIC_SERVER}/static/images/gadgets.png`,
  gadgets650: `${process.env.STATIC_SERVER}/static/images/gadgets650.png`,
  smartTvBg: `${process.env.STATIC_SERVER}/static/images/smartTvBg.png`,
  smartTvBg240: `${process.env.STATIC_SERVER}/static/images/smartTvBg240.png`,
  smartTvBg340: `${process.env.STATIC_SERVER}/static/images/smartTvBg340.png`,
  smartTvBg400: `${process.env.STATIC_SERVER}/static/images/smartTvBg400.png`,
  smartTvBg650: `${process.env.STATIC_SERVER}/static/images/smartTvBg650.png`,
  smartTvBg880: `${process.env.STATIC_SERVER}/static/images/smartTvBg880.png`,
  fashion280: `${process.env.STATIC_SERVER}/static/images/fashion280.png`,
  fashion: `${process.env.STATIC_SERVER}/static/images/fashion.png`,
  fashion355: `${process.env.STATIC_SERVER}/static/images/fashion355.png`,
  doublecheck: `${process.env.STATIC_SERVER}/static/images/doublecheck.png`,
  doublecheckBig: `${process.env.STATIC_SERVER}/static/images/doublecheckBig.png`,
  deferredPayment: `${process.env.STATIC_SERVER}/static/images/deferredPayment.png`,
};
