import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованная карточка продукта */
export const StyledProductCard = styled.div`
  padding: 16px;
  max-width: 412px;
  .product-desc {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &:hover {
    background: ${defaultTheme.colors.light};
  }
`;
