/** libraries */
import { FC } from 'react';
import { Button } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter } from './styles';
/** constants */
import { SP_STATE } from '~/components/Blocks/Templates/Pab2c/Settings/constants';
/** stores */
import useCreatePasswordStore from './store/useCreatePasswordStore';

const CreatePasswordFooter: FC = () => {
  const {
    setPhoneNumberError,
    setContractNumberError,
    sendingCode,
    state,
    disableCreate,
    confirmCode,
    defineNewPassword,
  } = useCreatePasswordStore();

  const submitCode = () => {
    setPhoneNumberError('');
    setContractNumberError('');
    sendingCode();
  };

  return (
    <StyledFooter>
      {state === SP_STATE.PHONE && (
        <Button onClick={submitCode} disabled={disableCreate}>
          Отправить код
        </Button>
      )}
      {state === SP_STATE.CODE && (
        <Button onClick={confirmCode} disabled={disableCreate}>
          Подтвердить
        </Button>
      )}
      {state === SP_STATE.NEW_PASSWORD && (
        <Button onClick={defineNewPassword} disabled={disableCreate}>
          Создать пароль
        </Button>
      )}
    </StyledFooter>
  );
};

export default observer(CreatePasswordFooter);
