/** библиотеки */
import { useState, FC, useEffect } from 'react';
import * as React from 'react';
import { observer } from 'mobx-react';

/** Компоненты библиотеки */
import {
  Autocomplete,
  Text,
  defaultTheme,
  Input,
  Icon,
  Icons,
  Select,
  OptionProp,
  H1,
  Loader,
  LeadingText,
} from 'cordis-core-ui-planeta';

/** api */
import {
  searchStreetName,
  searchBuildingName,
  GetCitiesBySeriesCode,
} from '~/api/api';
import { getContacts } from '~/api/apiPab2c';

/** утилиты */
import { getPossibility } from './utils';
import { parseHtml } from '../Blocks/Shared/Shared.utils';
import { maskPhone } from '../Blocks/Templates/Pab2c/Help/utils';
import { getClientName } from '~/utils/utils';

/** Компоненты */
import { WizardContainer } from './ConnectionWizard.style';
import WizardConfirm from '~/components/ConnectionWizard/WizardConfirm';

/** типы */
import {
  TCity,
  AutocompleteItem,
  ERROR_MESSAGE,
  ConnectionWizardProps,
} from './ConnectionWizard.types';
import { outsideClickHelper } from '~/utils/outsideClickHelper';
import { useDebouncedEffect } from '~/utils/useDebouncedEffect';
import { City } from '~/interfaces/CityInterface';

/** constants */
import {
  CALL_FROM_SIDEPAGE_KEY,
  POSSIBILITY_KEY,
  TechnicalPossibilityType,
  connectionLabels,
  CONNECTION_TYPE,
  connectionText,
} from './constants';
import { EMAIL_CLASS_ID } from '../Blocks/Shared/ContactsAndNotifications/constants';
import { INPUT_PHONE_VALUE } from '~/constants/common';

/** store */
import useConnectionStore from './store/useConnectionStore';
import { useRootStore } from '~/stores/RootStore';

/**
 * Форма заявки на подключение.
 * Обязательные поля для заполнения: телефон, имя. Правильность ввода проверяется валидацией.
 * Дополнительная возможность - указание адреса и проверки доступности подключения.
 */
const ConnectionWizard: FC<ConnectionWizardProps> = ({
  isInCTA,
  comment,
  tariff,
  seriesCode,
}) => {
  const {
    cityStore: { cities },
    authStore: { auth, isAuth },
  } = useRootStore();
  const {
    phone,
    name,
    select,
    street,
    house,
    apartment,
    completed,
    setPhone,
    setName,
    setSelect,
    setStreet,
    setHouse,
    setApartment,
    errorPhone,
    setErrorPhone,
    setIsTPAvailable,
    setConnectionPossibilityLogInfo,
    technicalPossibilityTP,
    setTechnicalPossibilityTP,
    technicalPossibilityArea,
    setTechnicalPossibilityArea,
    connectionType,
    setConnectionType,
    setContractPhone,
  } = useConnectionStore();
  // доступные города для подключения продукта
  const [availableCities, setAvailableCities] = useState<City[]>(cities);
  // индикаторы загрузки данных улицы и дома
  const [streetLoading, setStreetLoading] = useState<boolean>(false);
  const [houseLoading, setHouseLoading] = useState<boolean>(false);
  // ошибки номера и дома
  const [streetError, setStreetError] = useState<boolean>(false);
  const [houseError, setHouseError] = useState<boolean>(false);
  // текущая выбранная улица
  const [selectedStreet, setSelectedStreet] = useState<
    OptionProp | undefined
  >();
  // текущий выбранный дом
  const [selectedHouse, setSelectedHouse] = useState<OptionProp | undefined>();
  const [addressCheckInProgress, setAddressCheckInProgress] = useState<boolean>(
    false,
  );

  const [isAddressChecked, setIsAddressChecked] = useState<boolean>(false);
  const [streets, setStreets] = useState<OptionProp[]>([]);
  const [buildings, setBuildings] = useState<OptionProp[]>([]);
  /** Флаг записи проверки технической возможности подключения */
  const [isLogged, setIsLogged] = useState<boolean>(false);

  /** Получает список городов доступных для продукта */
  const getCities = async () => {
    try {
      const citiesBySeriesCode = await GetCitiesBySeriesCode(seriesCode);
      const cityData = cities.filter((item) =>
        citiesBySeriesCode.includes(item.id),
      );
      setAvailableCities(cityData);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isInCTA) getCities();
  }, []);

  /** Преобразование массива городов для работы Select */
  const cities2select: TCity[] = availableCities.map((row) => ({
    value: String(row.id),
    label: row.name,
  }));

  /** Формирование массива типов подключения для работы Select */
  const connectionSelect = Object.entries(
    connectionLabels,
  ).map(([value, label]) => ({ value, label }));

  const hasPhoneError = phone.value.length < 16;

  /** Функция проверки адреса подключения */
  const checkAddress = async (currentHouse: string): Promise<void> => {
    setIsAddressChecked(false);
    setAddressCheckInProgress(true);

    try {
      const possibility = await getPossibility(
        select.label,
        street,
        currentHouse,
        apartment,
      );
      setTechnicalPossibilityArea(possibility.area);
      setTechnicalPossibilityTP(possibility.tp);
      setConnectionPossibilityLogInfo(possibility.connectionPossibilityLogInfo);
      setIsTPAvailable(possibility.tp === TechnicalPossibilityType.PRESENT);
      setAddressCheckInProgress(false);
      setIsAddressChecked(true);
    } catch (e) {
      setStreetError(true);
      setHouseError(true);
    }
  };

  // чтобы запросы не оправлялись после ввода каждой буквы, сделаем debounce для улицы и дома
  useDebouncedEffect(
    async () => {
      if (street.length > 2) {
        setStreetLoading(true);
        let streetNames = [];
        try {
          streetNames = await searchStreetName(select.label, street);
        } catch (e) {
          setStreetError(true);
        }
        const mappedNames = streetNames.map((item, index) => ({
          value: index,
          label: item,
        }));
        setStreets(mappedNames);
        setStreetLoading(false);
        const match = mappedNames.find(
          (item) => item.label.toLowerCase() === street.toLowerCase(),
        );
        setSelectedStreet(match);
        if (match) {
          setStreetError(false);
        } else {
          setStreetError(true);
        }
      }
    },
    300,
    [street],
  );

  useDebouncedEffect(
    async () => {
      if (house.length > 0) {
        setHouseLoading(true);
        let buildingNames = [];
        try {
          buildingNames = await searchBuildingName(select.label, street, house);
        } catch (e) {
          setHouseError(true);
        }
        const mappedNames = buildingNames.map((building, index) => ({
          value: index,
          label: building,
        }));
        setBuildings(mappedNames);
        setHouseLoading(false);
        const match = mappedNames.find(
          (item) => item.label.toLowerCase() === house.toLowerCase(),
        );
        setSelectedHouse(match);
        if (match) {
          setHouseError(false);
        } else {
          setHouseError(true);
        }
      } else if (!house.length) {
        setHouseError(false);
      }
    },
    300,
    [house],
  );

  useEffect(() => {
    setIsLogged(false);
  }, [street, house, apartment]);

  /**
   * Вызывает проверку ТВ с флагом записи
   * @param currentHouse {string} - текущее значение дома
   * */
  const onInputBlur = async (currentHouse?: string) => {
    if (isLogged || (!currentHouse && !house)) return;
    await checkAddress(currentHouse ?? house);
    setIsLogged(true);
  };

  /** Обработка снятия фокуса с поля "Дом".
   * Вынужденное решение - при выборе значения из опции вызывается событие onBlur,
   * которое вызывается первым (перед onChange) и успевает вызвать метод проверки ТВСПД с квартирой, указанной в input,
   * а не выбранной из опции. Если это не единичный случай, переделать Autocomplete
   * */
  const onHouseBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    setTimeout(() => {
      const currentHouse = e.target.value;
      onInputBlur(currentHouse);
    }, 100);
  };

  const validateTelephone = (): void => {
    setErrorPhone(hasPhoneError);
  };

  /** Изменение склонения города в заголовке к input улицы */
  const getCityName = (cityName: string): string => {
    switch (cityName) {
      case 'Екатеринбург':
        return 'Екатеринбурге';
      case 'Берёзовский':
        return 'Берёзовском';
      case 'Нижний Тагил':
        return 'Нижнем Тагиле';
      case 'Каменск-Уральский':
        return 'Каменск-Уральском';
      case 'Красноуральск':
        return 'Красноуральске';
      default:
        return 'Екатеринбурге';
    }
  };

  /** Изменение названия улицы */
  const onStreetInputChange = (value: string): void => {
    setSelectedStreet(undefined);
    setSelectedHouse(undefined);
    setStreetError(false);
    setIsAddressChecked(false);
    setHouse('');
    setApartment('');
    setStreet(value);
  };

  /** Изменение номера дома */
  const onHouseChange = (value: string): void => {
    setSelectedHouse(undefined);
    setHouseError(false);
    setIsAddressChecked(false);
    setHouse(value);
    setApartment('');
  };

  /** Изменение номера телефона */
  const onPhoneChange = (event): void => {
    const { value, forSend } = event;
    setErrorPhone(false);
    setPhone({ value, forSend });
  };

  /** Обработчик для поля ввода номера квартиры */
  const apartmentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (/^\d+$/.test(e.target.value) || e.target.value === '') {
      setApartment(e.target.value);
      setIsAddressChecked(false);
    }
  };

  /** Автозаполнение инпутов */
  useEffect(() => {
    if (completed?.get(tariff ?? CALL_FROM_SIDEPAGE_KEY)) return;
    if (isAuth && connectionType === CONNECTION_TYPE.friend) {
      setPhone(INPUT_PHONE_VALUE);
      setName('');
    }
    if (isAuth && connectionType !== CONNECTION_TYPE.friend) {
      setName(getClientName(auth.clientName));
      (async () => {
        let onlyPhones;
        try {
          const res = await getContacts();
          onlyPhones = res.reduce((acc, contact) => {
            if (contact.contactValueClassId !== EMAIL_CLASS_ID) {
              acc.push(maskPhone(contact.value));
            }
            return acc;
          }, []);
        } catch (e) {
          console.error('getContacts', e);
        }
        const initialPhone = {
          value: onlyPhones[0].label,
          forSend: onlyPhones[0].value,
        };
        setPhone(initialPhone);
        setContractPhone(initialPhone);
      })();
    }
  }, [isAuth, connectionType, completed]);

  useEffect(() => {
    if (isAuth && !connectionType) {
      setConnectionType(connectionSelect[0].value);
    }
  }, []);

  /** Текст результата подключения */
  const resultText = () => {
    const isMissingTp =
      (isAddressChecked &&
        technicalPossibilityTP === TechnicalPossibilityType.MISSING) ||
      localStorage.getItem(POSSIBILITY_KEY) === '0';

    if (connectionType === CONNECTION_TYPE.newAddress) {
      return isMissingTp ? (
        <Text lineHeight="24px">
          Мы сообщим вам, когда возможность подключения появится.
        </Text>
      ) : (
        <Text>
          Ваша заявка для подключения на&nbsp;новом адресе принята.
          <br />
          <br />
          Мы&nbsp;свяжемся с&nbsp;вами, чтобы уточнить детали заказа
          по&nbsp;телефону <Text fontWeightBold>{phone.value}.</Text>
        </Text>
      );
    }

    if (connectionType === CONNECTION_TYPE.friend) {
      return isMissingTp ? (
        <Text>
          Ваша заявка для подключения нового договора на другого клиента{' '}
          {street &&
            house &&
            `по адресу ${
              select.label
            }, ул.${'\u00A0'}${street}, д.${'\u00A0'}${house}${
              apartment ? `, кв.${'\u00A0'}${apartment}` : ''
            }`}{' '}
          принята.
          <br />
          <br />
          Мы&nbsp;свяжемся с&nbsp;{name || 'вашим другом'}, когда техническая
          возможность появится.
        </Text>
      ) : (
        <Text>
          Ваша заявка для подключения нового договора на другого клиента{' '}
          {street &&
            house &&
            `по адресу ${
              select.label
            }, ул.${'\u00A0'}${street}, д.${'\u00A0'}${house}${
              apartment ? `, кв.${'\u00A0'}${apartment}` : ''
            }`}{' '}
          принята.
          <br />
          <br />
          Мы&nbsp;свяжемся с&nbsp;{name || 'вашим другом'}, чтобы уточнить
          детали по&nbsp;телефону <Text fontWeightBold>{phone.value}.</Text>
        </Text>
      );
    }

    if (connectionType === CONNECTION_TYPE.moving) {
      return (
        <Text>
          Ваша заявка на&nbsp;перенос подключения на&nbsp;новый адрес принята.
          <br />
          Мы&nbsp;свяжемся с&nbsp;вами, чтобы уточнить детали заказа
          по&nbsp;телефону <Text fontWeightBold>{phone.value}.</Text>
        </Text>
      );
    }

    if (isMissingTp)
      return <>Мы сообщим вам, когда возможность подключения появится.</>;

    if (streetError || !selectedStreet || !selectedHouse || !apartment) {
      return (
        <>
          Мы свяжемся с вами в течение 30 минут, чтобы уточнить детали
          подключения.
        </>
      );
    }
    return (
      <>
        Мы&nbsp;свяжемся с&nbsp;вами в&nbsp;течение 30&nbsp;минут, чтобы
        уточнить детали и&nbsp;оформить договор.
      </>
    );
  };

  return (
    <WizardContainer isInCTA={isInCTA}>
      {completed && !completed?.get(tariff ?? CALL_FROM_SIDEPAGE_KEY) ? (
        <form className="wizard">
          <div className="wizard__wrapper">
            {isAuth && !isInCTA && (
              <div className="wizard__type">
                <Select
                  value={connectionType}
                  data={connectionSelect}
                  onOptionClick={(option: OptionProp): void => {
                    setConnectionType(option.value);
                  }}
                  width="200px"
                  className="wizard__type__input"
                />
                <Text>{parseHtml(connectionText[connectionType])}</Text>
              </div>
            )}
            <div className="wizard__adress-item">
              <Text>Город подключения</Text>
              <Select
                className="wizard__input"
                width="100%"
                onOptionClick={(option: OptionProp): void => {
                  setSelect(option);
                  onStreetInputChange('');
                  onHouseChange('');
                  setApartment('');
                }}
                value={select.value}
                data={cities2select}
              />
            </div>
            <div className="wizard__adress__container">
              <div className="wizard__adress">
                <div className="wizard__adress-item">
                  <Text>
                    Улица в&nbsp;
                    {getCityName(select.label)}
                  </Text>
                  <Autocomplete
                    className="wizard__input wizard__input-street"
                    items={streets}
                    text={street}
                    value={selectedStreet}
                    loading={streetLoading}
                    onInputChange={(val: string) => onStreetInputChange(val)}
                    onChange={(item: AutocompleteItem): void => {
                      // избегаем лишний эффект и лишний запрос
                      if (item.label === street) return;
                      onStreetInputChange(item.label);
                    }}
                    error={streetError}
                    width="100%"
                  />
                </div>
                <div className="wizard__adress-field">
                  <div className="wizard__adress-item">
                    <Text
                      {...(!street.length && {
                        color: defaultTheme.colors.disable,
                      })}
                    >
                      Дом
                    </Text>
                    <Autocomplete
                      className="wizard__input wizard__input-house"
                      items={buildings}
                      text={house}
                      outsideClickHelper={outsideClickHelper}
                      value={selectedHouse}
                      loading={houseLoading}
                      onInputChange={(val: string): void => onHouseChange(val)}
                      onChange={(item: AutocompleteItem): void => {
                        // избегаем лишний эффект и лишний запрос
                        if (item.label === house) return;
                        onHouseChange(item.label);
                      }}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                        onHouseBlur(e)
                      }
                      searchSensitivity={1}
                      disabled={!street.length}
                      error={houseError}
                      width="100%"
                    />
                  </div>
                  <div className="wizard__adress-item">
                    <Text
                      {...((!street.length || !house.length) && {
                        color: defaultTheme.colors.disable,
                      })}
                    >
                      Квартира
                    </Text>
                    <Input
                      className="wizard__input wizard__input-flat"
                      width="100%"
                      onChange={apartmentChange}
                      onBlur={() => onInputBlur()}
                      value={apartment}
                      disabled={!street.length || !house.length}
                    />
                  </div>
                </div>
              </div>
              {(streetError || houseError) && (
                <Text
                  className="wizard__error-address"
                  color={defaultTheme.colors.planeta}
                >
                  Адрес не найден
                </Text>
              )}
              {isAddressChecked &&
                technicalPossibilityTP === TechnicalPossibilityType.PRESENT && (
                  <div className="wizard__status-container">
                    <Icon icon={<Icons.OkGreenIcon />} />
                    <Text className="wizard__error">
                      {`По этому адресу подключение возможно. Ваш пояс обслуживания - ${technicalPossibilityArea}.`}
                    </Text>
                  </div>
                )}
              {isAddressChecked &&
                technicalPossibilityTP === TechnicalPossibilityType.MISSING && (
                  <Text className="wizard__status-container">
                    По указанному адресу подключение недоступно. Оставьте свои
                    контакты, и мы свяжемся с вами, когда появится возможность
                    подключения
                  </Text>
                )}
              {addressCheckInProgress && (
                <Loader small className="wizard__status-container" />
              )}
            </div>
            <div className="wizard__contacts">
              <div className="wizard__container">
                <Text>
                  {connectionType === CONNECTION_TYPE.friend
                    ? 'Телефон друга'
                    : 'Ваш телефон'}
                </Text>
                <Input
                  className="wizard__input wizard__input-phone"
                  type="phone"
                  color={defaultTheme.colors.black}
                  value={phone.value}
                  onChangeCustomInput={onPhoneChange}
                  onBlur={validateTelephone}
                  width="100%"
                  error={errorPhone}
                />
                {errorPhone && (
                  <Text
                    className="wizard__error"
                    color={defaultTheme.colors.planeta}
                  >
                    {ERROR_MESSAGE.EMPTY_PHONE}
                  </Text>
                )}
              </div>
              <div className="wizard__container">
                <Text>
                  {connectionType === CONNECTION_TYPE.friend
                    ? 'Как зовут друга'
                    : 'Как вас зовут'}
                </Text>
                <Input
                  className="wizard__input wizard__input-name"
                  type="text"
                  color={defaultTheme.colors.black}
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setName(e.target.value);
                  }}
                  width="100%"
                />
              </div>
            </div>
            {isInCTA && (
              <WizardConfirm comment={comment} tariff={tariff} horizontal />
            )}
          </div>
        </form>
      ) : (
        <div className="wizard_done">
          <Icon icon={<Icons.SuccessIconAnimated />} />
          <H1>Спасибо! Заявка принята</H1>
          <LeadingText color={defaultTheme.colors.black}>
            {resultText()}
          </LeadingText>
        </div>
      )}
    </WizardContainer>
  );
};

export default observer(ConnectionWizard);
