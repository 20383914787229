import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { StyledMdxParagraph } from '~/components/Blocks/Shared/Shared.style';

/** константы */
import { desktop1280, desktop940 } from '~/components/Grid/constants';

export const OperatingStateWizardContainer = styled.div`
  ${() => `
  
  .wizard {
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: ${defaultTheme.colors.white};
    
    &__wrapper {
      width: 100%;
    }
    
    &__content {
      width: 100%;
      &-success, &-error {
        margin: -2rem 0 2rem 0;
        & svg, & svg * {
          stroke: ${defaultTheme.colors.planeta};
        }
        & > h2 {
          font-size: 21px;
          line-height: 25px;
          font-weight: 500;
          margin: 1rem 0;
          max-width: 70%;
        }
      }
      &-table {
        margin: 1rem 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        & > div {
          color: ${defaultTheme.colors.shadow};
          padding: 0 0 1rem 0;
          &:nth-of-type(2n) {
            color: ${defaultTheme.colors.black};
          }
        }
      }
      &-date {
        color: ${defaultTheme.colors.black};
      }
      & > p {
        color: ${defaultTheme.colors.shadow};
      }
      & strong {
        font-weight: 700;
      }
      
      & .warning {
        background: ${defaultTheme.colors.backgroundWarning};
        padding: 1rem;
        margin: 1rem auto 0 auto;
        & p ~ p {
          margin: 1rem 0 0 0;
        }
      }
    }
    &__agreement {
      margin-top: 16px;
      > span:first-of-type {
        margin-bottom: 16px;
      }

      &__text {
        ${StyledMdxParagraph} {
          margin-bottom: 8px;
        }

        ul {
          margin-top: 20px;
        }

        li {
          margin-bottom: 16px;
        }

        li:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  
  @media (min-width: ${desktop1280}px) {
  .wizard {
    &__content {
      &-success, &-error {
        & > h2 {
          font-size: 44px;
          line-height: 49px;
          font-weight: 700;
        }
      }
    }
  }
  
  @media (max-width: ${desktop940}px) {
    .wizard {
      &__content {
        &-table {
          display: block;
          & > div {
            display: block;
            padding: 0 0 0.5rem 0;
            &:nth-of-type(2n) {
              padding: 0 0 1rem 0;
            }
          }
        }
      }
    }
  }
`};
`;

export const StyledOperatingStateError = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 24px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
