/** libraries */
import { FC } from 'react';
import { Text, Link, defaultTheme, Input } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledCreatePassword } from '../styles';
/** constants */
import { ATTEMPT, REMAINED } from '~/constants/common';
/** utils */
import { pluralizeAll } from '~/utils/utils';
/** stores */
import useCreatePasswordStore from '../store/useCreatePasswordStore';

const VerificationContractAndNumber: FC = (): JSX.Element => {
  const {
    contractNumber,
    setContractNumber,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    setPhoneNumberError,
    contractNumberError,
    setContractNumberError,
    remains,
  } = useCreatePasswordStore();

  const onChangeContractNumber = (event) => {
    const { value } = event.target;
    setContractNumber(value);
    setContractNumberError(
      value === '0' ? 'Несуществующий номер договора' : '',
    );
  };

  /** Номер телефона */
  const onChangePhoneNumber = (event) => {
    const { value, forSend, errorText } = event;
    setPhoneNumber({ value, forSend });
    setPhoneNumberError(errorText ? 'Введите корректный номер телефона ' : '');
  };

  return (
    <StyledCreatePassword>
      <div className="create-password__warning">
        <Text className="create-password__warning__block" lineHeight="24px">
          Укажите ваш номер договора с&nbsp;Планетой. Не&nbsp;можете найти номер
          договора&nbsp;&mdash;{' '}
          <Link href="/contacts" target="_blank">
            обратитесь
          </Link>{' '}
          в&nbsp;службу поддержки клиентов в&nbsp;вашем городе.
        </Text>
        <Text lineHeight="24px">
          На&nbsp;номер телефона будет отправлен код для&nbsp;восстановления
          пароля.
        </Text>
      </div>
      <div className="create-password__input-block">
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Номер договора
        </Text>
        <Input
          type="text"
          placeholder="Введите номер"
          value={contractNumber}
          error={contractNumberError}
          onChange={onChangeContractNumber}
          isFocus
        />
      </div>
      <div className="create-password__input-block">
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Телефон, указанный в договоре
        </Text>
        <Input
          type="phone"
          placeholder="Введите номер"
          value={phoneNumber.value}
          error={phoneNumberError}
          onChangeCustomInput={onChangePhoneNumber}
        />
      </div>
      {remains && (
        <div className="create-password__warning">
          <Text lineHeight="24px">
            По&nbsp;истечении пяти попыток ввода доступ к&nbsp;договору будет
            временно ограничен.
          </Text>
          <Text lineHeight="24px" fontWeightBold>
            {pluralizeAll(remains, ATTEMPT, REMAINED)}.
          </Text>
        </div>
      )}
    </StyledCreatePassword>
  );
};

export default observer(VerificationContractAndNumber);
