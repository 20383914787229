/* Библиотеки */
import styled from '@emotion/styled';
/* Цвета */
import { defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
/* Интерфейсы */
import { StyledProductSelectorDescriptionProps } from './ProductSelectorItem.interfaces';

/* Стилизованный блок с карточкой продукта */
export const StyledProductSelectorItem = styled.div<{
  withoutFilters?: boolean;
}>`
  ${({ withoutFilters }): string => `
      display: flex;
      flex-direction: column;
      position: relative;
      width: 244px;
      height: 542px;
      margin: ${withoutFilters ? '0 16px 0 0' : '13px 16px 0 0'};

      .marketingCategoryFeedLink {
        position: absolute;
        top: 87px;
        left: 30px;

        a > span: hover {
          text-decoration: underline;
        }
      }
      
      .top {
        top: 119px;
      }
    `};
`;

/* Стилизованный блок с описанием продукта */
export const StyledProductSelectorDescription = styled.div<StyledProductSelectorDescriptionProps>`
  ${({ hasTags, isBusiness }: StyledProductSelectorDescriptionProps) => `
      display: flex;
      flex-direction: column;
      padding: 24px 17px 27px 31px;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 252px;
      background: ${
        isBusiness ? defaultTheme.colors.graphite : defaultTheme.colors.planeta
      };
      overflow-y: hidden;
      padding-bottom: 10px;
      
      h3 {
        margin: ${hasTags ? 8 : 31}px 0 0 0;
      }
      
      > span:first-of-type {
        > div {
          margin-right: 7px;
        }
      }
      
      > span:last-of-type {
        white-space: pre-line;
        margin-top: 39px;
      }
    `};
`;

/* Стилизованный блок с информацией о продукте */
export const StyledProductSelectorInfo = styled.div`
  ${() => `
      display: flex;
      flex-direction: column;
      padding: 33px 31px;
      align-items: flex-start;
      justify-content: flex-start;
      background: ${defaultTheme.colors.light};
      white-space: pre-wrap;
      height: 100%;

      > span:first-of-type {
        display: flex;
        flex-direction: row;
        margin: 0 10px 0px 0;
      }

      > span {
        min-height: 25px;
      }
      
      h3 {
        margin-bottom: 34px;
      }
      
      .price {
        &__annual {
          display: flex;
        }
      }
      
      .action-discount {
        display: flex;
        flex-direction: column;
        margin: 12px 7px 0 0;
        
        > span {
          font-size: 11px;
          line-height: 12px;
    
          &:first-of-type {
            font-weight: 700;
          }
    
          &:last-of-type {
            font-weight: 400;
            text-decoration: line-through;
          }
    }
  }
    `};
`;

export const StyledTags = styled.span`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  div > div > span {
    letter-spacing: -0.01em;
  }
`;

/* Стилизованный текст с информацией о каналах */
export const StyledChannelText = styled(LeadingText)<{
  isTransformer?: boolean;
}>`
  ${({ isTransformer }): string => `
      margin-bottom: ${isTransformer ? 8 : 34}px;
    `};
`;
