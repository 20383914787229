import { Dispatch, SetStateAction } from 'react';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';

/** интерфейс промо сайдпейджа */
export interface PromoProps {
  /** контент для SidePage */
  content: string;
  /** функция оплаты картой */
  payByCreditCard: (newAmount?: number) => Promise<void>;
  /** функция оплаты СБП */
  paymentViaSBP: (
    contractNameFromShortLink?: string,
    newAmount?: number,
  ) => Promise<void>;
  /** номер договора, введённый в поле */
  contractName: string;
  /** сумма к оплате, введённая в поле */
  amount: number;
  /** тип активной акции */
  paymentPromoType: string;
  /** функция для сохранения информации об участии в акции */
  setAnswer: (
    contractName: string,
    amount: number,
    paymentPromoType: string,
    paymentType: PAYMENT_TYPE,
    dontShowAgain: boolean,
  ) => Promise<void>;
  /** изменяет флаг открытия данного сайдпейджа */
  setIsOpenPromo: Dispatch<SetStateAction<boolean>>;
  /** изменяет флаг загрузки кнопки "Оплатить" */
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  /** Показывать кнопки кешбэка */
  isCashbackBottoms: boolean;
  /** Текущий выбранный тип оплаты */
  currentPaymentType: PAYMENT_TYPE;
}

export enum CASHBACK_TEXT {
  DEFAULT,
  LOW,
  MEDIUM,
  MAX,
}
