/** библиотеки */
import * as React from 'react';

/** компоненты библиотеки */
import { H3, H2, Text, defaultTheme } from 'cordis-core-ui-planeta';

/** типы */
import { ConnectionMethod } from '~/constants/common';
import {
  DEFAULT_SPEED_CONTENT,
  PROGRESS_STYLE_TYPES,
  READABLE_SPEED,
  SpeedProps,
  TEXT,
} from './Speed.types';

/** стили */
import { StyledSpeed } from './Speed.style';

/** компоненты */
import SpeedProgress from './SpeedProgress';

/**
 * Блок скорость подключения
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=397981940
 * @param {SpeedProps} content содержимое
 */
const Speed: React.FC<SpeedProps> = ({ content }: SpeedProps) => {
  /** берет значение полей по умолчанию если блок не находится на странице продукта */
  const fields = content.fields.seriesCode
    ? content.fields
    : DEFAULT_SPEED_CONTENT;
  /** значение скорости и текст */
  const [speed, speedText] = fields.speedBaseText?.split(' ');
  /** метод подключения */
  const isFtth = fields.connectionMethod === ConnectionMethod.FTTH;
  /** цвет текста в зависимости от типа блока */
  const textColor = isFtth
    ? defaultTheme.colors.white
    : defaultTheme.colors.black;
  /** значение скорости типа в мегабайтах */
  const speedBase = fields.speedBase / READABLE_SPEED;
  /** текст блока в зависимости от скорости */
  const text = TEXT[speedBase];
  const progressStyle =
    isFtth || !content.fields.seriesCode
      ? PROGRESS_STYLE_TYPES.white
      : PROGRESS_STYLE_TYPES.colored;

  /** преобразовывает скорость в шкалу прогресса */
  const getProgressStatus = (currentSpeed: number): number => {
    if (currentSpeed > 70) {
      return 100;
    }
    if (currentSpeed > 30) {
      return 66;
    }
    return 33;
  };

  /** значение шкалы прогресса */
  const progressStatus = getProgressStatus(speedBase);

  return (
    <StyledSpeed isFtth={isFtth}>
      {isFtth ? (
        <div className="speed-ftth">
          <Text className="speed-ftth__text" color={textColor}>
            Оптический
          </Text>
          <H2 className="speed-ftth__value" color={textColor}>
            ГИГАБИТ
          </H2>
        </div>
      ) : (
        <div className="speed-lan">
          <H2 className="speed-lan__value" color={textColor}>
            {speed}
          </H2>
          <Text className="speed-lan__text" color={textColor} fontWeightBold>
            {speedText}
          </Text>
        </div>
      )}
      <SpeedProgress styleType={progressStyle} progress={progressStatus} />
      <div className="description">
        <H3 className="description__title" color={textColor}>
          {text.title}
        </H3>
        <Text className="description__text" color={textColor} lineHeight="24px">
          {text.description}
        </Text>
      </div>
    </StyledSpeed>
  );
};

export default Speed;
