import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop1100, desktop940 } from '~/components/Grid/constants';

/** стилевой компонент автоплатежа */
export const StyledAutoPaymentWizard = styled.div`
  ${() => {
    return css`
      width: 100%;
      .AutoPaymentWizard {
        &__text {
          max-width: 350px;
          margin: 0 0 20px 0;
        }

        &__binded-header {
          margin-top: 26px;
        }

        &__binded {
          &-text {
            margin-top: 16px;
            line-height: 24px;
          }
          &-header {
            margin-top: 32px;
          }
        }

        &__sbp-failed {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          > svg {
            margin: 0 2px 0 8px;
          }
        }

        &__snoska {
          &__link {
            cursor: pointer;
          }
          a {
            color: ${defaultTheme.colors.planeta};
          }
        }

        &__binded-account {
          display: flex;
          align-items: center;
          > svg,
          .logo {
            margin-right: 8px;
          }
        }

        &__bind {
          &__qr {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            &__text-block {
              margin: 32px 0 0 0;

              &__timer {
                margin-top: 16px;
                &__loader {
                  display: flex;
                  align-items: center;
                  margin-top: 8px;

                  > div:nth-of-type(2) {
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }

        &__error {
          margin-bottom: 8px;
        }

        &__money {
          display: flex;
          flex-direction: column;

          &__block {
            display: flex;
            flex-direction: column;
            > div {
              order: 2;
            }
            > span {
              order: 1;
              margin: 0 0 24px 0;
              cursor: pointer;
            }
          }

          >span: last-of-type {
            margin-top: 8px;
          }
        }

        &__price-tag {
          margin-bottom: 16px;
          > div {
            width: auto;
            .colorContainer {
              width: 100%;
              > span {
                color: ${defaultTheme.colors.shadow};
              }
            }
          }
        }

        &__warning-block {
          background-color: ${defaultTheme.colors.backgroundWarning};
          padding: 16px;
          margin: 0 0 32px 0;
          width: 100%;

          &__button {
            margin-top: 16px;
            cursor: pointer;
          }

          > span:nth-of-type(2) {
            margin-top: 16px;
          }
        }

        &__input-wrapper {
          position: relative;
          margin: 0 0 16px 0;
        }
        &__input-text {
          display: block;
          margin-bottom: 8px;
        }
        &__input-field {
          width: 320px;
          max-width: 100%;

          &.block-card {
            .select-wrapper .select-overlay {
              width: 100%;
            }
            .expired {
              .option-text,
              .option-bottomText {
                color: ${defaultTheme.colors.planeta};
              }
            }
          }
        }

        &__input-checkbox {
          display: block;
          margin: 0 0 26px 0;
          &-agreement a {
            color: ${defaultTheme.colors.planeta};
            &:hover {
              text-decoration: none;
            }
          }
          & * {
            &::selection {
              background: transparent;
            }
          }
        }

        &__value {
          padding: 5px 0;
          font-weight: 600;
        }

        & button {
          display: block;
        }

        &__warning {
          padding: 16px;
          background-color: ${defaultTheme.colors.backgroundWarning};
          margin-top: 32px;
          &__banks {
            cursor: pointer;
          }
        }
      }
      @media (min-width: ${desktop940}px) {
        .AutoPaymentWizard {
          &__bind {
            &__qr {
              flex-direction: row;
              &__text-block {
                margin: 0 0 0 32px;
              }
            }
          }
        }
      }

      @media (min-width: ${desktop1100}px) {
        .AutoPaymentWizard {
          &__money {
            &__block {
              flex-direction: row;
              align-items: flex-end;
              > div {
                order: 1;
              }

              > span {
                height: 54px;
                order: 2;
                margin: 0 0 0 16px;
                padding-top: 15px;
              }
            }
          }
        }
      }
    `;
  }};
`;
