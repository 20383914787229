/* Типы */
import { Block } from '~/interfaces/BlockInterface';
// Цикловая зависимость типов друг от друга (import/no-cycle) допустима, по этому
/* eslint-disable */
import { CallToActionProductProps } from './CallToActionProduct/CallToActionProduct.types';
import { CallToActionFeeProps } from './CallToActionFee/CallToActionFee.types';
import { CallToActionInternetProps } from './CallToActionInternet/CallToActionInternet.types';
import { CallToActionTelevisionProps } from './CallToActionTelevision/CallToActionTelevision.types';
import { CallToActionTimeShiftProps } from './CallToActionTimeShift/CallToActionTimeShift.types';
import { PromoInfoProps } from '~/components/Blocks/Shared/Shared.types';
import { ConnectionMethod } from '~/constants/common';
import { DefaultImgs } from '../Device/Device.types';
/* eslint-enable */

/** Поля блока */
export interface CallToActionFields
  extends CallToActionProductProps,
    CallToActionInternetProps {
  /* Стоимость продукта */
  priceInfo: CallToActionPriceInfo;
  /* Информация о каналах */
  channelsInfo?: CallToActionTelevisionProps;
  /* Флаг Трансформера */
  isTransformer: boolean;
  /* Флаг годового продукта */
  isAnnual: boolean;
  /* Продолжительность отложенного платежа (в часах) */
  distortionDuration: number;
  /* Информация об управлении просмотром */
  timeShiftInfo: CallToActionTimeShiftProps;
  /* Информация об оборудовании */
  devicesInfo: {
    devices: CallToActionDeviceInfo[];
  };
  /* Код серии продукта */
  seriesCode?: string;
  /** Данные для блока "Фиксируем цены на год" */
  fixedPrice?: CallToActionFixedPriceProps;
  /** Альтернативное наименование продукта */
  customHeader?: string;
}

/** Контент блока */
export interface CallToActionContent extends Block {
  /* Поля блока */
  fields: CallToActionFields;
  defaultImgs: DefaultImgs[];
}

/** интерфейс компонента CallToAction */
export interface CallToActionProps {
  /* Контент с полями блока */
  content: CallToActionContent;
}

/** Свойства стоимости продукта */
export interface CallToActionPriceInfo extends CallToActionFeeProps {
  /* Стоимость подключения к сети */
  connection: number;
  /* Стоимость подключения к продукту */
  migrationCostFirst: number;
  /* Блок с акциями */
  promoConnectionInfos?: PromoInfoProps[];
  /* Авансовый платёж */
  prepayment: number;
}

/* Свойства блока с устройствами
 *  Используется в дочерних компонентах:
 *  CallToActionRouter
 * */
export interface CallToActionDeviceInfo {
  /* Цена в рассрочку */
  annuity?: number;
  /* Первоначальный взнос */
  downPayment?: number;
  /* Период рассрочки */
  leasePeriod?: number;
  /* Наименование устройства */
  name: string;
  /* Цена в приобретение */
  price: number;
  /* Единовременный платёж */
  ownershipOneTimeCharge?: number;
  /* Цена в пользование */
  ownershipPriceOn?: number;
  /* Информация об акциях на приобретение устройств */
  promoPriceInfos?: PromoInfoProps[];
  /* Тип устройства */
  type: CallToActionDeviceTypes;
}

/* Свойства для данных для Select с выбором типа или количества приобретения оборудования */
export interface DataSelectPurchaseTypesProps {
  /* Наименование */
  label: string;
  /* Значение */
  value: string;
}

/* Свойства стилизованного компонента CallToActionButtonStyle */
export interface CallToActionButtonStyleProps {
  /* Значение прокрутки относительно блока CallToAction */
  amountScroll: number;
  /* Текущая ширина блока */
  clientWidth: number;
  /* Флаг раскрытия настроек */
  showSettings: boolean;
}

/** Данные для блока "Фиксируем цены на год" */
export interface CallToActionFixedPriceProps {
  /** Ссылка на изображение */
  imgLink: string;
  /** Новая цена */
  newPrice: number;
  /** Скидка */
  discount: number;
  /** Текст о длительности акции */
  dueDate: string;
}

/** Типы изображений  */
export type DeviceImages = {
  [key in CallToActionDeviceTypes]: DeviceImagesType;
};

/** Свойства типа изображения  */
export type DeviceImagesType = {
  [key in ConnectionMethod]?: string;
};

/* Варианты отображения цены */
export enum CallToActionFeePriceTypes {
  /* За день */
  DAILY = 'День',
  /* За 30 дней */
  MONTH = 'Месяц',
}

/* Возможные типы оборудования */
export enum CallToActionDeviceTypes {
  /* Пульт для голосового управления */
  REMOTE_CONTROL = 'Remote_control',
  /* Wi-Fi роутер */
  ROUTER = 'Router',
  /* Телеприставка */
  STB = 'STB',
}

/* Возможные типы приобретения оборудования */
export enum PurchaseTypes {
  OWNERSHIP = 'Пользование',
  ANNUITY = 'Рассрочка',
  PURCHASE = 'Покупка',
}
