import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledTable = styled.table<{ width: string }>`
  ${({ width }) => {
    return css`
      border-collapse: collapse;
      table-layout: fixed;
      width: ${width ?? '100%'};
    `;
  }}
`;

export const StyledTr = styled.tr<{ backgroundColor?: string }>`
  ${({ backgroundColor }) => {
    return css`
      padding: 12px 16px;
      border-bottom: 1px solid ${defaultTheme.colors.line};
      ${backgroundColor
        ? css`
            background-color: ${backgroundColor};
          `
        : ''}
    `;
  }}
`;

export const StyledTh = styled.th<{ width?: number; textAlign?: string }>`
  ${({ width, textAlign }) => {
    return css`
      ${width ? `width: ${width}px;` : ''}
      height: 32px;
      text-align: ${textAlign ?? 'left'};
      padding-bottom: 8px;
      &:first-of-type {
        padding-left: 16px;
      }

      &:last-of-type {
        padding-right: 16px;
      }
    `;
  }}
`;

export const StyledTd = styled.td<{
  verticalAlign?: string;
  textAlign?: string;
}>`
  ${({ verticalAlign, textAlign }) => {
    return css`
      ${verticalAlign ? `vertical-align: ${verticalAlign};` : ''}
      text-align: ${textAlign ?? 'left'};
      padding: 12px 0;
      white-space: pre-line;
      &:first-of-type {
        padding-left: 16px;
      }

      &:last-of-type {
        padding-right: 16px;
      }
    `;
  }}
`;
