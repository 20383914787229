/* Библиотеки */
import styled from '@emotion/styled';

/* Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';

/* Стилизованный контент строки для компонента CallToAction */
export const StyledRowContent = styled.div`
  ${() => `
      display: flex;
      flex-direction: column;
      margin: 32px 0 48px 0;
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        flex-direction: row;
      }
    `}
`;

/* Стилизованный заголовок строки для компонента CallToAction */

export const StyledTitleContent = styled.div`
  ${() => `
      border-top: 1px solid ${defaultTheme.colors.line};
      padding-top: 16px;
    `}
`;
