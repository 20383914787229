export enum BIND_STATES {
  ON = 'Serviced',
  CLIENT_BLOCK = 'Night',
  NEW = 'Novice',
  PROVIDER_BLOCK_DEBT = 'Sleep',
}

export enum BIND_REFUSE_REASONS {
  MONEY = 'NotEnoughMoney',
  WRONG_STATE = 'WrongContractState',
  VACATION = 'VacationIsEnabled',
}
