/** библиотеки */
import styled from '@emotion/styled';

export const DeviceReturnWizardStyled = styled.div`
  .device-return-wizard {
    &__serial-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }

    &__agreement {
      margin-top: 16px;
      > span:first-of-type {
        margin-bottom: 8px;
      }
    }
  }
`;
