/** библиотеки */
import React, { FC, useMemo } from 'react';
import { formatNumber, isExternal } from '~/utils/utils';
import {
  Button,
  H3,
  Icon,
  Icons,
  LinkButton,
  OutsideClickHelper,
  Select,
  Snoska,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
/** типы */
import { ManualsProps } from '~/components/Blocks/Templates/Devices/interfaces';
import { OptionProp } from 'cordis-core-ui-planeta/dist/components/Select/interfaces';
/** константы */
import { DEFAULT_IMAGE } from '~/components/Blocks/Shared/DevicesCard/constants';
import { CONTACTS_LINK, OPERATING_STATE } from '~/constants/common';
import { QP_DEVICE } from '../../Templates/Devices/constants';
import {
  NO_OWNERSHIP_STB_ID,
  TABS_DEVICE,
} from '../../Templates/Devices/Components/DeviceTabs/constants';
/** стилевые компоненты */
import {
  StyledDetailedDevice,
  StyledDeviceDetailedCardImage,
} from '~/components/Blocks/Templates/Devices/style';
import { StyledIconLink } from '../../Templates/Pab2c/Devices/Components/DeviceCardWizard/style';
/* Утилиты */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import LinkWrapper from '~/components/LinkWrapper';
/* stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useDeviceDetailedCardStore from './store/useDeviceDetailedCardStore';

const DeviceDetailedCard: FC = () => {
  const {
    contractStateStore: { contractState },
    authStore: { isAuth, auth },
  } = useRootStore();
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const { toggleCallbackWizardVisible } = useCallbackStore();
  const { toggleVisible } = useMakeAuthStore();

  const {
    detailedDevice: item,
    select,
    setSelect,
    error,
    orderEquipment,
    isLoading,
    colorTabs,
    colorSelect,
    setColorSelect,
  } = useDeviceDetailedCardStore();

  const router = useRouter();

  /** Договор бездействует */
  const isZombie =
    contractState === OPERATING_STATE.DREGS ||
    contractState === OPERATING_STATE.STRAY;

  // Возвращает массив табов
  const generateTabs = (): OptionProp[] => {
    const { annuity, price, ownershipPriceOn, id } = item;

    let newTabs = [];
    if (price !== null) {
      newTabs.push(TABS_DEVICE.BUY);
    }
    // Исключение телеприставок UHD300X и Q-Box Ultra Wi-Fi, так как не могут быть предоставлены в пользование
    if (
      ownershipPriceOn !== null &&
      id !== NO_OWNERSHIP_STB_ID.UHD300X &&
      id !== NO_OWNERSHIP_STB_ID.QBOXULTRAWIFI
    ) {
      newTabs.push(TABS_DEVICE.USE);
    }
    if (annuity !== null) {
      newTabs.push(TABS_DEVICE.ANNUITY);
    }
    newTabs = newTabs.map((tab) => {
      const value = () => {
        switch (tab) {
          case TABS_DEVICE.BUY:
            return price;
          case TABS_DEVICE.USE:
            return ownershipPriceOn;
          case TABS_DEVICE.ANNUITY:
            return annuity;
          default:
            return price;
        }
      };
      return {
        value: value(),
        label: tab,
      };
    });

    setSelect(newTabs[0]);
    if (colorTabs.length) setColorSelect(colorTabs[0]);
    return newTabs;
  };

  // Табы оборудования
  const deviceTabs = useMemo(() => {
    return generateTabs();
  }, [item]);

  // Ссылка на изображение
  const getImageSrc = (): string => {
    const colorDeviceImage = item?.colors
      ? item.colors.find((colorItem) => colorItem.hex === colorSelect?.value)
          ?.image
      : null;
    if (!item.images && !colorDeviceImage)
      return `${process.env.STATIC_SERVER}${item.images || DEFAULT_IMAGE}`;

    const img = colorDeviceImage ?? item.images;
    return isExternal(img) ? img : `${process.env.STATIC_SERVER}${img}`;
  };

  /** Руководство */
  const showDocuments = (manual: ManualsProps): JSX.Element => {
    const link = isExternal(manual.link)
      ? manual.link
      : `${process.env.STATIC_SERVER}${manual.link}`;

    return (
      <StyledIconLink className="devices__manual" key={manual.link}>
        <LinkWrapper href={link} target="_blank">
          <Icon icon={<Icons.DocumentIcon />} />
          <span>{manual.name}</span>
        </LinkWrapper>
      </StyledIconLink>
    );
  };

  /** Открыть СП визарда подключения с карточки оборудования */
  const showConnectionWizard = () => {
    router.replace(
      {
        query: {
          ...router.query,
          [QP_DEVICE.NAME]: item.name,
          [QP_DEVICE.PRICE]: select.value,
          [QP_DEVICE.IS_BUY]: select.label === TABS_DEVICE.BUY,
        },
      },
      undefined,
      {
        shallow: true,
      },
    );
    toggleConnectionWizardVisible();
  };

  return (
    <StyledDetailedDevice>
      <div className="devices__block">
        <StyledDeviceDetailedCardImage imgSrc={getImageSrc()} />
        <div className="devices__block__right">
          {!item.archive && !item?.isNotAvailable && (
            <>
              {colorTabs.length > 0 && (
                <Select
                  className="devices__block__right__colors"
                  width="214px"
                  onOptionClick={(option) => setColorSelect(option)}
                  value={colorSelect?.value}
                  outsideClickHelper={OutsideClickHelper()}
                  data={colorTabs}
                />
              )}
              {deviceTabs.length > 1 && (
                <Select
                  width="214px"
                  onOptionClick={(option) => setSelect(option)}
                  value={select?.value as string}
                  outsideClickHelper={OutsideClickHelper()}
                  data={deviceTabs}
                />
              )}
              {deviceTabs.length === 1 && (
                <Text>
                  {select.label === TABS_DEVICE.BUY &&
                    'Доступна только покупка'}
                  {select.label === TABS_DEVICE.USE &&
                    'Доступно только приобретение в пользование'}
                  {select.label === TABS_DEVICE.ANNUITY &&
                    'Доступна только рассрочка'}
                </Text>
              )}
              <div className="devices__price">
                <H3>
                  {select.label === TABS_DEVICE.BUY && (
                    <>{formatNumber(select.value as number)} &#8381;</>
                  )}
                  {select.label === TABS_DEVICE.USE && (
                    <>
                      {isAuth ? '' : 'от'}{' '}
                      {formatNumber(select.value as number)} ₽ в день
                      {isAuth ? '' : '*'}
                    </>
                  )}
                  {select.label === TABS_DEVICE.ANNUITY && (
                    <>{formatNumber(select.value as number)} &#8381; в день</>
                  )}
                </H3>
                {select.label === TABS_DEVICE.ANNUITY && (
                  <Snoska className="devices__price-snoska">
                    на 12 месяцев, 0 ₽ первоначальный взнос
                  </Snoska>
                )}
                {select.label === TABS_DEVICE.USE &&
                  !!item.ownershipOneTimeCharge && (
                    <Snoska className="devices__price-snoska">
                      Единовременный платеж {item.ownershipOneTimeCharge} ₽
                    </Snoska>
                  )}
              </div>
              {isAuth && !isZombie && (
                <>
                  <Button
                    className="devices__button"
                    onClick={() =>
                      orderEquipment(auth.contractName, auth.clientName)
                    }
                    loading={isLoading}
                  >
                    Заказать
                  </Button>
                  {error && (
                    <Text
                      className="devices__error"
                      lineHeight="24px"
                      color={defaultTheme.colors.down}
                    >
                      {error}
                    </Text>
                  )}
                </>
              )}
            </>
          )}
          {item.manuals?.length > 0 &&
            item.manuals.map((manual) => showDocuments(manual))}
        </div>
      </div>
      {!isAuth ? (
        <div className="devices__auth-warning">
          <Text lineHeight="24px">
            <Text
              className="devices__cursor"
              lineHeight="24px"
              color={defaultTheme.colors.planeta}
              onClick={() => toggleVisible()}
            >
              Войдите
            </Text>
            , чтобы заказать или{' '}
            <Text
              className="devices__cursor"
              lineHeight="24px"
              color={defaultTheme.colors.planeta}
              onClick={showConnectionWizard}
            >
              отправьте
            </Text>{' '}
            заявку на подключение к Планете.
          </Text>
        </div>
      ) : (
        <>
          {item.archive || item?.isNotAvailable ? (
            <div className="devices__auth-warning">
              <Text lineHeight="24px">Устройство недоступно для заказа.</Text>
            </div>
          ) : (
            <>
              {isZombie && (
                <div className="devices__auth-warning__yellow">
                  <Text lineHeight="24px">
                    Ваш договор не&nbsp;обслуживается.{' '}
                    <LinkWrapper href={CONTACTS_LINK} target="_blank">
                      Обратитесь
                    </LinkWrapper>{' '}
                    в&nbsp;офис обслуживания или{' '}
                    <LinkButton onClick={toggleCallbackWizardVisible}>
                      позвоните нам
                    </LinkButton>
                    , чтобы восстановить его.
                  </Text>
                </div>
              )}
            </>
          )}
        </>
      )}
      <div className="devices__content">
        {item.content && parseHtml(item.content)}
      </div>
    </StyledDetailedDevice>
  );
};

export default observer(DeviceDetailedCard);
