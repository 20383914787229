/** libraries */
import { FC } from 'react';
import { Button, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledConnectNumberFooter } from './styles';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface ConnectNumberFooterProps {
  bind: () => Promise<void>;
  isLoading: boolean;
}

const ConnectNumberFooter: FC<ConnectNumberFooterProps> = ({
  bind,
  isLoading,
}: ConnectNumberFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  if (isTemporaryTokenAuth) {
    return (
      <StyledConnectNumberFooter>
        <Button disabled>Подключить</Button>
        <Text
          style={{ marginLeft: '10px' }}
          color={defaultTheme.colors.warning}
        >
          Действие доступно только клиенту
        </Text>
      </StyledConnectNumberFooter>
    );
  }

  return (
    <StyledConnectNumberFooter>
      <Button onClick={bind} loading={isLoading}>
        Подключить
      </Button>
    </StyledConnectNumberFooter>
  );
};

export default observer(ConnectNumberFooter);
