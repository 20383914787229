/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import {
  Button,
  ButtonStyleTypes,
  H2,
  H3,
  Icon,
  Icons,
  SidePage,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledAddingContact } from '~/components/Blocks/Shared/ContactsAndNotifications/Components/AddingContact/styles';
/** constants */
import { desktop1100 } from '~/components/Grid/constants';
/** stores */
import useSettingsStore from '../../store/useSettingsStore';
import useContactsAndNotificationsStore from '~/components/Blocks/Shared/ContactsAndNotifications/store/useContactsAndNotificationsStore';

const EmailConfirm: FC = () => {
  const {
    emailConfirmStore: { isShowEmailConfirm, setIsShowEmailConfirm },
  } = useSettingsStore();

  const {
    setIsShowContactsAndNotifications,
  } = useContactsAndNotificationsStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <SidePage
      show={isShowEmailConfirm.show}
      onCloseClick={() =>
        setIsShowEmailConfirm({ show: false, hasError: false })
      }
      removeScrollBar
    >
      <StyledAddingContact>
        <div className="result__icon">
          <Icon
            icon={
              isShowEmailConfirm.hasError ? (
                <Icons.NotOkBigIcon />
              ) : (
                <Icons.SuccessIconAnimated />
              )
            }
          />
        </div>
        <>
          {isDesktop1100 ? (
            <H2>
              {isShowEmailConfirm.hasError
                ? 'Ошибка подтверждения электронной почты'
                : 'Электронная почта подтверждена'}
            </H2>
          ) : (
            <H3>
              {isShowEmailConfirm.hasError
                ? 'Ошибка подтверждения электронной почты'
                : 'Электронная почта подтверждена'}
            </H3>
          )}
        </>
        <Button
          styleType={ButtonStyleTypes.SECONDARY}
          onClick={() => {
            setIsShowEmailConfirm({ show: false, hasError: false });
            setIsShowContactsAndNotifications(true);
          }}
        >
          Вернуться к просмотру контактов
        </Button>
      </StyledAddingContact>
    </SidePage>
  );
};

export default observer(EmailConfirm);
