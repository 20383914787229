/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo } from '../Legal.types';

/** методы-помощники */
import { getLineRentInactiveFor30Days } from './utils';

/** интерфейс компонента-шаблона LineRentText */
interface LineRentTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона LineRentText
 * @param {LineRentTextProps} content
 * @returns {React.FC}
 */
const LineRentText: React.FC<LineRentTextProps> = ({
  content,
}: LineRentTextProps) => {
  // Для продуктов СуперХит и пригородных с такими id скрывать шаблон
  const NOT_SHOW_FOR_IDS: number[] = [23, 256, 312];
  const idInArray = NOT_SHOW_FOR_IDS.filter(
    (seriesId) => seriesId === content.seriesId,
  );

  // Скрываем шаблон по условию
  if (content.lineRentInactive <= 0 || idInArray.length) return null;

  /**
   * текст шаблона
   * @returns {JSX.Element}
   */
  const Text = (): JSX.Element => (
    <span>
      Абонентская Линия Связи для Пояса обслуживания 0 предоставляется
      бесплатно, за исключением случаев, когда обслуживание по Договору
      приостановлено. В случае приостановки обслуживания размер ежедневной платы
      составляет {content.lineRentInactive} руб./день (
      {getLineRentInactiveFor30Days(content.lineRentInactive)} руб./30 дней).
    </span>
  );

  return <p>{Text()}</p>;
};

export default LineRentText;
