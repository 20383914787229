/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { IMAGES } from '~/components/Blocks/Templates/Advantages/constants';

/** константы */
import {
  desktop430,
  desktop700,
  desktop940,
} from '~/components/Grid/constants';

/** стилевой компонент пополнения баланса */
export const StyledDeferredPayment = styled.div<{
  isShowBanner: boolean;
  isActivated: boolean;
}>`
  ${({ isShowBanner, isActivated }): string => {
    return `
    position: relative;
    height: auto;
    min-height: 400px;
    width: 100%;
    background: ${
      isShowBanner
        ? `url(${IMAGES.deferredPayment}) no-repeat`
        : defaultTheme.colors.white
    };
    background-position: center center;
    background-size: cover;
    padding: 32px;

    h2, h3 {
      margin: 0 0 16px 0;
    }
    
    .deferred-payment {
      &__unauthorized-text {
        width: auto;
      }

      &__block-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 32px 0 12px 0;

        &__block:first-of-type {
          margin-right: 64px;
        }

        &__block {
          margin-bottom: 20px;
          h3 {
            margin: 0;
          }
          > span {
            margin-bottom: 8px;
          }
          
          &.duration {
            > span {
              margin-bottom: 4px;
            }
          }

          &__status {
            display: flex;
            align-items: center;

            svg {
              margin-right: 8px;
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      &__button-block-wrapper {
        display: flex;
        flex-direction: column;

        &__button-block {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
        }

        &__balance {
          width: 100%;
        }

        &__button {
          width: 100%;
          letter-spacing: -0.03em;
        }

        &__autopayment {
          width: 100%;
          margin: 16px 0 0 0;
        }
      }
    }

    @media (min-width: ${desktop430}px) {
      .deferred-payment {
        &__unauthorized-text {
          width: 334px;
        }
        &__button-block-wrapper {
          &__button {
            width: ${isActivated ? '246px' : '204px'};
          }

          &__balance {
            width: fit-content;
          }

          &__autopayment {
            width: fit-content;
          }
        }
      }
    }

    @media (min-width: ${desktop700}px) {
      .deferred-payment {
        &__button-block-wrapper {
          &__button-block {
            flex-direction: row;
          }
          &__autopayment {
            margin: 0 0 0 24px;
          }
        }
      }
    }

    @media (min-width: ${desktop940}px) {
      height: 400px;

      .deferred-payment {
        &__button-block-wrapper {
          position: absolute;
          bottom: 32px;
        }
      }
    }
    
    `;
  }};
`;
