/** libraries */
import { observer } from 'mobx-react';
import { Button, SidePage, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** components */
import VacationShutdown from '~/components/Blocks/Shared/VacationShutdown/VacationShutdown';
/** styles */
import { StyledFooter } from '~/components/Blocks/Templates/Vacation/style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStateStore from '~/components/Blocks/Templates/Vacation/store/useVacationStore';

const VacationShutdownSP = (): JSX.Element => {
  const {
    contractStateStore: { updateAuthCondition },
    authStore: { isTemporaryTokenAuth },
    vacationStore: {
      isOrdered,
      isResult,
      isCorrect,
      isLoading,
      reset,
      setVacationCancel,
      setVacationStop,
      getVacation,
    },
  } = useRootStore();
  const {
    isVacationShutdownOpen,
    setIsChecked,
    setIsVacationShutdownOpen,
  } = useVacationStateStore();

  /** Отключение услуги Каникулы */
  const disableVacation = () => {
    if (isOrdered) {
      setVacationCancel(getVacation, setIsChecked);
    } else {
      setVacationStop(getVacation, setIsChecked);
    }
  };

  const onCloseSP = async () => {
    if (isResult && isCorrect) {
      await updateAuthCondition();
    }
    setIsChecked(!(isResult && isCorrect));
    reset();
    setIsVacationShutdownOpen(false);
  };
  return (
    <SidePage
      show={isVacationShutdownOpen}
      headerText={!isResult && 'Отключить услугу Каникулы'}
      onCloseClick={onCloseSP}
      footerContainer={
        !isResult && (
          <StyledFooter>
            <Button
              onClick={disableVacation}
              loading={isLoading}
              disabled={isTemporaryTokenAuth}
            >
              Отключить
            </Button>
            {isTemporaryTokenAuth && (
              <Snoska className="snoska" color={defaultTheme.colors.gray}>
                Действие доступно только клиенту
              </Snoska>
            )}
          </StyledFooter>
        )
      }
      isOnlyMobileFooter
    >
      <VacationShutdown />
    </SidePage>
  );
};

export default observer(VacationShutdownSP);
