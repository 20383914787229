/* Библиотеки */
import { useEffect } from 'react';

import * as React from 'react';

/* Типы */
import { Text, H2, Icons, Tabs, TabsStyleTypes } from 'cordis-core-ui-planeta';
import { CallToActionFeeProps } from './CallToActionFee.types';
import { CallToActionFeePriceTypes } from '../CallToAction.types';

/* Константы */
import { TABS_VARIANTS } from '../CallToAction.constants';

/* Стили */
import { StyledLeftCol, StyledRightCol } from './CallToActionFee.style';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';

/* Утилиты */
import { formatNumber, pluralizeAll } from '~/utils/utils';

/* Дочерний компонент "Абонентская плата", компонента CallToAction */
const CallToActionFee: React.FC<CallToActionFeeProps> = ({
  annual,
  daily,
  thirtyDays,
  timeshiftPrice,
  routerFee,
  STBFee,
  distortionDuration,
  setFeePerDay,
}: CallToActionFeeProps) => {
  /* Количество дней отложенного платежа */
  const distortionDurationDays = Math.floor(distortionDuration / 24);
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<number>(
    daily + timeshiftPrice + routerFee + STBFee,
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    const dailyFee = daily + timeshiftPrice + routerFee + STBFee;
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setFeePerDay(dailyFee);
        setActiveTabValue(dailyFee);
        break;
      case 1:
        setActiveTabValue(
          thirtyDays + timeshiftPrice * 30 + routerFee * 30 + STBFee * 30,
        );
        break;
      default:
        setFeePerDay(dailyFee);
        setActiveTabValue(dailyFee);
    }
  };

  useEffect(() => {
    onChangeTab(activeTabIndex);
  }, [timeshiftPrice, routerFee, STBFee]);

  /* Склонение количества дней отложенного платежа */
  const distortionDurationDaysCount = React.useMemo(
    () => pluralizeAll(distortionDurationDays, ['день', 'дня', 'дней']),
    [distortionDurationDays],
  );

  return (
    <CallToActionRow title={<Text>Абонентская плата</Text>}>
      <StyledLeftCol priceType={CallToActionFeePriceTypes[activeTabIndex]}>
        <div>
          <Tabs
            value={TABS_VARIANTS}
            styleType={TabsStyleTypes.SECONDARY}
            onChange={onChangeTab}
            activeTabIndex={activeTabIndex}
          />
        </div>
        <H2>
          {activeTabValue === 0
            ? 'Отсутствует'
            : `${formatNumber(activeTabValue)}\u0020\u20bd`}
        </H2>
        <div>
          {!annual && <Icons.OkIcon />}
          {/* yaspeller ignore:start */}
          <Text>
            {annual
              ? `Подключение\nна год ${annual}\u20bd`
              : `Отложенный платёж\nна ${distortionDurationDaysCount}`}
          </Text>
        </div>
      </StyledLeftCol>
      <StyledRightCol>
        {!annual && <Icons.OkIcon />}
        <Text>
          {annual
            ? `Подключение\nна год ${annual}\u20bd`
            : `Отложенный платёж\nна ${distortionDurationDaysCount}`}
        </Text>
        {/* yaspeller ignore:end */}
      </StyledRightCol>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionFee);
