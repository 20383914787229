/** библиотеки */
import * as React from 'react';

/** компоненты библиотеки */
import { Snoska } from 'cordis-core-ui-planeta';

/** компоненты */
import ConnectionText from './LegalContentTemplates/ConnectionText';
import HintText from './LegalContentTemplates/HintTextText';
import PrepaymentText from './LegalContentTemplates/PrepaymentText';
import LineRentText from './LegalContentTemplates/LineRentText';
import StbRentText from './LegalContentTemplates/StbRentText';
import DeviceOffText from './LegalContentTemplates/DeviceOffText';
import RouterRentText from './LegalContentTemplates/RouterRentText';
import VoiceCallMinutesText from './LegalContentTemplates/VoiceCallMinutesText';
import BusinessTariffsText from './LegalContentTemplates/BusinessTariffsText';
import PhoneText from './LegalContentTemplates/PhoneText';
import DeviceLeaseText from './LegalContentTemplates/DeviceLeaseText';
import BaudRateText from './LegalContentTemplates/BaudRateText';

/** типы */
import { LegalContent } from './Legal.types';

/** стили */
import { StyledLegalBlock } from './Legal.style';
import { parseHtml } from './parser';

/** константы */
import { SeriesCode } from './Legal.const';
import { ConnectionMethod } from '~/constants/common';

/** интерфейс компонента Заголовок страницы */
interface LegalProps {
  content: LegalContent;
}

/**
 * Блок "Legal"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=401223293
 * @param content
 */
const Legal: React.FC<LegalProps> = ({ content }: LegalProps) => {
  const { fields } = content;
  const { contentLegal } = fields;

  const ConnectConditionsText: JSX.Element = (
    <span>{parseHtml(contentLegal.connectConditions)}</span>
  );
  const isLan = contentLegal.connectionMethod === ConnectionMethod.LAN;

  const calculatedText = (): JSX.Element => {
    return (
      <>
        {contentLegal.seriesCode === SeriesCode.LIGHT_14 && isLan ? (
          <p>{parseHtml(contentLegal.connectConditions)}</p>
        ) : (
          <ConnectionText content={contentLegal} />
        )}
        <HintText content={contentLegal} />
        <PhoneText content={contentLegal} />
        <PrepaymentText content={contentLegal} />
        <BaudRateText content={contentLegal} />
        <LineRentText content={contentLegal} />
        <StbRentText content={contentLegal} />
        <RouterRentText content={contentLegal} />
        <DeviceLeaseText content={contentLegal} />
        <DeviceOffText content={contentLegal} />
        {contentLegal && contentLegal.seriesCode !== SeriesCode.LIGHT_14 && (
          <>{ConnectConditionsText}</>
        )}
        {(!isLan || contentLegal.isBundleB2B) && (
          <>
            <VoiceCallMinutesText content={contentLegal} />
            <BusinessTariffsText content={contentLegal} />
          </>
        )}
      </>
    );
  };

  return (
    <StyledLegalBlock>
      <Snoska>
        {fields.content ? parseHtml(fields.content) : calculatedText()}
      </Snoska>
    </StyledLegalBlock>
  );
};

export default Legal;
