/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';

/** Компоненты */
import { defaultTheme, Input, Text } from 'cordis-core-ui-planeta';
import CallbackWizardSuccess from './CallbackWizardSuccess';

/** стилевые компоненты */
import { StyledCallbackWizard } from './CallbackWizard.style';

/** store */
import useCallbackStore from './store/useCallbackStore';

const CallbackWizard: FC = () => {
  const {
    isCompleted,
    phone,
    phoneError,
    setPhone,
    setPhoneError,
    validateTelephone,
  } = useCallbackStore();
  return (
    <>
      {isCompleted ? (
        <CallbackWizardSuccess />
      ) : (
        <StyledCallbackWizard>
          <div className="callbackWizard__label">
            <Text>Ваш телефон</Text>
          </div>
          <div>
            <Input
              className="callbackWizard__input"
              type="phone"
              value={phone}
              onChangeCustomInput={(event): void => {
                if (phoneError) setPhoneError('');
                setPhone(event.value);
              }}
              onBlur={validateTelephone}
              width="100%"
              error={!!phoneError}
            />
          </div>
          {phoneError && (
            <Text color={defaultTheme.colors.planeta}>{phoneError}</Text>
          )}
        </StyledCallbackWizard>
      )}
    </>
  );
};

export default observer(CallbackWizard);
