import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
`;

export const Header = styled.span`
  margin-bottom: 17px;
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'space-between' : 'flex-start'};
  width: 100%;

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            text-align: left;
          `
        : css`
            margin-bottom: 46px;
          `}
  }

  > div {
    top: 2px;
  }
`;
