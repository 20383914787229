import styled from '@emotion/styled';
import { desktop940 } from '~/components/Grid/constants';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: start;
  white-space: pre-line;

  @media (min-width: ${desktop940}px) {
    align-items: start;
  }
`;

export const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    gap: 24px;
    align-items: start;
    justify-content: start;
  }
`;

export const StyledLoaderWrapper = styled.div`
  width: 212px;
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 43px;
  max-width: 494px;
  white-space: initial;

  @media (min-width: ${desktop940}px) {
    align-items: start;
    white-space: pre-line;
  }
`;
