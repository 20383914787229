import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledMobileTable = styled.div`
  .table {
    &__products {
      display: flex;
      flex-direction: column;
    }
    &__product {
      margin-bottom: 16px;
    }

    &__product:last-of-type {
      margin-bottom: 0;
    }

    &__border {
      border-bottom: 1px solid ${defaultTheme.colors.disable};
      margin: 24px 0;

      &__header {
        margin-bottom: 8px;
      }
    }

    &__line-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      &__date-block {
        display: flex;
        > span:first-of-type {
          margin-right: 8px;
        }
      }

      &__date,
      &__comment,
      &__price {
        margin-bottom: 8px;
      }
    }
  }
`;
