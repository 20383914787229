/* Библиотеки */
import * as React from 'react';

/* Типы */
import { Text, H3 } from 'cordis-core-ui-planeta';
import { CallToActionPrepaymentProps } from './CallToActionPrepayment.types';

/* Стили */
import { StyledPrepaymentContent } from './CallToActionPrepayment.style';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';

/* Утилиты */
import { formatNumber } from '~/utils/utils';

/* Дочерний компонент "Итого, единовременный платёж" компонента CallToAction */
const CallToActionPrepayment: React.FC<CallToActionPrepaymentProps> = ({
  prepaymentPrice,
}: CallToActionPrepaymentProps) => (
  <CallToActionRow title={<Text>Итого, единовременный платёж</Text>}>
    <StyledPrepaymentContent>
      <H3>
        {prepaymentPrice === 0
          ? 'Бесплатно'
          : `${formatNumber(prepaymentPrice)}\u0020\u20bd`}
      </H3>
    </StyledPrepaymentContent>
  </CallToActionRow>
);

export default React.memo(CallToActionPrepayment);
