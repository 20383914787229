/* Библиотеки */
import styled from '@emotion/styled';

/* Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** Константы */
import {
  desktop500,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

export const VoiceCommunicationWrapper = styled.div`
  a: hover {
    text-decoration: none;
  }
`;

export const VoiceCommunicationContainer = styled.div`
  position: relative;
  padding: 32px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${defaultTheme.colors.white};
  box-sizing: border-box;
  min-width: 280px;

  h2 {
    margin-bottom: 0;
    line-height: 101.8%;
  }

  .voice-communication {
    &__title {
      h2 {
        font-size: 28px;
      }
    }

    &__phone-image {
      display: none;
    }

    &__box {
      display: flex;
    }

    &__item {
      &:first-of-type {
        display: none;
      }
    }

    &__text1 {
      margin: 10px 0 53px;
    }

    &__text2,
    &__text4 {
      margin-top: 19px;
    }

    &__text3 {
      margin-bottom: 3px;
    }

    &__item {
      &:nth-of-type(2) {
        width: 240px;
      }
    }
  }

  @media (min-width: ${desktop500}px) {
    justify-content: flex-start;
    .voice-communication {
      &__title {
        br {
          display: none;
        }
      }
      &__text1 {
        margin: 13px 0 105px;
        br:first-of-type {
          display: none;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    justify-content: space-between;
    padding: 36px 32px 33px;
    width: 100%;

    .voice-communication {
      &__title {
        h2 {
          font-size: 44px;
        }
      }

      &__text1 {
        margin: 26px 0 41px;
        width: 240px;
      }

      &__text3 {
        margin-bottom: 4px;
      }

      &__text4 {
        margin-top: 24px;
      }

      &__item {
        &:first-of-type {
          margin: 0 55px 0 5px;
          width: 264px;
          display: block;
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .voice-communication {
      &__phone-image {
        display: none;
      }
      &__item {
        &:first-of-type {
          margin: 0 105px 0 5px;
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .voice-communication {
      &__phone-image {
        position: absolute;
        right: 17px;
        bottom: 0;
        height: 385px;
        width: 215px;
        display: block;
      }

      &__item {
        &:first-of-type {
          margin: 0 55px 0 5px;
        }
      }
    }
  }
`;
