/** libraries */
import { FC, useEffect, useState } from 'react';
import { Loader } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** constants */
import { CardStates, ServiceNames, serviceInfo } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import { MyTVStyled } from '../../styles';
/** components */
import ChannelDescriptionCard from '../Cards/ChannelDescriptionCard/ChannelDescriptionCard';
import PackageDescriptionCard from '../Cards/PackageDescriptionCard/PackageDescriptionCard';
import MainPage from '../MainPage';
import ViewControl from '../Services/ViewControl/ViewControl';
import Wink from '../Services/Wink/Wink';
/** interfaces */
import { Channel } from '../../interfaces';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMyTVX3Store from '../../stores/MyTvX3Store';

const MyTVx3: FC = () => {
  const {
    authStore: { isLoadingAuth },
    tvStore: { tvChannelPackages, channelsList },
  } = useRootStore();
  const {
    activePackageCard,
    setActivePackageCard,
    activeChannelCard,
    setActiveChannelCard,
    cardState,
    setCardState,
    setIsDesktop940,
  } = useMyTVX3Store();
  /** Флаг загрузки блока */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  /** Обработчик клика на карточку сервиса  */
  const onClickServiceCard = (service): void => {
    const { name } = service;
    switch (name) {
      case ServiceNames.VIEW_CONTROL:
        setCardState(CardStates.VIEW_CONTROL_SERVICE);
        break;
      case ServiceNames.WINK:
        setCardState(CardStates.WINK_SERVICE);
        break;
      default:
    }
  };

  /** Изменение активной карточки при подключении/отключении пакета */
  useEffect(() => {
    if (activePackageCard) {
      const activePackage = tvChannelPackages.find((item) => {
        if (activePackageCard?.id) {
          return item.channelPackId === activePackageCard.id;
        }
        return item.channelPackId === activePackageCard.channelPackId;
      });
      if (activePackage) setActivePackageCard(activePackage);
    }
  }, [tvChannelPackages]);

  /** Изменение активной карточки при подключении/отключении канала */
  useEffect(() => {
    if (
      activeChannelCard?.isChannelWrapper ||
      (activeChannelCard?.singleChannelPacks?.length &&
        activeChannelCard?.singleChannelPacks[0])
    ) {
      const activeChannel = channelsList.find((item) => {
        if (activeChannelCard.isChannelWrapper) {
          return item.channelPackId === activeChannelCard.channelPackId;
        }
        return item?.weburgId && activeChannelCard?.weburgId
          ? item.weburgId === activeChannelCard.weburgId
          : item.id === activeChannelCard.id;
      });
      if (activeChannel)
        setActiveChannelCard((activeChannel as unknown) as Channel);
    }
  }, [channelsList]);

  useEffect(() => {
    setIsLoading(isLoadingAuth);
  }, [isLoadingAuth]);

  useEffect(() => {
    setIsDesktop940(isDesktop940);
  }, [isDesktop940]);

  return (
    <MyTVStyled
      isAutoHeight={[
        CardStates.CHANNEL_DESCRIPTION,
        CardStates.PACKAGE_DESCRIPTION,
        CardStates.VIEW_CONTROL_SERVICE,
        CardStates.WINK_SERVICE,
      ].includes(cardState)}
      isLoading={isLoading}
    >
      <div>
        {isLoading ? (
          <div className="my-tv__loader">
            <Loader />
          </div>
        ) : (
          <>
            <div
              className={cardState === CardStates.MAIN ? '' : 'my-tv__hidden'}
            >
              <MainPage
                onClickServiceCard={onClickServiceCard}
                serviceInfo={serviceInfo}
              />
            </div>
            {cardState === CardStates.CHANNEL_DESCRIPTION && (
              <ChannelDescriptionCard />
            )}
            {cardState === CardStates.PACKAGE_DESCRIPTION && (
              <PackageDescriptionCard onClickServiceCard={onClickServiceCard} />
            )}
            {cardState === CardStates.VIEW_CONTROL_SERVICE && <ViewControl />}
            {cardState === CardStates.WINK_SERVICE && <Wink />}
          </>
        )}
      </div>
    </MyTVStyled>
  );
};

export default observer(MyTVx3);
