/** Библиотеки */
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** Компоненты */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  Icons,
  LinkButton,
  Text,
  Icon,
  Link as CordisLink,
} from 'cordis-core-ui-planeta';
import Link from 'next/link';
import CityPopup from '../CityPopup';
import {
  StyledLeftPanel,
  StyledRightPanelMobile,
  StyledLeftPanelMobile,
  StyledRightPanel,
} from '../style';
import {
  StyledBrandLayout,
  StyledBrandLayoutMobile,
  StyledLogo,
  StyledMobilePanelsWrapper,
  StyledMobileCityConfirm,
} from './style';
import { StyledHumbuggerMobile } from '~/components/Header/ContentLayout/style';

/** Константы */
import { desktop940 } from '~/components/Grid/constants';

/** Утилиты */
import { getHeaderPhone } from '../utils';
import { DEFAULT_CITY, DEFAULT_LK_LINK } from '~/constants/common';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/** интерфейс панели быстрых ссылок */
interface BrandLayoutProps {
  toggleHandler: () => void;
  hamburgerOpen: boolean;
  openCitiesHandler: () => void;
  isConfirmShow: boolean;
  closeConfirmHandler: () => void;
  isCitiesOpen: boolean;
  toggleCitiesOpen: () => void;
}

/**
 * Блок меню "Бренд-панель"
 * @returns {React.FC}
 */
const BrandLayout: React.FC<BrandLayoutProps> = ({
  toggleHandler,
  hamburgerOpen,
  openCitiesHandler,
  isConfirmShow,
  closeConfirmHandler,
  isCitiesOpen,
  toggleCitiesOpen,
}: BrandLayoutProps) => {
  // Проверка размера экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    cityStore: { city, setCity, cities, contactPhones },
    authStore: { isAuth, removeAuth, isTemporaryTokenAuth, auth },
  } = useRootStore();
  const { toggleCallbackWizardVisible } = useCallbackStore();
  const { toggleVisible } = useMakeAuthStore();
  const router = useRouter();

  useEffect(() => {
    if (isAuth) {
      setCity(cities.find((c) => c.id === auth.cityId) ?? DEFAULT_CITY);
    }
  }, [isAuth]);

  // Номер телефона
  const headerPhone = getHeaderPhone(contactPhones, city);

  // Форматирование номера телефона
  const planetaPhone = headerPhone.replace(/\D/g, '');

  /**
   * вызывает клик по кнопке открытия чата
   */
  const openChat = (): void => {
    const button = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (button) button.click();
  };

  /** подтвердить город */
  const onConfirmCity = (): void => {
    closeConfirmHandler();
    setCity(city);
  };

  /** открыть список городов с контактами */
  const onPhoneClick = (): void => {
    if (isConfirmShow) closeConfirmHandler();
    toggleHandler();
    openCitiesHandler();
  };

  /** сменить выбранный город */
  const onCancelCity = (): void => {
    closeConfirmHandler();
    toggleHandler();
    openCitiesHandler();
  };

  /** закрыть блок подтверждения города */
  const closeBtnClickHandler = (): void => {
    closeConfirmHandler();
  };

  /** Ссылка на Личный кабинет клиента */
  const redirectToLK = () => router.push(DEFAULT_LK_LINK, DEFAULT_LK_LINK);

  const BrandLayoutDesktop = (): JSX.Element => (
    <StyledBrandLayout>
      <StyledLeftPanel>
        <Link href="/">
          <StyledLogo
            src={`${process.env.STATIC_SERVER}/static/images/planeta_logo.svg`}
            alt="Лого"
          />
        </Link>
        <CityPopup
          isConfirmShow={isConfirmShow}
          closeConfirmHandler={closeConfirmHandler}
          isCitiesPopupOpen={isCitiesOpen}
          toggleCitiesPopup={toggleCitiesOpen}
        />
      </StyledLeftPanel>
      <StyledRightPanel>
        <LinkButton color={defaultTheme.colors.pink} onClick={openChat}>
          <Icons.ChatIcon />
          Чат
        </LinkButton>
        <LinkButton
          color={defaultTheme.colors.pink}
          onClick={toggleCallbackWizardVisible}
        >
          Заказать звонок
        </LinkButton>
        <CordisLink href={`tel:${planetaPhone}`}>
          <Text color={defaultTheme.colors.white} fontWeightBold>
            {headerPhone}
          </Text>
        </CordisLink>
      </StyledRightPanel>
    </StyledBrandLayout>
  );

  const layoutDesktopMemo = useMemo(() => <BrandLayoutDesktop />, [
    isTemporaryTokenAuth,
    isCitiesOpen,
    isConfirmShow,
    contactPhones.length,
  ]);

  const BrandLayoutMobile = (): JSX.Element => (
    <StyledBrandLayoutMobile>
      {isConfirmShow && (
        <StyledMobileCityConfirm>
          <div className="mobileCityConfirm__btn-close">
            <span
              onClick={closeBtnClickHandler}
              onKeyPress={closeBtnClickHandler}
              role="button"
              tabIndex={0}
              style={{ outline: 'none' }}
              data-test-button=""
            >
              <Icon icon={<Icons.CloseIcon />} />
            </span>
          </div>
          <Text
            className="mobileCityConfirm__title"
            color={defaultTheme.colors.light}
          >{`Ваш город ${city.name}?`}</Text>
          <div>
            <Button
              styleType={ButtonStyleTypes.SECONDARY}
              onClick={onConfirmCity}
              background="inherit"
              border={`1px solid ${defaultTheme.colors.white}`}
              color={defaultTheme.colors.white}
              minWidth="86px"
            >
              Да
            </Button>
            <LinkButton
              color={defaultTheme.colors.light}
              onClick={onCancelCity}
            >
              Выбрать другой
            </LinkButton>
          </div>
        </StyledMobileCityConfirm>
      )}
      <StyledMobilePanelsWrapper>
        <StyledLeftPanelMobile>
          <StyledHumbuggerMobile
            className="hamburger"
            role="button"
            onClick={toggleHandler}
            onKeyPress={toggleHandler}
            tabIndex={0}
            isOpened={hamburgerOpen}
            data-test-button=""
          >
            <Icon icon={<Icons.HamburgerIcon />} />
          </StyledHumbuggerMobile>
          <Link href="/">
            <a className="planeta-icon">
              <Icons.PlanetaIcon />
            </a>
          </Link>
        </StyledLeftPanelMobile>
        <StyledRightPanelMobile>
          <LinkButton
            color={defaultTheme.colors.pink}
            onClick={openChat}
            className="chat-icon"
          >
            <Icons.ChatIcon />
          </LinkButton>
          {!isAuth ? (
            <LinkButton
              color={defaultTheme.colors.pink}
              onClick={onPhoneClick}
              className="phone-icon"
            >
              <Icons.PhoneIcon />
            </LinkButton>
          ) : (
            <LinkButton color={defaultTheme.colors.pink} onClick={redirectToLK}>
              <Icons.CabinetIcon />
            </LinkButton>
          )}
          {!isAuth && (
            <Button
              className="enter-button"
              styleType={ButtonStyleTypes.SECONDARY}
              background="inherit"
              border={`1px solid ${defaultTheme.colors.white}`}
              color={defaultTheme.colors.white}
              minWidth="79px"
              onClick={() => toggleVisible()}
            >
              Войти
            </Button>
          )}

          {isAuth && (
            <LinkButton
              color={defaultTheme.colors.pink}
              onClick={() => removeAuth(true)}
              className="logout-icon"
            >
              <Icons.LogoutIcon />
            </LinkButton>
          )}
        </StyledRightPanelMobile>
      </StyledMobilePanelsWrapper>
    </StyledBrandLayoutMobile>
  );

  return isDesktop940 ? layoutDesktopMemo : <BrandLayoutMobile />;
};

export default observer(BrandLayout);
