import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Image from 'next/image';

export const ImageWrapper = styled(Image)`
  z-index: 2;
`;

export default styled.div<{ $hasImgZoom: boolean }>`
  // Image не воспринимает атрибуты от styled components
  ${ImageWrapper} {
    ${({ $hasImgZoom }) =>
      $hasImgZoom &&
      css`
        cursor: pointer;
      `}
  }
`;
