/** libraries */
import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
/** components */
import { Button } from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';
/** config */
import { PAYMENT_SLUG } from '~/constants/common';
import { desktop1280 } from '~/components/Grid/constants';

const Root = styled.div`
  margin-top: 16px;
`;

const Debt: FC = () => {
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  return (
    <Root>
      <LinkWrapper href={`/${PAYMENT_SLUG}`}>
        <Button>
          {isDesktop1280 ? 'Пополнить лицевой счёт' : 'Пополнить счёт'}
        </Button>
      </LinkWrapper>
    </Root>
  );
};

export default memo(Debt);
