import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop1280, desktop330 } from '../Grid/constants';

/** стилизованный блок с меню */
/** отступы на 17px и 34px из-за remove scroll при открытии Popup, который сдвигает body на 17px, p.s. костыль */
export const StyledHeaderWrapper = styled.div<{
  $isTemporaryTokenAuth: boolean;
}>`
  width: 100vw;
  position: relative;
  background: ${({ $isTemporaryTokenAuth }) =>
    $isTemporaryTokenAuth
      ? defaultTheme.colors.gray
      : defaultTheme.colors.planeta};
  display: flex;
  position: fixed;
  justify-content: center;
  z-index: 101;
  top: 0;

  .header-padding {
    padding-top: 60px;
  }
  .wrapper {
    width: 100%;
    max-width: ${desktop1280}px;
  }
  &.hidden {
    top: -120px;
    transition: top 500ms;

    &:hover {
      top: -60px;
    }
    /** элемент для обработки наведения  */
    &::after {
      content: '';
      position: absolute;
      bottom: -30px;
      height: 30px;
      width: 100%;
    }
  }
  &.small {
    top: -60px;
    transition: top 500ms;
  }
  &.small__mobile {
    top: 0px;
    transition: top 500ms;
  }
`;

/** стилизованный компонент меню */
export const StyledHeader = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
`;

/** стилизованная панель меню левая */
export const StyledLeftPanel = styled.div`
  display: flex;
  align-items: center;

  .hamburger {
    outline: none;
    svg {
      stroke: ${defaultTheme.colors.white};
    }
    &:hover {
      background: ${defaultTheme.colors.pinkSelect};
      cursor: pointer;
    }
  }
`;

/** стилизованная панель мобильного меню левая */
export const StyledLeftPanelMobile = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 2;
  justify-content: flex-start;
  margin-left: 4px;
  a {
    position: relative;
    margin: 0 25px 0 0;
  }
  svg {
    fill: ${defaultTheme.colors.white};
    stroke: none;
  }
  .hamburger {
    outline: none;
    margin-right: 11px;
    svg {
      stroke: ${defaultTheme.colors.white};
    }
    &:hover {
      background: ${defaultTheme.colors.pinkSelect};
      cursor: pointer;
    }
  }
  .planeta-icon {
    display: none;
  }

  @media (min-width: ${desktop330}px) {
    .planeta-icon {
      display: block;
    }
  }
`;

/** стилизованная панель меню правая */
export const StyledRightPanel = styled.div`
  display: flex;
  align-items: center;
  a + a {
    margin-left: 16px;
  }

  svg {
    stroke: ${defaultTheme.colors.pink};
    stroke-width: 2px;
    fill: none !important;
    &:hover {
      stroke: ${defaultTheme.colors.white};
    }
  }

  .logout-icon {
    margin: 0 0 0 10px;
    & svg {
      margin: 0;
    }
  }
`;

/** стилизованная панель мобильного меню правая */
export const StyledRightPanelMobile = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;

  svg {
    stroke: ${defaultTheme.colors.white};
    stroke-width: 2px;
    fill: none !important;
  }
  .phone-icon {
    margin-right: 20px;
  }
  .chat-icon {
    margin-right: 6px;
  }
  .exit-icon {
    margin-right: 0;
  }
  .enter-button {
    padding: 10px 16px;

    span {
      font-size: 16px;
      letter-spacing: -0.03em;
    }
  }
`;
