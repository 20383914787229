/** библиотеки */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop430,
  desktop940,
  desktop1100,
  desktop1280,
  desktop475,
  desktop600,
} from '~/components/Grid/constants';

export const StyledQrSidePage = styled.div`
  .qr {
    &__code-block {
      display: flex;
      flex-direction: column;
    }
    &__text-block {
      margin: 16px 0 0 0;
      &__timer {
        margin-top: 16px;
        &__loader {
          display: flex;
          align-items: center;
          margin-top: 8px;

          > div:nth-of-type(2) {
            margin-left: 8px;
          }
        }
      }
    }

    &__warning {
      padding: 16px;
      background-color: ${defaultTheme.colors.backgroundWarning};
      margin-top: 16px;
      &__banks {
        cursor: pointer;
      }
    }

    &__snoska {
      margin-top: 32px;
      a {
        color: ${defaultTheme.colors.planeta};
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .qr {
      &__code-block {
        flex-direction: row;
      }
      &__text-block {
        margin: 0 0 0 24px;
        &__timer {
          margin-top: 24px;
        }
      }
      &__warning {
        margin-top: 40px;
      }
      &__snoska {
        position: absolute;
        bottom: 32px;
      }
    }
  }
`;

/** стилевой компонент пополнения баланса */
export const StyledPayment = styled.div<{ hasBorder?: boolean; size: number }>`
  ${({ hasBorder, size }) => {
    return css`
      height: auto;
      width: 100%;
      background: ${defaultTheme.colors.white};
      border: 1px solid ${hasBorder ? defaultTheme.colors.disable : 'none'};

      h3[data-test-sidepage-header] {
        white-space: pre-wrap;

        @media (min-width: ${desktop475}px) {
          white-space: nowrap;
        }
      }

      @media (min-width: ${desktop940}px) {
        min-height: 400px;
        height: 400px;
      }
    `;
  }};
`;

export const StyledPaymentX2Form = styled.div<{
  isOpenSBPCards?: boolean;
  isAuth: boolean;
  isChangeContract: boolean;
  isSBP?: boolean;
}>`
  ${({ isOpenSBPCards, isAuth, isChangeContract, isSBP }) => {
    return css`
      height: 100%;
      position: relative;
      padding: 32px;

      ${isOpenSBPCards &&
      `div[data-test-sidepage]:after {
        content: '';
        background: white;
        bottom: -20vh;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 20vh;
        z-index: 999;
      }`}

      .payment-form {
        &__header {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 22px;

          h3 {
            margin: 0;
          }

          &__link {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }

        &__tags {
          margin-right: 4px;
          margin-top: 7px;

          .tagButton__text,
          input:checked ~ .tagButton__text {
            background: ${defaultTheme.colors.light};
            color: ${defaultTheme.colors.black};
            border-color: ${defaultTheme.colors.light};
          }

          .tagButton__text:hover,
          input:checked ~ .tagButton__text:hover {
            background: ${defaultTheme.colors.disable};
            border-color: ${defaultTheme.colors.disable};
            color: ${defaultTheme.colors.black};
          }
        }

        &__wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: ${isAuth && !isChangeContract
            ? 'flex-start'
            : 'space-between'};

          &__block {
            display: flex;
            flex-direction: column;
            width: ${isAuth && !isChangeContract ? '100%' : '48%'};
            margin-bottom: 16px;

            &__card-block-half {
              width: 100%;
              flex-grow: ${isAuth && !isChangeContract ? '0' : '1'};
            }

            input {
              width: 100%;
              border-radius: 0;
            }
            input: disabled {
              background-color: #fff;
            }
            .select-wrapper .select-overlay {
              width: 100%;
            }
          }

          &__block-text {
            margin-bottom: 10px;
          }

          &__block-card {
            .select-wrapper {
              width: 100%;
            }
            .expired {
              .option-text,
              .option-bottomText {
                color: ${defaultTheme.colors.planeta};
              }
            }
          }

          &__errorBlock {
            display: flex;
            flex-direction: column;
          }

          &__text {
            margin-bottom: 10px;
          }
        }

        &__checkbox {
          margin-bottom: 24px;
        }

        &__button-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;

          &__checkbox {
            position: static;
            display: block;
            margin-bottom: 16px;

            .checkMark {
              margin-right: 9px;
            }
          }

          button {
            margin-bottom: 8px;
            width: max-content;
            transition: none;

            .button__content {
              display: flex;
              align-items: center;
            }

            .sbp-icon {
              display: inline;
              height: 32px;
              margin-right: 5px;

              svg path {
                fill: white;
              }
            }
          }

          &__snoska {
            &__link {
              cursor: pointer;
            }
          }
        }
      }

      .displayNone {
        display: none;
      }

      @media (min-width: ${desktop430}px) {
        .payment-form__wrapper__block {
          margin-right: ${isAuth && !isChangeContract ? '16px' : '0'};
          width: ${isAuth && !isChangeContract ? '336px' : '48%'};
        }
      }

      @media (min-width: ${desktop600}px) {
        .payment-form__wrapper {
          flex-wrap: ${isAuth && !isChangeContract ? 'nowrap' : 'wrap'};

          &__block {
            margin-right: ${isAuth && !isChangeContract ? '16px' : '0'};
            width: ${isAuth && !isChangeContract ? '30%' : '49%'};

            &__card-block-half {
              width: ${isAuth && !isChangeContract ? '336px' : '32%'};
            }
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .payment-form {
          &__header {
            flex-direction: row;
            margin-bottom: 18px;
            align-items: baseline;
            flex-wrap: nowrap;
          }

          &__wrapper {
            margin-bottom: 0;
            flex-wrap: nowrap;
            &__block {
              width: ${isAuth && !isChangeContract ? '178px' : '32%'};
              margin-bottom: 8px;
              margin-right: 16px;

              &__card-block-half {
                width: ${isAuth && !isChangeContract ? '336px' : '32%'};
              }
            }
          }

          &__button-wrapper {
            position: absolute;
            bottom: 30px;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            &__checkbox {
              position: absolute;
              right: auto;
              top: ${isSBP ? '20px' : '15px'};
              left: ${isSBP ? '340px' : '260px'};
            }

            button {
              margin: 0 32px 8px 0;
            }
          }
        }
      }

      @media (min-width: ${desktop1280}px) {
        .payment-form {
          &__wrapper {
            flex-wrap: nowrap;
            &__block {
              margin-bottom: 16px;
              width: ${isAuth && !isChangeContract ? '200px' : '26%'};
              margin-bottom: 8px;

              &__card-block-half {
                width: ${isAuth && !isChangeContract ? '336px' : '32%'};
              }
            }
          }

          &__button-wrapper {
            flex-direction: row;
            align-items: center;

            &__snoska {
              width: 100%;
            }
          }
        }
      }
    `;
  }}
`;

export const StyledPaymentX1Form = styled.div<{
  isOpenSBPCards?: boolean;
  isAuth?: boolean;
  showCheckbox?: boolean;
  isChangeContract?: boolean;
}>`
  ${({ isOpenSBPCards, isAuth, showCheckbox, isChangeContract }) => {
    return css`
      height: 100%;
      position: relative;
      padding: 26px 32px;
      display: flex;
      flex-wrap: wrap;

      ${isOpenSBPCards &&
      `div[data-test-sidepage]:after {
        content: '';
        background: white;
        bottom: -20vh;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 20vh;
        z-index: 999;
      }`}

      .payment-form {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 32px;
          flex-wrap: wrap;
          align-self: flex-start;

          &__link {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 6px;
          }
        }

        &__wrapper {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0;
          justify-content: space-between;

          &__block {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 16px;

            input {
              border-radius: 0;
            }
            input: disabled {
              background-color: #fff;
            }
            .select-wrapper .select-overlay {
              width: 100%;
            }
          }

          &__block-text {
            margin-bottom: 11px;
          }

          &__block-card {
            .select-wrapper {
              width: 100%;
            }
            .expired {
              .option-text,
              .option-bottomText {
                color: ${defaultTheme.colors.planeta};
              }
            }
          }

          &__errorBlock {
            display: flex;
            flex-direction: column;
          }

          &__text {
            margin-bottom: 10px;
          }
        }

        &__button-wrapper {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: wrap;
          align-self: flex-end;
          width: 100%;

          &__checkbox {
            position: static;
            display: block;
            margin-bottom: ${showCheckbox && isChangeContract
              ? '16px'
              : '20px'};
          }

          button {
            width: 100%;
            max-width: 300px;
            margin-bottom: 9px;
            transition: none;
            padding: 12px 0;

            .button__content {
              display: flex;
              align-items: center;
            }

            .sbp-icon {
              display: inline;
              height: 32px;
              margin-right: 5px;

              svg path {
                fill: white;
              }
            }
          }

          &__snoska {
            &__link {
              cursor: pointer;
            }
          }
        }
      }

      .displayNone {
        display: none;
      }

      @media (min-width: ${desktop430}px) {
        .payment-form {
          &__header {
            margin-bottom: 16px;
          }

          &__wrapper__block {
            width: ${isChangeContract || !isAuth ? '49%' : '100%'};

            &__card-block-100 {
              width: 100%;

              .select-wrapper .select-overlay {
                width: 100%;
              }
            }

            &__card-block-half {
              flex-grow: 1;
            }
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .payment-form {
          &__header {
            margin-bottom: ${isChangeContract || !isAuth ? '16px' : '10px'};

            &__link > a {
              font-size: 11px;
            }
          }

          &__button-wrapper button {
            width: 100%;
            max-width: unset;
          }

          &__wrapper {
            &__block {
              width: ${isChangeContract || !isAuth ? '49%' : '100%'};
              margin-bottom: ${isChangeContract || !isAuth ? '16px' : '9px'};
            }

            &__text {
              margin-top: -7px;
              margin-bottom: 0;

              > span {
                font-size: 11px;
              }
            }
          }
        }
      }

      @media (min-width: ${desktop1100}px) {
        .payment-form {
          &__header {
            margin-bottom: ${isChangeContract || !isAuth ? '16px' : '20px'};

            &__link > a {
              font-size: 16px;
            }
          }
          &__wrapper__block {
            width: ${isChangeContract || !isAuth ? '49%' : '34%'};
          }
          &__input-wrapper_money {
            margin-right: ${isChangeContract || !isAuth ? '' : '17px'};
          }

          &__wrapper__text {
            margin-top: -7px;
            margin-bottom: 10px;

            > span {
              font-size: 16px;
            }
          }
        }
      }
    `;
  }}
`;
