import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
/** constants */
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop940,
} from '~/components/Grid/constants';

export const StyledContactsAndNotifications = styled.div`
  .contacts-notifications {
    &__quiet-hour {
      &__header {
        display: flex;
        > span {
          margin-right: 36px;
        }

        &__result {
          display: flex;
          align-items: center;
          > svg {
            margin-right: 8px;
          }
        }
      }
      &__switcher {
        margin: 29px 0 24px;
      }
      &__time-interval {
        display: flex;
        flex-direction: column;

        &__select {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;

          > div {
            margin-top: 8px;
            .select-wrapper {
              width: 100%;
              .select-overlay {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      &__table {
        display: flex;
        flex-direction: column;
        &__line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 15px 16px 12px 16px;
          &__contact {
            max-width: 154px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &__value {
            display: flex;
            align-items: center;
            order: 1;
            margin-right: 16px;
            max-width: fit-content;
            width: 65%;
          }
          &__switcher {
            margin-left: 0;
            margin-top: 12px;
            order: 3;
            width: 100%;

            td {
              vertical-align: middle;
              > .text {
                display: flex;
                align-items: center;
              }
            }
          }
          &__confirm {
            order: 3;
            margin-left: 0;
            margin-top: 12px;
            width: 100%;
            cursor: pointer;
          }
          &__delete {
            order: 2;
            cursor: pointer;
            margin-left: auto;
          }
        }
        &__line:not(:last-of-type) {
          border-bottom: 1px solid ${defaultTheme.colors.line};
        }
      }
      &__add-button-mobile {
        margin: 16px 0;
        width: 100%;
        > span {
          letter-spacing: -0.03em;
        }
      }
      &__add-button-desktop {
        margin-top: 17px;
        width: 237px;
        > span {
          letter-spacing: -0.03em;
        }
      }
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .cursor-default {
    cursor: default;
  }

  @media (min-width: ${desktop500}px) {
    .contacts-notifications {
      &__contacts {
        &__table {
          &__line {
            &__contact {
              max-width: 119px;
            }
            &__value {
              order: 1;
              width: inherit;
            }
            &__switcher {
              order: 2;
              margin-left: auto;
              margin-top: 0px;
              width: inherit;
            }
            &__confirm {
              order: 2;
              margin-left: auto;
              margin-top: 0px;
              width: inherit;
            }
            &__delete {
              order: 3;
              margin-left: 24px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .contacts-notifications {
      &__quiet-hour {
        &__time-interval {
          display: flex;
          flex-direction: row;
          &__select {
            display: flex;
            flex-direction: column;
            margin: 0 16px 24px 0;

            > div {
              .select-wrapper {
                width: 232px;
                .select-overlay {
                  width: 232px;
                }
              }
            }
          }
        }
      }

      &__contacts {
        margin-top: 56px;
        &__table {
          margin-top: 17px;
          &__line {
            &__contact {
              max-width: 210px;
            }
            &__value {
              width: inherit;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .contacts-notifications {
      &__contacts {
        &__table {
          &__line {
            &__contact {
              max-width: 330px;
            }
          }
        }
      }
    }
  }
  @media (min-width: ${desktop1280}px) {
    .contacts-notifications {
      &__contacts {
        &__table {
          &__line {
            &__contact {
              max-width: 442px;
            }
          }
        }
      }
    }
  }
`;

export const StyledCommonError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 8px;
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
