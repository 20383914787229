/** библиотеки */
import * as React from 'react';
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { H1, Text } from 'cordis-core-ui-planeta';

/** типы */
import { HeaderInfo, StyledHeaderInfoProps } from './ExampleBlock.types';

/** интерфейс компонента Заголовок страницы */
interface ExampleBlockProps {
  content: HeaderInfo;
}

const StyledHeader = styled.div<StyledHeaderInfoProps>`
  ${({ hasBorder }): string => `
  border: ${hasBorder ? 'solid' : 'none'};
  word-break: break-word;
  .header {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  `}
`;

/**
 * компонент Заголовок страницы
 * @param content
 */
const ExampleBlock: React.FC<ExampleBlockProps> = ({
  content,
}: ExampleBlockProps) => {
  const { fields } = content;
  return (
    <StyledHeader hasBorder={fields.hasBorder}>
      <H1 className="header">{fields.header}</H1>
      {fields.description && (
        <Text className="description">{fields.description}</Text>
      )}
    </StyledHeader>
  );
};

export default ExampleBlock;
