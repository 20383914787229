/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Icons,
  Input,
  Popup,
  Snoska,
  Switcher,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { nanoid } from 'nanoid';
/** styles */
import { StyledRates } from './styles';
/** constants */
import {
  desktop940,
  desktop1280,
  desktop1100,
} from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/constants/common';
import { DIRECTION } from '../../constants';
/** interfaces */
import { CodePrice } from '../../interfaces';
/** components */
import CostByDestination from '../../Lightboxes/CostByDestination/CostByDestination';
/** api */
import { getCallPrice } from '~/api/apiPab2c';
/** utils */
import {
  convertExPhoneNumberForQuery,
  convertRuPhoneNumberForQuery,
} from '../../utils';
import { formatNumber } from '~/utils/utils';

const Rates: FC = () => {
  const [isForeign, setIsForeign] = useState<boolean>(false);
  /** код  */
  const [code, setCode] = useState<string>(isForeign ? '10' : '8');
  useEffect(() => {
    setCode(isForeign ? '10' : '8');
  }, [isForeign]);
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  /** Показать стоимость по направлениям */
  const [
    isShowCostByDestination,
    setIsShowCostByDestination,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [codePrices, setCodePrices] = useState<CodePrice[]>([]);

  /** Генерация ключей для списка */
  const ids = useMemo(
    () => (codePrices.length ? codePrices.map(() => nanoid(5)) : []),
    [codePrices],
  );

  const getCallPriceData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const directionCode = isForeign ? DIRECTION.external : DIRECTION.internal;
      const query =
        directionCode === DIRECTION.internal
          ? convertRuPhoneNumberForQuery(code)
          : convertExPhoneNumberForQuery(code);
      const res = await getCallPrice(directionCode, query);
      setCodePrices(res);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const table = () => {
    return (
      <StyledRates>
        <div className="rates__table">
          {codePrices.map((item, index) => {
            return (
              <div className="rates__table__line" key={ids[index]}>
                <div className="rates__table__line__column">
                  {!isDesktop940 ? (
                    <Text color={defaultTheme.colors.shadow} lineHeight="24px">
                      {item.tariffZoneName}
                    </Text>
                  ) : (
                    <Snoska>{item.tariffZoneName}</Snoska>
                  )}
                  <Text
                    className="rates__table__line__weight"
                    lineHeight="20px"
                  >
                    {item.name}
                  </Text>
                </div>
                <div className="rates__table__line__code">
                  <Text
                    className="rates__table__line__code__text"
                    color={defaultTheme.colors.shadow}
                    lineHeight="20px"
                  >
                    Код:
                  </Text>
                  <Text
                    className="rates__table__line__weight"
                    lineHeight="20px"
                  >
                    {item.directionCode
                      .replace(/[{()}\[7\]]/g, '')
                      .replace(/;/g, ', ')}
                  </Text>
                </div>
                <div className="rates__table__line__code">
                  {!isDesktop940 && (
                    <Text color={defaultTheme.colors.shadow} lineHeight="24px">
                      Стоимость
                    </Text>
                  )}
                  <Text
                    className="rates__table__line__weight"
                    lineHeight="20px"
                  >
                    {formatNumber(item.price)}&nbsp;₽ в мин
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </StyledRates>
    );
  };

  const notFound = () => {
    return (
      <StyledRates>
        <div className="rates__not-found">
          <Text className="rates__not-found__text" lineHeight="20px">
            Ничего не найдено
          </Text>
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Попробуйте указать код города или страны
          </Text>
        </div>
      </StyledRates>
    );
  };

  const content = () => {
    if (isError)
      return (
        <StyledRates>
          <div className="rates__error">
            <Icons.ErrorIcon />
            <Text lineHeight="24px" color={defaultTheme.colors.down}>
              {DEFAULT_ERROR}
            </Text>
          </div>
        </StyledRates>
      );
    if (!codePrices.length) return notFound();
    return table();
  };

  return (
    <StyledRates>
      <div className="rates__switcher">
        <div>
          <Text className="rates__switcher__text">Россия</Text>
          <Switcher
            checked={isForeign}
            onClick={() => setIsForeign(!isForeign)}
          >
            <Text className="rates__switcher__text">Другие страны</Text>
          </Switcher>
        </div>
        <Text
          className="rates__switcher__link"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
          onClick={() => setIsShowCostByDestination(true)}
        >
          {isDesktop1100 ? 'Показать с' : 'C'}тоимость по направлениям
        </Text>
      </div>
      <div className="rates__block">
        <div className="rates__block__input">
          <Input
            className="rates__block__input__code"
            type="text"
            placeholder="Введите код"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            isFocus
          />
          {isDesktop940 && !isDesktop1280 && (
            <Snoska className="rates__block__input__text">
              Укажите код города, номер абонента или первые цифры
            </Snoska>
          )}
          {(isDesktop1280 || !isDesktop940) && (
            <Text
              className="rates__block__input__text"
              lineHeight="24px"
              color={defaultTheme.colors.shadow}
            >
              Укажите код города, номер абонента или первые цифры
            </Text>
          )}
        </div>
        <Button onClick={getCallPriceData} loading={isLoading}>
          Узнать стоимость
        </Button>
      </div>
      {content()}
      <Popup
        className="rates__lightbox"
        show={isShowCostByDestination}
        onCloseClick={(): void => setIsShowCostByDestination(false)}
        onOutsideClick={(): void => setIsShowCostByDestination(false)}
        title="Стоимость по направлениям"
        width="832px"
      >
        <CostByDestination />
      </Popup>
    </StyledRates>
  );
};

export default Rates;
