import { ReactNode, useContext } from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { Provider, MobXProviderContext } from 'mobx-react';
import createStore, { ISoftlineStore } from './MainSoftlineStore';
import { SoftlineFieldsProps } from '../../types';

interface SoftlineProps {
  children: ReactNode;
  fields: SoftlineFieldsProps;
}

export const SoftlineProvider = ({
  children,
  fields,
}: SoftlineProps): JSX.Element => {
  const store = useLocalStore<ISoftlineStore>(() => createStore(fields));
  return <Provider SoftlineStore={store}>{children}</Provider>;
};

export const useSoftlineStore = () => {
  const { SoftlineStore } = useContext(MobXProviderContext);
  return SoftlineStore;
};
