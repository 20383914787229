/** библиотеки */
import styled from '@emotion/styled';

/** константы */
import { IMAGES } from '../constants';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const StyledSberATM = styled.div<{ isDesktop940: boolean }>`
  ${({ isDesktop940 }): string => {
    return `
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      background: ${
        isDesktop940
          ? `url(${IMAGES.sberATM}) no-repeat`
          : `url(${IMAGES.sberATM2}) no-repeat`
      };
      background-position: center center;
      padding: 32px;
      
      h2,
      h3 {
        margin-bottom: 16px;
      }
      
      h2 {
        width: 300px;
      }
      
      h3 {
        width: 190px;
      }
      
      .mainText {
        max-width: 190px;
        margin-bottom: 16px;
      }
      
      @media (min-width: ${desktop940}px) {
        height: 400px;
      }
    `;
  }};
`;
