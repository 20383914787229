import * as React from 'react';
import { useMemo } from 'react';

/* Интерфейсы */
import { ProductSelectorContentProps } from './ProductSelector.interfaces';
/** Компоненты */
import ProductTemplate from '../../Shared/ProductTemplate/ProductTemplate';

/**
 * Блок "Селектор"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=467286590
 * @param content
 */
const ProductSelector: React.FC<ProductSelectorContentProps> = ({
  content,
}: ProductSelectorContentProps) => {
  const {
    blocks,
    fields: { marketingFields, summaryFields, withoutFilters },
  } = content;

  const products = useMemo(() => {
    return blocks.map((item) => item.fields);
  }, []);

  return (
    <ProductTemplate
      blocks={products}
      marketingFields={marketingFields}
      summaryFields={summaryFields}
      withoutFilters={withoutFilters}
    />
  );
};

export default React.memo(ProductSelector);
