/** библиотеки */
import { useState, FC } from 'react';
import * as React from 'react';
import { observer } from 'mobx-react';

/** Компоненты библиотеки */
import {
  Text,
  Input,
  Loader,
  Link,
  defaultTheme,
  Snoska,
} from 'cordis-core-ui-planeta';

/** Компоненты */
import { AuthWizardContainer } from './AuthWizard.style';
/** stores */
import useMakeAuthStore from './store/useMakeAuthStore';
import { useRootStore } from '~/stores/RootStore';
import useCreatePasswordStore from '../Blocks/Shared/CreatePassword/store/useCreatePasswordStore';

/**
 * Форма авторизации.
 */
const AuthWizard: FC = () => {
  const {
    login,
    password,
    errorPassword,
    errorLogin,
    serverError,
    serverExtensionsError,
    setIsLoading,
    setLogin,
    setPassword,
    setServerError,
    resetErrors,
    validatePassword,
    validateLogin,
    validation,
  } = useMakeAuthStore();
  const {
    authStore: { makeAuth },
  } = useRootStore();
  const { setIsShowCreatePasswordWizard } = useCreatePasswordStore();

  const [authInProgress] = useState<boolean>(false);

  const openCreatePasswordSidePage = () => setIsShowCreatePasswordWizard(true);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      makeAuth(
        login,
        password,
        resetErrors,
        validation,
        setIsLoading,
        setServerError,
      );
    }
  };

  return (
    <>
      <AuthWizardContainer>
        <form className="wizard">
          <div className="wizard__wrapper">
            <div className="wizard__input-item">
              <div className="wizard__label">
                <label htmlFor="authWizardLogin">
                  <Text>Логин</Text>
                </label>
                <Link
                  onClick={openCreatePasswordSidePage}
                  target="_blank"
                  className="link__register"
                >
                  Я новый пользователь
                </Link>
              </div>
              <div>
                <Input
                  id="authWizardLogin"
                  className="wizard__input wizard__input-login"
                  type="text"
                  inputMode="numeric"
                  value={login}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setLogin(e.target.value);
                  }}
                  onBlur={validateLogin}
                  error={errorLogin}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>

            <div className="wizard__input-item">
              <label htmlFor="authWizardPassword" className="wizard__label">
                <Text>Пароль</Text>
              </label>
              <div>
                <Input
                  id="authWizardPassword"
                  className="wizard__input wizard__input-password"
                  type="password"
                  value={password}
                  onBlur={validatePassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={handleKeyPress}
                  error={errorPassword}
                />
              </div>
            </div>

            {serverError && (
              <Text
                className="wizard__server-error"
                color={defaultTheme.colors.planeta}
              >
                {serverError}
              </Text>
            )}
            {serverExtensionsError && (
              <Snoska
                className="wizard__server-error-extension"
                color={defaultTheme.colors.darkGrey}
              >
                {serverExtensionsError.Message}
              </Snoska>
            )}

            {authInProgress && (
              <Loader small className="wizard__status-container" />
            )}
          </div>
        </form>
      </AuthWizardContainer>
    </>
  );
};

export default observer(AuthWizard);
