/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';

/** Компоненты */
import { Button, defaultTheme, Link, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledAuthWizardConfirm } from './AuthWizardConfirm.style';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '../store/useMakeAuthStore';
import useCreatePasswordStore from '~/components/Blocks/Shared/CreatePassword/store/useCreatePasswordStore';

/** Блок авторизации */
const AuthWizardConfirm: FC = () => {
  const {
    agreementStore: { setIsShowAgreement },
    authStore: { makeAuth },
  } = useRootStore();
  const {
    isLoading,
    login,
    password,
    resetErrors,
    validation,
    setIsLoading,
    setServerError,
  } = useMakeAuthStore();
  const { setIsShowCreatePasswordWizard } = useCreatePasswordStore();
  const openCreatePasswordSidePage = () => setIsShowCreatePasswordWizard(true);

  return (
    <>
      <StyledAuthWizardConfirm>
        <div className="auth__terminal">
          <Link
            onClick={openCreatePasswordSidePage}
            className="link__reset-password"
          >
            Я не помню пароль
          </Link>

          <Button
            type="button"
            onClick={() =>
              makeAuth(
                login,
                password,
                resetErrors,
                validation,
                setIsLoading,
                setServerError,
              )
            }
            loading={isLoading}
          >
            Войти
          </Button>
        </div>

        <Text className="agreement__description">
          Нажимая кнопку, вы даёте согласие на обработку{' '}
          <Link
            color={defaultTheme.colors.planeta}
            onClick={() => setIsShowAgreement(true)}
          >
            персональных данных
          </Link>
        </Text>
      </StyledAuthWizardConfirm>
    </>
  );
};

export default observer(AuthWizardConfirm);
