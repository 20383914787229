/** libraries */
import {
  H2,
  H3,
  Icon,
  Icons,
  Input,
  SidePage,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { FC } from 'react';
import { observer } from 'mobx-react';
/** styles */
import { StyledPin } from './styles';
/** constants */
import { DEFAULT_ERROR } from '../../constants';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import PinFooter from './PinFooter';
/** stores */
import useSettingsStore from '../../store/useSettingsStore';

const PinSidePage: FC = () => {
  const {
    pinStore: {
      isShowPinWizard,
      password,
      setPassword,
      passwordError,
      setPasswordError,
      restoreState,
      result,
      code,
      setCode,
      codeError,
      setCodeError,
    },
  } = useSettingsStore();

  /** Изменение input */
  const onChangeCode = (event) => {
    const { value } = event.target;

    setCodeError(value.length < 5 ? '5 любых цифр' : '');
    if (value.length <= 5) setCode(value.replace(/[^0-9]/g, ''));
  };

  /** Изменение input */
  const onChangePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
  };
  /** Событие фокуса на текущем пароле */
  const onFocusPassword = () => {
    setPasswordError('');
  };

  // Вычисление ширины экрана
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <SidePage
      show={isShowPinWizard}
      headerText={!result.isResult && 'Изменить ПИН-код'}
      onCloseClick={restoreState}
      footerContainer={!result.isResult && <PinFooter />}
      isOnlyMobileFooter
      removeScrollBar
    >
      {result.isResult && result.isCorrect && (
        <StyledPin>
          <div className="result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? (
            <H2>ПИН-код изменён успешно</H2>
          ) : (
            <H3>ПИН-код изменён успешно</H3>
          )}
        </StyledPin>
      )}

      {result.isResult && !result.isCorrect && (
        <StyledPin>
          <div className="result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </StyledPin>
      )}

      {!result.isResult && (
        <StyledPin>
          <Text className="pin__info" lineHeight="24px">
            <Text lineHeight="24px" fontWeightBold>
              ПИН-код
            </Text>{' '}
            &mdash; это ваш персональный номер, который используется
            для&nbsp;управления услугами в&nbsp;разделе «Моя Планета».
          </Text>
          <div className="pin__input">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Новый ПИН-код
            </Text>
            <Input
              className="pin__input__inlet"
              type="password"
              placeholder="Введите код"
              value={code}
              error={codeError}
              onChange={onChangeCode}
              isFocus
            />
            {!codeError && (
              <Text
                className="pin__input__info"
                color={defaultTheme.colors.shadow}
                lineHeight="24px"
              >
                5&nbsp;любых цифр
              </Text>
            )}
          </div>
          <div className="pin__input">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Пароль раздела «Моя Планета»
            </Text>
            <Input
              className="pin__input__inlet"
              type="password"
              placeholder="Введите пароль"
              value={password}
              error={passwordError}
              onChange={onChangePassword}
              onFocus={onFocusPassword}
            />
            {!passwordError && (
              <Text
                className="pin__input__info"
                color={defaultTheme.colors.shadow}
                lineHeight="24px"
              >
                Укажите для подтверждения
              </Text>
            )}
          </div>
        </StyledPin>
      )}
    </SidePage>
  );
};

export default observer(PinSidePage);
