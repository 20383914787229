/** libraries */
import { FC } from 'react';
import { defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** utils */
import { pluralizeAll } from '~/utils/utils';

/** interfaces */
import { ServiceInfoProps } from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

/** components */
import ServiceCardWrapper from '../Cards/ServiceCards/ServiceCardWrapper';

/** constants */
import { SERVICE, STORE_TYPE } from '../../constants';

/** stores */
import { useMyTvStore } from '../../stores/useMyTvStore';

interface ServicesTab {
  /** сервисы */
  serviceInfo: ServiceInfoProps[];
  /** Клик по карточке сервиса */
  onClickServiceCard: (service: ServiceInfoProps) => void;
}

/** Таб "Сервисы" */
const ServicesTab: FC<ServicesTab> = ({
  serviceInfo,
  onClickServiceCard,
}: ServicesTab) => {
  const { storeType } = useMyTvStore();
  /** Формат блока X2 */
  const isX2 = storeType === STORE_TYPE.MY_TV_X2;

  return (
    <div className="my-tv__tab-block__block">
      {!isX2 && (
        <LeadingText
          className="my-tv__tab-block__block__count"
          color={defaultTheme.colors.shadow}
        >
          {pluralizeAll(serviceInfo.length, SERVICE)}
        </LeadingText>
      )}
      <div className="my-tv__tab-block__block__cards">
        {serviceInfo.map((item) => {
          return (
            <ServiceCardWrapper
              key={item.name}
              service={item}
              onClick={() => onClickServiceCard(item)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(ServicesTab);
