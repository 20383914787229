/* параметры точек, представляющих цифру 9 */
export const pointsNine = [
  // row 1
  { diam: 14, posX: 1, posY: 68, isActive: true },
  // row 2
  { diam: 14, posX: 3, posY: 48, isActive: true },
  { diam: 14, posX: 3, posY: 86, isActive: true },
  // row 3
  { diam: 14, posX: 9, posY: 32, isActive: true },
  { diam: 14, posX: 5, posY: 104, isActive: true },
  // row 4
  { diam: 14, posX: 19, posY: 18, isActive: true },
  { diam: 9, posX: 21, posY: 49, isActive: true },
  { diam: 8, posX: 18, posY: 65, isActive: true },
  { diam: 8, posX: 16, posY: 79, isActive: true },
  { diam: 8, posX: 19, posY: 94, isActive: true },
  { diam: 9, posX: 24, posY: 107, isActive: true },
  { diam: 14, posX: 15, posY: 121, isActive: true },
  // row 5
  { diam: 14, posX: 33, posY: 8, isActive: true },
  { diam: 9, posX: 29, posY: 36, isActive: true },
  { diam: 14, posX: 33, posY: 50, isActive: true },
  { diam: 14, posX: 28, posY: 67, isActive: true },
  { diam: 14, posX: 29, posY: 84, isActive: true },
  { diam: 9, posX: 35, posY: 120, isActive: true },
  { diam: 14, posX: 26, posY: 134, isActive: true },
  // row 6
  { diam: 14, posX: 50, posY: 2, isActive: true },
  { diam: 13, posX: 40, posY: 24, isActive: true },
  { diam: 13, posX: 47, posY: 40, isActive: true },
  { diam: 13, posX: 39, posY: 101, isActive: true },
  { diam: 13, posX: 46, posY: 125, isActive: true },
  { diam: 14, posX: 44, posY: 143, isActive: true },
  // row 7
  { diam: 14, posX: 67, posY: 0, isActive: true },
  { diam: 13, posX: 57, posY: 18, isActive: true },
  { diam: 13, posX: 65, posY: 36, isActive: true },
  { diam: 13, posX: 55, posY: 109, isActive: true },
  { diam: 13, posX: 63, posY: 127, isActive: true },
  { diam: 14, posX: 62, posY: 145, isActive: true },
  // row 8
  { diam: 14, posX: 86, posY: 3, isActive: true },
  { diam: 13, posX: 75, posY: 19, isActive: true },
  { diam: 13, posX: 82, posY: 40, isActive: true },
  { diam: 13, posX: 72, posY: 109, isActive: true },
  { diam: 13, posX: 80, posY: 126, isActive: true },
  { diam: 14, posX: 79, posY: 144, isActive: true },
  // row 9
  { diam: 14, posX: 101, posY: 10, isActive: true },
  { diam: 13, posX: 91, posY: 25, isActive: true },
  { diam: 8, posX: 105, posY: 39, isActive: true },
  { diam: 13, posX: 95, posY: 51, isActive: true },
  { diam: 13, posX: 100, posY: 70, isActive: true },
  { diam: 13, posX: 99, posY: 88, isActive: true },
  { diam: 6, posX: 106, posY: 102, isActive: true },
  { diam: 13, posX: 89, posY: 103, isActive: true },
  { diam: 13, posX: 96, posY: 119, isActive: true },
  { diam: 14, posX: 95, posY: 139, isActive: true },
  // row 10
  { diam: 14, posX: 114, posY: 24, isActive: true },
  { diam: 8, posX: 112, posY: 51, isActive: true },
  { diam: 6, posX: 116, posY: 66, isActive: true },
  { diam: 6, posX: 116, posY: 78, isActive: true },
  { diam: 8, posX: 116, posY: 91, isActive: true },
  { diam: 13, posX: 110, posY: 108, isActive: true },
  { diam: 14, posX: 111, posY: 131, isActive: true },
  // row 11
  { diam: 14, posX: 122, posY: 41, isActive: true },
  { diam: 10, posX: 125, posY: 60, isActive: true },
  { diam: 13, posX: 125, posY: 78, isActive: true },
  { diam: 13, posX: 124, posY: 98, isActive: true },
  { diam: 14, posX: 125, posY: 120, isActive: true },
  // row 12
  { diam: 13, posX: 139, posY: 65, isActive: true },
  { diam: 13, posX: 138, posY: 87, isActive: true },
  { diam: 14, posX: 138, posY: 110, isActive: true },
  // row 13
  { diam: 13, posX: 153, posY: 53, isActive: true },
  { diam: 13, posX: 152, posY: 76, isActive: true },
  { diam: 14, posX: 153, posY: 99, isActive: true },
  // row 14
  { diam: 13, posX: 167, posY: 39, isActive: true },
  { diam: 6, posX: 172, posY: 57, isActive: true },
  { diam: 13, posX: 166, posY: 66, isActive: true },
  { diam: 14, posX: 166, posY: 88, isActive: true },
  // row 15
  { diam: 14, posX: 180, posY: 26, isActive: true },
  { diam: 11, posX: 183, posY: 46, isActive: true },
  { diam: 13, posX: 181, posY: 62, isActive: true },
  { diam: 14, posX: 180, posY: 79, isActive: true },
];
