/** libraries */
import { FC, useState } from 'react';
import ReactPlayer from 'react-player';
/** components */
import {
  PlayerButton,
  PlayerCircle,
  PlayerWrapper,
} from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/AudioPlayer/style';
import { Text } from 'cordis-core-ui-planeta';
/** utils */
import { getFileNameFromUrl } from '~/utils/utils';

const AudioPlayer: FC<{ audio: string }> = ({ audio }) => {
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const startPlayer = () => setShowPlayer(true);
  const stopPlayer = () => setShowPlayer(false);

  return (
    <>
      {!showPlayer ? (
        <PlayerWrapper>
          <PlayerCircle onClick={startPlayer}>
            <PlayerButton />
          </PlayerCircle>
          <Text>{getFileNameFromUrl(audio)}</Text>
        </PlayerWrapper>
      ) : (
        <ReactPlayer
          playing
          // https://github.com/cookpete/react-player/issues/1595
          // onPause={stopPlayer}
          onEnded={stopPlayer}
          width="100%"
          height={42}
          controls
          url={`${process.env.STATIC_SERVER}/${audio}`}
        />
      )}
    </>
  );
};

export default AudioPlayer;
