/** Libraries */
import styled from '@emotion/styled';
/** Constants */
import { desktop940 } from '~/components/Grid/constants';

export default styled.footer`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    width: 100%;
  }

  button {
    width: 100%;
    margin-bottom: 16px;
  }

  span + a {
    margin-top: 0;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    > span,
    button {
      width: auto;
    }

    button ~ button {
      margin: 0 0 0 16px;
    }
  }
`;
