/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme, Icon, Link } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop1280,
  desktop1100,
  desktop940,
  desktop440,
} from '~/components/Grid/constants';

/** типы */
import { StyledSummaryDescriptionProps } from './Summary.types';

/**
 * Styled-component для отображения блока summary
 */
export const StyledSummary = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
  }
  .change-connection-success {
    margin: -51px 0 26.5px;
  }
`;

/**
 * Styled-component для отображения описания
 */
export const StyledSummaryDescription = styled.div<StyledSummaryDescriptionProps>`
  ${({ $isBusiness, $action }): string => {
    const getBackground = () => {
      return $isBusiness
        ? defaultTheme.colors.graphite
        : defaultTheme.colors.planeta;
    };
    return `
    ${
      $action?.background
        ? `
        background: url(${process.env.STATIC_SERVER}/${$action.background});
        background-size: cover;
        background-repeat: no-repeat;`
        : `background: ${getBackground()};`
    }
    width: 100%;
    min-width: 280px;

    > div {
      height: 100%;
    }

    .padding {
      padding: 28px 32px;
    }

    .container {
      height: 100%;
      
      > a {
        text-decoration: none;
      }
    }

    .title {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 28px;
    }

    .subtitle {
      margin-bottom: 16px;
    }

    .tags {
      display: flex;
    }

    .tags > div {
      margin-right: 8px;
    }

    a:hover {
      text-decoration: none;
    }
   
    
    @media (min-width: ${desktop940}px) {
      min-width: 280px;
      width: 400px;
    }
    
    @media (min-width: ${desktop1100}px) {
      .title {
        font-size: 28px;
      }
      min-width: 340px;
    }
    
    @media (min-width: ${desktop1280}px) {
      min-width: 400px;
      .title {
        font-size: 44px;
      }
    }
  `;
  }}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

/**
 * Styled-component для отображения информации
 */
export const StyledSummaryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-top: 26px;
  padding-right: 32px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 0;

  .internet__speed {
    margin-top: 8px;
  }

  .info-statistics {
    display: flex;
    flex-wrap: wrap;
  }
  .internet {
    min-width: 187px;

    &__name-wrapper {
      display: flex;
    }
  }

  .internet,
  .phone {
    margin-right: 32px;
  }

  .icon {
    margin-right: 16px;
  }

  .value {
    margin-bottom: 30px;
  }

  .action-block {
    display: flex;
    margin-bottom: 32px;
    align-items: flex-end;
  }

  .action-info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    &__header {
      font-size: 18px;
    }
  }

  .action-secondIcon {
    display: none;
  }

  .info-devices {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
    padding-top: 7px;

    &__icon {
      margin-right: 10px;
    }
    &__text {
      display: flex;
      flex-direction: column;
      .info-devices__name {
        margin-right: 8px;
      }
      .row {
        margin-top: 8px;
      }
    }
  }

  .image {
    position: relative;
    width: 75px;
    height: 50px;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: column;
    padding-bottom: 26px;
    flex-wrap: nowrap;
    .action-block {
      margin-bottom: 0;
    }

    .value,
    .info-devices {
      margin-bottom: 28px;
    }
    .info-devices {
      padding-top: 0;
    }
    .info-devices__name {
      white-space: nowrap;
    }
    .info-statistics {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (min-width: ${desktop1100}px) {
    max-width: 565px;

    .internet {
      min-width: 122px;
    }
    .info-statistics {
      flex-wrap: nowrap;
    }
    .info-devices {
      margin-bottom: 0;
    }
    .action-secondIcon {
      width: 126px;
      height: 70px;
      display: block;
      margin-left: 32px;
    }
  }
`;

/**
 * Styled-component для отображения табов
 */
export const StyledTabs = styled.div`
  width: max-content;

  > span:first-of-type {
    display: flex;
    align-items: center;

    .action-discount ~ .value {
      margin-top: 0;
      margin-left: 15px;
    }
  }

  .value {
    margin-top: 8px;
    margin-bottom: 0;
  }

  .value__margin {
    margin-bottom: 28px;
  }

  @media (min-width: ${desktop1100}px) {
    .value__margin {
      margin-bottom: 0;
    }
  }
`;

/**
 * Styled-component для отображения цен
 */
export const StyledSummaryCost = styled.div<{ $hasSecondIcon: boolean }>`
  padding: 26px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  align-items: unset;
  padding-top: 0;

  > span:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .value {
    margin-bottom: ${({ $hasSecondIcon }) => ($hasSecondIcon ? '6px' : '30px')};
  }
  .action-secondIcon {
    width: 141px;
    height: 79px;
    margin: 9px 0 24px 0;
  }

  .action-discount {
    display: flex;
    flex-direction: column;

    > span {
      font-size: 11px;
      line-height: 13px;

      &:first-of-type {
        font-weight: 700;
      }

      &:last-of-type {
        font-weight: 400;
        text-decoration: line-through;
      }
    }
  }

  .connected-text {
    width: max-content;
    margin: 7px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  .ftth-text {
    width: 222px;
    margin-top: 8px;
  }

  button {
    width: 100%;
    padding: 12px 36px;
    text-wrap: nowrap;
  }

  @media (min-width: ${desktop440}px) {
    > span:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .action-secondIcon {
      margin: 0 0 24px 30px;
    }
  }

  @media (min-width: ${desktop940}px) {
    align-items: ${({ $hasSecondIcon }) =>
      $hasSecondIcon ? 'center' : 'flex-end'};
    flex-direction: row;

    .action-secondIcon {
      margin: 0 0 15px 30px;
    }

    .value {
      margin-bottom: 0;
    }

    button {
      width: auto;
    }
  }

  @media (min-width: ${desktop1100}px) {
    align-items: ${({ $hasSecondIcon }) =>
      $hasSecondIcon ? 'center' : 'flex-end'};
    padding: 26px 32px;
    flex-direction: column;

    .action-secondIcon {
      display: none;
    }
  }

  @media (min-width: ${desktop1280}px) {
    align-items: baseline;
    min-width: 300px;

    .button {
      &__content {
        white-space: nowrap;
      }
    }
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${desktop1100}px) {
    flex-direction: row;
  }
`;

export const StyledIcon = styled(Icon)`
  svg {
    margin: -40px 0 20px 0;
  }
`;

export const StyledConnect = styled.div`
  > span:first-of-type {
    display: block;
    margin-bottom: 8px;
  }

  > div {
    > a {
      cursor: default;
      > span {
        & > span {
          cursor: pointer;
        }
      }
    }
  }

  a:hover {
    text-decoration: none;
  }
`;
