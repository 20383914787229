import styled from '@emotion/styled';

/** constants */
import { IMAGES } from './constants';
import { desktop940 } from '~/components/Grid/constants';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;

export const StyledVacation = styled.div<{
  size: number;
  isDesktop400: boolean;
  isDesktop940: boolean;
  isDesktop1100: boolean;
  isDesktop1280: boolean;
  isActivated: boolean;
}>`
  ${({
    size,
    isDesktop400,
    isDesktop940,
    isDesktop1100,
    isDesktop1280,
    isActivated,
  }): string => {
    const imageSize = (): string => {
      if (isDesktop1280) return 'right';
      if (isDesktop1100) return '360px 0';
      if (isDesktop940) return '270px 0';
      return 'right';
    };
    const firstGradientSize = () => {
      if (isDesktop940 && !isDesktop1100) return '50%';
      return '40%';
    };
    const secondGradientSize = () => {
      if (isDesktop940 && !isDesktop1100) return '98%';
      return '80%';
    };
    const background = (): string => {
      if (!isDesktop400) return `url(${IMAGES.vacationSm}) no-repeat`;
      if (size === 3 && isDesktop940)
        return `linear-gradient(90deg, #313131 ${firstGradientSize()}, rgba(0, 0, 0, 0) ${secondGradientSize()}, rgba(0, 0, 0, 0) 104.18%), no-repeat ${imageSize()} url(${
          IMAGES.vacation
        })`;
      return `url(${
        !isDesktop940 ? IMAGES.vacationBig : IMAGES.vacationSmall
      }) no-repeat`;
    };

    return `
    display: flex;
    flex-direction: ${size === 1 || !isDesktop940 ? 'column' : 'row'};
    height: auto;
    width: 100%;
    background: ${background()};
    ${size !== 3 || !isDesktop940 ? 'background-position: center center;' : ''}
    ${size !== 3 || !isDesktop940 ? 'background-size: cover;' : ''}
    padding: ${size === 3 && isDesktop940 ? '20px 32px 22px' : '32px'};
    

    .vacation {
      &__block {
        display: flex;
        flex-direction: column;
        ${size === 3 && isDesktop940 ? ' width: 432px' : ''}
      }
      &__header {
        margin-bottom: ${isActivated ? '8px' : '16px'};
      }
      &__text {
        margin-bottom: ${size === 1 || !isDesktop940 ? '16px' : '0'};
        ${size === 3 && isDesktop940 ? ' max-width: 317px;' : ''}
        &__enabled {
          max-width: 300px;
        }

        &__bold {
          font-weight: bold;
        }

        ${
          !isDesktop940 &&
          `&__disabled {
          max-width: 415px;
        }`
        }
        ${
          !isDesktop940 &&
          `&__ordered {
          max-width: 340px;
        }`
        }
      }
      &__button {
        width: fit-content;
        background-color: transparent;
        border: 2px solid ${defaultTheme.colors.white};
        margin-bottom: ${size === 3 && isDesktop940 ? '13px' : '8px'};
        padding: 13px 40px;

        span {
          color: ${defaultTheme.colors.white};
          letter-spacing: -0.03em;
        }
      }
      &__switcher {
        margin-bottom: ${size === 3 && isDesktop940 ? '13px' : '8px'};
      }
      &__connection-button {
        width: inherit;
      }
      &__snoska {
        max-width: 300px;

        &__disabled {
          max-width: 230px;
        }
      }
      &__resume-button {
        cursor: pointer;
      }
    }

    .sidepage {
      &__content {
        margin-top: 44px !important;
      }
    }

    @media (min-width: ${desktop940}px) {
      ${size === 1 ? 'height: 400px;' : ''}
      .vacation {
        &__connection-button {
          width: auto;
        }
      }
    }
    `;
  }}
`;
