/** утилиты */
import { pluralizeAll } from '~/utils/utils';

/** константы */
import { DevicePlural } from '../Legal.const';

/** типы */
import { DeviceInfo } from '../Legal.types';

/**
 * Тип физического подключения клиента продукта:
 * ftth - подключение по оптико-волоконному кабелю;
 * lan  - подключение по кабелю медная витая пара;
 * wifi - подключение по технологии wifi.
 *
 * Для продуктов серии «Оптический Гигабит» используется тип подключения "ftth".
 */
const GIGABIT_CONNECTION_TYPE_CODE = 'ftth';

/**
 * Проверка типа подключения
 * @param connectionMethodCode
 */
export const isGigabit = (connectionMethodCode: string): boolean => {
  return connectionMethodCode === GIGABIT_CONNECTION_TYPE_CODE;
};

/**
 * Метод возвращает размер платы на продукте в состоянии приостановки обслуживания за 30 дней.
 * @param price
 */
export const getLineRentInactiveFor30Days = (price: number): number => {
  return price * 30;
};

/**
 * Конвертирует отображаемый вид количество устройств для рассрочки
 * Пример:
 * [{itemTypeCode: "router", maxQuantity: 1}, {itemTypeCode: "stb", maxQuantity: 2}] => "1 маршрутизатор, 2 телеприставки"
 * @param {DeviceInfo} itemMaxQuantityList список устройств
 * @returns {string} сгенерированная строка
 */
export const getDevicesString = (itemMaxQuantityList: DeviceInfo[]): string => {
  const namesArray = itemMaxQuantityList.map((item) => {
    const plural = pluralizeAll(
      item.maxQuantity,
      DevicePlural[item.itemTypeCode],
    );
    return plural;
  });
  if (namesArray.length === 1) {
    return namesArray[0];
  }
  return namesArray.join(', ');
};
