/** libraries */
import { Instance, types } from 'mobx-state-tree';
/** constants */
/** interfaces */
const devicePurchaseStateModel = types.model({
  isWarrantyBind: types.boolean,
  isLease: types.boolean,
});

const DeviceAfterAuthModel = types
  .model({
    /** ID оборудования для открытия сайдпейджа с детальной информацией */
    deviceId: types.maybeNull(types.number),
    /** Информация о заказываемом оборудовании */
    devicePurchaseState: types.maybeNull(devicePurchaseStateModel),
  })
  .actions((self) => ({
    setDeviceId: (id: number) => {
      self.deviceId = id;
    },
    setDevicePurchaseState: (isWarrantyBind: boolean, isLease: boolean) => {
      self.devicePurchaseState = { isWarrantyBind, isLease };
    },
    resetDeviceAfterAuthStore: () => {
      self.deviceId = null;
      self.devicePurchaseState = null;
    },
  }));

export type IDeviceAfterAuthStore = Instance<typeof DeviceAfterAuthModel>;

export default DeviceAfterAuthModel;
