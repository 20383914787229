import QrLegal from '~/components/Blocks/Templates/PaymentResult/Components/DeclinedSBPPayment/Components/QrLegal';

const TEXT_CONFIG = {
  HEADER_BINDING:
    'Похоже, что этот счёт СБП удалён в\u00A0приложении банка.\nПривяжите его повторно для оплаты.',
  MOBILE_BINDING: 'Привязать и оплатить',
  MOBILE_PAYMENT: 'Оплатить',
  HEADER_PAYMENT:
    'Похоже, что есть какие-то проблемы на\u00A0стороне банка.\nПопробуйте позже или совершите разовую оплату',
  ADVICE:
    'Отсканируйте QR-код из\u00A0приложения банка\nили через камеру телефона',
  SNOSKA: <QrLegal />,
};

export default TEXT_CONFIG;
