/** libraries */
import { types, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { cloneDeep } from 'lodash';

/** components */
import { StyledColorBullet } from '~/components/Blocks/Templates/Devices/style';
/** interfaces */
import { ResultPropsModel } from '~/components/Blocks/Shared/ContactsAndNotifications/store/models';
import { OptionProp } from 'cordis-core-ui-planeta';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** api */
import {
  createDemand,
  getContacts,
  getContactValueClasses,
} from '~/api/apiPab2c';
/** constants */
import {
  DEFAULT_ERROR,
  DEFAULT_RESULT,
  DEMAND_CAUSE,
  DEMAND_REQUEST_ERRORS,
  WORKFLOW_IDS,
} from '~/constants/common';
import { DEFAULT_SELECT } from '~/components/Blocks/Templates/Devices/constants';
import { TABS_DEVICE } from '~/components/Blocks/Templates/Devices/Components/DeviceTabs/constants';
import { CONTACT_TYPES } from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { maskPhone } from '~/components/Blocks/Templates/Pab2c/Help/utils';
import { DevicesFields } from '~/components/Blocks/Templates/Devices/interfaces';
import { DevicesFieldsModel } from '~/components/Blocks/Templates/Pab2c/Devices/stores/DevicesStore';

const RequestsStateModel = types.model('State', {
  createDemand: createApiPathModel('POST /Demand/Demand/CreateDemand'),
});

const DeviceDetailedCardModel = types
  .model('DeviceDetailedCardModel', {
    requestsState: RequestsStateModel,
    /** Флаг отображения детального сайдпейджа */
    isDetailedDeviceShow: types.boolean,
    /** Информация о конкретном устройстве */
    detailedDeviceData: types.maybeNull(DevicesFieldsModel),
    /** Телефон для связи */
    phone: types.string,
    /** Результат заказа оборудования */
    result: ResultPropsModel,
    error: types.string,
    /** Select способа покупки */
    select: types.model({
      label: types.string,
      value: types.union(types.string, types.number),
    }),
    /** Select цвета */
    colorSelect: types.model({
      label: types.string,
      value: types.string,
    }),
  })
  .views((self) => ({
    get detailedDevice() {
      return toJS(self.detailedDeviceData);
    },
    get isLoading() {
      return self.requestsState.createDemand.isLoading;
    },
    /** Цвета оборудования */
    get colorTabs() {
      if (!self.detailedDeviceData?.colors?.length) return [];
      const tabs = self.detailedDeviceData.colors.map((color) => {
        return {
          value: color.hex,
          label: color.name,
          leftIcon: <StyledColorBullet hex={color.hex} />,
        };
      });
      return tabs;
    },
  }))
  .actions((self) => ({
    setIsDetailedDeviceShow(isShow: boolean) {
      self.isDetailedDeviceShow = isShow;
    },
    setDetailedDeviceData(data: DevicesFields) {
      self.detailedDeviceData = cloneDeep(data);
    },
    setError(error: string) {
      self.error = error;
    },
    setSelect(select: OptionProp) {
      self.select = select;
    },
    setColorSelect(select: OptionProp) {
      self.colorSelect = select;
    },
    resetDetailedDeviceStore() {
      self.result = DEFAULT_RESULT;
      self.select = DEFAULT_SELECT;
      self.colorSelect = DEFAULT_SELECT;
      self.isDetailedDeviceShow = false;
      self.error = '';
    },
    /** Заказать оборудование */
    orderEquipment: flow(function* (contractName: string, clientName: string) {
      self.requestsState.createDemand.reset();
      self.requestsState.createDemand.setLoading();
      try {
        const [contacts, contactTypes] = yield Promise.all([
          getContacts(),
          getContactValueClasses(),
        ]);
        const phoneContactTypeId = contactTypes.find(
          (type) => type.code === CONTACT_TYPES.PHONE,
        ).id;
        const emailContactTypeId = contactTypes.find(
          (type) => type.code === CONTACT_TYPES.EMAIL,
        ).id;
        const { phones, emails } = contacts.reduce(
          (acc, contact) => {
            if (
              contact.contactValueClassId === phoneContactTypeId &&
              !acc.phones.length
            ) {
              acc.phones.push(maskPhone(contact.value));
            } else if (
              contact.contactValueClassId === emailContactTypeId &&
              !acc.emails.length
            ) {
              acc.emails.push(contact.value);
            }
            return acc;
          },
          { phones: [], emails: [] },
        );
        self.phone = phones[0].label;
        const res = yield createDemand(
          WORKFLOW_IDS.CONTACT_THE_CUSTOMER,
          'Заявка из раздела «Оборудование»',
          `Номер договора: ${contractName}. ${
            self.detailedDeviceData.typeName
          } ${self.detailedDeviceData.vendorName} ${
            self.detailedDeviceData.name
          }. ${self.select.label}. Цена: ${
            self.select?.value ?? self.detailedDeviceData.price
          } руб.${self.select.label === TABS_DEVICE.BUY ? '' : ' в день.'} ${
            self.colorSelect?.value ? `Цвет: ${self.colorSelect?.label}.` : ''
          } ${
            self.select.label === TABS_DEVICE.USE
              ? 'Модель предоставляемого в пользование оборудования может быть заменена на аналогичную.'
              : ''
          }`,
          clientName,
          phones.length ? phones[0].value : null,
          emails.length ? emails[0] : null,
          DEMAND_CAUSE.ADDITIONAL_SERVICES,
        );
        self.result = {
          isResult: true,
          isCorrect: res.status === 200,
        };
        self.requestsState.createDemand.setSuccess();
      } catch (e) {
        self.requestsState.createDemand.setFail();
        const err = e?.errorMessage ? JSON.parse(e.errorMessage) : null;
        if (err?.Type === DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS) {
          self.error = DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS;
          return;
        }
        self.error = DEFAULT_ERROR;
      }
    }),
  }));

export const createDeviceDetailedCardStoreInstance = () =>
  DeviceDetailedCardModel.create({
    requestsState: { createDemand: defaultModelState },
    isDetailedDeviceShow: false,
    detailedDeviceData: null,
    phone: '',
    result: DEFAULT_RESULT,
    error: '',
    select: DEFAULT_SELECT,
    colorSelect: DEFAULT_SELECT,
  });

export default DeviceDetailedCardModel;
