/** библиотеки */
import * as React from 'react';
import { defaultTheme, H2, Tag, Text } from 'cordis-core-ui-planeta';

/** компоненты */
import { StyledLink, StyledSummaryDescription } from '../Summary.style';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { ActionType, Tag as TagType } from '../Summary.types';

/** интерфейс компонента описание тарифа */
interface SummaryDescriptionProps extends ActionType {
  tags: TagType[];
  seriesName: string;
  marketingGroupName: string;
  marketingText: string;
  isBusiness: boolean;
  productFeedLink: string;
  /** Открыть продукт в новой вкладке */
  isLinkInNewTab?: boolean;
  isClosed: boolean;
}

/**
 * компонент описания тарифа
 * @param {Tag[]} tags
 * @param {string} seriesName
 * @param {string} marketingGroupName
 * @param {string} marketingText
 * @param {boolean} isBusiness
 * @param {string} productFeedLink
 * @param {ActionType} action
 * @param {boolean} isClosed
 */
const SummaryDescription: React.FC<SummaryDescriptionProps> = ({
  tags,
  seriesName,
  marketingGroupName,
  marketingText,
  isBusiness,
  productFeedLink,
  action,
  isLinkInNewTab,
  isClosed,
}: SummaryDescriptionProps) => {
  const getSubtitleColor = () => {
    if (isBusiness) return defaultTheme.colors.light;
    return action?.color ?? defaultTheme.colors.pink;
  };

  const summaryBody = (
    <div className="container padding">
      <div className="tags">
        {tags.map((tag) => (
          <Tag
            color={action?.color ?? defaultTheme.colors.white}
            colorTag={action?.color ?? defaultTheme.colors.white}
            key={tag.code}
          >
            {tag.name.toUpperCase()}
          </Tag>
        ))}
      </div>
      <div>
        <H2
          className="title"
          color={action?.color ?? defaultTheme.colors.white}
        >
          {seriesName}
        </H2>
        <Text className="subtitle" color={getSubtitleColor()}>
          {!isClosed && marketingGroupName}
        </Text>
      </div>
      <Text
        color={action?.color ?? defaultTheme.colors.white}
        dangerouslySetInnerHTML={{ __html: marketingText }}
      />
    </div>
  );

  return (
    <StyledSummaryDescription $isBusiness={isBusiness} $action={action}>
      {productFeedLink ? (
        <>
          {isLinkInNewTab ? (
            <StyledLink
              href={decodeURIComponent(productFeedLink)}
              target="_blank"
            >
              {summaryBody}
            </StyledLink>
          ) : (
            <LinkWrapper href={productFeedLink}>{summaryBody}</LinkWrapper>
          )}
        </>
      ) : (
        <div className="container">{summaryBody}</div>
      )}
    </StyledSummaryDescription>
  );
};

export default React.memo(SummaryDescription);
