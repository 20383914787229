/** libraries */
import { FC } from 'react';
/** interfaces */
import { MainSideProps } from './interfaces';
/** components */
import Wrapper, { Root, StyledDate } from './style';
import { LeadingText } from 'cordis-core-ui-planeta';
/** constants */
import CONFIG from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/config';
import { MiddleLine } from '~/components/Blocks/Templates/Timeline/MiddleRow/style';

/** Расширенный блок */
const MainSide: FC<MainSideProps> = ({
  index,
  date,
  isMobile,
}: MainSideProps) => {
  const Slide = CONFIG[index].component;

  if (!CONFIG[index].component) return null;

  return (
    <Root $isMobile={isMobile}>
      <Wrapper $index={index} $isMobile={isMobile}>
        <StyledDate>
          <LeadingText color={CONFIG[index].dateColor}>{date}</LeadingText>
        </StyledDate>
        <Slide />
      </Wrapper>
      {!isMobile && <MiddleLine $height={64} />}
    </Root>
  );
};

export default MainSide;
