/** библиотеки */
import { ReactNode, FC } from 'react';

interface WordProps {
  isActive?: boolean;
  children: ReactNode;
}

const Word: FC<WordProps> = ({ isActive, children }) => (
  <span className={`voiceControl__word ${isActive ? 'active' : ''}`}>
    {children}
  </span>
);

export default Word;
