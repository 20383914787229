import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
`;

export const Header = styled.span`
  margin-bottom: 21px;
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) => ($isMobile ? 'flex-end' : 'center')};
  width: 100%;

  ${Header} {
    ${({ $isMobile }) =>
      !$isMobile &&
      css`
        font-size: 174px;
        margin-top: 10rem;
        margin-bottom: 21px;
      `}

    &::first-letter {
      color: ${defaultTheme.colors.trendyPink};
    }
  }

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            text-align: left;
            margin-bottom: 28px;
          `
        : css`
            margin-top: 2rem;
          `}
  }
`;
