/* Библиотеки */
import style from '@emotion/styled';

export const StyledConnectionContent = style.div`
  ${() => `
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 35%;
      white-space: pre-line;
      h3 {
        margin-bottom: 0;
      }

      > span {
        display: flex;
        align-items: center;
        div[data-test-tag] {
          margin: 0 0 15px 10px;
        }
      }
    `}
`;
