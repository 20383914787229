/* Библиотеки */
import style from '@emotion/styled';
/* Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';

/* Стилизованная часть левой колонки строки со скоростью интернета */
export const StyledLeftColumn = style.div`
  ${() => `
      display: flex;
      flex-direction: column;
      white-space: pre-line;
      min-width: 35%;
      h3 {
        margin: 0 0 16px 0;
      }
      
      h3 + span {
        display: flex;
        align-items: center
      }
      
      div[data-test-tag] {
        margin: 0 0 15px 10px;
      }
      
      > div:last-child {
        display: flex;
        align-items: center;
        margin-top: 32px;
        svg {
          margin-right: 12px;
          flex-shrink: 0;
        }
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          display: none;
        }
      }
    `}
`;

/* Стилизованная часть правой колонки строки со скоростью интернета */
export const StyledRightColumn = style.div`
  ${() => `
      display: none;
      max-width: 250px;
      align-items: flex-start;
      span {
        white-space: pre-line;
      }
      svg {
        margin-right: 12px;
      }
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: flex;
      }
    `}
`;
