/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo, DevicePriceInfo } from '../Legal.types';
import { DeviceCode } from '../../Summary/Summary.types';

/** интерфейс компонента-шаблона StbRentText */
interface StbRentTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона StbRentText
 * @param {StbRentTextProps} content
 * @returns {React.FC}
 */
const StbRentText: React.FC<StbRentTextProps> = ({
  content,
}: StbRentTextProps) => {
  const { devicePriceInfos } = content;

  const stbDeviceInfo: DevicePriceInfo = devicePriceInfos.find(
    (device) => device.deviceCode === DeviceCode.STB,
  );

  const isCanRent = stbDeviceInfo && !stbDeviceInfo.isOwnership;

  const isShowBlock = stbDeviceInfo || isCanRent;

  const getRentInfoWithOwnership = devicePriceInfos.find(
    (device) => device.deviceCode === DeviceCode.STB && device.isOwnership,
  );

  const getRentInfo = devicePriceInfos.find(
    (device) => device.deviceCode === DeviceCode.STB && !device.isOwnership,
  );

  return (
    <>
      {isShowBlock && (
        <>
          {getRentInfoWithOwnership?.isAvailable &&
            getRentInfo?.isAvailable && (
              <span>
                Возможно предоставление Телеприставки в пользование с
                последующей передачей в собственность или предоставление
                Телеприставки в пользование.
              </span>
            )}

          {getRentInfo?.isAvailable && (
            <p>
              {!getRentInfoWithOwnership.isAvailable && (
                <>Возможно предоставление Телеприставки в пользование.</>
              )}
              {stbDeviceInfo.oneTimeCharge > 0 && (
                <>
                  Условие получения Телеприставки в пользование — единовременный
                  платёж при передаче в пользование в размере
                  {stbDeviceInfo.oneTimeCharge} руб. Единовременный платёж
                  списывается с Лицевого Счёта Клиента.{' '}
                </>
              )}
              Срок пользования Телеприставкой — до момента расторжения Договора.
            </p>
          )}

          {getRentInfoWithOwnership.isAvailable && (
            <p>
              {!getRentInfo?.isAvailable && (
                <span>
                  Возможно предоставление Телеприставки в пользование с
                  последующей передачей в собственность.
                  <br />
                </span>
              )}
              {stbDeviceInfo.oneTimeCharge > 0 && (
                <>
                  Условие получения Телеприставки в пользование с последующей
                  передачей в собственность — единовременный платёж при передаче
                  в пользование в размере {stbDeviceInfo.oneTimeCharge} руб.
                  Единовременный платёж списывается с Лицевого Счёта Клиента.{' '}
                </>
              )}
              Срок пользования Телеприставкой — до момента расторжения Договора
              или до перехода Телеприставки в собственность. Переход в
              собственность происходит через 3 года после начала оказания
              услуги.
            </p>
          )}
        </>
      )}
    </>
  );
};

export default StbRentText;
