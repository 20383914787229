import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop650,
  desktop800,
  desktop940,
} from '~/components/Grid/constants';

export const StyledHelp = styled.div`
  background-color: ${defaultTheme.colors.white};
  padding: 32px;
  height: auto;

  h3 {
    margin-bottom: 33px;
    width: 100%;
  }

  .help {
    &__blocks {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: auto;
    }
    &__action-block {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-bottom: 24px;

      > svg {
        margin-right: 8px;
      }
    }
    &__action-block:last-of-type {
      margin-bottom: 0;
    }

    &__chat-icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: ${desktop650}px) {
    .help {
      &__blocks {
        height: 168px;
      }
    }
  }

  @media (min-width: ${desktop800}px) {
    .help {
      &__blocks {
        height: 120px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
    .help {
      &__blocks {
        height: auto;
      }
    }
  }
`;
