/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo } from '../Legal.types';

/** методы-помощники */
import { isGigabit } from './utils';

/** утилиты */
import { formatNumber } from '~/utils/utils';
import { SeriesCode } from '../Legal.const';

/** интерфейс компонента-шаблона ConnectionText */
interface ConnectionTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона ConnectionText
 * @param {ConnectionTextProps} content
 * @returns {React.FC}
 */
const ConnectionText: React.FC<ConnectionTextProps> = ({
  content,
}: ConnectionTextProps) => {
  /**
   * текст для опции canMigrate
   * @returns {JSX.Element}
   */
  const TextNotMigrate = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора.
    </span>
  );

  /**
   * текст для опции connectionMethod
   * @param price
   * @returns {JSX.Element}
   */
  const TextGigabit = (price: number): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор Продукта при
      заключении Договора или смена продукта при наличии у Клиента постоянного
      подключения к Сети Оператора. Для выбора Продукта обязательно подключение
      по технологии Оптический гигабит.
      <br />
      <br />
      Стоимость подключения по технологии Ethernet FTTH (Оптический Гигабит) —{' '}
      {formatNumber(price)} руб.
    </span>
  );

  /**
   * текст для "Подключения" с опцией connectionFee > migrationCostMin
   * @returns {JSX.Element}
   */
  const TextConnectFee = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта при переходе с другого продукта с
      большей абонентской платой при наличии у Клиента постоянного подключения к
      Сети Оператора. В случае, если Клиент перешёл на данный Продукт с другого
      продукта с меньшей или равной абонентской платой, стоимость подключения к
      продукту равна {formatNumber(content.migrationCostMin)}&nbsp;руб.
    </span>
  );

  /**
   * текст для "Подключения" с опцией migrationCostMax
   * @returns {JSX.Element}
   */
  const TextConnectFeeWithCostMax = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта при переходе с другого продукта с
      меньшей или равной абонентской платой при наличии у Клиента постоянного
      подключения к Сети Оператора. В случае, если Клиент перешёл на данный
      продукт с другого продукта с большей абонентской платой, стоимость
      подключения к продукту равна {formatNumber(content.migrationCostMax)}
      &nbsp;руб.
    </span>
  );

  /**
   * текст для "Подключения" с опциями connectionFee = 0,
   * migrationCostMin = 0 и migrationCostMax = 0
   * @returns {JSX.Element}
   */
  const TextConnectFeeWithZeroPrice = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта с меньшей или равной абонентской
      платой при наличии у Клиента постоянного подключения к Сети Оператора.
    </span>
  );

  /**
   * текст если не одна из опций не выполнена
   * @returns {JSX.Element}
   */
  const TextDefault = (): JSX.Element => (
    <span>
      * Под «Подключением к продукту» понимается выбор продукта при заключении
      Договора или смена продукта при наличии у Клиента постоянного подключения
      к Сети Оператора.
    </span>
  );

  /**
   * текст для "Подключения" с кодом продукта "hit.super.2.0"
   * @returns {JSX.Element}
   */
  const TextHitSuper2 = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта при наличии у Клиента постоянного
      подключения к Сети Оператора.
      <br />
      Для выбора Продукта обязательно подключение по технологии Оптический
      гигабит.
    </span>
  );

  /**
   * текст для "Подключения" с кодом продукта "online.super.2.0"
   * @returns {JSX.Element}
   */
  const TextOnlineSuper2 = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта при наличии у Клиента постоянного
      подключения к Сети Оператора.
      <br />
      Для выбора Продукта обязательно подключение по технологии Оптический
      гигабит.
    </span>
  );

  /**
   * текст для "Подключения" с параметром "bundle"
   * @returns {JSX.Element}
   */
  const TextBundleB2B = (): JSX.Element => (
    <span>
      Продукт доступен только клиентам физическим лицам, занимающим нежилые
      помещения жилых зданий в зоне технической возможности подключения к сети
      Оператора.
    </span>
  );

  /**
   * текст для "Подключения" с параметром "hit.super"
   * @returns {JSX.Element}
   */
  const TextHitSuper = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта при наличии у Клиента постоянного
      подключения к Сети Оператора.
    </span>
  );

  /**
   * текст для "Подключения" с параметром "bundle"
   * @returns {JSX.Element}
   */
  const TextOnlineSuper = (): JSX.Element => (
    <span>
      * Под &laquo;Подключением к продукту&raquo; понимается выбор продукта при
      заключении Договора или смена продукта при наличии у Клиента постоянного
      подключения к Сети Оператора.
    </span>
  );

  let Result: JSX.Element = null;
  if (!content.canMigrate) {
    Result = TextNotMigrate();
  } else if (content.isBundleB2B) {
    Result = TextBundleB2B();
  } else if (content.seriesCode === SeriesCode.HIT_SUPER_2_0) {
    Result = TextHitSuper2();
  } else if (content.seriesCode === SeriesCode.ONLINE_SUPER_2_0) {
    Result = TextOnlineSuper2();
  } else if (isGigabit(content.connectionMethod)) {
    Result = TextGigabit(content.connectionPrice);
  } else if (content.seriesCode === SeriesCode.HIT_SUPER) {
    Result = TextHitSuper();
  } else if (content.seriesCode === SeriesCode.ONLINE_SUPER) {
    Result = TextOnlineSuper();
  } else if (
    content.connectionFee > 0 &&
    content.connectionFee > content.migrationCostMin
  ) {
    Result = TextConnectFee();
  } else if (content.connectionFee === 0 && content.migrationCostMax > 0) {
    Result = TextConnectFeeWithCostMax();
  } else if (
    content.connectionFee === 0 &&
    content.migrationCostMin === 0 &&
    content.migrationCostMax === 0
  ) {
    Result = TextConnectFeeWithZeroPrice();
  } else {
    Result = TextDefault();
  }

  return <p>{Result}</p>;
};

export default ConnectionText;
