/* Библиотеки */
import style from '@emotion/styled';
/* Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';
/* Типы */
import { CallToActionFeeLeftColumnProps } from './CallToActionFee.types';

/* Стилизованная часть левой колонки строки с абонентской платой */
export const StyledLeftCol = style.div<CallToActionFeeLeftColumnProps>`
  ${() => `
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 35%;
      h2 {
        word-break: break-all;
        margin: 8px 0 36px 0;
        line-height: 47px;
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          margin: 8px 0 0 0;
        }
      }
      > div:last-child {
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
        }
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          display: none;
        }
      }
    `}
`;

/* Стилизованная часть правой колонки строки с абонентской платой */
export const StyledRightCol = style.div`
  ${() => `
      display: none;
      max-width: 250px;
      align-items: flex-start;
      span {
        white-space: pre-line;
      }
      svg {
        margin-right: 12px;
      }
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: flex;
      }
    `}
`;
