/** libraries */
import styled from '@emotion/styled';
import { desktop800 } from '~/components/Grid/constants';

export const StyledSuspendedForNonPayment = styled.div`
  .payment {
    &__button-block {
      display: flex;
      flex-direction: column;

      > a {
        margin: 8px 0 0 0;
        order: 2;
        button {
          width: fit-content;
        }
      }
      > span {
        width: auto;
        order: 1;
      }
    }
  }

  @media (min-width: ${desktop800}px) {
    .payment {
      &__button-block {
        flex-direction: row;

        button {
          margin: 0 24px 0 0;
          order: 1;
        }
        > span {
          width: 385px;
          order: 2;
        }
      }
    }
  }
`;
