/** libraries */
import { FC, useMemo } from 'react';
import { H2, H3, Icon, Icons, SidePage } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** constants */
import {
  DEFAULT_ERROR,
  CONTACT_TYPES,
} from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import DeletingContactFooter from './DeletingContactFooter';
/** stores */
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';

const DeletingContactSidePage: FC = () => {
  const {
    contactTypes,
    deletingContactStore: {
      deleteContactInfo,
      setIsShowDeletingContact,
      isShowDeletingContact,
      result,
    },
  } = useContactsAndNotificationsStore();

  // Вычисление ширины экрана
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const emailClassId = contactTypes.find(
    (type) => type.code === CONTACT_TYPES.EMAIL,
  )?.id;

  /** Тип удаляемого контакта */
  const contactType = useMemo(() => {
    if (deleteContactInfo?.contactValueClassId === emailClassId) {
      return 'e-mail';
    }
    return 'номер телефона';
  }, [deleteContactInfo]);

  return (
    <SidePage
      show={isShowDeletingContact}
      headerText={!result.isResult && 'Удаление контакта'}
      onCloseClick={() => setIsShowDeletingContact(false)}
      footerContainer={!result.isResult && <DeletingContactFooter />}
      isOnlyMobileFooter
      removeScrollBar
    >
      {result.isResult ? (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      ) : (
        <div>
          Вы действительно хотите удалить {contactType}{' '}
          {deleteContactInfo?.value}?
        </div>
      )}
    </SidePage>
  );
};

export default observer(DeletingContactSidePage);
