export interface PromoProps {
  [key: string]: PromoContentProps;
}

export interface PromoContentProps {
  header: string;
  content: string;
  isCashbackBottoms?: boolean;
}

/** Тип оплаты */
export enum PAYMENT_TYPE {
  SBP = 'Sbp',
  CARD_PAYMENT = 'CardPayment',
}

/** Стандартный ответ api */
export interface DataProps {
  ok: boolean;
  status: number;
  statusText: string;
}
