import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${desktop940}px) {
    justify-content: flex-start;
    width: 830px;
    > div:first-of-type {
      width: 313px;
      padding: 40px 32px 32px;
    }
    > div:nth-of-type(2) {
      margin: 0 99px 0;
    }
    > div:last-of-type {
      width: 313px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    justify-content: flex-start;
    width: 1070px;
    > div:first-of-type {
      width: 433px;
    }
    > div:nth-of-type(2) {
      margin: 0 99px 0;
    }
    > div:last-of-type {
      width: 433px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    width: 1131px;
    > div:first-of-type {
      width: 466px;
    }
    > div:nth-of-type(2) {
      margin: 0 97px 0;
    }
    > div:last-of-type {
      width: 466px;
    }
  }
`;

export const MiddleLine = styled.div<{ $height: number }>`
  position: relative;
  width: 6px;
  background: ${defaultTheme.colors.white};
  z-index: 1;
  height: ${({ $height }) => $height}px;
  left: 6px;
`;

export const DateCircle = styled.div`
  user-select: none;
  position: absolute;
  top: 64px;
  left: -46px;
  background: ${defaultTheme.colors.white};
  z-index: 2;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 7px;

  span {
    font-weight: 500;
    font-size: 21px;
  }
`;
