/** библиотеки */
import React, { FC, useState, useEffect } from 'react';
import { H2, H3, TagButton, Button, Icon, Icons } from 'cordis-core-ui-planeta';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** типы */
import { TagProps } from '../../interfaces';

/** стилевые компоненты */
import { StyledDevicesHeader, StyledDevicesTags } from '../../style';

/* Утилиты */
import useWindowSize from '~/utils/useWindowSize';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { DEFAULT_TAG } from '../../constants';

/** stores */
import useDeviceStore from '../../store/useDeviceStore';

/**
 * Компонент с заголовком и тегами
 */
const DeviceHeader: FC = () => {
  const { tags, activeTag, setActiveTag } = useDeviceStore();

  // Ширина сайта
  const [width] = useWindowSize();
  // Вид блока с тегами в мобильной версии
  const [isThemesOpen, setIsThemesOpen] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый тег в мобильной версии
  const [candidateForActiveTag, setCandidateForActiveTag] = useState<TagProps>(
    DEFAULT_TAG,
  );
  // Объект router
  const router = useRouter();
  // Урл страницы
  const url = new URL(document.location.href);

  /**
   * Меняет Url
   * @param item текущий тег
   */
  const changeURL = (item: TagProps): void => {
    const valueHash = item.tag === DEFAULT_TAG.tag ? '' : `#${item.tag}`;
    router.push(`/devices${valueHash}`, `/devices${valueHash}`, {
      shallow: true,
    });
  };

  /**
   * Меняет значение активного тега
   * @param item текущий тег
   */
  const changeActiveTag = (item: TagProps): void => {
    if (item.tag === activeTag.tag) return;
    setActiveTag(item);
    changeURL(item);
  };

  /**
   * Обрабатывает клик по тегу в блоке тегов
   * @param item текущий тег
   */
  const handleTagClick = (item: TagProps) => {
    if (width < desktop940) {
      setCandidateForActiveTag(item);
      return;
    }
    changeActiveTag(item);
  };

  // Обрабатывает клик по кнопке "Показать"
  const handleTagButtonClick = () => {
    setIsThemesOpen(false);
    changeActiveTag(candidateForActiveTag);
  };

  useEffect(() => {
    if (width < desktop940 && isThemesOpen) setIsThemesOpen(false);
  }, [width]);

  // Изменение выбираемого, но не подтверждённого тега в мобильной версии
  useEffect(() => {
    setCandidateForActiveTag(activeTag);
  }, [activeTag.value]);

  // Изменение активного тега при переходе на страницу тега
  useEffect(() => {
    if (!tags.length) return;
    const urlTag = tags.find((item: TagProps) => `#${item.tag}` === url.hash);
    if (!urlTag) return;
    setActiveTag(urlTag);
  }, [tags.length, url.hash]);

  // Возвращает блок с заголовком
  const renderTitleBox = () => {
    return (
      <>
        {width >= desktop940 ? (
          <H2>Оборудование</H2>
        ) : (
          <>
            <div className="devices__title-wrap">
              <H3>Оборудование</H3>
              <Icon
                className="devices__title-icon"
                icon={isThemesOpen ? <Icons.CloseIcon /> : <Icons.ListIcon />}
                onClick={() => setIsThemesOpen(!isThemesOpen)}
                highlight
              />
            </div>
          </>
        )}
      </>
    );
  };

  // Возвращает блок с тегами
  const renderTag = (item: TagProps) => {
    const { value, tag } = item;

    return (
      <div className="devices__tag">
        <TagButton
          className="devices__tag-button"
          onChange={() => handleTagClick(item)}
          checked={
            width < desktop940
              ? tag === candidateForActiveTag.tag
              : tag === activeTag.tag
          }
        >
          {value}
        </TagButton>
      </div>
    );
  };

  return (
    <StyledDevicesHeader isVisible={isThemesOpen}>
      <div className="devices__header">
        {renderTitleBox()}
        {tags.length > 0 && (width >= desktop940 || isThemesOpen) && (
          <>
            <StyledDevicesTags>
              {tags.map((item: TagProps) => (
                <React.Fragment key={item.tag}>
                  {renderTag(item)}
                </React.Fragment>
              ))}
            </StyledDevicesTags>
            {width < desktop940 && (
              <div className="button-wrapper">
                <Button onClick={handleTagButtonClick}>Показать</Button>
              </div>
            )}
          </>
        )}
      </div>
    </StyledDevicesHeader>
  );
};

export default observer(DeviceHeader);
