import React, { FC } from 'react';
import { parseISO, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Image from 'next/image';

/** компоненты библиотеки */
import { H3, Text, Icons, defaultTheme } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledAnnouncement } from './Announcement.style';
import { useMediaQuery } from 'react-responsive';
import LinkWrapper from '~/components/LinkWrapper';

/** утилиты */
import { isExternal } from '~/utils/utils';

/** константы */
import { desktop450, desktop940 } from '~/components/Grid/constants';
import { STORIES_TAG } from '../Announcements.constants';

interface AnnouncementProps {
  publishedDt: string;
  title: string;
  subTitle?: string;
  imgLink: string;
  tag: string;
  fullSlug: string;
  blockWidth: number;
  isLoading?: boolean;
  tagClick?: (
    tag: string,
    tagUrl: string,
    event: React.MouseEvent<HTMLSpanElement>,
  ) => void;
  tagUrl: string;
}

/**
 * Компонент анонса
 */
const Announcement: FC<AnnouncementProps> = ({
  publishedDt,
  title,
  subTitle,
  imgLink,
  tag,
  fullSlug,
  blockWidth,
  tagClick,
  tagUrl,
}: AnnouncementProps) => {
  /** Вернёт отформатированную дату */
  const getDate = (dateString: string): string => {
    const dateISO = parseISO(dateString);
    return format(dateISO, 'd MMMM yyyy', { locale: ru });
  };

  const imageLoader = ({ src, width, quality }) => {
    const clientSrc = `${src}?w=${width}&q=${quality || 75}`;
    return isExternal(src)
      ? clientSrc
      : `${process.env.STATIC_SERVER}${clientSrc}`;
  };

  /** Вычисление ширины экрана */
  const isDesktop450 = useMediaQuery({
    query: `(min-width: ${desktop450}px)`,
  });

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isStories = tagUrl === STORIES_TAG;

  const getObjectFit = () => {
    const isDesktop = isDesktop940;
    const isMobile = isDesktop450;
    if (isMobile) return 'cover';
    if (isDesktop) return 'fill';
    if (!isDesktop && !isStories) return 'contain';
    return 'cover';
  };

  return (
    <StyledAnnouncement blockWidth={blockWidth} isStories={isStories}>
      <LinkWrapper href={fullSlug}>
        <div>
          {imgLink ? (
            <div className="announcement__image">
              <Image
                loader={imageLoader}
                src={imgLink}
                alt={title}
                layout="fill"
                objectFit={getObjectFit()}
                objectPosition="bottom"
                quality={100}
              />
            </div>
          ) : (
            <div className="announcement__default-image">
              <Icons.LogoShort />
            </div>
          )}
          <div className="announcement__content">
            <div className="announcement__text">
              <Text
                color={defaultTheme.colors.gray}
                onClick={(event) => tagClick(tag, tagUrl, event)}
              >
                {tag}
              </Text>
              <H3 isStories={isStories} className="announcement__title">
                {title}
              </H3>
            </div>
            <div className="announcement__date">
              <Text color={defaultTheme.colors.gray}>
                {getDate(publishedDt)}
              </Text>
            </div>
            {subTitle && (
              <Text fontWeightBold color={defaultTheme.colors.white}>
                {subTitle}
              </Text>
            )}
          </div>
        </div>
      </LinkWrapper>
    </StyledAnnouncement>
  );
};

export default React.memo(Announcement);
