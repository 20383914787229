/** библиотеки */
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** стили */
import { StyledDevicePurchasesWizard } from './style';

/** компоненты библиотеки */
import {
  Text,
  defaultTheme,
  LeadingText,
  PriceTag,
  Button,
  PriceTagBackgroundColor,
  ButtonStyleTypes,
  Icon,
  Icons,
  H2,
  H3,
  Input,
  Consent,
} from 'cordis-core-ui-planeta';

/** интерфейсы */
import { LeasePayAgreementResult, LeasePayInputParams } from '../../interfaces';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';

/** константы */
import { desktop1100 } from '~/components/Grid/constants';
import { DEFAULT_ERROR, INTERNAL_ERROR } from '../../constants';

/** api */
import { leasePayAgreementGet } from '~/api/apiPab2c';

/** utils */
import { formatNumber } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../../stores/useDevicesStore';

/**
 * Блок pab2cDevices. Компонент сайдпейджа погашения рассрочки
 */
const DeviceLeasePayWizard: FC = () => {
  const {
    authStore: { auth, isTemporaryTokenAuth },
    pab2cBalanceStore: { getBalance },
  } = useRootStore();
  const {
    isMainAgreement,
    setIsMainAgreement,
    detailedDeviceStore: { detailedDevice: device },
    deviceLeasePayStore: {
      setLeasePayAmount,
      leasePayAmount,
      leasePay,
      resultLeasePay,
      leaseError,
      leaseErrorCode,
      isErrorAmount,
      setIsErrorAmount,
    },
  } = useDevicesStore();

  /** Вычисление ширины экрана */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Разница между балансом и ценой выкупа оборудования */
  const differenceBetweenBalanceAndPrice = auth.balance - device.leaseSaldo;

  /** Баланс больше чем цена оборудования */
  const isBalanceMoreThanPrice = differenceBetweenBalanceAndPrice >= 0;

  /** Баланс больше 0 */
  const isPositiveBalance = auth.balance > 0;

  const handleKeyPress = async (event) => {
    if (
      !isMainAgreement ||
      !isPositiveBalance ||
      isTemporaryTokenAuth ||
      isErrorAmount
    ) {
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      await leasePay();
      await getBalance();
    }
  };

  const validateAmount = () => {
    const isAmountMore = isBalanceMoreThanPrice
      ? +leasePayAmount.forSend > device.leaseSaldo
      : +leasePayAmount.forSend > auth.balance;
    const isNotValidAmount = +leasePayAmount.forSend <= 0 || isAmountMore;
    setIsErrorAmount(isNotValidAmount);
  };

  /** Текст "Соглашение" */
  const [
    leasePayText,
    setLeasePayText,
  ] = useState<LeasePayAgreementResult | null>(null);

  /** Устанавливает текст "Гарантия+" */
  useEffect(() => {
    (async () => {
      try {
        const leasePayTextResult = await leasePayAgreementGet(device.sim);
        setLeasePayText(leasePayTextResult);
      } catch (e) {
        console.error('leasePayAgreementGet', e);
      }
    })();
  }, []);

  /** Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string | null>(leaseError);

  // Обработка ошибок при оплате
  useEffect(() => {
    setErrorMessage(leaseError);
    if (leaseErrorCode === INTERNAL_ERROR) {
      setErrorMessage(DEFAULT_ERROR);
    }
  }, [leaseErrorCode]);

  /** Максимальная сумма оплаты */
  const maxAmount = useMemo(() => {
    if (isBalanceMoreThanPrice) return formatNumber(device.leaseSaldo);
    return formatNumber(auth.balance);
  }, [isBalanceMoreThanPrice]);

  return (
    <StyledDevicePurchasesWizard>
      {resultLeasePay.isResult && resultLeasePay.isCorrect && (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? (
            <H2>{resultLeasePay.text}</H2>
          ) : (
            <H3>{resultLeasePay.text}</H3>
          )}
        </>
      )}

      {resultLeasePay.isResult && !resultLeasePay.isCorrect && (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{errorMessage}</H2> : <H3>{errorMessage}</H3>}
        </>
      )}

      {!resultLeasePay.isResult && (
        <>
          {device.serial && (
            <div className="device-purchases__block-wrapper">
              <Text
                className="device-purchases__block-wrapper__header"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Серийный номер
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {device.serial}
              </LeadingText>
            </div>
          )}
          <div className="device-purchases__block-wrapper">
            <div className="device-purchases__block-wrapper__price-tag">
              <PriceTag
                header="Сумма списания"
                value={`${formatNumber(device?.leaseSaldo)} ₽`}
                backgroundColor={
                  isPositiveBalance
                    ? PriceTagBackgroundColor.OK
                    : PriceTagBackgroundColor.WARNING
                }
                subscription={
                  isPositiveBalance
                    ? `На вашем${'\u000A'}счету ${formatNumber(auth.balance)} ₽`
                    : `Не хватает${'\u000A'}${formatNumber(
                        Math.abs(differenceBetweenBalanceAndPrice),
                      )} ₽`
                }
                fontColor={
                  isPositiveBalance
                    ? defaultTheme.colors.black
                    : defaultTheme.colors.warning
                }
                headerType="H2"
              />
              {!isPositiveBalance && (
                <LinkWrapper href="/payment">
                  <Button styleType={ButtonStyleTypes.SECONDARY}>
                    {!isDesktop1100 ? 'Пополнить' : 'Пополнить баланс'}
                  </Button>
                </LinkWrapper>
              )}
            </div>
          </div>
          {isPositiveBalance && (
            <>
              <div className="device-purchases__block-wrapper">
                <div className="wizard__input-item">
                  <div className="wizard__label">
                    <label htmlFor="leasePayAmount">
                      <Text color={defaultTheme.colors.shadow}>
                        Вы можете досрочно оплатить остаток по рассрочке
                      </Text>
                    </label>
                  </div>
                  <div>
                    <Input
                      id="leasePayAmount"
                      className="wizard__input wizard__input-amount"
                      type="money"
                      value={leasePayAmount.value}
                      onChangeCustomInput={(params): void => {
                        if (Number.isNaN(params.forSend)) {
                          params.forSend = 0;
                          params.value = '';
                        }
                        setLeasePayAmount(params as LeasePayInputParams);
                      }}
                      error={isErrorAmount}
                      onKeyPress={handleKeyPress}
                      placeholder={`от 1 до ${maxAmount} ₽`}
                      onBlur={validateAmount}
                      onKeyUp={validateAmount}
                    />
                  </div>
                  <Text
                    className="wizard__input-limit"
                    color={
                      isErrorAmount
                        ? defaultTheme.colors.planeta
                        : defaultTheme.colors.black
                    }
                  >
                    Введите от 1 до {maxAmount} ₽
                  </Text>
                </div>
              </div>
              {leasePayText && (
                <div className="device-purchases__agreement">
                  <Consent
                    header="Я&nbsp;намерен(а) купить предоставленное в&nbsp;пользование с&nbsp;передачей в&nbsp;собственность оборудование"
                    text={parseHtml(leasePayText.data)}
                    onChangeCheckbox={(e) =>
                      setIsMainAgreement(e.target.checked)
                    }
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </StyledDevicePurchasesWizard>
  );
};

export default observer(DeviceLeasePayWizard);
