/** libraries */
import { FC, useEffect, useState } from 'react';
import {
  Consent,
  defaultTheme,
  Input,
  Select,
  Text,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledAddingContact } from './styles';
/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';
/** constants */
import { CONTACT_TYPES } from '../../../../Templates/Pab2c/Settings/constants';
import {
  CELLPHONE_VALIDATE_REGEXP,
  EMAIL_VALIDATE_REGEXP,
} from '~/constants/common';
/** stores */
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';

const AddingContact: FC = () => {
  const {
    contactTypes,
    addingContactStore: {
      contactType,
      setContactType,
      phoneNumber,
      setPhoneNumber,
      phoneNumberError,
      setPhoneNumberError,
      email,
      setEmail,
      emailError,
      setEmailError,
      isNotification,
      setIsNotification,
    },
  } = useContactsAndNotificationsStore();

  /** Номер телефона */
  const onChangePhoneNumber = (event) => {
    const { value, forSend, errorText } = event;
    setPhoneNumber({ value, forSend });
    setPhoneNumberError(errorText ? 'Введите корректный номер телефона ' : '');
  };

  const onBlurPhoneNumber = () => {
    if (
      !CELLPHONE_VALIDATE_REGEXP.test(phoneNumber.forSend.replace('+7', '8'))
    ) {
      setPhoneNumberError('Введите корректный номер телефона');
    }
  };

  const onChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError('');
  };
  const onBlurEmail = () => {
    if (!EMAIL_VALIDATE_REGEXP.test(email)) {
      setEmailError('Введите корректную электронную почту');
    }
  };

  /** Предложения и акции */
  const [isOffersAndPromotions, setIsOffersAndPromotions] = useState<boolean>(
    true,
  );

  useEffect(() => {
    setContactType(
      contactTypes.find((type) => type.code === CONTACT_TYPES.PHONE),
    );
  }, []);

  return (
    <StyledAddingContact>
      <div className="add-contact__select">
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Тип контакта
        </Text>
        <Select
          placeholder="Выберите тип контакта"
          onOptionClick={(option) =>
            setContactType(
              contactTypes.find((item) => item.value === option.value),
            )
          }
          visibleOptionCount={4}
          value={contactType ? contactType.value : ''}
          outsideClickHelper={outsideClickHelper}
          data={contactTypes}
        />
      </div>
      {contactType?.code === CONTACT_TYPES.PHONE && (
        <div className="add-contact__select">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Номер телефона
          </Text>
          <Input
            type="phone"
            placeholder="Введите номер"
            value={phoneNumber.value}
            error={phoneNumberError}
            onChangeCustomInput={onChangePhoneNumber}
            onBlur={onBlurPhoneNumber}
            isFocus
          />
        </div>
      )}
      {contactType?.code === CONTACT_TYPES.EMAIL && (
        <div className="add-contact__select">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Электронная почта
          </Text>
          <Input
            type="text"
            placeholder="Введите почту"
            value={email}
            error={emailError}
            onChange={onChangeEmail}
            onBlur={onBlurEmail}
            isFocus
          />
        </div>
      )}
      <Consent
        header="Уведомления компании Планета"
        text="Уведомления о состоянии обслуживания договора, технических работах и изменениях условий обслуживания"
        checked={isNotification}
        onChangeCheckbox={(e) => setIsNotification(e.target.checked)}
      />
      <div className="add-contact__consent">
        <Consent
          header="Предложения и акции"
          text="Информирование о выгодных предложениях, скидках и акциях компании «Планета»"
          checked={isOffersAndPromotions}
          onChangeCheckbox={(e) => setIsOffersAndPromotions(e.target.checked)}
        />
      </div>
    </StyledAddingContact>
  );
};

export default observer(AddingContact);
