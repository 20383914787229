import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledRates = styled.div`
  margin-top: -16px;
  .rates {
    &__switcher {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        order: 2;
        > span:first-of-type {
          margin-right: 12px;
          font-weight: 500;
        }
        table {
          .wrapper {
            margin-right: 12px;
            .slider__button {
              background-color: ${defaultTheme.colors.planeta}!important;
              > span {
                visibility: hidden;
              }
            }
          }
        }
      }

      &__link {
        order: 1;
        margin: 0 0 24px 0;
        cursor: pointer;
      }

      &__text {
        font-weight: 500;
      }
    }

    &__block {
      display: flex;
      flex-direction: column;
      &__input {
        display: flex;
        flex-direction: column;
        &__text {
          order: 1;
          margin: 0 0 16px 0;
        }
        &__code {
          order: 2;
          input {
            width: 100%;
          }
        }
      }
      button {
        width: 100%;
        margin: 16px 0 24px;
      }
    }

    &__table {
      margin-top: 16px;
      &__line {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid ${defaultTheme.colors.line};
        margin-bottom: 8px;
        padding-bottom: 8px;
        &__weight {
          font-weight: 500;
        }
        &__column {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          > span:first-of-type {
            margin-bottom: 5px;
          }
        }
        &__code {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          > span:nth-of-type(2) {
            word-break: break-word;
          }
        }
        &__code:last-of-type {
          margin-bottom: 0;
        }
      }
      &__line:last-of-type {
        border-bottom: none;
      }
    }

    &__lightbox {
      .popup {
        &__container {
          h3 {
            text-align: start;
            font-size: 21px;
            line-height: 25px;
            font-weight: 500;
          }
          > div:first-of-type {
            padding: 32px 0 24px 0;
            margin: 0 32px;
            border-bottom: 1px solid ${defaultTheme.colors.line};
          }
        }
        &__header {
          &__close {
            right: 0;
            top: 29px;
          }
        }
      }
    }

    &__not-found {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      &__text {
        font-weight: 500;
        margin-bottom: 4px;
      }
    }

    &__error {
      display: flex;
      align-items: center;
      > svg {
        margin-right: 8px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .rates {
      &__switcher {
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
          order: 1;
        }

        &__link {
          margin: 0 0 0 24px;
          order: 2;
        }
      }
      &__block {
        flex-direction: row;
        &__input {
          display: flex;
          flex-direction: column;
          &__text {
            order: 2;
            margin: 8px 0 0 0;
          }
          &__code {
            order: 1;
          }
        }
        button {
          margin: 0 0 0 24px;
          height: 54px;
          width: 279px;
          padding: 12px 31px;
          > span {
            letter-spacing: -0.03em;
          }
        }
      }
      &__table {
        margin-top: 32px;
        max-height: 118px;
        width: max-content;
        overflow-x: hidden;
        padding-right: 5px;
        &__line {
          display: grid;
          gap: 8px;
          grid-template-columns: 311px 100px 100px;
          align-items: end;
          &__column {
            margin-bottom: 0;
            > span:first-of-type {
              margin-bottom: 4px;
            }
          }
          &__code {
            flex-direction: row;
            align-items: end;
            &__text {
              margin-right: 5px;
            }
            margin-bottom: 0;
          }
        }
      }
      &__table::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }

      &__table::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 6px;
      }

      &__table::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: ${defaultTheme.colors.disable};
      }

      &__lightbox {
        .popup {
          &__container {
            height: 600px;
            > div:first-of-type {
              margin: 0 24px;
              padding: 24px 0;
            }
          }
          &__header {
            &__close {
              right: 0;
              top: 22px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .rates {
      &__block {
        button {
          width: 240px;
          padding: 12px 31px;
        }
      }
      &__table {
        &__line {
          grid-template-columns: 411px 110px 110px;
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .rates {
      &__block {
        button {
          width: 225px;
          padding: 12px 31px;
        }
      }
      &__table {
        &__line {
          grid-template-columns: 431px 210px 110px;
        }
      }
    }
  }
`;
