import React, { FC } from 'react';
import { Button, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { StyledFooter } from './styles';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { observer } from 'mobx-react';
import useVoiceStore from '../../store/useVoiceStore';

interface PasswordFooterProps {
  changePassword: () => Promise<void>;
  isLoading: boolean;
  isDisabled: boolean;
}

const PasswordFooter: FC<PasswordFooterProps> = ({
  changePassword,
  isLoading,
  isDisabled,
}: PasswordFooterProps) => {
  const { isCreatePassword } = useVoiceStore();
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  if (isTemporaryTokenAuth) {
    return (
      <StyledFooter>
        <Button disabled>
          {isCreatePassword ? 'Создать пароль' : 'Изменить пароль'}
        </Button>
        <Text
          style={{ marginLeft: '10px' }}
          color={defaultTheme.colors.warning}
        >
          Действие доступно только клиенту
        </Text>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter>
      <Button
        onClick={changePassword}
        loading={isLoading}
        disabled={isDisabled}
      >
        {isCreatePassword ? 'Создать пароль' : 'Изменить пароль'}
      </Button>
    </StyledFooter>
  );
};

export default observer(PasswordFooter);
