/** библиотеки */
import React, { FC } from 'react';
import { isBefore } from 'date-fns';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  Snoska,
  defaultTheme,
} from 'cordis-core-ui-planeta';

/** типы */
import { WarrantyPlusWizardFooterProps } from './types';
/** styles */
import { StyledFooter } from './style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/**
 * Блок pab2cDevices. SidePageFooter выкупа оборудования
 */
const WarrantyPlusWizardFooter: FC<WarrantyPlusWizardFooterProps> = ({
  isLoading,
  isOrdered,
  toggleWarranty,
  device,
  isAgree,
}: WarrantyPlusWizardFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const isDisabled = (): boolean => {
    return !(
      isBefore(new Date(device.warrantyPlusInfo?.banTrimDate), new Date()) &&
      isAgree
    );
  };

  return (
    <StyledFooter>
      <Button
        styleType={ButtonStyleTypes.MAIN}
        type="button"
        onClick={() => toggleWarranty()}
        loading={isLoading}
        disabled={isDisabled() || isTemporaryTokenAuth}
      >
        {isOrdered ? 'Отключить' : 'Подключить'}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(WarrantyPlusWizardFooter);
