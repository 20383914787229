/** libraries */
import { FC } from 'react';
import { Text } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import Summary from '../../../Summary/Summary';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import useProductSwitcherStore from '../../store/useProductSwitcherStore';

const SummaryList: FC = () => {
  const {
    fields: { productsNotAvailableText },
    extendedSummaryData,
    marketingGroupsProducts,
    filteredTariff,
    defaultImgs,
  } = useProductSwitcherStore();

  const isPrivateTariff = (seriesCodeProp): boolean => {
    return !marketingGroupsProducts.includes(seriesCodeProp);
  };

  return (
    <div className="product-switcher__summary">
      {filteredTariff.length > 0 ? (
        filteredTariff.map((item) => {
          return (
            <Summary
              key={item.seriesCode}
              content={{ fields: item, defaultImgs }}
              isPrivate={isPrivateTariff(extendedSummaryData?.seriesCode)}
              isLinkInNewTab
            />
          );
        })
      ) : (
        <Text lineHeight="24px">{parseHtml(productsNotAvailableText)}</Text>
      )}
    </div>
  );
};

export default observer(SummaryList);
