/** библиотеки */
import { FC } from 'react';
import { observer } from 'mobx-react';

/** типы */
import { DevicesFields } from '../../interfaces';

/** стилевые компоненты */
import { StyledDevicesList } from '../../style';

/** компоненты */
import DevicesCard from '~/components/Blocks/Shared/DevicesCard/DevicesCard';

/* Утилиты */
import { isExternal } from '~/utils/utils';

/** константы */
import { DEVICES_TABS } from '../../constants';

/** stores */
import useDeviceStore from '../../store/useDeviceStore';
import useDeviceDetailedCardStore from '~/components/Blocks/Shared/DeviceDetailedCard/store/useDeviceDetailedCardStore';

/**
 * Витрина с карточками оборудования
 */
const DeviceCards: FC = () => {
  const { imageSizes, filteredDevices } = useDeviceStore();
  const {
    setDetailedDeviceData,
    setIsDetailedDeviceShow,
  } = useDeviceDetailedCardStore();

  const getImageInfo = (deviceImageUrl: string) => {
    const imgUrl = isExternal(deviceImageUrl)
      ? deviceImageUrl
      : `${process.env.STATIC_SERVER}${deviceImageUrl}`;

    return imageSizes.find((item) => item.url === imgUrl);
  };

  const handleShowDetailedInfoClick = (e, item: DevicesFields) => {
    const isClickTab = DEVICES_TABS.some((tab) => {
      return tab === e.target.innerHTML;
    });

    if (!isClickTab) {
      setDetailedDeviceData(item);
      setIsDetailedDeviceShow(true);
    }
  };

  if (!filteredDevices.length) return null;

  return (
    <StyledDevicesList>
      {filteredDevices.map((item) => (
        <DevicesCard
          key={item.id}
          customTag={item.tag}
          item={item}
          onDeviceCardMouseUp={(e) => handleShowDetailedInfoClick(e, item)}
          imageInfo={getImageInfo(item.images)}
        />
      ))}
    </StyledDevicesList>
  );
};

export default observer(DeviceCards);
