import styled from '@emotion/styled';
/** константы */
import { desktop940, desktop500 } from '~/components/Grid/constants';

export const StyledCallbackWizardConfirm = styled.div`
display: flex;
flex-direction: column;
align-items: center;

button {
  align-self: center;
  width: 100%;
}

a {
  padding: 0;
}

.agreement__description {
  display: flex;
  margin-bottom: 16px;
}

.server-error {
  display: block;
  width: 100%;
  padding-bottom: 16px;
}

@media (min-width: ${desktop500}px) {
  button {
    max-width: 400px;
  }
}

@media (min-width: ${desktop940}px) {
  flex-direction: column;
  max-width: '419px';
  
  button {
    align-self: start;
    width: 237px;
  }

  .agreement__description {
    width: auto;
    align-self: normal;
    margin-bottom: 16px;
  }
`;
