/** libraries */
import { types } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { Contacts } from '../interfaces';
import { ContactsModel, ResultPropsModel } from './models';
import { ResultProps } from '~/components/Blocks/Templates/Pab2c/Settings/interfaces';
/** constants */
import { DEFAULT_RESULT } from '~/constants/common';

export const DeletingContactStore = types
  .model('DeletingContact', {
    /** Сайдпейдж удаления контакта */
    isShowDeletingContact: types.boolean,
    deleteContactData: types.maybeNull(ContactsModel),
    /** Результат удаления контакта */
    result: ResultPropsModel,
  })
  .views((self) => ({
    /** Удаляемый контакт */
    get deleteContactInfo() {
      return toJS(self.deleteContactData);
    },
  }))
  .actions((self) => ({
    setIsShowDeletingContact: (isShow: boolean) => {
      self.isShowDeletingContact = isShow;
    },
    deleteContact: (contact: Contacts) => {
      self.deleteContactData = contact;
      self.isShowDeletingContact = true;
    },
    setResult: (result: ResultProps) => {
      self.result = result;
    },
  }));

export const DeletingContactStoreInstance = DeletingContactStore.create({
  isShowDeletingContact: false,
  deleteContactData: null,
  result: DEFAULT_RESULT,
});
