/* Типы */
import { PurchaseTypes, CallToActionFeePriceTypes } from './CallToAction.types';

/* Возможные способы приобретения устройств */
export const DEVICE_PURCHASE_TYPES: string[] = [
  ...Object.values(PurchaseTypes).filter((k) => {
    return typeof k === 'string';
  }),
];

/* Возможное количество оборудования, приобретаемого в собственность */
export const DEVICE_BUY_AMOUNT = ['1 шт.', '2 шт.', '3 шт.'];

/* Варианты табов с отображением стоимости сервиса */
export const TABS_VARIANTS = [
  ...Object.values(CallToActionFeePriceTypes).filter((k) => {
    return typeof k === 'string';
  }),
];

/* Наименование пульта управления */
export const REMOTE_NAME = 'ПДУ';

/** идентификатор элемента блока Call to Action */
export const CALL_TO_ACTION_BLOCK_ID = '#cta';
