/** libraries */
import { getParent, types } from 'mobx-state-tree';
/** constants */
import { OWNERSHIP_CODES } from '../constants';
/** interfaces */
import { IDevicesStore } from './DevicesStore';
import { ContractDevice } from '../interfaces';

const DetailedDeviceModel = types
  .model({
    /** Флаг отображения детального сайдпейджа */
    isDetailedDeviceShow: types.boolean,
    /** Информация о конкретном устройстве */
    detailedDeviceData: types.maybeNull(types.string),
  })
  .views((self) => ({
    get detailedDevice() {
      return JSON.parse(self.detailedDeviceData) as ContractDevice;
    },
  }))
  .views((self) => ({
    /** Код владения продуктом */
    get deviceOwnership() {
      if (
        self.detailedDevice?.ownership ===
        OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP
      ) {
        /** Если срок передачи в собственность прошёл */
        if (self.detailedDevice.ownershipEndDays < 1)
          return OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP;
        return OWNERSHIP_CODES.LEASING;
      }
      return self.detailedDevice?.ownership;
    },
  }))
  .actions((self) => ({
    setDetailedDeviceShow: (isShow: boolean) => {
      self.isDetailedDeviceShow = isShow;
    },
    setDetailedDevice: (item: ContractDevice) => {
      if (!item) {
        self.detailedDeviceData = null;
        return;
      }
      self.detailedDeviceData = JSON.stringify(item);
      const { devicePurchasesStore }: IDevicesStore = getParent(self);
      devicePurchasesStore.devicePurchaseCheck(item.sim);
      devicePurchasesStore.deviceLeaseCheck(item.sim);
    },
  }));

export const DetailedDeviceStoreInstance = {
  isDetailedDeviceShow: false,
  detailedDeviceData: null,
};

export default DetailedDeviceModel;
