import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledTranformerMode = styled.div`
  .transformer {
    &__underhead {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 32px;
      &__tabs {
        width: 100%;
        margin-bottom: 16px;
        > div {
          width: 100%;
          & > div {
            width: 100%;
          }
          .active {
            background-color: ${defaultTheme.colors.black};
            > span {
              color: ${defaultTheme.colors.white} !important;
            }
          }
        }
      }
      &__channel-formats {
        width: 100%;
        > div {
          width: 100%;
          .select-wrapper,
          .select-overlay {
            width: 100%;
          }
        }
      }
    }
    &__search-icon {
      height: 32px;
      cursor: pointer;
    }
  }

  @media (min-width: ${desktop940}px) {
    .transformer {
      &__underhead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 54px;
        margin-top: 24px;

        &__tabs {
          width: auto;
          margin-bottom: 0;
          > div {
            > div:first-of-type {
              padding: 10px 16px 10px 32px;
            }
            > div:nth-of-type(2) {
              padding: 10px 32px 10px 16px;
            }
            .active {
              padding: 10px 32px !important;
              > span {
              }
            }
          }
        }
        &__channel-formats {
          width: 202px;
          margin-top: 0;
          margin-left: auto;

          > div {
            width: 202px;
            .select-wrapper {
              width: 202px;
            }
          }
        }
      }
      &__search-icon {
        margin-left: 24px;
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .transformer {
      &__underhead {
        &__channel-formats {
          width: 261px;

          > div {
            width: 261px;
            .select-wrapper {
              width: 261px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .transformer {
      &__underhead {
        &__channel-formats {
          width: 220px;

          > div {
            width: 220px;
            .select-wrapper {
              width: 220px;
            }
          }
        }
      }
    }
  }
`;
