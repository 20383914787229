/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop340,
  desktop400,
  desktop650,
  desktop800,
  desktop880,
  desktop940,
  desktop1100,
} from '~/components/Grid/constants';

import { IMAGES } from '../constants';

export const StyledTvScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  padding: 32px;
  padding-top: 28px;
  background-image: none;
  background-color: ${defaultTheme.colors.white};

  h2,
  h3 {
    font-weight: 700;
    margin-bottom: 14px;
  }

  .title {
    max-width: 164px;
  }

  .title__650 {
    max-width: 494px;
    margin-bottom: 30px;
  }

  .text {
    display: inline-block;
    max-width: 333px;
  }

  .text__line-height21 {
    line-height: 21px;
  }

  .text__mobile {
    max-width: 166px;
  }

  .text__650 {
    display: inline-block;
    max-width: 376px;
  }

  @media (min-width: ${desktop340}px) and (max-width: ${desktop400}px),
    (min-width: ${desktop1100}px) {
    .title {
      max-width: unset;
    }
  }

  @media (min-width: ${desktop650}px) {
    background-image: url(${IMAGES.gadgets650});
    background-position: bottom right -240px;
    background-repeat: no-repeat;
  }

  @media (min-width: ${desktop800}px) {
    background-position: bottom right -100px;
  }

  @media (min-width: ${desktop880}px) {
    background-position: bottom right;
  }

  @media (min-width: ${desktop940}px) {
    background-image: none;
  }
`;

export const StyledGadgetsBG = styled.div`
  height: 47px;
  margin-top: 12px;
  background: url(${IMAGES.gadgets}) no-repeat;

  @media (min-width: ${desktop650}px) {
    background-image: none;
  }

  @media (min-width: ${desktop940}px) {
    background: url(${IMAGES.gadgets}) no-repeat;
  }

  @media (min-width: ${desktop1100}px) {
    margin-top: 24px;
  }
`;
