import { format, parseISO, getDate, getMonth } from 'date-fns';
import { ru } from 'date-fns/locale';

/** Форматирует дату в строку
 * @param date - дата
 * @param formatType - формат даты
 */
export const dateString = (date: string | Date, formatType: string): string => {
  const formatDate = (dateTypeofDate: Date) => {
    return format(dateTypeofDate, formatType, { locale: ru }).replace(
      /\./g,
      ' ',
    );
  };
  if (typeof date === 'string') return formatDate(parseISO(date));
  return formatDate(date);
};

/** Преобразует первую букву в заглавную
 * @param date - строка
 */
export const withCapitalLetter = (date: string): string => {
  return date.charAt(0).toUpperCase() + date.slice(1);
};

/** Проверяет, что дата является первым числом следующего месяца */
export const isFirstDayOfNextMonth = (date: Date) => {
  const currentMonth = getMonth(new Date());
  const nextMonth = getMonth(date);

  return getDate(date) === 1 && currentMonth + 1 === nextMonth;
};
