import styled from '@emotion/styled';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const AgreementStyled = styled.div`
  h3 {
    margin-top: 30px;
  }

  ol {
    margin: 8px 0 0 16px;
  }

  @media (min-width: ${desktop940}px) {
    margin: 50px;

    .agreementPopup__text__description {
      max-width: 900px;
    }
  }
`;
