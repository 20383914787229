import { ReactNode, useContext } from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { Provider, MobXProviderContext } from 'mobx-react';
import { VacationContentProps } from '../../interfaces';
import createStore, { IMainVacationStore } from './MainVacationStore';

interface VacationProps {
  children: ReactNode;
  content: VacationContentProps;
}

export const VacationProvider = ({
  children,
  content,
}: VacationProps): JSX.Element => {
  const store = useLocalStore<IMainVacationStore>(() => createStore(content));
  return <Provider VacationStore={store}>{children}</Provider>;
};

export const useMainVacationStore = () => {
  const { VacationStore } = useContext(MobXProviderContext);
  return VacationStore;
};
