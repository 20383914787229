/** libraries */
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import {
  defaultTheme,
  Icon,
  Icons,
  OptionProp,
  Select,
  TabIconPosition,
  Tabs,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { channelFormats } from '../../../../constants';

/** styles */
import { StyledTranformerMode } from './styles';

/** components */
import TransformerTab from '../../../Tabs/TransformerTab';
import PackagesTab from '../../../Tabs/PackagesTab';
import ChannelsTab from '../../../Tabs/ChannelsTab';
import Search from '../../../Search/Search';

/** interfaces */
import { SubjectWithChannels } from '../../../../interfaces';

/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';

/** stores */
import { useMyTvStore } from '../../../../stores/useMyTvStore';
import { useRootStore } from '~/stores/RootStore';

interface TranformerModeProps {
  channelsByGroups: SubjectWithChannels[];
  activeChannelFormat: OptionProp;
  setActiveChannelFormat: Dispatch<SetStateAction<OptionProp>>;
}

const TranformerMode: FC<TranformerModeProps> = ({
  channelsByGroups,
  activeChannelFormat,
  setActiveChannelFormat,
}: TranformerModeProps) => {
  const {
    filteredPackages,
    isEditTransformer,
    searchMode,
    setSearchMode,
  } = useMyTvStore();

  const {
    tvStore: { idsAddedPacketsToTransformer, idsAddedChannelsToTransformer },
  } = useRootStore();

  /** Индекс активного таба */
  const [activeTransformerTabIndex, setActiveTransformerTabIndex] = useState(0);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Количество каналов в пакетах */
  const numberOfConnectedChannelsInPackages = useMemo(() => {
    const packets = filteredPackages.filter((item) =>
      idsAddedPacketsToTransformer.includes(item.channelPackId ?? item.id),
    );
    return packets.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      const ids = item?.channelIds ?? item?.channelPackChannels;
      ids.forEach((id) => {
        const singleId = id?.single_channel_packs?.length
          ? id?.single_channel_packs[0].id
          : null;
        if (
          !acc.includes(id) &&
          !idsAddedChannelsToTransformer.includes(singleId)
        )
          acc.push(id);
      });
      return acc;
    }, []).length;
  }, [idsAddedPacketsToTransformer, idsAddedChannelsToTransformer]);

  /** Количество редактируемых каналов */
  const numberOfChannels = useMemo(
    () =>
      numberOfConnectedChannelsInPackages +
      idsAddedChannelsToTransformer.length,
    [numberOfConnectedChannelsInPackages, idsAddedChannelsToTransformer],
  );

  const transformerTabs = useMemo(() => {
    return [
      {
        value: isDesktop940 && `Трансформер${'\u2007'}${numberOfChannels}`,
        icon: isEditTransformer ? (
          <Icon
            icon={<Icons.EditIcon />}
            iconColor={defaultTheme.colors.yellow}
          />
        ) : (
          !isDesktop940 && <Icons.ConnectedIcon white />
        ),
        inactiveIcon: isEditTransformer ? (
          <Icons.EditIcon />
        ) : (
          !isDesktop940 && <Icons.ConnectedIcon />
        ),
        iconPosition: isEditTransformer && TabIconPosition.RIGHT,
      },
      {
        value: isDesktop940 && 'Пакеты',
        icon: !isDesktop940 && <Icons.PackagesIcon white />,
        inactiveIcon: !isDesktop940 && <Icons.PackagesIcon />,
      },
      {
        value: isDesktop940 && 'Каналы',
        icon: !isDesktop940 && <Icons.ChannelsIcon white />,
        inactiveIcon: !isDesktop940 && <Icons.ChannelsIcon />,
      },
    ];
  }, [isEditTransformer, numberOfChannels, isDesktop940]);

  /* Возвращает компонент при переключении табов */
  const getTabContent = (tabIndex: number): JSX.Element => {
    switch (tabIndex) {
      case 0:
        return (
          <TransformerTab
            allChannelsIncludedInProduct={[]}
            numberOfChannels={numberOfChannels}
            isTransformer
          />
        );
      case 1:
        return (
          <PackagesTab
            tvChannelPackages={filteredPackages}
            packagesIncludedInProduct={[]}
            isTransformer
          />
        );
      case 2:
        return (
          <ChannelsTab
            formatFilteredChannelList={channelsByGroups}
            allChannelsIncludedInProduct={[]}
            isTransformer
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <StyledTranformerMode>
      {searchMode ? (
        <Search
          packagesIncludedInProduct={[]}
          allChannelsIncludedInProduct={[]}
          isTransformer
        />
      ) : (
        <>
          <div className="transformer__underhead">
            <div className="transformer__underhead__tabs">
              <Tabs
                valueWithIcons={transformerTabs}
                onChange={(tabIndex) => setActiveTransformerTabIndex(tabIndex)}
                activeTabIndex={activeTransformerTabIndex}
              />
            </div>
            <div className="transformer__underhead__channel-formats">
              {activeTransformerTabIndex === 2 && (
                <Select
                  placeholder="Выберите цвет радуги"
                  onOptionClick={(option) => setActiveChannelFormat(option)}
                  value={activeChannelFormat ? activeChannelFormat.value : ''}
                  outsideClickHelper={outsideClickHelper}
                  data={channelFormats}
                />
              )}
            </div>
            {isDesktop940 && (
              <div
                className="transformer__search-icon"
                onClick={() => setSearchMode(true)}
              >
                <Icons.GreySearchIcon />
              </div>
            )}
          </div>
          <div className="my-tv__tab-block">
            {getTabContent(activeTransformerTabIndex)}
          </div>
        </>
      )}
    </StyledTranformerMode>
  );
};

export default observer(TranformerMode);
