import { Instance, types } from 'mobx-state-tree';
import { INITIAL_RESULT } from '~/constants/common';

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

const AnnualProductRenewalStore = types
  .model('', {
    isAnnualProductRenewal: (types.boolean, false),
    result: ResultModel,
    seriesName: (types.string, ''),
  })
  .actions((self) => ({
    setIsAnnualProductRenewal: (isAnnualProductRenewal) => {
      self.isAnnualProductRenewal = isAnnualProductRenewal;
    },
    setResult: (result) => {
      self.result = result;
    },
    setSeriesName: (seriesName) => {
      self.seriesName = seriesName;
    },
    onCloseSP: () => {
      self.isAnnualProductRenewal = false;
      self.result = INITIAL_RESULT;
      self.seriesName = '';
    },
  }));

export default AnnualProductRenewalStore;
export type IAnnualProductRenewalStore = Instance<
  typeof AnnualProductRenewalStore
>;
