/** libraries */
import { FC } from 'react';
import {
  Button,
  ButtonStyleTypes,
  H2,
  H3,
  Icon,
  Icons,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** constants */
import {
  ADD_CONTACT_SP_STATE,
  CONTACT_TYPES,
  DEFAULT_ERROR,
} from '../../../../Templates/Pab2c/Settings/constants';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import AddingContactFooter from './AddingContactFooter';
import AddingContact from './AddingContact';
import ConfirmationCode from './ConfirmationCode';
/** styles */
import { StyledAddingContact } from './styles';
/** stores */
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';

const AddingContactSidePage: FC = () => {
  const {
    addingContactStore: {
      isShowAddingContact,
      addContactState: state,
      contactType,
      confirmContact,
      email,
      resetState,
    },
  } = useContactsAndNotificationsStore();

  // Вычисление ширины экрана
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <SidePage
      show={isShowAddingContact}
      headerText={
        (state === ADD_CONTACT_SP_STATE.CONTACT && 'Добавить контакт') ||
        (state === ADD_CONTACT_SP_STATE.CODE && 'Подтвердить контакт') ||
        undefined
      }
      onCloseClick={resetState}
      footerContainer={<AddingContactFooter />}
      isOnlyMobileFooter
      removeScrollBar
    >
      {state === ADD_CONTACT_SP_STATE.CONTACT && <AddingContact />}
      {state === ADD_CONTACT_SP_STATE.CODE && <ConfirmationCode />}
      {state === ADD_CONTACT_SP_STATE.RESULT && (
        <StyledAddingContact>
          <div className="result__icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {contactType?.code === CONTACT_TYPES.PHONE && (
            <>
              {isDesktop1100 ? (
                <H2>Номер телефона подтверждён</H2>
              ) : (
                <H3>Номер телефона подтверждён</H3>
              )}
            </>
          )}
          {contactType?.code === CONTACT_TYPES.EMAIL && (
            <>
              {confirmContact ? (
                <div className="result__yellow-block">
                  <Text lineHeight="24px">
                    На электронную почту{' '}
                    <Text lineHeight="24px" fontWeightBold>
                      {confirmContact.value}
                    </Text>{' '}
                    отправлено письмо с ссылкой-подтверждением.
                  </Text>
                  <Text lineHeight="24px">
                    Если вы не нашли письмо во входящих, поищите в папке Спам.
                  </Text>
                </div>
              ) : (
                <div className="result__yellow-block">
                  <Text lineHeight="24px">
                    Электронная почта{' '}
                    <Text lineHeight="24px" fontWeightBold>
                      {email}
                    </Text>{' '}
                    добавлена. На неё отправлено письмо с
                    ссылкой-подтверждением.
                  </Text>
                  <Text lineHeight="24px">
                    Если вы не нашли письмо во входящих, поищите в папке Спам.
                  </Text>
                </div>
              )}
            </>
          )}
          <Button styleType={ButtonStyleTypes.SECONDARY} onClick={resetState}>
            Вернуться к просмотру контактов
          </Button>
        </StyledAddingContact>
      )}
      {state === ADD_CONTACT_SP_STATE.ERROR && (
        <StyledAddingContact>
          <div className="result__icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </StyledAddingContact>
      )}
    </SidePage>
  );
};

export default observer(AddingContactSidePage);
