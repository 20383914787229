import { defaultModelState } from '~/stores/models/createApiPathModel';
import ChatleStateModel, { IChatleStateStore } from './ChatleModel';
import { createContext, useContext } from 'react';

const store = ChatleStateModel.create({
  requestsState: {
    setReservedChatles: defaultModelState,
  },
  serverError: '',
  chatleAmount: '',
  maxChatleAmount: null,
  errorChatleAmount: false,
  isChatleLoading: false,
  isChatleCompleted: false,
  reserveChatleData: null,
  isChatleVisible: false,
});

const ChatleStateStoreContext = createContext<IChatleStateStore>(store);

const useChatleStateStore = () => {
  return useContext(ChatleStateStoreContext);
};

export default useChatleStateStore;
