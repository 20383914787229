export const DevicePlural = {
  router: ['маршрутизатор', 'маршрутизатора', 'маршрутизаторов'],
  stb: ['телеприставку', 'телеприставки', 'телеприставок'],
  phone: ['SIP-телефон', 'SIP-телефона', 'SIP-телефонов'],
  handset: ['гарнитуру', 'гарнитуры', 'гарнитур'],
  camera: ['камеру', 'камеры', 'камер'],
  /* eslint-disable camelcase */
  remote_control: ['пульт', 'пульты', 'пультов'],
};

export const SeriesCode = {
  LIGHT_14: 'light.14',
  HIT_22: 'hit.22.2023',
  HIT_SUPER_2_0: 'hit.super.2.0',
  ONLINE_SUPER_2_0: 'online.super.2.0',
  HIT_SUPER: 'hit.super',
  ONLINE_SUPER: 'online.super',
  SPECIAL: '100x100',
};
