/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo, DevicePriceInfo } from '../Legal.types';
import { DeviceCode } from '../../Summary/Summary.types';

/** интерфейс компонента-шаблона RouterRentText */
interface RouterRentTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона RouterRentText
 * @param {RouterRentTextProps} content
 * @returns {React.FC}
 */
const RouterRentTex: React.FC<RouterRentTextProps> = ({
  content,
}: RouterRentTextProps) => {
  const { devicePriceInfos } = content;

  const routerDeviceInfo: DevicePriceInfo = devicePriceInfos.find(
    (device) => device.deviceCode === DeviceCode.ROUTER,
  );

  const isCanRent = routerDeviceInfo && !routerDeviceInfo.isOwnership;

  const getRentInfoWithOwnership = devicePriceInfos.find(
    (device) => device.deviceCode === DeviceCode.ROUTER && device.isOwnership,
  );

  const getRentInfo = devicePriceInfos.find(
    (device) => device.deviceCode === DeviceCode.ROUTER && !device.isOwnership,
  );

  return (
    <>
      {isCanRent && (
        <>
          {getRentInfoWithOwnership.isAvailable && getRentInfo.isAvailable && (
            <span>
              Возможно предоставление Маршрутизатора (роутера) в пользование с
              последующей передачей в собственность или предоставление
              Маршрутизатора в пользование.
            </span>
          )}

          {getRentInfo.isAvailable && (
            <p>
              {!getRentInfoWithOwnership.isAvailable && (
                <>
                  Возможно предоставление Маршрутизатора (роутера) в
                  пользование.
                </>
              )}
              {routerDeviceInfo.oneTimeCharge > 0 && (
                <>
                  Условие получения Маршрутизатора (роутера) в пользование —
                  единовременный платёж при передаче в пользование в размере
                  {routerDeviceInfo.oneTimeCharge} руб. Единовременный платёж
                  списывается с Лицевого Счёта Клиента.{' '}
                </>
              )}
              Срок пользования Маршрутизатором — до момента расторжения
              Договора.
            </p>
          )}

          {getRentInfoWithOwnership.isAvailable && (
            <p>
              {!getRentInfo.isAvailable && (
                <span>
                  Возможно предоставление Маршрутизатора (роутера) в пользование
                  с последующей передачей в собственность.
                  <br />
                </span>
              )}
              {routerDeviceInfo.oneTimeCharge > 0 && (
                <>
                  Условие получения Маршрутизатора (роутера) в пользование с
                  последующей передачей в собственность — единовременный платёж
                  при передаче в пользование в размере{' '}
                  {routerDeviceInfo.oneTimeCharge} руб. Единовременный платёж
                  списывается с Лицевого Счёта Клиента.{' '}
                </>
              )}
              Срок пользования Маршрутизатором — до момента расторжения Договора
              или до перехода Маршрутизатора в собственность. Переход в
              собственность происходит через 3 года после начала оказания
              услуги.
            </p>
          )}
        </>
      )}
    </>
  );
};

export default RouterRentTex;
