/** библиотеки */
import { useEffect, useState, FC } from 'react';

/** компоненты */
import Announcement from '~/components/Blocks/Templates/Announcements/Announcement/index';

/** типы */
import { SingleAnnouncementContentProps } from './SingleAnnouncement.types';

/** стили */
import useWindowSize from '~/utils/useWindowSize';

/** константы */
import {
  blockWidth280,
  blockWidth340,
  blockWidth400,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

/**
 * Блок "Единичный анонс"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=491371345
 * @param content
 */
const SingleAnnouncement: FC<SingleAnnouncementContentProps> = ({
  content,
}: SingleAnnouncementContentProps) => {
  const { announcement } = content.fields;

  /** Ширина экрана */
  const [width] = useWindowSize();

  /** Ширина блока с анонсом */
  const [blockWidth, setBlockWidth] = useState<number>(blockWidth400);

  // Вычислит ширину блока с анонсом
  useEffect(() => {
    switch (true) {
      case width >= desktop1280:
        if (blockWidth !== blockWidth400) setBlockWidth(blockWidth400);
        break;
      case width >= desktop1100 && width <= desktop1280:
        if (blockWidth !== blockWidth340) setBlockWidth(blockWidth340);
        break;
      case width >= desktop940 && width <= desktop1100:
        if (blockWidth !== blockWidth280) setBlockWidth(blockWidth280);
        break;
      default:
        setBlockWidth(null);
    }
  }, [width]);

  return (
    <>
      {announcement && (
        <Announcement
          publishedDt={announcement.publishedDt}
          title={announcement.title}
          subTitle={announcement.subTitle}
          imgLink={announcement.imgLink}
          tag={announcement.tag}
          tagClick={(tag, tagUrl, event) => event.stopPropagation()}
          blockWidth={blockWidth}
          fullSlug={announcement.fullSlug}
          tagUrl={announcement.tagUrl}
        />
      )}
    </>
  );
};

export default SingleAnnouncement;
