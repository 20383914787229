/** libraries */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** components */
import { defaultTheme, Text, Link } from 'cordis-core-ui-planeta';
import { WarningRoot } from '~/components/Blocks/Templates/PaymentResult/Components/DeclinedSBPPayment/Components/style';
/** stores */
import useListOfSbpBanksStore from '~/components/Blocks/Shared/ListOfSbpBanks/store/useListOfSbpBanksStore';

const Warning: FC = () => {
  const { setIsListOfSbpBanksOpen } = useListOfSbpBanksStore();

  return (
    <WarningRoot>
      <Text lineHeight="24px">
        <Text lineHeight="24px" fontWeightBold>
          Нельзя привязать счёт&nbsp;СБП
        </Text>{' '}
        в&nbsp;банковских приложениях некоторых банков.
        <br />
        Ознакомьтесь со{'\u00A0'}
        <Link
          color={defaultTheme.colors.planeta}
          onClick={() => setIsListOfSbpBanksOpen(true)}
        >
          списком банков
        </Link>
        , поддерживающих подписки СБП.
      </Text>
    </WarningRoot>
  );
};

export default observer(Warning);
