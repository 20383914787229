/* eslint-disable camelcase */
/** libraries */
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  Icon,
  Icons,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import Image from 'next/image';
import { format, parseISO, isBefore } from 'date-fns';
import { ru } from 'date-fns/locale';
import Highlighter from 'react-highlight-words';
import { observer } from 'mobx-react';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { PACKAGE, STORE_TYPE, TEXT } from '../../../constants';

/** styles */
import { ChannelCardStyled } from './styles';

/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { getDate } from '../../../utils';

/** interfaces */
import { ChannelCardFieldsProps, PictureClarityFormat } from './interfaces';
import {
  AvailabilityStates,
  ChannelPackage,
  ConnectionStates,
} from '../../../interfaces';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';

/**
 * Блок «ЛК. Моё телевидение»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=690527702
 */
const ChannelCard: FC<ChannelCardFieldsProps> = ({
  channel,
  onClick,
  isConnection,
  disabled,
  highlight,
  allChannelsIncludedInProduct,
  isTransformer,
}: ChannelCardFieldsProps) => {
  const {
    id,
    weburgId,
    name,
    imgLink,
    channelDefinition,
    adultContent,
    singleChannelPacks,
    availablePackets,
    isAccessible,
    isFree,
    unbindDt,
    banTrimDate,
    timeshiftDepth,
  } = channel;

  const {
    tvStore: {
      connectedChannelsInPackages,
      connectedChannelsOutsidePackages,
      connectedTVPackages,
      transformerInfo,
      tvChannelPackages,
      idsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
    },
  } = useRootStore();

  const { filteredPackages: packagesInTelevision, storeType } = useMyTvStore();

  const isTelevision = storeType === STORE_TYPE.TELEVISION;

  const packages: ChannelPackage[] = useMemo(
    () =>
      (isTelevision
        ? packagesInTelevision
        : tvChannelPackages) as ChannelPackage[],
    [isTelevision, packagesInTelevision, tvChannelPackages],
  );

  /** Канал можно подключить отдельно от пакета  */
  const isSeparately = useMemo(
    () =>
      singleChannelPacks?.length > 0 ||
      (isTelevision &&
        availablePackets?.find(
          (item) =>
            item.name === channel.name &&
            item?.price !== null &&
            item?.price !== 0,
        )),
    [isTelevision],
  );

  /** Цена канала отдельно от пакета */
  const singlePrice = useMemo(() => {
    if (isTelevision)
      return availablePackets?.find(
        (item) =>
          item.name === channel.name &&
          item?.price !== null &&
          item?.price !== 0,
      )?.price;
    if (singleChannelPacks?.length) return singleChannelPacks[0]?.price;
    return null;
  }, [isTelevision]);

  /** Доступно управление просмотром */
  const isViewControlAvailable = useMemo(() => !!timeshiftDepth, []);

  /** Пакеты, в которых подключён канал */
  const [connectedPackages, setConnectedPackages] = useState<ChannelPackage[]>(
    [],
  );

  /** Добавленные в трансформер пакеты, в составе которых есть этот канал */
  const [addedToTransformerPackages, setAddedToTransformerPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Убранные в трансформер пакеты, в составе которых есть этот канал */
  const [removedPackages, setRemovedPackages] = useState<ChannelPackage[]>([]);

  /** Дата отключения канала */
  const [shutdownDate, setShutdownDate] = useState<string>('');

  /** Тип подключения */
  const connection = useMemo(() => {
    if (isConnection) return ConnectionStates.NotConnected;
    /** Добавленные в трансформер пакеты (без подключённых) */
    const addedPackages = idsAddedPacketsToTransformer?.length
      ? packages.filter((item) =>
          idsAddedPacketsToTransformer.includes(item.id),
        )
      : [];
    /** Канал добавлен в трансформер */
    const channelAddedToTransformer = idsAddedChannelsToTransformer.find(
      (item) =>
        singleChannelPacks?.length ? item === singleChannelPacks[0]?.id : null,
    );
    if (isTelevision) {
      /** Добавлен в трансформер в пакетах */
      if (isTransformer && addedPackages.length) {
        /** Добавленные в трансформер пакеты, в которых есть этот канал */
        const packets = addedPackages.filter((item) =>
          item.channelPackChannels.find((canal) => canal.id === id),
        );
        if (packets.length) {
          setAddedToTransformerPackages(
            (packets as unknown) as ChannelPackage[],
          );
          return ConnectionStates.AddedToTransformerInPackage;
        }
      }

      /** Добавлен в трансформер */
      if (isTransformer && channelAddedToTransformer) {
        return ConnectionStates.AddedToTransformer;
      }

      return allChannelsIncludedInProduct.find(
        (item) => item.id === channel.id,
      ) && !isTransformer
        ? ConnectionStates.ConnectedInTheProduct
        : ConnectionStates.NotConnected;
    }
    /** Подключён отдельно от пакета */
    const isConnectedSeparately = connectedChannelsOutsidePackages.includes(
      weburgId,
    );
    /** Подключён в продукте */
    if (isFree && isConnectedSeparately)
      return ConnectionStates.ConnectedInTheProduct;

    /** Канал подключён в пакете */
    const connectedChannelId = connectedChannelsInPackages.find(
      (item) => item === weburgId,
    );
    /** Пакеты, в которых подключён канал */
    const connectedPackets = connectedChannelId
      ? connectedTVPackages.filter((item) => {
          return item.channelIds.includes(connectedChannelId);
        })
      : [];

    /** Подключённые пакеты в трансформере (не убранные и не с отказом) */
    const connectedPacketsInTransformer = connectedPackets.filter((item) => {
      return (
        idsAddedPacketsToTransformer?.includes(item.channelPackId) &&
        !item.unbindDt
      );
    });
    /** Все подключённые пакеты с отказом */
    const isAllRefusal = connectedPackets.length
      ? connectedPackets.every((item) => item.unbindDt)
      : false;

    /** Подключён в пакете */
    if (
      ((connectedPacketsInTransformer.length && transformerInfo) ||
        (!transformerInfo && connectedPackets.length)) &&
      !isAllRefusal
    ) {
      const packets = transformerInfo
        ? connectedPacketsInTransformer
        : connectedPackets.filter((item) => !item.unbindDt);
      if (packets.find((item) => item.isFree))
        return ConnectionStates.ConnectedInTheProduct;
      setConnectedPackages((packets as unknown) as ChannelPackage[]);
      return ConnectionStates.ConnectedInAPackage;
    }

    /** Добавлен в трансформер в пакетах */
    if (transformerInfo && addedPackages.length) {
      /** Добавленные в трансформер пакеты, в которых есть этот канал */
      const packets = addedPackages.filter((item) =>
        item.channelPackChannels.find((canal) =>
          canal?.weburgId ? canal.weburgId === weburgId : canal?.id === id,
        ),
      );
      if (packets.length) {
        setAddedToTransformerPackages((packets as unknown) as ChannelPackage[]);
        return ConnectionStates.AddedToTransformerInPackage;
      }
    }

    /** Добавлен в трансформер */
    if (
      transformerInfo &&
      !isConnectedSeparately &&
      channelAddedToTransformer
    ) {
      return ConnectionStates.AddedToTransformer;
    }

    /** Из трансформера убраны пакеты, в которых находился канал */
    if (
      transformerInfo &&
      connectedPackets.length &&
      !connectedPackets.every((item) =>
        idsAddedPacketsToTransformer.includes(item.channelPackId),
      )
    ) {
      setRemovedPackages(
        (connectedPackets.filter(
          (item) => !item.unbindDt,
        ) as unknown) as ChannelPackage[],
      );
      return ConnectionStates.RemovedFromTheTransformerInPackage;
    }

    /** Все пакеты с отказом */
    if (isAllRefusal) {
      setConnectedPackages((connectedPackets as unknown) as ChannelPackage[]);
      return ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced;
    }

    /** Убран из трансформера */
    if (
      transformerInfo &&
      isConnectedSeparately &&
      !channelAddedToTransformer
    ) {
      return ConnectionStates.RemovedFromTheTransformer;
    }

    /** Дата отключения канала */
    if (unbindDt) {
      setShutdownDate(
        transformerInfo
          ? `${getDate(unbindDt)} года`
          : `${getDate(unbindDt, 'dd.MM.yyyy')} года`,
      );
      return ConnectionStates.CancellationIssuedSeparatelyFromThePackage;
    }

    if (isConnectedSeparately) {
      return ConnectionStates.ConnectedSeparatelyFromThePackage;
    }

    return ConnectionStates.NotConnected;
  }, [
    transformerInfo,
    connectedChannelsInPackages,
    connectedChannelsOutsidePackages,
    idsAddedChannelsToTransformer,
    idsAddedPacketsToTransformer,
    connectedTVPackages,
    packages,
  ]);

  // Вычисление ширины экрана
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Текст статуса подключения */
  const connectionStatusText = useMemo((): string | boolean => {
    switch (connection) {
      case ConnectionStates.AddedToTransformer:
        return TEXT.ADDED;
      case ConnectionStates.RemovedFromTheTransformer:
        return TEXT.REMOVED;
      case ConnectionStates.AddedToTransformerInPackage:
        return addedToTransformerPackages.length > 1
          ? TEXT.ADDED_IN_PACKAGES
          : TEXT.ADDED_IN_PACKAGE;
      case ConnectionStates.RemovedFromTheTransformerInPackage:
        return removedPackages.length > 1
          ? TEXT.REMOVED_PACKAGES
          : TEXT.REMOVED_PACKAGE;
      case ConnectionStates.ConnectedSeparatelyFromThePackage:
        return TEXT.CONNECTED;
      case ConnectionStates.ConnectedInAPackage:
        return connectedPackages?.length > 1
          ? TEXT.CONNECTED_IN_PACKAGES
          : TEXT.CONNECTED_IN_PACKAGE;
      case ConnectionStates.ConnectedInTheProduct:
        return TEXT.INCLUDED_IN_PRODUCT;
      case ConnectionStates.CancellationIssuedSeparatelyFromThePackage:
        return TEXT.CHANNEL_ABANDONMENT;
      case ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced:
        return connectedPackages?.length > 1
          ? TEXT.REJECTION_OF_PACKAGES
          : TEXT.REJECTION_OF_PACKAGE;
      default:
        return false;
    }
  }, [
    connection,
    connectedPackages,
    addedToTransformerPackages,
    removedPackages,
  ]);

  /** Варианты подключения */
  const connectionOptions = useMemo(() => {
    return availablePackets?.length && packages?.length
      ? availablePackets
          .map((item) => {
            return packages.find((pack) => pack.channelPackName === item.name);
          })
          .filter((item) => {
            return (
              item !== undefined &&
              !connectedPackages?.find(
                (pack) => pack.channelPackName === item.channelPackName,
              )
            );
          })
      : [];
  }, [availablePackets, packages, connectedPackages]);

  /** Доступность */
  const availability = useMemo(() => {
    /** Не подключён и нет возможности подключить отдельный канал */
    if (!isAccessible && !isSeparately) {
      if (connectionOptions?.length > 1)
        return AvailabilityStates.OnlyAvailableInPackages;
      if (connectionOptions?.length === 1)
        return AvailabilityStates.OnlyAvailableInOnePack;
    }

    return AvailabilityStates.AvailableSeparatelyFromThePackage;
  }, [connectionOptions]);

  /** JSX доступности */
  const availabilityText = () => {
    switch (availability) {
      case AvailabilityStates.AvailableSeparatelyFromThePackage:
        return false;
      case AvailabilityStates.OnlyAvailableInOnePack:
        return (
          <Text className="channel-card__availability">
            Доступен{' '}
            <Snoska color={defaultTheme.colors.planeta}>в пакете</Snoska>
          </Text>
        );
      case AvailabilityStates.OnlyAvailableInPackages:
        return (
          <Text className="channel-card__availability">
            Доступен в {pluralizeAll(connectionOptions?.length, PACKAGE)}
          </Text>
        );
      default:
        return false;
    }
  };

  /** Оформлен отказ */
  const isRejectionOfThePackage = useMemo(
    () =>
      connection ===
        ConnectionStates.CancellationIssuedSeparatelyFromThePackage ||
      connection ===
        ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
    [connection],
  );

  /** Отключение доступно сразу */
  const isRejectionAvailable = useMemo(
    () => isBefore(new Date(parseISO(banTrimDate)), new Date()),
    [banTrimDate],
  );

  /** Дата, после которой возможен отказ от канала */
  const refusalDate = (connectedPackageDate?: string) => {
    if (!banTrimDate && !connectedPackageDate) return null;
    if (transformerInfo) {
      return `${getDate(connectedPackageDate ?? banTrimDate)} года`;
    }

    if (!isRejectionAvailable)
      return format(parseISO(banTrimDate), 'dd.MM.yyyy', {
        locale: ru,
      });
    return '';
  };

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (
        [
          ConnectionStates.AddedToTransformer,
          ConnectionStates.AddedToTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.planeta;
      if (
        [
          ConnectionStates.RemovedFromTheTransformer,
          ConnectionStates.RemovedFromTheTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.black;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (
        [
          ConnectionStates.AddedToTransformer,
          ConnectionStates.AddedToTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.pink;
      if (
        [
          ConnectionStates.RemovedFromTheTransformer,
          ConnectionStates.RemovedFromTheTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.disable;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isMinDesktop940) return undefined;

      /** Подключён в пакетах */
      if (connection === ConnectionStates.ConnectedInAPackage) {
        if (connectedPackages?.length === 1) {
          return `Подключён в пакете ${connectedPackages[0]?.channelPackName}`;
        }
        /** Много пакетов */
        if (connectedPackages?.length > 1) {
          return `Подключён в пакетах ${connectedPackages
            .map((item) => item.channelPackName)
            .join(', ')}`;
        }
      }

      /** Добавлен в пакетах */
      if (connection === ConnectionStates.AddedToTransformerInPackage) {
        if (addedToTransformerPackages?.length === 1) {
          return `Добавлен в пакете ${addedToTransformerPackages[0]?.channelPackName}. Будет подключён при применении трансформера`;
        }
        /** Много пакетов */
        if (addedToTransformerPackages?.length > 1) {
          return `Добавлен в пакетах ${addedToTransformerPackages
            .map((item) => item.channelPackName)
            .join(', ')}. Будет подключён при применении трансформера`;
        }
      }

      /** Отказ от пакетов */
      if (
        connection ===
        ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced
      ) {
        const stringDate = connectedPackages[0]?.unbindDt
          ? `${getDate(connectedPackages[0]?.unbindDt)} года`
          : '';
        if (connectedPackages?.length === 1) {
          return `Оформлен отказ от канала в составе пакета ${connectedPackages[0]?.channelPackName} с ${stringDate}`;
        }
        /** Много пакетов */
        if (connectedPackages?.length > 1) {
          /** Дата отключения одинаковая для все пакетов */
          const isSameDate = connectedPackages.reduce(
            (acc, item, index, arr) => {
              if (!acc) return false;
              return index !== 0
                ? arr[index - 1]?.unbindDt === item?.unbindDt
                : true;
            },
            true,
          );

          return isSameDate
            ? `Оформлен отказ от канала в составе пакетов ${connectedPackages
                .map((item) => item.channelPackName)
                .join(', ')} с ${stringDate}`
            : `Оформлен отказ от канала в составе пакетов ${connectedPackages
                .map((item) => {
                  const unbindDate = item.unbindDt
                    ? `${getDate(item.unbindDt)} года`
                    : '';
                  return `${item.channelPackName} c ${unbindDate}`;
                })
                .join(', ')}`;
        }
      }

      /** Отказ отдельно от пакета */
      if (
        connection ===
        ConnectionStates.CancellationIssuedSeparatelyFromThePackage
      )
        return `Оформлен отказ с ${shutdownDate}`;

      /** Добавлен в трансформер */
      if (
        [
          ConnectionStates.AddedToTransformerInPackage,
          ConnectionStates.AddedToTransformer,
        ].includes(connection)
      ) {
        return 'Будет подключён при применении трансформера';
      }

      /** Удалён из трансформера */
      if (connection === ConnectionStates.RemovedFromTheTransformer) {
        return isRejectionAvailable
          ? 'Будет отключён при применении трансформера'
          : `Будет оформлен отказ с ${refusalDate()} при применении трансформера`;
      }

      /** Удалён из трансформера в пакете */
      if (connection === ConnectionStates.RemovedFromTheTransformerInPackage) {
        const stringDate = removedPackages[0]?.banTrimDate
          ? `${getDate(removedPackages[0]?.banTrimDate)} года`
          : '';
        if (transformerInfo) {
          if (removedPackages.length === 1) {
            return `Будет оформлен отказ от канала в составе пакета ${
              removedPackages[0]?.channelPackName
            } ${
              !isRejectionAvailable && `с ${stringDate}`
            } при применении трансформера`;
          }
          if (removedPackages.length > 1) {
            /** Дата отключения одинаковая для все пакетов */
            const isSameDate = removedPackages.reduce(
              (acc, item, index, arr) => {
                if (!acc) return false;
                return index !== 0
                  ? arr[index - 1]?.banTrimDate === item?.banTrimDate
                  : true;
              },
              true,
            );

            return isSameDate
              ? `Будет оформлен отказ от канала в составе пакетов ${removedPackages
                  .map((item) => item.channelPackName)
                  .join(', ')} с ${stringDate} при применении трансформера`
              : `Будет оформлен отказ от канала в составе пакетов ${removedPackages
                  .map((item) => {
                    const banDate = item.banTrimDate
                      ? `${getDate(item.banTrimDate)} года`
                      : '';
                    return `${item.channelPackName} ${
                      !isRejectionAvailable && `${banDate}`
                    }`;
                  })
                  .join(', ')} при применении трансформера`;
          }
        }
      }
      return undefined;
    };

    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          !isRejectionOfThePackage &&
          ![
            ConnectionStates.AddedToTransformer,
            ConnectionStates.RemovedFromTheTransformer,
            ConnectionStates.AddedToTransformerInPackage,
            ConnectionStates.RemovedFromTheTransformerInPackage,
          ].includes(connection) &&
          colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Информация для мобильной версии карточки */
  const infoForMobile = (): JSX.Element => {
    switch (true) {
      case connection === ConnectionStates.AddedToTransformer:
        return customTag(TEXT.ADDED);
      case connection === ConnectionStates.RemovedFromTheTransformer:
        return customTag(TEXT.REMOVED);
      case connection === ConnectionStates.AddedToTransformerInPackage:
        return customTag(TEXT.IN_PACKAGE);
      case connection === ConnectionStates.RemovedFromTheTransformerInPackage:
        return customTag(TEXT.REMOVED_PACKAGE);
      case connection === ConnectionStates.ConnectedSeparatelyFromThePackage:
        return customTag(TEXT.CONNECTED);
      case connection === ConnectionStates.ConnectedInAPackage:
        return customTag(TEXT.IN_PACKAGE);
      case connection === ConnectionStates.ConnectedInTheProduct:
        return customTag(TEXT.IN_PRODUCT);
      case connection ===
        ConnectionStates.CancellationIssuedSeparatelyFromThePackage:
        return customTag(TEXT.CHANNEL_ABANDONMENT);
      case connection ===
        ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced:
        return customTag(TEXT.CANCELLATION);
      case availability === AvailabilityStates.OnlyAvailableInOnePack:
        return (
          <Text
            className="channel-card__availability"
            color={defaultTheme.colors.shadow}
          >
            В пакете
          </Text>
        );
      case availability === AvailabilityStates.OnlyAvailableInPackages:
        return (
          <Text
            className="channel-card__availability"
            color={defaultTheme.colors.shadow}
          >
            В {pluralizeAll(connectionOptions?.length, PACKAGE)}
          </Text>
        );
      case connection === ConnectionStates.NotConnected &&
        availability === AvailabilityStates.AvailableSeparatelyFromThePackage:
        return isSeparately && singlePrice !== null ? (
          <Snoska
            className="channel-card__info-block__price"
            color={defaultTheme.colors.black}
          >
            {formatNumber(singlePrice)} ₽ в день
          </Snoska>
        ) : (
          <></>
        );
      default:
        return <></>;
    }
  };

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** События клика по карточке */
  const onChannelCardClick = () => onClick(channel);

  const refComponent: RefObject<HTMLSpanElement> | null = useRef(null);

  /** Высота блока с названием */
  const [nameLineHeight, setNameLineHeight] = useState<number>(null);
  useEffect(() => {
    if (isMinDesktop940) return;

    const { height } = refComponent.current.getBoundingClientRect();
    setNameLineHeight(height);
  }, [refComponent]);

  return (
    <ChannelCardStyled
      onClick={onClick ? onChannelCardClick : null}
      nameLineHeight={nameLineHeight}
      disabled={disabled}
      id={channel.name}
    >
      <div className="channel-card__block">
        <div className="channel-card__header-block">
          <div className="channel-card__header">
            {isMinDesktop940 && (
              <Snoska
                className="channel-card__definition"
                color={defaultTheme.colors.shadow}
              >
                {isConnection ? 'подключить вне пакета' : 'телеканал'}
              </Snoska>
            )}
            {highlight ? (
              <Text
                forwardedRef={refComponent}
                className="channel-card__header__name-highlighted"
              >
                <Highlighter
                  searchWords={[highlight]}
                  autoEscape
                  textToHighlight={name}
                />
              </Text>
            ) : (
              <Text
                forwardedRef={refComponent}
                className="channel-card__header__name"
              >
                {name}
              </Text>
            )}
            {!isMinDesktop940 && infoForMobile()}
          </div>
          <div className="channel-card__logo">
            {imgLink && (nameLineHeight || isMinDesktop940) && (
              <Image
                loader={imageLoader}
                src={imgLink}
                alt={name}
                width={isMinDesktop940 ? 96 : 77}
                height={isMinDesktop940 ? 46 : 38}
                quality={1}
                loading="lazy"
              />
            )}
          </div>
        </div>
      </div>
      {isMinDesktop940 && (
        <div className="channel-card__block">
          <div className="channel-card__info-block">
            {!isConnection && connection !== ConnectionStates.NotConnected && (
              <div className="channel-card__info-block__tag">
                {customTag(connectionStatusText)}
              </div>
            )}
            {[
              ConnectionStates.RemovedFromTheTransformer,
              ConnectionStates.RemovedFromTheTransformerInPackage,
            ].includes(connection) &&
              !isRejectionAvailable && <Icons.WaitIcon />}
            {isSeparately && singlePrice !== null && (
              <Text
                className="channel-card__info-block__price"
                lineHeight="16px"
              >
                {formatNumber(singlePrice)} ₽ в&nbsp;день
              </Text>
            )}
          </div>
          {connection === ConnectionStates.NotConnected && availabilityText()}
          {isConnection && disabled && (
            <Snoska
              className="channel-card__disabled-text"
              color={defaultTheme.colors.disable}
            >
              после отключения
            </Snoska>
          )}
          {!isConnection && (
            <div className="channel-card__icons">
              {channelDefinition === PictureClarityFormat.SD && (
                <Icon icon={<Icons.SDIcon />} />
              )}
              {channelDefinition === PictureClarityFormat.HD && (
                <Icon icon={<Icons.HDIcon />} />
              )}
              {channelDefinition === PictureClarityFormat.FourK && (
                <Icon icon={<Icons.FourKIcon />} />
              )}
              {isViewControlAvailable && (
                <Icon icon={<Icons.ViewControlIcon />} />
              )}
              {adultContent && <Icon icon={<Icons.ForAdultsIcon />} />}
            </div>
          )}
        </div>
      )}
    </ChannelCardStyled>
  );
};

export default observer(ChannelCard);
