import styled from '@emotion/styled';

export const StyledChangeConnectionTypeSP = styled.div`
  .sidepage__content {
    margin-top: 42px !important;
  }

  svg {
    margin-bottom: 24px;
  }
`;
