/** библиотеки */
import * as React from 'react';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { Text, Link as CordisLink } from 'cordis-core-ui-planeta';

/** компоненты библиотеки */

/** типы */
import { ProductPageProps } from './ProductPage.types';
import { BlocksRow } from '~/components/Feed/BlocksRow';

import { useRootStore } from '~/stores/RootStore';

/**
 * Блок Страница продукта
 * @param content
 */
const ProductPage: React.FC<ProductPageProps> = ({
  content,
}: ProductPageProps) => {
  const {
    cityStore: { city },
  } = useRootStore();
  const { blocks, fields } = content;

  return fields.availableCities.includes(city.id) ? (
    <BlocksRow noPadding blocks={blocks} />
  ) : (
    <div>
      <Text>
        К сожалению, выбранный продукт не доступен в городе {city.name},
        попробуйте&nbsp;
        <Link href={fields.notFoundProductLink}>
          <>
            <CordisLink href={fields.notFoundProductLink}>
              {fields.notFoundProductName}
            </CordisLink>
          </>
        </Link>
      </Text>
    </div>
  );
};

export default observer(ProductPage);
