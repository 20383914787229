/** libraries */
import { FC } from 'react';
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import ChangePassword from './ChangePassword';
import ChangePasswordFooter from './ChangePasswordFooter';
/** stores */
import useSettingsStore from '../../store/useSettingsStore';

/** SidePage изменения пароля */
const ChangePasswordSidePage: FC = () => {
  const {
    changePasswordStore: {
      isShowChangePasswordWizard,
      result,
      resetChangePasswordState,
    },
  } = useSettingsStore();

  return (
    <SidePage
      show={isShowChangePasswordWizard}
      headerText={!result.isResult && 'Изменить пароль'}
      onCloseClick={resetChangePasswordState}
      footerContainer={!result.isResult && <ChangePasswordFooter />}
      isOnlyMobileFooter
      removeScrollBar
    >
      <ChangePassword />
    </SidePage>
  );
};

export default observer(ChangePasswordSidePage);
