/** библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** типы */
import { DeviceListContentProps } from './DeviceList.types';
/** styles */
import { StyledList } from './DeviceList.style';
/** components */
import { DeviceListStoreProvider } from './store/useDeviceListStore';
import DeviceListTabs from './DeviceListTabs';
import Portal from '~/components/Portal/Portal';
import DeviceDetailedCardSP from '../../Shared/DeviceDetailedCard/DeviceDetailedCardSP';
import DeviceRequestErrorSP from '../../Shared/DeviceDetailedCard/DeviceRequestErrorSP';

/**
 * Блок "Минивитрина оборудования"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=525730789
 * @param content
 */
const DeviceList: FC<DeviceListContentProps> = ({ content }) => {
  return (
    <DeviceListStoreProvider
      list={content.fields.content}
      allDevices={content.fields.allDevices}
    >
      <StyledList>
        <DeviceListTabs />
        <Portal>
          <DeviceDetailedCardSP />
          <DeviceRequestErrorSP />
        </Portal>
      </StyledList>
    </DeviceListStoreProvider>
  );
};

export default observer(DeviceList);
