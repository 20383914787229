/** библиотеки */
import styled from '@emotion/styled';

/** стилизованный компонент 404 страницы */
export const NotFoundStyled = styled.div`
  h1 {
    margin-top: 33px;
  }
  h3 {
    margin-top: 30px;
  }
  .suggestions {
    margin-top: 16px;
  }
`;
