import styled from '@emotion/styled';

export const StyledSoftlineCard = styled.li<{
  itemWidth: number;
  isHide?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ itemWidth }) => (itemWidth ? `${itemWidth}px` : '200px')};
  cursor: pointer;
  ${({ isHide }) => (isHide ? 'visibility: hidden;' : '')};
  .logo {
    height: 24px;
    width: 96px;
    margin-bottom: 14px;
  }
  .name {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
  }
  .tags {
    margin-bottom: 8px;
  }
  .count {
    margin-bottom: 8px;
  }
`;
