/** libraries */
import styled from '@emotion/styled';

export const StyledCloseToZombie = styled.div`
  .zombie {
    &__text {
      margin-bottom: 8px;
    }
  }

  h3 {
    margin-bottom: 16px;
  }
`;
