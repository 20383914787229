import {
  useState,
  useEffect,
  RefObject,
  SetStateAction,
  Dispatch,
} from 'react';
import { isMobile } from 'react-device-detect';
import { WARRANTY_WIZARD_STATES } from '~/components/Blocks/Templates/Pab2c/Devices/Components/WarrantyPlusWizard/constants';
import { warrantyBind, warrantyUnbind } from '~/api/apiPab2c';
import { DEFAULT_ERROR } from '~/components/Blocks/Templates/Pab2c/Devices/constants';
import { WarrantyStateResult } from '~/components/Blocks/Templates/Pab2c/Devices/Components/WarrantyPlusWizard/types';
import { SidePageHandle } from 'cordis-core-ui-planeta/dist/components/SidePage/SidePage.types';
import { ContractDevice } from './interfaces';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
import useDeviceAfterAuthStore from './stores/useDeviceAfterAuthStore';

export interface WarrantyHook {
  isWarrantyPlusWizardShow: boolean;
  setIsWarrantyPlusWizardShow: Dispatch<SetStateAction<boolean>>;
  isWarrantyPlusWizardLoading: boolean;
  setIsWarrantyPlusWizardLoading: Dispatch<SetStateAction<boolean>>;
  isWarrantyPlusWizardAgree: boolean;
  setIsWarrantyPlusWizardAgree: Dispatch<SetStateAction<boolean>>;
  warrantyWizardState: WARRANTY_WIZARD_STATES;
  setWarrantyWizardState: Dispatch<SetStateAction<WARRANTY_WIZARD_STATES>>;
  warrantyPlusResult: WarrantyStateResult;
  setWarrantyPlusResult: Dispatch<SetStateAction<WarrantyStateResult>>;
  toggleWarranty: () => Promise<void>;
  handleWarrantyAgreementState: () => void;
}

/** Хук работы с Гарантией */
const useWarranty = (
  device: ContractDevice,
  updatePab2cDevices: () => void,
  parentSidePage: RefObject<SidePageHandle>,
): WarrantyHook => {
  const { setOpenSPAfterAuthorizationState } = useMakeAuthStore();
  const { setDeviceId } = useDeviceAfterAuthStore();

  const [
    isWarrantyPlusWizardShow,
    setIsWarrantyPlusWizardShow,
  ] = useState<boolean>(false);
  const [
    isWarrantyPlusWizardLoading,
    setIsWarrantyPlusWizardLoading,
  ] = useState<boolean>(false);
  const [
    isWarrantyPlusWizardAgree,
    setIsWarrantyPlusWizardAgree,
  ] = useState<boolean>(false);
  const [
    warrantyWizardState,
    setWarrantyWizardState,
  ] = useState<WARRANTY_WIZARD_STATES>(WARRANTY_WIZARD_STATES.ON);
  const [
    warrantyPlusResult,
    setWarrantyPlusResult,
  ] = useState<WarrantyStateResult>(null); // Замените на правильный тип

  useEffect(() => {
    setWarrantyWizardState(
      device?.warrantyPlusInfo?.isOrdered
        ? WARRANTY_WIZARD_STATES.OFF
        : WARRANTY_WIZARD_STATES.ON,
    );
  }, []);

  useEffect(() => {
    if (parentSidePage?.current && isMobile)
      parentSidePage.current.scrollToTop();
  }, [isWarrantyPlusWizardShow]);

  const toggleWarranty = async () => {
    setIsWarrantyPlusWizardLoading(true);
    const isOrdered = device?.warrantyPlusInfo?.isOrdered;
    try {
      if (isOrdered) {
        await warrantyUnbind(device.sim);
      } else {
        await warrantyBind(device.sim);
      }
      setWarrantyWizardState(
        isOrdered
          ? WARRANTY_WIZARD_STATES.SUCCESS_OFF
          : WARRANTY_WIZARD_STATES.SUCCESS_ON,
      );
      await updatePab2cDevices();
    } catch (error) {
      if (error.statusCode === 401) {
        setDeviceId(device.id);
        setOpenSPAfterAuthorizationState(
          isOrdered
            ? SIDE_PAGES.WARRANTY_AGREEMENT
            : SIDE_PAGES.WARRANTY_ACTION,
        );
        setIsWarrantyPlusWizardShow(false);
      }
      setWarrantyWizardState(WARRANTY_WIZARD_STATES.ERROR);
      setWarrantyPlusResult({
        status: {
          description: DEFAULT_ERROR,
        },
      });
    }
    setIsWarrantyPlusWizardLoading(false);
  };

  const handleWarrantyAgreementState = () => {
    setIsWarrantyPlusWizardAgree(!isWarrantyPlusWizardAgree);
  };

  return {
    isWarrantyPlusWizardShow,
    setIsWarrantyPlusWizardShow,
    isWarrantyPlusWizardLoading,
    setIsWarrantyPlusWizardLoading,
    isWarrantyPlusWizardAgree,
    setIsWarrantyPlusWizardAgree,
    warrantyWizardState,
    setWarrantyWizardState,
    warrantyPlusResult,
    setWarrantyPlusResult,
    toggleWarranty,
    handleWarrantyAgreementState,
  };
};

export default useWarranty;
