/** libraries */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
import { Loader } from 'cordis-core-ui-planeta';
/** styles */
import { StyledGlobalPreloader } from './style';
/** store */
import { useRootStore } from '~/stores/RootStore';

/**
 * Глобальный прелоадер на ленту при переходе по маршрутам внутри сайта.
 */
const GlobalPreloader = (): JSX.Element => {
  const {
    preloaderStore: { isLoaderVisible, routeChangeStart, routeChangeEnd },
  } = useRootStore();

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeEnd);
    router.events.on('routeChangeError', routeChangeEnd);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeEnd);
      router.events.off('routeChangeError', routeChangeEnd);
    };
  }, []);

  return (
    <StyledGlobalPreloader
      style={{ visibility: isLoaderVisible ? 'visible' : 'hidden' }}
    >
      <Loader className="loader" small />
      <div className="overlay" />
    </StyledGlobalPreloader>
  );
};

export default observer(GlobalPreloader);
