/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** Константы */
import { marginBetweenBlocks, desktop940 } from '~/components/Grid/constants';

export const StyledAnnouncements = styled.div`
  H2 {
    margin: 16px 0 32px 32px;
  }

  .lastAnnouncementHeader {
    margin-top: 30px;
    margin-right: 16px;
  }

  .announcements {
    &__title-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__loading-wrapper {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__loadingStart-wrapper {
      height: 100vh;
      position: fixed;
      right: 0;
      background-color: ${defaultTheme.colors.light};
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
    }
  }

  @media (min-width: ${desktop940}px) {
    .announcements {
      &__loading-wrapper {
        margin-top: 0;
      }
    }
  }
`;

export const StyledAnnouncementsHeader = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => {
    if (isVisible) {
      return `
      margin-bottom: 32px;
      padding: 32px;
      background: ${defaultTheme.colors.white};

      .announcements {
        &__header {
          position: relative;
          max-height: 550px;
          overflow-y: auto;

          H3 {
            margin: 0 16px 0 0;
          }
        }
      }
      `;
    }
    return '';
  }}
`;

export const StyledAnnouncementsTags = styled.div`
  margin: 32px 0;
  text-transform: capitalize;

  .announcements {
    &__tag {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 32px;

    .announcements__tag {
      margin-bottom: 32px;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
`;

export const StyledWrapperAnnouncementsList = styled.div`
  overflow: hidden;
`;

export const StyledAnnouncementsList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: left 0.5s;
  flex-wrap: wrap;

  li {
    margin-right: 0;
    margin-bottom: 24px;
  }

  li:nth-of-type(3n + 3) {
    margin-right: 0;
    margin-bottom: 24px;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    li {
      margin-right: ${marginBetweenBlocks}px;
      margin-bottom: ${marginBetweenBlocks}px;
    }

    li:nth-of-type(3n + 3) {
      margin-right: 0;
      margin-bottom: ${marginBetweenBlocks}px;
    }
  }
`;
