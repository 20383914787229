/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import { desktop940 } from '~/components/Grid/constants';

export const ServiceCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 114px;
  width: 100%;
  border: 1px solid ${defaultTheme.colors.disable};
  padding: 12px 7px 14px 7px;
  background: ${defaultTheme.colors.white};
  cursor: pointer;

  .service-card {
    &__definition {
      margin-bottom: 4px;
    }

    &__name {
      font-size: 11px;
      line-height: 16px;
      font-weight: 500;
      width: min-content;
      margin-bottom: 8px;
      text-align: center;
      & mark {
        background-color: transparent;
        font-weight: bold;
      }
    }

    &__info-block {
      display: flex;
      align-items: baseline;

      &__tag {
        margin-right: 0;

        span {
          text-align: center;
        }
      }

      &__price {
        font-size: 11px;
        letter-spacing: -0.01em;
      }
    }

    &__block {
      display: grid;
      margin: 0 auto;
    }
  }

  div[data-test-tag] {
    letter-spacing: -0.03em;
    cursor: default;
    line-height: 0;
  }

  @media (min-width: ${desktop940}px) {
    height: 140px;
    width: 282px;
    padding: 12px 12px 14px 16px;
    align-items: baseline;

    .service-card {
      &__name {
        line-height: 20px;
        font-size: 16px;
        width: auto;
        margin-bottom: 0;
        text-align: inherit;
      }

      &__info-block {
        &__tag {
          margin-right: 6px;
        }
      }

      &__block {
        margin: 0 0 30px;
      }
    }
  }
`;
