/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Text,
  defaultTheme,
  Input,
  H2,
  H3,
  Icon,
  Icons,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** styles */
import { StyledChangePassword } from './styles';

/** api */
import { generatePassword } from '~/api/apiPab2c';

/** components */
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '../../constants';
import { PASSWORD_MAX_LENGTH } from '~/constants/common';

/** stores */
import useSettingsStore from '../../store/useSettingsStore';
import { useRootStore } from '~/stores/RootStore';
import useCreatePasswordStore from '~/components/Blocks/Shared/CreatePassword/store/useCreatePasswordStore';

const ChangePassword: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  const { setIsShowCreatePasswordWizard } = useCreatePasswordStore();

  const {
    changePasswordStore: {
      setCurrentPassword,
      currentPasswordError,
      setCurrentPasswordError,
      newPassword,
      setNewPassword,
      newPasswordConfirmation,
      setNewPasswordConfirmation,
      result,
    },
  } = useSettingsStore();

  /** Показать правила */
  const [isShowRules, setIsShowRules] = useState<boolean>(false);
  /** Восемь символов */
  const [isEightSymbols, setIsEightSymbols] = useState<boolean>(false);
  /** Одна цифра */
  const [isOneDigit, setIsOneDigit] = useState<boolean>(false);
  /** Одна прописная буква */
  const [isOneCapitalLetter, setIsOneCapitalLetter] = useState<boolean>(false);
  /** Одна строчная буква */
  const [isOneLowercaseLetter, setIsOneLowercaseLetter] = useState<boolean>(
    false,
  );
  /** Значение шкалы */
  const scaleValue = useMemo(() => {
    const trueRuleLength = [
      isEightSymbols,
      isOneDigit,
      isOneCapitalLetter,
      isOneLowercaseLetter,
    ].filter((item) => item).length;
    if (!trueRuleLength) return 25;
    return 25 * trueRuleLength;
  }, [isEightSymbols, isOneDigit, isOneCapitalLetter, isOneLowercaseLetter]);

  /** Цвет шкалы */
  const fillerColor = (value: number) => {
    if (value <= 25) return defaultTheme.colors.warning;
    if (value <= 75) return defaultTheme.colors.graphite;
    return defaultTheme.colors.green;
  };

  /** Проверка на правила */
  const checkRules = (value) => {
    setIsEightSymbols(value.replace(/ /g, '').length >= 8);
    setIsOneDigit(/[0-9]/.test(value));
    setIsOneCapitalLetter(!/^[^A-ZА-ЯЁ]*$/.test(value));
    setIsOneLowercaseLetter(/[a-zа-яё]/.test(value));
  };

  /** Событие смены текущего пароля */
  const onCurrentPasswordChange = (event) => {
    const { value } = event.target;
    setCurrentPassword(value);
  };
  /** Событие фокуса на текущем пароле */
  const onFocusCurrent = () => {
    setCurrentPasswordError('');
  };

  /** Ошибка нового пароля */
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  /** Событие смены нового пароля */
  const onNewPasswordChange = (event) => {
    const { value } = event.target;
    setNewPassword(value);
    checkRules(value);
    const re = /[!@#$%^&*()_\-+=[{\]};:<>|.\/?]/;
    setNewPasswordError(
      re.test(value)
        ? 'Пароль не должен содержать символы <!@#$%^&*()_-+=[{]};:<>|./?>'
        : '',
    );
    if (newPasswordConfirmation.length)
      setNewPasswordConfirmationError(
        value !== newPasswordConfirmation ? 'Пароли не совпадают' : '',
      );
    if (value.length > PASSWORD_MAX_LENGTH)
      setNewPasswordError('Пароль слишком длинный');
  };
  /** Событие фокуса на новом пароле */
  const onFocusNew = () => {
    setIsShowRules(true);
    setNewPasswordError('');
  };

  /** Ошибка подтверждения нового пароля */
  const [
    newPasswordConfirmationError,
    setNewPasswordConfirmationError,
  ] = useState<string>('');
  /** Событие смены подтверждения нового пароля */
  const onNewPasswordConfirmationChange = (event) => {
    const { value } = event.target;
    setNewPasswordConfirmation(value);
    setNewPasswordConfirmationError(
      value === newPassword || (!value.length && !newPassword.length)
        ? ''
        : 'Пароли не совпадают',
    );
  };

  /** Генерация пароля */
  const generatePass = async () => {
    try {
      const res = await generatePassword();
      checkRules(res);
      setIsShowRules(true);
      setNewPassword(res);
      setNewPasswordConfirmation(res);
      setNewPasswordConfirmationError('');
    } catch (e) {
      setNewPasswordError(DEFAULT_ERROR);
    }
  };

  useEffect(() => {
    if (!newPassword.length && !newPasswordConfirmation.length)
      setNewPasswordConfirmationError('');
  }, [newPassword, newPasswordConfirmation]);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <StyledChangePassword>
      {result.isResult && result.isCorrect && (
        <>
          <div className="result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? <H2>Пароль изменён</H2> : <H3>Пароль изменён</H3>}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <div className="result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}

      {!result.isResult && (
        <>
          <div className="password__input-block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Текущий пароль
            </Text>
            <div className="password__input-block__block">
              <Input
                type="password"
                placeholder="Пароль"
                error={currentPasswordError}
                onChange={onCurrentPasswordChange}
                onFocus={onFocusCurrent}
                isFocus
              />
              {isDesktop940 && (
                <Text
                  className="password__input-block__block__action"
                  lineHeight="24px"
                  color={
                    isTemporaryTokenAuth
                      ? defaultTheme.colors.disable
                      : defaultTheme.colors.planeta
                  }
                  onClick={() => {
                    if (!isTemporaryTokenAuth) {
                      setIsShowCreatePasswordWizard(true);
                    }
                  }}
                >
                  Восстановить
                </Text>
              )}
            </div>
          </div>
          <div className="password__input-block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Новый пароль
            </Text>
            <div className="password__input-block__block">
              <Input
                type="password"
                placeholder="Пароль"
                value={newPassword}
                error={newPasswordError}
                onChange={onNewPasswordChange}
                onFocus={onFocusNew}
                autocomplete="new-password"
              />
              <Text
                className="password__input-block__block__action"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={generatePass}
              >
                Сгенерировать
              </Text>
            </div>
          </div>
          {isShowRules && (
            <div className="password__security">
              <Text lineHeight="24px">Надёжность пароля</Text>
              <FillableScale value={scaleValue} fillerColor={fillerColor} />
              <>
                {[
                  { condition: isEightSymbols, text: 'Не менее 8 символов' },
                  { condition: isOneDigit, text: 'Как минимум 1 цифра' },
                  {
                    condition: isOneCapitalLetter,
                    text: 'Как минимум 1 прописная буква',
                  },
                  {
                    condition: isOneLowercaseLetter,
                    text: 'Как минимум 1 строчная буква',
                  },
                ].map((item) => (
                  <Text
                    key={item.text}
                    className={`${item.condition ? 'line-through' : ''}`}
                    lineHeight="24px"
                  >
                    {item.text}
                  </Text>
                ))}
              </>
              <div>
                <Text
                  className="password__security__warning"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Не должен содержать символы {'<!@#$%^&*()_-+=[{]};:<>|./?>'}
                </Text>
                <Text
                  className="password__security__warning"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Постарайтесь не&nbsp;использовать пароль, который уже
                  используется Вами на&nbsp;других сайтах или который можно
                  легко разгадать
                </Text>
              </div>
            </div>
          )}
          <div className="password__input-block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Подтвердить новый пароль
            </Text>
            <div className="password__input-block__block">
              <Input
                type="password"
                placeholder="Пароль"
                value={newPasswordConfirmation}
                error={newPasswordConfirmationError}
                onChange={onNewPasswordConfirmationChange}
                autocomplete="new-password"
              />
            </div>
          </div>
        </>
      )}
    </StyledChangePassword>
  );
};

export default observer(ChangePassword);
