/** Наименование класса для блока поиска */
export const CLASS_NAME_SEARCH_BLOCK = 'mobile-sbp__search';
/** Наименование класса блока с контентом Side Page */
export const CLASS_NAME_SP_BLOCK = 'sidepage__container';
/** Высота с учётом отступа */
export const MARGIN_SP_HEIGHT = 140;

export const DEEP_LINK_TEXT = {
  IGNORED: `Похоже, на${'\u00A0'}вашем устройстве нет приложения выбранного банка или вы${'\u00A0'}отказались от${'\u00A0'}перехода к${'\u00A0'}оплате. Установите его, выберите другой банк из${'\u00A0'}списка или попробуйте ещё раз.`,
  FALLBACK: `Похоже, на${'\u00A0'}вашем устройстве нет приложения выбранного банка или вы${'\u00A0'}отказались от${'\u00A0'}его открытия. Установите его или выберите другой банк из${'\u00A0'}списка.`,
  RETURN: `Вы${'\u00A0'}можете вернуться к${'\u00A0'}списку банков или повторно перейти в${'\u00A0'}приложение, если не${'\u00A0'}завершили платёж.`,
};
