/** библиотеки */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop1100,
  desktop1280,
  desktop900,
  desktop940,
} from '~/components/Grid/constants';
import { ANIMATION_DURATION } from '~/components/Blocks/Templates/Pab2c/Devices/constants';

export const StyledDevices = styled.div`
  H2 {
    margin: 16px 0 32px 32px;
  }

  H3 {
    margin: 16px 0;
  }

  .devices {
    &__title-wrap {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      H3 {
        margin: 16px 16px 16px 0;
      }
    }
  }

  .button-wrapper {
    padding: 0 32px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
    }
  }
`;

export const StyledSP = styled.div`
  .sidepage {
    &__container {
      overflow: hidden;
    }
    &__content {
      margin-top: 42px !important;
    }
  }
`;

export const StyledDevicesHeader = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => {
    if (isVisible) {
      return `
      margin-bottom: 32px;
      padding: 32px 0;
      background: ${defaultTheme.colors.white};

      .devices {
        &__header {
          position: relative;
          max-height: 550px;
          overflow-y: auto;

          H3 {
            margin: 0 16px 0 0 !important;
          }
        }
      }
      `;
    }
    return '';
  }}
`;

export const StyledDevicesTags = styled.div`
  margin: 32px 0;
  padding: 0 32px;

  .devices {
    &__tag {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 32px;
    padding: 0;

    .devices__tag {
      margin-bottom: 32px;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
`;

export const StyledDeviceDetailedCardImage = styled.div<{ imgSrc: string }>`
  ${({ imgSrc }) => css`
    height: 160px;
    width: 160px;
    background: url(${imgSrc}) left bottom no-repeat;
    background-size: contain;
    @media (min-width: ${desktop900}px) {
      height: 232px;
      width: 232px;
    }
  `}
`;

export const StyledResult = styled.div`
  h2 {
    margin: 24px 0 32px 0;
  }
`;

export const StyledDevicesList = styled.div<{
  isPab2cView?: boolean;
  animationName?: 'right' | 'left';
  withBanner?: boolean;
  xPosition?: number;
}>`
  ${({ isPab2cView, animationName, withBanner, xPosition }) => `
    display: flex;
    flex-direction: ${isPab2cView ? 'row' : 'column'};
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: ${isPab2cView ? 'nowrap' : 'wrap'};
    width: 100%;
    position: relative;
    ${
      animationName &&
      `animation: ${animationName} ${ANIMATION_DURATION}ms ease-in-out`
    };
    ${
      isPab2cView &&
      `
    left: ${xPosition}px;
    transition: left .5s;
    `
    };

    li {
      margin-right: '0';
      margin-bottom: 24px;
    }
  
    li:nth-of-type(3n + 3) {
      margin-right: '0';
      margin-bottom: 24px;
    }
  
    @media (min-width: ${desktop940}px) {
      ${withBanner && 'width: calc(100% - 330px)'};
      flex-direction: row;
      
      > div {
        > div:nth-of-type(2) {
          top: -47px;
        }
      }
      
      li {
        margin-right: ${isPab2cView ? '0' : '32px'};
        margin-bottom: ${isPab2cView ? '0' : '32px'};
        ${
          isPab2cView &&
          `
          display: flex;
          flex-direction: column;
        `
        }
      }
  
      li:nth-of-type(3n + 3) {
        margin-right: ${isPab2cView ? '0' : '0'};
        margin-bottom: ${isPab2cView ? '0' : '32px'};
        ${
          isPab2cView &&
          `
          display: flex;
          flex-direction: column;
        `
        }
      }
    }
    
     @media (min-width: ${desktop1100}px) {
       ${withBanner && 'width: calc(100% - 355px)'};
     }
     
     @media (min-width: ${desktop1280}px) {
       ${withBanner && 'width: calc(100% - 400px)'};
     }
  `}
`;

export const StyledSidePageFooter = styled.div`
  ul > li {
    list-style-type: none;
    padding-left: 21px;
  }

  ol {
    margin-left: 13px;

    li {
      padding-left: 8px;
    }
  }
`;

export const StyledDetailedDevice = styled.div`
  padding: 0;
  width: 100%;
  background: ${defaultTheme.colors.white};

  .select-overlay {
    width: 100% !important;
  }

  .devices {
    &__block {
      display: flex;
      flex-direction: column;
      margin: 0 0 16px 0;
      &__right {
        display: flex;
        flex-direction: column;
        margin: 16px 0 0 0;
        &__colors {
          margin-bottom: 16px;
        }
      }
    }
    &__price {
      margin: 16px 0;
      h3 {
        margin: 0;
      }
    }
    &__price-snoska {
      margin-top: 8px;
    }
    &__button {
      width: 160px;
      margin: 0 0 16px 0;
    }
    &__item-title {
      margin: 8px 0 35px 0;
      height: 64px;
      display: flex;
      flex-direction: column;
    }
    &__item-tabs {
      height: 24px;
      white-space: nowrap;
      overflow: auto;
      overflow-y: hidden;
    }
    &__item-price {
      margin: 5px 0 20px 0;
      height: 32px;
    }
    &__error {
      margin-top: 8px;
    }
    &__auth-warning {
      background-color: ${defaultTheme.colors.lightBlue};
      padding: 16px;
      margin-bottom: 16px;
      &__yellow {
        background-color: ${defaultTheme.colors.backgroundWarning};
        padding: 16px;
        margin-bottom: 16px;
        > span {
          & > div {
            display: contents;
          }
        }
      }
    }
    &__cursor {
      cursor: pointer;
    }
    &__content {
      @supports (scrollbar-gutter: stable) {
        scrollbar-gutter: stable;
        padding-right: 14px;
      }
      height: 435px;
      width: 98%;
      overflow: hidden;
    }
    &__content:hover {
      overflow: auto;
    }
    &__content::-webkit-scrollbar {
      width: 5px;
      padding-left: 6px;
    }

    &__content::-webkit-scrollbar-track {
      background-color: ${defaultTheme.colors.white};
      border-radius: 10px;
    }

    &__content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${defaultTheme.colors.lightGrey};
    }
  }

  @media (min-width: ${desktop940}px) {
    .devices {
      &__block {
        flex-direction: row;
        position: relative;
        &__right {
          margin: 0 0 0 48px;
        }
      }
    }
  }
`;

export const StyledLegalBlockDevice = styled.div`
  p {
    margin: 0;
  }
`;

export const StyledColorBullet = styled.div<{ hex: string }>`
  ${({ hex }) => {
    return css`
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: ${hex};
    `;
  }}
`;

export const StyledFailureSP = styled.div`
  margin-top: -42px;
  svg {
    margin-bottom: 24px;
  }
`;
