/** libraries */
import styled from '@emotion/styled';
import { desktop940 } from '~/components/Grid/constants';

export const StyledVoluntarySuspension = styled.div`
  display: flex;
  flex-direction: column;

  .button-block {
    display: flex;
    flex-direction: column;

    button {
      order: 2;
      margin: 8px 0 0 0;
      width: fit-content;
    }

    > span {
      order: 1;
      width: 100%;
    }
  }

  @media (min-width: ${desktop940}px) {
    .button-block {
      flex-direction: row;
      button {
        order: 1;
        margin: 0 24px 0 0;
      }

      > span {
        order: 2;
        width: 164px;
      }
    }
  }
`;
