/** Libraries */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
/** Components */
import { H3, Icon, Icons, LinkButton, Text } from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';
/** Types */
import { ConfigTypes, ResultProps } from '../types';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/**
 * Блок результата
 */
const Result: FC<ResultProps> = ({
  activeConfig,
  redirectUri,
}: ResultProps) => {
  const { toggleVisible } = useMakeAuthStore();

  const linkButtonClick = () => {
    if (
      [ConfigTypes.APPROVE_CONFIG, ConfigTypes.LINKED_CONFIG].includes(
        activeConfig.name,
      )
    )
      toggleVisible();
  };

  useEffect(() => {
    if (
      [ConfigTypes.SUCCESS_CONFIG, ConfigTypes.CANCEL_CONFIG].includes(
        activeConfig.name,
      )
    )
      window.location.assign(redirectUri);
  }, []);

  return (
    <>
      {activeConfig.type === 'success' ? (
        <Icon icon={<Icons.OkBigIcon />} />
      ) : (
        <Icon icon={<Icons.NotOkBigIcon />} />
      )}
      <H3>{activeConfig.header}</H3>
      <Text>{activeConfig.content}</Text>
      {activeConfig.link.href && (
        <LinkWrapper
          target={
            activeConfig.name !== ConfigTypes.SUSPENDED_CONFIG
              ? '_self'
              : '_blank'
          }
          href={activeConfig.link.href}
        >
          {activeConfig.link.name}
        </LinkWrapper>
      )}
      {!activeConfig.link.href && (
        <LinkButton onClick={linkButtonClick}>
          {activeConfig.link.name}
        </LinkButton>
      )}
    </>
  );
};

export default observer(Result);
