export const INTERNAL_ERROR = 'InternalError';

export const DEFAULT_ERROR = 'Что-то пошло не так. Повторите попытку позже.';

// state result по умолчанию
export const DEFAULT_RESULT = {
  isResult: false,
  isCorrect: true,
  text: '',
};

export enum ResultSetTariffCodes {
  ERROR = 'InternalError',
  SUCCESS = 'Ok',
}
