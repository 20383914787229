/* eslint-disable camelcase */
/** libraries */
import { FC, useMemo, useState } from 'react';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icon,
  Icons,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** interfaces */
import { Channel, ChannelPackage, ConnectionStates } from '../../../interfaces';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { CardStates, CHANNEL_DESCRIPTION_ID, TEXT } from '../../../constants';

/** styles */
import { StyledChannelDescription } from './styles';
import {
  parseHtml,
  scrollToBlockById,
} from '~/components/Blocks/Shared/Shared.utils';

/** components */
import Row from '../../common/RowContent';
import Platforms from '../../Platforms/Platforms';
import ChannelProperties from '../../ChannelProperties/ChannelProperties';
import ChannelPackageCard from '../../Cards/ChannelPackageCard/ChannelPackageCard';
import ToggleTabs from '../../common/ToggleTabs';
import ChannelCard from '../../Cards/ChannelCard/ChannelCard';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** utils */
import { formatNumber } from '~/utils/utils';

/** stores */
import { useMyTvStore } from '../../../stores/useMyTvStore';
import { useRootStore } from '~/stores/RootStore';

interface ChannelDescriptionProps {
  // channels: SubjectWithChannels[];
  /** Каналы включённые в продукт */
  allChannelsIncludedInProduct: Channel[];
  /** Каналы вне пакета включённые в продукт */
  channelsIncludedInProduct: ChannelPackage[];
  /** Пакеты включённые в продукт */
  packagesIncludedInProduct: ChannelPackage[];
  /** Трансформер в блоке Телевидение */
  isTransformer?: boolean;
}

const ChannelDescription: FC<ChannelDescriptionProps> = ({
  allChannelsIncludedInProduct,
  channelsIncludedInProduct,
  packagesIncludedInProduct,
  isTransformer,
}: ChannelDescriptionProps) => {
  const {
    tvStore: {
      editOrderSum,
      setEditOrderSum,
      idsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
      setIdsAddedChannelsToTransformer,
    },
  } = useRootStore();
  const {
    transformationLimit,
    filteredPackages: packages,
    activeChannelCard: channel,
    openCardBlockId,
    setCardState,
    setIsEditTransformer,
    setActivePackageCard,
    onClickPackageCard,
    augmentedChannelsByGroups: channels,
  } = useMyTvStore();

  const {
    id,
    weburgId,
    imgLink,
    name,
    description,
    availablePackets,
    channelDefinition,
    audioStreams,
    adultContent,
    itvChannelLink,
    singleChannelPacks,
  } = channel;

  /** Цена одиночного канала */
  const singleChannelPrice =
    singleChannelPacks?.length > 0 ? singleChannelPacks[0].price : null;

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Категория канала */
  const category = useMemo(
    () =>
      channels.find((item) =>
        item.channels.find((canal) =>
          canal?.weburgId ? canal.weburgId === weburgId : canal.id === id,
        ),
      ).name,
    [channels],
  );

  /** Варианты подключения */
  const connectionOptions = useMemo(() => {
    return availablePackets?.length && packages?.length
      ? availablePackets
          .map((item) => {
            return packages.find((pack) => pack.channelPackName === item.name);
          })
          .filter((item) => {
            return item !== undefined;
          })
      : [];
  }, [availablePackets]);

  /** Добавленные в трансформер пакеты, в составе которых есть этот канал */
  const [addedToTransformerPackages, setAddedToTransformerPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Тип подключения */
  const connection = useMemo(() => {
    /** Подключён в продукте отдельно от пакета */
    if (channelsIncludedInProduct.find((item) => item.id === channel.id))
      return ConnectionStates.ConnectedInTheProduct;
    /** Подключён в пакете, который подключён в продукте */
    const channelInProduct = allChannelsIncludedInProduct.find(
      (item) => item.id === channel.id,
    );
    if (channelInProduct) return ConnectionStates.ConnectedInPackageInProduct;

    /** Добавленные в трансформер пакеты (без подключённых) */
    const addedPackages = idsAddedPacketsToTransformer?.length
      ? packages.filter((item) =>
          idsAddedPacketsToTransformer.includes(item.id),
        )
      : [];

    /** Добавлен в трансформер в пакетах */
    if (isTransformer && addedPackages.length) {
      /** Добавленные в трансформер пакеты, в которых есть этот канал */
      const packets = addedPackages.filter((item) =>
        item.channelPackChannels.find((canal) => canal.id === id),
      );
      if (packets.length) {
        setAddedToTransformerPackages(packets);
        return ConnectionStates.AddedToTransformerInPackage;
      }
    }

    /** Канал добавлен в трансформер */
    const channelAddedToTransformer = idsAddedChannelsToTransformer.find(
      (item) =>
        singleChannelPacks?.length ? item === singleChannelPacks[0].id : null,
    );

    /** Добавлен в трансформер */
    if (isTransformer && channelAddedToTransformer) {
      return ConnectionStates.AddedToTransformer;
    }
    return ConnectionStates.NotConnected;
  }, [
    isTransformer,
    idsAddedChannelsToTransformer,
    idsAddedPacketsToTransformer,
    allChannelsIncludedInProduct,
    packages,
  ]);

  /** Дополнительная цена за трансформер */
  const additionalPriceForTransformer = useMemo(() => {
    if (singleChannelPacks?.length === 0 && !isTransformer) return undefined;
    /** Остаток лимита трансформера */
    const residue = transformationLimit - editOrderSum;

    if (residue > singleChannelPrice) {
      return null;
    }
    if (transformationLimit > editOrderSum && residue < singleChannelPrice) {
      return singleChannelPrice - residue;
    }
    return singleChannelPrice;
  }, [transformationLimit, editOrderSum]);

  /** Текст кнопки трансформера */
  const transformerButtonText = useMemo(() => {
    switch (connection) {
      case ConnectionStates.NotConnected:
        return 'Добавить в Трансформер';
      case ConnectionStates.AddedToTransformer:
        return 'Убрать канал';
      default:
        return null;
    }
  }, [connection]);

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (
        [
          ConnectionStates.AddedToTransformer,
          ConnectionStates.AddedToTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.planeta;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (
        [
          ConnectionStates.AddedToTransformer,
          ConnectionStates.AddedToTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.pink;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isDesktop940) return undefined;

      /** Добавлен в пакетах */
      if (connection === ConnectionStates.AddedToTransformerInPackage) {
        if (addedToTransformerPackages?.length === 1) {
          return `Добавлен в пакете ${addedToTransformerPackages[0]?.channelPackName}. Будет подключён при применении трансформера`;
        }
        /** Много пакетов */
        if (addedToTransformerPackages?.length > 1) {
          return `Добавлен в пакетах ${addedToTransformerPackages
            .map((item) => item.channelPackName)
            .join(', ')}. Будет подключён при применении трансформера`;
        }
      }

      /** Добавлен в трансформер */
      if (
        [
          ConnectionStates.AddedToTransformerInPackage,
          ConnectionStates.AddedToTransformer,
        ].includes(connection)
      ) {
        return 'Будет подключён при применении трансформера';
      }

      return undefined;
    };

    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          ![
            ConnectionStates.AddedToTransformer,
            ConnectionStates.AddedToTransformerInPackage,
          ].includes(connection) && colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = useMemo((): string | boolean => {
    switch (connection) {
      case ConnectionStates.AddedToTransformer:
        return TEXT.ADDED;
      case ConnectionStates.AddedToTransformerInPackage:
        return addedToTransformerPackages.length > 1
          ? TEXT.ADDED_IN_PACKAGES
          : TEXT.ADDED_IN_PACKAGE;
      case ConnectionStates.ConnectedInTheProduct:
        return TEXT.INCLUDED_IN_PRODUCT;
      case ConnectionStates.ConnectedInPackageInProduct:
        return TEXT.INCLUDED_IN_PRODUCT;
      default:
        return false;
    }
  }, [connection, addedToTransformerPackages]);

  /** Событие клика трансформера */
  const transformerClick = () => {
    setIsEditTransformer(true);

    switch (connection) {
      case ConnectionStates.NotConnected:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer?.length
            ? [...idsAddedChannelsToTransformer, singleChannelPacks[0].id]
            : [singleChannelPacks[0].id],
        );
        setEditOrderSum(editOrderSum + singleChannelPrice);
        break;
      case ConnectionStates.AddedToTransformer:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer.filter(
            (item) => item !== singleChannelPacks[0].id,
          ),
        );
        setEditOrderSum(editOrderSum - singleChannelPrice);
        break;
      default:
        break;
    }
  };

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    if (transformationLimit > editOrderSum) {
      return `доступно ${formatNumber(
        transformationLimit - editOrderSum,
      )} ₽ для трансформации`;
    }
    return `${formatNumber(
      editOrderSum - transformationLimit,
    )} ₽ в день за доп. каналы`;
  }, [editOrderSum, transformationLimit]);

  return (
    <StyledChannelDescription id={CHANNEL_DESCRIPTION_ID}>
      <div className="channel-description-card__header">
        <div className="channel-description-card__header__logo-block">
          <div className="channel-description-card__header__logo-block__logo">
            {imgLink && (
              <Image
                loader={imageLoader}
                src={imgLink}
                alt={name}
                width={isDesktop940 ? 190 : 130}
                height={isDesktop940 ? 88 : 60}
                quality={100}
                loading="lazy"
              />
            )}
          </div>
          <div className="channel-description-card__header__logo-block__name-block">
            <H3>{name}</H3>
            <Text color={defaultTheme.colors.shadow}>{category}</Text>
            {[
              ConnectionStates.ConnectedInPackageInProduct,
              ConnectionStates.ConnectedInTheProduct,
              ConnectionStates.AddedToTransformer,
              ConnectionStates.AddedToTransformerInPackage,
            ].includes(connection) && (
              <div className="channel-description-card__header__logo-block__name-block__tag-block">
                {customTag(connectionStatusText)}
                {connection ===
                  ConnectionStates.AddedToTransformerInPackage && (
                  <Text color={defaultTheme.colors.planeta}>
                    {addedToTransformerPackages
                      ?.map((item) => (
                        <Text
                          className="channel-description-card__platforms__digital-tv__link"
                          color={defaultTheme.colors.planeta}
                          onClick={() => {
                            setActivePackageCard(item);
                            setCardState(CardStates.PACKAGE_DESCRIPTION);
                          }}
                        >
                          {item.channelPackName}
                        </Text>
                      ))
                      .reduce(
                        (acc, x) =>
                          acc === null ? (
                            x
                          ) : (
                            <>
                              {acc}, {x}
                            </>
                          ),
                        null,
                      )}
                  </Text>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="channel-description-card__header__close-icon"
          onClick={() => {
            setCardState(CardStates.MAIN);
            if (!isDesktop940)
              setTimeout(() => {
                scrollToBlockById(openCardBlockId, 'auto', 'center');
              }, 0);
          }}
        >
          <Icons.CloseIcon />
        </div>
      </div>

      <Row>
        <Text>{parseHtml(description)}</Text>
      </Row>

      {isTransformer &&
        singleChannelPacks?.length &&
        connection !== ConnectionStates.AddedToTransformerInPackage && (
          <div className="channel-description-card__subscription-cost">
            <Row
              title={`Стоимость подписки ${
                isTransformer && `в${'\u00A0'}Трансформере`
              }`}
            >
              <ToggleTabs
                cost={singleChannelPacks[0]?.price}
                additionally={
                  ![ConnectionStates.AddedToTransformer].includes(connection)
                    ? additionalPriceForTransformer
                    : null
                }
              />

              {isTransformer && (
                <div className="channel-description-card__transformer__button-block">
                  <FillableScale
                    value={editOrderSum}
                    maxValue={transformationLimit}
                  >
                    <Text className="text" lineHeight="24px">
                      {textUnderScale}
                    </Text>
                  </FillableScale>
                  {[
                    ConnectionStates.NotConnected,
                    ConnectionStates.AddedToTransformer,
                    ConnectionStates.AddedToTransformerInPackage,
                  ].includes(connection) && (
                    <div className="channel-description-card__transformer__button-block__refusal-block">
                      <Button
                        onClick={() => transformerClick()}
                        icon={
                          [ConnectionStates.NotConnected].includes(
                            connection,
                          ) && <Icon icon={<Icons.PlusIcon />} />
                        }
                        styleType={
                          [
                            ConnectionStates.AddedToTransformer,
                            ConnectionStates.ConnectedSeparatelyFromThePackage,
                          ].includes(connection)
                            ? ButtonStyleTypes.SECONDARY
                            : undefined
                        }
                      >
                        {transformerButtonText}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Row>
          </div>
        )}

      {connection === ConnectionStates.NotConnected &&
        connectionOptions?.length > 0 && (
          <div className="channel-description-card__connection-options">
            <Row title="Варианты подключения">
              {singleChannelPacks?.length > 0 && !isTransformer && (
                <ChannelCard
                  channel={channel}
                  isConnection
                  isTransformer={isTransformer}
                />
              )}
              {connectionOptions.map((packet: ChannelPackage) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={packet}
                    onClick={() => onClickPackageCard(packet)}
                    packagesIncludedInProduct={packagesIncludedInProduct}
                    isTransformer={isTransformer}
                  />
                );
              })}
            </Row>
          </div>
        )}

      <Platforms itv_channel_link={itvChannelLink} isWink={false} />

      <Row
        title="Свойства канала"
        removeLine={
          !(
            isTransformer &&
            [
              ConnectionStates.AddedToTransformerInPackage,
              ConnectionStates.AddedToTransformer,
            ].includes(connection)
          )
        }
      >
        <ChannelProperties
          channelDefinition={channelDefinition}
          audioStreams={audioStreams}
          adultContent={adultContent}
          isViewControlAvailable={false}
        />
      </Row>

      {isTransformer &&
        [
          ConnectionStates.AddedToTransformerInPackage,
          ConnectionStates.AddedToTransformer,
        ].includes(connection) &&
        connectionOptions?.length > 0 && (
          <div className="channel-description-card__connection-options">
            <Row title="Канал доступен в пакетах" removeLine>
              {connectionOptions.map((packet: ChannelPackage) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={packet}
                    onClick={() => onClickPackageCard(packet)}
                    packagesIncludedInProduct={packagesIncludedInProduct}
                    isTransformer={isTransformer}
                  />
                );
              })}
            </Row>
          </div>
        )}
    </StyledChannelDescription>
  );
};

export default observer(ChannelDescription);
