import styled from '@emotion/styled';
import { defaultTheme, LeadingText, H3 } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledSbpBindingSuggestion = styled.div`
  .button-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    > button {
      height: 54px;
    }
  }

  button {
    width: 100%;
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    > span {
      margin: -9px 8px 0 8px;
      height: 37px;
    }
  }

  .error {
    max-width: 443px;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;

    a {
      color: ${defaultTheme.colors.planeta};
    }
  }

  .snoska {
    margin-top: 16px;
    a {
      color: ${defaultTheme.colors.planeta};
    }
  }

  .warning {
    padding: 16px;
    background-color: ${defaultTheme.colors.backgroundWarning};
    margin-top: 24px;

    &__banks {
      cursor: pointer;
    }
  }

  @media (min-width: ${desktop940}px) {
    .button-block {
      flex-direction: row;
      margin-top: 32px;
    }

    button {
      width: fit-content;
    }

    .header {
      > span {
        margin: -3px 8px 0 8px;
      }
    }
    .info {
      margin: 0 0 0 16px;
    }
  }
`;

export const HeaderMobile = styled(LeadingText)`
  svg {
    height: 31px;
    position: relative;
    top: -4px;
  }
`;

export const HeaderDesktop = styled(H3)`
  svg {
    height: 37px;
    position: relative;
    margin: 0 8px;
    top: -2px;
  }
`;
