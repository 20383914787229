/** libraries */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import { desktop500, desktop940 } from '~/components/Grid/constants';

export const StyledToggleTabs = styled.div<{ isAdditionally: boolean }>`
  ${({ isAdditionally }) => {
    return css`
      min-width: 120px;

      .toggle-tabs {
        &__tabs {
          line-height: 24px;

          .active {
            > span {
              color: ${defaultTheme.colors.shadow};
            }
          }
        }
      }
      > div:nth-of-type(2) {
        width: auto;
        .colorContainer {
          ${isAdditionally ? 'padding: 4px 8px;' : 'padding: 0 !important;'}
          width: 100%;
          flex-direction: column;
          align-items: baseline;
          margin-top: 4px;

          h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.03em;
          }
        }
      }

      @media (min-width: ${desktop500}px) {
        > div:nth-of-type(2) {
          width: 552px;
          .colorContainer {
            width: 70%;
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        > div:nth-of-type(2) {
          .colorContainer {
            flex-direction: row;
            align-items: center;
            h3 {
              font-size: 32px;
              line-height: 40px;
            }
          }
        }
      }
    `;
  }}
`;
