/** libraries */
import { FC, RefObject, useRef } from 'react';
import { observer } from 'mobx-react';
import { Loader } from 'cordis-core-ui-planeta';
/** styles */
import { StyledProductSelector } from './ProductTemplate.style';
/** interfaces */
import {
  ProductTemplateProps,
  SummaryFieldsProps,
} from './ProductTemplate.interfaces';
import { BUSINESS_GROUP_CODE } from '../../Templates/Summary/Summary.types';
/** components */
import ProductSelectorItem from './ProductSelectorItem/ProductSelectorItem';
import ProductSwitcherWizard from '~/components/Blocks/Shared/ProductSwitcherWizard/ProductSwitcherWizard';
import ChangeConnectionTypeSP from '../../Templates/ProductSwitcher/ChangeConnectionTypeSP';
import AddingContactSidePage from '../ContactsAndNotifications/Components/AddingContact/AddingContactSidePage';
import ArrowsContainer from './ArrowsContainer';
import ProductTags from './ProductTags';
import Portal from '~/components/Portal/Portal';
/** hooks */
import { useProductTemplate } from './ProductTemplateHook';

const ProductTemplate: FC<ProductTemplateProps> = ({
  blocks,
  marketingFields,
  summaryFields,
  withoutFilters,
  activeProductTag,
  isMiniProductSwitcher,
  isLoading,
}: ProductTemplateProps) => {
  const productRef: RefObject<HTMLDivElement> | null = useRef(null);

  const {
    getProductList,
    activeProductTags,
    setActiveProductTags,
    getMarketingNameByCode,
    getMiniSummaryByCode,
    productListLength,
    xPosition,
    setXPosition,
    codes,
  } = useProductTemplate({
    marketingFields,
    summaryFields,
    blocks,
    isMiniProductSwitcher,
  });

  return (
    <StyledProductSelector
      xPosition={xPosition}
      ref={productRef}
      withoutFilters={withoutFilters}
    >
      {isLoading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <>
          {!withoutFilters && (
            <ProductTags
              marketingFields={marketingFields}
              isMiniProductSwitcher={isMiniProductSwitcher}
              activeProductTag={activeProductTag}
              activeProductTags={activeProductTags}
              setActiveProductTags={setActiveProductTags}
              codes={codes}
            />
          )}
          <ArrowsContainer
            productListLength={productListLength}
            activeProductTags={activeProductTags}
            productRef={productRef}
            xPosition={xPosition}
            setXPosition={setXPosition}
          >
            {productListLength > 0 &&
              getProductList().map(
                (item: SummaryFieldsProps) =>
                  getMiniSummaryByCode(item.seriesCode)[0] && (
                    <ProductSelectorItem
                      key={item.seriesCode}
                      isAnnual={item.isAnnual}
                      channelsInfo={item.channelsInfo}
                      marketingName={getMarketingNameByCode(
                        item.marketingGroupCode,
                      )}
                      marketingText={item.marketingText}
                      priceInfo={item.priceInfo}
                      seriesName={item.seriesName}
                      speedBaseText={item.speedBaseText}
                      marketingCategoryFeedLink={
                        getMiniSummaryByCode(item.seriesCode)[0]
                          .marketingCategoryFeedLink
                      }
                      productFeedLink={
                        getMiniSummaryByCode(item.seriesCode)[0].productFeedLink
                      }
                      tags={item.tags}
                      isBusiness={
                        item.marketingGroupCode === BUSINESS_GROUP_CODE
                      }
                      tariffId={item.tariffId}
                      withoutFilters={withoutFilters}
                      action={getMiniSummaryByCode(item.seriesCode)[0].action}
                      tariff={item}
                    />
                  ),
              )}
          </ArrowsContainer>
        </>
      )}
      <Portal wrapperId="portalPrimary">
        <AddingContactSidePage />
        <ChangeConnectionTypeSP />
      </Portal>
      <Portal>
        <ProductSwitcherWizard />
      </Portal>
    </StyledProductSelector>
  );
};

export default observer(ProductTemplate);
