import * as React from 'react';

/* Типы */
import {
  Text,
  H3,
  defaultTheme,
  Tabs,
  Icons,
  TabsStyleTypes,
} from 'cordis-core-ui-planeta';
import {
  CallToActionTelevisionProps,
  CHANNEL_NAMES,
} from './CallToActionTelevision.types';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';

/* Стили */
import {
  StyledRightColumn,
  StyledLeftColumn,
} from './CallToActionTelevision.style';

/* Утилиты */
import { pluralizeAll } from '~/utils/utils';

/* Дочерний компонент "Цифровое телевидение", компонента CallToAction */
const CallToActionTelevision: React.FC<CallToActionTelevisionProps> = ({
  allChannelCount,
  channelCount,
  hdChannelCount,
  uhdChannelCount,
  isTransformer,
}: CallToActionTelevisionProps) => {
  /* Варианты склонений количества каналов */
  const CHANNELS_VARIANTS: string[] = [
    'телеканал',
    'телеканала',
    'телеканалов',
  ];
  /* Варианты вкладок таба */
  const tabsValue = [
    ...Object.values(CHANNEL_NAMES).filter((k) => {
      const isChannelName = typeof k === 'string';
      // Скрываем вкладку 4K в случае отсутствия телеканалов соответствующего качества
      return !uhdChannelCount
        ? isChannelName && k !== CHANNEL_NAMES.uhd
        : isChannelName;
    }),
  ];
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<string>(
    pluralizeAll(channelCount, CHANNELS_VARIANTS),
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number, tabValue: string) => {
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setActiveTabValue(pluralizeAll(channelCount, CHANNELS_VARIANTS));
        break;
      case 1:
        setActiveTabValue(pluralizeAll(hdChannelCount, CHANNELS_VARIANTS));
        break;
      case 2:
        setActiveTabValue(pluralizeAll(uhdChannelCount, CHANNELS_VARIANTS));
        break;
      default:
        setActiveTabValue(pluralizeAll(channelCount, CHANNELS_VARIANTS));
    }
  };

  return (
    <CallToActionRow
      title={
        <Text>
          Цифровое телевидение<sup>&nbsp;1</sup>
        </Text>
      }
    >
      <StyledLeftColumn isTransformer={isTransformer}>
        {!isTransformer && (
          <Tabs
            value={tabsValue}
            onChange={onChangeTab}
            activeTabIndex={activeTabIndex}
            width="100px"
            styleType={TabsStyleTypes.SECONDARY}
          />
        )}
        <H3>{!isTransformer ? activeTabValue : 'Твой набор каналов'}</H3>
        {isTransformer && (
          <Text color={defaultTheme.colors.shadow}>{`Выбирай из ${pluralizeAll(
            allChannelCount,
            CHANNELS_VARIANTS,
          )}`}</Text>
        )}
        <div>
          <div>
            <Icons.OkIcon />
            <Text color={defaultTheme.colors.shadow}>
              Портал цифрового телевидения Планеты
            </Text>
          </div>
        </div>
      </StyledLeftColumn>
      <StyledRightColumn>
        <div>
          <Icons.OkIcon />
          <Text color={defaultTheme.colors.shadow}>
            Портал цифрового
            <br />
            телевидения Планеты
          </Text>
        </div>
      </StyledRightColumn>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionTelevision);
