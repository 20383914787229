/** libraries */
import styled from '@emotion/styled';
import { desktop800 } from '~/components/Grid/constants';

export const StyledProlongationInformation = styled.div`
  .prolongation {
    &__price-button {
      display: flex;
      flex-direction: column;
      margin: 16px 0 0;

      > div:first-of-type {
        width: auto;
        margin: 0 0 16px 0;
        .colorContainer {
          width: 100%;
          padding: 4px 5px 4px 7px;
          > span {
            line-height: 24px;
          }
        }
      }

      button {
        width: fit-content;
        height: 56px;
      }
    }
  }

  @media (min-width: ${desktop800}px) {
    .prolongation {
      &__price-button {
        flex-direction: row;

        > div:first-of-type {
          margin: 0 16px 0 0;

          .colorContainer {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
`;
