/** типы устройств */
export enum DEVICE_TYPES {
  Phone = 'phone',
  STB = 'stb',
  Router = 'router',
  Handset = 'handset',
  Camera = 'camera',
  SmartSpeaker = 'smart_speaker',
}

// todo: Возвращать из метода
export const DEVICES = {
  [DEVICE_TYPES.Phone]: {
    id: 6,
    name: 'Телефона',
  },
  [DEVICE_TYPES.STB]: {
    id: 8,
    name: 'Телеприставки',
  },
  [DEVICE_TYPES.Router]: {
    id: 9,
    name: 'Маршрутизатора',
  },
  [DEVICE_TYPES.Handset]: {
    id: 11,
    name: 'Телефонной трубки',
  },
  [DEVICE_TYPES.Camera]: {
    id: 13,
    name: 'Камеры',
  },
  [DEVICE_TYPES.SmartSpeaker]: {
    id: 17,
    name: 'Умной колонки',
  },
};
