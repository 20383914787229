/** компоненты библиотеки */
import { Text, defaultTheme } from 'cordis-core-ui-planeta';

import { PreviewBannerStyled } from './style';

export const PreviewBanner = (): JSX.Element => (
  <PreviewBannerStyled>
    <Text color={defaultTheme.colors.gray}>Режим предосмотра</Text>
  </PreviewBannerStyled>
);
