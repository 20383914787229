/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo } from '../Legal.types';

/** утилиты */
import { formatNumber } from '~/utils/utils';

/** интерфейс компонента-шаблона PrepaymentText */
interface PrepaymentTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона PrepaymentText
 * @param {PrepaymentTextProps} content
 * @returns {React.FC}
 */
const PrepaymentText: React.FC<PrepaymentTextProps> = ({
  content,
}: PrepaymentTextProps) => {
  /**
   * Строка с авансовым платежом
   * @returns {JSX.Element}
   */
  const PrepaymentLine = (): JSX.Element => (
    <span>
      Обязательный авансовый платёж: {formatNumber(content.prepayment)} руб.
      <br />
    </span>
  );

  /**
   * текст шаблона
   * @returns {JSX.Element}
   */
  const Text = (): JSX.Element => (
    <span>
      Порог Отключения: {formatNumber(content.tariffSwitchOff)} руб. Порог
      Включения: {formatNumber(content.tariffSwitchOn)} руб.
    </span>
  );

  return (
    <p>
      {content.prepayment > 0 && PrepaymentLine()}
      {Text()}
    </p>
  );
};

export default PrepaymentText;
