/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop900,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { IMAGES } from './constants';

export const StyledSendWish = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 353px;
  width: 100%;

  .background {
    width: 100%;
    height: auto;
    background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), url(${IMAGES.background});
  }
  
  .background-fade {
    width: 100%;
    height: auto;
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${IMAGES.background});
  }

  .send-wish {
    &__block-x3 {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 16px 16px 16px;
    }

    &__greeting {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__auth {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      > button {
        width: 134px;
        margin-top: 32px;
      }
    }

    &__answer {
      display: flex;
      flex-direction: column;

      > h2 {
        margin: 16px 0;
      }

      &__textareaWrapper {
        position: relative;
        span {
          width: 100%;
        }
        textarea {
          max-width: 767px;
          width: 100%;
          height: 145px;
        }

        &__textareaError {
          position: absolute;
          font-size: 16px;
          color: ${defaultTheme.colors.gray};
        }
      }
    }

    &__repost {
      display: flex;
      flex-direction: column;

      h2 {
        margin: 16px 0;
      }

      &__text {
        margin-bottom: 32px;
      }

      &__icons {
        display: flex;
        margin: 16px 0;

        &__icon {
          position: relative;
          width: 48px;
          height: 48px;
          border-radius: 50px;
          cursor: pointer;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &__vk {
          background: #3076f6;
          margin-right: 16px;
        }

        &__ok {
          background: #dd7940;
        }
      }
    }
  }

  @media (min-width: ${desktop900}px) {
    height: 400px;
    flex-direction: row;

    .send-wish {
      &__block-x3 {
        max-width: none;
        width: 100%;
        padding: 32px 32px 30px 32px;
      }

      &__auth {
        margin-top: 0;
      }

      &__greeting {
        margin-bottom: 32px;

        H2 {
          margin-bottom: 0;
        }
      }

      &__text {
        margin-bottom: 0;
      }

        &__textareaWrapper {
          textarea {
            max-width: 767px;
            height: 123px;
          }
        }
      }

      &__repost {
        padding-right: 32px;
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    height: 353px;
    .send-wish {
      &__answer {
        &__textareaWrapper {
          textarea {
            margin: 0;
          }
        }
      }
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    width: auto;
    margin: 32px 0;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: center;

    .snoska {
      margin-top: 25px;
    }
  }
`;
