import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  desktop940,
  desktop1100,
  desktop1280,
  desktop500,
} from '~/components/Grid/constants';
import { Text, defaultTheme } from 'cordis-core-ui-planeta';
import { isExternal } from '~/utils/utils';

export const StyledBannerText = styled(Text)`
  flex: 1;
`;

export const StyledBanner = styled.div<{ $imgUrl?: string }>`
  height: 400px;
  width: 100%;
  position: absolute;
  right: -45px;
  top: -88px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ $imgUrl }) =>
    $imgUrl &&
    css`
      background-image: url(${isExternal($imgUrl)
        ? $imgUrl
        : `${process.env.STATIC_SERVER}${$imgUrl}`});
      background-position: top;
    `}

  h3 {
    margin-bottom: 0;
  }

  @media (min-width: ${desktop940}px) {
    width: 280px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 340px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 400px;
  }
`;

export const StyledNoSoftlines = styled.main<{
  isBusinessProduct: boolean;
}>`
  ${({ isBusinessProduct }) => `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  max-width: ${isBusinessProduct ? '' : '280px'};
  gap: 8px;

  > div {
    margin: 15px 0;
  }

  .catalog {
    margin: 0 0 16px 0;
  }
    `}
`;
interface StyledProductSelectorArrowProps {
  /* Флаг позиции слева */
  isLeft?: boolean;
  /* Флаг отображения компонента */
  show: boolean;
}
/* Элемент для смещения карточек продукта влево или вправо */
export const StyledProductSelectorArrow = styled.div`
  ${
    ({ isLeft, show }: StyledProductSelectorArrowProps) =>
      // Считает, что ширина в box-shadow - повторяющееся слово...
      /* yaspeller ignore:start */
      `
      padding: 0 ${isLeft ? '2px' : '0'} 0 ${!isLeft ? '3px' : '0'};
      display: ${show ? 'flex' : 'none'};
      align-items: center;
      justify-content: center;
      background: ${defaultTheme.colors.white};
      border-radius: 50%;
      width: 50px;
      height: 50px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 30%;
      left: ${isLeft ? '-17px' : 'auto'};
      right: ${!isLeft ? '-17px' : 'auto'};
      cursor: pointer;
      z-index: 2;

      svg {
        transform: rotate(${isLeft ? 180 : 0}deg);
      }

      @media(min-width: ${desktop500}px) {
        padding-right: ${isLeft ? '1px' : '0'};
      }
    `
    /* yaspeller ignore:end */
  }
`;
