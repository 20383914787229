/** библиотеки */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop609 } from '~/components/Grid/constants';

export const StyledSoftlineSubscribeWizard = styled.div`
  .subscribe-wizard {
    &__price-block {
      &__subscription-cost-text {
        margin-right: 8px;
      }

      &__price-tag {
        > div {
          margin-top: 4px;

          .header {
            display: none;
          }

          .colorContainer {
            width: auto;
          }
        }
      }

      &__promo-price {
        &__caption {
          margin-top: 8px;
        }
      }
    }

    &__is-minimum-order-time-wrapper {
      display: flex;
      flex-direction: column;

      &__block {
        margin-top: 16px;
      }
    }

    &__agreement {
      margin-top: 32px;

      a {
        color: ${defaultTheme.colors.planeta};
      }

      ol {
        list-style-type: none;
        li {
          margin-top: 16px;
        }
      }
    }
  }

  @media (min-width: ${desktop609}px) {
    .subscribe-wizard {
      &__is-minimum-order-time-wrapper {
        flex-direction: row;

        &__block {
          margin: 0 0 0 40px;
        }
      }
    }
  }
`;
