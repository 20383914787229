/** Libraries */
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
/** Components */
import StyledIgrovoy from './style';
import Main from './Main';
import Result from './Result';
/** Types */
import {
  ConfigTypes,
  GameProps,
  isMainConfig,
  isResultConfig,
  MainConfig,
  ResultConfig,
} from './types';
import { DEFAULT_LK_LINK, OPERATING_STATE } from '~/constants/common';
import { checkGameAccountBind } from '~/api/api';
import { removeLeadingZero } from '~/utils/utils';
/** Stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/** https://ckb.itmh.ru/pages/viewpage.action?pageId=646840861
 * Блок "Игровая платформа */
const Igrovoy: FC = () => {
  const {
    contractStateStore: { contractState },
    authStore: { isAuth, auth },
  } = useRootStore();
  const { toggleVisible } = useMakeAuthStore();
  const router = useRouter();

  /** Формирует данные для страниц блока */
  const configCreator = (configType: ConfigTypes): void => {
    switch (configType) {
      case ConfigTypes.APPROVE_CONFIG:
        setActiveConfig({
          name: ConfigTypes.APPROVE_CONFIG,
          header: `Привязка к договору ${removeLeadingZero(auth.contractName)}`,
          content: `При нажатии кнопки «Подтвердить привязку» я, ${
            auth.clientName
          }, Договор ${removeLeadingZero(
            auth.contractName,
          )}, направляю в${'\u00A0'}Общество с${'\u00A0'}ограниченной ответственностью «Комтехцентр» (ООО «Комтехцентр») настоящую заявку на${'\u00A0'}привязку моего договора к${'\u00A0'}профилю Игровой платформы.`,
          mainButton: 'Подтвердить привязку',
          secondaryButton: 'Отменить',
          link: {
            name: 'Войти в другой договор',
          },
        });
        break;
      case ConfigTypes.UNAUTHORIZED_CONFIG:
        setActiveConfig({
          name: ConfigTypes.UNAUTHORIZED_CONFIG,
          header: `Привязка к${'\u00A0'}договору Планеты`,
          content: `Для привязки к профилю Игровой платформы авторизуйтесь с${'\u00A0'}вашим договором Планеты.`,
          mainButton: 'Войти',
        });
        break;
      case ConfigTypes.CANCEL_CONFIG:
        setActiveConfig({
          name: ConfigTypes.CANCEL_CONFIG,
          type: 'failure',
          header: 'Вы отказались от привязки профиля к договору Планеты',
          content:
            'Вы будете перенаправлены обратно на сайт Игровой платформы. Если этого не произошло, нажмите на ссылку ниже.',
          link: {
            name: 'Вернуться на igrovoy.rt.ru',
            href: gameParams.redirectUri,
          },
        });
        break;
      case ConfigTypes.SUSPENDED_CONFIG:
        setActiveConfig({
          name: ConfigTypes.SUSPENDED_CONFIG,
          type: 'failure',
          header: `Вы${'\u00A0'}не${'\u00A0'}можете привязать профиль, пока договор не${'\u00A0'}обслуживается`,
          content: `Обслуживание вашего договора ${removeLeadingZero(
            auth.contractName,
          )}${'\u00A0'}в настоящий момент приостановлено. Подробное описание вы${'\u00A0'}найдёте в${'\u00A0'}разделе «Моя Планета».`,
          link: {
            name: 'Перейти в раздел «Моя Планета»',
            href: DEFAULT_LK_LINK,
          },
        });
        break;
      case ConfigTypes.SUCCESS_CONFIG:
        setActiveConfig({
          name: ConfigTypes.SUCCESS_CONFIG,
          type: 'success',
          header: 'Подтверждение получено',
          content: `Получено подтверждение для привязки договора ${removeLeadingZero(
            auth.contractName,
          )} к профилю Игровой платформы. Для завершения привязки вы будете перенаправлены обратно на сайт Игровой платформы. Если этого не произошло, нажмите на ссылку ниже.`,
          link: {
            name: `Вернуться на${'\u00A0'}igrovoy.rt.ru`,
            href: gameParams.redirectUri,
          },
        });
        break;
      case ConfigTypes.LINKED_CONFIG:
        setActiveConfig({
          name: ConfigTypes.LINKED_CONFIG,
          type: 'failure',
          header: `К${'\u00A0'}этому договору уже привязан профиль Игровой платформы`,
          content: 'Для привязки профиля выберите другой договор.',
          link: {
            name: 'Войти в другой договор',
          },
        });
        break;
      case ConfigTypes.FAILURE_CONFIG:
        setActiveConfig({
          name: ConfigTypes.FAILURE_CONFIG,
          type: 'failure',
          header: 'Ошибка привязки договора',
          content:
            'При привязке договора произошла ошибка. Пожалуйста, повторите попытку позже.',
          link: {
            name: `Вернуться на${'\u00A0'}igrovoy.rt.ru`,
            href: gameParams.redirectUri,
          },
        });
        break;
      default:
        setActiveConfig(null);
    }
  };

  useEffect(() => {
    if (!router.isReady) return;
    setGameParams({
      redirectUri: router.query.redirect_uri as string,
    });
  }, [router.isReady]);

  useEffect(() => {
    if (!isAuth) {
      toggleVisible();
      configCreator(ConfigTypes.UNAUTHORIZED_CONFIG);
      return;
    }

    (async () => {
      try {
        const isLinked = await checkGameAccountBind();
        if (isLinked) {
          configCreator(ConfigTypes.LINKED_CONFIG);
          return;
        }
      } catch (e) {
        console.error(e);
        configCreator(ConfigTypes.FAILURE_CONFIG);
        return;
      }

      if (contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT) {
        configCreator(ConfigTypes.SUSPENDED_CONFIG);
        return;
      }

      configCreator(ConfigTypes.APPROVE_CONFIG);
    })();
  }, [auth.contractId]);

  const [activeConfig, setActiveConfig] = useState<ResultConfig | MainConfig>(
    undefined,
  );
  const [gameParams, setGameParams] = useState<GameProps>(null);
  return (
    <StyledIgrovoy>
      {isMainConfig(activeConfig) && (
        <Main
          activeConfig={activeConfig}
          configCreator={configCreator}
          setGameParams={setGameParams}
          gameParams={gameParams}
        />
      )}
      {isResultConfig(activeConfig) && (
        <Result
          activeConfig={activeConfig}
          redirectUri={gameParams.redirectUri}
        />
      )}
    </StyledIgrovoy>
  );
};

export default observer(Igrovoy);
