/* Библиотеки */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
/** компоненты библиотеки */
import { defaultTheme, SidePage } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { ANIMATION_DURATION } from './constants';

export const StyledSoftlines = styled.div<{ isFewSubscriptions: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${defaultTheme.colors.white};
  padding: ${({ isFewSubscriptions }) =>
    isFewSubscriptions ? '32px' : '32px 32px 0 32px'};

  .pab2cDevices {
    &__content {
      display: flex;
      position: relative;
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;

    .pab2cDevices__content {
      width: 100%;
    }
  }
`;

export const StyledSoftlinesCardGroup = styled.ul`
  display: flex;
  justify-content: flex-start;
  background: ${defaultTheme.colors.white};
  list-style: none;
`;

export const StyledSoftlinesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > h3 {
    margin-bottom: 16px;
  }

  button {
    margin-bottom: 16px;
    width: 100%;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    > h3 {
      margin-bottom: 24px;
    }
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSidePage = styled(SidePage)<{ $isHideHeader?: boolean }>`
  ${({ $isHideHeader }) =>
    $isHideHeader &&
    css`
      .sidepage__header {
        visibility: hidden;
      }
    `}
`;

export const StyledSoftlinesList = styled.div<{
  animationName?: 'right' | 'left';
  withBanner?: boolean;
  xPosition?: number;
  isBusinessProduct: boolean;
}>`
  ${({ animationName, withBanner, xPosition, isBusinessProduct }) => `
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    ${
      animationName &&
      `animation: ${animationName} ${ANIMATION_DURATION}ms ease-in-out`
    };
    left: ${xPosition}px;
    transition: left .5s;;

    li {
      margin-bottom: 24px;
    }
  
    @media (min-width: ${desktop940}px) {
      ${withBanner && !isBusinessProduct && 'width: calc(100% - 330px)'};
      flex-direction: row;
      
      > div {
        > div:nth-of-type(2) {
          top: -47px;
        }
      }
      
      li {
        margin-right: 32px;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
      }
  
      li:nth-of-type(3n + 3) {
        margin-right: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
      }
    }
    
     @media (min-width: ${desktop1100}px) {
       ${withBanner && !isBusinessProduct && 'width: calc(100% - 355px)'};
     }
     
     @media (min-width: ${desktop1280}px) {
       ${withBanner && !isBusinessProduct && 'width: calc(100% - 400px)'};
     }
  `}
`;
