/** Библиотеки */
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

/** Типы */
import { MenuInfo } from '~/components/Feed/FeedPage.types';

/** Компоненты */
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

/** Константы */
import { desktop940 } from '~/components/Grid/constants';

/** интерфейс меню popup */
interface MenuPopupProps {
  toggleHandler: () => void;
  open: boolean;
  openCitiesHandler: () => void;
  closeCitiesHandler: () => void;
  isCitiesOpen: boolean;
  menu: MenuInfo;
}

/**
 * Блок popup с развёрнутым меню
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=397992795
 * @returns {React.FC}
 */
const MenuPopup: React.FC<MenuPopupProps> = ({
  toggleHandler,
  open,
  openCitiesHandler,
  closeCitiesHandler,
  isCitiesOpen,
  menu,
}: MenuPopupProps) => {
  // Проверка размера экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <>
      {isDesktop940 ? (
        <MenuDesktop toggleHandler={toggleHandler} open={open} menu={menu} />
      ) : (
        <MenuMobile
          toggleHandler={toggleHandler}
          open={open}
          openCitiesHandler={openCitiesHandler}
          closeCitiesHandler={closeCitiesHandler}
          isCitiesOpen={isCitiesOpen}
          menu={menu}
        />
      )}
    </>
  );
};

export default MenuPopup;
