import styled from '@emotion/styled';
import { defaultTheme, Link } from 'cordis-core-ui-planeta';

export const WarningRoot = styled.div`
  background-color: ${defaultTheme.colors.backgroundWarning};
  padding: 16px;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: ${defaultTheme.colors.planeta} !important;
`;
