/** библиотеки */
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** стили */
import { StyledSoftlineSubscribeWizard } from './style';
/** компоненты библиотеки */
import {
  defaultTheme,
  H3,
  PriceTag,
  PriceTagBackgroundColor,
  Tabs,
  TabsStyleTypes,
  Text,
} from 'cordis-core-ui-planeta';
/** константы */
import { desktop450 } from '~/components/Grid/constants';
import { TABS } from '../DescriptionOfSoftlineSubscription/constants';
/** hooks */
import { useSoftline } from '../hooks/SoftlineHook';
import { useBindData } from '../hooks/BindDataHook';
/** api */
import { getSubscriptionBindAgreement } from '~/api/api';
/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { pluralizeAll } from '~/utils/utils';
/** stores */
import useSoftlineStateModelStore from '../stores/useSoftlineStateStore';

/**
 * Сайдпейдж с подключением подписки
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 */
const SoftlineSubscribeWizard = (): JSX.Element => {
  const { softlineDescription } = useSoftlineStateModelStore();
  const {
    isAnnual,
    isPromo,
    tabs,
    activeTab,
    onChangeTab,
    formattedPrice,
    formattedPricePerMonth,
  } = useSoftline();
  const { backgroundColor, fontColor, subscriptionText } = useBindData();

  const { metricId, trialInfo, chatlingCashback } = softlineDescription;

  // Вычисление ширины экрана
  const isMinDesktop450 = useMediaQuery({
    query: `(min-width: ${desktop450}px)`,
  });

  /** Минимальный срок заказа */
  const isMinimumOrderTime = !isAnnual && !isPromo;

  /** Текст соглашения */
  const [agreement, setAgreement]: HooksTyping<string> = useState<string>(null);

  /** Получает текст соглашения */
  const getAgreement = async () => {
    try {
      const res = await getSubscriptionBindAgreement(metricId);
      setAgreement(res);
    } catch (e) {
      console.error('getSubscriptionBindAgreement', e);
    }
  };
  useEffect(() => {
    getAgreement();
  }, []);

  /** Ширина priceTag */
  const priceTagWidth = isMinDesktop450 ? '289px' : 'auto';

  return (
    <StyledSoftlineSubscribeWizard>
      <div className="subscribe-wizard__is-minimum-order-time-wrapper">
        <div className="subscribe-wizard__price-block">
          <Text
            className="subscribe-wizard__price-block__subscription-cost-text"
            lineHeight="24px"
          >
            Стоимость подписки {isAnnual ? 'за год' : ''}
          </Text>
          {isAnnual ? (
            <div className="subscribe-wizard__price-block__price-tag">
              <PriceTag
                header=""
                value={`${formattedPrice} ₽`}
                backgroundColor={backgroundColor}
                fontColor={fontColor}
                subscription={subscriptionText}
                width={
                  formattedPrice.length < 6 && !chatlingCashback
                    ? priceTagWidth
                    : 'auto'
                }
                headerType={isMinDesktop450 ? 'H2' : 'H3'}
              />
            </div>
          ) : (
            <>
              <Tabs
                value={tabs}
                styleType={TabsStyleTypes.SECONDARY}
                onChange={onChangeTab}
                activeTabIndex={activeTab.index}
              />
              {isPromo ? (
                <div className="subscribe-wizard__price-block__price-tag">
                  <PriceTag
                    header=""
                    value="0 ₽"
                    subscription={`Промопериод${'\u000A'}${pluralizeAll(
                      trialInfo?.trialDays,
                      ['день', 'дня', 'дней'],
                    )}`}
                    backgroundColor={PriceTagBackgroundColor.OK}
                    width={priceTagWidth}
                    headerType={isMinDesktop450 ? 'H2' : 'H3'}
                  />
                  <Text
                    className="subscribe-wizard__price-block__promo-price__caption"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    После промопериода —{' '}
                    {activeTab.value === TABS.DAY
                      ? `${formattedPrice} ₽ в день`
                      : `${formattedPricePerMonth} ₽ в месяц`}
                  </Text>
                </div>
              ) : (
                <>
                  {activeTab.value === TABS.DAY && (
                    <div className="subscribe-wizard__price-block__price-tag">
                      <PriceTag
                        header=""
                        value={`${formattedPrice} ₽`}
                        backgroundColor={backgroundColor}
                        fontColor={fontColor}
                        subscription={subscriptionText}
                        width={
                          formattedPrice.length < 6 && !chatlingCashback
                            ? priceTagWidth
                            : 'auto'
                        }
                        headerType={isMinDesktop450 ? 'H2' : 'H3'}
                      />
                    </div>
                  )}
                  {activeTab.value === TABS.MONTH && (
                    <div className="subscribe-wizard__price-block__price-tag">
                      <PriceTag
                        header=""
                        value={`${formattedPricePerMonth} ₽`}
                        backgroundColor={backgroundColor}
                        fontColor={fontColor}
                        subscription={subscriptionText}
                        width={
                          formattedPrice.length < 6 && !chatlingCashback
                            ? priceTagWidth
                            : 'auto'
                        }
                        headerType={isMinDesktop450 ? 'H2' : 'H3'}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {isMinimumOrderTime && (
          <div className="subscribe-wizard__is-minimum-order-time-wrapper__block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Минимальный срок подписки
            </Text>
            <H3>30 дней</H3>
          </div>
        )}
      </div>
      {agreement && (
        <div className="subscribe-wizard__agreement">
          <Text
            lineHeight="24px"
            dangerouslySetInnerHTML={{ __html: agreement }}
          />
        </div>
      )}
    </StyledSoftlineSubscribeWizard>
  );
};

export default observer(SoftlineSubscribeWizard);
