/** библиотеки */
import React from 'react';
import { observer } from 'mobx-react';
/** стили */
import { StyledSoftlineUnsubscribeSuccess } from './style';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
/** компоненты библиотеки */
import {
  defaultTheme,
  LeadingText,
  H2,
  Text,
  Icon,
  Icons,
} from 'cordis-core-ui-planeta';
/** store */
import useSoftlineStateModelStore from '../../stores/useSoftlineStateStore';

/**
 * Сайдпейдж с успешным отказом от подписки
 */
const SoftlineUnsubscribeWizaedSuccess = (): JSX.Element => {
  const { softlineDescription } = useSoftlineStateModelStore();
  const {
    pendingUnbindDate,
    trialInfo,
    name,
    availableUnbindDt,
  } = softlineDescription;

  const formattedPendingUnbindDate =
    pendingUnbindDate &&
    format(parseISO(pendingUnbindDate), 'd MMMM Y', {
      locale: ru,
    });

  const formattedTrialEndDate =
    trialInfo.trialEndDate &&
    format(parseISO(trialInfo.trialEndDate), 'd MMMM Y', {
      locale: ru,
    });

  /** Дата, с которой возможно отключение, т.е. дата окончания МСП (минимального срока действия подписки) */
  const formattedavailableUnbindDate =
    availableUnbindDt &&
    format(parseISO(availableUnbindDt), 'd MMMM Y', {
      locale: ru,
    });

  const dateOutput = (): string => {
    // если запланировано отключение
    if (formattedPendingUnbindDate) return formattedPendingUnbindDate;
    // если промо-период
    if (trialInfo?.isEnabled ?? trialInfo?.trialDays > 0)
      return formattedTrialEndDate;
    // если период МСП
    if (formattedavailableUnbindDate) return formattedavailableUnbindDate;
    return '';
  };

  return (
    <StyledSoftlineUnsubscribeSuccess>
      <div className="unsubscribe__content-icon">
        <Icon icon={<Icons.SuccessIconAnimated />} />
      </div>
      <H2
        dangerouslySetInnerHTML={{
          __html: `Подписка ${name} будет отключена`,
        }}
      />
      <Text color={defaultTheme.colors.shadow} lineHeight="24px">
        Отключение произойдёт
      </Text>
      <div>
        <LeadingText color={defaultTheme.colors.black}>
          <strong>{dateOutput()}</strong>
        </LeadingText>
      </div>
    </StyledSoftlineUnsubscribeSuccess>
  );
};

export default observer(SoftlineUnsubscribeWizaedSuccess);
