/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
/** components */
import Summary from '../../../Summary/Summary';
/** interfaces */
import { ActionType, SummaryFields } from '../../../Summary/Summary.types';
/** stores */
import useProductSwitcherStore from '../../store/useProductSwitcherStore';

const SingleSummary: FC = () => {
  const {
    extendedSummaryData,
    marketingGroupsProducts,
    defaultImgs,
  } = useProductSwitcherStore();

  const isPrivateTariff = (seriesCodeProp): boolean => {
    return !marketingGroupsProducts.includes(seriesCodeProp);
  };

  return (
    <div className="product-switcher__single-summary">
      <Summary
        content={{
          fields: (extendedSummaryData as unknown) as SummaryFields &
            ActionType,
          defaultImgs,
        }}
        isPrivate={isPrivateTariff(extendedSummaryData.seriesCode)}
      />
    </div>
  );
};

export default observer(SingleSummary);
