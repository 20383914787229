export const DEFAULT_RESULT = {
  isResult: false,
  isCorrect: false,
};

/** сообщения об ошибках ввода */
export enum ERROR_MESSAGE {
  EMPTY_PHONE = 'Введите корректный номер телефона',
}

export enum WIZARD_STATE {
  PHONE,
  CODE_SEND,
  CODE_VALIDATE,
  RESULT,
}
