import styled from '@emotion/styled';

/** константы */
import { desktop285, desktop420 } from '~/components/Grid/constants';

export const BlockedEntranceContainer = styled.div`
  ${() => `
    display: flex;
    flex-direction: column;
    margin-top: 45px;

    .blockedLeadText {
      margin: 24px 0 16px 0;
    }

    .blockedText {
      max-width: 600px;
    }

    @media (min-width: ${desktop285}px) {
      margin-top: 10px;
    }

    @media (min-width: ${desktop420}px) {
      margin-top: -20px;
    }
  `}
`;
