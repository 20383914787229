/** libraries */
import { types, Instance, flow } from 'mobx-state-tree';
import { banksListImageConverter } from '~/components/Blocks/Templates/Payment/utils';
import createApiPathModel from '~/stores/models/createApiPathModel';

const RequestsStateModel = types.model('State', {
  banksListImageConverter: createApiPathModel('banksListImageConverter'),
});

const BanksPropsModel = types.model({
  bankName: types.identifier,
  logo: types.string,
  schema: types.string,
  width: types.maybe(types.number),
  height: types.maybe(types.number),
  sort: types.maybe(types.number),
});

const ListOfSbpBanksModel = types
  .model({
    requestsState: RequestsStateModel,
    isListOfSbpBanksOpen: types.boolean,
    convertedSbpBanksList: types.array(BanksPropsModel),
    searchValue: types.string,
  })
  .views((self) => ({
    /** Отфильтрованный по поиску список банков */
    get filteredBanksList() {
      if (!self.searchValue) return self.convertedSbpBanksList;
      const filteredBanks = self.convertedSbpBanksList.filter((item) => {
        return item.bankName
          .trim()
          .toLowerCase()
          .includes(self.searchValue.trim().toLowerCase());
      });
      return filteredBanks;
    },
    get isLoading() {
      return self.requestsState.banksListImageConverter.isLoading;
    },
    get isError() {
      return self.requestsState.banksListImageConverter.hasError;
    },
  }))
  .actions((self) => {
    return {
      setIsListOfSbpBanksOpen: (isOpen: boolean) => {
        self.isListOfSbpBanksOpen = isOpen;
      },
      setSearchValue: (text: string) => {
        self.searchValue = text;
      },
      getConvertedSbpBanksList: flow(function* (sbpBanks) {
        self.requestsState.banksListImageConverter.reset();
        self.requestsState.banksListImageConverter.setLoading();
        try {
          const res = yield banksListImageConverter(sbpBanks);
          self.convertedSbpBanksList = res;
          self.requestsState.banksListImageConverter.setSuccess();
        } catch (e) {
          self.requestsState.banksListImageConverter.setFail();
          console.error('getConvertedSBPBanksList', e);
        }
      }),
      resetStore: () => {
        self.isListOfSbpBanksOpen = false;
        self.searchValue = '';
      },
    };
  });

export default ListOfSbpBanksModel;
export type IListOfSbpBanksStore = Instance<typeof ListOfSbpBanksModel>;
