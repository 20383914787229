/** libraries */
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import AnnualProductRenewalWizard from '~/components/Blocks/Shared/AnnualProductRenewalWizard/AnnualProductRenewalWizard';
import AnnualProductRenewalWizardFooter from '~/components/Blocks/Shared/AnnualProductRenewalWizard/AnnualProductRenewalWizardFooter';
/** stores */
import useAnnualProductRenewalStore from '~/components/Blocks/Shared/AnnualProductRenewalWizard/store/useAnnualProductRenewalStore';

const ProlongationSP = (): JSX.Element => {
  const {
    isAnnualProductRenewal,
    seriesName,
    result,
    onCloseSP,
  } = useAnnualProductRenewalStore();
  return (
    <SidePage
      show={isAnnualProductRenewal}
      width="832px"
      headerText={!result.isResult && `Продление продукта ${seriesName}`}
      onCloseClick={onCloseSP}
      footerContainer={!result.isResult && <AnnualProductRenewalWizardFooter />}
      isOnlyMobileFooter
      removeScrollBar
    >
      <AnnualProductRenewalWizard />
    </SidePage>
  );
};

export default observer(ProlongationSP);
