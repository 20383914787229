import {
  DAYS_DECLENSION,
  MONTH_DECLENSION,
  YEARS_DECLENSION,
} from '~/components/Blocks/Templates/ChatlesExp/constants';
import { pluralizeAll } from '~/utils/utils';
import { REWARD } from '~/components/Blocks/Templates/ChatlesExp/config';
import { ResultsInfoProps } from '~/components/Blocks/Templates/ChatlesExp/interfaces';

// Возвращает период с момента начала обслуживания в тестовом виде и возможное вознаграждение
export const getResultsInfo = (amount: number): ResultsInfoProps => {
  const amountOfMonth = amount * 12;
  // Количество лет
  const years = Math.floor(amount);
  // Количество месяцев
  const month =
    years > 0
      ? Math.floor(amountOfMonth - years * 12)
      : Math.floor(amountOfMonth);
  // Количество дней
  const days = Math.floor(amount * 365);

  // Период
  let duration;

  switch (true) {
    case years > 10:
      duration = pluralizeAll(years, YEARS_DECLENSION);
      break;
    case years >= 1:
      duration = month
        ? `${pluralizeAll(years, YEARS_DECLENSION)} и ${pluralizeAll(
            month,
            MONTH_DECLENSION,
          )}`
        : `${pluralizeAll(years, YEARS_DECLENSION)}`;
      break;
    case amountOfMonth >= 1:
      duration = pluralizeAll(month, MONTH_DECLENSION);
      break;
    default:
      duration = pluralizeAll(days, DAYS_DECLENSION);
      break;
  }

  // Вознаграждение
  let chatles;

  switch (true) {
    case years >= 10:
      chatles = REWARD.more10Years;
      break;
    case years >= 7:
      chatles = REWARD.from7to9Years;
      break;
    case years >= 4:
      chatles = REWARD.from4to6Years;
      break;
    case years >= 1:
      chatles = REWARD.from1to3Years;
      break;
    default:
      chatles = REWARD.less1Year;
      break;
  }

  return { duration, chatles };
};
