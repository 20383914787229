/** libraries */
import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Loader, Text } from 'cordis-core-ui-planeta';
import { nanoid } from 'nanoid';
/** styles */
import { StyledSuspensionPaymentsList } from './style';
/** constants */
import { DEVICE_TYPES } from '../../../Devices/Components/DeviceReturnWizard/constants';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface SuspensionPaymentsListProps {
  linePriceOff: number;
}

/** Список платежей, взимаемых после приостановки обслуживания */
const SuspensionPaymentsList: FC<SuspensionPaymentsListProps> = ({
  linePriceOff,
}: SuspensionPaymentsListProps) => {
  const {
    pab2cDevicesStore: { pab2cDevicesList, isLoading },
    pab2cVoiceStore: {
      phonesMaintainPrice,
      numberOfPhones,
      isLoading: isPhonesLoading,
    },
    summaryDataStore: { summaryData },
  } = useRootStore();

  const phonesPrice = phonesMaintainPrice(summaryData.phonesCount);

  /** Плата за пользование оборудованием */
  const priceForDevicesList = useMemo(() => {
    if (!pab2cDevicesList.length) return <></>;
    return pab2cDevicesList.map((item, index) => {
      if (!item.priceOff) return null;
      return (
        <li key={nanoid(index)}>
          {item.deviceTypeCode === DEVICE_TYPES.Router
            ? 'Маршрутизатора'
            : 'Телеприставки'}{' '}
          —{' '}
          <Text lineHeight="24px" fontWeightBold>
            {item.priceOff} ₽ в день
          </Text>
        </li>
      );
    });
  }, [pab2cDevicesList]);

  return (
    <StyledSuspensionPaymentsList>
      {isPhonesLoading || isLoading ? (
        <Loader small />
      ) : (
        <Text className="payment__list" lineHeight="24px">
          Продолжает взиматься плата за использование:
          <ul>
            <li>
              Абонентской Линии Связи —{' '}
              <Text lineHeight="24px" fontWeightBold>
                {linePriceOff} ₽ в день
              </Text>
            </li>
            {priceForDevicesList}
            {!!phonesPrice && (
              <li>
                Местного телефонного номера ({numberOfPhones} шт.) —{' '}
                <Text lineHeight="24px" fontWeightBold>
                  {formatNumber(phonesPrice)} ₽ в день
                </Text>
              </li>
            )}
          </ul>
        </Text>
      )}
    </StyledSuspensionPaymentsList>
  );
};

export default observer(SuspensionPaymentsList);
