/* Библиотеки */
import style from '@emotion/styled';
/* Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';

/* Стилизованная часть левой колонки строки с подключением на год */
export const StyledLeftColumn = style.div`
  ${() => `
      display: none;
      flex-direction: column;
      white-space: pre-line;
      min-width: 35%;
      h3 {
        margin-bottom: 0;
      }
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: flex;
      }
    `}
`;

/* Стилизованная часть правой колонки строки со скоростью интернета */
export const StyledRightColumn = style.div`
  ${() => `
      display: flex;
      max-width: 250px;
      align-items: flex-start;
      flex-direction: column;
      span {
        white-space: pre-line;
      }
      svg {
        margin-right: 12px;
      }
      h3 {
        margin: 0 0 36px 0;
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          display: none;
        }
      }
      div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
         @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          align-items: flex-start;
        }
      }
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        flex-direction: row;
      }
    `}
`;
