/** библиотеки */
import * as React from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

/** компоненты библиотеки */
import { H1, H3, Text } from 'cordis-core-ui-planeta';

/** типы */
import { HeaderDescriptionProps } from './HeaderDescription.types';

/** размеры экрана */
import { desktop500 } from '~/components/Grid/constants';

const StyledHeader = styled.div`
  margin-bottom: 16px;
  .header {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

/**
 * компонент Заголовок страницы
 * @param content
 */
const HeaderDescription: React.FC<HeaderDescriptionProps> = ({
  content,
}: HeaderDescriptionProps) => {
  const { fields } = content;

  /** Вычисление ширины экрана */
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  return (
    <StyledHeader>
      {isDesktop500 ? (
        <H1 className="header">{fields.header}</H1>
      ) : (
        <H3 className="header">{fields.header}</H3>
      )}
      {fields.description && (
        <Text className="description">{fields.description}</Text>
      )}
    </StyledHeader>
  );
};

export default HeaderDescription;
