import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';

const banks = css`
  .banks {
    &__container {
      margin-top: 24px;
      height: 400px;
      overflow-x: hidden;
      &__list {
        display: flex;
        flex-direction: column;
        &__bank {
          display: flex;
          flex-direction: row;
          align-items: center;

          &__logo {
            height: 40px;
            padding: 4px 8px;
          }
        }
      }
    }
  }
`;

export const StyledListOfSbpBanks = styled.div`
  .popup {
    &__container {
      > div:first-of-type {
        padding: 24px 80px 24px 24px;
      }

      &__content {
        width: 784px;
        margin: auto;
        padding: 24px 0 !important;
        border-top: 1px solid ${defaultTheme.colors.disable};
      }
    }

    &__header {
      &__close {
        top: 24px;
      }
    }
  }

  .loader,
  .error {
    height: 450px;
  }

  ${banks}
`;

export const StyledListOfSbpBanksMobile = styled.div`
  ${banks}

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
