/** utils */
import { isExternal, getDevicesFeatures } from '~/utils/utils';
/** api */
import { getDevices, getFeedBySlug } from '~/api/api';
import { DEVICES } from '~/constants/common';

/** типы */
import { Block } from '~/interfaces/BlockInterface';
import { DevicesContent, ImageSizeData } from '../../Devices/interfaces';

export const getImageInfo = (imageSize: ImageSizeData): ImageSizeData => {
  if (!imageSize?.url) return imageSize;
  const url = isExternal(imageSize.url)
    ? imageSize.url
    : `${process.env.STATIC_SERVER}${imageSize.url}`;
  return { ...imageSize, url };
};

/**
 * возвращает блок device дополненный полями
 * @param {Block} block
 */
export const getDataForPab2cDevices = async (
  block: Block,
  preview?: boolean,
): Promise<DevicesContent> => {
  const devices = await getDevices(false);
  const devicesFeatures = await getFeedBySlug(DEVICES, preview);
  const devicesBlock = devicesFeatures.blocks.find(
    (item) => item.template === DEVICES,
  );
  const devicesFields = {
    ...devicesBlock.fields,
    ...devices,
  };
  const deviceFeaturesBlockDevice = devicesFeatures.blocks.find(
    (item) => item.template === DEVICES,
  );
  const devicesWithFeatures = await getDevicesFeatures(
    devicesFields,
    deviceFeaturesBlockDevice.fields.features,
  );

  const fields = { ...block.fields, allDevices: devicesWithFeatures };
  return {
    ...block,
    fields,
  };
};
