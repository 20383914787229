/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const StyledAnyPayments = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background: ${defaultTheme.colors.white};
  padding: 32px;

  h2,
  h3 {
    margin-bottom: 16px;
  }

  .mainText {
    max-width: 500px;
    margin-bottom: 16px;
  }

  .sidepage {
    &__container {
      height: 100% !important;
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
  }
`;
