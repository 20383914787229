/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop650,
  desktop750,
  desktop800,
  desktop940,
} from '~/components/Grid/constants';
import {
  StyledRowContent,
  StyledRowContentBody,
  StyledRowContentTitle,
} from '../../common/RowContent/styles';
import { ChannelCardStyled } from '../../Cards/ChannelCard/styles';

export const StyledChannelDescription = styled.div`
  padding: 32px !important;
  .channel-description-card {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      &__logo-block {
        display: flex;
        flex-direction: column;
        &__logo {
          width: 130px;
          height: 60px;
          border: 1px solid ${defaultTheme.colors.disable};
          margin-right: 24px;

          > div {
            display: block !important;
          }
        }

        &__name-block {
          display: flex;
          flex-direction: column;
          margin: 24px 0 0 0;
          h3 {
            margin-bottom: 4px;
          }

          &__tag-block {
            display: flex;
            align-items: center;
            margin-top: 6px;
            > div[data-test-tag] {
              display: flex;
              line-height: 0;

              > div {
                padding: 1px 8px;

                > span {
                  letter-spacing: -0.03em;
                }
              }
            }
            > span {
              margin-left: 8px;
            }
          }
        }
      }

      &__close-icon {
        cursor: pointer;
      }
    }

    &__subscription-cost {
      &__rejection {
        margin-top: 8px;
        width: 174px;
      }
    }

    &__transformer {
      &__button-block {
        margin-top: 32px;

        .scale {
          margin: 0 0 7px;
        }

        &__refusal-block {
          display: flex;
          flex-direction: column;
          margin-top: 16px;

          > span {
            margin-top: 8px;
          }
          button {
            height: 54px;
          }
        }
      }
    }

    &__connection-options {
      ${ChannelCardStyled} {
        cursor: default;
      }
      ${StyledRowContent} {
        padding-bottom: 8px;
      }
      ${StyledRowContentBody} {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 1fr;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 36px;
        width: 100%;
        padding-right: 2px;
      }

      ${StyledRowContentBody}::-webkit-scrollbar {
        width: 5px;
      }

      ${StyledRowContentBody}::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 10px;
      }

      ${StyledRowContentBody}::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${defaultTheme.colors.lightGrey};
      }
    }

    &__platforms {
      &__digital-tv {
        display: flex;
        flex-direction: column;

        &__name-block {
          display: flex;
          flex-direction: column;
          margin: 12px 0 0 0;
        }

        &__link {
          cursor: pointer;
        }
      }
      &__block {
        display: flex;
        flex-direction: column;
        margin-top: 34px;
      }
      &__wink {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        > span {
          width: 166px;
          margin-top: 6px;
          span {
            margin-right: 4px;
            cursor: pointer;
          }
        }
      }

      &__smart-tv {
        margin-bottom: 16px;
      }

      &__set-top-box {
        margin-bottom: 16px;
      }

      &__apple-tv {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        > span {
          width: 210px;
          margin-top: 10px;
          a {
            margin-left: 4px;
            color: ${defaultTheme.colors.planeta};
          }
        }
      }
    }

    &__properties {
      display: flex;
      flex-direction: column;

      &__first {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        > div {
          margin: 0 0 16px 0;
        }
      }
      &__second {
        display: flex;
        flex-direction: column;

        > div {
          margin: 0 0 16px 0;
        }
      }
      &__block {
        display: flex;
        flex-direction: column;

        > span:nth-of-type(2) {
          font-weight: 500;
          margin-top: 4px;

          svg {
            margin-right: 11px;
          }
        }
      }
      &__link {
        cursor: pointer;
        margin-top: 4px;
      }
      &__adult {
        width: 182px;
        margin-top: 4px;
        letter-spacing: -0.01em;

        > span {
          margin-left: 3px;
        }
      }
    }
  }

  @media (min-width: ${desktop500}px) {
    .channel-description-card {
      &__transformer {
        &__button-block {
          &__refusal-block {
            flex-direction: row;
            align-items: center;

            > span {
              margin: 0 0 0 16px;
            }
          }
        }
      }

      &__connection-options {
        ${StyledRowContentBody} {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }

      &__platforms {
        &__digital-tv {
          flex-direction: row;

          &__name-block {
            margin: auto 0 auto 16px;
          }
        }
        &__block {
          flex-direction: row;

          &__first {
            width: 210px;
          }
        }
      }

      &__properties {
        &__first {
          flex-direction: row;
          margin-bottom: 20px;
          > div {
            margin-bottom: 0;
          }

          > div:first-of-type {
            width: 210px;
          }
        }
        &__second {
          flex-direction: row;

          > div {
            margin-bottom: 0;
          }

          > div:first-of-type {
            width: 210px;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop650}px) {
    .channel-description-card {
      &__connection-options {
        ${StyledRowContentBody} {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
  }

  @media (min-width: ${desktop750}px) {
    .channel-description-card {
      &__connection-options {
        ${StyledRowContentBody} {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
      }
    }
  }

  @media (min-width: ${desktop800}px) {
    .channel-description-card {
      &__platforms {
        &__block {
          &__first {
            width: 296px;
          }
        }
      }

      &__properties {
        &__first {
          > div:first-of-type {
            width: 296px;
          }
        }
        &__second {
          > div:first-of-type {
            width: 296px;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    padding-bottom: 0;
    .channel-description-card {
      &__header {
        &__logo-block {
          flex-direction: row;
          &__name-block {
            margin: auto 0;
          }

          &__logo {
            width: 190px;
            height: 88px;
          }
        }
      }
      &__connection-options {
        ${StyledRowContentBody} {
          display: flex;
          gap: 24px;
          width: 671px;
          > div {
            width: 303px;

            .channel-package-card {
              width: 303px;
            }
            .channel-package-card__duplicate-wrapper {
              width: 303px;
              .channel-package-card__duplicate {
                width: 303px;
              }
            }
          }
        }
      }

      &__platforms {
        &__block {
          margin-top: 40px;

          &__first {
            width: 327px;
          }
        }
      }

      &__properties {
        &__first {
          > div:first-of-type {
            width: 327px;
          }
        }
        &__second {
          > div:first-of-type {
            width: 327px;
          }
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 170px;
      max-width: 170px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    .channel-description-card {
      &__connection-options {
        ${StyledRowContentBody} {
          width: 807px;
          > div {
            width: 239px;

            .channel-package-card {
              width: 239px;
            }
            .channel-package-card__duplicate-wrapper {
              width: 239px;
              .channel-package-card__duplicate {
                width: 239px;
              }
            }
          }
        }
      }

      &__platforms {
        &__block {
          &__first {
            width: 279px;
          }
        }
      }

      &__properties {
        &__first {
          > div:first-of-type {
            width: 279px;
          }
        }
        &__second {
          > div:first-of-type {
            width: 279px;
          }
        }
      }
    }

    ${StyledRowContentTitle} {
      min-width: 215px;
      max-width: 215px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    .channel-description-card {
      &__connection-options {
        ${StyledRowContentBody} {
          width: 940px;
          > div {
            width: 284px;

            .channel-package-card {
              width: 284px;
            }
            .channel-package-card__duplicate-wrapper {
              width: 284px;
              .channel-package-card__duplicate {
                width: 284px;
              }
            }
          }
        }
      }

      &__platforms {
        &__block {
          &__first {
            width: 324px;
          }
        }
      }

      &__properties {
        &__first {
          > div:first-of-type {
            width: 324px;
          }
        }
        &__second {
          > div:first-of-type {
            width: 324px;
          }
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 260px;
      max-width: 260px;
    }
  }
`;
