/** библиотеки */
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

/** стили */
import { StyledAnyPayments } from './AnyPayments.style';

/** типы */
import { AnyPaymentsProps } from './AnyPayments.types';

/** константы */
import { desktop1280 } from '~/components/Grid/constants';

/** библиотека */
import { H2, H3, LinkButton, SidePage, Text } from 'cordis-core-ui-planeta';

/** утилиты */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** компоненты */
import Portal from '~/components/Portal/Portal';

/**
 * Блок "Другие способы оплаты"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=595272498
 * @param content
 */
const AnyPayments: React.FC<AnyPaymentsProps> = ({
  content,
}: AnyPaymentsProps) => {
  const { mainText, sidePageText } = content.fields;

  /** Состояние sidePage */
  const [isSidePageOpen, setIsSidePageOpen] = useState(false);

  /** Вычисление ширины экрана */
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  return (
    <StyledAnyPayments>
      {isDesktop1280 ? <H2>Другие способы</H2> : <H3>Другие способы</H3>}
      <Text className="mainText" lineHeight="24px">
        {parseHtml(mainText)}
      </Text>
      <LinkButton onClick={() => setIsSidePageOpen(true)}>
        Смотреть все варианты
      </LinkButton>
      <Portal>
        <SidePage
          show={isSidePageOpen}
          headerText="Другие способы оплаты"
          onCloseClick={() => setIsSidePageOpen(false)}
        >
          {parseHtml(sidePageText)}
        </SidePage>
      </Portal>
    </StyledAnyPayments>
  );
};

export default AnyPayments;
