/** библиотеки */
import * as React from 'react';

/** утилиты */
import { SeriesCode } from '../Legal.const';

/** типы */
import { LegalInfo } from '../Legal.types';

/** интерфейс компонента-шаблона BaudRateTextProps */
interface BaudRateTextProps {
  content: LegalInfo;
}

const BaudRateText: React.FC<BaudRateTextProps> = ({
  content,
}: BaudRateTextProps) => {
  /**
   * Строка с информацией о ширине канала передачи данных
   * @returns {JSX.Element}
   */
  const Text = (): JSX.Element => (
    <span>
      ** Ширина канала передачи данных при подключении к сети оператора по
      технологии Оптический Гигабит (FTTH, стандарт Ethernet, 1000BASE-X) и по
      технологии Оптический Гигабит (FTTH, стандарт GPON) составляет 1000 Мбит/с
      до внешних ресурсов интернета, поэтому данные могут передаваться в 10 раз
      быстрее, чем при подключении к сети оператора с помощью витой пары (UTP,
      стандарт Ethernet, 100BASE-T).
    </span>
  );

  return content.seriesCode === SeriesCode.HIT_SUPER_2_0 ||
    content.seriesCode === SeriesCode.ONLINE_SUPER_2_0 ? (
    <p>{Text()}</p>
  ) : null;
};

export default BaudRateText;
