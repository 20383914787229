export const TABS_VARIANTS = ['Денежные расчёты', 'Операции с Чатлами'];

export enum MONTHS {
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
}

/** В предложном падеже */
export enum MONTHS_PREPOSITIONAL {
  'январе',
  'феврале',
  'марте',
  'апреле',
  'мае',
  'июне',
  'июле',
  'августе',
  'сентябре',
  'октябре',
  'ноябре',
  'декабре',
}

export enum MOBILE_SELECT_TYPE {
  BEGIN,
  END,
}
