/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** типы */
import {
  StyledAvatarProps,
  StyledBubbleProps,
} from '~/components/Blocks/Templates/Avatar/Avatar.types';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const StyledAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  background-color: transparent;
  position: relative;
  height: 100%;

  .head {
    font-weight: 700;
    line-height: 24px;
    display: block;
    font-size: 16px;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }

  .title {
    font-size: 22px;
    line-height: 27px;
    word-break: break-word;
  }

  .personal {
    display: flex;
    margin: 16px 0 0 0;
    &__avatar {
      padding: 0 8px 0 0;
    }
    &__body {
      padding: 0 0 0 8px;
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
  }
`;

export const StyledBubble = styled.div<StyledBubbleProps>`
  ${({ isQuote }): string => `
    border-radius: 50px;
    background-color: ${defaultTheme.colors.pinkSelect};
    padding: 32px;
    ${!isQuote && 'position: absolute'};
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    ${isQuote ? 'height: 100%; min-height: 400px;' : 'max-width: 400px;'}

    &:before {
      content: '';
      fill: ${defaultTheme.colors.pinkSelect};
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA0NiA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ2IDMyLjc0NDJDMzMuOTYwMiA0Mi4wMzMyIDIzLjAyMzIgNDMuNzgyOCAwIDQ0QzE3LjQyMjIgMzIuMTMzMiAyMS4yOTE5IDIyLjMyODIgMTkuMTY2NyAwTDQ2IDMyLjc0NDJaIiBmaWxsPSIjRTA0RTkwIi8+Cjwvc3ZnPgo=');
      background-position: 50% 50%;
      z-index: -1;
      width: 46px;
      height: 44px;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    @media (min-width: ${desktop940}px) {
      ${isQuote ? 'height: 400px;' : 'max-width: 400px;'}
    }
    `}
`;

export const StyledImg = styled.div<StyledAvatarProps>`
  ${({ img }): string =>
    `
    height: 400px;
    width: 100%;
    background: url(${process.env.STATIC_SERVER}/${img}) no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    max-width: 400px;
  `}
`;

export const StyledAvatarImg = styled.div<StyledAvatarProps>`
  ${({ img }): string =>
    `
    height: 70px;
    width: 70px;
    background: url(${process.env.STATIC_SERVER}/${img}) no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 100%;
  `}
`;
