/** libraries */
import { types, flow, Instance } from 'mobx-state-tree';
/** api */
import {
  authByTemporaryPassword,
  definePassword,
  sendCodeByCall,
  sendCodeBySMS,
} from '~/api/apiPab2c';
import createApiPathModel from '~/stores/models/createApiPathModel';
/** interfaces */
import { InputValue } from '~/components/Blocks/Templates/Pab2c/Settings/interfaces';
import { ResultPropsModel } from '~/components/Blocks/Templates/Pab2c/Settings/store/models';
/** constants */
import {
  DEFAULT_ERROR,
  initialPhoneNumber,
  SP_STATE,
} from '../../../Templates/Pab2c/Settings/constants';
import { BLOCK_CONTRACT_ERRORS } from '../constants';
import { DEFAULT_RESULT, PASSWORD_MAX_LENGTH } from '~/constants/common';

const RequestsStateModel = types.model('State', {
  sendingCode: createApiPathModel(
    'GET /Security/PasswordRecovery/SendCodeBySMS',
  ),
  sendingCodeByCall: createApiPathModel(
    'GET /Security/PasswordRecovery/SendCodeByCall',
  ),
  confirmCode: createApiPathModel('GET /Security/Auth/AuthByTemporaryPassword'),
  defineNewPassword: createApiPathModel(
    'GET /Security/PasswordRecovery/SetPassword',
  ),
});

const InputValueModel = types.model({
  forSend: types.string,
  value: types.string,
});

const CreatePasswordModel = types
  .model({
    requestsState: RequestsStateModel,
    isShowCreatePasswordWizard: types.boolean,
    /** Новый пароль */
    newPassword: types.string,
    /** Ошибка нового пароля */
    newPasswordError: types.string,
    /** Подтверждение нового пароля */
    newPasswordConfirmation: types.string,
    /** Ошибка подтверждения нового пароля */
    newPasswordConfirmationError: types.string,
    phoneNumber: InputValueModel,
    phoneNumberError: types.string,
    /** Номер договора */
    contractNumber: types.string,
    contractNumberError: types.string,
    /** Количество оставшихся попыток указать номер */
    remains: types.maybeNull(types.number),
    /** СМС код */
    code: types.string,
    codeError: types.string,
    /** Состояние сайдпейджа */
    state: types.enumeration(Object.values(SP_STATE)),
    /** Договор заблокирован */
    isBlockedContract: types.boolean,
    /** Время блокировки договора */
    blockedContractTime: types.maybeNull(types.number),
    token: types.maybeNull(types.string),
    /** Результат изменения пароля */
    result: ResultPropsModel,
  })
  .views((self) => ({
    /** Disable кнопки "Создать" */
    get disableCreate() {
      if (self.state === SP_STATE.PHONE) {
        return (
          !self.contractNumber.length ||
          self.contractNumber === '0' ||
          self.phoneNumber.value.length < 16
        );
      }

      if (self.state === SP_STATE.CODE) {
        return self.code.length < 6;
      }

      if (self.state === SP_STATE.NEW_PASSWORD) {
        const re = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
        const symbols = /[!@#$%^&*()_\-+=[{\]};:<>|.\/?]/;
        return (
          self.newPassword !== self.newPasswordConfirmation ||
          !re.test(self.newPassword) ||
          symbols.test(self.newPassword) ||
          self.newPassword.length > PASSWORD_MAX_LENGTH
        );
      }
      return true;
    },
  }))
  .actions((self) => {
    return {
      /** Отправка кода на номер телефона */
      sendingCode: flow(function* () {
        self.requestsState.sendingCode.reset();
        self.requestsState.sendingCode.setLoading();
        self.remains = null;
        self.codeError = '';
        try {
          yield sendCodeBySMS(
            self.contractNumber,
            self.phoneNumber.forSend.replace('+7', ''),
          );
          self.state = SP_STATE.CODE;
          self.remains = null;
          self.requestsState.sendingCode.setSuccess();
        } catch (e) {
          console.error(e, 'sendingCode');
          self.requestsState.sendingCode.setFail();
          const error = e?.errorMessage ? JSON.parse(e.errorMessage) : null;
          switch (error?.Type) {
            case BLOCK_CONTRACT_ERRORS.ExceededRecoveryAttempts:
              self.blockedContractTime = error.Extensions?.BanTime;
              self.isBlockedContract = true;
              break;
            case BLOCK_CONTRACT_ERRORS.MessageSending:
              self.contractNumberError = 'Превышен лимит отправки SMS';
              self.codeError = 'Превышен лимит отправки SMS';
              break;
            case BLOCK_CONTRACT_ERRORS.Password:
              self.contractNumberError = 'Неверный номер договора';
              break;
            case BLOCK_CONTRACT_ERRORS.BadPhone:
              self.phoneNumberError = 'Введенный номер не указан в договоре';
              self.remains = error?.Extensions?.Remains ?? null;
              break;
            case BLOCK_CONTRACT_ERRORS.BadTemporaryPassword:
              self.codeError = 'Код введен неверно';
              self.remains = error?.Extensions?.Remains ?? null;
              break;
            default:
              self.contractNumberError = DEFAULT_ERROR;
              if (self.state === SP_STATE.CODE) self.codeError = DEFAULT_ERROR;
          }
        }
      }),
      /** Отправляет запрос на диктовку временного кода по сотовому */
      sendingCodeByCall: flow(function* () {
        self.requestsState.sendingCodeByCall.reset();
        self.requestsState.sendingCodeByCall.setLoading();
        self.remains = null;
        self.codeError = '';
        try {
          yield sendCodeByCall(
            self.contractNumber,
            self.phoneNumber.forSend.replace('+7', ''),
          );
          self.requestsState.sendingCodeByCall.setSuccess();
        } catch (e) {
          console.error(e, 'sendingCodeByCall');
          self.requestsState.sendingCodeByCall.setFail();
          const error = JSON.parse(e.errorMessage);
          switch (error?.Type) {
            case BLOCK_CONTRACT_ERRORS.ExceededRecoveryAttempts:
              self.blockedContractTime = error.Extensions?.BanTime;
              self.isBlockedContract = true;
              break;
            case BLOCK_CONTRACT_ERRORS.DictationBlocked:
              self.codeError = 'Превышен лимит отправки голосовых сообщений';
              break;
            case BLOCK_CONTRACT_ERRORS.Password:
              self.contractNumberError = 'Неверный номер договора';
              break;
            case BLOCK_CONTRACT_ERRORS.BadPhone:
              self.phoneNumberError = 'Введенный номер не указан в договоре';
              self.remains = error?.Extensions?.Remains ?? null;
              break;
            case BLOCK_CONTRACT_ERRORS.BadTemporaryPassword:
              self.codeError = 'Код введен неверно';
              self.remains = error?.Extensions?.Remains ?? null;
              break;
            default:
              self.contractNumberError = DEFAULT_ERROR;
              if (self.state === SP_STATE.CODE) self.codeError = DEFAULT_ERROR;
          }
        }
      }),
      /** Подтверждение кода */
      confirmCode: flow(function* () {
        self.requestsState.confirmCode.reset();
        self.requestsState.confirmCode.setLoading();
        try {
          const res = yield authByTemporaryPassword(
            self.contractNumber,
            self.code,
          );
          if (res?.token) {
            self.state = SP_STATE.NEW_PASSWORD;
            self.token = res.token;
          }
          self.requestsState.confirmCode.setSuccess();
        } catch (e) {
          console.error(e, 'confirmCode');
          self.requestsState.confirmCode.setFail();
          const error = JSON.parse(e.errorMessage);
          switch (error.Type) {
            case BLOCK_CONTRACT_ERRORS.AuthCountException:
              self.codeError = 'Неверно указан код';
              self.remains = error.Extensions.Remains;
              break;
            case BLOCK_CONTRACT_ERRORS.BlockException:
              self.blockedContractTime = error.Extensions?.BanTime;
              self.isBlockedContract = true;
              break;
            case BLOCK_CONTRACT_ERRORS.ContractNotServicedException:
              self.codeError = 'Договор не обслуживается';
              break;
            default:
              self.codeError = DEFAULT_ERROR;
          }
        }
      }),
      /** Установка нового пароля */
      defineNewPassword: flow(function* () {
        if (!self.token) return;
        self.requestsState.defineNewPassword.reset();
        self.requestsState.defineNewPassword.setLoading();

        try {
          const res = yield definePassword(self.newPassword, self.token);
          if (res.status === 200) {
            self.result = {
              isResult: true,
              isCorrect: true,
            };
          } else {
            self.result = {
              isResult: true,
              isCorrect: false,
            };
          }
          self.requestsState.defineNewPassword.setSuccess();
        } catch (e) {
          console.error(e, 'defineNewPassword');
          self.requestsState.defineNewPassword.setFail();
          self.newPasswordError = DEFAULT_ERROR;
        }
      }),
      setIsShowCreatePasswordWizard: (isShow: boolean) => {
        self.isShowCreatePasswordWizard = isShow;
      },
      setNewPassword: (password: string) => {
        self.newPassword = password;
      },
      setNewPasswordError: (error: string) => {
        self.newPasswordError = error;
      },
      setNewPasswordConfirmation: (password: string) => {
        self.newPasswordConfirmation = password;
      },
      setNewPasswordConfirmationError: (error: string) => {
        self.newPasswordConfirmationError = error;
      },
      setPhoneNumber: (phone: InputValue) => {
        self.phoneNumber = phone;
      },
      setPhoneNumberError: (error: string) => {
        self.phoneNumberError = error;
      },
      setContractNumber: (contractNumber: string) => {
        self.contractNumber = contractNumber;
      },
      setContractNumberError: (error: string) => {
        self.contractNumberError = error;
      },
      setRemains: (counter: number) => {
        self.remains = counter;
      },
      setCode: (code: string) => {
        self.code = code;
      },
      setCodeError: (error: string) => {
        self.codeError = error;
      },
      setState: (state: SP_STATE) => {
        self.state = state;
      },
      resetState: (contractNumber: string) => {
        self.contractNumber = contractNumber !== '0' ? contractNumber : '';
        self.isShowCreatePasswordWizard = false;
        self.contractNumberError = '';
        self.phoneNumber = initialPhoneNumber;
        self.phoneNumberError = '';
        self.code = '';
        self.codeError = '';
        self.newPassword = '';
        self.newPasswordError = '';
        self.newPasswordConfirmation = '';
        self.newPasswordConfirmationError = '';
        self.remains = null;
        self.state = SP_STATE.PHONE;
        self.isBlockedContract = false;
        self.blockedContractTime = null;
        self.result = DEFAULT_RESULT;
      },
    };
  });

export default CreatePasswordModel;

export type ICreatePasswordModel = Instance<typeof CreatePasswordModel>;
