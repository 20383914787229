/** библиотеки */
import { useMediaQuery } from 'react-responsive';

/** стили */
import { StyledSberSMS } from './SberSMS.style';

/** константы */
import { desktop1280 } from '~/components/Grid/constants';

/** библиотека */
import { H2, H3, Text, defaultTheme } from 'cordis-core-ui-planeta';

/**
 * Блок «Оплата. Сбербанк. Через СМС»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=595379177
 */
const SberSMS = (): JSX.Element => {
  /** Вычисление ширины экрана */
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  return (
    <StyledSberSMS>
      {isDesktop1280 ? (
        <H2>Через SMS на номер 900</H2>
      ) : (
        <H3>Через SMS на номер 900</H3>
      )}
      <Text className="mainText" lineHeight="24px">
        Клиенты Сбербанка могут пополнить счёт, отправив SMS на&nbsp;номер 900 с
        текстом «Планета&nbsp;XXXXXX&nbsp;YYYY».
      </Text>
      <div className="background">
        <div className="greenBackground">
          {/* yaspeller ignore:start */}
          <Text
            className="greenText"
            color={defaultTheme.colors.white}
            lineHeight="24px"
          >
            Планета ХХХХХХ YYYY
          </Text>
          {/* yaspeller ignore:end */}
          <span className="greenImage" />
        </div>
        <span className="firstLine line" />
        <span className="secondLine line" />
        <Text className="contractNumber text" lineHeight="24px">
          Номер договора
        </Text>
        <Text className="amountOfPayment text" lineHeight="24px">
          Сумма платежа
        </Text>
      </div>
    </StyledSberSMS>
  );
};

export default SberSMS;
