/** библиотеки */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940, desktop1280 } from '~/components/Grid/constants';

export const StyledDescriptionOfSoftlineSubscription = styled.div`
  margin-top: -41px;

  .description {
    &__image-wrapper {
      margin: 42px 0 49px 0;
    }
    &__price-block {
      &__subscription-cost-text {
        margin-right: 8px;
      }

      &__price-tag {
        > div {
          margin-top: 4px;

          .header {
            display: none;
          }

          .colorContainer {
            width: auto;
          }
        }
      }

      &__promo-price {
        &__caption {
          margin-top: 8px;
        }
      }
    }

    &__auth-price-block {
      display: flex;
      flex-direction: column;

      &__switcher {
        margin-top: 34px;

        td:first-of-type {
          vertical-align: middle;
        }

        .text {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__distribution-information-block {
      margin: 32px 0;

      &__link-to-instructions {
        margin-bottom: 24px;
      }

      &__info-wrapper {
        margin-bottom: 24px;
        > span {
          margin-bottom: 4px;
        }
        > h3 {
          margin-bottom: 0;
        }
      }

      &__os-wrapper {
        display: flex;
        flex-direction: column;

        > span {
          margin-bottom: 8px;
        }

        &__upload {
          display: flex;
          flex-direction: column;

          .select-wrapper {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }
    }

    &__button-block-not-auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0;

      button {
        width: 100%;
      }

      &__text {
        width: 100%;
        margin-top: 16px;
      }
    }

    &__button-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0;

      button {
        width: 100%;
      }

      &__text {
        width: 100%;
        max-width: auto;
        margin-top: 16px;
      }
    }

    &__text-block {
      margin-bottom: 32px;
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: auto;

      &__block {
        max-width: 348px;
        margin: 0 24px 24px 0;

        &__header {
          margin-bottom: 12px;
        }

        &__li {
          margin-bottom: 8px;
          margin-left: 20px;

          span {
            display: block;
          }
        }
      }
    }

    &__inactive-subscription {
      margin: 32px 0;

      &__button-block {
        display: flex;
        flex-direction: column;

        button {
          width: 100%;
        }

        &__text {
          margin-top: 16px;
        }
      }
    }

    &__link-wrapper {
      > div {
        display: contents;
      }
    }

    &__loader {
      display: flex;
      justify-content: center;
      margin: 32px 0;
    }
  }

  @media (min-width: ${desktop940}px) {
    .description {
      &__auth-price-block {
        flex-direction: row;

        &__switcher {
          margin: 0 0 0 32px;
        }
      }

      &__button-block-not-auth {
        flex-direction: row;

        button {
          width: auto;
        }

        &__text {
          margin: 0 0 0 16px;
        }
      }

      &__distribution-information-block {
        &__os-wrapper {
          &__upload {
            flex-direction: row;
            align-items: center;

            .select-wrapper {
              width: 355px;
              margin-bottom: 0;
            }

            &__button {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .description {
      &__content-wrapper {
        height: 500px;
      }

      &__button-block {
        flex-direction: row;

        button {
          width: auto;
        }

        &__text {
          max-width: 513px;
          margin: 0 0 0 16px;
        }
      }

      &__inactive-subscription {
        &__button-block {
          flex-direction: row;
          button {
            width: auto;
          }
          &__text {
            margin-left: 16px;
          }
        }
      }
    }
  }
`;

export const StyledSidePageFooter = styled.div`
  margin-bottom: -20px;
  & span {
    & div {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
  }

  a {
    color: ${defaultTheme.colors.planeta} !important;
  }
`;
