import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledVoice = styled.div`
  background-color: ${defaultTheme.colors.white};
  padding: 32px;
  height: auto;
  overflow: hidden;

  .voice {
    &__header {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      h3 {
        margin-bottom: 0;
      }
      &__lead {
        margin-bottom: 32px;
      }
      &__tabs {
        & > div {
          width: -webkit-fill-available;
          justify-content: space-between;
          > div {
            width: 100%;
            padding: 11px 0px;
          }
        }
      }
      button {
        margin-top: 40px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
    .voice {
      &__header {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
        h3 {
          margin-right: 16px;
        }
        &__lead {
          margin-bottom: 0;
        }
        &__tabs {
          height: 54px;
          margin-right: 13px;
          justify-content: flex-start;
          & > div {
            margin-bottom: 0;
            > div {
              width: 52px;
            }
          }
        }

        button {
          height: 54px;
          min-width: 80px;
          padding: 12px 22px;
          margin-top: 0px;
          > span {
            letter-spacing: -0.03em;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .voice {
      &__header {
        &__tabs {
          margin-right: 16px;
          & > div {
            > div {
              width: 61.25px;
            }
          }
        }

        button {
          height: 54px;
          padding: 15px 22px;
          /* width: auto; */
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .voice {
      &__header {
        &__tabs {
          & > div {
            > div {
              width: 76.5px;
            }
          }
        }
      }
    }
  }
`;
