/* Значение минимальной высоты изображения на мониторе */
export const IMAGE_HEIGHT_DESKTOP = 220;

/* Значение минимальной высоты изображения на мобильном устройства */
export const IMAGE_HEIGHT_MOBILE = 208;

/* Фиксированная ширина блока с карточкой устройства */
export const DEFAULT_IMAGE = '/static/images/devices/default-image.jpg';

export const DAYS = ['день', 'дня', 'дней'];
