/** Библиотеки */
import * as React from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** Компоненты */
import {
  H3,
  Icon,
  Icons,
  Text,
  defaultTheme,
  Button,
  ButtonStyleTypes,
  ButtonIconPosition,
  ButtonText,
  LinkButton,
} from 'cordis-core-ui-planeta';
import Link from 'next/link';
import { StyledMobileBalanceWidget } from './style';
import LinkWrapper from '~/components/LinkWrapper';

/** Константы */
import { DEFAULT_LK_LINK } from '~/constants/common';
import { PAYMENT_BLOCK_ID } from '~/components/Blocks/Templates/Payment/constants';

/** Утилиты */
import { formatNumber, getClientName, getGreetingText } from '~/utils/utils';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import { useRootStore } from '~/stores/RootStore';

/** интерфейс виджета баланса */
interface BalanceWidgetProps {
  /* Закрыть / открыть меню */
  toggleHandler: () => void;
}

/**
 * Блок popup с развёрнутым меню
 * @returns {React.FC}
 */
const BalanceWidget: React.FC<BalanceWidgetProps> = ({ toggleHandler }) => {
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const {
    authStore: { auth, isTemporaryTokenAuth },
  } = useRootStore();
  const router = useRouter();

  /**
   * Показывает блок пополнения счёта
   */
  const scrollToPayment = (): void => {
    scrollToBlockById(`${PAYMENT_BLOCK_ID}1`);
  };
  /**
   * Вызывает клик по кнопке открытия чата
   */
  const openChat = (): void => {
    const button = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (button) button.click();
  };

  /** Ссылка на Личный кабинет клиента */
  const redirectToLK = () => {
    router.push(DEFAULT_LK_LINK, DEFAULT_LK_LINK);
    toggleHandler();
  };

  return (
    <StyledMobileBalanceWidget
      $isTemporaryTokenAuth={isTemporaryTokenAuth}
      data-test-balance-widget
    >
      <LinkWrapper href={DEFAULT_LK_LINK} target="_self">
        <Text fontWeightBold>
          {isTemporaryTokenAuth
            ? 'Вход для сотрудников'
            : `${getGreetingText()}, ${getClientName(auth.clientName)}`}
        </Text>
      </LinkWrapper>
      <Text color={defaultTheme.colors.shadow}>Баланс на счёте</Text>
      <H3>{formatNumber(auth.balance, false)}&nbsp;&#8381;</H3>
      <div>
        <Button
          styleType={ButtonStyleTypes.SECONDARY}
          onClick={redirectToLK}
          background={defaultTheme.colors.sweetBlue}
        >
          Моя Планета
        </Button>
        <Button
          styleType={ButtonStyleTypes.SECONDARY}
          minWidth="50px"
          background={defaultTheme.colors.sweetBlue}
          icon={<Icon icon={<Icons.ChatIcon />} />}
          iconPosition={ButtonIconPosition.CENTER}
          onClick={openChat}
        />
      </div>
      <LinkButton onClick={scrollToPayment} color={defaultTheme.colors.black}>
        Пополнить баланс
      </LinkButton>
      <Link href="/payment">
        <a>
          <ButtonText onClick={toggleHandler}>Автоплатёж</ButtonText>
        </a>
      </Link>
      <LinkButton
        onClick={toggleConnectionWizardVisible}
        color={defaultTheme.colors.black}
      >
        Подключить Планету
      </LinkButton>
    </StyledMobileBalanceWidget>
  );
};

export default observer(BalanceWidget);
