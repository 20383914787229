/** libraries */
import React, { FC, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Autocomplete,
  Icons,
  Popup,
  defaultTheme,
  Text,
  LinkButton,
  SidePage,
  Loader,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { observer } from 'mobx-react';
/** styles */
import { StyledListOfSbpBanks, StyledListOfSbpBanksMobile } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/constants/common';
/** stores */
import useListOfSbpBanksStore from './store/useListOfSbpBanksStore';
import { useRootStore } from '~/stores/RootStore';

const ListOfSbpBanks: FC = () => {
  const {
    sbpBanksStore: { sbpBanks, isSbpBanksLoading, isSbpBanksError },
  } = useRootStore();

  const {
    isListOfSbpBanksOpen,
    convertedSbpBanksList,
    getConvertedSbpBanksList,
    setSearchValue,
    searchValue,
    filteredBanksList,
    isLoading,
    resetStore,
    isError,
  } = useListOfSbpBanksStore();

  /** Ссылка на input */
  const searchInput = useRef(null);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  useEffect(() => {
    if (!convertedSbpBanksList?.length) {
      getConvertedSbpBanksList(sbpBanks);
    }
  }, [sbpBanks.length]);

  /**
   * вызывает клик по кнопке открытия чата
   */
  const openChat = (): void => {
    const button = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (button) button.click();
  };

  const main = (): JSX.Element => {
    return (
      <StyledListOfSbpBanksMobile>
        <Autocomplete
          forwardedRef={searchInput}
          showSearchIcon
          items={[]}
          text={searchValue}
          placeholder={isDesktop940 ? 'Поиск банков' : 'Поиск'}
          onInputChange={(value) => setSearchValue(value)}
          hideOptions
        />
        <div className="banks__container">
          {filteredBanksList.length ? (
            <div className="banks__container__list">
              {filteredBanksList.map((item) => {
                return (
                  <div
                    key={item.schema}
                    className="banks__container__list__bank"
                  >
                    <div className="banks__container__list__bank__logo">
                      {item?.logo ? (
                        <Image
                          src={`data:image/png;base64, ${item.logo}`}
                          alt="logo"
                          width={item.width}
                          height={item.height}
                          quality={100}
                        />
                      ) : (
                        <Icons.SbpIcon />
                      )}
                    </div>
                    <Text>{item.bankName}</Text>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {searchValue.length ? (
                <div>
                  <Text lineHeight="24px" color={defaultTheme.colors.black}>
                    Если вашего банка нет в списке, вероятно, он не поддерживает
                    подписку СБП.
                    <br />
                    Если вы уверены в обратном, сообщите нам по телефону{' '}
                    <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
                      8 (343) 379-00-09
                    </Text>{' '}
                    или{' '}
                    <LinkButton
                      color={defaultTheme.colors.planeta}
                      onClick={openChat}
                    >
                      напишите в чат
                    </LinkButton>
                    .
                  </Text>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </StyledListOfSbpBanksMobile>
    );
  };

  const loader = () => {
    return (
      <StyledListOfSbpBanksMobile>
        <div className="loader">
          <Loader />
        </div>
      </StyledListOfSbpBanksMobile>
    );
  };

  const error = () => {
    return (
      <StyledListOfSbpBanksMobile>
        <Text lineHeight="24px" className="error">
          {DEFAULT_ERROR}
        </Text>
      </StyledListOfSbpBanksMobile>
    );
  };

  const content = () => {
    if (isSbpBanksError || isError) return error();
    if (isLoading || isSbpBanksLoading) return loader();
    return main();
  };

  if (isMobile)
    return (
      <SidePage
        show={isListOfSbpBanksOpen}
        headerText="Банки, поддерживающие подписку СБП"
        onCloseClick={resetStore}
      >
        {content()}
      </SidePage>
    );

  return (
    <StyledListOfSbpBanks>
      <Popup
        show={isListOfSbpBanksOpen}
        title="Банки, поддерживающие подписку СБП"
        onCloseClick={resetStore}
        onOutsideClick={resetStore}
        width={(isDesktop940 && '832px') || undefined}
      >
        {content()}
      </Popup>
    </StyledListOfSbpBanks>
  );
};

export default observer(ListOfSbpBanks);
