/** libraries */
import styled from '@emotion/styled';

export const StyledSuspensionPaymentsList = styled.div`
  margin: 16px 0;

  .payment__list {
      display: block;
      max-width: 664px;

      ul {
        margin-left: 32px;
      }
    }
  }
`;
