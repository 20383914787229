/** libraries */
import styled from '@emotion/styled';

/** constants */
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop700,
  desktop940,
} from '~/components/Grid/constants';

/** styles */
import { StyledRowContentTitle } from '../../common/RowContent/styles';

export const StyledViewControl = styled.div`
  padding-bottom: 32px;
  .view-control {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      &__name-tag {
        h3 {
          margin-bottom: 0;
          letter-spacing: -0.03em;
        }

        &__tag-package {
          display: flex;
          align-items: center;
          margin-top: 4px;
          > div[data-test-tag] {
            line-height: 0;
            > span {
              letter-spacing: -0.03em;
            }
          }
          > span {
            margin-left: 8px;
          }
        }
      }

      &__close-icon {
        cursor: pointer;
      }
    }

    &__service-benefits {
      display: flex;
      flex-direction: column;
      height: auto;
      flex-wrap: nowrap;
      width: 568px;

      &__block {
        display: flex;
        flex-direction: column;
        width: 308px;
        margin-bottom: 20px;

        > svg {
          margin-bottom: 8px;
        }
      }

      &__block:nth-of-type(6) {
        margin-bottom: 0;
      }
      &__block:nth-of-type(3) {
        margin-bottom: 20px;
      }
    }

    &__subcription-cost {
      h3 {
        margin-bottom: 0px;
      }

      > button {
        margin-top: 19px;
      }

      &__refusal {
        width: 174px;
        margin-top: 8px;
      }
    }

    &__packages {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: 390px;
      overflow-x: hidden;
      width: auto;
      margin-right: -18px;

      > div {
        margin: 0 16px 16px 0;
      }
      > div:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    &__subscription-management {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > span {
        width: auto;
        margin: 8px 0 0 0;
      }
    }

    &__link {
      cursor: pointer;
    }
  }

  @media (min-width: ${desktop500}px) {
    .view-control {
      &__packages {
        max-height: 260px;
        > div:nth-of-type(2n) {
          margin-right: 16px;
        }
      }

      &__subscription-management {
        flex-direction: row;
        align-items: center;

        > span {
          width: 134px;
          margin: 0 0 0 16px;
        }
      }
    }
  }

  @media (min-width: ${desktop700}px) {
    .view-control {
      &__service-benefits {
        flex-wrap: wrap;
        height: 304px;

        &__block:nth-of-type(3n) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .view-control {
      &__header {
        &__name-tag {
          &__tag-package {
            margin-top: 6px;
          }
        }
      }

      &__packages {
        width: 671px;
        max-height: 640px;
        > div {
          margin: 0 24px 24px 0;
          width: 303px;

          .channel-package-card {
            width: 303px;
          }
          .channel-package-card__duplicate-wrapper {
            width: 303px;
            .channel-package-card__duplicate {
              width: 303px;
            }
          }
        }
        > div:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 170px;
      max-width: 170px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    .view-control {
      &__packages {
        width: 807px;
        max-height: 476px;
        > div {
          width: 239px;

          .channel-package-card {
            width: 239px;
          }
          .channel-package-card__duplicate-wrapper {
            width: 239px;
            .channel-package-card__duplicate {
              width: 239px;
            }
          }
        }
        > div:nth-of-type(2n) {
          margin-right: 24px;
        }
        > div:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 215px;
      max-width: 215px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    .view-control {
      &__packages {
        width: 940px;
        > div {
          width: 284px;

          .channel-package-card {
            width: 284px;
          }
          .channel-package-card__duplicate-wrapper {
            width: 284px;
            .channel-package-card__duplicate {
              width: 284px;
            }
          }
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 260px;
      max-width: 260px;
    }
  }
`;
