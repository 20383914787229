/** libraries */
import { parseISO, format } from 'date-fns';
import { ru } from 'date-fns/locale';

/** api */
import { getChannelsBySeriesCode, getSummaryBySeriesCode } from '~/api/api';
import { getITVChannelPackListBySeriesCode } from '~/api/apiPab2c';

/** типы */
import { Block } from '~/interfaces/BlockInterface';
import { MyTVContent, ChannelPackage } from './interfaces';
/** components */
import { useMyTvStore } from './stores/useMyTvStore';
import useMyTVX3Store from './stores/MyTvX3Store';
import useMyTVX2Store from './stores/MyTvX2Store';
/** constants */
import { STORE_TYPE } from './constants';

/**
 * Возвращает блок MyTV дополненный полем channels
 * @param {Block} block
 */
export const getDataForMyTV = async (block: Block): Promise<MyTVContent> => {
  const channelsByGroups = await getChannelsBySeriesCode(
    block.fields?.seriesCode,
  );
  const packages = await getITVChannelPackListBySeriesCode(
    block.fields?.seriesCode,
  );
  const summary = block.fields?.seriesCode
    ? await getSummaryBySeriesCode(block.fields?.seriesCode)
    : null;

  const fields = {
    ...block.fields,
    channelsByGroups,
    packages,
    summary,
    transformationLimit: summary?.transformationLimit ?? null,
  };
  return {
    ...block,
    fields,
  };
};

/** Дата отключения одинаковая для все пакетов */
export const isSameDate = (packages: ChannelPackage[]): boolean => {
  return packages.reduce((acc, item, index, arr) => {
    if (!acc) return false;
    return index !== 0 ? arr[index - 1]?.unbindDt === item?.unbindDt : true;
  }, true);
};

/** Получает дату */
export const getDate = (
  dateString: string,
  dateFormat = 'd MMMM yyyy',
): string => {
  const dateISO = parseISO(dateString);
  return format(dateISO, dateFormat, { locale: ru });
};

/** Выбирайте нужный стор, в зависимости от типа блока Телевидения */
export const getStore = (storeType: STORE_TYPE) => {
  switch (storeType) {
    case STORE_TYPE.MY_TV_X3:
      return useMyTVX3Store();
    case STORE_TYPE.MY_TV_X2:
      return useMyTVX2Store();
    case STORE_TYPE.TELEVISION:
      return useMyTvStore();
    default:
      return useMyTvStore();
  }
};
