/** Библиотеки */
import styled from '@emotion/styled';
import * as React from 'react';
/** Константы */
import {
  desktop1100,
  desktop1280,
  desktop940,
  innerPadding,
  marginBetweenBlocks,
  blockWidth400,
  blockWidth340,
  blockWidth280,
  minWidth,
} from '~/components/Grid/constants';

/** Props компонента Col */
interface ColProps extends React.HTMLProps<HTMLDivElement> {
  /** Размер блока */
  size?: 1 | 1.5 | 2 | 3;
  children?: JSX.Element;
}

/**
 * Колонка для отображения в сетке
 */
const StyledCol = styled.div`
  flex: 0 0 auto;
  border-radius: 8px;
  width: 100%;
  min-width: ${minWidth}px;
  padding: ${innerPadding}px;

  & + & {
    margin-top: ${marginBetweenBlocks}px;
  }

  @media (min-width: ${desktop940}px) {
    width: ${blockWidth280}px;
    min-width: unset;
    max-width: none;

    &.col-1-5 {
      width: ${blockWidth280 * 1.5 + marginBetweenBlocks / 2}px;
    }

    &.col-2 {
      width: ${blockWidth280 * 2 + marginBetweenBlocks}px;
    }

    &.col-3 {
      width: ${blockWidth280 * 3 + marginBetweenBlocks * 2}px;
    }

    & + & {
      margin-left: ${marginBetweenBlocks}px;
      margin-top: 0;
    }
  }

  @media (min-width: ${desktop1100}px) {
    width: ${blockWidth340}px;

    &.col-1-5 {
      width: ${blockWidth340 * 1.5 + marginBetweenBlocks / 2}px;
    }

    &.col-2 {
      width: ${blockWidth340 * 2 + marginBetweenBlocks}px;
    }

    &.col-3 {
      width: ${blockWidth340 * 3 + marginBetweenBlocks * 2}px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    width: ${blockWidth400}px;

    &.col-1-5 {
      width: ${blockWidth400 * 1.5 + marginBetweenBlocks / 2}px;
    }

    &.col-2 {
      width: ${blockWidth400 * 2 + marginBetweenBlocks}px;
    }

    &.col-3 {
      width: ${blockWidth400 * 3 + marginBetweenBlocks * 2}px;
    }
  }
`;

export const Col: React.FC<ColProps> = ({ size = 1, children }: ColProps) => {
  let style;
  switch (size) {
    default:
    case 1:
      break;
    case 1.5:
      style = 'col-1-5';
      break;
    case 2:
    case 3:
      style = `col-${size}`;
      break;
  }
  return <StyledCol className={style}>{children}</StyledCol>;
};
