/* Библиотеки */
import * as React from 'react';
import Image from 'next/image';

/* Интерфейс */
import { VoiceCommunicationProps } from './interface';

/* Компоненты */
import {
  H2,
  LeadingText,
  Text,
  Icons,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';

/* Стили */
import {
  VoiceCommunicationWrapper,
  VoiceCommunicationContainer,
} from './style';

/**
 * Блок "Голосовая связь"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=491371334
 * @param content
 */
const VoiceCommunication: React.FC<VoiceCommunicationProps> = ({
  content,
}: VoiceCommunicationProps) => {
  const { callPriceMinuteMin } = content;
  const { link, title } = content.fields;

  const price = callPriceMinuteMin.toString().replace(/\./, ',');

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Контент компонента */
  const renderContent = () => {
    return (
      <VoiceCommunicationContainer>
        <div>
          <div className="voice-communication__title">
            <H2>
              Алло!
              <br /> Это Планета!
            </H2>
          </div>
          <div className="voice-communication__text1">
            <LeadingText color={defaultTheme.colors.shadow}>
              Пользуйтесь
              <br /> плюсами
              <br /> IP-телефонии
            </LeadingText>
          </div>
        </div>
        <div className="voice-communication__box">
          <div className="voice-communication__item">
            <Icons.VoiceLines />
            <div className="voice-communication__text2">
              <Text color={defaultTheme.colors.shadow}>
                Высокое качество связи.
                <br />
                Мы&nbsp;используем только премиум
                <br />
                маршруты с&nbsp;наивысшим
                <br />
                качеством связи
              </Text>
            </div>
          </div>
          <div className="voice-communication__item">
            <div className="voice-communication__text3">
              <Text color={defaultTheme.colors.shadow}>Тарифы от</Text>
            </div>
            <H2>{`${price} ₽`}</H2>
            <div className="voice-communication__text4">
              <Text color={defaultTheme.colors.shadow}>
                Рекордно низкие тарифы на&nbsp;междугородние звонки
              </Text>
            </div>
          </div>
        </div>
        <div className="voice-communication__phone-image">
          <Image
            loader={imageLoader}
            src={`${process.env.STATIC_SERVER}/static/images/voiceInfo-phone.png`}
            alt="voiceInfo"
            height={385}
            width={215}
            quality={100}
          />
        </div>
      </VoiceCommunicationContainer>
    );
  };

  return (
    <VoiceCommunicationWrapper>
      {link ? (
        <LinkWrapper href={link}>
          <div title={title}>{renderContent()}</div>
        </LinkWrapper>
      ) : (
        renderContent()
      )}
    </VoiceCommunicationWrapper>
  );
};

export default React.memo(VoiceCommunication);
