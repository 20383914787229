/** libraries */
import { FC, useMemo } from 'react';
import { defaultTheme, LeadingText, Text } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** interfaces */
import { Channel, ChannelPackage } from '../../interfaces';

/** components */
import ChannelPackageCard from '../Cards/ChannelPackageCard/ChannelPackageCard';
import ChannelCard from '../Cards/ChannelCard/ChannelCard';

/** utils */
import { pluralizeAll } from '~/utils/utils';

/** constants */
import { CHANNEL, PACKAGE_NOMINATIVE } from '../../constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMyTVX3Store from '../../stores/MyTvX3Store';

/** Таб "Подключённые" */
const ConnectedTab: FC = () => {
  const {
    tvStore: {
      connectedTVPackages,
      channelsOutsidePackages,
      channelsList,
      tvChannelPackages,
    },
  } = useRootStore();
  const {
    onClickPackageCard,
    onClickChannelCard,
    getPopularChannels,
    getPopularPackages,
  } = useMyTVX3Store();

  const channelsOutside: Channel[] = useMemo(
    () => ((channelsOutsidePackages as unknown) as Channel[]) ?? [],
    [channelsOutsidePackages],
  );
  const popularPackages = getPopularPackages(tvChannelPackages);
  const popularChannels = getPopularChannels(channelsList);

  return (
    <div className="my-tv__tab-block__block">
      {!connectedTVPackages?.length && !channelsOutside?.length && (
        <>
          <div className="my-tv__tab-block__block__not-found">
            <LeadingText
              className="my-tv__tab-block__block__not-found__first-text"
              color={defaultTheme.colors.black}
            >
              Каналы и пакеты не подключены, но это легко исправить!
            </LeadingText>
            <Text
              className="my-tv__tab-block__block__not-found__second-text"
              lineHeight="24px"
              color={defaultTheme.colors.shadow}
            >
              Подключите каналы и пакеты и наслаждайтесь качественным
              телевидением
            </Text>
          </div>
          <div className="my-tv__tab-block__block__count">
            <LeadingText
              className="my-tv__tab-block__block__category"
              color={defaultTheme.colors.black}
            >
              Популярные каналы и пакеты
            </LeadingText>
          </div>
          <div className="my-tv__tab-block__block__cards">
            {popularPackages?.length > 0 &&
              popularPackages.map((item) => {
                return (
                  <ChannelPackageCard
                    key={item.channelPackName}
                    packet={item}
                    onClick={() => onClickPackageCard(item)}
                  />
                );
              })}
            {popularChannels.map((item: Channel) => (
              <ChannelCard
                key={item.name}
                channel={item}
                onClick={() => onClickChannelCard(item)}
              />
            ))}
          </div>
        </>
      )}
      {connectedTVPackages?.length !== 0 && (
        <>
          <div className="my-tv__tab-block__block__count">
            <LeadingText
              className="my-tv__tab-block__block__category"
              color={defaultTheme.colors.black}
            >
              Пакеты
            </LeadingText>
            <LeadingText
              className="my-tv__tab-block__block__count"
              color={defaultTheme.colors.shadow}
            >
              {pluralizeAll(connectedTVPackages?.length, PACKAGE_NOMINATIVE)}
            </LeadingText>
          </div>
          <div className="my-tv__tab-block__block__cards">
            {connectedTVPackages?.map((item) => {
              return (
                <ChannelPackageCard
                  key={item.channelPackName}
                  packet={(item as unknown) as ChannelPackage}
                  onClick={() => onClickPackageCard(item)}
                />
              );
            })}
          </div>
        </>
      )}
      {channelsOutside?.length !== 0 && (
        <>
          <div className="my-tv__tab-block__block__count">
            <LeadingText
              className="my-tv__tab-block__block__category"
              color={defaultTheme.colors.black}
            >
              Каналы вне пакетов
            </LeadingText>
            <LeadingText
              className="my-tv__tab-block__block__count"
              color={defaultTheme.colors.shadow}
            >
              {pluralizeAll(channelsOutside.length, CHANNEL)}
            </LeadingText>
          </div>
          <div className="my-tv__tab-block__block__cards">
            {channelsOutside.map((item) => {
              return (
                <ChannelCard
                  key={item.name}
                  channel={item}
                  onClick={() => onClickChannelCard(item)}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(ConnectedTab);
