/** libraries */
import { FC, useMemo } from 'react';
import { defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
import { ViewportList } from 'react-viewport-list';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';

/** utils */
import { pluralizeAll } from '~/utils/utils';
import { getStore } from '../../utils';

/** components */
import ChannelCard from '../Cards/ChannelCard/ChannelCard';
import { StyledBlockCards } from '~/components/Blocks/Templates/Pab2c/MyTV/styles';

/** constants */
import { CHANNEL, STORE_TYPE } from '../../constants';
import {
  desktop1100,
  desktop500,
  desktop650,
  desktop750,
  desktop940,
} from '~/components/Grid/constants';

/** interfaces */
import { Channel, SubjectWithChannels } from '../../interfaces';

/** stores */
import { useMyTvStore } from '../../stores/useMyTvStore';

/** props */
interface ChannelsTabProps {
  /** Отфильтрованный по формату список каналов */
  formatFilteredChannelList: SubjectWithChannels[];
  /** Каналы включённые в продукт */
  allChannelsIncludedInProduct?: Channel[];
  isTransformer?: boolean;
}

/** Таб "Каналы" */
const ChannelsTab: FC<ChannelsTabProps> = ({
  formatFilteredChannelList,
  allChannelsIncludedInProduct,
  isTransformer,
}: ChannelsTabProps) => {
  const { storeType } = useMyTvStore();
  const { onClickChannelCard } = getStore(storeType);

  /** Формат блока X2 */
  const isX2 = storeType === STORE_TYPE.MY_TV_X2;
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  const isDesktop650 = useMediaQuery({
    query: `(min-width: ${desktop650}px)`,
  });

  const isDesktop750 = useMediaQuery({
    query: `(min-width: ${desktop750}px)`,
  });

  const getChunkSize = () => {
    if (isDesktop1100) return 3;
    if (isDesktop940) return 2;
    if (isDesktop750) return 5;
    if (isDesktop650) return 4;
    if (isDesktop500) return 3;
    return 2;
  };

  const splitChannelsIntoGroups = (arrayOfObjects) => {
    const chunkSize = getChunkSize();
    const groupedObjects = [];

    arrayOfObjects.forEach((obj) => {
      const { name, channels } = obj;

      for (let i = 0; i < channels.length; i += chunkSize) {
        const chunk = channels.slice(i, i + chunkSize);
        groupedObjects.push({ name, channels: chunk, id: nanoid(5) });
      }
    });

    return groupedObjects;
  };

  const viewPortItems = useMemo(() => {
    return isX2
      ? splitChannelsIntoGroups(formatFilteredChannelList)
      : formatFilteredChannelList;
  }, [formatFilteredChannelList]);

  return (
    <>
      <ViewportList items={viewPortItems} overscan={10}>
        {(channel) => {
          return channel.channels.length ? (
            <div
              key={isX2 ? channel.id : channel.name}
              className="my- tv__tab-block__block"
            >
              {channel.name && (
                <div className="my-tv__tab-block__block__count">
                  <LeadingText
                    className="my-tv__tab-block__block__category"
                    color={defaultTheme.colors.black}
                  >
                    {channel.name}
                  </LeadingText>
                  <LeadingText
                    className="my-tv__tab-block__block__count"
                    color={defaultTheme.colors.shadow}
                  >
                    {pluralizeAll(channel.channels.length, CHANNEL)}
                  </LeadingText>
                </div>
              )}
              <StyledBlockCards
                isX2={isX2}
                className="my-tv__tab-block__block__cards"
              >
                {channel.channels.map((item) => {
                  return (
                    <ChannelCard
                      key={nanoid(5)}
                      channel={item}
                      onClick={() => onClickChannelCard(item)}
                      allChannelsIncludedInProduct={
                        allChannelsIncludedInProduct
                      }
                      isTransformer={isTransformer}
                    />
                  );
                })}
              </StyledBlockCards>
            </div>
          ) : null;
        }}
      </ViewportList>
    </>
  );
};

export default observer(ChannelsTab);
