/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { ACTION_MAP, OWNERSHIP_CODES } from '../../constants';

/** стили */
import { StyledFooter } from './style';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../../stores/useDevicesStore';

/**
 * Блок pab2cDevices. Футер сайдпейджа погашения рассрочки
 */
const DeviceLeasePayWizardFooter: FC = () => {
  const {
    isMainAgreement,
    detailedDeviceStore: { detailedDevice, deviceOwnership },
    deviceLeasePayStore: { leasePayAmount, leasePay, isLoading, isErrorAmount },
  } = useDevicesStore();

  const {
    authStore: { auth, isTemporaryTokenAuth },
    pab2cBalanceStore: { getBalance },
  } = useRootStore();

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Баланс больше 0 */
  const isPositiveBalance = auth.balance > 0;

  const handleClick = async () => {
    await leasePay();
    await getBalance();
  };

  /** Текст для кнопки */
  const buttonText = () => {
    if (deviceOwnership === OWNERSHIP_CODES.LEASING)
      return isDesktop940
        ? ACTION_MAP.Leasing.desktop
        : ACTION_MAP.Leasing.mobile;
    if (deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING)
      return isDesktop940
        ? ACTION_MAP.BoughtLeasing.desktop
        : ACTION_MAP.BoughtLeasing.mobile;
    return '';
  };

  return (
    <StyledFooter>
      <Button
        className="device-purchases__buy-button"
        disabled={
          !isMainAgreement ||
          !isPositiveBalance ||
          +leasePayAmount.forSend > detailedDevice.leaseSaldo ||
          +leasePayAmount.forSend <= 0 ||
          !leasePayAmount.value.length ||
          isErrorAmount ||
          isLoading ||
          isTemporaryTokenAuth
        }
        onClick={handleClick}
      >
        {buttonText()}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(DeviceLeasePayWizardFooter);
