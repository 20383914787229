import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
/** константы */
import { desktop940, desktop500 } from '~/components/Grid/constants';

export const StyledAuthWizardConfirm = styled.div`
display: flex;
flex-direction: column;
align-items: center;

button {
  align-self: center;
  width: 100%;
}

a {
  padding: 0;
}

.agreement__description {
  color: ${defaultTheme.colors.gray};
  margin-top: 20px;
}
  
.auth__terminal {
  display: block;
  width: 100%;
}

.link__reset-password {
  text-align: left;
  display: block;
  margin: 20px 0;
  align-self: start;
}

@media (min-width: ${desktop500}px) {
  button {
    max-width: 100%;
    width: 320px;
    align-self: start;
  }


  .agreement__description {
    align-self: start;
  }
}

@media (min-width: ${desktop940}px) {
  flex-direction: column;
  max-width: 419px;

  .auth__terminal {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  
  button {
    align-self: start;
    width: 237px;
  }

  .agreement__description {
    width: auto;
    align-self: normal;
    margin-bottom: 16px;
  }
`;
