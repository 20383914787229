/** libraries */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop650,
  desktop750,
  desktop940,
} from '~/components/Grid/constants';

/** styles */
import { StyledFillableScale } from '~/components/Blocks/Shared/FillableScale/style';

export const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  background: ${defaultTheme.colors.white};
  padding: 32px;

  > div {
    height: 258px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;

export const MyTVStyled = styled.div<{
  isAutoHeight: boolean;
  isLoading: boolean;
}>`
  ${({ isAutoHeight, isLoading }) => {
    return css`
      > div {
        display: flex;
        flex-direction: column;
        height: 640px;
        background: ${defaultTheme.colors.white};
        justify-content: flex-start;
        padding: ${isLoading ? '32px' : '32px 32px 0'};
        overflow-x: hidden;
      }

      > div::-webkit-scrollbar {
        width: 5px;
      }

      > div::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 10px;
      }

      > div::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${defaultTheme.colors.lightGrey};
      }

      .my-tv {
        &__header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 32px;
          height: auto;

          h3 {
            order: 1;
            margin-bottom: 0;
          }

          &__tabs {
            width: 100%;
            order: 3;
            margin-top: 32px;
            margin-right: 0;
            > div {
              width: 100%;
              justify-content: space-between;

              & > div {
                letter-spacing: -0.02em;
                padding: 7px 5px;
                width: 100%;
              }
              .active {
                background-color: ${defaultTheme.colors.black};
                > span {
                  color: ${defaultTheme.colors.white} !important;
                }
              }
            }
          }

          &__channel-formats {
            order: 4;
            width: 100%;
            margin-top: 16px;
            > div {
              width: 100%;
              .select-wrapper,
              .select-overlay {
                width: 100%;
              }
            }
          }

          &__search-icon {
            order: 2;
            margin-left: auto;
            cursor: pointer;
          }
        }

        &__tab-block {
          &__block {
            display: flex;
            flex-direction: column;

            &__count {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 16px;
            }

            &__category {
              font-size: 20px;
              line-height: 24px;
              margin-right: 16px;
            }

            &__cards {
              display: grid;
              grid-gap: 1rem;
              grid-template-columns: 1fr 1fr;
              flex-wrap: wrap;
              margin-bottom: 36px;
              width: auto;

              &.viewport {
                > div:first-of-type,
                > div:last-of-type {
                  position: absolute;
                }
              }
            }

            &__not-found {
              display: flex;
              flex-direction: column;
              margin-bottom: 40px;

              &__first-text {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 16px;
              }

              &__second-text {
                width: 360px;
              }

              &__third-text {
                width: 457px;
              }

              &__transformer-text {
                width: auto;
              }
            }

            &__transformer {
              display: flex;
              flex-direction: column;
              margin-bottom: 32px;

              &__block {
                display: flex;
                flex-direction: column;
                width: auto;
                margin: 0 0 16px 0;
              }

              &__header {
                font-size: 20px;
                margin-bottom: 7px;
              }

              button {
                margin-right: 16px;
                padding: 12px 13px;
              }

              a {
                letter-spacing: -0.03em;
              }
            }
          }
        }

        &__search {
          &__header {
            display: flex;
            align-items: center;
            margin-bottom: 38px;

            &__close-icon {
              cursor: pointer;
              margin-left: 24px;
            }
          }
        }

        &__mobile-filters {
          min-height: 540px;
          position: relative;

          &__header {
            display: flex;
            justify-content: space-between;
          }

          &__tags {
            margin: 32px 0;
            &__tag:not(:last-of-type) {
              margin-bottom: 32px;
              &__tag-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                jistify-content: space-between;
              }
            }
          }

          &__button-wrapper {
            width: 100%;
            position: absolute;
            bottom: 0;

            button {
              width: 100%;
            }
          }
        }

        &__loader {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }

        &__hidden {
          height: 0;
          overflow: hidden;
        }
      }

      @media (min-width: ${desktop500}px) {
        .my-tv {
          &__header {
            &__tabs {
              & > div {
                & > div {
                  padding: 7px 23px;
                  width: 100%;
                }
              }
            }
          }
          &__tab-block {
            &__block {
              &__cards {
                grid-template-columns: 1fr 1fr 1fr;
              }
              &__transformer {
                button {
                  padding: 12px 38px;
                }
              }
            }
          }
        }
      }

      @media (min-width: ${desktop650}px) {
        .my-tv {
          &__tab-block {
            &__block {
              &__cards {
                grid-template-columns: 1fr 1fr 1fr 1fr;
              }
            }
          }
        }
      }

      @media (min-width: ${desktop750}px) {
        .my-tv {
          &__tab-block {
            &__block {
              &__cards {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              }
            }
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        > div {
          display: flex;
          height: ${isAutoHeight ? 'auto' : '640px'};
          width: 904px;
          min-height: 640px;
          padding: ${isAutoHeight ? '32px' : '32px 32px 0'};
        }

        .my-tv {
          &__header {
            margin-bottom: 40px;
            height: auto;

            h3 {
              width: 100%;
              margin-top: 5px;
              margin-bottom: 1.5rem;
            }

            &__tabs {
              width: auto;
              order: 2;
              margin-top: 5px;
              margin-bottom: 5px;

              & > div {
                > div {
                  padding: 10px 24px;
                  letter-spacing: -0.02em;
                  margin-left: -2px;
                  &:first-of-type {
                    padding-left: 32px;
                    &.active {
                      margin: 0 -8px 0 0;
                    }
                  }

                  &:last-of-type {
                    padding-right: 32px;
                    &.active {
                      margin: 0 0 0 -8px;
                    }
                  }

                  &.active {
                    padding: 10px 32px;
                    margin: 0 -8px;
                  }

                  > span {
                    color: ${defaultTheme.colors.shadow};
                    font-weight: 600;
                    letter-spacing: -0.02em;
                  }

                  .icon {
                    line-height: 0;
                  }
                }
              }
            }

            &__channel-formats {
              order: 3;
              width: 136px;
              margin-top: 0;
              margin-left: auto;

              > div {
                width: 136px;
                .select-wrapper {
                  width: 136px;
                }
              }
            }

            &__search-icon {
              margin-right: 0;
              margin-left: 24px;
              order: 4;
            }
          }

          &__tab-block {
            max-height: 450px;
            overflow-x: hidden;
            margin-right: -25px;

            &__block {
              &__count {
                margin-bottom: 24px;
              }

              &__cards {
                display: flex;
                gap: 24px;
                > div {
                  width: 264px;

                  .channel-package-card {
                    width: 264px;
                  }
                  .channel-package-card__duplicate-wrapper {
                    width: 264px;
                    .channel-package-card__duplicate {
                      width: 264px;
                    }
                  }
                }
              }

              &__transformer {
                flex-direction: row;
                margin-bottom: 40px;

                &__block {
                  width: 260px;
                  margin: 0 24px 0 0;
                }

                ${StyledFillableScale} {
                  width: 264px;
                }
              }

              &__not-found {
                &__transformer-text {
                  width: 700px;
                }
              }
            }
          }

          &__tab-block::-webkit-scrollbar {
            width: 5px;
          }

          &__tab-block::-webkit-scrollbar-track {
            background-color: ${defaultTheme.colors.white};
            border-radius: 10px;
          }

          &__tab-block::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: ${defaultTheme.colors.lightGrey};
          }
        }
      }

      @media (min-width: ${desktop1100}px) {
        > div {
          width: 1084px;
        }
        .my-tv {
          &__header {
            margin-bottom: 40px;
            height: auto;

            h3 {
              width: 100%;
              margin-bottom: 1.5rem;
            }

            &__tabs {
              order: 2;
              margin-top: 5px;
              margin-bottom: 5px;
            }

            &__channel-formats {
              width: 181px;
              > div {
                width: 181px;
                .select-wrapper {
                  width: 181px;
                }
              }
            }

            &__search-icon {
              margin-right: 0;
              order: 4;
            }
          }

          &__tab-block {
            max-height: 450px;
            &__block {
              &__cards {
                > div {
                  width: 324px;
                  .channel-package-card {
                    width: 324px;
                  }
                  .channel-package-card__duplicate-wrapper {
                    width: 324px;
                    .channel-package-card__duplicate {
                      width: 324px;
                    }
                  }
                }
              }

              &__transformer {
                &__block {
                  width: 324px;
                }

                ${StyledFillableScale} {
                  width: 324px;
                }
              }
            }
          }
        }
      }

      @media (min-width: ${desktop1280}px) {
        > div {
          width: 1264px;
        }
        .my-tv {
          &__header {
            flex-wrap: nowrap;
            h3 {
              width: 94.44px;
              margin-top: 0;
              margin-bottom: 0;
            }
            &__channel-formats {
              width: 226px;
              margin-left: 30px;

              > div {
                width: 226px;
                .select-wrapper {
                  width: 226px;
                }
              }
            }

            &__tabs {
              order: 2;
              margin-top: 5px;
              margin-bottom: 5px;
              margin-left: auto;

              & > div {
                > div {
                  margin-left: 0;
                }
              }
            }
          }
          &__tab-block {
            max-height: 514px;
            &__block {
              &__cards {
                > div {
                  width: 282px;
                  .channel-package-card {
                    width: 282px;
                  }
                  .channel-package-card__duplicate-wrapper {
                    width: 282px;
                    .channel-package-card__duplicate {
                      width: 282px;
                    }
                  }
                }
              }

              &__transformer {
                &__block {
                  width: 282px;
                }

                ${StyledFillableScale} {
                  width: 282px;
                }
              }
            }
          }
        }
      }
    `;
  }}
`;

export const StyledBlockCards = styled.div<{ isX2?: boolean }>`
  ${({ isX2 }) =>
    isX2 &&
    css`
      margin-bottom: 24px !important;
    `}
`;
