/** libraries */
import { FC, memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** components */
import OperatingStateWizardFooter from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizardFooter';
import OperatingStateWizard from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizard';
import { Button, ButtonStyleTypes, SidePage } from 'cordis-core-ui-planeta';
import Portal from '~/components/Portal/Portal';
/** api */
import { resumeService } from '~/api/apiPab2c';
/** constants */
import { VIEW_TYPES } from '~/components/Blocks/Templates/Pab2c/ContractState/types';
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop940,
} from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/constants/common';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
/** store */
import useVacationStateStore from '../../../Vacation/store/useVacationStore';
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const Root = styled.div`
  margin-top: 16px;
`;

const Suspended: FC = () => {
  const {
    contractStateStore: { updateAuthCondition },
    authStore: { isTemporaryTokenAuth, isAuth },
    vacationStore: { isActivated, isOrdered, getVacation },
  } = useRootStore();
  const { setIsVacationShutdownOpen } = useVacationStateStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  const [
    isOperatingStateWizardShow,
    setIsOperatingStateWizardShow,
  ] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [viewType, setViewType] = useState<VIEW_TYPES>(VIEW_TYPES.RESUME);
  const [
    isOperatingStateLoading,
    setIsOperatingStateLoading,
  ] = useState<boolean>(false);

  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  const handleOperatingStateSubmit = async () => {
    setIsOperatingStateLoading(true);
    setError(null);

    try {
      await resumeService();
      await getVacation();
      setViewType(VIEW_TYPES.RESUME_SUCCESS);
      setOpenSPAfterAuthorizationState(null);
    } catch (e) {
      if (e.statusCode === 401) {
        setOpenSPAfterAuthorizationState(SIDE_PAGES.RESUMPTION_ACTION);
        return;
      }
      setOpenSPAfterAuthorizationState(null);
      setViewType(VIEW_TYPES.RESUME_ERROR);
      setError(DEFAULT_ERROR);
    } finally {
      setIsOperatingStateLoading(false);
    }
  };

  const getOperatingStateTitle = (): string => {
    return viewType === VIEW_TYPES.RESUME_SUCCESS ||
      viewType === VIEW_TYPES.RESUME_ERROR
      ? null
      : 'Возобновить обслуживание';
  };

  const onCloseClick = async () => {
    setIsOperatingStateWizardShow(false);
    setError(null);
    setViewType(VIEW_TYPES.RESUME);
    setOpenSPAfterAuthorizationState(null);
    if (viewType === VIEW_TYPES.RESUME_SUCCESS) await updateAuthCondition();
  };

  const getButtonText = () => {
    if (isOrdered)
      return `Отменить ${
        isDesktop1100 || (!isDesktop940 && isDesktop500) ? 'Каникулы' : ''
      }`;
    return `Возобновить ${
      isDesktop1280 || (!isDesktop940 && isDesktop500) ? 'обслуживание' : ''
    }`;
  };

  const onButtonClick = () => {
    if (isOrdered || isActivated) {
      setIsVacationShutdownOpen(true);
      return;
    }
    setIsOperatingStateWizardShow(true);
  };

  useEffect(() => {
    if (!isAuth) return;
    if (openSPAfterAuthorizationState === SIDE_PAGES.RESUMPTION_ACTION) {
      setIsOperatingStateWizardShow(true);
      handleOperatingStateSubmit();
    }
  }, [isAuth]);

  return (
    <>
      <Root>
        <Button styleType={ButtonStyleTypes.MAIN} onClick={onButtonClick}>
          {getButtonText()}
        </Button>
      </Root>
      <Portal>
        <SidePage
          show={isOperatingStateWizardShow}
          width="832px"
          headerText={getOperatingStateTitle()}
          onCloseClick={onCloseClick}
          footerContainer={
            viewType === VIEW_TYPES.RESUME ? (
              <OperatingStateWizardFooter
                handleSubmit={handleOperatingStateSubmit}
                viewType={viewType}
                isActionLoading={isOperatingStateLoading}
                isTemporaryTokenAuth={isTemporaryTokenAuth}
              />
            ) : (
              <></>
            )
          }
          isOnlyMobileFooter
          removeScrollBar
        >
          <OperatingStateWizard viewType={viewType} error={error} />
        </SidePage>
      </Portal>
    </>
  );
};

export default memo(observer(Suspended));
