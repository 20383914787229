/* Значение тега по умолчанию */
export const DEFAULT_TAG = { value: 'Всё', tag: 'all', types: [] };

/* Значение тега 'Архив' */
export const ARCHIVE_TAG = {
  value: 'Архив',
  tag: 'archive',
  types: ['Archive'],
};

/* Значения табов оборудования */
export const DEVICES_TABS = ['Рассрочка', 'Покупка', 'В пользование'];

export const DEVICE_PLURAL = {
  router: 'маршрутизатора',
  stb: 'телеприставки',
  phone: 'SIP-телефона',
  handset: 'гарнитуры',
  camera: 'камеры',
  /* eslint-disable camelcase */
  remote_control: 'пульта',
};

export const TOO_MUCH_REQUESTS_ERROR_TEXT =
  'Превышено количество заявок за\u00A0день. Новую вы можете отправить завтра.';

// Тексты для сообщения о результате отправки заявки
export enum RESULT_TEXT {
  SUCCESS = 'Ура! Заказ оформлен!',
  FAILURE = 'Что-то пошло не\u00A0так...',
}

export enum QP_DEVICE {
  NAME = 'deviceName',
  PRICE = 'devicePrice',
  IS_BUY = 'isBuy',
}

export const DEFAULT_SELECT = {
  label: '',
  value: '',
};
