import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';
import { StyledFillableScale } from '../FillableScale/style';

export const StyledCreatePassword = styled.div`
  .create-password {
    &__warning {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 24px;

      &__block {
        margin-bottom: 16px;
      }
    }

    &__input-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      > span:first-of-type {
        margin-bottom: 8px;
      }
    }
  }

  .code {
    &__info {
      display: flex;
      margin-bottom: 13px;
      &__block {
        display: flex;
        flex-direction: column;

        > span:first-of-type {
          margin-bottom: 4px;
        }
      }
      &__block:first-of-type {
        margin-right: 80px;
      }
    }

    &__link {
      cursor: pointer;
      margin-bottom: 24px;
    }

    &__input {
      display: flex;
      flex-direction: column;
      &__block {
        display: flex;
        flex-direction: column;

        > span:first-of-type {
          margin-bottom: 8px;
        }
      }
    }

    &__action {
      height: auto;
      display: flex;
      align-items: center;
      margin: 16px 0 0 0;

      &__timer-text {
        width: 100%;
      }

      &__block {
        display: flex;
        flex-direction: column;
        > span {
          cursor: pointer;
        }
        > span:first-of-type {
          margin-bottom: 12px;
        }
      }
    }

    &__warning {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-top: 24px;
    }
  }

  .password {
    &__input-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      > span {
        margin-bottom: 8px;
      }

      &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &__action {
          margin-top: 16px;
          cursor: pointer;
        }
        div[data-test-error] {
          width: auto;
        }
        > span {
          width: 100%;
          > div {
            width: 100%;
            & > div {
              width: 100%;

              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &__input-block:last-of-type {
      margin-bottom: 0;
    }
    &__security {
      display: flex;
      flex-direction: column;
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 24px;

      > span:not(:last-of-type) {
        margin-bottom: 4px;
      }

      &__warning {
        margin-top: 16px;
      }

      .line-through {
        text-decoration-line: line-through;
        color: ${defaultTheme.colors.disable};
      }
    }
  }

  ${StyledFillableScale} {
    margin: 0 40px 0 0;

    .scale {
      margin: 4px 0 8px;
    }
  }

  @media (min-width: ${desktop940}px) {
    .code {
      &__input {
        flex-direction: row;
        align-items: start;
        &__block {
          &__code {
            > div[data-test-error] {
              > span {
                max-width: 320px;
              }
            }
          }
        }
      }

      &__action {
        height: 54px;
        margin: 32px 0 0 16px;

        &__block {
          > span:first-of-type {
            margin-bottom: 4px;
          }
        }
        &__timer-text {
          width: 188px;
        }
      }
    }

    .password {
      &__input-block {
        &__block {
          flex-direction: row;
          &__action {
            margin: 12px 0 0 16px;
          }
          div[data-test-error] {
            width: 330px;
          }
          > span {
            width: 330px;
            > div {
              width: 330px;
              & > div {
                width: 330px;

                input {
                  width: 330px;
                }
              }
            }
          }
        }
      }
    }

    ${StyledFillableScale} {
      width: auto;
      margin: 0 40px 0 0;
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    width: 100%;
    margin-bottom: 16px;
  }

  @media (min-width: ${desktop940}px) {
    align-items: flex-start;

    > button {
      width: auto;
      margin-bottom: 0;
    }
  }
`;
