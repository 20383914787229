/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** Стили */
import { StyledFooter } from './ChatleWizard.style';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useChatleStateStore from './store/useChatleStore';

const ChatleWizardFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
    pab2cBalanceStore: { getBalance },
  } = useRootStore();
  const {
    reserveChatles,
    chatleAmount,
    errorChatleAmount,
    isChatleLoading,
  } = useChatleStateStore();
  return (
    <StyledFooter className="wizard__button">
      <Button
        type="button"
        onClick={() => reserveChatles(getBalance)}
        disabled={
          errorChatleAmount || !chatleAmount.length || isTemporaryTokenAuth
        }
        loading={isChatleLoading}
      >
        Зарезервировать
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(ChatleWizardFooter);
