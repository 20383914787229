/** библиотеки */
import styled from '@emotion/styled';

/** компонента библиотеки */
import { defaultTheme, Tag } from 'cordis-core-ui-planeta';

/** интерфейсы */
import { StyledDevicesItemImageProps } from '~/components/Blocks/Shared/DevicesCard/interfaces';

/** константы */
import { desktop900, desktop940 } from '~/components/Grid/constants';

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;

export const StyledFooterButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex-direction: column;

  button {
    min-height: 54px;
    width: 100%;
  }

  @media (min-width: ${desktop900}px) {
    flex-direction: row;
  }

  @media (min-width: ${desktop940}px) {
    button {
      width: auto;
    }
  }
`;

export const StyledCardWizardImage = styled.div<StyledDevicesItemImageProps>`
  ${({ imgSrc, imgSizes }: StyledDevicesItemImageProps) => `
    height: ${imgSizes ? imgSizes.newHeight : 0}px;
    width: ${imgSizes ? imgSizes.newWidth : 0}px;
    background: url(${imgSrc}) left bottom no-repeat;
    background-size: contain;
  `}
`;

export const DeviceCardWizardResultStyled = styled.div`
  .result-icon {
    margin: -51px 0 26.5px;

    & svg,
    svg * {
      stroke: ${defaultTheme.colors.planeta};
    }
  }
`;

export const StyledCardWizardImageWrapper = styled.div<{
  minHeight: number;
}>`
  ${({ minHeight }) => {
    return `
    margin-bottom: 1rem;
    display: flex;
    align-items: end;
    min-height: ${minHeight + 36}px;
    padding: 1rem;
    border-bottom: 1px solid ${defaultTheme.colors.disable};
  `;
  }}}
`;

export const DeviceCardWizardStyled = styled.div`
  position: relative;

  .device-card-wizard {
    max-width: 720px;
    &__form-owning {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 1rem 0;
    }

    &__block {
      display: flex;
      margin: 0 0 2rem 0;
      &-value {
        margin: 0.3rem 0;
      }
      &-inner {
        min-width: 27%;
        max-width: 50%;
        &:nth-of-type(n + 2) {
          padding: 0 0 0 2rem;
        }
        > div > a {
          display: flex;
          align-items: center;
          & svg {
            margin: 0 0.5rem 0 0;
          }
        }
        & strong {
          font-weight: 700;
          font-size: 28px;
          line-height: 31px;
        }
        &__link {
          margin: 0.4rem 0 0 0;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    &__price-block {
      & .devices__item-price {
        margin: 0.3rem 0;
      }
    }

    &__warranty-block {
      display: block;
    }

    &__warning {
      background: ${defaultTheme.colors.backgroundWarning};
      padding: 1rem;
      margin: 1rem 0;
    }

    &__agreement {
      margin: 2rem 0 0 0;
      & a {
        color: ${defaultTheme.colors.planeta};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: ${desktop900}px) {
    .device-card-wizard {
      max-width: 100%;
      &__block {
        display: block;

        &-inner {
          margin: 0 0 1.5rem 0;
          max-width: 100%;

          &:nth-of-type(n + 2) {
            padding: 0;
          }

          > span:first-of-type {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
`;

export const StyledIconLink = styled.div`
  margin-bottom: 12px;
  width: max-content;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none !important;

    > span:first-of-type {
      margin-right: 7px;
    }

    div[data-test-preloader] {
      margin-right: 14px;
      position: relative;
      left: 3px;
    }
  }
`;

export const StyledWarrantyTag = styled(Tag)`
  position: absolute;
  top: -5%;
`;
