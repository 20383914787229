/** libraries */
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** api */
import {
  getSbpPaymentCheckOrderState,
  sbpRegisterPaymentWithBinding,
  setSbpPaymentRegisterOrderByContractName,
} from '~/api/api';
/** components */
import Root, { StyledInfo, StyledLoaderWrapper, StyledMain } from './style';
import { H3, LeadingText, Text, Loader, Button } from 'cordis-core-ui-planeta';
import Warning from './Components/Warning';
/** constants */
import TEXT_CONFIG from './config';
import { RESULT_STATE } from '~/components/Blocks/Templates/PaymentResult/constants';
import { desktop940 } from '~/components/Grid/constants';
import { CHECK_PAYMENT_SLUG } from '~/components/Blocks/Templates/Payment/constants';
/** types */
import { SbpPaymentProps } from '~/components/Blocks/Templates/Payment/types';
/** stroes */
import useMobileSBPStore from '../../../Payment/MobileSBP/store/useMobileSBPStore';

interface DeclinedSBPPaymentProps {
  condition: RESULT_STATE.SbpBoundFailed | RESULT_STATE.SbpPaymentFailed;
}

const DeclinedSBPPayment: FC<DeclinedSBPPaymentProps> = ({
  condition,
}: DeclinedSBPPaymentProps) => {
  const { waitingPage, setIsOpenSBPCards, setPayload } = useMobileSBPStore();
  const router = useRouter();
  const [paymentData, setPaymentData] = useState<SbpPaymentProps>(null);
  const isBinding = condition === RESULT_STATE.SbpBoundFailed;
  const {
    HEADER_PAYMENT,
    HEADER_BINDING,
    SNOSKA,
    ADVICE,
    MOBILE_PAYMENT,
    MOBILE_BINDING,
  } = TEXT_CONFIG;

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  useEffect(() => {
    if (
      !router.isReady ||
      !router?.query?.amount ||
      !router?.query?.contractNumber ||
      !router?.query?.contractId ||
      !router?.query?.linkedAccount
    )
      return;

    (async () => {
      try {
        const res = isBinding
          ? await sbpRegisterPaymentWithBinding(
              router?.query?.contractNumber as string,
              Number(router.query.amount as string),
            )
          : await setSbpPaymentRegisterOrderByContractName(
              router.query.contractNumber as string,
              Number(router.query.amount as string),
            );

        setPaymentData(res);
        setPayload(res.payload);
      } catch (e) {
        console.error('DeclinedSBPPayment');
      }
    })();
  }, [router.isReady]);

  useEffect(() => {
    if (!paymentData || (isMobile && !waitingPage)) return;
    const id = setInterval(async () => {
      try {
        const res = await getSbpPaymentCheckOrderState(
          router.query.contractNumber as string,
          paymentData.qrId,
        );
        if (res.statusCode === 'Success') {
          clearInterval(id);
          const sbpParams = `&contractNumber=${
            router.query.contractNumber
          }&contractId=${router.query.contractId}&qrId=${
            paymentData.qrId
          }&isRememberPaymentData=${isBinding}&sbpSubscriptionId=${
            paymentData?.sbpSubscriptionId ?? 'undefined'
          }&linkedAccount=null&amount=${router.query.amount}`;

          router.push(
            `/${CHECK_PAYMENT_SLUG}?isCorrect=${true}${sbpParams}`,
            `/${CHECK_PAYMENT_SLUG}?isCorrect=${true}${sbpParams}`,
          );
        }
      } catch (e) {
        console.error('DeclinedSBPPayment');
      }
    }, 2000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [paymentData, waitingPage]);

  const qRCode = (): JSX.Element => {
    if (isMobile) return null;
    return paymentData?.qrImage ? (
      <Image
        src={`data:image/png;base64, ${paymentData.qrImage}`}
        alt="qrcode"
        width={212}
        height={212}
        quality={100}
      />
    ) : (
      <StyledLoaderWrapper>
        <Loader />
      </StyledLoaderWrapper>
    );
  };

  return (
    <Root>
      {!isDesktop940 ? (
        <LeadingText>{isBinding ? HEADER_BINDING : HEADER_PAYMENT}</LeadingText>
      ) : (
        <H3>{isBinding ? HEADER_BINDING : HEADER_PAYMENT}</H3>
      )}

      <StyledMain>
        {isMobile && (
          <Button onClick={() => setIsOpenSBPCards(true)}>
            {isBinding ? MOBILE_BINDING : MOBILE_PAYMENT}
          </Button>
        )}

        {qRCode()}

        <StyledInfo>
          {!isMobile && <Text>{ADVICE}</Text>}
          {isBinding && <Warning />}
        </StyledInfo>
      </StyledMain>

      {isBinding && SNOSKA}
    </Root>
  );
};

export default observer(DeclinedSBPPayment);
