/** libraries */
import { FC, memo } from 'react';
import styled from '@emotion/styled';
/** components */
import WaitingConnection, {
  Root as WaitingRoot,
} from '~/components/Blocks/Templates/Pab2c/ContractState/Components/WaitingConnection';
import { Root as PhoneRoot } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/PhoneConnection';
import { defaultTheme, Text, Button } from 'cordis-core-ui-planeta';
import { StyledHeader } from '~/components/Blocks/Templates/Pab2c/ContractState/style';
import LinkWrapper from '~/components/LinkWrapper';
/** config */
import { CONTRACT_STATE } from '~/components/Blocks/Templates/Pab2c/ContractState/config';
/** constants */
import { PAYMENT_SLUG } from '~/constants/common';
/** utils */
import { formatNumber } from '~/utils/utils';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 9px;

  ${WaitingRoot}, ${WaitingRoot} > ${PhoneRoot} {
    margin-top: 9px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 9px;
`;

export type PrepaymentConnectionProps = {
  view: CONTRACT_STATE.WAITING_PAYMENT | CONTRACT_STATE.NOT_ENOUGH_PAYMENT;
  money: number;
};

const PrepaymentConnection: FC<PrepaymentConnectionProps> = ({
  view,
  money,
}) => {
  return (
    <Root>
      <Text color={defaultTheme.colors.white}>
        {view === CONTRACT_STATE.WAITING_PAYMENT
          ? 'Внесите авансовый платёж'
          : 'Недостаточно средств для аванса'}
      </Text>
      <StyledHeader color={defaultTheme.colors.white}>
        {formatNumber(money)} ₽
      </StyledHeader>
      <WaitingConnection />
      <LinkWrapper href={`/${PAYMENT_SLUG}`}>
        <StyledButton>Оплатить</StyledButton>
      </LinkWrapper>
    </Root>
  );
};

export default memo(PrepaymentConnection);
