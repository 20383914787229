import styled from '@emotion/styled';
import { defaultTheme, LeadingText, Text, H3 } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledAutopaymentSuggestion = styled.div`
  .autopayment {
    &__header {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px;
      > span {
        margin: -1px 0 0 8px;

        > svg {
          width: 56px;
          height: 26px;
        }
      }
    }
    &__block {
      margin-top: 32px;
      display: flex;
      flex-direction: column;

      .colorContainer {
        > span {
          color: ${defaultTheme.colors.shadow};
          margin-top: 4px;
        }
      }
    }

    &__money {
      &__block {
        display: flex;
        flex-direction: column;

        > div {
          order: 2;
          display: flex;
          flex-direction: column;
        }

        > span {
          order: 1;
          margin: 0 0 16px 0;
        }
      }
    }

    &__input-text {
      margin-bottom: 8px;
    }

    &__input-field {
      width: 100%;
    }

    &__button {
      margin: 32px 0 16px;
    }

    &__warning-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin: 16px 0 0 0;

      &__button {
        margin-top: 16px;
        cursor: pointer;
      }
    }

    &__snoska {
      a {
        color: ${defaultTheme.colors.planeta};
      }
    }

    &__error {
      margin: 16px 0;
    }
  }

  @media (min-width: ${desktop940}px) {
    .autopayment {
      &__header {
        > span {
          margin: -3px 0 0 8px;

          > svg {
            width: 79px;
            height: 37px;
          }
        }
      }
      &__block {
        flex-direction: row;
        justify-content: space-between;
        > div:first-of-type {
          width: 459px;
        }
        .colorContainer {
          width: 100%;
          > span {
            margin-top: 0;
          }
        }
      }

      &__button {
        margin: 40px 0 16px;
      }

      &__money {
        &__block {
          flex-direction: row;
          align-items: end;
          > div {
            order: 1;
          }

          > span {
            order: 2;
            height: 54px;
            margin: 0 0 0 24px;
            padding-top: 15px;
            cursor: pointer;
          }
        }

        > span {
          margin-top: 16px;
        }
      }

      &__input-field {
        width: 298px;
      }

      &__warning-block {
        width: 357px;
        margin: 0 0 0 24px;
      }

      &__error {
        margin: 0 0 0 16px;
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .autopayment {
      &__warning-block {
        width: 537px;
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .autopayment {
      &__warning-block {
        width: 717px;
      }
    }
  }
`;

export const SuccessHeaderDesktop = styled(H3)`
  margin-bottom: 0;
`;

export const SuccessHeaderMobile = styled(LeadingText)`
  margin-bottom: 0;
`;

export const SuccessText = styled(Text)`
  margin-top: 16px;
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderMobile = styled(LeadingText)`
  svg {
    width: 56px;
    height: 26px;
    position: relative;
    margin-left: 8px;
    top: 5.1px;
  }
`;

export const HeaderDesktop = styled(H3)`
  margin-bottom: 32px;

  svg {
    width: 79px;
    height: 37px;
    position: relative;
    margin-left: 8px;
    top: 9px;
  }
`;
