/** библиотеки */
import { FC } from 'react';

/** компоненты библиотеки */
import { defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import StyledPoint from './Point';
import LinkWrapper from '~/components/LinkWrapper';

/** стилевые компоненты */
import { StyledResiliency } from './ResiliencyBanner.style';

/* helpers */
import { pointsNine } from './ResiliencyBanner.helpers';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

const text = [
  {
    id: 'firstNum',
    points: [...pointsNine],
  },
  {
    id: 'secondNum',
    points: [...pointsNine],
  },
];

const ResiliencyBanner: FC<AdvantagesContentProps> = ({ content }) => (
  <LinkWrapper href={content.fields?.link}>
    <StyledResiliency>
      <div className="resiliencyBanner__wrapper-animation">
        <div className="resiliencyBanner__animation-block">
          <ul className="resiliencyBanner__list resiliencyBanner__list--animation-text">
            {text.map((character) => (
              <li key={character.id}>
                <div className="resiliencyBanner__character">
                  {character.points.map((point, index) => (
                    <StyledPoint
                      key={String(index)}
                      diam={point.diam}
                      posX={point.posX}
                      posY={point.posY}
                      isActive={point.isActive}
                    />
                  ))}
                </div>
              </li>
            ))}
          </ul>
          <H3 className="resiliencyBanner__title-number">,98%</H3>
        </div>
      </div>
      <H3 color={defaultTheme.colors.white}>Работает всё!</H3>
      <Text
        className="resiliencyBanner__text"
        color={defaultTheme.colors.white}
      >
        Надёжность сети Планета проверена высокими нагрузками в период
        увеличения интернет-трафика
      </Text>
    </StyledResiliency>
  </LinkWrapper>
);

export default ResiliencyBanner;
