import styled from '@emotion/styled';
import { desktop600, desktop940 } from '~/components/Grid/constants';

export const StyledProlongationNotification = styled.div`
  .prolongation {
    &__info {
      margin: 18px 0 16px;
    }

    &__image {
      max-width: 750px;
      height: calc(50vw - 70px);
      position: relative;
    }

    &__price-button {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 32px 0;

      h2 {
        margin-bottom: 0;
        margin-right: 24px;
      }

      button {
        height: 54px;
        margin-top: 16px;
      }
    }
  }

  @media (min-width: ${desktop600}px) {
    .prolongation {
      &__price-button {
        flex-direction: row;
        align-items: center;

        button {
          margin-top: 0px;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .prolongation {
      &__image {
        height: 320px;
      }
    }
  }
`;
