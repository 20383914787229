/** библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** компоненты */
import DevicesCard from '~/components/Blocks/Shared/DevicesCard/DevicesCard';
/** типы */
import { DeviceItemFilterType } from './DeviceList.types';
import {
  DevicesFields,
  ImageSizeData,
} from '~/components/Blocks/Templates/Devices/interfaces';
/** утилиты */
import { isExternal } from '~/utils/utils';
/** константы */
import { DEVICES_TABS } from '~/components/Blocks/Templates/Devices/constants';
/** stores */
import useDeviceListStore from './store/useDeviceListStore';
import useDeviceDetailedCardStore from '../../Shared/DeviceDetailedCard/store/useDeviceDetailedCardStore';

const DeviceListTabs: FC = () => {
  const { filteredDevices, list } = useDeviceListStore();
  const {
    setDetailedDeviceData,
    setIsDetailedDeviceShow,
  } = useDeviceDetailedCardStore();

  const handleShowDetailedInfoClick = (e, item) => {
    const isClickTab = DEVICES_TABS.some((tab) => {
      return tab === e.target.innerHTML;
    });

    if (!isClickTab) {
      setDetailedDeviceData(item);
      setIsDetailedDeviceShow(true);
    }
  };

  const getCustomTag = (
    deviceItem: DevicesFields,
    contentList: DeviceItemFilterType[],
  ) => {
    return contentList.find(
      (deviceFeature) => deviceFeature.id === deviceItem.id,
    )?.tag;
  };

  const getImageInfo = (deviceImageData: ImageSizeData) => {
    const newImageData = { ...deviceImageData };
    newImageData.url = isExternal(deviceImageData.url)
      ? deviceImageData.url
      : `${process.env.STATIC_SERVER}${deviceImageData.url}`;
    return newImageData;
  };

  return (
    <>
      {filteredDevices.map((item) => (
        <React.Fragment key={item.id}>
          <DevicesCard
            customTag={getCustomTag(item, list)}
            item={item}
            onDeviceCardMouseUp={(e) => handleShowDetailedInfoClick(e, item)}
            imageInfo={getImageInfo(item.imageSize)}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default observer(DeviceListTabs);
