/* Значения табов оборудования */
export enum TABS_DEVICE {
  USE = 'В пользование',
  ANNUITY = 'Рассрочка',
  BUY = 'Покупка',
}

// id телеприставок UHD300X, Q-Box Ultra Wi-Fi

export enum NO_OWNERSHIP_STB_ID {
  UHD300X = 60,
  QBOXULTRAWIFI = 65,
}
