/** libraries */
import { FC, memo } from 'react';
import { useMediaQuery } from 'react-responsive';
/** components */
import {
  Header,
  MainText,
  ResultWrapper,
} from '~/components/Blocks/Templates/ChatlesExp/styles';
import Share from './Share';
import {
  defaultTheme,
  H2,
  H3,
  LeadingText,
  Text,
} from 'cordis-core-ui-planeta';
/** constants */
import { DRAW_DATE } from '~/components/Blocks/Templates/ChatlesExp/config';
import { desktop940 } from '~/components/Grid/constants';
/** interfaces */
import { ResultsProps } from '~/components/Blocks/Templates/ChatlesExp/interfaces';

const Result: FC<ResultsProps> = ({ resultsInfo, octagon }: ResultsProps) => {
  const { path, width, height } = octagon;

  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <>
      <ResultWrapper $path={path} $width={width} $height={height}>
        <MainText color={defaultTheme.colors.white} as={isDesktop ? H3 : Text}>
          Благодарим за доверие!{'\n'}Вы в Планете:
        </MainText>
        {isDesktop ? (
          <>
            <Header color={defaultTheme.colors.white} as={H2}>
              {resultsInfo.duration}
            </Header>
            <MainText color={defaultTheme.colors.white} as={Text}>
              У&#160;вас есть шанс выиграть{'\n'}
              {resultsInfo.chatles} бонусных рублей, розыгрыш состоится{' '}
              {DRAW_DATE}
            </MainText>
          </>
        ) : (
          <MainText color={defaultTheme.colors.white} as={LeadingText}>
            {resultsInfo.duration}
            {'\n'}У&#160;вас есть шанс выиграть{'\n'}
            {resultsInfo.chatles} бонусных рублей,{'\n'}розыгрыш состоится{' '}
            {DRAW_DATE}
          </MainText>
        )}
      </ResultWrapper>
      <Share />
    </>
  );
};

export default memo(Result);
