/** библиотеки */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop900, desktop940 } from '~/components/Grid/constants';

export const WarrantyPlusWizardResultStyled = styled.div`
  margin: -36px 0 0 0;

  & > h2 {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    padding: 1rem 0 0 0;
  }

  & svg,
  svg * {
    stroke: ${defaultTheme.colors.planeta};
  }

  @media (min-width: ${desktop940}px) {
    & > h2 {
      font-weight: 700;
      font-size: 44px;
      line-height: 46px;
    }
  }
`;

export const WarrantyPlusWizardStyled = styled.div`
  .warranty-plus-wizard {
    & .consent {
      & a {
        color: ${defaultTheme.colors.planeta};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__warning {
      background: ${defaultTheme.colors.backgroundWarning};
      padding: 1rem;
      margin: 1rem 0;
    }

    &__block {
      display: flex;
      margin: 0 0 1rem 0;
      &-inner {
        &:nth-of-type(n + 2) {
          padding: 0 0 0 2rem;
        }
        > div > a {
          display: flex;
          align-items: center;
          & svg {
            margin: 0 0.5rem 0 0;
          }
        }
        & strong {
          font-weight: 700;
          font-size: 28px;
          line-height: 31px;
        }
      }
    }
  }

  @media (max-width: ${desktop900}px) {
    .warranty-plus-wizard {
      max-width: 100%;
      &__block {
        display: block;

        &-inner {
          margin: 0 0 1.5rem 0;
          &:nth-of-type(n + 2) {
            padding: 0;
          }
        }
      }
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
