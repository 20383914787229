/** Библиотеки */
import * as React from 'react';
import { Category, ProductCard } from '../MenuPopup.types';

/** Компоненты */
import { StyledProductCardMobile } from './style';

import CategoryItem from '../CategoryItem';

/**
 * Компонент карточки продукта
 * @returns {React.FC}
 */
const ProductItem: React.FC<ProductCard> = (productCard: ProductCard) => {
  const category = productCard as Category;
  return (
    <StyledProductCardMobile>
      <CategoryItem {...category} />
    </StyledProductCardMobile>
  );
};

export default ProductItem;
