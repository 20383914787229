import styled from '@emotion/styled';
/** Компоненты */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованный блок для popup */
export const StyledPopupWrapper = styled.div`
  position: relative;
`;

/** стилизованный блок подтверждения выбора города */
export const StyledConfirm = styled.div`
  padding-left: 24px;
  padding-right: 26px;
  height: 60px;
  display: flex;
  align-items: center;
  button {
    margin-left: 32px;
    margin-right: 20px;
  }
  background: ${defaultTheme.colors.planetaDark};

  .confirm__close-btn {
    margin-left: 20px;

    svg {
      stroke: ${defaultTheme.colors.white} !important;
      cursor: pointer;
    }
  }
`;

/** стилизованный список городов */
export const StyledList = styled.ul`
  list-style-type: none;
  li + li {
    margin-top: 18px;
  }
`;
