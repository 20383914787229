import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop1280 } from '~/components/Grid/constants';

interface StyledMenuPopupProps extends React.HTMLProps<HTMLDivElement> {
  open?: boolean;
}

/** стилизованный блок popup с развёрнутым меню */
export const StyledMenuPopup = styled.div<StyledMenuPopupProps>`
  ${({ open }): string => `
    height: calc(100% - 60px);
    overflow-x: auto;
    position: fixed;
    display: ${open ? 'flex' : 'none'};
    justify-content: center;
    left: 0;
    width: 100%;
    padding: 32px;
    padding-left: 19px;
    background: ${defaultTheme.colors.white};
    z-index: 101;
    top: 60px;

    .menu-popup-container {
      width: 100%;
      max-width: ${desktop1280}px;
      padding: 0 32px;
    }
  `}
`;

/** стилизованный блок с заголовком */
export const StyledPopupHeader = styled.div`
  position: relative;
  display: flex;
  padding-left: 13px;
  height: 57px;
  justify-content: right;
`;

/** стилизованный блок с контентом */
export const StyledPopupContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

/** стилизованный блок-колонка */
export const StyledContentColumn = styled.div`
  .column-title {
    padding-left: 13px;
    margin-bottom: 16px;
  }

  .column-item-1 {
    padding-right: 16px;
  }

  .column-item-2 {
    padding-right: 16px;
    min-width: 250px;
  }

  .column-item-3 {
    > li + li {
      margin-top: 32px;
    }
  }

  ul {
    list-style-type: none;
    li + li {
      margin-top: 16px;
    }
  }
`;
