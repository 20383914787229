/** libraries */
import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
/** components */
import PhoneConnection from './PhoneConnection';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
/** config */
import { PHONE_TEXT } from '~/components/Blocks/Templates/Pab2c/ContractState/config';
import { StyledHeader } from '~/components/Blocks/Templates/Pab2c/ContractState/style';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
`;

export type AppointedConnectionProps = {
  dt: string;
};

const AppointedConnection: FC<AppointedConnectionProps> = ({ dt }) => {
  const formattedDate = dt
    ? format(parseISO(dt), 'd MMMM, EEE', { locale: ru })
    : null;

  return (
    <Root>
      <Text color={defaultTheme.colors.white}>Дата подключения</Text>
      <StyledHeader color={defaultTheme.colors.white}>
        {formattedDate}
      </StyledHeader>
      <PhoneConnection text={PHONE_TEXT.APPOINTED} />
    </Root>
  );
};

export default memo(AppointedConnection);
