import * as React from 'react';
import { observer } from 'mobx-react';

/** Компоненты */
import { Popup } from 'cordis-core-ui-planeta';
import { Agreement } from './Agreement';
import { CustomAgreement } from './CustomAgreement';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Модальное окно соглашения.
 * */
const AgreementPopup = (): JSX.Element => {
  const {
    agreementStore: {
      setIsShowAgreement,
      customAgreementContent,
      isShowAgreement,
      setCustomAgreementContent,
    },
  } = useRootStore();

  const closePopup = () => {
    setIsShowAgreement(!isShowAgreement);
    setCustomAgreementContent(null);
  };

  return (
    <Popup
      show={isShowAgreement}
      onCloseClick={(): void => closePopup()}
      onOutsideClick={(): void => closePopup()}
      width="100%"
    >
      {customAgreementContent ? (
        <CustomAgreement
          title={customAgreementContent.title}
          body={customAgreementContent.body}
        />
      ) : (
        <Agreement />
      )}
    </Popup>
  );
};

export default observer(AgreementPopup);
