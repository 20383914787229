/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

interface StyledSpeedProps extends React.HTMLProps<HTMLDivElement> {
  isFtth?: boolean;
}

/** стилизованный блок скорости подключения */
export const StyledSpeed = styled.div<StyledSpeedProps>`
  ${({ isFtth }): string => `
    background: ${
      isFtth ? defaultTheme.colors.planeta : defaultTheme.colors.white
    };
    padding: 32px;
    height: 100%;

    .speed {
      &-lan {
        display: flex;
        margin-bottom: 14px;

        &__value {
          margin-bottom: 0;
          font-size: 76px;
          line-height: 76%;
          letter-spacing: -0.08em;
          
          @media (min-width: ${desktop1100}px){
            font-size: 116px;
          }
          
          @media (min-width: ${desktop1280}px){
            font-size: 140px;

          }
        }
        &__text {
          margin-left: 18px;
          font-size: 21px;
        }
      }

      &-ftth {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        &__value {
          margin-bottom: 0;
          font-size: 50px;
          line-height: 31px;
          letter-spacing: -0.05em;
          font-weight: 900;

          @media (min-width: ${desktop940}px){
            font-size: 50px;
            line-height: 31px;
          }
          
          @media (min-width: ${desktop1100}px){
            font-size: 62px;
            line-height: 46px;
          }

          @media (min-width: ${desktop1280}px){
            font-size: 77px;
            line-height: 62px;
          }
        }

        &__text {
          font-size: 21px;
          margin-bottom: 8px;
          margin-left: 5px;
        }
      }
    }

    .description {
      &__title {
        margin: 16px 0;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.03em;

        @media (min-width: ${desktop940}px){
          margin: 13px 0;
          font-size: ${isFtth ? '28px' : '21px'};
        }

        @media (min-width: ${desktop1100}px){
            font-size: 28px;
        }

        @media (min-width: ${desktop1280}px){
          margin: ${isFtth ? '16px 0' : '13px 0'};
          font-size: ${isFtth ? '44px' : '28px'};
          line-height: ${isFtth ? '101.8%' : '32px'};
      }
    }
  `}
`;

interface StyledSpeedProgressProps extends React.HTMLProps<HTMLDivElement> {
  progress?: number;
}

/** стилизованный прогресс-бар скорости */
export const StyledSpeedProgress = styled.div<StyledSpeedProgressProps>`
  ${({ progress }): string => `
    position: relative;
    max-width: 336px;

    .progress-wrapper {
      width: ${progress}%;  
      z-index: 2;
      overflow: hidden;

      &__blur {
        position: absolute;
        top: -25px;
        right: -45px;
      }
    }

    .imageWrapper {
      width: 100%;
      height: 20px;
      position: relative;
    }

    .progress-helper {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      opacity: 0.2;
      overflow: hidden;
      z-index: 1;
      animation-name: progress-helper;
      animation-duration: 1.5s;
    }

    .progress-blur_animation {
      animation-name: progress-blur;
      animation-duration: 1.5s;
      animation-timing-function: ease-out;
    }

    @keyframes progress-blur {
      from {
        opacity: 0;
        right: 80%;
      }
      to {
        opacity: 100%;
      }
    }
  `}
`;
