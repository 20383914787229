/** libraries */
import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { LeadingText, defaultTheme, Text } from 'cordis-core-ui-planeta';
/** interfaces */
import {
  APPEAL_STATUS,
  AppealData,
  MY_APPEALS_STATE,
} from '../Blocks/Templates/Pab2c/Help/interfaces';
/** styles */
import { StyledMyAppeals } from './styles';
/** components */
import VisibilityObserver from '~/components/VisibilityObserver';
import AppealCard from '../Blocks/Templates/Pab2c/Help/Components/AppealCard/AppealCard';
/** constants */
import { DEMAND_QP } from '~/components/Blocks/Templates/Pab2c/Help/constants';

interface MyAppealsProps {
  list: AppealData[];
  setAppeal: Dispatch<SetStateAction<AppealData>>;
  setState: Dispatch<SetStateAction<MY_APPEALS_STATE>>;
}

const MyAppeals: FC<MyAppealsProps> = ({
  list,
  setAppeal,
  setState,
}: MyAppealsProps) => {
  const router = useRouter();
  /** Получен ответ */
  const [answerReceivedList, setAnswerReceivedList] = useState<AppealData[]>(
    [],
  );
  /** Открытые */
  const [openList, setOpenList] = useState<AppealData[]>([]);
  /** Закрытые */
  const [allClosedList, setAllClosedList] = useState<AppealData[]>([]);
  /** Закрытые порциями */
  const [closedList, setClosedList] = useState<AppealData[]>();

  const lastClosedDemandRef: RefObject<HTMLDivElement> | null = useRef(null);

  useEffect(() => {
    if (!router.isReady || !router.query?.[DEMAND_QP]) return;
    const demand = list.find(
      (dem) => dem.id === Number(router.query?.[DEMAND_QP]),
    );
    if (!demand) return;
    onClickAppeal(demand);
  }, [router.isReady]);

  const getLists = () => {
    const answerReceived = [];
    const open = [];
    const allClosed = [];

    list.forEach((item) => {
      if (item.unreadCommentCount > 0) {
        answerReceived.push(item);
      } else if (
        [APPEAL_STATUS.IN_WORK, APPEAL_STATUS.RAW].includes(item.stateCode) &&
        item.unreadCommentCount === 0
      ) {
        open.push(item);
      } else if (
        [APPEAL_STATUS.REJECT, APPEAL_STATUS.CLOSED].includes(item.stateCode) &&
        item.unreadCommentCount === 0
      ) {
        allClosed.push(item);
      }
    });

    setAnswerReceivedList(answerReceived);
    setOpenList(open);
    setAllClosedList(allClosed.reverse());
  };

  useEffect(() => {
    getLists();
  }, [list]);

  /** Получает 10 закрытых обращений */
  const getTenClosed = () => {
    const firstTen = allClosedList.slice(0, 9);
    allClosedList.splice(0, 9);
    return firstTen;
  };
  useEffect(() => {
    setClosedList(getTenClosed());
  }, [allClosedList]);

  /** Клик по карточке */
  const onClickAppeal = (item: AppealData) => {
    setAppeal(item);
    setState(MY_APPEALS_STATE.APPEAL);
  };

  // Дозагрузка закрытых
  const getCloseList = () => {
    setTimeout(
      () => setClosedList((prevItem) => [...prevItem, ...getTenClosed()]),
      10,
    );
  };

  if (!answerReceivedList?.length && !openList?.length && !closedList?.length) {
    return (
      <StyledMyAppeals>
        <div className="appeals__no-hits">
          <LeadingText color={defaultTheme.colors.black}>
            Нет обращений
          </LeadingText>
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Обратитесь в службу поддержки клиентов, отправив сообщение.
          </Text>
        </div>
      </StyledMyAppeals>
    );
  }

  const getAppealsList = (appealsList): JSX.Element[] => {
    return appealsList.map((item, index) => {
      if (
        index === appealsList.length - 1 &&
        allClosedList.length > closedList.length
      ) {
        return (
          <VisibilityObserver
            key={item.id}
            onElementVisible={getCloseList}
            targetRef={lastClosedDemandRef}
          >
            <div ref={lastClosedDemandRef} onClick={() => onClickAppeal(item)}>
              <AppealCard
                number={item.id}
                status={item.stateCode}
                date={item.createdDate}
                text={item.mem}
                unreadCommentCount={item.unreadCommentCount}
              />
            </div>
          </VisibilityObserver>
        );
      }
      return (
        <div key={item.id} onClick={() => onClickAppeal(item)}>
          <AppealCard
            number={item.id}
            status={item.stateCode}
            date={item.createdDate}
            text={item.mem}
            unreadCommentCount={item.unreadCommentCount}
          />
        </div>
      );
    });
  };

  return (
    <StyledMyAppeals>
      {answerReceivedList.length > 0 && (
        <div className="appeals__block">
          <LeadingText color={defaultTheme.colors.black}>
            Получен ответ
          </LeadingText>
          <div className="appeals__block__cards">
            {getAppealsList(answerReceivedList)}
          </div>
        </div>
      )}
      {openList.length > 0 && (
        <div className="appeals__block">
          <LeadingText color={defaultTheme.colors.black}>Открытые</LeadingText>
          <div className="appeals__block__cards">
            {getAppealsList(openList)}
          </div>
        </div>
      )}
      {closedList.length > 0 && (
        <div className="appeals__block">
          <LeadingText color={defaultTheme.colors.black}>Закрытые</LeadingText>
          <div className="appeals__block__cards">
            {getAppealsList(closedList)}
          </div>
        </div>
      )}
    </StyledMyAppeals>
  );
};

export default MyAppeals;
