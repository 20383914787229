/** libraries */
import { toJS } from 'mobx';
import { types, flow, Instance, applySnapshot, cast } from 'mobx-state-tree';
import {
  getCallPrice,
  getForwardingRules,
  getPhoneStatistics,
  getTariffVoicePrice,
} from '~/api/apiPab2c';
import {
  ForwardingRuleModel,
  PhoneModel,
  PriceModel,
  ResultPropsModel,
  SelectPropsModel,
  StatisticPropsModel,
} from '~/stores/models/Pab2cVoiceModel';
import createApiPathModel from '~/stores/models/createApiPathModel';
import {
  checkDirectionPhoneNumber,
  convertExPhoneNumberForQuery,
  convertRuPhoneNumberForQuery,
} from '../utils';
import { DEFAULT_STATE, DIRECTION } from '../constants';
import { ForwardingRule, Phone, ResultProps, SelectProps } from '../interfaces';

const RequestsStateModel = types.model('State', {
  getPhoneStatistics: createApiPathModel('GET /Phone/Phone/GetPhoneStatistics'),
  getForwarding: createApiPathModel('GET /Phone/Phone/GetForwardingRules'),
  getTariffVoicePrice: createApiPathModel(
    'GET /Phone/Phone/GetTariffVoicePrice',
  ),
});

const VoiceModel = types
  .model({
    requestsState: RequestsStateModel,
    /** Соглашение для отключения номера телефона */
    agreement: types.string,
    /** Статистика */
    statisticsData: types.array(StatisticPropsModel),
    /** Select начала периода */
    beginningData: types.maybeNull(SelectPropsModel),
    /** Select конец периода */
    endData: types.maybeNull(SelectPropsModel),
    /** Показать статистику */
    isShowStatistics: types.boolean,
    /** Сайдпейдж "подключить номер" */
    isShowConnectNumber: types.boolean,
    /** Результат сайдпейджа */
    resultData: ResultPropsModel,
    /** Сайдпейдж пароля */
    isShowPassword: types.boolean,
    /** Создать пароль */
    isCreatePassword: types.boolean,
    /** Телефон для изменения пароля/отключения номера */
    phonePassword: types.maybeNull(PhoneModel),
    /** Переадресация */
    forwardingData: types.array(ForwardingRuleModel),
    /** Редактируемое правило */
    editableRuleData: types.maybeNull(ForwardingRuleModel),
    isLoadingConnectNumber: types.boolean,
    /** Сайдпейдж отключения номера */
    isShowNumberDeactivation: types.boolean,
    /** Сайдпейдж "создать правило" */
    isShowCreatingRule: types.boolean,
    /** Создание правила */
    isCreate: types.boolean,
    /** Редактирование правила */
    isEdit: types.boolean,
    /** sim id для отключения номера */
    sim: types.maybeNull(types.number),
    /** Сайдпейдж отмены отключения номера */
    isShowCancelNumberDeactivation: types.boolean,
    /** Цены на звонки */
    prices: types.array(PriceModel),
  })
  .views((self) => ({
    get statistics() {
      return toJS(self.statisticsData);
    },
    get beginning() {
      return toJS(self.beginningData);
    },
    get end() {
      return toJS(self.endData);
    },
    get result() {
      return toJS(self.resultData);
    },
    get forwarding() {
      return toJS(self.forwardingData);
    },
    get editableRule() {
      return toJS(self.editableRuleData);
    },
    get isLoadingStatistics() {
      return self.requestsState.getPhoneStatistics.isLoading;
    },
    get isErrorStatistics() {
      return self.requestsState.getPhoneStatistics.hasError;
    },
    get isLoadingForwarding() {
      return self.requestsState.getForwarding.isLoading;
    },
    get isErrorForwarding() {
      return self.requestsState.getForwarding.hasError;
    },
    get isCorrectForwarding() {
      return self.requestsState.getForwarding.isFetched;
    },
  }))
  .actions((self) => {
    return {
      /** Получает статистику звонков */
      getStatistics: flow(function* () {
        self.requestsState.getPhoneStatistics.reset();
        self.requestsState.getPhoneStatistics.setLoading();
        try {
          const res = yield getPhoneStatistics(
            self.beginning.value.split(' ')[0],
            self.end.value.split(' ')[1],
            true,
          );
          self.statisticsData = res ?? [];
          self.requestsState.getPhoneStatistics.setSuccess();
        } catch (e) {
          console.error('getPhoneStatistics', e);
          self.requestsState.getPhoneStatistics.setFail();
        }
      }),
      /** Получает список переадресации */
      getForwarding: flow(function* () {
        self.requestsState.getForwarding.reset();
        self.requestsState.getForwarding.setLoading();
        try {
          const res = yield getForwardingRules();
          const forwarfingWithPrice = res.map(async (item) => {
            const direction = checkDirectionPhoneNumber(item.forwardNumber);
            const query =
              direction === DIRECTION.internal
                ? convertRuPhoneNumberForQuery(item.forwardNumber)
                : convertExPhoneNumberForQuery(item.forwardNumber);
            const price = await getCallPrice(direction, query);
            return {
              ...item,
              price,
            };
          });
          Promise.all(forwarfingWithPrice).then((results) => {
            applySnapshot(self.forwardingData, results);
            self.requestsState.getForwarding.setSuccess();
          });
        } catch (e) {
          console.error('getForwardingRules', e);
          self.requestsState.getForwarding.setFail();
        }
      }),
      /** Получает цены на звонки */
      getPrices: flow(function* (tariffId: number) {
        self.requestsState.getTariffVoicePrice.reset();
        self.requestsState.getTariffVoicePrice.setLoading();
        try {
          const res = yield getTariffVoicePrice(tariffId);
          self.prices = res ?? [];
          self.requestsState.getTariffVoicePrice.setSuccess();
        } catch (e) {
          console.error('getTariffVoicePrice', e);
          self.requestsState.getTariffVoicePrice.setFail();
        }
      }),
      setBeginning: (select: SelectProps) => {
        self.beginningData = select;
      },
      setEnd: (select: SelectProps) => {
        self.endData = select;
      },
      setIsShowStatistics: (isShow: boolean) => {
        self.isShowStatistics = isShow;
      },
      setIsShowConnectNumber: (isShow: boolean) => {
        self.isShowConnectNumber = isShow;
      },
      setResult: (result: ResultProps) => {
        applySnapshot(self.resultData, result);
      },
      setIsShowPassword: (isShow: boolean) => {
        self.isShowPassword = isShow;
      },
      setIsCreatePassword: (isCreate: boolean) => {
        self.isCreatePassword = isCreate;
      },
      setPhonePassword: (phone: Phone) => {
        self.phonePassword = phone;
      },
      setEditableRule: (forwardingRule: ForwardingRule | null) => {
        self.editableRuleData = cast(forwardingRule);
      },
      setIsLoadingConnectNumber: (isLoading: boolean) => {
        self.isLoadingConnectNumber = isLoading;
      },
      setIsShowNumberDeactivation: (isShow: boolean) => {
        self.isShowNumberDeactivation = isShow;
      },
      setIsShowCreatingRule: (isShow: boolean) => {
        self.isShowCreatingRule = isShow;
      },
      setIsCreate: (isCreate: boolean) => {
        self.isCreate = isCreate;
      },
      setIsEdit: (isEdit: boolean) => {
        self.isEdit = isEdit;
      },
      setSim: (sim: number) => {
        self.sim = sim;
      },
      setIsShowCancelNumberDeactivation: (isShow: boolean) => {
        self.isShowCancelNumberDeactivation = isShow;
      },
      setAgreement: (agr) => {
        self.agreement = agr;
      },
      resetVoice: () => {
        applySnapshot(self, DEFAULT_STATE);
      },
    };
  });

export default VoiceModel;
export type IVoiceStore = Instance<typeof VoiceModel>;
