import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
`;

export const Header = styled.span<{ $isMobile?: boolean }>`
  margin-bottom: 17px;
  ${({ $isMobile }) =>
    !$isMobile
      ? css`
          margin-top: 10rem;
        `
      : ''}
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'space-between' : 'center'};
  width: 100%;

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            text-align: left;

            p {
              margin-bottom: 32px;
            }
          `
        : css`
            margin-top: 2rem;
          `}
  }
`;
