/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icons,
  SidePage,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** styles */
import { StyledViewControl } from './styles';

/** constants */
import { CardStates, STORE_TYPE, TEXT } from '../../../constants';
import { desktop940 } from '~/components/Grid/constants';
import { INITIAL_RESULT } from '../../common/SidePageResult/constants';
import { MOUNTHS } from '~/components/Blocks/Templates/MyStoriesContest/constants';

/** components */
import Row from '../../common/RowContent';
import ToggleTabs from '../../common/ToggleTabs';
import ChannelPackageCard from '../../Cards/ChannelPackageCard/ChannelPackageCard';
import ViewControlConnectionWizard from '../../SidePages/ViewControlConnectionWizard/ViewControlConnectionWizard';

/** interfaces */
import { ConnectionStates } from '../../Cards/ServiceCards/interfaces';
import { ResultProps } from '../../common/SidePageResult/types';
import { ChannelPackage } from '../../../interfaces';

/** utils */
import Portal from '~/components/Portal/Portal';
import { getDate, getStore, isSameDate } from '../../../utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';

/** api */
import {
  checkITvTimeshiftBindCondition,
  checkITvTimeshiftUnbindCondition,
} from '~/api/apiPab2c';

const ViewControl: FC = () => {
  const {
    tvStore: {
      tvChannelPackages,
      connectedTVPackages,
      transformerInfo,
      viewControlInfo,
      idsAddedPacketsToTransformer,
    },
  } = useRootStore();
  const { storeType } = useMyTvStore();
  const { setCardState, setActivePackageCard, onClickPackageCard } = getStore(
    storeType,
  );
  const isLightbox = storeType === STORE_TYPE.MY_TV_X2;

  const {
    isOrdered,
    price,
    pendingRejectionDate,
    banTrimDate,
    currentTypeCode,
  } = viewControlInfo;

  /** Дата отключения сервиса */
  const [shutdownDate, setShutdownDate] = useState<string>('');

  /** Пакеты с отказом */
  const [connectedRejectedPackages, setConnectedRejectedPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Подключённые пакеты */
  const [connectedPackages, setConnectedPackages] = useState<ChannelPackage[]>(
    [],
  );

  /** Убранные пакеты из трансформера с УП */
  const [removedPackages, setRemovedPackages] = useState<ChannelPackage[]>([]);

  /** Добавленные в трансформер пакеты, в которых есть управление просмотром */
  const [addedToTransformerPackages, setAddedToTransformerPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Подключённые пакеты с услугой "Управление просмотра" */
  const connectedPackagesWithViewControl = useMemo(() => {
    return tvChannelPackages.filter((item) => {
      /** Подключённый пакет */
      const connectedPacket = connectedTVPackages.find(
        (pack) => pack.channelPackId === item.channelPackId,
      );
      return item.isTimeshiftIncluded && connectedPacket;
    });
  }, [tvChannelPackages, connectedTVPackages]);

  /** Не подключённые пакеты с услугой "Управление просмотра" */
  const packages = useMemo(() => {
    return tvChannelPackages.filter((item) => {
      /** Подключённый пакет */
      const connectedPacket = connectedTVPackages.find(
        (pack) => pack.channelPackId === item.channelPackId,
      );
      return item.isTimeshiftIncluded && !connectedPacket;
    });
  }, [tvChannelPackages, connectedTVPackages]);

  /** Статус подключения */
  const connection = useMemo(() => {
    /** Сервис подключён в пакете */
    if (
      connectedPackagesWithViewControl?.length > 0 &&
      isOrdered &&
      currentTypeCode !== 'timeshift.single'
    ) {
      /** Подключённые пакеты с УП */
      const connectedPacks = connectedPackagesWithViewControl.filter(
        (item) =>
          ((transformerInfo &&
            idsAddedPacketsToTransformer?.includes(item.channelPackId)) ||
            !transformerInfo) &&
          !item.unbindDt,
      );

      /** Подключён в пакетах */
      if (connectedPacks.length) {
        setConnectedPackages((connectedPacks as unknown) as ChannelPackage[]);
        return ConnectionStates.ConnectedInAPackage;
      }

      /** Добавлен в пакете */
      if (transformerInfo && idsAddedPacketsToTransformer?.length) {
        /** Добавленные в трансформер пакеты, в которых есть управление просмотром */
        const addedPackages = idsAddedPacketsToTransformer?.length
          ? tvChannelPackages.reduce((acc, item) => {
              if (
                idsAddedPacketsToTransformer.includes(item.id) &&
                item.isTimeshiftIncluded
              ) {
                acc.push(item);
              }
              return acc;
            }, [])
          : null;
        if (addedPackages.length) {
          setAddedToTransformerPackages(addedPackages);
          return ConnectionStates.AddedToTransformerInPackage;
        }
      }

      /** Из трансформера убран пакет, в котором находился канал */
      if (
        transformerInfo &&
        connectedPackagesWithViewControl.length &&
        !connectedPackagesWithViewControl.every((item) =>
          idsAddedPacketsToTransformer.includes(item.channelPackId),
        )
      ) {
        setRemovedPackages(
          (connectedPackagesWithViewControl.filter(
            (item) => !item.unbindDt,
          ) as unknown) as ChannelPackage[],
        );
        return ConnectionStates.RemovedFromTheTransformerInPackage;
      }

      /** Пакеты, в которых оформлен отказ */
      const rejectedPackets = connectedPackagesWithViewControl.filter(
        (item) => item.unbindDt,
      );
      if (rejectedPackets.length) {
        setConnectedRejectedPackages(
          (rejectedPackets as unknown) as ChannelPackage[],
        );
        return ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected;
      }
    }

    /** Подключён */
    if (isOrdered && !pendingRejectionDate) {
      return ConnectionStates.Connected;
    }

    /** Отказ от сервиса */
    if (pendingRejectionDate) {
      const date = new Date(parseISO(pendingRejectionDate));
      setShutdownDate(
        `${date.getDate().toString()} ${
          MOUNTHS[date.getMonth()]
        } ${date.getFullYear().toString()} года`,
      );
      return ConnectionStates.RefusalIssued;
    }
    return ConnectionStates.NotConnected;
  }, [
    viewControlInfo,
    connectedPackagesWithViewControl,
    transformerInfo,
    idsAddedPacketsToTransformer,
  ]);

  /** Отключение доступно сразу */
  const isRejectionAvailable = useMemo(
    () =>
      banTrimDate
        ? isBefore(new Date(parseISO(banTrimDate)), new Date())
        : true,
    [banTrimDate],
  );

  /** Дата, после которой возможен отказ от сервиса */
  const refusalDate = (connectedPackageDate?: string) => {
    if (!banTrimDate && !connectedPackageDate) return null;
    if (transformerInfo) {
      return `${getDate(connectedPackageDate ?? banTrimDate)} года`;
    }
    if (!isRejectionAvailable) return getDate(banTrimDate, 'dd.MM.yyyy');
    return '';
  };

  /** Отказ от сервиса */
  const isRejectionOfThePackage = [
    ConnectionStates.RefusalIssued,
    ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected,
  ].includes(connection);

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if ([ConnectionStates.AddedToTransformerInPackage].includes(connection))
        return defaultTheme.colors.planeta;
      if (
        [ConnectionStates.RemovedFromTheTransformerInPackage].includes(
          connection,
        )
      )
        return defaultTheme.colors.black;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if ([ConnectionStates.AddedToTransformerInPackage].includes(connection))
        return defaultTheme.colors.pink;
      if (
        [ConnectionStates.RemovedFromTheTransformerInPackage].includes(
          connection,
        )
      )
        return defaultTheme.colors.disable;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isDesktop940) return undefined;

      /** Подключён в пакетах */
      if (connection === ConnectionStates.ConnectedInAPackage) {
        if (connectedPackages?.length === 1) {
          return `Подключён в пакете ${connectedPackages[0]?.channelPackName}`;
        }
        /** Много пакетов */
        if (connectedPackages?.length > 1) {
          return `Подключён в пакетах ${connectedPackages
            .map((item) => item.channelPackName)
            .join(', ')}`;
        }
      }

      /** Добавлены пакеты */
      if (connection === ConnectionStates.AddedToTransformerInPackage) {
        if (addedToTransformerPackages?.length === 1) {
          return `Добавлен в пакете ${addedToTransformerPackages[0]?.channelPackName}. Будет подключён при применении трансформера`;
        }
        /** Много пакетов */
        if (addedToTransformerPackages?.length > 1) {
          return `Добавлен в пакетах ${addedToTransformerPackages
            .map((item) => item.channelPackName)
            .join(', ')}. Будет подключён при применении трансформера`;
        }
        return 'Будет подключён при применении трансформера';
      }

      /** Удалён из трансформера в пакете */
      if (connection === ConnectionStates.RemovedFromTheTransformerInPackage) {
        const stringDate = removedPackages[0]?.banTrimDate
          ? `${getDate(removedPackages[0]?.banTrimDate)} года`
          : '';
        if (removedPackages.length === 1) {
          return isRejectionAvailable
            ? `Будет отключён в составе пакета ${removedPackages[0]?.channelPackName} при применении трансформера`
            : `Будет оформлен отказ от сервиса в составе пакета ${removedPackages[0]?.channelPackName} с ${stringDate} при применении трансформера`;
        }

        if (removedPackages.length > 1) {
          if (isRejectionAvailable) {
            return `Будет отключён в составе пакетов ${removedPackages
              .map((item) => item.channelPackName)
              .join(', ')} при применении трансформера`;
          }
          /** Дата отключения одинаковая для все пакетов */
          if (isSameDate(removedPackages)) {
            return `Будет оформлен отказ от сервиса в составе пакетов ${removedPackages
              .map((item) => item.channelPackName)
              .join(', ')} с ${stringDate} при применении трансформера`;
          }
          return `Будет оформлен отказ от сервиса в составе пакетов ${removedPackages
            .map((item) => {
              const banDate = item.banTrimDate
                ? `${getDate(item.banTrimDate)} года`
                : '';
              return `${item.channelPackName}
                ${banDate}`;
            })
            .join(', ')} при применении трансформера`;
        }
      }
      /** Оформлен отказ */
      if (connection === ConnectionStates.RefusalIssued)
        return `Оформлен отказ с ${shutdownDate}`;

      /** Оформлен отказ от пакетов */
      if (
        connection ===
        ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected
      ) {
        const date = new Date(parseISO(connectedRejectedPackages[0]?.unbindDt));
        const stringDate = `${date.getDate().toString()} ${
          MOUNTHS[date.getMonth()]
        } ${date.getFullYear().toString()} года`;
        if (connectedRejectedPackages?.length === 1) {
          return `Оформлен отказ от сервиса в составе пакета ${connectedRejectedPackages[0]?.channelPackName} с ${stringDate}`;
        }
        if (connectedRejectedPackages?.length > 1) {
          return isSameDate(connectedRejectedPackages)
            ? `Оформлен отказ от сервиса в составе пакетов ${connectedRejectedPackages
                .map((item) => item.channelPackName)
                .join(', ')} с ${stringDate}`
            : `Оформлен отказ от сервиса в составе пакетов ${connectedRejectedPackages
                .map((item) => {
                  const unbindDate = item.unbindDt
                    ? `${getDate(item.unbindDt)} года`
                    : '';
                  return `${item.channelPackName} c ${unbindDate}`;
                })
                .join(', ')}`;
        }
      }
      return undefined;
    };
    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          !isRejectionOfThePackage &&
          ![
            ConnectionStates.AddedToTransformerInPackage,
            ConnectionStates.RemovedFromTheTransformerInPackage,
          ].includes(connection) &&
          colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = (): string | boolean => {
    switch (connection) {
      case ConnectionStates.AddedToTransformerInPackage:
        return addedToTransformerPackages.length > 1
          ? TEXT.ADDED_IN_PACKAGES
          : TEXT.ADDED_IN_PACKAGE;
      case ConnectionStates.RemovedFromTheTransformerInPackage:
        return removedPackages.length > 1
          ? TEXT.REMOVED_PACKAGES
          : TEXT.REMOVED_PACKAGE;
      case ConnectionStates.Connected:
        return TEXT.CONNECTED;
      case ConnectionStates.ConnectedInAPackage:
        return connectedPackages?.length > 1
          ? TEXT.CONNECTED_IN_PACKAGES
          : TEXT.CONNECTED_IN_PACKAGE;
      case ConnectionStates.RefusalIssued:
        return TEXT.CANCELLATION;
      case ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected:
        return connectedRejectedPackages?.length > 1
          ? TEXT.REJECTION_OF_PACKAGES
          : TEXT.REJECTION_OF_PACKAGE;
      default:
        return false;
    }
  };

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Сайдпейдж подключения/отключения пакета */
  const [isOpenConnectionSP, setIsOpenConnectionSP] = useState<boolean>(false);

  /** Результат подключения/отключения пакета */
  const [result, setResult] = useState<ResultProps>(INITIAL_RESULT);

  /** true - подключение пакета, false - отключение пакета */
  const [isConnection, setIsConnection] = useState<boolean>(true);

  /** Событие подключения/отключения пакета */
  const disablePackage = () => {
    setIsConnection(false);
    setIsOpenConnectionSP(true);
  };

  /** disable кнопки */
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  /** Загрузка кнопки */
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(true);

  /** Проверяет, можно ли для договора подключить услугу "Управление просмотром" */
  const proofITvTimeshiftBindCondition = async () => {
    try {
      await checkITvTimeshiftBindCondition();
      setIsDisableButton(false);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Проверяет, можно ли для договора отключить услугу "Управление просмотром" */
  const proofITvTimeshiftUnbindCondition = async () => {
    try {
      await checkITvTimeshiftUnbindCondition();
      setIsDisableButton(false);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    if (isOrdered) {
      proofITvTimeshiftUnbindCondition();
    } else {
      proofITvTimeshiftBindCondition();
    }
  }, [isOrdered]);

  return (
    <StyledViewControl>
      <div className="view-control__header">
        <div className="view-control__header__name-tag">
          <H3>Управление просмотром</H3>
          <div className="view-control__header__name-tag__tag-package">
            {connection !== ConnectionStates.NotConnected &&
              customTag(connectionStatusText())}
            {connection === ConnectionStates.ConnectedInAPackage && (
              <Text color={defaultTheme.colors.planeta}>
                {connectedPackages
                  .map((item) => (
                    <Text
                      className="view-control__link"
                      color={defaultTheme.colors.planeta}
                      onClick={() => {
                        setActivePackageCard(item);
                        setCardState(CardStates.PACKAGE_DESCRIPTION);
                      }}
                    >
                      {item.channelPackName}
                    </Text>
                  ))
                  .reduce(
                    (acc, x) =>
                      acc === null ? (
                        x
                      ) : (
                        <>
                          {acc}, {x}
                        </>
                      ),
                    null,
                  )}
              </Text>
            )}
            {connection === ConnectionStates.AddedToTransformerInPackage && (
              <Text color={defaultTheme.colors.planeta}>
                {addedToTransformerPackages
                  .map((item) => (
                    <Text
                      className="view-control__link"
                      color={defaultTheme.colors.planeta}
                      onClick={() => {
                        setActivePackageCard(item);
                        setCardState(CardStates.PACKAGE_DESCRIPTION);
                      }}
                    >
                      {item.channelPackName}
                    </Text>
                  ))
                  .reduce(
                    (acc, x) =>
                      acc === null ? (
                        x
                      ) : (
                        <>
                          {acc}, {x}
                        </>
                      ),
                    null,
                  )}
              </Text>
            )}
            {connection ===
              ConnectionStates.RemovedFromTheTransformerInPackage && (
              <Text color={defaultTheme.colors.planeta}>
                {removedPackages
                  .map((item) => (
                    <Text
                      className="view-control__link"
                      color={defaultTheme.colors.planeta}
                      onClick={() => {
                        setActivePackageCard(item);
                        setCardState(CardStates.PACKAGE_DESCRIPTION);
                      }}
                    >
                      {item.channelPackName}
                    </Text>
                  ))
                  .reduce(
                    (acc, x) =>
                      acc === null ? (
                        x
                      ) : (
                        <>
                          {acc}, {x}
                        </>
                      ),
                    null,
                  )}
              </Text>
            )}
            {connection ===
              ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected && (
              <Text color={defaultTheme.colors.planeta}>
                {connectedRejectedPackages
                  .map((item) => (
                    <Text
                      className="view-control__link"
                      color={defaultTheme.colors.planeta}
                      onClick={() => {
                        setActivePackageCard(item);
                        setCardState(CardStates.PACKAGE_DESCRIPTION);
                      }}
                    >
                      {item.channelPackName}
                    </Text>
                  ))
                  .reduce(
                    (acc, x) =>
                      acc === null ? (
                        x
                      ) : (
                        <>
                          {acc}, {x}
                        </>
                      ),
                    null,
                  )}
              </Text>
            )}
          </div>
        </div>
        {!isLightbox && (
          <div
            className="view-control__header__close-icon"
            onClick={() => setCardState(CardStates.MAIN)}
          >
            <Icons.CloseIcon />
          </div>
        )}
      </div>

      <Row>
        <Text lineHeight="24px">
          Смотрите любимые сериалы, фильмы и&nbsp;программы, когда удобно.
        </Text>
      </Row>

      {![
        ConnectionStates.ConnectedInAPackage,
        ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected,
        ConnectionStates.AddedToTransformerInPackage,
        ConnectionStates.RemovedFromTheTransformerInPackage,
      ].includes(connection) && (
        <Row title="Стоимость подписки">
          <div className="view-control__subcription-cost">
            <ToggleTabs cost={price} />
            {connection === ConnectionStates.RefusalIssued && (
              <Text
                className="view-control__subcription-cost__refusal"
                lineHeight="24px"
                color={defaultTheme.colors.gray}
              >
                Списания прекратятся с {shutdownDate}
              </Text>
            )}
            {connection === ConnectionStates.NotConnected && (
              <Button
                onClick={() => {
                  setIsConnection(true);
                  setIsOpenConnectionSP(true);
                }}
                disabled={isDisableButton}
                loading={isLoadingButton}
              >
                {isDesktop940 ? 'Подключить сервис' : 'Подключить'}
              </Button>
            )}
          </div>
        </Row>
      )}

      <Row
        title="Преимущества сервиса"
        removeLine={
          !packages?.length ||
          (isLightbox && connection !== ConnectionStates.Connected)
        }
      >
        <div className="view-control__service-benefits">
          <div className="view-control__service-benefits__block">
            <Icons.TimeIcon />
            <Text>
              Удобное время
              <br />
              начала передачи
            </Text>
          </div>
          <div className="view-control__service-benefits__block">
            <Icons.RewindIcon />
            <Text>
              Перемотка на любой момент
              <br />
              передачи
            </Text>
          </div>
          <div className="view-control__service-benefits__block">
            <Icons.PausePlayIcon />
            <Text>
              Пауза и просмотр
              <br />
              передач
            </Text>
          </div>
          <div className="view-control__service-benefits__block">
            <Icons.CalendarIcon />
            <Text>
              Архив передач
              <br />
              до 7 дней
            </Text>
          </div>
          <div className="view-control__service-benefits__block">
            <Icons.DevicesIcon />
            <Text>
              Доступно на телевизоре
              <br />и компьютере
            </Text>
          </div>
          <div className="view-control__service-benefits__block">
            <Icons.PlayTVIcon />
            <Text>
              Все любимые телеканалы,
              <br />
              включая HD-каналы
            </Text>
          </div>
        </div>
      </Row>

      {packages?.length > 0 && !isLightbox && (
        <Row
          title={`Сервис в пакетах с${'\u00A0'}телеканалами`}
          removeLine={[
            ConnectionStates.NotConnected,
            ConnectionStates.RefusalIssued,
            ConnectionStates.ConnectedInAPackage,
            ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected,
            ConnectionStates.AddedToTransformerInPackage,
            ConnectionStates.RemovedFromTheTransformerInPackage,
          ].includes(connection)}
        >
          <div className="view-control__packages">
            {packages?.length > 0 &&
              packages.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
          </div>
        </Row>
      )}

      {[ConnectionStates.Connected].includes(connection) && (
        <Row title="Управление подпиской" removeLine>
          <div className="view-control__subscription-management">
            <Button
              styleType={ButtonStyleTypes.SECONDARY}
              onClick={disablePackage}
              disabled={isDisableButton}
              loading={isLoadingButton}
            >
              {isDesktop940 ? 'Отключить сервис' : 'Отключить'}
            </Button>
            {isRejectionAvailable ? (
              <Snoska>Станет недоступен сразу после отключения</Snoska>
            ) : (
              <Snoska>Отключение доступно с&nbsp;{refusalDate()}</Snoska>
            )}
          </div>
        </Row>
      )}
      <Portal>
        <SidePage
          show={isOpenConnectionSP}
          headerText={
            !result.isResult &&
            `${
              isConnection ? 'Подключение' : 'Отключение'
            } сервиса Управление просмотром`
          }
          onCloseClick={() => {
            setIsOpenConnectionSP(false);
            setResult(INITIAL_RESULT);
          }}
        >
          <ViewControlConnectionWizard
            isConnection={isConnection}
            result={result}
            setResult={setResult}
          />
        </SidePage>
      </Portal>
    </StyledViewControl>
  );
};

export default observer(ViewControl);
