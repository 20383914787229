import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const Ball = styled.div`
  position: absolute;
  top: 65px;
  left: -20px;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  z-index: 1;
`;

export const MobileBallText = styled.span`
  position: relative;
  top: 36px;
  user-select: none;
`;

export const MobileBall = styled.div<{ $textColor: string }>`
  position: absolute;
  top: -35px;
  left: 0;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  z-index: 1;
  text-align: center;

  ${MobileBallText} {
    color: ${({ $textColor }) => $textColor};
  }
`;

export default styled.div<{
  $backgroundColor: string;
  $isMobile?: boolean;
  $nullDate?: boolean;
}>`
  position: relative;
  width: ${({ $isMobile }) => ($isMobile ? '100%' : '360px')};
  border-radius: 50px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  ${({ $nullDate }) =>
    $nullDate ? 'padding: 2rem;' : 'padding: 64px 2rem 2rem;'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-top-left-radius: 11px;
      margin-top: 51px;
    `}

  ${Ball}, ${MobileBall} {
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }

  button {
    background-color: transparent;
    border: 2px solid ${defaultTheme.colors.white};
  }

  @media (min-width: ${desktop940}px) {
    padding: 64px 2rem 2rem;
    margin-bottom: 32px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 466px;
  }
`;

export const TextWrapper = styled.div<{ $textColor: string }>`
  z-index: 2;

  span {
    color: ${({ $textColor }) => $textColor};
  }
`;
