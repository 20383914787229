/** libraries */
import { FC, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  LeadingText,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { ServiceNames, STORE_TYPE, TEXT } from '../../../constants';
import { MOUNTHS } from '~/components/Blocks/Templates/MyStoriesContest/constants';

/** interfaces */
import { ConnectionStates, ServiceCardFieldsProps } from './interfaces';
import { ChannelPackage } from '../../../interfaces';

/** styles */
import { ServiceCardStyled } from './styles';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';

/** utils */
import { formatNumber } from '~/utils/utils';
import { getDate, isSameDate } from '../../../utils';

/**
 * Блок «ЛК. Моё телевидение»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=690527702
 */
const ServiceViewControlCard: FC<ServiceCardFieldsProps> = ({
  service,
  onClick,
}: ServiceCardFieldsProps) => {
  const {
    tvStore: {
      tvChannelPackages,
      connectedTVPackages,
      transformerInfo,
      viewControlInfo,
      idsAddedPacketsToTransformer,
    },
  } = useRootStore();
  const { storeType } = useMyTvStore();
  const isTelevision = storeType === STORE_TYPE.TELEVISION;

  const { name } = service;

  /** Цена */
  const [price, setPrice] = useState<number>(null);

  /** Дата отключения сервиса */
  const [shutdownDate, setShutdownDate] = useState<string>('');

  /** Пакеты с отказом */
  const [connectedRejectedPackages, setConnectedRejectedPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Подключённые пакеты */
  const [connectedPackages, setConnectedPackages] = useState<ChannelPackage[]>(
    [],
  );

  /** Убранные пакеты из трансформера с УП */
  const [removedPackages, setRemovedPackages] = useState<ChannelPackage[]>([]);

  /** Добавленные в трансформер пакеты, в которых есть управление просмотром */
  const [addedToTransformerPackages, setAddedToTransformerPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Подключённые пакеты с услугой "Управление просмотра" */
  const connectedPackagesWithViewControl = useMemo(() => {
    if (isTelevision) return [];
    return tvChannelPackages.filter((item) => {
      /** Подключённый пакет */
      const connectedPacket = connectedTVPackages.find(
        (pack) => pack.channelPackId === item.channelPackId,
      );
      return item?.isTimeshiftIncluded && connectedPacket;
    });
  }, [tvChannelPackages, connectedTVPackages]);

  const connection = useMemo(() => {
    if (isTelevision) return ConnectionStates.NotConnected;
    switch (name) {
      case ServiceNames.VIEW_CONTROL:
        setPrice(viewControlInfo?.price ?? null);
        /** Сервис подключён в пакете */
        if (
          connectedPackagesWithViewControl?.length > 0 &&
          viewControlInfo?.isOrdered &&
          viewControlInfo?.currentTypeCode !== 'timeshift.single'
        ) {
          /** Подключённые пакеты с УП */
          const connectedPacks = connectedPackagesWithViewControl.filter(
            (item) =>
              ((transformerInfo &&
                idsAddedPacketsToTransformer?.includes(item.channelPackId)) ||
                !transformerInfo) &&
              !item.unbindDt,
          );

          /** Подключён в пакетах */
          if (connectedPacks.length) {
            setConnectedPackages(
              (connectedPacks as unknown) as ChannelPackage[],
            );
            return ConnectionStates.ConnectedInAPackage;
          }

          /** Добавлен в пакете */
          if (transformerInfo && idsAddedPacketsToTransformer?.length) {
            /** Добавленные в трансформер пакеты, в которых есть управление просмотром */
            const addedPackages = idsAddedPacketsToTransformer?.length
              ? tvChannelPackages.reduce((acc, item) => {
                  if (
                    idsAddedPacketsToTransformer.includes(item.id) &&
                    item.isTimeshiftIncluded
                  ) {
                    acc.push(item);
                  }
                  return acc;
                }, [])
              : null;
            if (addedPackages.length) {
              setAddedToTransformerPackages(addedPackages);
              return ConnectionStates.AddedToTransformerInPackage;
            }
          }

          /** Из трансформера убран пакет, в котором находился канал */
          if (
            transformerInfo &&
            connectedPackagesWithViewControl.length &&
            !connectedPackagesWithViewControl.every((item) =>
              idsAddedPacketsToTransformer.includes(item.channelPackId),
            )
          ) {
            setRemovedPackages(
              (connectedPackagesWithViewControl.filter(
                (item) => !item.unbindDt,
              ) as unknown) as ChannelPackage[],
            );
            return ConnectionStates.RemovedFromTheTransformerInPackage;
          }

          /** Пакеты, в которых оформлен отказ */
          const rejectedPackets = connectedPackagesWithViewControl.filter(
            (item) => item.unbindDt,
          );
          if (rejectedPackets.length) {
            setConnectedRejectedPackages(
              (rejectedPackets as unknown) as ChannelPackage[],
            );
            return ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected;
          }
        }

        /** Подключён */
        if (
          viewControlInfo?.isOrdered &&
          !viewControlInfo?.pendingRejectionDate
        ) {
          return ConnectionStates.Connected;
        }

        /** Отказ от сервиса */
        if (viewControlInfo?.pendingRejectionDate) {
          const date = new Date(
            parseISO(viewControlInfo?.pendingRejectionDate),
          );
          setShutdownDate(
            `${date.getDate().toString()} ${
              MOUNTHS[date.getMonth()]
            } ${date.getFullYear().toString()} года`,
          );
          return ConnectionStates.RefusalIssued;
        }
        return ConnectionStates.NotConnected;
      default:
        return ConnectionStates.NotConnected;
    }
  }, [
    viewControlInfo,
    connectedPackagesWithViewControl,
    transformerInfo,
    idsAddedPacketsToTransformer,
  ]);

  // Вычисление ширины экрана
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  // Отказ от пакета
  const isRejectionOfThePackage = [
    ConnectionStates.RefusalIssued,
    ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected,
  ].includes(connection);

  /** Отключение доступно сразу */
  const isRejectionAvailable = useMemo(
    () =>
      viewControlInfo?.banTrimDate
        ? isBefore(new Date(parseISO(viewControlInfo?.banTrimDate)), new Date())
        : true,
    [viewControlInfo?.banTrimDate],
  );

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (connection === ConnectionStates.AddedToTransformerInPackage)
        return defaultTheme.colors.planeta;
      if (connection === ConnectionStates.RemovedFromTheTransformerInPackage)
        return defaultTheme.colors.black;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (connection === ConnectionStates.AddedToTransformerInPackage)
        return defaultTheme.colors.pink;
      if (connection === ConnectionStates.RemovedFromTheTransformerInPackage)
        return defaultTheme.colors.disable;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isMinDesktop940) return undefined;

      /** Подключён в пакетах */
      if (connection === ConnectionStates.ConnectedInAPackage) {
        if (connectedPackages?.length === 1) {
          return `Подключён в пакете ${connectedPackages[0]?.channelPackName}`;
        }
        /** Много пакетов */
        if (connectedPackages?.length > 1) {
          return `Подключён в пакетах ${connectedPackages
            .map((item) => item.channelPackName)
            .join(', ')}`;
        }
      }

      /** Добавлены пакеты */
      if (connection === ConnectionStates.AddedToTransformerInPackage) {
        if (addedToTransformerPackages?.length === 1) {
          return `Добавлен в пакете ${addedToTransformerPackages[0]?.channelPackName}. Будет подключён при применении трансформера`;
        }
        /** Много пакетов */
        if (addedToTransformerPackages?.length > 1) {
          return `Добавлен в пакетах ${addedToTransformerPackages
            .map((item) => item.channelPackName)
            .join(', ')}. Будет подключён при применении трансформера`;
        }
        return 'Будет подключён при применении трансформера';
      }

      /** Удалён из трансформера в пакете */
      if (connection === ConnectionStates.RemovedFromTheTransformerInPackage) {
        const stringDate = removedPackages[0]?.banTrimDate
          ? `${getDate(removedPackages[0]?.banTrimDate)} года`
          : '';
        if (removedPackages.length === 1) {
          return isRejectionAvailable
            ? `Будет отключён в составе пакета ${removedPackages[0]?.channelPackName} при применении трансформера`
            : `Будет оформлен отказ от сервиса в составе пакета ${removedPackages[0]?.channelPackName} с ${stringDate} при применении трансформера`;
        }

        if (removedPackages.length > 1) {
          if (isRejectionAvailable) {
            return `Будет отключён в составе пакетов ${removedPackages
              .map((item) => item.channelPackName)
              .join(', ')} при применении трансформера`;
          }
          /** Дата отключения одинаковая для все пакетов */
          if (isSameDate(removedPackages)) {
            return `Будет оформлен отказ от сервиса в составе пакетов ${removedPackages
              .map((item) => item.channelPackName)
              .join(', ')} с ${stringDate} при применении трансформера`;
          }
          return `Будет оформлен отказ от сервиса в составе пакетов ${removedPackages
            .map((item) => {
              const banDate = item.banTrimDate
                ? `${getDate(item.banTrimDate)} года`
                : '';
              return `${item.channelPackName}
                ${banDate}`;
            })
            .join(', ')} при применении трансформера`;
        }
      }
      /** Оформлен отказ */
      if (connection === ConnectionStates.RefusalIssued)
        return `Оформлен отказ с ${shutdownDate}`;

      /** Оформлен отказ от пакетов */
      if (
        connection ===
        ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected
      ) {
        const date = new Date(parseISO(connectedRejectedPackages[0]?.unbindDt));
        const stringDate = `${date.getDate().toString()} ${
          MOUNTHS[date.getMonth()]
        } ${date.getFullYear().toString()} года`;
        if (connectedRejectedPackages?.length === 1) {
          return `Оформлен отказ от сервиса в составе пакета ${connectedRejectedPackages[0]?.channelPackName} с ${stringDate}`;
        }
        if (connectedRejectedPackages?.length > 1) {
          return isSameDate(connectedRejectedPackages)
            ? `Оформлен отказ от сервиса в составе пакетов ${connectedRejectedPackages
                .map((item) => item.channelPackName)
                .join(', ')} с ${stringDate}`
            : `Оформлен отказ от сервиса в составе пакетов ${connectedRejectedPackages
                .map((item) => {
                  const unbindDate = item.unbindDt
                    ? `${getDate(item.unbindDt)} года`
                    : '';
                  return `${item.channelPackName} c ${unbindDate}`;
                })
                .join(', ')}`;
        }
      }
      return undefined;
    };
    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          !isRejectionOfThePackage &&
          ![
            ConnectionStates.AddedToTransformerInPackage,
            ConnectionStates.RemovedFromTheTransformerInPackage,
          ].includes(connection) &&
          colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = (): string | boolean => {
    switch (connection) {
      case ConnectionStates.NotConnected:
        return false;
      case ConnectionStates.AddedToTransformerInPackage:
        if (addedToTransformerPackages.length > 1)
          return isMinDesktop940 ? TEXT.ADDED_IN_PACKAGES : TEXT.IN_PACKAGES;
        return isMinDesktop940 ? TEXT.ADDED_IN_PACKAGE : TEXT.IN_PACKAGE;
      case ConnectionStates.RemovedFromTheTransformerInPackage:
        return removedPackages.length > 1
          ? TEXT.REMOVED_PACKAGES
          : TEXT.REMOVED_PACKAGE;
      case ConnectionStates.Connected:
        return TEXT.CONNECTED;
      case ConnectionStates.RefusalIssued:
        return TEXT.CANCELLATION;
      case ConnectionStates.ConnectedInAPackage:
        if (connectedPackages.length > 1)
          return isMinDesktop940 ? TEXT.CONNECTED_IN_PACKAGES : TEXT.IN_PACKAGE;
        return isMinDesktop940 ? TEXT.CONNECTED_IN_PACKAGE : TEXT.IN_PACKAGE;
      case ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected:
        return connectedRejectedPackages.length > 1
          ? TEXT.REJECTION_OF_PACKAGES
          : TEXT.REJECTION_OF_PACKAGE;
      default:
        return false;
    }
  };

  return (
    <ServiceCardStyled onClick={onClick}>
      <div className="service-card__block">
        {isMinDesktop940 && (
          <Snoska
            className="service-card__definition"
            color={defaultTheme.colors.shadow}
          >
            сервис
          </Snoska>
        )}
        <LeadingText
          className="service-card__name"
          color={defaultTheme.colors.totalBlack}
        >
          {name}
        </LeadingText>
      </div>
      <div className="service-card__info-block">
        {connection !== ConnectionStates.NotConnected && (
          <div className="service-card__info-block__tag">
            {customTag(connectionStatusText())}
          </div>
        )}
        {((!isMinDesktop940 && connection === ConnectionStates.NotConnected) ||
          (![
            ConnectionStates.ConnectedInAPackage,
            ConnectionStates.ThePackageInWhichTheServiceIsLocatedHasBeenRejected,
            ConnectionStates.AddedToTransformerInPackage,
          ].includes(connection) &&
            isMinDesktop940 &&
            price !== null)) && (
          <Text className="service-card__info-block__price" lineHeight="16px">
            {formatNumber(price)} ₽ в день
          </Text>
        )}
      </div>
    </ServiceCardStyled>
  );
};

export default observer(ServiceViewControlCard);
