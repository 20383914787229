/** библиотеки */
import styled from '@emotion/styled';

export const StyledLegalBlock = styled.div`
  margin: 0 32px;

  ul {
    margin-top: 14px;

    li {
      list-style-type: none;
      padding-left: 21px;
      margin-bottom: 14px;
    }
  }

  ol {
    margin-top: 14px;
    margin-left: 13px;

    li {
      padding-left: 8px;
      margin-bottom: 16px;
    }
  }
`;
