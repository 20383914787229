/** api */
import {
  getAnnouncements,
  getAnnouncementsTags,
  getAnnouncementsByCount,
} from '~/api/api';

/** типы */
import { Block } from '~/interfaces/BlockInterface';
import {
  AnnouncementsProps,
  AnnouncementsType,
  AnnouncementFieldsContentProps,
  AnnouncementsTagsByCity,
} from './Announcements.types';

/** константы */
import { INIT_ANNOUNCEMENT_PAGE } from './Announcements.constants';

/**
 * возвращает блок device дополненный полями
 * @param {Block} block
 */
export const getDataForAnnouncements = async (
  block: Block,
): Promise<AnnouncementsProps> => {
  const { type, tags } = block.fields;
  const tagsKeys = tags ? Object.keys(tags) : [];
  let announcements;
  switch (type) {
    case AnnouncementsType.FULL:
      announcements = await getAnnouncements(tagsKeys, INIT_ANNOUNCEMENT_PAGE);
      break;
    default:
      announcements = await getAnnouncements();
  }

  const fields = {
    ...block.fields,
    isFinished: announcements.isFinished,
    announcements: [...announcements.announcements],
  };
  return {
    ...block,
    fields,
  };
};

export const getAnnouncementsOnPage = (
  tags: string[],
  cityId?: number,
  page?: number,
  currentSlug?: string,
): Promise<AnnouncementFieldsContentProps> => {
  return getAnnouncements(tags, cityId, page, currentSlug);
};

/**
 * Возвращает определённое количество записей
 * @param tags Теги
 * @param cityId Идентификатор города
 * @param count Ограничение количества возвращаемых записей
 */
export const getAnnouncementsByCountOnPage = (
  tags: string[],
  cityId?: number,
  count?: number,
): Promise<AnnouncementFieldsContentProps> => {
  return getAnnouncementsByCount(tags, cityId, count);
};

/**
 * Возвращает список тегов, привязанных к городу.
 */
export const getAnnouncementsTagsByCity = (): Promise<AnnouncementsTagsByCity> => {
  return getAnnouncementsTags();
};
