import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { StyledMdxParagraph } from '~/components/Blocks/Shared/Shared.style';

/** константы */
import { desktop1280, desktop940 } from '~/components/Grid/constants';

export const IPv6StateWizardContainer = styled.div`
  ${() => `
  
  .wizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: ${defaultTheme.colors.white};
    
    & a {
      color: ${defaultTheme.colors.planeta};
      text-decoration: none;
      &:hover {
        text-decoration: underline
      }
    }
    
    &__wrapper {
      width: 100%;
    }
    
    &__content {
      width: 100%;
      &-success, &-error {
        margin: -2rem 0 2rem 0;
        & svg, & svg * {
          stroke: ${defaultTheme.colors.planeta};
        }
        & > h2 {
          font-size: 21px;
          line-height: 25px;
          font-weight: 500;
          margin: 1rem 0;
          max-width: 70%;
        }
      }

      ${StyledMdxParagraph}:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  
  @media (min-width: ${desktop1280}px) {
    .wizard {
      &__content {
        &-success, &-error {
          & > h2 {
            font-size: 44px;
            line-height: 49px;
            font-weight: 700;
          }
        }
      }
    }
  }
`};
`;

export const StyledFooter = styled.div`
  ${() => `
    display: flex;
    flex-direction: column;
    align-items: center;
    
    button {
      width: 100%;
      margin-bottom: 10px;
    }

    .snoska {
      letter-spacing: -0.01em;
    }
    
    @media (min-width: ${desktop940}px) {
      flex-direction: row;
      
      button {
        width: auto;
        margin: 0 16px 0 0;
      }
    }
  `};
`;
