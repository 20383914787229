import styled from '@emotion/styled';
import {
  desktop800,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

export const StyledPrepayment = styled.div`
  .prepayment {
    &__price-button {
      display: flex;
      flex-direction: column;
      margin: 16px 0 0;

      > div:first-of-type {
        width: auto;
        margin: 0 0 16px 0;
        min-height: 56px;
        .colorContainer {
          padding: 4px 5px 4px 7px;
          > span {
            line-height: 24px;
          }
        }
      }

      button {
        width: fit-content;
        height: 56px;
      }
    }

    &__info {
      margin: 16px 0;
      max-width: 100%;
    }
  }

  @media (min-width: ${desktop800}px) {
    .prepayment {
      &__price-button {
        flex-direction: row;

        > div:first-of-type {
          margin: 0 16px 0 0;

          .colorContainer {
            width: 100%;
            flex-wrap: nowrap;
          }
        }
      }
      &__info {
        max-width: 528px;
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .prepayment {
      &__info {
        max-width: 648px;
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .prepayment {
      &__info {
        max-width: 768px;
      }
    }
  }
`;
