/** libraires */
import { Instance, types, cast, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import {
  ColorModel,
  DevicesFieldsModel,
  ImageSizeDataModel,
  ManualsPropsModel,
} from '../../Pab2c/Devices/stores/DevicesStore';
import { DevicesFields, FieldsProps, TagProps } from '../interfaces';
/** api */
import { getDevices } from '~/api/api';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** constants */
import { ARCHIVE_TAG, DEFAULT_TAG } from '../constants';
/** stores */
import { DeviceDetailedCardStoreInstance } from '~/components/Blocks/Shared/DeviceDetailedCard/store/useDeviceDetailedCardStore';

const RequestsStateModel = types.model('State', {
  getDevices: createApiPathModel('GET /Device/Device/GetAllDevices'),
});

const EnhancedFieldsPropsModel = types.model({
  features: types.map(
    types.model({
      images: types.string,
      gigabit: types.maybe(types.boolean),
      sort: types.number,
      tag: types.maybe(types.string),
      colors: types.maybe(types.array(ColorModel)),
      content: types.maybeNull(types.string),
      archive: types.maybe(types.boolean),
      footer: types.maybe(types.string),
      manuals: types.maybe(types.array(ManualsPropsModel)),
    }),
  ),
  deviceFilters: types.map(
    types.model({
      tag: types.maybe(types.string),
      types: types.array(types.string),
    }),
  ),
  imageSizes: types.array(ImageSizeDataModel),
  defaultIds: types.model({
    ftth: types.number,
    router: types.number,
    stb: types.number,
  }),
});

const TagPropsModel = types.model({
  value: types.string,
  tag: types.string,
  types: types.array(types.string),
});

const DeviceModel = types
  .model('DeviceModel', {
    requestsState: RequestsStateModel,
    fields: types.map(DevicesFieldsModel),
    enhancedFields: EnhancedFieldsPropsModel,
    /** Устройства под авторизацией */
    authDevices: types.array(DevicesFieldsModel),
    /** Активный тег */
    activeTag: TagPropsModel,
  })
  .views((self) => ({
    /** Коллекция всех тегов в хедере */
    get tags() {
      const newTags = [DEFAULT_TAG];
      const filters = toJS(self.enhancedFields.deviceFilters);
      filters.forEach((value, key: string) => {
        newTags.push({
          value: key.replace(/"/g, ''),
          tag: value.tag,
          types: value.types,
        });
      });
      return newTags;
    },
    get features() {
      return toJS(self.enhancedFields.features);
    },
    get imageSizes() {
      return toJS(self.enhancedFields.imageSizes);
    },
    get fieldsData() {
      return toJS(self.fields);
    },
    /** Массив оборудования с дополнительными полями */
    get devices() {
      let newDevices: DevicesFields[] = [];

      // Заполнение массива оборудования
      self.fields.forEach((field, key) => {
        if (!(+key >= 0)) return;
        newDevices.push(field);

        // Добавление в объект оборудования его дополнительных свойств
        const features = toJS(self.enhancedFields.features);
        features.forEach((feature, featureKey) => {
          const id = Number(featureKey);
          if (id === field.id) {
            newDevices[key] = {
              ...newDevices[key],
              ...feature,
            };
          }
        });
      });
      /** Устройства под авторизацией */
      if (self.authDevices.length) {
        newDevices = newDevices.map((item) => {
          const authDevice = self.authDevices.find(
            (device) => device.id === item.id,
          );
          return authDevice
            ? {
                ...authDevice,
                ...item,
                annuity: authDevice.annuity,
                ownershipPriceOn: authDevice.ownershipPriceOn,
              }
            : { ...item, isNotAvailable: true };
        });
      }

      const {
        detailedDeviceData,
        setDetailedDeviceData,
        isDetailedDeviceShow,
      } = DeviceDetailedCardStoreInstance;
      /** Если открыт сп во время авторизации - сменить инфу на новую */
      if (isDetailedDeviceShow && self.authDevices.length) {
        const authDevice = newDevices.find(
          (device) => device.id === detailedDeviceData.id,
        );
        setDetailedDeviceData(authDevice);
      }

      return [
        ...newDevices
          .filter((item) => item.sort)
          .sort((a, b) => a.sort - b.sort),
        ...newDevices.filter((item) => !item.sort),
      ];
    },
  }))
  .views((self) => ({
    /** Отфильтрованный по активному тегу массив оборудования */
    get filteredDevices() {
      if (self.activeTag.tag === DEFAULT_TAG.tag) {
        return toJS(self.devices.filter((item) => !item.archive));
      }

      if (self.activeTag.tag === ARCHIVE_TAG.tag) {
        return toJS(self.devices.filter((item) => item.archive));
      }

      return toJS(
        self.devices.filter(
          (item) => self.activeTag.types.includes(item.type) && !item.archive,
        ),
      );
    },
  }))
  .actions((self) => ({
    /** Получение устройств под авторизацией */
    getDevices: flow(function* () {
      self.requestsState.getDevices.reset();
      self.requestsState.getDevices.setLoading();
      try {
        const res = yield getDevices();
        self.authDevices = res;
        self.requestsState.getDevices.setSuccess();
      } catch (e) {
        console.error(e, 'getDevices');
        self.requestsState.getDevices.setFail();
      }
    }),
    resetAuthDevices() {
      self.authDevices.clear();
    },
    setActiveTag: (tag: TagProps) => {
      self.activeTag = cast(tag);
    },
  }));

export type IDeviceStore = Instance<typeof DeviceModel>;

export const createDeviceStore = (fields: FieldsProps) => {
  const {
    features,
    deviceFilters,
    imageSizes,
    defaultIds,
    filters,
    ...rest
  } = fields;
  return DeviceModel.create({
    requestsState: { getDevices: defaultModelState },
    fields: { ...rest },
    enhancedFields: { features, deviceFilters, imageSizes, defaultIds },
    authDevices: [],
    activeTag: DEFAULT_TAG,
  });
};
