import styled from '@emotion/styled';
import { desktop940 } from '~/components/Grid/constants';

export const StyledNewAppeal = styled.div`
  margin: 20px 0 0;
  .new {
    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 8px;
      }
      > span[data-test-select] {
        width: 100%;
      }
      input {
        width: -webkit-fill-available;
      }
      &__contractName {
        display: flex;
        align-items: end;
        > h3 {
          margin: 0 16px 0 0;
        }
        > a {
          margin-bottom: 2px;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .new {
      &__block {
        > span[data-test-select] {
          width: 330px;
        }
        input {
          width: 330px;
        }
      }
    }
  }
`;
