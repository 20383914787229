/** libraries */
import { FC } from 'react';
import {
  H2,
  H3,
  Icon,
  Icons,
  Loader,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** styles */
import { StyledChangeConnectionTypeSP } from './styles';
/** constants */
import { desktop1100 } from '~/components/Grid/constants';
import { DEFAULT_ERROR, DEMAND_REQUEST_ERRORS } from '~/constants/common';
import { RESULT_TEXT } from './constants';
/** utils */
import { maskPhone, getDotAddress } from '~/utils/utils';
/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import { useRootStore } from '~/stores/RootStore';

const ChangeConnectionTypeSP: FC = () => {
  const {
    authStore: { auth },
  } = useRootStore();

  /** Контекст подключения продукта */
  const {
    showChangeConnectionTypeSP,
    setShowChangeConnectionTypeSP,
    isDemandLoading,
    serverError,
    phone,
  } = useConnectionStore();
  /** Ширина сайта */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const changeConnectionTypeText = `Мы проверим техническую возможность подключения оптики по${'\u00A0'}адресу ${getDotAddress(
    auth.dotInfo,
  )} и${'\u00A0'}свяжемся с${'\u00A0'}вами, чтобы уточнить детали${
    phone.forSend ? ` по${'\u00A0'}телефону ${maskPhone(phone.forSend)}` : ''
  }.`;

  const tooMuchRequestsErrorText = `Для согласования дальнейших действий наши специалисты будут связываться с вами по телефонам, указанным в договоре ${
    auth.contractName
  }: ${maskPhone(phone.forSend)}.`;

  return (
    <StyledChangeConnectionTypeSP>
      <SidePage
        show={showChangeConnectionTypeSP}
        onCloseClick={() => setShowChangeConnectionTypeSP(false)}
        removeScrollBar
      >
        {isDemandLoading && <Loader />}
        {!isDemandLoading && !serverError && (
          <>
            <Icon icon={<Icons.SuccessIconAnimated />} />
            {isDesktop1100 ? (
              <H2>{RESULT_TEXT.SUCCESS}</H2>
            ) : (
              <H3>{RESULT_TEXT.SUCCESS}</H3>
            )}
            <Text lineHeight="24px">{changeConnectionTypeText}</Text>
          </>
        )}
        {!isDemandLoading && serverError && (
          <>
            <Icon icon={<Icons.NotOkBigIcon />} />
            {isDesktop1100 ? (
              <H2>
                {serverError === DEMAND_REQUEST_ERRORS.SWITCH_CONNECTION
                  ? RESULT_TEXT.REPEAT
                  : RESULT_TEXT.FAILURE}
              </H2>
            ) : (
              <H3>
                {' '}
                {serverError === DEMAND_REQUEST_ERRORS.SWITCH_CONNECTION
                  ? RESULT_TEXT.REPEAT
                  : RESULT_TEXT.FAILURE}
              </H3>
            )}
            <Text lineHeight="24px">
              {serverError === DEMAND_REQUEST_ERRORS.SWITCH_CONNECTION
                ? tooMuchRequestsErrorText
                : DEFAULT_ERROR}
            </Text>
          </>
        )}
      </SidePage>
    </StyledChangeConnectionTypeSP>
  );
};

export default observer(ChangeConnectionTypeSP);
