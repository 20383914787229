import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop500,
  desktop650,
  desktop750,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';
import { StyledFillableScale } from '~/components/Blocks/Shared/FillableScale/style';

export const StyledMyTVx2 = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    height: auto;
    background: ${defaultTheme.colors.white};
    justify-content: flex-start;
    padding: 32px;
    overflow-x: hidden;
  }

  > div::-webkit-scrollbar {
    width: 5px;
  }

  > div::-webkit-scrollbar-track {
    background-color: ${defaultTheme.colors.white};
    border-radius: 10px;
  }

  > div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${defaultTheme.colors.lightGrey};
  }

  .my-tv {
    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 32px;
      height: auto;

      h3 {
        margin-bottom: 0;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.03em;
      }

      &__tabs {
        width: 100%;
        margin: 32px 0 16px 0;
        > div {
          width: 100%;
          justify-content: space-between;

          & > div {
            letter-spacing: -0.02em;
            padding: 7px 5px;
            width: 100%;
          }
          .active {
            background-color: ${defaultTheme.colors.black};
            > span {
              color: ${defaultTheme.colors.white} !important;
            }
          }
        }
      }

      &__button {
        width: 100%;
        button {
          width: 100%;
          padding: 14px 0;
        }
      }
    }

    &__tab-block {
      &__block {
        display: flex;
        flex-direction: column;

        &__count {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 16px;
        }

        &__category {
          font-size: 20px;
          line-height: 24px;
          margin-right: 16px;
        }

        &__cards {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr;
          flex-wrap: wrap;
          margin-bottom: 36px;
          width: auto;

          &.viewport {
            > div:first-of-type,
            > div:last-of-type {
              position: absolute;
            }
          }
        }

        &__not-found {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          &__first-text {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 16px;
          }

          &__second-text {
            width: 360px;
          }

          &__third-text {
            width: 457px;
          }

          &__transformer-text {
            width: auto;
          }
        }

        &__transformer {
          display: flex;
          flex-direction: column;
          margin-bottom: 32px;

          &__block {
            display: flex;
            flex-direction: column;
            width: auto;
            margin: 0 0 16px 0;
          }

          &__header {
            font-size: 20px;
            margin-bottom: 7px;
          }

          button {
            margin-right: 16px;
            padding: 12px 13px;
          }

          a {
            letter-spacing: -0.03em;
          }
        }
      }
    }

    &__not-connected {
      display: flex;
      flex-direction: column;
      > span:first-of-type {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      > span:nth-of-type(2) {
        max-width: 294px;
      }
    }

    &__scale-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      > span:first-of-type {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 16px 0;
      }
    }

    &__not-available {
      > span:first-of-type {
        display: block;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: ${desktop500}px) {
    .my-tv {
      &__header {
        &__tabs {
          & > div {
            & > div {
              padding: 7px 23px;
              width: 100%;
            }
          }
        }
      }
      &__tab-block {
        &__block {
          &__cards {
            grid-template-columns: 1fr 1fr 1fr;
          }
          &__transformer {
            button {
              padding: 12px 38px;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop650}px) {
    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop750}px) {
    .my-tv {
      &__tab-block {
        &__block {
          &__cards {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    > div {
      display: flex;
      height: 400px;
      padding: 32px 32px 0;
    }

    .my-tv {
      &__header {
        margin-bottom: 40px;
        height: auto;

        h3 {
          width: 100%;
          margin: 4px 0 24px;
          font-size: 32px;
          line-height: 40px;
        }

        &__tabs {
          width: auto;
          margin: 0 32px 0 0;

          & > div {
            > div {
              padding: 10px 36px;
              letter-spacing: -0.02em;
              margin-left: -2px;
              &:first-of-type {
                padding-left: 44px;
                &.active {
                  margin: 0 -8px 0 0;
                }
              }

              &:last-of-type {
                padding-right: 44px;
                &.active {
                  margin: 0 0 0 -8px;
                }
              }

              &.active {
                padding: 10px 44px;
                margin: 0 -8px;
              }

              > span {
                color: ${defaultTheme.colors.shadow};
                font-weight: 600;
                letter-spacing: -0.02em;
              }

              .icon {
                line-height: 0;
              }
            }
          }
        }

        &__button {
          width: fit-content;
          margin: 0 0 0 0;
          button {
            width: fit-content;
            padding: 14px 24px;
          }
        }
      }

      &__tab-block {
        max-height: 450px;
        overflow-x: hidden;
        margin-right: -25px;

        &__block {
          &__count {
            margin-bottom: 24px;
          }

          &__cards {
            display: flex;
            gap: 24px;
            > div {
              width: 252px;

              .channel-package-card {
                width: 252px;
              }
              .channel-package-card__duplicate-wrapper {
                width: 252px;
                .channel-package-card__duplicate {
                  width: 252px;
                }
              }
            }
          }
        }
      }

      &__tab-block::-webkit-scrollbar {
        width: 5px;
      }

      &__tab-block::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 10px;
      }

      &__tab-block::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${defaultTheme.colors.lightGrey};
      }

      &__scale-block {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 34px;
        > span:first-of-type {
          margin: 0 24px 0 0;
        }
        &__count {
          width: 121px;
        }
        ${StyledFillableScale} {
          width: 251px;
          margin: 0;
        }
      }
    }

    .popup {
      &__container {
        > div:first-of-type {
          padding-bottom: 6px;

          h3 {
            text-align: inherit;
          }
        }
        &__content {
          padding: 0 32px 40px !important;
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .my-tv {
      &__header {
        margin-bottom: 40px;
        height: auto;

        &__tabs {
          margin: 0 auto 0 0;
          & > div {
            > div {
              padding: 10px 24px;
              &:first-of-type {
                padding-left: 32px;
              }

              &:last-of-type {
                padding-right: 32px;
              }

              &.active {
                padding: 10px 32px;
              }
            }
          }
        }
      }

      &__tab-block {
        max-height: 450px;
        &__block {
          &__cards {
            > div {
              width: 200px;
              .channel-package-card {
                width: 200px;
              }
              .channel-package-card__duplicate-wrapper {
                width: 200px;
                .channel-package-card__duplicate {
                  width: 200px;
                }
              }
              .channel-card__info-block__tag {
                text-wrap: nowrap;
              }
            }
          }
        }
      }

      &__scale-block {
        ${StyledFillableScale} {
          width: 424px;
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .my-tv {
      &__header {
        flex-wrap: nowrap;
        h3 {
          width: fit-content;
          margin: 0 24px 0 0;
        }

        &__tabs {
          margin: 5px auto 5px 0;
          & > div {
            > div {
              padding: 10px 23px;
              &:first-of-type {
                padding-left: 31px;
              }

              &:last-of-type {
                padding-right: 31px;
              }

              &.active {
                padding: 10px 31px;
              }
            }
          }
        }

        &__button {
          button {
            padding: 14px 39px;
          }
        }
      }
      &__tab-block {
        max-height: 514px;
        &__block {
          &__cards {
            > div {
              width: 240px;
              .channel-package-card {
                width: 240px;
              }
              .channel-package-card__duplicate-wrapper {
                width: 240px;
                .channel-package-card__duplicate {
                  width: 240px;
                }
              }
            }
          }
        }
      }
      &__scale-block {
        ${StyledFillableScale} {
          width: 504px;
        }
      }
    }
  }
`;

export const StyledPopup = styled.div`
  .popup {
    &__container {
      > div:first-of-type {
        padding-bottom: 6px;

        h3 {
          text-align: inherit;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.03em;
        }
      }
      &__content {
        padding-top: 0 !important;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .popup {
      &__container {
        > div:first-of-type {
          padding-bottom: 6px;

          h3 {
            text-align: inherit;
          }
        }
        &__content {
          padding: 0 32px 40px !important;
        }
      }
    }
  }
`;
