import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledBlockedContract = styled.div`
  > svg {
    margin-bottom: 24px;
  }

  h2,
  h3 {
    margin: 0;
  }

  .yellow-block {
    width: 100%;
    margin-top: 32px;
    padding: 16px;
    background-color: ${defaultTheme.colors.backgroundWarning};
  }
`;
