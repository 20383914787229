/** библиотеки */
import React, { useState, useEffect } from 'react';
import { VKShareButton, OKShareButton } from 'react-share';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** стили */
import { StyledFooter, StyledSendWish } from './SendWish.style';

/** компоненты библиотеки */
import {
  Text,
  H2,
  LeadingText,
  defaultTheme,
  Button,
  Textarea,
  Icons,
  Snoska,
} from 'cordis-core-ui-planeta';

/** константы */
import { IMAGES, ERRORS, SOCIAL_DATA } from './constants';
import { desktop400, desktop900 } from '~/components/Grid/constants';
import { BLOCK_CONTRACT_ERRORS } from '../../Shared/CreatePassword/constants';

/** api */
import { getPlanetaHistoryInfo, setAnswer, setReposted } from '~/api/api';

/** компоненты */
import { removeLeadingZero, getClientName } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/** Блок Конкурс «Отправь пожелания»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=744502504
 */
const SendWish: React.FC = () => {
  const {
    authStore: { isAuth, auth, isTemporaryTokenAuth },
  } = useRootStore();
  const { toggleVisible } = useMakeAuthStore();
  /** Страница ответа */
  const [isAnswer, setIsAnswer] = useState<boolean>(false);

  /** Флаг repost */
  const [isReposted, setIsReposted] = useState<boolean>(false);

  /** Значение textarea */
  const [textareaValue, setTextareaValue] = useState<string>('');

  /** Текст ошибки textarea */
  const [errorTextarea, setErrorTextarea] = useState<string>('');

  /** Флаг fetched */
  const [isFetched, setFetched] = useState<boolean>(false);

  // Вычисление ширины экрана
  const isMinDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });
  const isMinDesktop900 = useMediaQuery({
    query: `(min-width: ${desktop900}px)`,
  });

  /** Информация о конкурсе пользователя */
  const getSendWishInfo = async () => {
    try {
      const data = await getPlanetaHistoryInfo();
      setIsAnswer(data.status !== 204);
      if (data.status !== 204) {
        const res = await data.json();
        setIsReposted(res.isReposted);
      }
    } catch (e) {
      console.error('sendWishInfoError', e);
    }
  };

  /** Установка флага true для поля is_reposted */
  const reposted = async () => {
    try {
      await setReposted();
    } catch (e) {
      console.error('repostedError', e);
    }
  };

  /** Кнопка "отправить ответ" */
  const submitReply = async () => {
    validation(textareaValue);
    setFetched(true);
    if (!errorTextarea) {
      try {
        const res = await setAnswer(textareaValue);
        if (res.status === 400) setErrorTextarea(ERRORS.Error);
        else await getSendWishInfo();
      } catch (e) {
        const error = JSON.parse(e.errorMessage);
        if (
          error?.Type === BLOCK_CONTRACT_ERRORS.ContractNotServicedException
        ) {
          setErrorTextarea(ERRORS.NotServiced);
        }
        setErrorTextarea(ERRORS.Error);
      } finally {
        setFetched(false);
      }
    }
  };

  /** Валидация textarea */
  const validation = (text) => {
    if (text.length >= 400) setErrorTextarea(ERRORS.Over400Characters);
    else setErrorTextarea('');
  };

  /** Обработчик textarea */
  const handleChangeTextarea = (e) => {
    validation(e.target.value);
    setTextareaValue(e.target.value.replace(/\ {2,}/g, ' '));
  };

  useEffect(() => {
    if (isAuth) getSendWishInfo();
  }, [isAuth]);

  return (
    <StyledSendWish>
      <div className={isAuth && isAnswer ? 'background-fade' : 'background'}>
        <div className="send-wish__block-x3">
          {!isAuth && (
            <div className="send-wish__auth">
              {isMinDesktop400 ? (
                <LeadingText
                  color={defaultTheme.colors.black}
                  className="send-wish__auth__text"
                >
                  {' '}
                  Чтобы участвовать в&nbsp;розыгрыше
                  <br />
                  призов, авторизуйся по&nbsp;номеру
                  <br />
                  договора
                </LeadingText>
              ) : (
                <LeadingText
                  color={defaultTheme.colors.black}
                  className="send-wish__auth__text"
                >
                  Чтобы участвовать в&nbsp;розыгрыше призов, авторизуйся
                  по&nbsp;номеру договора
                </LeadingText>
              )}

              <Button onClick={() => toggleVisible()}>Войти</Button>
            </div>
          )}

          {isAuth && !isAnswer && (
            <div className="send-wish__answer">
              {isMinDesktop900 ? (
                <div className="send-wish__greeting">
                  <H2>Желаю каждому планетянину</H2>
                  <Text>
                    {getClientName(auth.clientName)},{' '}
                    {removeLeadingZero(auth.contractName)}
                  </Text>
                </div>
              ) : (
                <H2>Желаю каждому планетянину</H2>
              )}

              <div className="send-wish__answer__textareaWrapper">
                <Textarea
                  resize="none"
                  onChange={(e) => handleChangeTextarea(e)}
                  value={textareaValue}
                  maxLength={400}
                  disabled={isFetched}
                />
                {errorTextarea && (
                  <div className="send-wish__answer__textareaWrapper__textareaError">
                    <Snoska>{errorTextarea}</Snoska>
                  </div>
                )}
              </div>
              <StyledFooter>
                <Button
                  onClick={submitReply}
                  disabled={isTemporaryTokenAuth || textareaValue === ''}
                  loading={isFetched}
                >
                  Поздравить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
              {!isMinDesktop900 && (
                <Text>
                  {getClientName(auth.clientName)},{' '}
                  {removeLeadingZero(auth.contractName)}
                </Text>
              )}
            </div>
          )}

          {isAuth && isAnswer && (
            <div className="send-wish__answer">
              {isMinDesktop900 ? (
                <div className="send-wish__greeting">
                  <H2>Спасибо за&nbsp;тёплые пожелания!</H2>
                  <Text>
                    {getClientName(auth.clientName)},{' '}
                    {removeLeadingZero(auth.contractName)}
                  </Text>
                </div>
              ) : (
                <H2>Спасибо за&nbsp;тёплые пожелания!</H2>
              )}
              <LeadingText
                color={defaultTheme.colors.black}
                className="send-wish__repost__text"
              >
                Результаты розыгрыша будут опубликованы 25&nbsp;декабря.
                <br />
                <br />
                Поделись этой страничкой в&nbsp;соцсетях с&nbsp;друзьями
                и&nbsp;жди приятных подарков.
              </LeadingText>
              <Text lineHeight="24px">Поделиться:</Text>
              <div className="send-wish__repost__icons">
                <VKShareButton
                  url={SOCIAL_DATA.shareUrl}
                  title={`${SOCIAL_DATA.title}`}
                  image={IMAGES.sharing}
                >
                  <div
                    onClick={async () => {
                      if (!isReposted) {
                        await reposted();
                        await getSendWishInfo();
                      }
                    }}
                    className="send-wish__repost__icons__icon send-wish__repost__icons__vk"
                  >
                    <Icons.VKIcon />
                  </div>
                </VKShareButton>
                <OKShareButton
                  url={SOCIAL_DATA.shareUrl}
                  title={`${SOCIAL_DATA.title}`}
                  image={IMAGES.sharing}
                >
                  <div
                    onClick={async () => {
                      if (!isReposted) {
                        await reposted();
                        await getSendWishInfo();
                      }
                    }}
                    className="send-wish__repost__icons__icon send-wish__repost__icons__ok"
                  >
                    <Icons.ClassmatesIcon />
                  </div>
                </OKShareButton>
              </div>
              {!isMinDesktop900 && (
                <Text>
                  {getClientName(auth.clientName)},{' '}
                  {removeLeadingZero(auth.contractName)}
                </Text>
              )}
            </div>
          )}
        </div>
      </div>
    </StyledSendWish>
  );
};

export default observer(SendWish);
