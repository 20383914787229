import { PLANETA_URL } from '~/constants/common';

export const IMAGES = {
  marusyaMini: `${process.env.STATIC_SERVER}/static/images/swipe-indicator_images/MarusyaMini.svg`,
  planeta: `${process.env.STATIC_SERVER}/static/images/swipe-indicator_images/planeta.svg`,
  hexagon: `${process.env.STATIC_SERVER}/static/images/swipe-indicator_images/hexagon.svg`,
  shearing: `${PLANETA_URL}/static/images/konkurs-shearing.png`,
};

export enum ERRORS {
  EmptyField = 'Расскажи нам свою историю',
  Over400Characters = 'Пожалуйста, не более 400 символов',
  Error = 'Что-то пошло не так...',
  NotServiced = 'Договор не обслуживается',
}

/* Данные для шаринга в социальных сетях */
export const SOCIAL_DATA = {
  shareUrl: `${PLANETA_URL}/story`,
  title: 'Пройди опрос и выиграй крутые призы от Планеты!',
};

export enum MOUNTHS {
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
}
