/** libraries */
import styled from '@emotion/styled';

/** constants */
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledSettings = styled.div`
  background-color: ${defaultTheme.colors.white};
  padding: 32px;
  height: auto;
  min-height: 380px;

  h3 {
    margin-bottom: 33px;
  }

  .settings {
    &__entrance-password {
      margin-bottom: 24px;
    }

    &__action-block {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      > svg {
        margin-right: 8px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
