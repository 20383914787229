import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme, ColorsType } from 'cordis-core-ui-planeta';
import { desktop800 } from '~/components/Grid/constants';

export const StyledNotificationCard = styled.div<{
  color: ColorsType;
  isOpen: boolean;
  isCarousel: boolean;
  height: number;
  isHide: boolean;
}>`
  ${({ color, isOpen, isCarousel, height, isHide }) => {
    return css`
      background-color: ${defaultTheme.colors.white};
      border-left: 8px solid ${color};
      padding: 32px 32px 32px 24px;
      margin: 0;

      .notification-card {
        &__header {
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;
        }
        &__open-hide {
          margin: 16px 0 0 0;
          cursor: pointer;
        }
        &__tag {
          line-height: 0;
          height: 20px;
          > div {
            height: 20px;
            & > div {
              padding: 1px 8px;
              height: 20px;
            }
          }
        }
        &__children {
          ${!isCarousel && isHide
            ? `${
                isOpen
                  ? `max-height: ${height}px; overflow: hidden; transition: max-height 1s ease-out;`
                  : 'max-height: 0; overflow: hidden; transition: max-height 1s ease-out;'
              }`
            : ''}
        }
      }

      @media (min-width: ${desktop800}px) {
        .notification-card {
          &__header {
            flex-direction: row;
          }
          &__open-hide {
            margin: 0 0 0 16px;
          }
        }
      }
    `;
  }}
`;
