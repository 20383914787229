import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledPinWizard = styled.div<{ isError: boolean }>`
  ${({ isError }) => {
    return css`
      .pincode {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 32px;
      }
      .pincode input[type='number'] {
        width: 100%;
        max-width: 60px;
        height: 54px;
        line-height: 24px;
        text-align: center;
        border: 1px solid
          ${isError ? defaultTheme.colors.planeta : defaultTheme.colors.disable};
        color: ${defaultTheme.colors.black};
        padding: 16px 0px;
        margin: 0 6px;
        font-size: 16px;
        outline: none;
        background-color: #fff;
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
      }
      .pincode input[type='number']:first-of-type {
        margin: 0 6px 0 0;
      }
      .pincode input[type='number']::-webkit-outer-spin-button,
      .pincode input[type='number']::-webkit-inner-spin-button {
        display: none;
      }

      .error {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        &__message {
          margin-top: 4px;
          max-width: 320px;
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        > span {
          cursor: pointer;
          margin-bottom: 8px;
        }
      }

      @media (min-width: ${desktop940}px) {
        .pincode input[type='number'] {
          width: 60px;
        }
      }
    `;
  }}
`;
