import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованный виджет баланса в мобильном меню */
export const StyledMobileBalanceWidget = styled.div<{
  $isTemporaryTokenAuth: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: ${({ $isTemporaryTokenAuth }) =>
    $isTemporaryTokenAuth
      ? 'rgba(0, 0, 0, 0.35)'
      : defaultTheme.colors.sweetBlue};
  align-items: flex-start;
  padding: 16px 22px;
  margin-top: 40px;
  & > div:first-of-type {
    margin-bottom: 14px;
  }
  & > a {
    font-size: 18px;
    margin-bottom: 18px;
    &:hover {
      text-decoration: underline;
    }
  }
  & > a:last-of-type {
    margin-bottom: 21px;
  }
  & > h3 {
    margin-top: 6px;
    margin-bottom: 16px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    & > button {
      & svg {
        fill: none;
        stroke: ${defaultTheme.colors.planeta};
      }
    }
    & > button:last-of-type {
      padding: 8px;
    }
    & > button:first-of-type {
      margin-right: 14px;
    }
  }
  a {
    text-decoration: none;
  }
  ${({ $isTemporaryTokenAuth }) =>
    $isTemporaryTokenAuth &&
    css`
      button {
        background: rgba(0, 0, 0, 0);
      }
    `};
`;
