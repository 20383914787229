import { ALL_TAG } from '../../Templates/ProductSwitcher/constants';
import { MarketingTagsValues } from './ProductTemplate.interfaces';
import { SALE_MARKETING_TAG } from './ProductTemplate.constants';

/**
 * Создаёт состояние маркетинг тегов в виде объекта {'код маркетинг категории' : boolean}
 * @param isInitial - флаг начального состояния
 * @param codes - коды маркетинг тегов
 */
export const formatTagsByObject = (
  isInitial: boolean,
  codes: string[],
): MarketingTagsValues => {
  // Маркетинг теги в виде объекта
  const tags = codes.map((item: string) =>
    (item === SALE_MARKETING_TAG || item === ALL_TAG.code) && isInitial
      ? [item, isInitial]
      : [item, false],
  );

  return tags.reduce((obj, [key, val]) => {
    obj[key as string] = val;
    return obj;
  }, {}) as MarketingTagsValues;
};
