/** библиотеки */
import styled from '@emotion/styled';

export const StyledVideo = styled.div`
  height: 400px;
  max-width: 240px;
  position: relative;

  img {
    border-radius: 50px;
  }

  .play-icon {
    position: absolute;
    left: calc(50% - 90px / 2 + 8px);
    top: calc(50% - 110px / 2);
    transition: 1s;
  }

  .play-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .popup__container__content {
    padding-top: 30px !important;
  }
`;
