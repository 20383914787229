import styled from '@emotion/styled';
/** constants */
import { desktop940 } from '~/components/Grid/constants';

export const StyledPin = styled.div`
  .pin {
    &__info {
      margin-bottom: 24px;
    }
    &__input {
      display: flex;
      flex-direction: column;
      &__inlet {
        margin: 8px 0 0;
        input {
          width: 100%;
        }
        > div[data-test-error] {
          > span {
            line-height: 24px;
          }
        }
      }

      &__info {
        margin-top: 16px;
      }
    }
    &__input:first-of-type {
      margin-bottom: 32px;
    }
  }

  .result-icon {
    svg {
      margin-bottom: 26px;
    }
  }

  @media (min-width: ${desktop940}px) {
    .pin {
      &__info {
        margin-bottom: 32px;
      }
      &__input {
        &__inlet {
          width: 330px;
          input {
            width: 330px;
          }
        }
      }
    }
  }
`;
