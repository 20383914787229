/** библиотеки */
import React, { FC, useState } from 'react';
import { H3, Tabs, TabsStyleTypes } from 'cordis-core-ui-planeta';

/** типы */
import { ActiveTab, DeviceTabsProps } from './interfaces';

/** константы */
import { TABS_PERIOD } from './constants';
import { formatNumber } from '~/utils/utils';

/**
 * Компонент с карточкой оборудования
 */
const DevicePriceTabs: FC<DeviceTabsProps> = ({ cost }: DeviceTabsProps) => {
  // Возвращает массив табов
  const generateTabs = (): TABS_PERIOD[] | [] => {
    const newTabs = [];

    if (cost !== null) {
      newTabs.push(TABS_PERIOD.DAY);
      newTabs.push(TABS_PERIOD.MONTH);
    }

    return newTabs;
  };

  /* Событие при изменении таба */
  const onChangeTab = (index: number, value: string) => {
    setActiveTab({ index, value });
  };

  // Табы оборудования
  const deviceTabs = generateTabs();

  // Исходный активный таб
  const initialActiveTab = {
    index: 0,
    value: deviceTabs[0],
  };

  // Активный таб
  const [activeTab, setActiveTab] = useState<ActiveTab>(initialActiveTab);

  return (
    <>
      <div className="">
        <Tabs
          value={deviceTabs}
          styleType={TabsStyleTypes.SECONDARY}
          onChange={onChangeTab}
          activeTabIndex={activeTab.index}
        />
      </div>

      <H3 className="devices__item-price">
        {activeTab.value === TABS_PERIOD.DAY && (
          <>{formatNumber(cost)} &#8381;</>
        )}
        {activeTab.value === TABS_PERIOD.MONTH && (
          <>{formatNumber(30 * cost)} &#8381;</>
        )}
      </H3>
    </>
  );
};

export default DevicePriceTabs;
