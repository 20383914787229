/** библиотеки */
import styled from '@emotion/styled';
import { defaultTheme, H3, LeadingText, Text } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledPaymentResult = styled.div`
  .result {
    background: ${defaultTheme.colors.white};
    height: auto;
    padding: 32px;
    position: relative;

    &__header {
      margin-bottom: 32px;
      h2 {
        margin-bottom: 0;
      }
      > span {
        margin-top: 16px;
      }
    }

    &__time-is-up {
      margin-bottom: 16px;
    }

    &__waiting {
      display: flex;
      align-items: center;
      > div {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 24px;
      }

      &__text {
        margin-top: 16px;
      }
    }

    &__bind {
      &__qr {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        &__warning {
          padding: 16px;
          background-color: ${defaultTheme.colors.backgroundWarning};
          margin-top: 24px;
          &__banks {
            cursor: pointer;
          }
        }

        &__text-block {
          margin: 32px 0 0 0;

          &__timer {
            margin-top: 16px;
            &__loader {
              display: flex;
              align-items: center;
              margin-top: 8px;

              > div:nth-of-type(2) {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    &__failed {
      display: flex;
      flex-direction: column;

      &__header {
        margin-bottom: 32px;
      }

      &__button-block {
        display: flex;
        flex-direction: column;
        > span {
          max-width: 212px;
          margin: 16px 0 0 0;
        }
        &__error {
          margin-top: 16px;
        }
      }
    }
  }

  .icon {
    > svg {
      margin-bottom: 24px;
    }
  }

  @media (min-width: ${desktop940}px) {
    .result {
      min-height: 400px;
      &__bind {
        &__qr {
          flex-direction: row;
          &__text-block {
            margin: 0 0 0 32px;
          }
        }
      }

      &__failed {
        &__button-block {
          flex-direction: row;
          > span {
            margin: 0 0 0 16px;
          }
        }
      }
    }
  }
`;

export const SuccessHeaderDesktop = styled(H3)`
  margin-bottom: 0;
`;

export const SuccessHeaderMobile = styled(LeadingText)`
  margin-bottom: 0;
`;

export const SuccessText = styled(Text)`
  margin-top: 16px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 15px);
  left: 50%;

  @media (min-width: ${desktop940}px) {
    top: calc(50% - 50px);
  }
`;
