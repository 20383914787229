/** библиотеки */
import styled from '@emotion/styled';
/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';
/** константы */
import { desktop370 } from '~/components/Grid/constants';

export const StyledFeedbackCustomBlock = styled.div`
  height: 400px;
  padding: 32px;
  background: ${defaultTheme.colors.white};
  display: flex;
  flex-direction: column;

  .feedback-block {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    &__header {
      display: flex;
      flex-direction: column;
      margin-left: 14px;

      span:first-of-type {
        font-size: 18px;
      }
    }
    &__rating {
      display: flex;
      flex-direction: column;
      max-width: 277px;
      margin-bottom: 9px;

      @media (min-width: ${desktop370}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
      }
    }
    &__text {
      overflow: hidden;
    }
  }
`;

export const StyledRatingCustom = styled.ul`
  display: flex;
  padding: 0;
  margin-right: 6px;
  list-style-type: none;
`;

export const StyledFeedbackBlock = styled.div`
  min-height: 400px;
  padding: 32px;
  background: ${defaultTheme.colors.white};

  .feedback-block__message {
    > h3 {
      margin-top: 11px;
      margin-bottom: 15px;
    }

    > span {
      margin: 20px 0;
    }
  }

  .feedback-block__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background: #30be63;
    border-radius: 100%;
  }

  .feedback-block__icon {
    font-size: 0;
    &--yandex {
      margin-right: 11px;
    }
    &--flamp {
      margin-right: 8px;
    }
  }

  .feedback-block__author {
    margin-bottom: 10px;
  }
`;

export const StyledRatingPointsList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

export const StyledSourceLinkBlock = styled.div`
  display: inline-flex;
  align-items: center;
`;
