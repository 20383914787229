/** libraries */
import { FC } from 'react';
import {
  Button,
  H1,
  Icon,
  Icons,
  LeadingText,
  Text,
  defaultTheme,
  ButtonStyleTypes,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledSuccessScreen } from './styles';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** components */
import Warning from '~/components/Blocks/Shared/Warning/Warning';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { WORLFLOW_NAMES } from '~/components/MyAppealsSidePage/constants';
import useMyAppealsStore from '~/components/MyAppealsSidePage/store/useMyAppealsStore';
/** stores */

interface SuccessScreenProps {
  number: number;
  goBackToViewingRequests: () => void;
}

/** Экран успеха */
const SuccessScreen: FC<SuccessScreenProps> = ({
  number,
  goBackToViewingRequests,
}: SuccessScreenProps) => {
  const { typeOfApplication } = useMyAppealsStore();
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  return (
    <StyledSuccessScreen>
      <Icon icon={<Icons.OkBigIcon />} />
      <H1>Обращение № {number} принято</H1>
      <div className="success__date">
        <LeadingText color={defaultTheme.colors.black}>
          {typeOfApplication === WORLFLOW_NAMES.MALFUNCTION
            ? 'Служба технической поддержки ответит вам в ближайшее время'
            : 'Менеджеры свяжутся с вами в ближайшее время'}
        </LeadingText>
      </div>
      <Warning
        text={
          <>
            {typeOfApplication === WORLFLOW_NAMES.MALFUNCTION && (
              <>
                <Text lineHeight="24px">
                  Номер и текст обращения продублированы на указанный вами
                  email.
                </Text>
                <br />
                <br />
              </>
            )}
            <Text lineHeight="24px">
              В случае повторного обращения по данному вопросу сообщите номер
              заявки нашему специалисту.
            </Text>
          </>
        }
      />
      <Button
        className="success__button"
        styleType={ButtonStyleTypes.SECONDARY}
        onClick={goBackToViewingRequests}
      >
        {isDesktop940 ? 'Вернуться к просмотру обращений' : 'К обращениям'}
      </Button>
    </StyledSuccessScreen>
  );
};

export default observer(SuccessScreen);
