import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorsType } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledComment = styled.div<{
  backgroundColor: ColorsType;
  isClient: boolean;
}>`
  ${({ backgroundColor, isClient }) => {
    return css`
      display: flex;
      justify-content: ${isClient ? 'end' : 'start'};
      > div {
        width: 90%;
        background-color: ${backgroundColor};
        padding: 12px;
        white-space: pre-line;
        border-radius: 10px 10px ${isClient ? '2px' : '10px'}
          ${isClient ? '10px' : '2px'};
      }

      @media (min-width: ${desktop940}px) {
        > div {
          width: 400px;
        }
      }
      @media (min-width: ${desktop1100}px) {
        > div {
          width: 520px;
        }
      }
      @media (min-width: ${desktop1280}px) {
        > div {
          width: 640px;
        }
      }
    `;
  }}
`;
