/* Библиотеки */
import * as React from 'react';

/* Типы */
import { CallToActionConnectionProps } from './CallToActionConnection.types';
import { ConnectionMethod } from '~/constants/common';

/* Стили */
import { StyledConnectionContent } from './CallToActionConnection.style';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
import { Text, H3, Tag, defaultTheme } from 'cordis-core-ui-planeta';

/* Утилиты */
import { formatNumber } from '~/utils/utils';

/* Дочерний компонент "Стоимость подключения" компонента CallToAction */
const CallToActionConnection: React.FC<CallToActionConnectionProps> = ({
  connectionMethod,
  connection,
  finishDt,
  isAction,
}: CallToActionConnectionProps) => (
  <CallToActionRow
    title={
      <Text>
        {connectionMethod === ConnectionMethod.FTTH
          ? 'Стоимость подключения к оптической сети'
          : 'Стоимость подключения'}
      </Text>
    }
  >
    <StyledConnectionContent>
      {/* yaspeller ignore:start */}
      <span>
        <H3>
          {connection === 0
            ? 'Бесплатно'
            : `${formatNumber(connection)}\u0020\u20bd`}
        </H3>
        {isAction && (
          <Tag
            color={defaultTheme.colors.planeta}
            colorTag={defaultTheme.colors.pink}
          >
            АКЦИЯ
          </Tag>
        )}
      </span>
      {finishDt && (
        <Text>
          {connection === 0
            ? `Бесплатное подключение\nдо\u0020${finishDt}`
            : `Акция до\u0020${finishDt}`}
        </Text>
      )}
      {/* yaspeller ignore:end */}
    </StyledConnectionContent>
  </CallToActionRow>
);

export default React.memo(CallToActionConnection);
