/** библиотеки */
import { useEffect, useMemo, useState } from 'react';
/** константы */
import { BIND_STATES, BIND_REFUSE_REASONS } from './constants';
import { ANNUAL_SUBSCRIPTION } from '../constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useSoftlineStateModelStore from '../stores/useSoftlineStateStore';
/** интерфейсы */
import { BindDataHookReturnProps, BindData } from '../types';
/** компоненты библиотеки */
import { defaultTheme, PriceTagBackgroundColor } from 'cordis-core-ui-planeta';
/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
/** api */
import { checkBindAvailability } from '~/api/api';

/** Хук логики отображения возможности подключения подписки */
export const useBindData = (): BindDataHookReturnProps => {
  const { softlineDescription } = useSoftlineStateModelStore();
  const {
    pendingUnbindDate,
    chatlingCashback,
    metricId,
    durationDays,
    statusString,
  } = softlineDescription;

  const {
    authStore: { isAuth },
  } = useRootStore();

  /** Сохраняем ответ метода возможности подключения подписки */
  const [bindData, setBindData]: HooksTyping<BindData> = useState<BindData>({
    isAvailable: false,
  });
  const [
    isLoadingHook,
    setIsLoadingHook,
  ]: HooksTyping<boolean> = useState<boolean>(false);
  useEffect(() => {
    if (!isAuth) return;
    setIsLoadingHook(true);
    (async () => {
      try {
        const response = await checkBindAvailability(metricId);
        setBindData(response);
      } catch (e) {
        console.error('checkBindAvailability', e);
      } finally {
        setIsLoadingHook(false);
      }
    })();
  }, [isAuth]);

  /** Подписку можно подключить */
  const { isAvailable } = bindData;

  /** Сумма для пополнения счёта */
  const { requiredMoneyToBind } = bindData;

  /** Недостаточно средств */
  const isInsufficientFunds =
    bindData.bindRefuseReason === BIND_REFUSE_REASONS.MONEY &&
    bindData.contractStateCode === BIND_STATES.ON;

  /** Обслуживание приостановлено оператором */
  const isNotServed =
    bindData.bindRefuseReason === BIND_REFUSE_REASONS.WRONG_STATE &&
    bindData.contractStateCode === BIND_STATES.PROVIDER_BLOCK_DEBT;

  /** Добровольная приостановка обслуживания */
  const isClientBlock =
    bindData.bindRefuseReason === BIND_REFUSE_REASONS.WRONG_STATE &&
    bindData.contractStateCode === BIND_STATES.CLIENT_BLOCK;

  /** Подключение не завершено */
  const isNovice =
    bindData.bindRefuseReason === BIND_REFUSE_REASONS.WRONG_STATE &&
    bindData.contractStateCode === BIND_STATES.NEW;

  /** Подключена услуга "Каникулы" */
  const isVacation = bindData.bindRefuseReason === BIND_REFUSE_REASONS.VACATION;
  const isAnnual = useMemo(() => durationDays === ANNUAL_SUBSCRIPTION, [
    durationDays,
  ]);

  /** Подписка активна */
  const isActiveSubscription = useMemo(() => {
    return !!statusString;
  }, [pendingUnbindDate]);

  /** Активная годовая подписка */
  const isActiveAnnual = isActiveSubscription && isAnnual;

  /** Цвет фона priceTag */
  const backgroundColor = useMemo(() => {
    if (isInsufficientFunds && !isActiveSubscription)
      return PriceTagBackgroundColor.WARNING;
    if (isNotServed && !isActiveAnnual) return PriceTagBackgroundColor.DOWN;
    if (chatlingCashback?.cashbackPercent) return PriceTagBackgroundColor.OK;
    return PriceTagBackgroundColor.DEFAULT;
  }, [isInsufficientFunds, isActiveSubscription, isNotServed, isActiveAnnual]);

  /** Цвет шрифта priceTag */
  const fontColor = useMemo(() => {
    if (isInsufficientFunds && !isActiveSubscription)
      return defaultTheme.colors.warning;
    if (isNotServed && !isActiveAnnual) return defaultTheme.colors.down;
    return defaultTheme.colors.black;
  }, [isInsufficientFunds, isActiveSubscription, isNotServed, isActiveAnnual]);

  /** Текст описания на ценнике */
  const subscriptionText =
    ((isInsufficientFunds || isNotServed) &&
      !isActiveAnnual &&
      `Недостаточно${'\u000A'}средств`) ||
    (chatlingCashback?.cashbackPercent &&
      `Вернём${'\u000A'}${
        chatlingCashback.cashbackPercent
      }% бонусами<sup>*</sup>`);

  return {
    isActiveSubscription,
    isAvailable,
    isInsufficientFunds,
    isNotServed,
    backgroundColor,
    fontColor,
    subscriptionText,
    requiredMoneyToBind,
    isVacation,
    isClientBlock,
    isNovice,
    isLoadingHook,
  };
};
