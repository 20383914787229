/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';

/** Стилизованные компоненты */
import { StyledSidePage } from '../Pab2cStyle';

/** Компоненты  */
import DeviceLeasePayWizard from '../Components/DeviceLeasePayWizard/DeviceLeasePayWizard';
import DeviceLeasePayWizardFooter from '../Components/DeviceLeasePayWizard/DeviceLeasePayWizardFooter';

/** Константы */
import {
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../stores/useDevicesStore';

/**
 * Блок pab2cDevices. SidePage погашения рассрочки
 */
const DeviceLeasePaySP: FC = () => {
  const {
    resetState,
    detailedDeviceStore: { detailedDevice, deviceOwnership },
    deviceLeasePayStore: {
      isDeviceLeasePayWizardShow,
      resultLeasePay,
      resetLeasePayState,
      leasePurchaseIsSucceeded,
      requestsState: { leasePay },
    },
  } = useDevicesStore();

  const {
    pab2cDevicesStore: { updatePab2cDevices },
    pab2cBalanceStore: { getBalance },
  } = useRootStore();

  const headerText = () => {
    if (deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING)
      return `Погашение рассрочки на ${DEVICE_TYPES_GENITIVE[
        detailedDevice?.deviceTypeCode
      ]?.toLowerCase()}  ${detailedDevice.modelName}`;
    return '';
  };

  const handleClick = async () => {
    resetState();
    resetLeasePayState();
    /** Обновить список оборудования и баланс при успешном погашении рассрочки */
    if (leasePurchaseIsSucceeded) {
      await updatePab2cDevices();
      setTimeout(getBalance, 4000);
      leasePay.reset();
    }
  };

  return (
    <StyledSidePage
      $isHideHeader={resultLeasePay.isResult}
      show={isDeviceLeasePayWizardShow}
      headerText={headerText()}
      onCloseClick={handleClick}
      footerContainer={
        !resultLeasePay.isResult ? <DeviceLeasePayWizardFooter /> : <></>
      }
      isOnlyMobileFooter
    >
      <DeviceLeasePayWizard />
    </StyledSidePage>
  );
};

export default observer(DeviceLeasePaySP);
