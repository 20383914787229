/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** Стили */
import { StyledFooter, StyledSnoska } from './style';
/** Компоненты */
import {
  Snoska,
  Button,
  ButtonStyleTypes,
  Link,
  defaultTheme,
} from 'cordis-core-ui-planeta';
/** Константы */
import { DOCUMENTS_LINK, VIEW_TYPES } from '../constants';
/** Типы */
import { AutoPaymentWizardFooterProps } from './types';
/** store */
import { useRootStore } from '~/stores/RootStore';
import useListOfSbpBanksStore from '../../../ListOfSbpBanks/store/useListOfSbpBanksStore';

const AutoPaymentWizardFooter: FC<AutoPaymentWizardFooterProps> = ({
  viewType,
  setViewType,
  unbindAutoPayment,
  isLoading,
  bindAutoPayment,
  setSbpBindingInfo,
  setSbpBindingCheckInfo,
  registerSbpPaymentWithBinding,
  minAmountValue,
}: AutoPaymentWizardFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const { setIsListOfSbpBanksOpen } = useListOfSbpBanksStore();

  return (
    <StyledFooter>
      {viewType === VIEW_TYPES.UNBIND && (
        <StyledSnoska>
          <Button
            type="button"
            styleType={ButtonStyleTypes.SECONDARY}
            onClick={() => unbindAutoPayment()}
            loading={isLoading}
            disabled={isTemporaryTokenAuth}
          >
            Отключить автоплатёж
          </Button>
          {isTemporaryTokenAuth && (
            <Snoska className="snoska" color={defaultTheme.colors.gray}>
              Действие доступно только клиенту
            </Snoska>
          )}
        </StyledSnoska>
      )}

      {viewType === VIEW_TYPES.BIND && (
        <>
          <StyledSnoska>
            <Button
              type="button"
              onClick={() => bindAutoPayment()}
              loading={isLoading}
              disabled={isTemporaryTokenAuth}
            >
              Подключить автоплатёж
            </Button>
            {isTemporaryTokenAuth && (
              <Snoska className="snoska" color={defaultTheme.colors.gray}>
                Действие доступно только клиенту
              </Snoska>
            )}
          </StyledSnoska>
          <Snoska className="legal">
            Нажатием кнопки вы&nbsp;подтверждаете согласие с&nbsp;условиями
            подключения автоплатежа, указанными в{'\u00A0'}
            <Link href={DOCUMENTS_LINK} target="_blank">
              Регламенте
            </Link>
            . Привязать счёт можно в&nbsp;приложении любого банка,{' '}
            <Snoska
              className="legal__link"
              color={defaultTheme.colors.planeta}
              onClick={() => setIsListOfSbpBanksOpen(true)}
            >
              поддерживающего подписки СБП
            </Snoska>
            . Для подключения автоплатежа мы&nbsp;спишем с&nbsp;новой карты или{' '}
            счёта&nbsp;СБП {minAmountValue}&nbsp;₽ и&nbsp;зачислим
            их&nbsp;на&nbsp;ваш Лицевой счёт. Вы&nbsp;будете перенаправлены
            на&nbsp;защищённый сайт или в&nbsp;приложение банка.
          </Snoska>
        </>
      )}

      {viewType === VIEW_TYPES.LINKING_CARD_FAILED && (
        <div className="button-block">
          <Button
            type="button"
            onClick={() => bindAutoPayment()}
            loading={isLoading}
          >
            Привязать карту
          </Button>
          <Snoska className="legal">
            Вы будете перенаправлены на защищённый сайт банка
          </Snoska>
        </div>
      )}

      {viewType === VIEW_TYPES.LINKING_ACCOUNT_FAILED && (
        <Button
          type="button"
          styleType={ButtonStyleTypes.SECONDARY}
          onClick={registerSbpPaymentWithBinding}
          loading={isLoading}
        >
          Попробовать ещё
        </Button>
      )}

      {viewType === VIEW_TYPES.UNBIND_FAILED && (
        <Button
          type="button"
          styleType={ButtonStyleTypes.SECONDARY}
          onClick={() => setViewType(VIEW_TYPES.UNBIND)}
          loading={isLoading}
        >
          Попробовать ещё
        </Button>
      )}

      {viewType === VIEW_TYPES.BIND_FAILED && (
        <Button
          type="button"
          styleType={ButtonStyleTypes.SECONDARY}
          onClick={() => {
            setSbpBindingInfo(null);
            setSbpBindingCheckInfo(null);
            setViewType(VIEW_TYPES.BIND);
          }}
          loading={isLoading}
        >
          Попробовать ещё
        </Button>
      )}
    </StyledFooter>
  );
};

export default observer(AutoPaymentWizardFooter);
