/** библиотеки */
import styled from '@emotion/styled';

import { StyledPointProps } from './Point.types';

export const StyledPoint = styled.div<StyledPointProps>`
  ${({ diam, posX, posY, isActive }) => `
    position: absolute;
    top: ${posX}px;
    left: ${posY}px;
    width: ${diam}px;
    height: ${diam}px;
    background: #24FF00;
    border-radius: 40%;
    opacity: ${isActive ? '1' : '0'};
  `}
`;
