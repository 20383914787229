/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo } from '../Legal.types';

/** интерфейс компонента-шаблона DeviceOffText */
interface DeviceOffTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона DeviceOffText
 * @param {DeviceOffTextProps} content
 * @returns {React.FC}
 */
const DeviceOffText: React.FC<DeviceOffTextProps> = ({
  content,
}: DeviceOffTextProps) => {
  const { devicePriceInfos } = content;

  // Проверяет возможность аренды
  const hasRentDevice: boolean = devicePriceInfos?.some(
    (device) => device.isAvailable,
  );

  // Скрываем шаблон по условию
  if (!hasRentDevice) return null;

  /**
   * текст шаблона
   * @returns {JSX.Element}
   */
  const Text = (): JSX.Element => (
    <span>
      Указанные условия пользования дополнительным оборудованием могут
      отличаться в случаях, когда обслуживание по Договору приостановлено.
    </span>
  );

  return <p>{Text()}</p>;
};

export default DeviceOffText;
