/** libraries */
import { FC } from 'react';
import { defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** utils */
import { pluralizeAll } from '~/utils/utils';
import { getStore } from '../../utils';

/** components */
import ChannelPackageCard from '../Cards/ChannelPackageCard/ChannelPackageCard';

/** constants */
import { PACKAGE_NOMINATIVE, STORE_TYPE } from '../../constants';

/** interfaces */
import { ChannelPackage } from '../../interfaces';

/** stores */
import { useMyTvStore } from '../../stores/useMyTvStore';

interface PackagesTabProps {
  /** Все пакеты телеканалов */
  tvChannelPackages: ChannelPackage[];
  /** Пакеты включённые в продукт на витрине */
  packagesIncludedInProduct?: ChannelPackage[];
  isTransformer?: boolean;
}

/** Таб "Пакеты" */
const PackagesTab: FC<PackagesTabProps> = ({
  tvChannelPackages,
  packagesIncludedInProduct,
  isTransformer,
}: PackagesTabProps) => {
  const { storeType } = useMyTvStore();
  const { onClickPackageCard } = getStore(storeType);
  /** Формат блока X2 */
  const isX2 = storeType === STORE_TYPE.MY_TV_X2;

  return (
    <div className="my-tv__tab-block__block">
      {!isX2 && (
        <LeadingText
          className="my-tv__tab-block__block__count"
          color={defaultTheme.colors.shadow}
        >
          {pluralizeAll(tvChannelPackages?.length, PACKAGE_NOMINATIVE)}
        </LeadingText>
      )}
      <div className="my-tv__tab-block__block__cards">
        {tvChannelPackages?.length > 0 &&
          tvChannelPackages.map((item) => {
            return (
              <ChannelPackageCard
                key={item.channelPackName}
                packet={item}
                onClick={() => onClickPackageCard(item)}
                packagesIncludedInProduct={packagesIncludedInProduct}
                isTransformer={isTransformer}
              />
            );
          })}
      </div>
    </div>
  );
};

export default observer(PackagesTab);
