/** libraries */
import { FC, useEffect, useState } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** interfaces */
import { Contacts } from '../../interfaces';
/** api */
import {
  addContact,
  notificationSubscription,
  verifyContact,
} from '~/api/apiPab2c';
/** constants */
import {
  ADD_CONTACT_SP_STATE,
  CONTACT_ERROR,
  CONTACT_TYPES,
  DEFAULT_ERROR,
} from '../../../../Templates/Pab2c/Settings/constants';
import {
  CELLPHONE_VALIDATE_REGEXP,
  EMAIL_VALIDATE_REGEXP,
} from '~/constants/common';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
/** styles */
import { StyledFooter } from '../../../../Templates/Pab2c/Settings/style';
/** stores */
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const AddingContactFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth, isAuth },
  } = useRootStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  const {
    startTimer,
    setTimerIsOn,
    contacts,
    addingContactStore: {
      addContactState: state,
      contactType,
      phoneNumber,
      email,
      code,
      contactData,
      setAddContactState,
      setCodeError,
      isNotification,
      setContactData,
      setPhoneNumberError,
      setEmailError,
      sendingCode,
      connectTariffCallback,
      setIsShowAddingContact,
      resetState,
    },
  } = useContactsAndNotificationsStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Подтверждение кода */
  const confirmCode = async () => {
    try {
      setIsLoading(true);
      await verifyContact(contactData.id, code);
      if (connectTariffCallback) {
        setIsShowAddingContact(false);
        connectTariffCallback();
        resetState();
        return;
      }
      setAddContactState(ADD_CONTACT_SP_STATE.RESULT);
      setOpenSPAfterAuthorizationState(null);
    } catch (e) {
      const error = e.errorMessage ? JSON.parse(e.errorMessage) : {};
      if (e.statusCode === 401) {
        setOpenSPAfterAuthorizationState(SIDE_PAGES.VERIFY_CONTACT);
        return;
      }
      setOpenSPAfterAuthorizationState(null);
      let errorMessage = '';
      switch (error.Type) {
        case 'ContactException': {
          errorMessage = 'Неверно указан код';
          break;
        }
        default: {
          errorMessage = DEFAULT_ERROR;
          break;
        }
      }
      setCodeError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  /** Подписка контакта на уведомления */
  const subscribeToNotifications = async (data?: Contacts) => {
    try {
      await notificationSubscription(
        1,
        data?.id ?? contactData.id,
        isNotification,
      );
    } catch (e) {
      console.error('subscribeToNotificationsError', e);
    }
  };

  /** Метод добавления контакта */
  const addingContact = async () => {
    try {
      setIsLoading(true);
      const res = await addContact(
        contactType.id,
        contactType.code === CONTACT_TYPES.PHONE
          ? phoneNumber.forSend.replace('+7', '8')
          : email,
      );
      setContactData(res);
      subscribeToNotifications(res);
      startTimer();
      setTimerIsOn(true);
      setAddContactState(
        contactType.code === CONTACT_TYPES.PHONE
          ? ADD_CONTACT_SP_STATE.CODE
          : ADD_CONTACT_SP_STATE.RESULT,
      );
      setOpenSPAfterAuthorizationState(null);
    } catch (e) {
      if (e.statusCode === 401) {
        setOpenSPAfterAuthorizationState(SIDE_PAGES.ADD_CONTACT);
        return;
      }
      setOpenSPAfterAuthorizationState(null);
      setPhoneNumberError(CONTACT_ERROR);
      setEmailError(CONTACT_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  /** Обработка добавления контакта */
  const addingContactHandler = (): void => {
    const samePhoneFromContacts = contacts.find(
      (item) => item.value === phoneNumber.forSend.replace('+7', '8'),
    );
    const sameEmailFromContacts = contacts.find((item) => item.value === email);

    if (
      contactType.code === CONTACT_TYPES.PHONE &&
      samePhoneFromContacts?.isVerified
    ) {
      setPhoneNumberError('Телефон уже добавлен');
      return;
    }
    if (
      contactType.code === CONTACT_TYPES.PHONE &&
      samePhoneFromContacts &&
      !samePhoneFromContacts?.isVerified
    ) {
      setContactData(samePhoneFromContacts);
      startTimer();
      setTimerIsOn(true);
      sendingCode();
      setAddContactState(
        contactType.code === CONTACT_TYPES.PHONE
          ? ADD_CONTACT_SP_STATE.CODE
          : ADD_CONTACT_SP_STATE.RESULT,
      );
      return;
    }
    if (contactType.code === CONTACT_TYPES.EMAIL && sameEmailFromContacts) {
      setEmailError('Электронная почта уже добавлена');
      return;
    }

    addingContact();
  };

  /** disable кнопки "добавить контакт" */
  const disableAddContact = () => {
    if (!contactType?.code) return true;
    if (
      state === ADD_CONTACT_SP_STATE.CONTACT &&
      contactType.code === CONTACT_TYPES.PHONE
    ) {
      return !CELLPHONE_VALIDATE_REGEXP.test(
        phoneNumber.forSend.replace('+7', '8'),
      );
    }
    if (
      state === ADD_CONTACT_SP_STATE.CONTACT &&
      contactType.code === CONTACT_TYPES.EMAIL
    ) {
      return !EMAIL_VALIDATE_REGEXP.test(email);
    }
    if (state === ADD_CONTACT_SP_STATE.CODE) {
      return code.length < 4;
    }
    return true;
  };

  useEffect(() => {
    if (!isAuth) return;
    if (openSPAfterAuthorizationState === SIDE_PAGES.ADD_CONTACT) {
      addingContactHandler();
    }
    if (openSPAfterAuthorizationState === SIDE_PAGES.VERIFY_CONTACT) {
      confirmCode();
    }
  }, [isAuth]);

  return (
    <StyledFooter>
      {state === ADD_CONTACT_SP_STATE.CONTACT && (
        <Button
          loading={isLoading}
          onClick={addingContactHandler}
          disabled={disableAddContact() || isTemporaryTokenAuth}
        >
          Добавить контакт
        </Button>
      )}
      {state === ADD_CONTACT_SP_STATE.CODE && (
        <Button
          loading={isLoading}
          onClick={confirmCode}
          disabled={disableAddContact()}
        >
          Подтвердить
        </Button>
      )}
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(AddingContactFooter);
