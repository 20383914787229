/** libraries */
import { SidePage, Snoska } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** styles */
import { StyledSidePageFooter } from '~/components/Blocks/Templates/Devices/style';
/** components */
import DescriptionOfSoftlineSubscription from '~/components/Blocks/Templates/Softline/DescriptionOfSoftlineSubscription/DescriptionOfSoftlineSubscription';
/** stores */
import useSoftlineStateModelStore from '~/components/Blocks/Templates/Softline/stores/useSoftlineStateStore';

/* Сайдпейдж с описанием подписки Softline */
const SoftlineDescriptionSP = (): JSX.Element => {
  const {
    softlineDescription,
    isOpenDescription,
    setIsOpenDescription,
  } = useSoftlineStateModelStore();
  return (
    <SidePage
      show={isOpenDescription}
      headerText={softlineDescription && `${softlineDescription.name}`}
      onCloseClick={() => setIsOpenDescription(false)}
      headerTitleText={
        softlineDescription && softlineDescription.nameFromMethod
      }
      footerContainer={
        softlineDescription?.footer && (
          <StyledSidePageFooter>
            <Snoska>{parseHtml(softlineDescription.footer)}</Snoska>
          </StyledSidePageFooter>
        )
      }
      removeScrollBar
      showMobileHeader
    >
      <DescriptionOfSoftlineSubscription />
    </SidePage>
  );
};

export default observer(SoftlineDescriptionSP);
