/** библиотеки */
import styled from '@emotion/styled';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const StyledSoftlineSubscribeWizardFooter = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
  }

  .error {
    margin-top: 16px;
  }

  @media (min-width: ${desktop940}px) {
    button {
      width: 191px;
    }
  }
`;
