/** библиотеки */
import { FC } from 'react';

/** компоненты библиотеки */
import { defaultTheme, H2, H3, Inset, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledSmartTV } from './SmartTV.style';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

const SmartTV: FC<AdvantagesContentProps> = ({ content }) => {
  const TITLE = 'Теперь можно без приставки';
  const TEXT = `Приложение для${'\u00A0'}Smart${'\u00A0'}TV${'\u00A0\u2014'} это плюс!`;
  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledSmartTV>
        <H3 className="title__mobile" color={defaultTheme.colors.planeta}>
          {TITLE}
        </H3>
        <H3 className="title" color={defaultTheme.colors.planeta}>
          {TITLE}
        </H3>
        <H2 className="title__400" color={defaultTheme.colors.planeta}>
          {TITLE}
        </H2>
        <Text className="text__mobile" color={defaultTheme.colors.shadow}>
          {TEXT}
        </Text>
        <Inset
          className="text__line-height21"
          color={defaultTheme.colors.shadow}
        >
          {TEXT}
        </Inset>
        <Inset className="text" color={defaultTheme.colors.shadow}>
          {TEXT}
        </Inset>
      </StyledSmartTV>
    </LinkWrapper>
  );
};

export default SmartTV;
