/** Библиотеки */
import * as React from 'react';
import Link from 'next/link';

/** Типы */
import {
  H3,
  LinkButton as CordisLinkButton,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { Category } from '../MenuPopup.types';

/** Компоненты */
import { StyledCategory } from './style';

/**
 * Создаёт элемент ссылки на категорию
 * @returns {React.FC}
 */
const CategoryItem: React.FC<Category> = (category: Category) => (
  <StyledCategory>
    <div>
      <Link href={category.href}>
        <span>
          <CordisLinkButton
            href={category.href}
            onClick={category.toggleHandler}
          >
            <H3>{category.title}</H3>
          </CordisLinkButton>
        </span>
      </Link>
    </div>
    {category.subTitile && (
      <div className="category-subtitle">
        <div>
          <Link href={category.href}>
            <span>
              <CordisLinkButton
                href={category.href}
                onClick={category.toggleHandler}
                color={defaultTheme.colors.black}
              >
                {category.subTitile}
              </CordisLinkButton>
            </span>
          </Link>
        </div>
      </div>
    )}
  </StyledCategory>
);

export default CategoryItem;
