/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { css } from '@emotion/react';

/** constants */
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop650,
  desktop700,
  desktop750,
  desktop900,
  desktop940,
} from '~/components/Grid/constants';

/** styles */
import {
  StyledRowContentTitle,
  StyledRowContentBody,
} from '../../common/RowContent/styles';

export const StyledPackageDescriptionCard = styled.div<{ isLightbox: boolean }>`
  ${({ isLightbox }) => {
    const cardWidth = isLightbox ? '271px' : null;
    return css`
      .package-description {
        &__header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid ${defaultTheme.colors.line};
          margin-bottom: 24px;
          padding-bottom: 24px;

          &__name-tag {
            h3 {
              letter-spacing: -0.03em;
            }
            > div[data-test-tag] {
              line-height: 0;
              margin-top: 4px;
            }
            &__time {
              display: flex;
              align-items: center;
              margin-top: 8px;

              > div:first-of-type {
                display: inherit;
                > div {
                  padding: 1px 8px;
                }
              }

              > svg {
                margin-left: 8px;
              }
            }
          }

          h3 {
            margin-bottom: 0;
          }

          &__close-icon {
            cursor: pointer;
          }
        }

        &__subcription-cost {
          h3 {
            margin-bottom: 0px;
          }

          &__button-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 19px;

            > span {
              width: 193px;
              margin: 16px 0 0 0;
            }
          }

          &__refusal {
            width: 174px;
            margin-top: 8px;
          }
        }

        &__channels-n-services {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr;
          flex-wrap: wrap;
          padding-bottom: 32px;
          width: auto;
          max-height: ${isLightbox ? 'fit-content' : '416px'};
          overflow-x: hidden;
          padding-right: 2px;
        }

        &__channels-n-services::-webkit-scrollbar {
          width: 5px;
        }

        &__channels-n-services::-webkit-scrollbar-track {
          background-color: ${defaultTheme.colors.white};
          border-radius: 10px;
        }

        &__channels-n-services::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: ${defaultTheme.colors.lightGrey};
        }

        &__subscription-management {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > span {
            width: auto;
            margin: 8px 0 0 0;
          }
        }

        &__transformer {
          &__button-block {
            margin-top: 32px;

            .scale {
              margin: 0 0 7px;
            }

            &__refusal-block {
              display: flex;
              flex-direction: column;
              margin-top: 16px;

              > span {
                margin-top: 8px;
              }
              button {
                height: 54px;
              }
              &__button {
                width: fit-content;
                padding: 15px 40px;
                > span {
                  letter-spacing: -0.03em;
                }
              }
            }
          }

          &__refusal-date {
            width: 267px;
            margin-top: 12px;
          }
        }

        &__add-all-channels {
          button {
            height: 54px;
            margin-top: 16px;
          }
        }

        &__lightbox {
          &__tag {
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid ${defaultTheme.colors.line};
            div[data-test-tag] {
              letter-spacing: -0.03em;
              cursor: default;
              line-height: 0;

              > div {
                padding: 1px 8px;
              }
            }
          }
        }
      }
      ${StyledRowContentBody} {
        max-width: -webkit-fill-available;
      }

      @media (min-width: ${desktop500}px) {
        .package-description {
          &__channels-n-services {
            grid-template-columns: 1fr 1fr 1fr;
          }

          &__subscription-management {
            flex-direction: row;
            align-items: center;

            > span {
              width: 134px;
              margin: 0 0 0 16px;
            }
          }

          &__transformer {
            &__button-block {
              &__refusal-block {
                flex-direction: row;
                align-items: center;

                > span {
                  margin: 0 0 0 16px;
                }
              }
            }
          }
        }
      }

      @media (min-width: ${desktop650}px) {
        .package-description {
          &__channels-n-services {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
      }

      @media (min-width: ${desktop700}px) {
        .package-description {
          &__transformer {
            &__button-block {
              &__refusal-block {
                &__button {
                  width: 398px;
                }
              }
            }
          }
        }
      }

      @media (min-width: ${desktop750}px) {
        .package-description {
          &__channels-n-services {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }
        }
      }

      @media (min-width: ${desktop900}px) {
        .package-description {
          &__channels-n-services {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .package-description {
          &__header {
            &__name-tag {
              > div[data-test-tag] {
                margin-top: 6px;
                line-height: 0;
              }
            }
          }

          &__subcription-cost {
            &__button-block {
              flex-direction: row;
              align-items: center;

              > span {
                width: 192px;
                margin: 0 0 0 16px;
              }
            }
          }

          &__channels-n-services {
            display: flex;
            gap: 24px;
            width: ${isLightbox ? '596px' : '670px'};
            max-height: 468px;
            > div {
              width: ${cardWidth ?? '307px'};
            }
          }
        }
        ${StyledRowContentTitle} {
          min-width: 170px;
          max-width: 170px;
        }
      }

      @media (min-width: ${desktop1100}px) {
        .package-description {
          &__channels-n-services {
            width: ${isLightbox ? '596px' : '805px'};
            > div {
              width: ${cardWidth ?? '241px'};
            }
          }
        }
        ${StyledRowContentTitle} {
          min-width: ${isLightbox ? '170px' : '215px'};
          max-width: ${isLightbox ? '170px' : '215px'};
        }
      }

      @media (min-width: ${desktop1280}px) {
        .package-description {
          &__channels-n-services {
            width: ${isLightbox ? '596px' : '940px'};
            > div {
              width: ${cardWidth ?? '287px'};
            }
          }
        }
        ${StyledRowContentTitle} {
          min-width: ${isLightbox ? '170px' : '260px'};
          max-width: ${isLightbox ? '170px' : '260px'};
        }
      }
    `;
  }}
`;
