/** libraries */
import { FC, useEffect } from 'react';
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import ContactsAndNotifications from './ContactsAndNotifications';
import Portal from '~/components/Portal/Portal';
/** stores */
import useContactsAndNotificationsStore from './store/useContactsAndNotificationsStore';

const ContactsAndNotificationsSidePage: FC = () => {
  const {
    isShowContactsAndNotifications,
    setIsShowContactsAndNotifications,
    getContactTypes,
    getAllContacts,
    getAllSubscriptions,
  } = useContactsAndNotificationsStore();

  useEffect(() => {
    if (isShowContactsAndNotifications) {
      getContactTypes();
      getAllContacts();
      getAllSubscriptions();
    }
  }, [isShowContactsAndNotifications]);

  return (
    <Portal>
      <SidePage
        show={isShowContactsAndNotifications}
        headerText="Контакты и уведомления"
        onCloseClick={() => setIsShowContactsAndNotifications(false)}
      >
        <ContactsAndNotifications />
      </SidePage>
    </Portal>
  );
};

export default observer(ContactsAndNotificationsSidePage);
