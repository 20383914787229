/** библиотеки */
import React, { FC, useState } from 'react';
import {
  Button,
  ButtonStyleTypes,
  LeadingText,
  Tabs,
  TabsStyleTypes,
  defaultTheme,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** типы */
import { ActiveTab, DeviceTabsProps } from './interfaces';
/** константы */
import { TABS_DEVICE, NO_OWNERSHIP_STB_ID } from './constants';
import { desktop1280, desktop940 } from '~/components/Grid/constants';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Компонент с карточкой оборудования
 */
const DeviceTabs: FC<DeviceTabsProps> = ({
  item,
  orderEquipment,
}: DeviceTabsProps) => {
  const { annuity, price, ownershipPriceOn, id } = item;
  const {
    authStore: { isAuth },
  } = useRootStore();

  // Возвращает массив табов
  const generateTabs = (): TABS_DEVICE[] | [] => {
    const newTabs = [];
    if (price !== null) {
      newTabs.push(TABS_DEVICE.BUY);
    }
    // Исключение телеприставок UHD300X и Q-Box Ultra Wi-Fi, так как не могут быть предоставлены в пользование
    if (
      ownershipPriceOn !== null &&
      id !== NO_OWNERSHIP_STB_ID.UHD300X &&
      id !== NO_OWNERSHIP_STB_ID.QBOXULTRAWIFI
    ) {
      newTabs.push(TABS_DEVICE.USE);
    }
    if (annuity !== null) {
      newTabs.push(TABS_DEVICE.ANNUITY);
    }
    return newTabs;
  };

  /* Событие при изменении таба */
  const onChangeTab = (index: number, value: string) => {
    setActiveTab({ index, value });
  };

  // Табы оборудования
  const deviceTabs = generateTabs();

  // Исходный активный таб
  const initialActiveTab = {
    index: 0,
    value: deviceTabs[0],
  };

  // Активный таб
  const [activeTab, setActiveTab] = useState<ActiveTab>(initialActiveTab);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  return (
    <>
      <div className="devices__item-tabs">
        <Tabs
          value={deviceTabs}
          styleType={TabsStyleTypes.SECONDARY}
          onChange={onChangeTab}
          activeTabIndex={activeTab.index}
        />
      </div>

      <div className="devices__item-tabs__button-block">
        <LeadingText color={defaultTheme.colors.black}>
          {activeTab.value === TABS_DEVICE.BUY && (
            <>{formatNumber(price)} &#8381;</>
          )}
          {activeTab.value === TABS_DEVICE.USE && (
            <>
              {isAuth ? '' : 'от'} {formatNumber(ownershipPriceOn)} ₽ в день
              {isAuth ? '' : '*'}
            </>
          )}
          {activeTab.value === TABS_DEVICE.ANNUITY && (
            <>{formatNumber(annuity)} &#8381; в день</>
          )}
        </LeadingText>
        {isDesktop940 && !isDesktop1280 ? (
          <Text
            lineHeight="24px"
            color={defaultTheme.colors.planeta}
            onClick={(e) => orderEquipment(e, null)}
          >
            Заказать
          </Text>
        ) : (
          <Button
            styleType={ButtonStyleTypes.SECONDARY}
            onClick={(e) => orderEquipment(e, null)}
          >
            Заказать
          </Button>
        )}
      </div>
    </>
  );
};

export default observer(DeviceTabs);
