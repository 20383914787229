/** libraries */
import { FC, useMemo } from 'react';
import {
  Button,
  Text,
  PriceTag,
  PriceTagBackgroundColor,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledPrepayment } from './styles';
/** interfaces */
import { NewContractProps } from '../../../ContractState/types';
/** constants */
import { desktop400, desktop940 } from '~/components/Grid/constants';
/** utils */
import LinkWrapper from '~/components/LinkWrapper';
import { getHeaderPhone } from '~/components/Header/utils';
import { formatNumber } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface PrepaymentProps {
  newContractInfo: NewContractProps;
}

const Prepayment: FC<PrepaymentProps> = ({
  newContractInfo,
}: PrepaymentProps) => {
  /** Достаточно средств для аванса */
  const isEnoughFunds = newContractInfo?.leftToPay === 0;

  // Вычисление ширины экрана
  const isDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    cityStore: { city, contactPhones },
  } = useRootStore();
  // Номер телефона
  const phone = useMemo(() => {
    return getHeaderPhone(contactPhones, city);
  }, [contactPhones, city]);

  return (
    <StyledPrepayment>
      <div className="prepayment__price-button">
        <PriceTag
          value={`${formatNumber(newContractInfo?.moneyCharge)} ₽`}
          subscription={
            isEnoughFunds
              ? `Чтобы завершить${
                  !isDesktop940 ? '\u000A' : ' '
                }процесс подключения${'\u000A'}${
                  isDesktop940 ? 'к сети оператора связи Планета' : ''
                }`
              : `Не хватает${'\u000A'}${formatNumber(
                  Math.abs(newContractInfo?.leftToPay),
                )} ₽`
          }
          backgroundColor={
            isEnoughFunds
              ? PriceTagBackgroundColor.OK
              : PriceTagBackgroundColor.WARNING
          }
          fontColor={
            isEnoughFunds
              ? defaultTheme.colors.black
              : defaultTheme.colors.warning
          }
          headerType={isDesktop940 ? 'H2' : 'H3'}
        />
        <LinkWrapper href="/payment">
          <Button>{`Пополнить ${isDesktop400 ? 'баланс' : ''}`}</Button>
        </LinkWrapper>
      </div>
      {isEnoughFunds ? (
        <Text className="prepayment__info" lineHeight="24px">
          В&nbsp;течение{' '}
          <Text lineHeight="24px" fontWeightBold>
            7&nbsp;дней
          </Text>{' '}
          после внесения аванса в&nbsp;необходимом размере мы&nbsp;свяжемся
          с&nbsp;вами, чтобы согласовать время подключения и&nbsp;презентации
          сервисов Планеты. В&nbsp;периоды высокой нагрузки срок ожидания звонка
          может быть увеличен.
        </Text>
      ) : (
        <Text className="prepayment__info" lineHeight="24px">
          В&nbsp;течение{' '}
          <Text lineHeight="24px" fontWeightBold>
            7&nbsp;дней
          </Text>{' '}
          после внесения аванса в &nbsp;необходимом размере мы&nbsp;свяжемся
          с&nbsp;вами, чтобы согласовать время подключения и&nbsp;презентации
          сервисов Планеты. В&nbsp;периоды высокой нагрузки срок ожидания звонка
          может быть увеличен.
        </Text>
      )}
      <br />
      <Text lineHeight="24px">
        По вопросам подключения обращайтесь по телефону{' '}
        <Text lineHeight="24px" fontWeightBold>
          {phone}.
        </Text>
      </Text>
    </StyledPrepayment>
  );
};

export default observer(Prepayment);
