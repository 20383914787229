/** библиотеки */
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

/** размеры экрана */
import { Text, defaultTheme, H3, Link, Snoska } from 'cordis-core-ui-planeta';
import { desktop940, desktop1100 } from '~/components/Grid/constants';

/** компоненты библиотеки */

/** стили */
import {
  StyledFeedbackBlock,
  StyledRatingPointsList,
  StyledSourceLinkBlock,
} from '../style';

/**
 * компонент Отзыв с Flamp
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=403095610
 */
const FeedbackFlamp3: React.FC = () => {
  /** Вычисление ширины экрана */
  const is1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const is940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const FeedbackTextComponents = {
    H3,
    Text,
  };

  const FeedbackTextComponent =
    !is1100 && is940 ? FeedbackTextComponents.Text : FeedbackTextComponents.H3;

  const ratingPoints = ['*', '*', '*', '*', '*'];

  const IconFlamp = (): JSX.Element => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#pattern-feedback-flamp)" />
      <defs>
        <pattern
          id="pattern-feedback-flamp"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image-feedback-flamp" transform="scale(0.0178571)" />
        </pattern>
        <image
          id="image-feedback-flamp"
          width="56"
          height="56"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAACeUlEQVRoBe1aSy9DQRQ+V9rSalHiEUFiISHdCSsaVjbEBolEYmshiFj4ARZWFiIRYSfED5CIhbBBIiyEUPFIxCOekWrTFrcPvUSYmqs9V+lMM5N0Md+cmfm+852Ze9tUquo7CwFPTZJQbFNQ0RwGC4EcmkZQFg4S6eCwIxzk0DSCsnCQSAeHHeEgh6YRlIWDRDo47Oh+y9mUKkFDpQlsJQYoLdBDab4eMkzxLwzH+Qt0jtyg6WoWqHxraaxOh97mLLCa4y8IrURlgmaBg61WaKkxqyzLDqwp9e11Fi7EKWlGCyzM0UFPUyY7FkVhghbYUW8Bgx73s0EUDn86jD6DdlsalVAgCDC74oalbS/cuwJvMaNduVBWqKfG90/dU3E10OMLb6ChoQUWWOlT5jc8MDbvJCj4A+q/Z63u+YjYv+qgS1SNyMm1rDaUUBwt8NFLL5W2WjNkM/g8RAvcPX2mOlKSq4Px7jzqWCJB+oH6gdHCphfsNiM14uzOT8Vp4PRAPg0mMKcnCL0TdwSG7aAFLu944eDCAuVFhm97rTtivzgqir/Pj1zw4zaOxDF9dIkGw0dwaO4B5IgbMhS+MNcdT5i9/yUWLVBhdXgpv5XOg/v9eadgx1cy3Do/+wrGQtMkUCG+dfQMbcPXMLn4CK7wzbq2H3t5/qdw9Bn8Sk4RNrXogpllNxgNbL6+/Urgh1jfSwiUD6bF8qom+3Fr0vaPi0DawtEw7l7VoglK1LjmSyZRhLH7CoHYjLEWLxxkzREsn6R3UBL/k8HWBGPxSV+iQiBjFYemIxxEp4yxCcJBxgxB0xEOolPG2AThIGOGoOkIB9EpY2xC0jv4CmM6hkBj2shfAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );

  return (
    <StyledFeedbackBlock>
      <StyledRatingPointsList data-test-list="">
        {ratingPoints.map((item, index) => (
          <li key={String(index)} data-test-li="">
            <div className="feedback-block__circle">
              <Snoska color={defaultTheme.colors.white}>{index + 1}</Snoska>
            </div>
          </li>
        ))}
      </StyledRatingPointsList>
      <div className="feedback-block__message feedback-block__message--flamp">
        <FeedbackTextComponent fontWeightBold>
          &laquo;Радуют три вещи: поддержка IPv6, белый IP и отсутствие
          авторизаторов&raquo;
        </FeedbackTextComponent>
      </div>
      <div className="feedback-block__author">
        <Text color={defaultTheme.colors.shadow}>Александр Берсенёв</Text>
      </div>
      <div>
        <Link
          href="https://ekaterinburg.flamp.ru/firm/planeta_ooo_komtekhcentr-1267165676195197/otzyv-6075512"
          color={defaultTheme.colors.disable}
          target="_blank"
        >
          <StyledSourceLinkBlock>
            <div className="feedback-block__icon feedback-block__icon--flamp">
              <IconFlamp />
            </div>
            <Text color={defaultTheme.colors.disable}>Отзыв на Flamp</Text>
          </StyledSourceLinkBlock>
        </Link>
      </div>
    </StyledFeedbackBlock>
  );
};

export default FeedbackFlamp3;
