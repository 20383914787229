/** libraries */
import { FC } from 'react';
import Image from 'next/image';
import { Button, H2, Snoska, Text } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** interfaces */
import { ProlongationProps } from '../../interfaces';
/** styles */
import { StyledProlongationNotification } from './styles';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber } from '~/utils/utils';
/** constants */
import { desktop370, desktop940 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useAnnualProductRenewalStore from '~/components/Blocks/Shared/AnnualProductRenewalWizard/store/useAnnualProductRenewalStore';

interface ProlongationNotificationProps {
  prolongation: ProlongationProps;
}

/** Баннер пролонгации годового продукта */
const ProlongationNotification: FC<ProlongationNotificationProps> = ({
  prolongation,
}: ProlongationNotificationProps) => {
  const {
    summaryDataStore: { seriesName },
    prolongationStore: { prolongationInfo, isLoadingAgreement, getAgreement },
  } = useRootStore();
  const {
    setSeriesName,
    setIsAnnualProductRenewal,
  } = useAnnualProductRenewalStore();

  const { text, image, legal } = prolongation;

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  // Вычисление ширины экрана
  const isDesktop360 = useMediaQuery({
    query: `(min-width: ${desktop370}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const getObjectFit = () => {
    const isDesktop = isDesktop940;
    if (isDesktop) return 'fill';
    if (!isDesktop) return 'contain';
    return 'cover';
  };

  return (
    <StyledProlongationNotification>
      <Text className="prolongation__info" lineHeight="24px">
        {parseHtml(text)}
      </Text>
      <div className="prolongation__image">
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}${image}`}
          alt="mic"
          layout="fill"
          objectFit={getObjectFit()}
          objectPosition="bottom"
          quality={100}
        />
      </div>
      <div className="prolongation__price-button">
        <H2>{`${formatNumber(prolongationInfo.prolongation.price)} ₽`}</H2>
        <Button
          onClick={() =>
            getAgreement(setIsAnnualProductRenewal, seriesName, setSeriesName)
          }
          loading={isLoadingAgreement}
        >
          {isDesktop360 ? 'Продлить продукт' : 'Продлить'}
        </Button>
      </div>
      <Snoska>{parseHtml(legal)}</Snoska>
    </StyledProlongationNotification>
  );
};

export default observer(ProlongationNotification);
