/** libraries */
import { cast, flow, getParent, types } from 'mobx-state-tree';
/** api */
import { devicePurchaseCheck } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** constants */
import { OWNERSHIP_CODES } from '../constants';
/** interfaces */
import { IDevicesStore } from './DevicesStore';

const RequestsStateModel = types.model('State', {
  devicePurchaseCheck: createApiPathModel(
    'GET /Device/ContractDevice/DevicePurchaseCheck',
  ),
});

const DevicePurchaseCheckResultModel = types.model({
  leaseAnnuity: types.maybe(types.number),
  leaseCredit: types.maybe(types.number),
  leasePeriod: types.maybe(types.number),
  notAvailableFundsError: types.maybe(types.boolean),
});

const DevicePurchasesModel = types
  .model({
    requestsState: RequestsStateModel,
    /** Флаг сайдпейджа покупки оборудования */
    isDevicePurchasesWizardShow: types.boolean,
    /** Показать детальный сайдпейдж приобретения оборудования */
    isPurchaseDeviceShow: types.boolean,
    /** Оборудование приобретается в рассрочку */
    isLease: types.boolean,
    /** Данные проверке возможности выкупа оборудования */
    devicePurchaseCheckResult: types.maybeNull(DevicePurchaseCheckResultModel),
    /** Данные проверке возможности выкупа оборудования в рассрочку */
    deviceLeasePurchaseCheckResult: types.maybeNull(
      DevicePurchaseCheckResultModel,
    ),
  })
  .views((self) => ({
    get text() {
      return self.isPurchaseDeviceShow;
    },
    get isLoading() {
      return self.requestsState.devicePurchaseCheck.isLoading;
    },
  }))
  .actions((self) => ({
    setIsPurchaseDeviceShow: (isShow: boolean) => {
      self.isPurchaseDeviceShow = isShow;
    },
    setIsDevicePurchasesWizardShow: (isShow: boolean) => {
      self.isDevicePurchasesWizardShow = isShow;
    },
    setIsLease: (isLease: boolean) => {
      self.isLease = isLease;
    },
    /** Проверка возможности выкупить оборудование */
    devicePurchaseCheck: flow(function* (sim) {
      self.requestsState.devicePurchaseCheck.reset();
      self.requestsState.devicePurchaseCheck.setLoading();
      try {
        const res = yield devicePurchaseCheck(sim, self.isLease);
        self.devicePurchaseCheckResult = res;
        self.requestsState.devicePurchaseCheck.setSuccess();
      } catch (e) {
        console.error(e, 'devicePurchaseCheck');
        self.requestsState.devicePurchaseCheck.setFail();
        const error = JSON.parse(e.errorMessage);
        if (error.Type === 'DevicePurchaseCheckNotAvailableFundsException') {
          self.devicePurchaseCheckResult = cast({
            notAvailableFundsError: true,
          });
          return;
        }
        self.devicePurchaseCheckResult = null;
      }
    }),
    /** Проверка возможности оформления рассроки на оборудование */
    deviceLeaseCheck: flow(function* (sim) {
      self.requestsState.devicePurchaseCheck.reset();
      self.requestsState.devicePurchaseCheck.setLoading();
      try {
        const res = yield devicePurchaseCheck(sim, true);
        self.deviceLeasePurchaseCheckResult = res;
        self.requestsState.devicePurchaseCheck.setSuccess();
      } catch (e) {
        console.error(e, 'devicePurchaseCheck');
        self.requestsState.devicePurchaseCheck.setFail();
        const error = JSON.parse(e.errorMessage);
        if (error.Type === 'DevicePurchaseCheckNotAvailableFundsException') {
          self.deviceLeasePurchaseCheckResult = cast({
            notAvailableFundsError: true,
          });
          return;
        }
        self.deviceLeasePurchaseCheckResult = null;
      }
    }),
    /** Изменение состояния сайдпейджа */
    openSidePage: (state, isDeviceLease) => {
      switch (state) {
        case OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP:
          self.isPurchaseDeviceShow = true;
          self.isLease = isDeviceLease;
          break;
        case OWNERSHIP_CODES.LEASING:
          self.isDevicePurchasesWizardShow = true;
          self.isLease = isDeviceLease;
          break;
        case OWNERSHIP_CODES.BOUGHT_LEASING:
          const {
            deviceLeasePayStore: { setIsDeviceLeasePayWizardShow },
          }: IDevicesStore = getParent(self);
          setIsDeviceLeasePayWizardShow(true);
          break;
        default:
          break;
      }
    },
    resetPurchaseStore: () => {
      self.isLease = false;
      self.isPurchaseDeviceShow = false;
      self.isDevicePurchasesWizardShow = false;
    },
  }));

export const DevicePurchasesStoreInstance = {
  requestsState: {
    devicePurchaseCheck: defaultModelState,
  },
  isDevicePurchasesWizardShow: false,
  isPurchaseDeviceShow: false,
  isLease: false,
  devicePurchaseCheckResult: null,
  deviceLeasePurchaseCheckResult: null,
};

export default DevicePurchasesModel;
