/** Библиотеки */
import React, { FC, RefObject, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** Стилизованные компоненты */
import {
  LoaderWrapper,
  StyledSoftlines,
  StyledSoftlinesHeader,
} from './Pab2cStyle';
/** Компоненты  */
import { H3, Loader, Button, ButtonStyleTypes } from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';
/** Константы */
import { desktop940 } from '~/components/Grid/constants';
import { SOFT_SLUG } from '~/constants/common';
import { TITLE_SOFTLINE_BLOCK } from './constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** components */
import SoftlineSliderList from './SoftlineSliderList/SoftlineSliderList';
/** interfaces */
import { SoftlinesContentProps } from './interfaces';
/** store */
import { Pab2cSoftlineProvider } from './store/usePab2cSoftlineStore';

/**
 * Блок «РМП. Мои антивирусы»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=774543281
 */
const Softlines: FC<SoftlinesContentProps> = ({ content }) => {
  const {
    authStore: { isLoadingAuth },
    softlineStore: { isFewSubscriptions, isLoadingSoftlineSubscriptions },
  } = useRootStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isDataLoading = isLoadingSoftlineSubscriptions || isLoadingAuth;

  // Ссылка на основной блок компонента
  const softlinesRef: RefObject<HTMLDivElement> = useRef(null);

  return (
    <Pab2cSoftlineProvider fields={content.fields}>
      <StyledSoftlines
        ref={softlinesRef}
        isFewSubscriptions={isFewSubscriptions}
      >
        <StyledSoftlinesHeader>
          <H3>{TITLE_SOFTLINE_BLOCK}</H3>
          {!isFewSubscriptions && (
            <LinkWrapper href={SOFT_SLUG} target="_blank">
              {isDesktop940 ? (
                'Заказать ещё'
              ) : (
                <Button styleType={ButtonStyleTypes.SECONDARY}>
                  Заказать ещё
                </Button>
              )}
            </LinkWrapper>
          )}
        </StyledSoftlinesHeader>

        {isDataLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}

        {!isDataLoading && <SoftlineSliderList softlinesRef={softlinesRef} />}
      </StyledSoftlines>
    </Pab2cSoftlineProvider>
  );
};

export default observer(Softlines);
