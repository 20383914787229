/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';

export const ApplyTransformerWizardStyled = styled.div`
  .apply {
    display: flex;
    flex-direction: column;

    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      &__header {
        margin-bottom: 8px;
      }

      > div {
        width: 100%;
        margin: 0;
      }
    }

    &__price {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      height: auto;
      margin-bottom: 32px;

      &__block {
        margin-bottom: 16px;
      }

      &__block:last-of-type {
        margin-bottom: 0;
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 700;
        letter-spacing: -0.03em;
      }
    }

    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      margin-bottom: 32px;
      padding: 16px;

      > span:first-of-type {
        margin-bottom: 8px;
      }
    }

    &__view-control {
      margin-bottom: 32px;
    }

    &__agreement {
      margin-bottom: 32px;
      > span:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .apply {
      &__price {
        flex-direction: row;

        &__block {
          margin-bottom: 0;
        }

        &__block:first-of-type {
          width: 289px;
        }

        &__block:nth-of-type(2) {
          width: 45%;
        }

        &__block:nth-of-type(3) {
          margin-top: 24px;
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .apply {
      &__price {
        h3 {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }
`;
