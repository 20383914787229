/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';

/** components */
import {
  H1,
  H2,
  H3,
  defaultTheme,
  LeadingText,
  ButtonStyleTypes,
  Text,
} from 'cordis-core-ui-planeta';
import Wrapper, { Header, MainText, StyledButton, StyledLine } from './styles';

/** config */
import CONFIG from '../../config';
import { desktop940, desktop350 } from '~/components/Grid/constants';
import Image from 'next/image';
/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';

/** Линия с бегунком */
export const Line: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop350 = useMediaQuery({
    query: `(min-width: ${desktop350}px)`,
  });
  const imageLoader = ({ src, width }) => {
    return `${src}?w=${width}`;
  };
  return (
    <div className="lines">
      <div className="lines__fade" />
      <div className="lines__line">
        <Text className="dates" color={defaultTheme.colors.white}>
          2003
        </Text>
      </div>
      <div className="lines__point">
        {!isDesktop350 && <H3 color={defaultTheme.colors.white}>300013</H3>}
        {!isDesktop940 && isDesktop350 && (
          <H2 color={defaultTheme.colors.white}>300013</H2>
        )}
        {isDesktop940 && <H1 color={defaultTheme.colors.white}>300013</H1>}
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${CONFIG[8].icon}`}
          alt={CONFIG[8].header}
          width={72}
          height={72}
        />
        <Text color={defaultTheme.colors.white}>2023</Text>
      </div>
    </div>
  );
};

/** Слайд Екатеринбурга */
const Lead: FC = () => {
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  if (!isDesktop940)
    return (
      <Wrapper $isMobile>
        <Header
          dangerouslySetInnerHTML={{ __html: CONFIG[8].headerMobile }}
          as={H3}
          color={defaultTheme.colors.white}
          $isMobile
        />
        <StyledLine>
          <Line />
        </StyledLine>
        <MainText
          dangerouslySetInnerHTML={{ __html: CONFIG[8].mainTextMobile }}
          as={Text}
          color={defaultTheme.colors.white}
        />
        <StyledButton
          styleType={ButtonStyleTypes.DARK_BACKGROUND}
          onClick={toggleConnectionWizardVisible}
        >
          Присоединиться
        </StyledButton>
      </Wrapper>
    );

  return (
    <Wrapper>
      <StyledLine>
        <Line />
      </StyledLine>
      <Header as={H2} color={defaultTheme.colors.white}>
        {CONFIG[8].header}
      </Header>
      <MainText
        dangerouslySetInnerHTML={{ __html: CONFIG[8].mainText }}
        as={LeadingText}
        color={defaultTheme.colors.white}
      />
      <StyledButton
        styleType={ButtonStyleTypes.DARK_BACKGROUND}
        onClick={toggleConnectionWizardVisible}
      >
        Подключить Планету
      </StyledButton>
    </Wrapper>
  );
};

export default observer(Lead);
