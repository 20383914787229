/** libraries */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop430, desktop940 } from '~/components/Grid/constants';

export const ChannelCardStyled = styled.div<{
  nameLineHeight: number;
  disabled: boolean;
}>`
  ${({ nameLineHeight, disabled }) => {
    return css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 114px;
      min-width: 103.5px;
      min-width: 103.5px;
      border: 1px solid ${defaultTheme.colors.disable};
      padding: 12px 7px 0 7px;
      background-color: ${defaultTheme.colors.white};
      cursor: ${disabled ? 'default' : 'pointer'};

      .channel-card {
        &__definition {
          ${disabled && `color: ${defaultTheme.colors.disable};`}
          margin-bottom: 4px;
        }

        &__header-block {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          position: relative;
          height: 100%;
        }

        &__header {
          display: flex;
          flex-direction: column;
          align-items: center;

          &__name,
          &__name-highlighted {
            text-align: center;
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: ${nameLineHeight > 16 ? '4px' : '16px'};
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            ${disabled && `color: ${defaultTheme.colors.disable};`};

            &-highlighted {
              color: ${defaultTheme.colors.gray};
              & mark {
                color: ${defaultTheme.colors.black};
                background-color: transparent;
              }
            }
          }
        }

        &__logo {
          height: 38px;
          width: 77px;
          display: flex;
          margin: 4px 0 0 0;
          ${nameLineHeight > 16
            ? 'position: absolute; bottom: 4px;'
            : 'margin-bottom: 4px;'}

          > span {
            margin: auto;
          }
        }

        &__info-block {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          &__tag {
            margin-right: 6px;

            > div {
              & > div {
                padding: 1px 8px;
              }
            }
          }

          > svg {
            margin-right: 4px;
          }

          &__price {
            padding: 2px 8px;
            font-size: 11px;
            letter-spacing: -0.01em;
            text-align: center;
            ${disabled && `color: ${defaultTheme.colors.disable};`}
          }
        }

        &__availability {
          font-size: 11px;
          line-height: 16px;
          letter-spacing: -0.01em;
          margin-bottom: 6px;
        }

        &__disabled-text {
          margin-top: 6px;
        }

        &__icons {
          line-height: 16px;
          > span {
            margin-right: 19px;
          }
        }

        &__block {
          height: 100%;
        }
      }

      div[data-test-tag] {
        letter-spacing: -0.03em;
        cursor: default;
        line-height: 0;
      }

      @media (min-width: ${desktop430}px) {
        width: 100%;
      }

      @media (min-width: ${desktop940}px) {
        height: 140px;
        width: 282px;
        padding: 12px 12px 14px 16px;

        .channel-card {
          &__header-block {
            flex-direction: row;
            align-items: flex-start;
          }

          &__header {
            align-items: flex-start;
            &__name,
            &__name-highlighted {
              text-align: inherit;
              line-height: 20px;
              font-size: 16px;
              margin-bottom: 0;
              margin-top: 4px;
            }
          }

          &__logo {
            height: 46px;
            width: 96px;
            margin-left: 8px;
            margin-bottom: 0;
            position: inherit;
          }

          &__block {
            height: auto;
          }

          &__info-block {
            &__price {
              padding: 2px 0;
              text-align: inherit;
            }
          }
        }
      }
    `;
  }}
`;
