/** libraries */
import { types, Instance, cast, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { BanksProps } from '../../types';
/** constants */
import { CHECK_URL_AMOUNT } from '../../constants';
/** utils */
import createApiPathModel from '~/stores/models/createApiPathModel';
import { banksListImageConverter } from '../../utils';
/** api */
import { getAllBanks } from '~/api/api';

const RequestsStateModel = types.model('State', {
  getConvertedBanksList: createApiPathModel('GET /Sbp/GetAllBanks'),
});

export const BanksPropsModel = types.model({
  bankName: types.string,
  logo: types.string,
  schema: types.string,
  width: types.maybe(types.number),
  height: types.maybe(types.number),
  sort: types.maybe(types.number),
});

const MobileSBPModel = types
  .model({
    requestsState: RequestsStateModel,
    /** Выбранный банк для оплаты по СБП */
    selectedBank: types.maybeNull(BanksPropsModel),
    /** Текст результата проверки приложения банка */
    deepLinkText: types.string,
    /** ссылка на оплату СБП */
    payload: types.string,
    /** Открыть сайдпейдж */
    isOpenSBPCards: types.boolean,
    /** Страница ожидания платежа */
    waitingPage: types.boolean,
    /** Список банков со скорректированными иконками */
    convertedBanksList: types.array(BanksPropsModel),
    /** Список банков для привязки СБП со скорректированными иконками */
    convertedSBPBanksList: types.array(BanksPropsModel),
    /** Количество попыток проверок доступа до эквайринга */
    fetchAmount: types.number,
    /** ID таймера для проверки платежа по СБП */
    timerId: types.maybeNull(types.frozen<NodeJS.Timeout>()),
    /** Флаг сброса состояний */
    isZeroing: types.boolean,
    /** Использовать список банков для привязки СБП */
    isUseSBPBanksList: types.boolean,
  })
  .views((self) => ({
    get banksList() {
      return toJS(
        self.isUseSBPBanksList
          ? self.convertedSBPBanksList
          : self.convertedBanksList,
      );
    },
  }))
  .actions((self) => {
    return {
      setSelectedBank: (bank: BanksProps) => {
        self.selectedBank = cast(bank);
      },
      setDeepLinkText: (text: string) => {
        self.deepLinkText = text;
      },
      setIsOpenSBPCards: (isOpen: boolean) => {
        self.isOpenSBPCards = isOpen;
      },
      setWaitingPage: (isWaiting: boolean) => {
        self.waitingPage = isWaiting;
      },
      setPayload: (payload: string) => {
        self.payload = payload;
      },
      setFetchAmount: (num: number) => {
        self.fetchAmount = num;
      },
      setTimerId: (id: NodeJS.Timeout) => {
        self.timerId = id;
      },
      setIsZeroing: (isZeroing: boolean) => {
        self.isZeroing = isZeroing;
      },
      setIsUseSBPBanksList: (flag: boolean) => {
        self.isUseSBPBanksList = flag;
      },
      getConvertedBanksList: flow(function* () {
        self.requestsState.getConvertedBanksList.reset();
        self.requestsState.getConvertedBanksList.setLoading();
        try {
          const allBanks = yield getAllBanks();
          const banksList = allBanks.map((bank, index) => ({
            ...bank,
            sort: index,
          }));
          const res = yield banksListImageConverter(banksList);
          self.convertedBanksList = res;
          self.requestsState.getConvertedBanksList.setSuccess();
        } catch (e) {
          console.error('getConvertedBanksList', e);
          self.requestsState.getConvertedBanksList.setFail();
        }
      }),
      getConvertedSBPBanksList: flow(function* (sbpBanks) {
        try {
          const res = yield banksListImageConverter(sbpBanks);
          self.convertedSBPBanksList = res;
        } catch (e) {
          console.error('getConvertedSBPBanksList', e);
        }
      }),
      resetStore: () => {
        self.isOpenSBPCards = false;
        self.waitingPage = false;
        clearInterval(self.timerId);
        self.fetchAmount = CHECK_URL_AMOUNT;
        self.deepLinkText = '';
        self.payload = '';
        self.isUseSBPBanksList = false;
        self.isZeroing = false;
      },
    };
  });

export default MobileSBPModel;
export type IMobileSBPStore = Instance<typeof MobileSBPModel>;
