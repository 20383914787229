import { ReactNode, useContext } from 'react';
import { createDeviceStore, IDeviceStore } from './DeviceStore';
import { FieldsProps } from '../interfaces';
import { MobXProviderContext, Provider, useLocalObservable } from 'mobx-react';

interface DeviceProviderProps {
  children: ReactNode;
  fields: FieldsProps;
}

export const DeviceStoreProvider = ({
  children,
  fields,
}: DeviceProviderProps): JSX.Element => {
  const store = useLocalObservable(() => createDeviceStore(fields));
  return <Provider DeviceStore={store}>{children}</Provider>;
};

const useDeviceStore = (): IDeviceStore => {
  const { DeviceStore } = useContext(MobXProviderContext);
  return DeviceStore;
};

export default useDeviceStore;
