/* Библиотеки */
import style from '@emotion/styled';

export const StyledPrepaymentContent = style.div`
  ${() => `
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 35%;
      white-space: pre-line;
      h3 {
        margin-bottom: 0;
      }    
    `}
`;
