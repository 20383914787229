import Head from 'next/head';
import { DefaultSeo } from 'next-seo';

interface Props {
  title: string;
  description: string;
}

const SEO = ({ title, description }: Props): JSX.Element => {
  return (
    <>
      <DefaultSeo title={title} description={description} />
      <Head>
        <></>
      </Head>
    </>
  );
};

export default SEO;
