/** библиотеки */
import { ReactNode } from 'react';
import { Link, LinkStyleTypes } from 'cordis-core-ui-planeta';
import ReactHTMLParser, { convertNodeToElement } from 'react-html-parser';

/**
 * Преобразует ссылки в полученной строке в компонент библиотеки
 * @param html строка html
 */
export const parseHtml = (html: string): ReactNode => {
  const transform = (node, index): ReactNode => {
    if (node.type === 'tag' && node.name === 'a') {
      return (
        <Link
          key={index}
          href={node.attribs.href}
          target={node.attribs.target}
          styleType={LinkStyleTypes.SECONDARY}
        >
          {node.children[0]?.data}
        </Link>
      );
    }
    return convertNodeToElement(node, index, transform);
  };

  const options = {
    decodeEntities: true,
    transform,
  };

  return ReactHTMLParser(html, options);
};
