import { Block } from '~/interfaces/BlockInterface';

export interface StyleProps {
  style: STYLE;
}

export interface SecondMenuFields extends StyleProps {
  settings: TwentyYearsStyle | SuperPlanetStyle;
  menu: MenuProps[];
}

export enum STYLE {
  /** 20 лет */
  TWENTY_YEARS = 'twentyYears',
  /** Cверх планета */
  SUPER_PLANET = 'superPlanet',
}

export interface StyledTextProps {
  textColor: string;
}

/** Стиль лендинга "20 лет" */
export interface TwentyYearsStyle extends StyledTextProps {
  headerText: string;
  mainText: string;
  imgLink: string;
  backgroundColor: string;
  menu: MenuProps[];
}

/** Стиль лендинга "СверхПланета" */
export interface SuperPlanetStyle {
  /** Анимированные слова */
  animationWords?: string[];
  backgroundColor?: string;
  backgroundImgLink?: string;
  mobileBackgroundImgLink: string;
}

export interface SecondMenuContentProps {
  content: SecondMenuProps;
}

interface SecondMenuProps extends Block {
  fields: SecondMenuFields;
}

export interface MenuProps {
  name: string;
  link: string;
}

/** Проверка типа settings на лендинг "20 лет"  */
export const isTwentyYearsStyle = (
  settings: TwentyYearsStyle | SuperPlanetStyle,
): settings is TwentyYearsStyle => {
  return (settings as TwentyYearsStyle).headerText !== undefined;
};
