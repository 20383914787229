// Тип состояния отображение визарда
export enum VIEW_TYPES {
  /** Обслуживание не приостановлено */
  PAUSE,
  /** Успешная приостановка обслуживания */
  PAUSE_SUCCESS,
  /** Обслуживание приостановлено, отображается возобновление */
  RESUME,
  /** Успешное возобновление */
  RESUME_SUCCESS,
  /** Неуспешная приостановка обслуживания */
  PAUSE_ERROR,
  /** Неуспешное возобновление обслуживания */
  RESUME_ERROR,
}
