/** библиотеки */
import { FC } from 'react';
import Image from 'next/image';

/** компоненты библиотеки */
import { defaultTheme, H2, H3, Inset, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledTimeshift } from './Timeshift.style';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

/** константы */
import { IMAGES } from '../constants';

const Timeshift: FC<AdvantagesContentProps> = ({ content }) => {
  const TITLE = 'Управление просмотром';
  const TEXT = `Смотрите сериалы, фильмы и${'\u00A0'}передачи когда удобно`;

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };
  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledTimeshift>
        <div className="timeshiftShadow" />
        <div className="timeshiftContent">
          <div>
            <H2 className="title__wide" color={defaultTheme.colors.white}>
              {TITLE}
            </H2>
            <H3 className="title" color={defaultTheme.colors.white}>
              {TITLE}
            </H3>
            <Text className="text__240" color={defaultTheme.colors.white}>
              {TEXT}
            </Text>
            <Inset className="text" color={defaultTheme.colors.white}>
              {TEXT}
            </Inset>
          </div>
        </div>
        <div className="timeshiftContent__bottom">
          <Image
            loader={imageLoader}
            src={IMAGES.timeshiftArrow}
            alt="arrow"
            width={69}
            height={69}
            quality={100}
          />
          <Text lineHeight="25px" color={defaultTheme.colors.white}>
            Архив передач до 7 дней
          </Text>
        </div>
      </StyledTimeshift>
    </LinkWrapper>
  );
};

export default Timeshift;
