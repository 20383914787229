/** Библиотеки */
import * as React from 'react';

/** Типы */
import { Text } from 'cordis-core-ui-planeta';
import { Folder, SubFolder } from '../MenuPopup.types';

/** Компоненты */
import { StyledFolder } from './style';
import SubFolderItem from '../SubFolderItem';

/**
 * Компонент элемента раздела
 * @returns {React.FC}
 */
const FolderItem: React.FC<Folder> = (folder: Folder) => {
  return (
    <StyledFolder>
      <Text fontWeightBold>{folder.name}</Text>
      <ul data-test-list="">
        {folder.subFolders.map((item: SubFolder) => (
          <li key={item.name} data-test-li="">
            <SubFolderItem
              name={item.name}
              href={item.href}
              toggleHandler={folder.toggleHandler}
            />
          </li>
        ))}
      </ul>
    </StyledFolder>
  );
};

export default FolderItem;
