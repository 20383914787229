/** libraries */
import { FC, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
/** interfaces */
import { TimelineContentProps } from '~/components/Blocks/Templates/Timeline/interfaces';
/** components */
import Nozzle from '~/components/Blocks/Templates/Timeline/Nozzle';
import MiddleRow from './MiddleRow';
import Wrapper from './style';
import { desktop940 } from '~/components/Grid/constants';
import RightSide from '~/components/Blocks/Templates/Timeline/MiddleRow/RightSide';
import MainSide from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide';

/** Timeline: https://ckb.itmh.ru/pages/viewpage.action?pageId=711867721 */
const Timeline: FC<TimelineContentProps> = ({
  content: {
    fields: { firstItem, lastItem, middleItems },
  },
}) => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  /** Генерация ключей для списка */
  const ids = useMemo(() => {
    return !middleItems.length
      ? []
      : middleItems.map(() => ({
          middleItemId: nanoid(5),
          mainSideId: nanoid(5),
          rightSideId: nanoid(5),
        }));
  }, [middleItems]);

  if (!isDesktop940)
    return (
      <Wrapper>
        {middleItems.map(({ rightSide, mainSide, date }, index) => {
          if (mainSide >= 0)
            return (
              <MainSide
                key={ids[index].mainSideId}
                isMobile
                date={date}
                index={mainSide}
              />
            );
          return (
            <RightSide
              key={ids[index].rightSideId}
              date={date}
              {...rightSide}
            />
          );
        })}
      </Wrapper>
    );

  return (
    <Wrapper>
      <Nozzle content={firstItem} />
      {middleItems
        .filter((item) => item.date !== '0')
        .map((props, index) => (
          <MiddleRow key={ids[index].middleItemId} {...props} />
        ))}
      <Nozzle isEnd content={lastItem} />
    </Wrapper>
  );
};

export default Timeline;
