/* Значение тега по умолчанию */
export const DEFAULT_TAG = { tag: 'Все', hash: 'all' };
// Номер страницы с анонсами
export const INIT_ANNOUNCEMENT_PAGE = 1;
// Ширина блока с анонсом для мобильной версии (ширина экрана 500 и менее)
export const MOBILE_WIDTH = 216;
// Количество анонсов, которые можно показать в блоке
export const BLOCKS_COUNT = 3;
// Страница с анонсами
export const DEFAULT_ANNOUNCEMENTS_PAGE = '/news';
// Количество запрашиваемых записей
export const DEFAULT_RESULT_COUNT = 24;
// Тег для смены оформления анонса на анонс Истории
export const STORIES_TAG = 'stories';
