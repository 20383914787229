/** библиотеки */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;

export const StyledDevicePurchasesWizard = styled.div`
  .device-purchases {
    &__block-list {
      display: flex;
      flex-flow: column;
      gap: 0;
    }
    &__block-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      &__header {
        margin-bottom: 4px;
      }

      &__price-tag {
        display: flex;
        flex-direction: column;

        > div:first-of-type {
          width: auto;
          .colorContainer {
            flex-direction: column;
            align-items: start;
            padding: 4px 16px 4px 8px;

            .subscription {
              white-space: inherit;
            }
          }
        }
        > div:nth-of-type(2) {
          margin-top: 16px;

          button {
            width: 100%;
          }
        }
      }
    }

    &__warning-text {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 32px;
    }

    &__guarantee {
      margin-bottom: 24px;
      a {
        color: ${defaultTheme.colors.planeta};
      }
    }

    &__agreement {
      margin: 16px 0 32px;
      > span:first-of-type {
        margin-bottom: 8px;
      }
    }

    &__buy-button {
      width: 100%;
    }

    &__result-icon {
      margin: -51px 0 26.5px;

      & svg,
      svg * {
        stroke: ${defaultTheme.colors.planeta};
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .device-purchases {
      &__block-list {
        display: flex;
        flex-flow: row;
        gap: 2rem;

        > div:first-of-type {
          width: 175px;
        }
      }
      &__block-wrapper {
        &__price-tag {
          flex-direction: row;
          align-items: end;

          > div:first-of-type {
            width: fit-content;

            .colorContainer {
              flex-direction: row;
              align-items: center;
              width: max-content;

              .subscription {
                white-space: break-spaces;
              }
            }
          }
          > div:nth-of-type(2) {
            margin-left: 32px;

            button {
              width: auto;
            }
          }
        }
      }

      &__buy-button {
        width: auto;
      }
    }
  }
`;
