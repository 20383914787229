/** libraries */
import { FC, memo } from 'react';
import styled from '@emotion/styled';
/** components */
import PhoneConnection from './PhoneConnection';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
import { StyledText } from '../style';
/** config */
import { PHONE_TEXT } from '~/components/Blocks/Templates/Pab2c/ContractState/config';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
`;

const WaitingConnection: FC = () => {
  return (
    <Root>
      <Text color={defaultTheme.colors.white}>
        Свяжемся в&nbsp;течение 7&nbsp;дней
      </Text>
      <Text color={defaultTheme.colors.disable}>
        после поступления платежа.
      </Text>
      <StyledText color={defaultTheme.colors.disable}>
        В&nbsp;периоды высокой нагрузки срок ожидания звонка может быть
        увеличен.
      </StyledText>
      <PhoneConnection text={PHONE_TEXT.CONNECTION} />
    </Root>
  );
};

export default memo(WaitingConnection);
