/** библиотеки */
import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  defaultTheme,
  H2,
  H3,
  Icons,
  SidePage,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** компоненты */
import WinkConnectionWizard from '~/components/Blocks/Templates/Pab2c/MyTV/Components/SidePages/WinkConnectionWizard';
import RowContent from '~/components/Blocks/Templates/Pab2c/MyTV/Components/common/RowContent';
import Portal from '~/components/Portal/Portal';

/** стили */
import { AdvantageListStyled, AdvantageStyled, WinkStyled } from './styles';
import { StyledFooter } from '../../../styles';

/** интерфейсы */
import { Advantage } from './types';
import { ResultProps } from '~/components/Blocks/Templates/Pab2c/MyTV/Components/common/SidePageResult/types';
import { WinkConnectionStatus } from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

/** константы */
import {
  CardStates,
  STORE_TYPE,
} from '~/components/Blocks/Templates/Pab2c/MyTV/constants';
import { desktop940 } from '~/components/Grid/constants';
import {
  advantages,
  WINK_ERROR_TYPE,
} from '~/components/Blocks/Templates/Pab2c/MyTV/Components/Services/Wink/constants';
import {
  DEFAULT_RESULT,
  WIZARD_STATE,
} from '~/components/Blocks/Templates/Pab2c/MyTV/Components/SidePages/WinkConnectionWizard/constants';

/** api */
import { winkAccountCreate } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';
/** utils */
import { getStore } from '../../../utils';

/** Сервис "Wink" */
const Wink: FC = (): JSX.Element => {
  const {
    tvStore: { winkInfo, getWink },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const { storeType } = useMyTvStore();
  const { setCardState } = getStore(storeType);

  const isLightbox = storeType === STORE_TYPE.MY_TV_X2;

  /** флаг открытия сайдпейджа */
  const [isOpened, setIsOpened] = useState<boolean>(false);
  /** Флаг загрузки результата подключения */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /** объект результата */
  const [serviceConnectResult, setServiceConnectResult] = useState<ResultProps>(
    DEFAULT_RESULT,
  );
  /** состояние подключения */
  const [connectionState, setConnectionState] = useState<WIZARD_STATE>(
    WIZARD_STATE.PHONE,
  );

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  /** Текст на кнопке */
  const buttonText = (): string => {
    if (winkInfo.accountStatus === WinkConnectionStatus.not_activated) {
      return isDesktop940 ? 'Активировать сервис' : 'Активировать';
    }
    return isDesktop940 ? 'Подключить сервис' : 'Подключить';
  };

  const handleSubmit = async (phone: string) => {
    if (connectionState === WIZARD_STATE.CODE_SEND) {
      setConnectionState(WIZARD_STATE.CODE_VALIDATE);
      return;
    }

    setIsLoading(true);

    if (connectionState === WIZARD_STATE.PHONE) {
      try {
        const result = await winkAccountCreate(phone);
        if (result) {
          await getWink();
          setIsLoading(false);
        }
      } catch (e) {
        const error = JSON.parse(e.errorMessage);
        setIsLoading(false);
        switch (error.Type) {
          case WINK_ERROR_TYPE.ACCOUNT_ALREADY_EXIST:
            setConnectionState(WIZARD_STATE.CODE_VALIDATE);
            break;
          case WINK_ERROR_TYPE.PHONE_ALREADY_EXIST:
            const result = await winkAccountCreate();
            if (result) {
              await getWink();
              setIsLoading(false);
            }
            break;

          default:
            setServiceConnectResult({
              isResult: true,
              isCorrect: false,
              text: 'Произошла ошибка. Повторите позже',
            });
        }
      }
    }
  };

  useEffect(() => {
    setIsLoading(false);
    setConnectionState(WIZARD_STATE.PHONE);
  }, []);

  useEffect(() => {
    if (winkInfo.accountStatus === WinkConnectionStatus.not_activated) {
      setConnectionState(WIZARD_STATE.CODE_VALIDATE);
    } else {
      setConnectionState(WIZARD_STATE.PHONE);
    }
  }, [isOpened, winkInfo]);

  const renderAdvantage = (advantage: Advantage, index: number) => {
    return (
      <AdvantageStyled key={index}>
        {advantage.icon && advantage.icon}
        <Text lineHeight="24px">{advantage.title}</Text>
      </AdvantageStyled>
    );
  };

  return (
    <WinkStyled>
      <div className="service__header">
        <H3>Wink</H3>
        {!isLightbox && (
          <div
            className="service__header__close-icon"
            onClick={() => {
              setCardState(CardStates.MAIN);
            }}
          >
            <Icons.CloseIcon />
          </div>
        )}
      </div>
      {winkInfo?.accountStatus === WinkConnectionStatus.activated && (
        <div className="service__tag">
          <Tag
            color={defaultTheme.colors.white}
            colorTag={defaultTheme.colors.green}
            backgroundColor={defaultTheme.colors.green}
          >
            Подключён
          </Tag>
        </div>
      )}
      <RowContent>
        <Text lineHeight="24px">
          Развлекательный видеосервис Wink. Более 50&nbsp;тысяч фильмов,
          сериалов и&nbsp;ТВ&mdash;контента, включая телеканалы
          Планеты&nbsp;&mdash; выбирайте и&nbsp;смотрите с&nbsp;любого
          устройства! Приятного просмотра!
        </Text>
      </RowContent>
      {winkInfo?.accountStatus !== WinkConnectionStatus.activated && (
        <RowContent title="Подключение сервиса">
          <Text>
            <H2>Бесплатно</H2>
            <StyledFooter>
              <Button
                loading={isLoading}
                onClick={() => setIsOpened(true)}
                disabled={isTemporaryTokenAuth}
              >
                {buttonText()}
              </Button>
              {isTemporaryTokenAuth && (
                <Snoska className="snoska" color={defaultTheme.colors.gray}>
                  Действие доступно только клиенту
                </Snoska>
              )}
            </StyledFooter>
          </Text>
        </RowContent>
      )}
      <RowContent title="Преимущества сервиса" removeLine>
        <AdvantageListStyled>
          {advantages.map((advantage, index) =>
            renderAdvantage(advantage, index),
          )}
        </AdvantageListStyled>
      </RowContent>

      <Portal>
        <SidePage
          show={isOpened}
          headerText={!serviceConnectResult.isResult && 'Подключение Wink'}
          onCloseClick={() => {
            setIsOpened(false);
            setServiceConnectResult(DEFAULT_RESULT);
          }}
          customHeaderHeight={32}
        >
          <WinkConnectionWizard
            result={serviceConnectResult}
            state={connectionState}
            handleSubmit={handleSubmit}
            setWizardState={setConnectionState}
            isLoading={isLoading}
          />
        </SidePage>
      </Portal>
    </WinkStyled>
  );
};

export default observer(Wink);
