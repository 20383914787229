/** libraires */
import { flow, Instance, types, cast } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** api */
import { getMarketingGroupsByCity } from '~/api/api';
import { getSummariesByTariffIds } from '~/api/apiPab2c';
/** interfaces */
import {
  AllowedTariffProps,
  ProductSwitcherFields,
  ProductSwitcherTemplate,
} from '../interfaces';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** types */
import {
  ActionFieldsModel,
  TariffPropsModel,
} from '~/stores/models/ConnectionTariffModel';
import { BUSINESS_GROUP_CODE } from '../../Summary/Summary.types';
/** components */
import { createTagsStore, TagsStoreModel } from './TagsStore';
import { SummaryDataStoreInstance } from '~/stores/SummaryDataStore';
import { DefaultImgs } from '../../Device/Device.types';

const RequestsStateModel = types.model('State', {
  getMarketingGroups: createApiPathModel(
    'GET /Summary/Summary/GetMarketingGroupsByCity',
  ),
  getSummariesByTariffIds: createApiPathModel(
    'GET /Summary/Summary/GetSummariesByTariffIds',
  ),
});

const ProductsModel = types.model({
  action: types.maybe(ActionFieldsModel),
  productFeedLink: types.maybe(types.string),
  promoCodes: types.maybe(types.array(types.string)),
  seriesCode: types.string,
  cities: types.maybe(types.array(types.number)),
  sort: types.number,
});

const ArchiveSummaryFieldsModel = types.model({
  seriesCode: types.string,
  productFeedLink: types.maybe(types.string),
  sort: types.number,
  tariffId: types.maybe(types.number),
});

const SpecialSummaryFieldsModel = types.model({
  price: types.number,
  seriesCode: types.maybe(types.string),
  tariffId: types.maybe(types.number),
});

const SwitchTariffModel = types.union(
  /** Основная модель продукта */
  TariffPropsModel,
  /** Модель для непубличных/архивных продуктов */
  ArchiveSummaryFieldsModel,
  /** Модель для продуктов Сотрудник */
  SpecialSummaryFieldsModel,
);

const ProductSwitcherFieldsModel = types.model({
  products: types.array(types.union(ProductsModel, ArchiveSummaryFieldsModel)),
  productsNotAvailableText: types.string,
  unionTariffNotAuth: types.array(SwitchTariffModel),
  sample: types.maybe(
    types.enumeration(Object.values(ProductSwitcherTemplate)),
  ),
});

const MarketingSeriesModel = types.model({
  seriesId: types.number,
  seriesCode: types.string,
  seriesName: types.string,
  sort: types.maybeNull(types.number),
});

const MarketingGroupsModel = types.model({
  id: types.maybe(types.number),
  name: types.string,
  mem: types.maybeNull(types.string),
  code: types.string,
  sort: types.number,
  marketingSeries: types.maybe(types.array(MarketingSeriesModel)),
  productList: types.array(SwitchTariffModel),
});

const DefaultImgModel = types.model({
  type: types.string,
  imgLink: types.string,
});

const ProductSwitcherModel = types
  .model('ProductSwitcherModel', {
    requestsState: RequestsStateModel,
    tagsStore: TagsStoreModel,
    fields: ProductSwitcherFieldsModel,
    /** Дефолтные изображения оборудования */
    defaultImgs: types.array(DefaultImgModel),
    /** Маркетинг-категории продуктов */
    marketingGroupsData: types.array(MarketingGroupsModel),
    /** Доступные тарифы в авторизованном состоянии */
    tariffsAuth: types.array(SwitchTariffModel),
    /** Показать заглушку с текстом недоступности смены продукта */
    isShowProductsNotAvailable: types.boolean,
  })
  .views((self) => ({
    get sample() {
      return self.fields.sample;
    },
    get marketingGroups() {
      return toJS(self.marketingGroupsData);
    },
    /** Доступные в городе продукты */
    get marketingGroupsProducts() {
      if (!self.marketingGroupsData.length) return [];
      return self.marketingGroupsData.reduce((acc, item) => {
        acc.push(...item.marketingSeries.map((series) => series.seriesCode));
        return acc;
      }, []);
    },
    /** Информация о подключенном продукте */
    get extendedSummaryData() {
      const { seriesCode, summaryData } = SummaryDataStoreInstance;
      if (!seriesCode) return null;
      const summaryFromCompare = self.fields.products.find(
        (summary) => summary.seriesCode === seriesCode,
      );
      return {
        ...summaryData,
        ...summaryFromCompare,
      };
    },
    /** Отфильтрованные по тегам продукты */
    get filteredTariff() {
      const { tags, activeProductTag } = self.tagsStore;
      if (!tags.length || !activeProductTag) return [];
      const filteredTag =
        tags.find((tag) => tag.code === activeProductTag.code)?.productsList ??
        [];
      return [...filteredTag].sort((a, b) => a.sort - b.sort);
    },
  }))
  .views((self) => ({
    get tariffs() {
      if (self.isShowProductsNotAvailable) return [];
      const tariffs = self.tariffsAuth.length
        ? toJS(self.tariffsAuth).filter(
            (item) =>
              item.seriesCode &&
              item.seriesCode !== self.extendedSummaryData?.seriesCode,
          )
        : toJS(self.fields.unionTariffNotAuth).filter(
            (item) =>
              item.seriesCode &&
              item.seriesCode !== self.extendedSummaryData?.seriesCode &&
              self.marketingGroupsProducts.includes(item.seriesCode),
          );
      return tariffs;
    },
  }))
  .views((self) => ({
    get isLoading() {
      return (
        self.requestsState.getMarketingGroups.isLoading ||
        (!self.tagsStore.activeProductTag &&
          !self.isShowProductsNotAvailable) ||
        (self.isShowProductsNotAvailable && !self.tariffs)
      );
    },
  }))
  .actions((self) => ({
    getMarketingGroups: flow(function* (cityId: number, isAuth: boolean) {
      self.requestsState.getMarketingGroups.reset();
      self.requestsState.getMarketingGroups.setLoading();
      try {
        const res = yield getMarketingGroupsByCity(cityId);
        const groups =
          isAuth || self.fields.sample === ProductSwitcherTemplate.MINI
            ? res
            : res.filter((item) => {
                return item.code !== BUSINESS_GROUP_CODE;
              });
        self.marketingGroupsData = groups;
        self.requestsState.getMarketingGroups.setSuccess();
      } catch (e) {
        self.requestsState.getMarketingGroups.setFail();
        console.error('getMarketingGroups', e);
      }
    }),
    /** Объединение данных о тарифах для авторизованного пользователя */
    defineTariffInfo: flow(function* (
      isAuth: boolean,
      allowedTariff: AllowedTariffProps[],
    ) {
      if (
        !self.marketingGroupsProducts.length ||
        (isAuth && !allowedTariff?.length) ||
        (!isAuth && allowedTariff?.length)
      )
        return;
      self.requestsState.getSummariesByTariffIds.reset();
      self.requestsState.getSummariesByTariffIds.setLoading();

      const unionTariffAuth = [];
      if (allowedTariff?.length && isAuth) {
        const allowedTariffIds = allowedTariff.map(
          (item) => item.tariffTo.tariffId,
        );
        try {
          const summariesData = yield getSummariesByTariffIds(allowedTariffIds);
          const dataForTariff = allowedTariff.map((item) => {
            const summaryFromCompare = toJS(self.fields.products).find(
              (summary) => summary.seriesCode === item.tariffTo.seriesCode,
            );
            const extendedSummary = summaryFromCompare
              ? summariesData.find(
                  (summary) =>
                    summary.seriesCode === summaryFromCompare.seriesCode,
                )
              : null;
            return {
              ...item,
              ...summaryFromCompare,
              ...(extendedSummary ?? null),
            };
          });
          unionTariffAuth.push(...dataForTariff);
        } catch (e) {
          console.error('defineTariffInfo', e);
          self.requestsState.getSummariesByTariffIds.setFail();
        }
      }

      self.isShowProductsNotAvailable = isAuth && unionTariffAuth.length <= 1;
      self.tariffsAuth = cast(unionTariffAuth);
      self.requestsState.getSummariesByTariffIds.setSuccess();
    }),
    setSample(sample: ProductSwitcherTemplate) {
      self.fields.sample = sample;
    },
    setFields(fields: ProductSwitcherFields) {
      self.fields = cast(fields);
    },
  }));

export type IProductSwitcherStore = Instance<typeof ProductSwitcherModel>;

export const createProductSwitcherStore = (
  fields: ProductSwitcherFields,
  defaultImgs: DefaultImgs[],
) => {
  return ProductSwitcherModel.create({
    requestsState: {
      getMarketingGroups: defaultModelState,
      getSummariesByTariffIds: defaultModelState,
    },
    tagsStore: createTagsStore(),
    fields,
    defaultImgs,
    marketingGroupsData: [],
    tariffsAuth: [],
    isShowProductsNotAvailable: false,
  });
};
