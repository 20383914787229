/** библиотеки */
import React, { FC, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { observer } from 'mobx-react';

/** константы */
import { VIEW_TYPES } from './constants';
import {
  MAX_AMOUNT_VALUE,
  MIN_AMOUNT_VALUE,
} from '~/components/Blocks/Templates/Payment/constants';
import { desktop940 } from '~/components/Grid/constants';

/** типы */
import { AutoPaymentWizardProps } from './types';
import {
  BanksProps,
  PaymentCardProps,
} from '~/components/Blocks/Templates/Payment/types';
import { SbpBindingsProps } from '~/components/Blocks/Templates/PaymentResult/interfaces';

/** стилевые компоненты */
import { StyledAutoPaymentWizard } from './style';
import {
  defaultTheme,
  Input,
  Text,
  Icon,
  Icons,
  PriceTag,
  H2,
  H3,
  LeadingText,
  Loader,
} from 'cordis-core-ui-planeta';
/* Компоненты */
import CardAndSbpPaymentSelect from '../../CardAndSbpPaymentSelect/CardAndSbpPaymentSelect';
import Portal from '~/components/Portal/Portal';
import ListOfSbpBanks from '../../ListOfSbpBanks/ListOfSbpBanks';
import Timer from '../../Timer/Timer';
/* utils */
import { chooseIconForSelect } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useListOfSbpBanksStore from '../../ListOfSbpBanks/store/useListOfSbpBanksStore';

const AutoPaymentWizard: FC<AutoPaymentWizardProps> = ({
  viewType,
  amount,
  setAmount,
  setIsAutoCount,
  errorMessage,
  card,
  setCard,
  stateFormError,
  convertedSbpBanksList,
  sbpBindingInfo,
  setTimeIsUp,
  setErrorMessage,
  isOtherAmount,
  setIsOtherAmount,
}: AutoPaymentWizardProps) => {
  const {
    paymentInfoStore: { sbpBindings, paymentCards },
    autoPaymentInfoStore: { autoPaymentData },
  } = useRootStore();
  const { setIsListOfSbpBanksOpen } = useListOfSbpBanksStore();

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const maxPayment = autoPaymentData?.maxPayment ?? MAX_AMOUNT_VALUE;
  const minPayment = autoPaymentData?.minPayment ?? MIN_AMOUNT_VALUE;

  // При вводе суммы
  const handleOnChangeInputMoney = (params) => {
    const { value, forSend } = params;
    const maxAmountValue = String(maxPayment).replace(/\u00A0/, '');
    if (forSend > +maxAmountValue)
      setAmount({
        value: `${maxAmountValue} \u20BD`,
        forSend: `${maxAmountValue}`,
      });
    else setAmount({ value, forSend });
  };

  /** Привязанная карта */
  const getBindedCard = (): PaymentCardProps => {
    return paymentCards.find(
      (item: PaymentCardProps) => item.id === autoPaymentData?.bindingId,
    );
  };

  /** Привязанный счёт сбп */
  const getBindedSbpAccount = (): SbpBindingsProps & BanksProps => {
    const binding = sbpBindings.find(
      (item: SbpBindingsProps) => item.id === autoPaymentData?.bindingId,
    );
    const bankInfo =
      convertedSbpBanksList?.length && binding
        ? convertedSbpBanksList.find(
            (bank) =>
              bank.bankName.toLowerCase() === binding.bankName.toLowerCase(),
          )
        : [];
    return binding ? { ...binding, ...(bankInfo as BanksProps) } : null;
  };

  const warningBlock = () => {
    return (
      <div className="AutoPaymentWizard__warning">
        <Text lineHeight="24px">
          <Text lineHeight="24px" fontWeightBold>
            Нельзя привязать счёт&nbsp;СБП
          </Text>{' '}
          в&nbsp;банковских приложениях некоторых банков.
          <br />
          Ознакомьтесь со{'\u00A0'}
          <Text
            className="AutoPaymentWizard__warning__banks"
            lineHeight="24px"
            color={defaultTheme.colors.planeta}
            onClick={() => setIsListOfSbpBanksOpen(true)}
          >
            списком банков
          </Text>
          , поддерживающих подписки СБП.
        </Text>
      </div>
    );
  };

  /** qr привязки сбп */
  const linkedSpbView = (): JSX.Element => {
    return (
      <>
        <div className="AutoPaymentWizard__bind__qr">
          <div>
            <Image
              src={`data:image/png;base64, ${sbpBindingInfo.qrImage}`}
              alt="qrcode"
              width={212}
              height={212}
              quality={100}
            />
          </div>
          <div className="AutoPaymentWizard__bind__qr__text-block">
            <Text lineHeight="24px">
              Отсканируйте QR-код из приложения банка
              <br />
              или через камеру телефона
            </Text>
            <div className="AutoPaymentWizard__bind__qr__text-block__timer">
              <Text lineHeight="24px">Ожидаем ответ от вашего банка:</Text>
              <div className="AutoPaymentWizard__bind__qr__text-block__timer__loader">
                <Loader small />
                <Timer setTimeIsUp={setTimeIsUp} />
              </div>
            </div>
          </div>
        </div>
        {warningBlock()}
      </>
    );
  };

  /** Сумма автоплатежа */
  const autopaymentAmount = useMemo(() => {
    return autoPaymentData?.autoCalcPayment;
  }, [autoPaymentData]);

  useEffect(() => {
    setAmount({
      value: `${autopaymentAmount} \u20BD`,
      forSend: `${autopaymentAmount}`,
    });
  }, [autoPaymentData, autopaymentAmount]);

  /** Основная вёрстка подключения или отключения автоплатежа */
  const mainWizardView = (): JSX.Element => {
    return (
      <>
        <div className="AutoPaymentWizard__form">
          <div className="AutoPaymentWizard__input-wrapper AutoPaymentWizard__input-wrapper-money">
            {viewType === VIEW_TYPES.UNBIND ? (
              <div className="AutoPaymentWizard__price-tag">
                {autoPaymentData?.payment !== null && (
                  <PriceTag
                    header="Сумма автоплатежа"
                    value={`${autoPaymentData?.payment} ₽`}
                    subscription={`Оплата произойдёт, когда баланс${'\u000A'}лицевого счёта будет меньше${'\u00A0'}100${'\u00A0'}₽`}
                    headerType={isDesktop940 ? 'H2' : 'H3'}
                  />
                )}
              </div>
            ) : (
              <>
                {isOtherAmount ? (
                  <>
                    <div className="AutoPaymentWizard__money">
                      <div className="AutoPaymentWizard__money__block">
                        <div>
                          <Text className="AutoPaymentWizard__input-text">
                            Сумма автоплатежа
                          </Text>
                          <Input
                            className="AutoPaymentWizard__input-field AutoPaymentWizard__input-money"
                            type="money"
                            placeholder="500 &#8381;"
                            value={amount.value}
                            onChangeCustomInput={handleOnChangeInputMoney}
                            width="100%"
                          />
                        </div>
                        <Text
                          lineHeight="24px"
                          color={defaultTheme.colors.planeta}
                          onClick={() => {
                            setIsAutoCount(true);
                            setAmount({
                              value: `${autopaymentAmount} \u20BD`,
                              forSend: `${autopaymentAmount}`,
                            });
                            setIsOtherAmount(false);
                          }}
                        >
                          Рассчитывать при каждой оплате
                        </Text>
                      </div>
                      <Text
                        color={
                          stateFormError.amount.error
                            ? defaultTheme.colors.planeta
                            : defaultTheme.colors.gray
                        }
                        lineHeight="24px"
                      >
                        Сумма {isDesktop940 && 'платежа'} от {minPayment} до{' '}
                        {maxPayment} {isDesktop940 ? 'рублей' : '₽'}.
                        <br />
                        <Text
                          color={defaultTheme.colors.gray}
                          lineHeight="24px"
                        >
                          Оплата произойдёт, когда баланс лицевого счета будет
                          меньше&nbsp;100&nbsp;₽
                        </Text>
                      </Text>
                    </div>
                  </>
                ) : (
                  <div className="AutoPaymentWizard__price-tag">
                    {autoPaymentData && (
                      <PriceTag
                        header="Сумма автоплатежа"
                        value={`${autopaymentAmount} ₽`}
                        subscription={`Оплата произойдёт, когда баланс${'\u000A'}лицевого счёта будет меньше${'\u00A0'}100${'\u00A0'}₽`}
                        headerType={isDesktop940 ? 'H2' : 'H3'}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {viewType === VIEW_TYPES.BIND && !isOtherAmount && (
            <div className="AutoPaymentWizard__warning-block">
              <Text lineHeight="24px">
                <Text lineHeight="24px" fontWeightBold>
                  Сумма автоплатежа может меняться
                </Text>{' '}
                в&nbsp;зависимости от&nbsp;состава подключённых услуг. Расчёт
                производится автоматически перед каждой оплатой. Сумма
                рассчитывается как среднее значение списаний
                за&nbsp;30&nbsp;дней.
              </Text>
              <Text
                className="AutoPaymentWizard__warning-block__button"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={() => {
                  setIsAutoCount(false);
                  setAmount({
                    value: `${autopaymentAmount} \u20BD`,
                    forSend: `${autopaymentAmount}`,
                  });
                  setIsOtherAmount(true);
                }}
              >
                Указать другую сумму
              </Text>
            </div>
          )}

          {viewType === VIEW_TYPES.UNBIND && (
            <div className="AutoPaymentWizard__warning-block">
              {isOtherAmount && (
                <Text lineHeight="24px">
                  <Text lineHeight="24px" fontWeightBold>
                    Сумма автоплатежа может меняться
                  </Text>{' '}
                  в&nbsp;зависимости от&nbsp;состава подключённых услуг. Расчёт
                  производится автоматически перед каждой оплатой. Сумма
                  рассчитывается как среднее значение списаний
                  за&nbsp;30&nbsp;дней.
                </Text>
              )}

              <Text lineHeight="24px">
                Чтобы изменить настройки автоплатежа, отключите его
                и&nbsp;подключите заново.
              </Text>
            </div>
          )}

          <div className="AutoPaymentWizard__input-wrapper">
            <Text
              className="AutoPaymentWizard__input-text"
              color={defaultTheme.colors.shadow}
            >
              {viewType === VIEW_TYPES.UNBIND
                ? `Автоплатёж подключён к ${getBindedCard() ? 'карте' : ''} ${
                    getBindedSbpAccount() ? 'счёту' : ''
                  }`
                : 'Привязать к карте или счёту СБП'}
            </Text>
            {viewType === VIEW_TYPES.UNBIND ? (
              <>
                {getBindedCard() && (
                  <div className="AutoPaymentWizard__binded-account">
                    {chooseIconForSelect(getBindedCard().paymentSystem)}
                    <Text lineHeight="24px" fontWeightBold>
                      •••• •••• •••• {getBindedCard().maskedPan.slice(-4)}
                    </Text>
                  </div>
                )}
                {getBindedSbpAccount() && (
                  <div className="AutoPaymentWizard__binded-account">
                    {getBindedSbpAccount()?.logo ? (
                      <div className="logo">
                        <Image
                          src={`data:image/png;base64, ${
                            getBindedSbpAccount().logo
                          }`}
                          alt="logo"
                          width={getBindedSbpAccount().width}
                          height={getBindedSbpAccount().height}
                          quality={100}
                        />
                      </div>
                    ) : (
                      <Icons.SbpIcon />
                    )}
                    <Text lineHeight="24px" fontWeightBold>
                      {getBindedSbpAccount().bankName}
                    </Text>
                  </div>
                )}
              </>
            ) : (
              <CardAndSbpPaymentSelect
                paymentSelect={card}
                setPaymentSelect={setCard}
                setError={setErrorMessage}
              />
            )}
          </div>

          {errorMessage && (
            <div className="AutoPaymentWizard__error">
              <Text color={defaultTheme.colors.planeta}>{errorMessage}</Text>
            </div>
          )}
        </div>
      </>
    );
  };

  /** Результат подключения или отключения автоплатежа */
  const resultWizardView = (): JSX.Element => {
    return (
      <>
        {(viewType === VIEW_TYPES.UNBIND_SUCCESS ||
          viewType === VIEW_TYPES.BIND_SUCCESS) && (
          <Icon icon={<Icons.OkBigIcon />} />
        )}
        {viewType === VIEW_TYPES.UNBIND_SUCCESS && (
          <>
            {isDesktop940 ? (
              <H2 className="AutoPaymentWizard__binded-header">
                Вы успешно отключили Автоплатёж
              </H2>
            ) : (
              <H3 className="AutoPaymentWizard__binded-header">
                Вы успешно отключили Автоплатёж
              </H3>
            )}
          </>
        )}

        {viewType === VIEW_TYPES.BIND_SUCCESS && autoPaymentData && (
          <>
            {isDesktop940 ? (
              <H2 className="AutoPaymentWizard__binded-header">
                Вы успешно подключили Автоплатёж
              </H2>
            ) : (
              <H3 className="AutoPaymentWizard__binded-header">
                Вы успешно подключили Автоплатёж
              </H3>
            )}
            <div className="AutoPaymentWizard__price-tag">
              {autoPaymentData?.payment !== null && (
                <PriceTag
                  header="Сумма автоплатежа"
                  value={`${autoPaymentData.payment} ₽`}
                  subscription={`Оплата произойдёт, когда баланс${'\u000A'}лицевого счёта будет меньше${'\u00A0'}100${'\u00A0'}₽`}
                  headerType={isDesktop940 ? 'H2' : 'H3'}
                />
              )}
            </div>
            <div className="AutoPaymentWizard__warning-block">
              {!isOtherAmount && (
                <Text lineHeight="24px">
                  <Text lineHeight="24px" fontWeightBold>
                    Сумма автоплатежа может меняться
                  </Text>{' '}
                  в&nbsp;зависимости от&nbsp;состава подключённых услуг. Расчёт
                  производится автоматически перед каждой оплатой. Сумма
                  рассчитывается как среднее значение списаний
                  за&nbsp;30&nbsp;дней.
                </Text>
              )}
              <Text lineHeight="24px">
                Чтобы изменить настройки автоплатежа, отключите его
                и&nbsp;подключите заново.
              </Text>
            </div>
            {getBindedCard() && (
              <>
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Автоплатёж подключён к карте
                </Text>
                <div className="AutoPaymentWizard__binded-account">
                  {chooseIconForSelect(getBindedCard().paymentSystem)}
                  <Text lineHeight="24px" fontWeightBold>
                    •••• •••• •••• {getBindedCard().maskedPan.slice(-4)}
                  </Text>
                </div>
              </>
            )}
            {getBindedSbpAccount() && (
              <>
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Автоплатёж подключён к счёту
                </Text>
                <div className="AutoPaymentWizard__binded-account">
                  {getBindedSbpAccount()?.logo ? (
                    <div className="logo">
                      <Image
                        src={`data:image/png;base64, ${
                          getBindedSbpAccount().logo
                        }`}
                        alt="logo"
                        width={getBindedSbpAccount().width}
                        height={getBindedSbpAccount().height}
                        quality={100}
                      />
                    </div>
                  ) : (
                    <Icons.SbpIcon />
                  )}
                  <Text lineHeight="24px" fontWeightBold>
                    {getBindedSbpAccount().bankName}
                  </Text>
                </div>
              </>
            )}
          </>
        )}

        {viewType === VIEW_TYPES.BIND_FAILED && (
          <>
            <Icon icon={<Icons.NotOkBigIcon />} />
            {isDesktop940 ? (
              <H2 className="AutoPaymentWizard__binded-header">
                Автоплатеж не подключён
              </H2>
            ) : (
              <H3 className="AutoPaymentWizard__binded-header">
                Автоплатеж не подключён
              </H3>
            )}
          </>
        )}

        {viewType === VIEW_TYPES.UNBIND_FAILED && (
          <>
            <Icon icon={<Icons.NotOkBigIcon />} />
            {isDesktop940 ? (
              <H2 className="AutoPaymentWizard__binded-header">
                Автоплатёж не отключён
              </H2>
            ) : (
              <H3 className="AutoPaymentWizard__binded-header">
                Автоплатёж не отключён
              </H3>
            )}
          </>
        )}

        {viewType === VIEW_TYPES.LINKING_ACCOUNT_FAILED && (
          <>
            <Icon icon={<Icons.NotOkBigIcon />} />
            {isDesktop940 ? (
              <H2 className="AutoPaymentWizard__binded-header">
                Автоплатеж не подключён
              </H2>
            ) : (
              <H3 className="AutoPaymentWizard__binded-header">
                Автоплатеж не подключён
              </H3>
            )}
            <LeadingText
              className="AutoPaymentWizard__sbp-failed"
              color={defaultTheme.colors.black}
            >
              Не получилось привязать счёт СБП?
            </LeadingText>
            <LeadingText color={defaultTheme.colors.black}>
              Попробуйте ещё раз
            </LeadingText>
            {warningBlock()}
          </>
        )}

        {viewType === VIEW_TYPES.LINKING_CARD_FAILED && (
          <>
            <Icon icon={<Icons.NotOkBigIcon />} />
            {isDesktop940 ? (
              <H2 className="AutoPaymentWizard__binded-header">
                Автоплатеж не подключён
              </H2>
            ) : (
              <H3 className="AutoPaymentWizard__binded-header">
                Автоплатеж не подключён
              </H3>
            )}
            <LeadingText
              className="AutoPaymentWizard__sbp-failed"
              color={defaultTheme.colors.black}
            >
              Не получилось привязать карту?
              <br />
              Попробуйте ещё раз
            </LeadingText>
          </>
        )}
      </>
    );
  };

  return (
    <StyledAutoPaymentWizard>
      {[VIEW_TYPES.UNBIND, VIEW_TYPES.BIND].includes(viewType) &&
        mainWizardView()}
      {[
        VIEW_TYPES.BIND_SUCCESS,
        VIEW_TYPES.UNBIND_SUCCESS,
        VIEW_TYPES.UNBIND_FAILED,
        VIEW_TYPES.LINKING_ACCOUNT_FAILED,
        VIEW_TYPES.LINKING_CARD_FAILED,
        VIEW_TYPES.BIND_FAILED,
      ].includes(viewType) && resultWizardView()}
      {viewType === VIEW_TYPES.LINKING_ACCOUNT && linkedSpbView()}
      <Portal>
        <ListOfSbpBanks />
      </Portal>
    </StyledAutoPaymentWizard>
  );
};

export default observer(AutoPaymentWizard);
