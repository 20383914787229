/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { SoftlineType } from './types';

export const StyledSoftline = styled.div<{ type: SoftlineType }>`
  ${({ type }) => {
    return `
  .softline {
    &__title-desktop {
      margin: 16px 0 32px 32px;
    }
    &__title-wrap {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      H3 {
        margin: 16px 16px 16px 0;
      }
    }

    &__loader {
      display: flex;
      justify-content: center;
      align-items: ${type === SoftlineType.SHORT ? 'center' : 'inherit'};
      width: 100%;
      height: ${type === SoftlineType.SHORT ? '432px' : '100vh'};
      position: ${type === SoftlineType.SHORT ? 'initial' : 'fixed'};
      right: 0;
      background-color: ${defaultTheme.colors.light};
    }
  }

  .button-wrapper {
    padding: 0 32px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
    }
  }
`;
  }}
`;

export const StyledSoftlineHeader = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => {
    if (isVisible) {
      return `
      margin-bottom: 32px;
      padding: 32px 0;
      background: ${defaultTheme.colors.white};

      .softline {
        &__header {
          position: relative;
          max-height: 550px;
          overflow-y: auto;

          H3 {
            margin: 0 16px 0 0 !important;
          }
        }
      }
      `;
    }
    return '';
  }}
`;

export const StyledSoftlineTags = styled.div`
  margin: 32px 0;
  padding: 0 32px;

  .softline {
    &__tag {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 32px;
    padding: 0;

    .softline__tag {
      margin-bottom: 32px;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
`;

export const StyledSoftLineList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: left 0.5s;
  flex-wrap: wrap;

  li {
    margin-right: 0;
    margin-bottom: 24px;
  }

  li:nth-of-type(3n + 3) {
    margin-right: 0;
    margin-bottom: 24px;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    li {
      margin-bottom: 32px;
    }
  }
`;

export const StyledSnoska = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
