/* Библиотеки */
import style from '@emotion/styled';
/* Типы */
import { defaultTheme } from 'cordis-core-ui-planeta';
import { CallToActionButtonStyleProps } from './CallToAction.types';

/** константы */
import { desktop400 } from '~/components/Grid/constants';

export const CallToActionContainer = style.div`
  ${() => `
      display: flex;
      flex-direction: column;
      background: ${defaultTheme.colors.white};
      width: 100%;
      > div {
        padding: 0 32px 0px 32px;
        &:first-of-type > h2 {
          margin: 32px 0;
        }
      }

      h2 {
        font-size: 34px;
        line-height: 36px;
      }

      @media (min-width: ${desktop400}px) {
        h2 {
          font-size: 44px;
          line-height: 45px;
        }
      }
    `}
`;

export const CallToActionConnectionWizard = style.div`
  ${() => `
      padding: 0 32px 0px 32px;
      background: ${defaultTheme.colors.light};
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        margin-top: 30px;
      }
    `}
`;

export const CallToActionConnectionButtonSticky = style.div<CallToActionButtonStyleProps>`
  ${({
    amountScroll,
    clientWidth,
    showSettings,
  }: CallToActionButtonStyleProps) => {
    // Значение прокрутки, начиная с которого следует показывать кнопку
    const scrollFrom = showSettings ? 0.8 : 1.8;
    // Значение прокрутки, начиная с которого следует скрывать кнопку
    const scrollTo = showSettings ? 4.3 : 3;
    return `
      width: ${clientWidth}px;
      position: fixed;
      bottom: 0;
      display: ${
        amountScroll > scrollFrom && amountScroll <= scrollTo ? 'block' : 'none'
      };
      padding: 13px 0 13px 0;
      button {
        width: 100%;
      }

      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: none;
      }
    `;
  }}
`;

export const CallToActionConnectionButton = style.div`
  ${() => `
      width: 100%;
      button {
        width: 100%;
      }
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        width: 50%;
        button {
          width: auto;
        }
        margin: 0 0 32px 0;
      }
    `}
`;
