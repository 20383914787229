/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import { desktop500, desktop940 } from '~/components/Grid/constants';

/** styles */
import { StyledOptionBlock } from '../../common/OptionBlock/styles';

export const ChannelConnectionWizardStyled = styled.div`
  .channel-connection {
    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      &__toggle-tabs {
        > div {
          > div:nth-of-type(2) {
            width: auto;
            .colorContainer {
              width: 100%;
            }
          }
        }
      }

      ${StyledOptionBlock} {
        padding: 0;
      }
    }

    &__warning {
      background-color: ${defaultTheme.colors.backgroundWarning};
      max-width: 720px;
      padding: 16px;
      margin-bottom: 32px;

      > span:nth-of-type(1) {
        margin-bottom: 8px;
      }
    }

    &__agreement {
      > span:nth-of-type(1) {
        margin-bottom: 16px;
      }
    }

    &__shutdown-time {
      margin-bottom: 32px;
    }
  }
  > button {
    width: 100%;
    margin-top: 32px;
  }

  @media (min-width: ${desktop500}px) {
    .channel-connection {
      &__wrapper {
        flex-direction: row;

        ${StyledOptionBlock} {
          padding-left: 60px;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    > button {
      width: auto;
    }
  }
`;
