import { types } from 'mobx-state-tree';
import { SummaryFieldsModel } from '~/stores/models/ConnectionTariffModel';

const ChannelGeneralPropsModel = types.model({
  id: types.number,
  name: types.string,
});
const ChannelPriceModel = types.model({
  name: types.string,
  price: types.maybeNull(types.number),
});

const ChannelFromSubjectsModel = types.compose(
  ChannelGeneralPropsModel,
  types.model({
    weburgId: types.maybe(types.number),
    imgLink: types.maybe(types.string),
    description: types.maybe(types.string),
    channelDefinition: types.maybe(types.string),
    includedToProduct: types.maybeNull(types.maybe(types.boolean)),
    availablePackets: types.array(ChannelPriceModel),
    availablePlatforms: types.array(types.string),
    adultContent: types.maybe(types.boolean),
    audioStreams: types.array(types.string),
  }),
);

const SubjectWithChannelsModel = types.model({
  name: types.string,
  position: types.maybe(types.number),
  channels: types.array(ChannelFromSubjectsModel),
});
const ChannelPackageGeneralPropsModel = types.model({
  channelPackName: types.maybe(types.string),
  isChannelWrapper: types.maybe(types.boolean),
  isFree: types.maybe(types.boolean),
  isOrdered: types.maybe(types.boolean),
  isSale: types.maybe(types.boolean),
  isTrial: types.maybe(types.boolean),
  banTrimDate: types.maybeNull(types.string),
  unbindDt: types.maybeNull(types.string),
  price: types.maybeNull(types.maybe(types.number)),
  isTimeshiftIncluded: types.maybe(types.boolean),
});

const ChannelOrderListPropsModel = types.compose(
  ChannelPackageGeneralPropsModel,
  types.model({
    channelPackId: types.maybe(types.number),
    channelIds: types.maybe(types.array(types.number)),
  }),
);

const SingleChannelPacksPropsModel = types.compose(
  ChannelOrderListPropsModel,
  types.model({
    id: types.number,
  }),
);

const ChannelFromPacketsModel = types.compose(
  ChannelGeneralPropsModel,
  types.model({
    idRis: types.number,
    singleChannelPacks: types.maybeNull(
      types.array(SingleChannelPacksPropsModel),
    ),
    isAccessible: types.boolean,
    timeshiftDepth: types.maybeNull(types.number),
    itvChannelLink: types.string,
  }),
);

const ChannelModel = types.compose(
  ChannelFromSubjectsModel,
  ChannelFromPacketsModel,
  SingleChannelPacksPropsModel,
);

const ChannelPackListPropsModel = types.compose(
  ChannelPackageGeneralPropsModel,
  types.model({
    name: types.maybe(types.string),
    id: types.maybe(types.number),
    channelPackChannels: types.maybe(types.array(ChannelModel)),
  }),
);

const ChannelPackageModel = types.compose(
  ChannelPackListPropsModel,
  ChannelOrderListPropsModel,
);

export const MyTVFieldsModel = types.model('MyTVFields', {
  seriesCode: types.maybeNull(types.string),
  baseSlug: types.maybe(types.string),
  transformerSlug: types.maybe(types.string),
  channelsByGroups: types.array(SubjectWithChannelsModel),
  packages: types.array(ChannelPackageModel),
  summary: types.maybeNull(SummaryFieldsModel),
  transformationLimit: types.maybeNull(types.number),
});
