/** libraries */
import { FC } from 'react';
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import AuthWizard from '~/components/AuthWizard/AuthWizard';
import AuthWizardConfirm from '~/components/AuthWizard/AuthWizardConfirm';
import BlockedEntrance from '~/components/AuthWizard/BlockedEntrance/BlockedEntrance';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const AuthSP: FC = (): JSX.Element => {
  const {
    isVisible: isAuthOpen,
    serverExtensionsError,
    onCloseClickSidePageAuth,
  } = useMakeAuthStore();
  /** Заблокирован ли вход */
  const isBlockedPage = !!serverExtensionsError?.BlockMinutes;

  return (
    <SidePage
      show={isAuthOpen}
      headerText={`Вход в${'\u00A0'}раздел «Моя Планета»`}
      onCloseClick={onCloseClickSidePageAuth}
      removeScrollBar={false}
      footerContainer={!isBlockedPage && <AuthWizardConfirm />}
      isOnlyMobileFooter
    >
      {isBlockedPage ? (
        <BlockedEntrance
          contractName={serverExtensionsError.ContractName}
          blockMinutes={serverExtensionsError.BlockMinutes}
        />
      ) : (
        <AuthWizard />
      )}
    </SidePage>
  );
};

export default observer(AuthSP);
