/* Ссылка на изображение по умолчанию для оборудования */
export const DEFAULT_IMAGE = '/static/images/devices/default-image.jpg';

/* Значения тегов оборудования */
export enum TAG_DEVICE {
  GIGABIT = 'ГИГАБИТ',
  GPON = 'GPON',
  WIFI = 'WI-FI',
  NEW = 'НОВИНКА',
}

/* Фиксированная ширина блока с карточкой устройства */
export const FIXED_CARD_WIDTH = {
  X2: {
    DESKTOP: 250,
    MOBILE: 315,
  },
  X3: {
    large: 400,
    medium: 320,
    small: 280,
  },
};

/** Размер дефолтного изображения устройства */
export const DEFAULT_IMAGE_SIZE = {
  height: 36,
  width: 67,
};
