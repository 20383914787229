import { createContext, useContext } from 'react';
import ListOfSbpBanksModel, {
  IListOfSbpBanksStore,
} from './ListOfSbpBanksModel';
import { defaultModelState } from '~/stores/models/createApiPathModel';

const store = ListOfSbpBanksModel.create({
  requestsState: {
    banksListImageConverter: defaultModelState,
  },
  isListOfSbpBanksOpen: false,
  convertedSbpBanksList: [],
  searchValue: '',
});

const ListOfSbpBanksStoreContext = createContext<IListOfSbpBanksStore>(store);

const useListOfSbpBanksStore = () => {
  return useContext(ListOfSbpBanksStoreContext);
};

export default useListOfSbpBanksStore;
