/** libraries */
import { defaultTheme } from 'cordis-core-ui-planeta';
import { css } from '@emotion/react';
/** components */
import Kur from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Kur';
import Ekb from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Ekb';
import Uhd from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Uhd';
import Planeta from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Planeta';
import Itv from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Itv';
import Ntg from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Ntg';
import Hit from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Hit';
import Gigabit from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Gigabit';
import Lead from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/Slides/Lead';

/** constants */
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export enum MAIN_SIDE {
  KUR,
  EKB,
  PLANETA,
  ITV,
  UHD,
  NTG,
  HIT,
  GIGABIT,
  LEAD,
}

export default [
  // KUR
  {
    header: 'Привет, Каменск!',
    mainText:
      'В&nbsp;2013 году Планета пришла на&nbsp;юг&nbsp;Свердловской области. Её&nbsp;технологичные и&nbsp;удобные сервисы стали доступны жителям Каменска-Уральского.',
    mainTextMobile:
      'В&nbsp;2013 году Планета пришла на&nbsp;юг&nbsp;Свердловской области. Её&nbsp;технологичные и&nbsp;удобные сервисы стали доступны жителям Каменска-Уральского.',
    background: 'static/images/20years/kur.png',
    backgroundMobile: 'static/images/20years/kur.png',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.black,
    linearGradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 56.05%, rgba(33, 33, 33, 0.69) 87.54%),',
    component: Kur,
    // Однолетков: "скорее всего, мы потом сделаем ещё одну версию блока, чтобы она была широкая,
    // но настраиваемая через mongo, а эти останутся тут навсегда"
    css: css`
      > div:nth-of-type(2) {
        > span {
          font-size: 21px;
          line-height: 25px;
        }
      }
      @media (min-width: ${desktop940}px) {
        padding: 0 195px;
      }
    `,
  },
  // EKB
  {
    header: 'Я ❤ ЕКБ',
    mainText:
      'В&nbsp;2019 году офис Планеты в&nbsp;Екатеринбурге переехал. Сейчас большинство вопросов можно решить удалённо, но&nbsp;мы&nbsp;всегда рады видеть вас в&nbsp;современном и&nbsp;уютном офисе по&nbsp;адресу Бажова,&nbsp;79.',
    mainTextMobile:
      'В&nbsp;2019 году офис Планеты в&nbsp;Екатеринбурге переехал. Сейчас большинство вопросов можно решить удалённо, но&nbsp;мы&nbsp;всегда рады видеть вас в&nbsp;современном и&nbsp;уютном офисе по&nbsp;адресу Бажова,&nbsp;79.',
    background: 'static/images/20years/ekb.png',
    backgroundMobile: 'static/images/20years/ekb_mobile2.png',
    dateColor: defaultTheme.colors.black,
    mobileBallColor: defaultTheme.colors.abbey,
    linearGradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 91.05%, rgba(33, 33, 33, 0.69) 132.54%),',
    component: Ekb,
    css: css`
      &::after {
        background-position-y: 37%;
      }

      > div:nth-of-type(2) {
        > span {
          font-size: 16px;
          line-height: 24px;
        }
      }

      @media (min-width: ${desktop940}px) {
        padding: 0 200px;

        div:nth-of-type(1) {
          span {
            color: ${defaultTheme.colors.black};
          }
        }

        div:nth-of-type(2) {
          position: relative;
          top: 220px;

          > span {
            font-size: 21px;
            line-height: 25px;
          }
        }

        &::after {
          background-position-y: top;
        }
      }
    `,
  },
  // PLANETA
  {
    header: 'www.planeta.tc',
    mainText:
      'Планета обретает свой уникальный фирменный стиль и&nbsp;новый сайт',
    mainTextMobile:
      'Планета обретает свой уникальный фирменный стиль и&nbsp;новый сайт',
    mainImage: 'static/images/20years/planeta_image.png',
    background: 'static/images/20years/planeta.png',
    backgroundMobile: 'static/images/20years/planeta_mobile.png',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.planeta,
    backgroundColorMobile: defaultTheme.colors.planeta,
    backgroundColor: defaultTheme.colors.planeta,
    component: Planeta,
    css: css`
      &::after {
        background-position-y: 36vw;
      }
      
      @media (min-width: ${desktop940}px) {
        &::after {
          background-position-y: 0;
          background-size: auto;
        }
        }
      }
    `,
  },
  // ITV
  {
    header: 'Собственная платформа цифрового ТВ',
    mainText:
      'В&nbsp;2007 году Планета запустила своё интерактивное телевидение с&nbsp;электронной телепрограммой и&nbsp;фильмовым фондом. На&nbsp;старте было 60&nbsp;каналов. Сегодня клиентам Планеты доступно 383&nbsp;телеканала.',
    mainTextMobile:
      'В&nbsp;2007 году Планета запустила своё интерактивное телевидение с&nbsp;электронной телепрограммой и&nbsp;фильмовым фондом. На&nbsp;старте было 60&nbsp;каналов. Сегодня абонентам доступно 383&nbsp;телеканала.',
    mainImage: 'static/images/20years/itv_image.png',
    background: 'static/images/20years/itv.png',
    backgroundMobile: 'static/images/20years/itv_mobile.png',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.mirage,
    backgroundColor: defaultTheme.colors.mirage,
    backgroundColorMobile: defaultTheme.colors.mirage,
    linearGradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 27%, rgba(33, 33, 33, 0.69) 51.54%),',
    component: Itv,
    css: css`
      &::after {
        background-position-y: 26vh;
        background-position-x: 20px;
        background-size: contain;
      }

      div:nth-of-type(2) {
        span {
          color: ${defaultTheme.colors.light};
        }
      }

      @media (min-width: ${desktop940}px) {
        padding: 0 80px;

        &::after {
          background-size: cover;
          background-position-y: 2vh;
          background-position-x: center;
        }
      }

      @media (min-width: ${desktop1100}px) {
        padding: 0 200px;
      }
    `,
  },
  // UHD
  {
    header: 'UHD',
    mainText:
      'В&nbsp;2017 году Планета запустила трансляцию телеканалов в&nbsp;формате ультравысокой чёткости.</br>Сегодня в&nbsp;Планете 11&nbsp;UHD-каналов.',
    mainTextMobile:
      'В&nbsp;2017 году Планета запустила трансляцию телеканалов в&nbsp;формате ультравысокой чёткости.</br>Сегодня в&nbsp;Планете 11&nbsp;UHD-каналов.',
    background: 'static/images/20years/uhd.png',
    backgroundMobile: 'static/images/20years/uhd_mobile.png',
    dateColor: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.totalBlack,
    mobileBallColor: defaultTheme.colors.totalBlack,
    isOnlyMobileBallColor: true,
    component: Uhd,
    css: css`
      &::after {
        background-position-y: top;
      }

      div:nth-of-type(2) {
        span {
          font-size: 16px;
          line-height: 24px;
          color: ${defaultTheme.colors.light};
        }
      }

      @media (min-width: ${desktop940}px) {
        padding: 0 270px;

        &::after {
          background-position-y: 10px;
        }

        div:nth-of-type(2) {
          span {
            font-size: 21px;
            line-height: 25px;
          }
        }
      }

      @media (min-width: ${desktop1280}px) {
        padding: 0 300px;
      }
    `,
  },
  // NTG
  {
    header: 'Таги-и-и-и-и-и-л!',
    mainText:
      'В&nbsp;2010&nbsp;году открыт первый офис в&nbsp;Нижнем Тагиле. Планета быстро завоевала популярность. Сегодня её&nbsp;услугами пользуются более 20&nbsp;000&nbsp;тагильчан.',
    mainTextMobile:
      'В&nbsp;2010&nbsp;году открыт первый офис в&nbsp;Нижнем Тагиле<p>Планета быстро завоевала популярность. Сегодня её&nbsp;услугами пользуются более 20&nbsp;000&nbsp;тагильчан.</p>',
    background: 'static/images/20years/ntg.png',
    backgroundMobile: 'static/images/20years/ntg_mobile.png',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.madison,
    linearGradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 56.05%, rgba(33, 33, 33, 0.69) 87.54%),',
    component: Ntg,
    css: css`
      div:nth-of-type(2) {
        span {
          color: ${defaultTheme.colors.light};
        }
      }
      @media (min-width: ${desktop940}px) {
        padding: 0 195px;
      }
    `,
  },
  // HIT
  {
    header: 'Планета — это ХИТ!',
    mainText:
      '<p>Появление легендарных комплексных продуктов ХИТ.</br>Первым был Хит&nbsp;699. За&nbsp;699 рублей в&nbsp;месяц в&nbsp;него входили интернет</br>на&nbsp;скорости 6&nbsp;Мбит/с, доступ к&nbsp;порталу Weburg.ru и&nbsp;75&nbsp;цифровых телеканалов.</br>Просто мечта для 2010&nbsp;года!</p><p>Сейчас знакомство с&nbsp;Планетой можно начать с&nbsp;лучшего по&nbsp;составу</br>промопродукта Хит 11&nbsp;всего за&nbsp;11&nbsp;рублей в&nbsp;день.</p>',
    mainTextMobile:
      'Появление легендарных комплексных продуктов ХИТ<p>Первым был Хит 699. Сейчас знакомство с&nbsp;Планетой можно начать с&nbsp;лучшего по&nbsp;составу промопродукта Хит 11&nbsp;всего за&nbsp;11&nbsp;₽/день.</p>',
    backgroundColorMobile: defaultTheme.colors.orangePeel,
    backgroundColor: defaultTheme.colors.orangePeel,
    mainImage: 'static/images/20years/hit.png',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.orangePeel,
    component: Hit,
    css: css``,
  },
  // GIGABIT
  {
    mainText:
      'В&nbsp;2017 году клиенты Планеты первыми в&nbsp;области получили сверхскоростной\nинтернет по&nbsp;самой современной технологии&nbsp;&mdash; &laquo;оптика до&nbsp;квартиры&raquo;.\nСегодня уже&nbsp;20% планетян пользуются гигабитным интернетом.',
    mainTextMobile:
      'В&nbsp;2017 году клиенты Планеты первыми в&nbsp;области получили сверхскоростной интернет по&nbsp;самой современной технологии&nbsp;&mdash; &laquo;оптика до&nbsp;квартиры&raquo;. Сегодня уже&nbsp;20% планетян пользуются гигабитным интернетом.',
    backgroundColor: defaultTheme.colors.planeta,
    backgroundColorMobile: defaultTheme.colors.planetaDark,
    mainImage: 'static/images/20years/gigabit.png',
    mainImageMobile: 'static/images/20years/gigabit_mobile.png',
    extraImage: 'static/images/20years/gigabit_fragments.png',
    frontImage: 'static/images/20years/gigabit_front.png',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.planetaDark,
    component: Gigabit,
    css: css`
      div:nth-of-type(2) {
        > span {
          font-size: 16px;
          line-height: 24px;
          color: ${defaultTheme.colors.light};
        }
      }

      @media (min-width: ${desktop940}px) {
        div:nth-of-type(2) {
          > span {
            font-size: 21px;
            line-height: 25px;
          }
        }
      }
    `,
  },
  // LEAD
  {
    header: 'Более 300 000 клиентов',
    headerMobile: 'Более<br>300 000 клиентов',
    mainText:
      'За&nbsp;20&nbsp;лет изменилось многое,\nно&nbsp;осталось главное&nbsp;&mdash; нас выбирают, с&nbsp;нами сравнивают!',
    mainTextMobile:
      'За&nbsp;20&nbsp;лет изменилось многое, но&nbsp;осталось главное&nbsp;&mdash; нас выбирают, с&nbsp;нами сравнивают!',
    backgroundColor: '#950043',
    backgroundColorMobile: '#950043',
    background: 'static/images/20years-logo.png',
    backgroundMobile: 'static/images/20years-logo.png',
    icon: 'static/upload-files/20years/point.svg',
    dateColor: defaultTheme.colors.white,
    mobileBallColor: defaultTheme.colors.abbey,
    component: Lead,
    css: css`
      &::after {
        background-position-y: center;
        background-position-x: 20vw;
        background-size: cover;
      }

      @media (min-width: ${desktop940}px) {
        &::after {
          background-position-y: 100px;
          background-position-x: center;
          background-size: auto;
        }
      }
    `,
  },
];
