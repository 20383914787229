/** Библиотеки */
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** Стилизованные компоненты */
import {
  LoaderWrapper,
  StyledDevices,
  StyledDevicesHeader,
} from './Pab2cStyle';

/** Компоненты  */
import { H3, Loader, Button, ButtonStyleTypes } from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';

/** Интерфейсы */
import { DevicesContentProps } from '~/components/Blocks/Templates/Pab2c/Devices/interfaces';

/** Константы */
import { desktop940 } from '~/components/Grid/constants';
import { TITLE_DEVICES_BLOCK } from '~/components/Blocks/Templates/Pab2c/Devices/constants';
import { DEVICES } from '~/constants/common';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import Main from './Main';
import { DevicesStoreProvider } from './stores/useDevicesStore';

/**
 * Блок "Моё оборудование"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=597419200
 */
const Devices: FC<DevicesContentProps> = ({ content }) => {
  const {
    authStore: { isLoadingAuth },
    pab2cDevicesStore: { isLoading },
  } = useRootStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isDataLoading = isLoading || isLoadingAuth;

  return (
    <StyledDevices>
      <StyledDevicesHeader>
        <H3>{TITLE_DEVICES_BLOCK}</H3>
        <LinkWrapper href={DEVICES} target="_blank">
          {isDesktop940 ? (
            'Заказать ещё'
          ) : (
            <Button styleType={ButtonStyleTypes.SECONDARY}>Заказать ещё</Button>
          )}
        </LinkWrapper>
      </StyledDevicesHeader>
      {isDataLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {!isDataLoading && (
        <DevicesStoreProvider fields={content.fields} size={content.size}>
          <Main />
        </DevicesStoreProvider>
      )}
    </StyledDevices>
  );
};

export default observer(Devices);
