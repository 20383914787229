/** libraries */
import { FC, useMemo } from 'react';
import { Text } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** interfaces */
import {
  NewContractProps,
  NEW_CONTRACT_STATUS,
} from '../../../ContractState/types';
/** styles */
import { StyledNewContractMessage } from './styles';
/** utils */
import { getDate } from '~/components/Blocks/Shared/Shared.utils';
import { getHeaderPhone } from '~/components/Header/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface NewContractMessageProps {
  newContractInfo: NewContractProps;
}

const NewContractMessage: FC<NewContractMessageProps> = ({
  newContractInfo,
}: NewContractMessageProps) => {
  const {
    cityStore: { city, contactPhones },
  } = useRootStore();

  // Номер телефона
  const phone = useMemo(() => {
    return getHeaderPhone(contactPhones, city);
  }, [contactPhones, city]);
  return (
    <StyledNewContractMessage>
      {newContractInfo?.status === NEW_CONTRACT_STATUS.MountPlanning && (
        <>
          <Text lineHeight="24px">
            В&nbsp;течение{' '}
            <Text lineHeight="24px" fontWeightBold>
              7&nbsp;дней
            </Text>{' '}
            после поступления платежа мы&nbsp;свяжемся с&nbsp;вами, чтобы
            согласовать время подключения и&nbsp;презентации сервисов Планеты.
            В&nbsp;периоды высокой нагрузки срок ожидания звонка может быть
            увеличен.
          </Text>
          <Text lineHeight="24px">
            По вопросам подключения обращайтесь по телефону{' '}
            <Text lineHeight="24px" fontWeightBold>
              {phone}
            </Text>
            .
          </Text>
        </>
      )}
      {newContractInfo?.status === NEW_CONTRACT_STATUS.Connecting &&
        newContractInfo?.connectingDate && (
          <>
            <Text lineHeight="24px">
              Ваше подключение запланировано на{' '}
              <Text lineHeight="24px" fontWeightBold>
                {getDate(newContractInfo?.connectingDate, 'd MMMM')}
              </Text>
              .
            </Text>
            <Text lineHeight="24px">
              Если вы не можете присутствовать в указанное время — обращайтесь
              по телефону{' '}
              <Text lineHeight="24px" fontWeightBold>
                {phone}
              </Text>
              .
            </Text>
          </>
        )}
      {newContractInfo?.status === NEW_CONTRACT_STATUS.Unknown && (
        <Text lineHeight="24px">
          За дополнительной информацией обращайтесь по телефону{' '}
          <Text lineHeight="24px" fontWeightBold>
            {phone}
          </Text>
          .
        </Text>
      )}
    </StyledNewContractMessage>
  );
};

export default observer(NewContractMessage);
