import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledProductChangeHistory = styled.div`
  border-top: 1px solid ${defaultTheme.colors.disable};
  padding: 24px 4px 0;

  .history {
    &__table {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span {
        width: auto;
        margin: 0 0 8px 0;
      }

      > span:last-of-type {
        margin-right: 0;
      }

      &__header {
        > span {
          margin-bottom: 16px !important;
        }
      }

      &__name-block {
        display: inline-table;
      }
    }

    &__table:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${desktop940}px) {
    .history {
      &__table {
        display: block;
        margin-bottom: 0;
        > span {
          width: 248px;
          margin: 0 16px 16px 0;
        }

        > span:last-of-type {
          margin-right: 0;
        }
      }
      &__table:last-of-type {
        > span {
          margin-bottom: 0;
        }
      }
    }
  }
`;
