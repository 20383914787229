import { FC } from 'react';
import { ColorsType, Text } from 'cordis-core-ui-planeta';
import { StyledTd } from './styles';

interface TdProps {
  children: React.ReactNode;
  verticalAlign?: string;
  textAlign?: string;
  onClick?: () => void;
  color?: ColorsType;
  className?: string;
}

const Td: FC<TdProps> = ({
  children,
  verticalAlign,
  textAlign,
  onClick,
  color,
  className,
}: TdProps) => {
  return (
    <StyledTd
      className={className}
      verticalAlign={verticalAlign}
      textAlign={textAlign}
      onClick={onClick}
    >
      <Text lineHeight="24px" color={color}>
        {children}
      </Text>
    </StyledTd>
  );
};

export default Td;
