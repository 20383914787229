/** библиотеки */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
/** стили */
import { StyledSoftlineSubscribeWizardSuccess } from './style';
/** компоненты библиотеки */
import {
  defaultTheme,
  H2,
  Text,
  Icon,
  Icons,
  Select,
  H3,
  Button,
} from 'cordis-core-ui-planeta';
/** утилиты */
import LinkWrapper from '~/components/LinkWrapper';
/** hook */
import { useSoftline } from '../../hooks/SoftlineHook';
/** stores */
import useSoftlineStateModelStore from '../../stores/useSoftlineStateStore';

/**
 * Сайдпейдж с успешным подключением подписки
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 */
const SoftlineSubscribeWizardSuccess = (): JSX.Element => {
  const { softlineDescription, simIdFromBind } = useSoftlineStateModelStore();
  const {
    selectDistributionOS,
    setSelectDistributionOS,
    distributionList,
    linkToInstallInstructions,
    getShipping,
    login,
    password,
    activationKey,
  } = useSoftline();

  const { name } = softlineDescription;

  useEffect(() => {
    if (simIdFromBind) getShipping(simIdFromBind);
  }, [simIdFromBind]);

  return (
    <StyledSoftlineSubscribeWizardSuccess>
      <div className="subscribe-wizard-success__icon">
        <Icon icon={<Icons.SuccessIconAnimated />} />
      </div>
      <H2 dangerouslySetInnerHTML={{ __html: `Подписка ${name} подключена` }} />
      <div className="subscribe-wizard-success__distribution-information-block">
        {linkToInstallInstructions && (
          <div className="subscribe-wizard-success__distribution-information-block__link-to-instructions">
            <LinkWrapper href={linkToInstallInstructions}>
              Инструкция по установке
            </LinkWrapper>
          </div>
        )}
        {login && (
          <div className="subscribe-wizard-success__distribution-information-block__info-wrapper">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Логин
            </Text>
            <H3>{login}</H3>
          </div>
        )}
        {password && (
          <div className="subscribe-wizard-success__distribution-information-block__info-wrapper">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Пароль
            </Text>
            <H3>{password}</H3>
          </div>
        )}
        {activationKey && (
          <div className="subscribe-wizard-success__distribution-information-block__info-wrapper">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Ключ активации
            </Text>
            <H3>{activationKey}</H3>
          </div>
        )}
        {distributionList && (
          <div className="subscribe-wizard-success__distribution-information-block__os-wrapper">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              ОС дистрибутива
            </Text>
            <div className="subscribe-wizard-success__distribution-information-block__os-wrapper__upload">
              <Select
                onOptionClick={(option) => setSelectDistributionOS(option)}
                visibleOptionCount={4}
                value={selectDistributionOS ? selectDistributionOS.value : ''}
                data={distributionList}
                disabled={distributionList.length === 1}
              />
              <div className="subscribe-wizard-success__distribution-information-block__os-wrapper__upload__button">
                <LinkWrapper href={selectDistributionOS.value}>
                  <Button>Скачать</Button>
                </LinkWrapper>
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledSoftlineSubscribeWizardSuccess>
  );
};

export default observer(SoftlineSubscribeWizardSuccess);
