/* eslint-disable camelcase */
/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icon,
  Icons,
  SidePage,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** interfaces */
import {
  ChannelPackage,
  ConnectionStates,
  WinkConnectionStatus,
} from '../../../interfaces';
import { ResultProps } from '../../common/SidePageResult/types';

/** styles */
import { StyledChannelDescriptionCard } from './styles';

/** constants */
import {
  CardStates,
  CHANNEL_DESCRIPTION_ID,
  STORE_TYPE,
  TEXT,
  TRANSFORMER_TEXT,
} from '../../../constants';
import {
  desktop500,
  desktop700,
  desktop940,
} from '~/components/Grid/constants';
import { INITIAL_RESULT } from '../../common/SidePageResult/constants';

/** components */
import Row from '../../common/RowContent';
import ChannelPackageCard from '../ChannelPackageCard/ChannelPackageCard';
import ChannelCard from '../ChannelCard/ChannelCard';
import ToggleTabs from '../../common/ToggleTabs';
import ChannelConnectionWizard from '../../SidePages/ChannelConnectionWizard/ChannelConnectionWizard';
import Platforms from '../../Platforms/Platforms';
import ChannelProperties from '../../ChannelProperties/ChannelProperties';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** utils */
import {
  parseHtml,
  scrollToBlockById,
} from '~/components/Blocks/Shared/Shared.utils';
import Portal from '~/components/Portal/Portal';
import { getDate, getStore } from '../../../utils';
import LinkWrapper from '~/components/LinkWrapper';
import { formatNumber } from '~/utils/utils';

/** api */
import {
  checkITvChannelPackBindCondition,
  checkITvChannelPackUnbindCondition,
} from '~/api/apiPab2c';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';

/** Карточка описания канала */
const ChannelDescriptionCard: FC = () => {
  const {
    summaryDataStore: { transformationLimit },
    tvStore: {
      connectedChannelsInPackages,
      connectedChannelsOutsidePackages,
      connectedTVPackages,
      transformerInfo,
      winkInfo,
      editOrderSum,
      setEditOrderSum,
      idsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
      setIdsAddedChannelsToTransformer,
      channels,
      tvChannelPackages,
    },
  } = useRootStore();
  const { storeType } = useMyTvStore();

  const {
    openCardBlockId,
    setCardState,
    setActivePackageCard,
    onClickPackageCard,
    activeChannelCard: channel,
    setIsEditTransformer,
  } = getStore(storeType);

  const isLightbox = storeType === STORE_TYPE.MY_TV_X2;

  const { accountStatus } = winkInfo;
  const {
    id,
    weburgId,
    imgLink,
    name,
    description,
    availablePackets,
    channelDefinition,
    audioStreams,
    adultContent,
    singleChannelPacks,
    price,
    isFree,
    unbindDt,
    banTrimDate,
    isChannelWrapper,
    channelPackId,
    itvChannelLink,
    timeshiftDepth,
  } = channel;

  /** Цена одиночного канала */
  const singleChannelPrice =
    singleChannelPacks?.length > 0 ? singleChannelPacks[0].price : null;

  /** Категория канала */
  const category = useMemo(
    () =>
      channels.find((item) =>
        item.channels.find((canal) =>
          canal?.weburgId ? canal.weburgId === weburgId : canal?.id === id,
        ),
      )?.name,
    [channels],
  );

  /** Отключение доступно сразу */
  const isRejectionAvailable = useMemo(
    () => isBefore(new Date(parseISO(banTrimDate)), new Date()),
    [banTrimDate],
  );

  /** Дата, после которой возможен отказ от канала */
  const refusalDate = (connectedPackageDate?: string) => {
    if (!banTrimDate && !connectedPackageDate) return null;
    if (transformerInfo) {
      return `${getDate(connectedPackageDate ?? banTrimDate)} года`;
    }

    if (!isRejectionAvailable) return getDate(banTrimDate, 'dd.MM.yyyy');
    return '';
  };

  /** Пакеты, в которых подключён канал */
  const [connectedPackages, setConnectedPackages] = useState<ChannelPackage[]>(
    [],
  );

  /** Добавленные в трансформер пакеты, в составе которых есть этот канал */
  const [addedToTransformerPackages, setAddedToTransformerPackages] = useState<
    ChannelPackage[]
  >([]);

  /** Убранные в трансформер пакеты, в составе которых есть этот канал */
  const [removedPackages, setRemovedPackages] = useState<ChannelPackage[]>([]);

  /** Дата отключения канала */
  const [shutdownDate, setShutdownDate] = useState<string>('');

  /** Тип подключения */
  const connection = useMemo(() => {
    /** Подключён отдельно от пакета */
    const isConnectedSeparately = connectedChannelsOutsidePackages.includes(
      weburgId,
    );
    /** Подключён в продукте */
    if (isFree && isConnectedSeparately)
      return ConnectionStates.ConnectedInTheProduct;

    /** Канал подключён в пакете */
    const connectedChannelId = connectedChannelsInPackages.find(
      (item) => item === weburgId,
    );
    /** Пакеты, в которых подключён канал */
    const connectedPackets = connectedChannelId
      ? connectedTVPackages.filter((item) => {
          return item.channelIds.includes(connectedChannelId);
        })
      : [];

    /** Подключённые пакеты в трансформере (не убранные и не с отказом) */
    const connectedPacketsInTransformer = connectedPackets.filter((item) => {
      return (
        idsAddedPacketsToTransformer?.includes(item.channelPackId) &&
        !item.unbindDt
      );
    });
    /** Все подключённые пакеты с отказом */
    const isAllRefusal = connectedPackets.length
      ? connectedPackets.every((item) => item.unbindDt)
      : false;

    /** Подключён в пакетах */
    if (
      ((connectedPacketsInTransformer.length && transformerInfo) ||
        (!transformerInfo && connectedPackets.length)) &&
      !isAllRefusal
    ) {
      const packets = transformerInfo
        ? connectedPacketsInTransformer
        : connectedPackets.filter((item) => !item.unbindDt);
      if (packets.find((item) => item.isFree))
        return ConnectionStates.ConnectedInTheProduct;
      setConnectedPackages((packets as unknown) as ChannelPackage[]);
      return ConnectionStates.ConnectedInAPackage;
    }

    /** Добавленные в трансформер пакеты (без подключённых) */
    const addedPackages = idsAddedPacketsToTransformer?.length
      ? tvChannelPackages.filter((item) =>
          idsAddedPacketsToTransformer.includes(item.id),
        )
      : [];

    /** Добавлен в трансформер в пакетах */
    if (transformerInfo && addedPackages.length) {
      /** Добавленные в трансформер пакеты, в которых есть этот канал */
      const packages = addedPackages.filter((item) =>
        item.channelPackChannels.find((canal) =>
          canal?.weburgId ? canal.weburgId === weburgId : canal?.id === id,
        ),
      );
      if (packages.length) {
        setAddedToTransformerPackages(
          (packages as unknown) as ChannelPackage[],
        );
        return ConnectionStates.AddedToTransformerInPackage;
      }
    }

    /** Канал добавлен в трансформер */
    const channelAddedToTransformer = idsAddedChannelsToTransformer.find(
      (item) =>
        singleChannelPacks?.length ? item === singleChannelPacks[0].id : null,
    );

    /** Добавлен в трансформер */
    if (
      transformerInfo &&
      !isConnectedSeparately &&
      channelAddedToTransformer
    ) {
      return ConnectionStates.AddedToTransformer;
    }

    /** Из трансформера убраны пакеты, в которых находился канал */
    if (
      transformerInfo &&
      connectedPackets.length &&
      !connectedPackets.every((item) =>
        idsAddedPacketsToTransformer.includes(item.channelPackId),
      )
    ) {
      setRemovedPackages(
        (connectedPackets.filter(
          (item) => !item.unbindDt,
        ) as unknown) as ChannelPackage[],
      );
      return ConnectionStates.RemovedFromTheTransformerInPackage;
    }

    /** Все пакеты с отказом */
    if (isAllRefusal) {
      setConnectedPackages((connectedPackets as unknown) as ChannelPackage[]);
      return ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced;
    }

    /** Убран из трансформера */
    if (
      transformerInfo &&
      isConnectedSeparately &&
      !channelAddedToTransformer
    ) {
      return ConnectionStates.RemovedFromTheTransformer;
    }

    /** Дата отключения канала */
    if (unbindDt) {
      const unbindDate = `${getDate(unbindDt)} года`;
      setShutdownDate(
        transformerInfo ? unbindDate : getDate(unbindDt, 'dd.MM.yyyy'),
      );
      return ConnectionStates.CancellationIssuedSeparatelyFromThePackage;
    }

    if (isConnectedSeparately) {
      return ConnectionStates.ConnectedSeparatelyFromThePackage;
    }

    return ConnectionStates.NotConnected;
  }, [
    transformerInfo,
    connectedChannelsInPackages,
    connectedChannelsOutsidePackages,
    idsAddedChannelsToTransformer,
    idsAddedPacketsToTransformer,
    connectedTVPackages,
    tvChannelPackages,
    channel,
  ]);

  /** Дата отключения пакетов */
  const shutdownPackageDate = useMemo(() => {
    if (
      connection ===
      ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced
    ) {
      return connectedPackages[0]?.unbindDt
        ? `${getDate(connectedPackages[0]?.unbindDt)} года`
        : '';
    }
    return '';
  }, [connection, connectedPackages]);

  /** Варианты подключения */
  const connectionOptions = useMemo(() => {
    return availablePackets?.length && tvChannelPackages?.length
      ? availablePackets
          .map((item) => {
            return tvChannelPackages.find(
              (pack) => pack.channelPackName === item.name,
            );
          })
          .filter((item) => {
            return (
              item !== undefined &&
              !connectedPackages?.find(
                (pack) => pack.channelPackName === item.channelPackName,
              ) &&
              !singleChannelPacks?.map((cp) => cp.id).includes(item.id)
            );
          })
      : [];
  }, [availablePackets, tvChannelPackages, connectedPackages]);

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop700 = useMediaQuery({
    query: `(min-width: ${desktop700}px)`,
  });
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  /** Доступно управление просмотром */
  const isViewControlAvailable = !!timeshiftDepth;

  /** Оформлен отказ */
  const isRejectionOfThePackage =
    connection ===
      ConnectionStates.CancellationIssuedSeparatelyFromThePackage ||
    connection ===
      ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced;

  /** wink */
  const isWink = accountStatus === WinkConnectionStatus.activated;

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (
        [
          ConnectionStates.AddedToTransformer,
          ConnectionStates.AddedToTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.planeta;
      if (
        [
          ConnectionStates.RemovedFromTheTransformer,
          ConnectionStates.RemovedFromTheTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.black;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (
        [
          ConnectionStates.AddedToTransformer,
          ConnectionStates.AddedToTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.pink;
      if (
        [
          ConnectionStates.RemovedFromTheTransformer,
          ConnectionStates.RemovedFromTheTransformerInPackage,
        ].includes(connection)
      )
        return defaultTheme.colors.disable;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isDesktop940) return undefined;

      /** Подключён в пакетах */
      if (connection === ConnectionStates.ConnectedInAPackage) {
        if (connectedPackages?.length === 1) {
          return `Подключён в пакете ${connectedPackages[0]?.channelPackName}`;
        }
        /** Много пакетов */
        if (connectedPackages?.length > 1) {
          return `Подключён в пакетах ${connectedPackages
            .map((item) => item.channelPackName)
            .join(', ')}`;
        }
      }

      /** Добавлен в пакетах */
      if (connection === ConnectionStates.AddedToTransformerInPackage) {
        if (addedToTransformerPackages?.length === 1) {
          return `Добавлен в пакете ${addedToTransformerPackages[0]?.channelPackName}. Будет подключён при применении трансформера`;
        }
        /** Много пакетов */
        if (addedToTransformerPackages?.length > 1) {
          return `Добавлен в пакетах ${addedToTransformerPackages
            .map((item) => item.channelPackName)
            .join(', ')}. Будет подключён при применении трансформера`;
        }
      }

      /** Отказ от пакетов */
      if (
        connection ===
        ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced
      ) {
        const stringDate = connectedPackages[0]?.unbindDt
          ? `${getDate(connectedPackages[0]?.unbindDt)} года`
          : '';
        if (connectedPackages?.length === 1) {
          return `Оформлен отказ от канала в составе пакета ${connectedPackages[0]?.channelPackName} с ${stringDate}`;
        }
        /** Много пакетов */
        if (connectedPackages?.length > 1) {
          /** Дата отключения одинаковая для все пакетов */
          const isSameDate = connectedPackages.reduce(
            (acc, item, index, arr) => {
              if (!acc) return false;
              return index !== 0
                ? arr[index - 1]?.unbindDt === item?.unbindDt
                : true;
            },
            true,
          );

          return isSameDate
            ? `Оформлен отказ от канала в составе пакетов ${connectedPackages
                .map((item) => item.channelPackName)
                .join(', ')} с ${stringDate}`
            : `Оформлен отказ от канала в составе пакетов ${connectedPackages
                .map((item) => {
                  const unbindDate = item.unbindDt
                    ? getDate(item.unbindDt)
                    : '';
                  return `${item.channelPackName} c ${unbindDate} года`;
                })
                .join(', ')}`;
        }
      }

      /** Отказ отдельно от пакета */
      if (
        connection ===
        ConnectionStates.CancellationIssuedSeparatelyFromThePackage
      )
        return `Оформлен отказ с ${shutdownDate}`;

      /** Добавлен в трансформер */
      if (
        [
          ConnectionStates.AddedToTransformerInPackage,
          ConnectionStates.AddedToTransformer,
        ].includes(connection)
      ) {
        return 'Будет подключён при применении трансформера';
      }

      /** Удалён из трансформера */
      if (connection === ConnectionStates.RemovedFromTheTransformer) {
        return isRejectionAvailable
          ? 'Будет отключён при применении трансформера'
          : `Будет оформлен отказ с ${refusalDate()} года при применении трансформера`;
      }

      /** Удалён из трансформера в пакете */
      if (connection === ConnectionStates.RemovedFromTheTransformerInPackage) {
        const stringDate = removedPackages[0]?.banTrimDate
          ? `${getDate(removedPackages[0]?.banTrimDate)} года`
          : '';
        if (transformerInfo) {
          if (removedPackages.length === 1) {
            return `Будет оформлен отказ от канала в составе пакета ${
              removedPackages[0]?.channelPackName
            } ${
              !isRejectionAvailable && `с ${stringDate} года`
            } при применении трансформера`;
          }
          if (removedPackages.length > 1) {
            /** Дата отключения одинаковая для все пакетов */
            const isSameDate = removedPackages.reduce(
              (acc, item, index, arr) => {
                if (!acc) return false;
                return index !== 0
                  ? arr[index - 1]?.banTrimDate === item?.banTrimDate
                  : true;
              },
              true,
            );

            return isSameDate
              ? `Будет оформлен отказ от канала в составе пакетов ${removedPackages
                  .map((item) => item.channelPackName)
                  .join(', ')} с ${stringDate} при применении трансформера`
              : `Будет оформлен отказ от канала в составе пакетов ${removedPackages
                  .map((item) => {
                    const banDate = item.banTrimDate
                      ? `${getDate(item.banTrimDate)} года`
                      : '';
                    return `${item.channelPackName} ${
                      !isRejectionAvailable && banDate
                    }`;
                  })
                  .join(', ')} при применении трансформера`;
          }
        }
      }
      return undefined;
    };

    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          !isRejectionOfThePackage &&
          ![
            ConnectionStates.AddedToTransformer,
            ConnectionStates.RemovedFromTheTransformer,
            ConnectionStates.AddedToTransformerInPackage,
            ConnectionStates.RemovedFromTheTransformerInPackage,
          ].includes(connection) &&
          colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = useMemo((): string | boolean => {
    switch (connection) {
      case ConnectionStates.AddedToTransformer:
        return TEXT.ADDED;
      case ConnectionStates.RemovedFromTheTransformer:
        return TEXT.REMOVED;
      case ConnectionStates.AddedToTransformerInPackage:
        return addedToTransformerPackages.length > 1
          ? TEXT.ADDED_IN_PACKAGES
          : TEXT.ADDED_IN_PACKAGE;
      case ConnectionStates.RemovedFromTheTransformerInPackage:
        return removedPackages.length > 1
          ? TEXT.REMOVED_PACKAGES
          : TEXT.REMOVED_PACKAGE;
      case ConnectionStates.ConnectedSeparatelyFromThePackage:
        return TEXT.CONNECTED;
      case ConnectionStates.ConnectedInAPackage:
        return connectedPackages?.length > 1
          ? TEXT.CONNECTED_IN_PACKAGES
          : TEXT.CONNECTED_IN_PACKAGE;
      case ConnectionStates.ConnectedInTheProduct:
        return TEXT.INCLUDED_IN_PRODUCT;
      case ConnectionStates.CancellationIssuedSeparatelyFromThePackage:
        return TEXT.CHANNEL_ABANDONMENT;
      case ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced:
        return connectedPackages?.length > 1
          ? TEXT.REJECTION_OF_PACKAGES
          : TEXT.REJECTION_OF_PACKAGE;
      default:
        return false;
    }
  }, [
    connection,
    connectedPackages,
    addedToTransformerPackages,
    removedPackages,
  ]);

  /** Сайдпейдж подключения/отключения канала */
  const [isOpenConnectionSP, setIsOpenConnectionSP] = useState<boolean>(false);

  /** Результат подключения/отключения канала */
  const [result, setResult] = useState<ResultProps>(INITIAL_RESULT);

  /** true - подключение канала, false - отключение канала */
  const [isConnection, setIsConnection] = useState<boolean>(true);

  /** Событие отключения канала */
  const disableChannel = () => {
    if (
      isChannelWrapper ||
      connection === ConnectionStates.ConnectedSeparatelyFromThePackage
    ) {
      setIsConnection(false);
      setIsOpenConnectionSP(true);
    }
  };

  /** disable кнопки */
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  /** Загрузка кнопки */
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(true);

  /** Проверяет предусловия подключения телеканала к договору */
  const proofITvChannelBindCondition = async () => {
    if (!singleChannelPacks?.length || !singleChannelPacks[0]?.id) return;
    try {
      await checkITvChannelPackBindCondition(singleChannelPacks[0]?.id);
      setIsDisableButton(false);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Проверяет предусловия открепления телеканала от договора */
  const proofITvChannelUnbindCondition = async () => {
    if (!channelPackId) return;
    try {
      await checkITvChannelPackUnbindCondition(channelPackId);
      setIsDisableButton(false);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    if (connection === ConnectionStates.ConnectedSeparatelyFromThePackage) {
      proofITvChannelUnbindCondition();
    }
    if (connection === ConnectionStates.NotConnected) {
      proofITvChannelBindCondition();
    }
  }, [connection, channel]);

  /** Текст кнопки трансформера */
  const transformerButtonText = useMemo(() => {
    switch (connection) {
      case ConnectionStates.NotConnected:
        return isDesktop940
          ? TRANSFORMER_TEXT.ADD_TO_TRANSFORMER
          : TRANSFORMER_TEXT.ADD;
      case ConnectionStates.AddedToTransformer:
        return TRANSFORMER_TEXT.REMOVE_CHANNEL;
      case ConnectionStates.ConnectedSeparatelyFromThePackage:
        return TRANSFORMER_TEXT.REMOVE_CHANNEL;
      case ConnectionStates.RemovedFromTheTransformer:
        return TRANSFORMER_TEXT.RETURN_TO_TRANSFORMER;
      case ConnectionStates.RemovedFromTheTransformerInPackage:
        return isDesktop940
          ? TRANSFORMER_TEXT.ADD_TO_TRANSFORMER
          : TRANSFORMER_TEXT.ADD;
      case ConnectionStates.CancellationIssuedSeparatelyFromThePackage:
        return isDesktop940
          ? TRANSFORMER_TEXT.ADD_TO_TRANSFORMER
          : TRANSFORMER_TEXT.ADD;
      case ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced:
        return isDesktop940
          ? TRANSFORMER_TEXT.ADD_TO_TRANSFORMER
          : TRANSFORMER_TEXT.ADD;
      default:
        return null;
    }
  }, [connection, isDesktop940]);

  /** Дополнительная цена за трансформер */
  const additionalPriceForTransformer = useMemo(() => {
    if (singleChannelPacks?.length === 0 && !transformerInfo) return undefined;
    /** Остаток лимита трансформера */
    const residue = transformationLimit - editOrderSum;

    if (residue > singleChannelPrice) {
      return null;
    }
    if (transformationLimit > editOrderSum && residue < singleChannelPrice) {
      return singleChannelPrice - residue;
    }
    return singleChannelPrice;
  }, [transformationLimit, editOrderSum]);

  /** Событие клика трансформера */
  const transformerClick = () => {
    setIsEditTransformer(true);

    switch (connection) {
      case ConnectionStates.NotConnected:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer?.length
            ? [...idsAddedChannelsToTransformer, singleChannelPacks[0].id]
            : [singleChannelPacks[0].id],
        );
        setEditOrderSum(editOrderSum + singleChannelPrice);
        break;
      case ConnectionStates.AddedToTransformer:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer.filter(
            (item) => item !== singleChannelPacks[0].id,
          ),
        );
        setEditOrderSum(editOrderSum - singleChannelPrice);
        break;

      case ConnectionStates.ConnectedSeparatelyFromThePackage:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer.filter(
            (item) => item !== singleChannelPacks[0].id,
          ),
        );
        setEditOrderSum(editOrderSum - singleChannelPrice);
        break;

      case ConnectionStates.RemovedFromTheTransformer:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer?.length
            ? [...idsAddedChannelsToTransformer, singleChannelPacks[0].id]
            : [singleChannelPacks[0].id],
        );
        setEditOrderSum(editOrderSum + singleChannelPrice);
        break;

      case ConnectionStates.RemovedFromTheTransformerInPackage:
        setIdsAddedChannelsToTransformer(
          idsAddedChannelsToTransformer?.length
            ? [...idsAddedChannelsToTransformer, singleChannelPacks[0].id]
            : [singleChannelPacks[0].id],
        );
        setEditOrderSum(editOrderSum + singleChannelPrice);
        break;
      default:
        break;
    }
  };

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    if (transformationLimit > editOrderSum) {
      return `доступно ${formatNumber(
        transformationLimit - editOrderSum,
      )} ₽ для трансформации`;
    }
    return `${formatNumber(
      editOrderSum - transformationLimit,
    )} ₽ в день за доп. каналы`;
  }, [editOrderSum, transformationLimit]);

  return (
    <StyledChannelDescriptionCard id={CHANNEL_DESCRIPTION_ID}>
      <div className="channel-description-card__header">
        <div className="channel-description-card__header__logo-block">
          <div className="channel-description-card__header__logo-block__logo">
            {imgLink && (
              <Image
                loader={imageLoader}
                src={imgLink}
                alt={name}
                width={isDesktop940 ? 190 : 130}
                height={isDesktop940 ? 88 : 60}
                quality={100}
                loading="lazy"
              />
            )}
          </div>
          <div className="channel-description-card__header__logo-block__name-block">
            <H3>{name}</H3>
            <Text color={defaultTheme.colors.shadow}>{category}</Text>
            {connection !== ConnectionStates.NotConnected && (
              <div className="channel-description-card__header__logo-block__name-block__tag-block">
                {customTag(connectionStatusText)}
                {[
                  ConnectionStates.RemovedFromTheTransformer,
                  ConnectionStates.RemovedFromTheTransformerInPackage,
                ].includes(connection) &&
                  !isRejectionAvailable && <Icons.WaitIcon />}
                {adultContent ? (
                  <Text color={defaultTheme.colors.planeta}>Для взрослых</Text>
                ) : (
                  <>
                    {[
                      ConnectionStates.ConnectedInAPackage,
                      ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
                      ConnectionStates.ConnectedInTheProduct,
                    ].includes(connection) &&
                      connectedPackages.length > 0 && (
                        <Text color={defaultTheme.colors.planeta}>
                          {connectedPackages
                            ?.map((item) => (
                              <Text
                                className="channel-description-card__platforms__digital-tv__link"
                                color={defaultTheme.colors.planeta}
                                onClick={() => {
                                  setActivePackageCard(item);
                                  setCardState(CardStates.PACKAGE_DESCRIPTION);
                                }}
                              >
                                {item.channelPackName}
                              </Text>
                            ))
                            .reduce(
                              (acc, x) =>
                                acc === null ? (
                                  x
                                ) : (
                                  <>
                                    {acc}, {x}
                                  </>
                                ),
                              null,
                            )}
                        </Text>
                      )}
                  </>
                )}
                {connection ===
                  ConnectionStates.AddedToTransformerInPackage && (
                  <Text color={defaultTheme.colors.planeta}>
                    {addedToTransformerPackages
                      .map((item) => (
                        <Text
                          className="channel-description-card__platforms__digital-tv__link"
                          color={defaultTheme.colors.planeta}
                          onClick={() => {
                            setActivePackageCard(item);
                            setCardState(CardStates.PACKAGE_DESCRIPTION);
                          }}
                        >
                          {item.channelPackName}
                        </Text>
                      ))
                      .reduce(
                        (acc, x) =>
                          acc === null ? (
                            x
                          ) : (
                            <>
                              {acc}, {x}
                            </>
                          ),
                        null,
                      )}
                  </Text>
                )}
                {connection ===
                  ConnectionStates.RemovedFromTheTransformerInPackage && (
                  <Text color={defaultTheme.colors.planeta}>
                    {removedPackages
                      .map((item) => (
                        <Text
                          className="channel-description-card__platforms__digital-tv__link"
                          color={defaultTheme.colors.planeta}
                          onClick={() => {
                            setActivePackageCard(item);
                            setCardState(CardStates.PACKAGE_DESCRIPTION);
                          }}
                        >
                          {item.channelPackName}
                        </Text>
                      ))
                      .reduce(
                        (acc, x) =>
                          acc === null ? (
                            x
                          ) : (
                            <>
                              {acc}, {x}
                            </>
                          ),
                        null,
                      )}
                  </Text>
                )}
              </div>
            )}
          </div>
        </div>
        {!isLightbox && (
          <div
            className="channel-description-card__header__close-icon"
            onClick={() => {
              setCardState(CardStates.MAIN);
              if (!isDesktop940)
                setTimeout(() => {
                  scrollToBlockById(openCardBlockId, 'auto', 'center');
                }, 0);
            }}
          >
            <Icons.CloseIcon />
          </div>
        )}
      </div>

      <Row>
        <Text>{parseHtml(description)}</Text>
      </Row>

      {((connection === ConnectionStates.ConnectedSeparatelyFromThePackage ||
        connection ===
          ConnectionStates.CancellationIssuedSeparatelyFromThePackage) &&
        !transformerInfo) ||
        (transformerInfo &&
          singleChannelPacks?.length > 0 &&
          ![
            ConnectionStates.AddedToTransformerInPackage,
            ConnectionStates.ConnectedInAPackage,
            ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
          ].includes(connection) && (
            <div className="channel-description-card__subscription-cost">
              <Row
                title={`Стоимость подписки ${
                  transformerInfo && `в${'\u00A0'}Трансформере`
                }`}
              >
                <ToggleTabs
                  cost={
                    singleChannelPacks?.length
                      ? singleChannelPacks[0]?.price
                      : price
                  }
                  additionally={
                    ![ConnectionStates.AddedToTransformer].includes(connection)
                      ? additionalPriceForTransformer
                      : null
                  }
                />
                {connection ===
                  ConnectionStates.CancellationIssuedSeparatelyFromThePackage && (
                  <Text
                    className="channel-description-card__subscription-cost__rejection"
                    color={defaultTheme.colors.gray}
                  >
                    Списания прекратятся с&nbsp;{shutdownDate}
                  </Text>
                )}
                {connection === ConnectionStates.RemovedFromTheTransformer && (
                  <>
                    {isRejectionAvailable ? (
                      <Text
                        className="channel-description-card__transformer__refusal-date"
                        lineHeight="24px"
                        color={defaultTheme.colors.gray}
                      >
                        Отключение доступно сразу после применения Трансформера
                      </Text>
                    ) : (
                      <Text
                        className="channel-description-card__transformer__refusal-date"
                        lineHeight="24px"
                        color={defaultTheme.colors.gray}
                      >
                        Отключение доступно
                        <br />
                        с&nbsp;{refusalDate()}
                      </Text>
                    )}
                  </>
                )}

                {transformerInfo && (
                  <div className="channel-description-card__transformer__button-block">
                    <FillableScale
                      value={editOrderSum}
                      maxValue={transformationLimit}
                    >
                      <Text className="text" lineHeight="24px">
                        {textUnderScale}
                      </Text>
                    </FillableScale>
                    {[
                      ConnectionStates.NotConnected,
                      ConnectionStates.AddedToTransformer,
                      ConnectionStates.AddedToTransformerInPackage,
                      ConnectionStates.ConnectedSeparatelyFromThePackage,
                      ConnectionStates.RemovedFromTheTransformer,
                      ConnectionStates.RemovedFromTheTransformerInPackage,
                      ConnectionStates.CancellationIssuedSeparatelyFromThePackage,
                      ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
                    ].includes(connection) &&
                      !(
                        isLightbox &&
                        connection ===
                          ConnectionStates.CancellationIssuedSeparatelyFromThePackage
                      ) && (
                        <div className="channel-description-card__transformer__button-block__refusal-block">
                          {isLightbox ? (
                            <LinkWrapper href="/tv/channels">
                              <Button
                                className="package-description__transformer__button-block__refusal-block__button"
                                styleType={ButtonStyleTypes.SECONDARY}
                              >
                                {isDesktop700
                                  ? 'Управлять Трансформером'
                                  : 'Управлять'}
                              </Button>
                            </LinkWrapper>
                          ) : (
                            <Button
                              onClick={() => transformerClick()}
                              icon={
                                [
                                  ConnectionStates.NotConnected,
                                  ConnectionStates.RemovedFromTheTransformer,
                                  ConnectionStates.RemovedFromTheTransformerInPackage,
                                ].includes(connection) && (
                                  <Icon icon={<Icons.PlusIcon />} />
                                )
                              }
                              styleType={
                                [
                                  ConnectionStates.AddedToTransformer,
                                  ConnectionStates.ConnectedSeparatelyFromThePackage,
                                ].includes(connection)
                                  ? ButtonStyleTypes.SECONDARY
                                  : undefined
                              }
                              disabled={[
                                ConnectionStates.CancellationIssuedSeparatelyFromThePackage,
                                ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
                              ].includes(connection)}
                            >
                              {transformerButtonText}
                            </Button>
                          )}
                          {connection ===
                            ConnectionStates.ConnectedSeparatelyFromThePackage && (
                            <>
                              {isRejectionAvailable ? (
                                <Snoska>
                                  Отключение доступно сразу после
                                  <br />
                                  применения Трансформера
                                </Snoska>
                              ) : (
                                <Snoska>
                                  Отключение доступно
                                  {isDesktop500 && <br />}
                                  с&nbsp;{refusalDate()}
                                </Snoska>
                              )}
                            </>
                          )}
                          {[
                            ConnectionStates.CancellationIssuedSeparatelyFromThePackage,
                            ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
                          ].includes(connection) && (
                            <Snoska className={`${isLightbox && 'snoska'}`}>
                              Заново добавить канал вы&nbsp;сможете
                              <br />
                              после{' '}
                              {connection ===
                              ConnectionStates.CancellationIssuedSeparatelyFromThePackage
                                ? shutdownDate
                                : shutdownPackageDate}
                            </Snoska>
                          )}
                        </div>
                      )}
                  </div>
                )}
              </Row>
            </div>
          ))}

      {(connection === ConnectionStates.NotConnected ||
        isRejectionOfThePackage) &&
        !transformerInfo &&
        (connectionOptions?.length > 0 || singleChannelPacks?.length > 0) &&
        !isLightbox && (
          <div className="channel-description-card__connection-options">
            <Row title="Варианты подключения">
              {connectionOptions.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
              {singleChannelPacks?.length > 0 && (
                <ChannelCard
                  channel={channel}
                  onClick={() => {
                    if (!isDisableButton && !shutdownDate && !isLoadingButton) {
                      setIsConnection(true);
                      setIsOpenConnectionSP(true);
                    }
                  }}
                  isConnection
                  disabled={
                    !!shutdownDate || isDisableButton || isLoadingButton
                  }
                />
              )}
            </Row>
          </div>
        )}

      {transformerInfo &&
        [
          ConnectionStates.NotConnected,
          ConnectionStates.RemovedFromTheTransformerInPackage,
          ConnectionStates.ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
        ].includes(connection) &&
        !isLightbox &&
        connectionOptions.length > 0 && (
          <div className="channel-description-card__connection-options">
            <Row title="Канал доступен в пакетах">
              {connectionOptions.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
            </Row>
          </div>
        )}

      {transformerInfo &&
        connection === ConnectionStates.RemovedFromTheTransformer &&
        connectionOptions?.length > 0 &&
        !isLightbox && (
          <div className="channel-description-card__connection-options">
            <Row title="Более выгодные варианты подключения">
              {connectionOptions.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
            </Row>
          </div>
        )}

      <Platforms itv_channel_link={itvChannelLink} isWink={isWink} />

      <Row
        title="Свойства канала"
        removeLine={
          connection === ConnectionStates.NotConnected ||
          isRejectionOfThePackage ||
          connection === ConnectionStates.ConnectedInTheProduct ||
          adultContent ||
          (transformerInfo &&
            [
              ConnectionStates.RemovedFromTheTransformer,
              ConnectionStates.RemovedFromTheTransformerInPackage,
            ].includes(connection)) ||
          (isLightbox &&
            connection !==
              ConnectionStates.ConnectedSeparatelyFromThePackage) ||
          !!(isLightbox && transformerInfo)
        }
      >
        <ChannelProperties
          channelDefinition={channelDefinition}
          audioStreams={audioStreams}
          adultContent={adultContent}
          isViewControlAvailable={isViewControlAvailable}
        />
      </Row>

      {connection === ConnectionStates.ConnectedInAPackage &&
        !adultContent &&
        connectionOptions?.length > 0 &&
        !isLightbox && (
          <div className="channel-description-card__connection-options">
            <Row title="Другие варианты подключения" removeLine>
              {connectionOptions.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
            </Row>
          </div>
        )}

      {connection === ConnectionStates.ConnectedSeparatelyFromThePackage &&
        connectionOptions?.length > 0 &&
        !isLightbox && (
          <div className="channel-description-card__connection-options">
            <Row
              title="Более выгодные варианты подключения"
              removeLine={!!transformerInfo}
            >
              {connectionOptions.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
            </Row>
          </div>
        )}

      {transformerInfo &&
        [
          ConnectionStates.AddedToTransformerInPackage,
          ConnectionStates.AddedToTransformer,
        ].includes(connection) &&
        connectionOptions?.length > 0 && (
          <div className="channel-description-card__connection-options">
            <Row title="Канал доступен в пакетах" removeLine>
              {connectionOptions.map((packet) => {
                return (
                  <ChannelPackageCard
                    key={packet.channelPackName}
                    packet={(packet as unknown) as ChannelPackage}
                    onClick={() => onClickPackageCard(packet)}
                  />
                );
              })}
            </Row>
          </div>
        )}

      {connection === ConnectionStates.ConnectedSeparatelyFromThePackage &&
        !transformerInfo && (
          <Row title="Управление подпиской" removeLine>
            <div className="channel-description-card__subscription-management">
              <Button
                styleType={ButtonStyleTypes.SECONDARY}
                onClick={disableChannel}
                disabled={isDisableButton}
                loading={isLoadingButton}
              >
                {isDesktop940 ? 'Отключить телеканал' : 'Отключить'}
              </Button>
              {isRejectionAvailable ? (
                <Snoska color={defaultTheme.colors.gray}>
                  Станет недоступен сразу после отключения
                </Snoska>
              ) : (
                <Snoska color={defaultTheme.colors.gray}>
                  Отключение доступно с&nbsp;{refusalDate()}
                </Snoska>
              )}
            </div>
          </Row>
        )}
      <Portal>
        <SidePage
          show={isOpenConnectionSP}
          headerText={
            !result.isResult &&
            `${isConnection ? 'Подключение' : 'Отключение'} Телеканала ${name}`
          }
          onCloseClick={() => {
            setIsOpenConnectionSP(false);
            setResult(INITIAL_RESULT);
          }}
        >
          <ChannelConnectionWizard
            isConnection={isConnection}
            channel={channel}
            result={result}
            setResult={setResult}
          />
        </SidePage>
      </Portal>
    </StyledChannelDescriptionCard>
  );
};

export default observer(ChannelDescriptionCard);
