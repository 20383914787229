/** библиотеки */
import { FC, useMemo } from 'react';

/** компоненты библиотеки */
import { defaultTheme, H2, H3, Inset, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledUltraHD } from './UltraHD.style';
import LinkWrapper from '~/components/LinkWrapper';

/** утилиты */
import { pluralizeAll } from '~/utils/utils';

/** типы */
import {
  AdvantagesContentProps,
  AdvantagesUltraHDFields,
} from '../Advantages.types';
import {
  SubjectWithChannels,
  ChannelFromSubjects as Channel,
} from '../../Pab2c/MyTV/interfaces';
import { CHANNEL_TYPE } from './interfaces';

/** Склонения количества каналов в именительном падеже */
export const CHANNELS_DECLINATION: string[] = ['канал', 'канала', 'каналов'];

const UltraHD: FC<AdvantagesContentProps> = ({ content }) => {
  const fields = content.fields as AdvantagesUltraHDFields;
  const { channels } = fields;
  // Список каналов одним массивом
  const channelsList: Channel[] =
    channels &&
    channels
      .map((item: SubjectWithChannels) => {
        return item.channels;
      })
      .flat(1);

  /**
   * Считает количество UltraHD телеканалов
   * @param {Channel[]} channelsList массив телеканалов
   * @returns {number} количество UltraHD телеканалов
   */
  const calcUltraHDChannel = useMemo(
    () => (channelList: Channel[]): number => {
      return channelList.reduce((ammount, channel) => {
        if (channel.channelDefinition === CHANNEL_TYPE.uhd) {
          return ammount + 1;
        }
        return ammount;
      }, 0);
    },
    [channelsList],
  );
  const ultraHDCount = calcUltraHDChannel(channelsList);
  const TITLE240 = '4К Ultra HD';
  const TITLE = 'Эпоха 4К UHD';
  const TEXT = `${pluralizeAll(
    ultraHDCount,
    CHANNELS_DECLINATION,
    null,
  )} в${'\u00A0'}формате Ultra HD${'\u00A0\u2014'} это плюс!`;
  const TEXT_WIDE = `Смотри сверхчёткое телевидение Планеты${'\u00A0\u2014'} не${'\u00A0'}перепутай с${'\u00A0'}реальностью!`;
  const TEXT340 = `${TEXT} ${TEXT_WIDE}`;
  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledUltraHD>
        <H3 className="title__240" color={defaultTheme.colors.white}>
          {TITLE240}
        </H3>
        <H2 className="title__wide" color={defaultTheme.colors.white}>
          {TEXT}
        </H2>
        <H3 className="title" color={defaultTheme.colors.white}>
          {TITLE}
        </H3>
        <Text className="text__240" color={defaultTheme.colors.white}>
          {TEXT}
        </Text>
        <Inset className="text__wide" color={defaultTheme.colors.white}>
          {TEXT_WIDE}
        </Inset>
        <Inset className="text" color={defaultTheme.colors.white}>
          {TEXT}
        </Inset>
        <Inset className="text__340" color={defaultTheme.colors.white}>
          {TEXT340}
        </Inset>
      </StyledUltraHD>
    </LinkWrapper>
  );
};

export default UltraHD;
