/** библиотеки */
import styled from '@emotion/styled';

/** типы */
import { StyledImageProps, StyledTeaserProps } from './Teaser.types';

export const StyledTeaser = styled.div<StyledTeaserProps>`
  ${({ imgSize }): string => `
    display: block;
    margin-left: 32px;

    & > div + div {
      margin-top: 16px;
    }

    .wrapper-img {
      height: ${imgSize.newHeight}px;
      width: ${imgSize.newWidth}px;
    }
  `}
`;

export const StyledImage = styled.div<StyledImageProps>`
  ${({ imgSrc, imgSize }): string => `
      height: ${imgSize.newHeight}px;
      width: ${imgSize.newWidth}px;
      background: url(${imgSrc}) left bottom no-repeat;
      background-size: contain;
  `}
`;
