import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Text, Checkbox, defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop350,
  desktop475,
  desktop940,
} from '~/components/Grid/constants';
import { CoinsKeysDesktop, CoinsKeysMobile } from './interfaces';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 60px;
  }
`;

export const Header = styled.span`
  text-align: center;
  margin-bottom: 23px;

  @media (min-width: ${desktop940}px) {
    white-space: nowrap;
  }
`;

export const MainHeader = styled(Header)`
  padding: 0;

  @media (min-width: ${desktop350}px) {
    padding: 0 13vw;
  }

  @media (min-width: ${desktop475}px) {
    padding: 0;
  }
`;

export const HeaderAuth = styled(Header)`
  white-space: pre-line;

  @media (min-width: ${desktop940}px) {
    max-width: 390px;
    white-space: pre-line;
  }
`;

export const MainText = styled.span`
  text-align: center;
  margin-bottom: 23px;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 60px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  input:checked ~ table .checkMark {
    background-color: ${defaultTheme.colors.violet};
    border: 1px solid ${defaultTheme.colors.violet};
  }

  z-index: 3;

  table {
    padding-left: 9vw;
  }

  @media (min-width: ${desktop940}px) {
    table {
      padding-left: 0;
    }
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 100px;
  height: 60px;

  span {
    font-size: 24px;
    font-weight: 700;
  }

  @media (min-width: ${desktop350}px) {
    width: 289px;
  }

  @media (min-width: ${desktop940}px) {
    span {
      font-size: 38px;
      font-weight: 600;
    }

    margin-bottom: 0;
    width: 299px;
    height: 87px;
  }
`;

export const Form = styled.form<{ $isAuth: boolean; $isLoading: boolean }>`
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $isLoading }) =>
    $isLoading ? 'center' : 'flex-start'};
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.71);
  box-shadow: 0px -13px 80px rgba(147, 147, 147, 0.17),
    0px -7.93453px 51.7276px rgba(147, 147, 147, 0.116447),
    0px -5.08912px 47.753px rgba(147, 147, 147, 0.0890936),
    0px -3.21321px 42.5388px rgba(147, 147, 147, 0.0674681),
    0px -1.88297px 33.0423px rgba(147, 147, 147, 0.0480802),
    0px -0.871217px 18.8162px rgba(147, 147, 147, 0.0285737);
  backdrop-filter: blur(12.5px);
  border-radius: 20px;
  width: 100%;
  max-width: 1039px;
  min-height: 189px;
  padding: 23px 21px;
  row-gap: 0;

  ${Header} {
    white-space: pre-line;
  }

  ${StyledButton} + div {
    margin-top: 23px;
  }

  @media (min-width: ${desktop940}px) {
    min-height: 577px;
    row-gap: ${({ $isAuth }) => (!$isAuth ? 76 : 41)}px;
    padding: 87px 0;

    ${Header} {
      font-weight: 600;
      line-height: 32px;

      ${({ $isAuth }) =>
        $isAuth &&
        css`
          margin-bottom: 9px;
        `};
    }

    ${StyledButton} + div {
      margin-top: 0;
    }
  }
`;

export const StyledText = styled(Text)`
  display: flex;
`;

export const CheckboxAgreement = styled.span`
  display: inline-block;
  text-align: left;

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: ${desktop940}px) {
    ${StyledText} {
      display: inline-flex;

      &:last-of-type {
        display: flex;
      }
    }
  }
`;

export const MobileCoins = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileCoinWrapper = styled.div<{ $coin: CoinsKeysMobile }>`
  position: relative;

  ${({ $coin }) => {
    switch ($coin) {
      case 'coinHeadsMobile':
        return css`
          order: 2;
          z-index: 2;
          bottom: -15px;
        `;
      case 'coin1000Mobile':
        return css`
          order: 1;
          z-index: 1;
          left: 58px;
          bottom: 9px;
        `;
      case 'coin1500Mobile':
        return css`
          order: 3;
          z-index: 3;
          right: 69px;
        `;
      default:
        return css``;
    }
  }}
`;

export const CoinWrapper = styled.div<{
  $coin: CoinsKeysDesktop;
}>`
  position: absolute;
  z-index: 1;

  @media (min-width: ${desktop940}px) {
    ${({ $coin }) => {
      switch ($coin) {
        case 'coinHeads':
          return css`
            top: 520px;
            right: -96px;
          `;
        case 'coin500':
          return css`
            top: 620px;
            left: 15px;
          `;
        case 'coin1000':
          return css`
            top: 250px;
            left: -60px;
          `;
        case 'coin1500':
          return css`
            top: 275px;
            right: -150px;
          `;
        default:
          return css``;
      }
    }}
  }

  @media (min-width: ${desktop1100}px) {
    ${({ $coin }) => {
      switch ($coin) {
        case 'coinHeads':
          return css`
            top: 455px;
            right: -10px;
          `;
        case 'coin500':
          return css`
            top: 555px;
            left: 91px;
          `;
        case 'coin1000':
          return css`
            top: 195px;
            left: -72px;
          `;
        case 'coin1500':
          return css`
            top: 200px;
            right: -86px;
          `;
        default:
          return css``;
      }
    }}
  }
`;

export const ResultWrapper = styled.div<{
  $path: string;
  $width: number;
  $height: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: url(${({ $path }) => $path});
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  padding: 82px 0;
  margin-top: -25px;

  ${MainText}:first-of-type {
    margin-bottom: 31px;
  }

  ${MainText}:nth-of-type(2) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  ${MainText}, ${Header} {
    z-index: 2;
    white-space: pre-line;
  }

  @media (min-width: ${desktop940}px) {
    padding: 0 96px 71px 96px;
    justify-content: center;
    margin-top: -45px;

    ${MainText}:first-of-type {
      margin-bottom: 44px;
    }
  }
`;

export const ShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  .share {
    &__text {
      text-align: center;
    }

    &__icons {
      display: flex;
      margin-top: 24px;

      > div {
        margin-right: 8px;
      }
    }

    &__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-color: black;
      border-radius: 50%;
      cursor: pointer;
    }

    &__ok {
      svg {
        width: 15px;
        height: 26px;
      }
    }
  }
`;
