/** libraries */
import { FC } from 'react';
/** interfaces */
import { RightSideProps } from '~/components/Blocks/Templates/Timeline/interfaces';
/** components */
import Wrapper, {
  Ball,
  MobileBall,
  MobileBallText,
  TextWrapper,
} from './style';
import ImageViewer from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/ImageViewer';
import { Header } from '~/components/Blocks/Templates/Timeline/MiddleRow/LeftSide/style';
import { LeadingText } from 'cordis-core-ui-planeta';
import AudioPlayer from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/AudioPlayer';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import VideoPlayer from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/VideoPlayer';

/** Правая карточка */
const RightSide: FC<RightSideProps> = ({
  backgroundColor,
  textColor,
  audio,
  video,
  headerText,
  hasImgZoom,
  mainText,
  wUnits,
  url,
  width,
  height,
  date,
}: RightSideProps) => {
  const isMobile = date === '0' || Boolean(date);
  return (
    <Wrapper
      $isMobile={isMobile}
      $backgroundColor={backgroundColor}
      $nullDate={date === '0'}
    >
      {isMobile ? (
        <MobileBall $textColor={textColor}>
          {date !== '0' && (
            <MobileBallText as={LeadingText}>{date}</MobileBallText>
          )}
        </MobileBall>
      ) : (
        <Ball />
      )}
      {url && (
        <ImageViewer
          url={url}
          width={width}
          height={height}
          wUnits={wUnits}
          hasImgZoom={hasImgZoom}
          headerText={headerText}
        />
      )}
      {headerText && (
        <Header as={LeadingText} color={textColor}>
          {headerText}
        </Header>
      )}
      {mainText && (
        <TextWrapper $textColor={textColor}>{parseHtml(mainText)}</TextWrapper>
      )}
      {audio && !isMobile && <AudioPlayer audio={audio} />}
      {video && !isMobile && <VideoPlayer video={video} />}
    </Wrapper>
  );
};

export default RightSide;
