/** Библиотеки */
import * as React from 'react';
import { observer } from 'mobx-react';

/** Компоненты */
import { Text, defaultTheme } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledCitySelector, StyledCityList } from './CitySelector.style';

/** интерфейсы */
import { City } from '~/interfaces/CityInterface';

/** stores */
import { useRootStore } from '~/stores/RootStore';

interface CitySelectorProps {
  onCloseClick: () => void;
}

/**
 * Блок выбора городов для мобильной версии
 * @returns {React.FC}
 */
const CitySelector: React.FC<CitySelectorProps> = ({
  onCloseClick,
}: CitySelectorProps) => {
  const {
    cityStore: { city, cities, setCity },
  } = useRootStore();
  /**
   * Элемент списка городов
   * @param {City} item - город
   * @returns {JSX.Element}
   */
  const CityItem = (item: City): JSX.Element => (
    <Text
      color={defaultTheme.colors.black}
      fontWeightBold={item.id === city.id}
      role="button"
      onClick={(): void => {
        onCloseClick();
        setCity(item);
      }}
    >
      {item.name}
    </Text>
  );

  return (
    <StyledCitySelector>
      <StyledCityList data-test-list="">
        {cities.map((item) => (
          <li key={item.shortName} data-test-li="">
            {CityItem(item)}
          </li>
        ))}
      </StyledCityList>
    </StyledCitySelector>
  );
};

export default observer(CitySelector);
