/* Стилизованный контент строки */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledRowContent = styled.div<{ removeLine?: boolean }>`
  ${({ removeLine }) => `
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-size: 16px;
      ${
        !removeLine &&
        `border-bottom: 1px solid ${defaultTheme.colors.line}; padding-bottom: 24px; margin: 0 0 24px 0;`
      };

      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        flex-direction: row;
      }
    `}
`;

/* Стилизованный заголовок строки */

export const StyledRowContentTitle = styled.div`
  ${() => `
      min-width: 100%;
      color: ${defaultTheme.colors.shadow};
      margin: 0 0 1rem 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      
      & svg {
        display: block;
        width: 24px;
        height: 24px;
        margin: 13px 0;
      }

      .description {
        margin-top: 2px;
      }
      
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        padding: 0 2rem 0 0;
        flex-direction: column-reverse;
        min-width: 260px;
        max-width: 260px;
        flex: 3;
        margin: 0;
        color: ${defaultTheme.colors.black};
      }
    `}
`;

/* Стилизованное содержимое  строки */

export const StyledRowContentBody = styled.div`
  ${() => `
      flex: 7
    `}
`;
