/** библиотеки */
import { FC } from 'react';
import * as React from 'react';

/** Компоненты библиотеки */
import { Icons, LeadingText, Text, defaultTheme } from 'cordis-core-ui-planeta';

/** Компоненты */
import { BlockedEntranceContainer } from './BlockedEntrance.style';

/** константы */

/** Типы */
import { BlockedEntranceProps } from './BlockedEntrance.types';

/**
 * Страница блокировки.
 */
const BlockedEntrance: FC<BlockedEntranceProps> = ({
  contractName,
  blockMinutes,
}: BlockedEntranceProps) => {
  return (
    <BlockedEntranceContainer>
      <Icons.BlockedIcon />
      <LeadingText
        className="blockedLeadText"
        color={defaultTheme.colors.totalBlack}
      >
        Доступ к договору {contractName} заблокирован на {blockMinutes} минут
      </LeadingText>
      <Text
        className="blockedText"
        lineHeight="24px"
        color={defaultTheme.colors.totalBlack}
      >
        Доступ был заблокирован после 5 неудачных попыток ввода пароля. Такая
        временная блокировка доступа снижает риск несанкционированного доступа в
        раздел «Моя Планета».
      </Text>
    </BlockedEntranceContainer>
  );
};

export default BlockedEntrance;
