/** Параметры блоков сетки */
// минимальная ширина на телефоне
export const minWidth = 287;
// ширина на определённых размерах экрана
export const blockWidth400 = 400;
export const blockWidth340 = 340;
export const blockWidth280 = 280;
// расстояние между блоками
export const marginBetweenBlocks = 32;
// стандартная высота блока
export const height = 216;
// внутренний отступ в блоке
export const innerPadding = 0;

/** Breakpoints */
export const desktop1280 = 1280;
export const desktop1100 = 1100;
export const desktop940 = 940;
export const desktop900 = 900;
export const desktop880 = 880;
export const desktop800 = 800;
export const desktop750 = 750;
export const desktop700 = 700;
export const desktop650 = 650;
export const desktop609 = 609;
export const desktop600 = 600;
export const desktop530 = 530;
export const desktop520 = 520;
export const desktop500 = 500;
export const desktop475 = 475;
export const desktop450 = 450;
export const desktop440 = 440;
export const desktop430 = 430;
export const desktop420 = 420;
export const desktop400 = 400;
export const desktop380 = 380;
export const desktop377 = 377;
export const desktop375 = 375;
export const desktop370 = 370;
export const desktop350 = 350;
export const desktop340 = 340;
export const desktop330 = 330;
export const desktop320 = 320;
export const desktop300 = 300;
export const desktop285 = 285;
export const desktop280 = 280;
export const desktop240 = 240;
