import { useEffect, useRef } from 'react';

interface useDeepLinkCheckerProps {
  /** Если браузер не смог обработать ссылку */
  onIgnored: () => void;
  /** Если пользователь отказался от открытия приложения */
  onFallback: () => void;
  /** Если приложение открылось и пользователь вернулся на страницу */
  onReturn: () => void;
}

type startChecking = () => void;

/** Hook проверки наличия установленного приложения на мобильных устройствах */
const useDeepLinkChecker = ({
  onIgnored,
  onReturn,
  onFallback,
}: useDeepLinkCheckerProps): startChecking => {
  const hasFocus = useRef<boolean>(true);
  const didHide = useRef<boolean>(false);

  /** При отображении системного окна срабатывает события потери фокуса */
  const onBlur = () => {
    hasFocus.current = false;
  };

  /** Срабатывает при отображении приложения */
  const onVisibilityChange = (e) => {
    if (e.target?.visibilityState !== 'hidden') return;
    didHide.current = true;
  };

  /** Обработка события фокуса */
  const onFocus = () => {
    if (didHide.current) {
      onReturn();
      didHide.current = false;
      hasFocus.current = true;

      return;
    }

    // Обработка события возвращения из приложения для iOS Safari 13.3+
    if (!hasFocus.current) {
      setTimeout(() => {
        if (!didHide.current) {
          onFallback();
        }
      }, 1000);
      hasFocus.current = true;
    }
  };

  /** Добавление слушателей */
  const bindEvents = (mode: 'add' | 'remove') => {
    [
      [window, 'blur', onBlur],
      [document, 'visibilitychange', onVisibilityChange],
      [window, 'focus', onFocus],
    ].forEach((conf) => conf[0][`${mode}EventListener`](conf[1], conf[2]));
  };

  /** Функция проверки */
  /* yaspeller ignore:start */
  // Здесь ты можешь подумать, что неплохо бы было передавать url и открывать его, но я не рекомендую тебе этого делать
  // - некоторые версии Safari не открывают ссылку повторно, если она не содержится в виде ссылки в DOM
  /* yaspeller ignore:end */
  const startChecking = () => {
    // Ждём появления системного окна
    const dialogTimeout = 500;

    setTimeout(() => {
      if (!hasFocus.current) return;
      onIgnored();
    }, dialogTimeout);
  };

  useEffect(() => {
    bindEvents('add');

    return () => {
      bindEvents('remove');
      hasFocus.current = true;
      didHide.current = false;
    };
  }, []);

  return startChecking;
};

export default useDeepLinkChecker;
