/** библиотеки */
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import { defaultTheme, H3, Tabs, TabsStyleTypes } from 'cordis-core-ui-planeta';
/** интерфейсы */
import { ActiveTab } from '../types';
/** константы */
import { TABS_SOFTLINE } from './constants';
import { ANNUAL_SUBSCRIPTION } from '../constants';
/** утилиты */
import { pluralizeAll } from '~/utils/utils';
import { HooksTyping } from '~/utils/typeScriptHelpers';
/** типы */
import { SoftlineTabsProps } from './types';
/** стили */
import { StyledSoftlinePrice } from './styles';
/** hooks */
import { useSoftline } from '../hooks/SoftlineHook';

/**
 * Компонент цены антивируса
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 */
const SoftlineTabs = ({ item }: SoftlineTabsProps): JSX.Element => {
  const { trialInfo, durationDays } = item;

  const { formattedPrice, formattedPricePerMonth } = useSoftline(item);

  /** Годовая подписка */
  const isAnnual = durationDays === ANNUAL_SUBSCRIPTION;

  /** Возвращает массив табов */
  const generateTabs = (): TABS_SOFTLINE[] | [] => {
    if (isAnnual) return [TABS_SOFTLINE.YEAR];
    const newTabs = [TABS_SOFTLINE.DAY, TABS_SOFTLINE.MONTH];
    // Если промо период
    if (trialInfo?.isEnabled || trialInfo?.trialDays > 0)
      newTabs.unshift(TABS_SOFTLINE.PROMO);
    return newTabs;
  };

  /** Массив табов */
  const softlineTabs = generateTabs();

  /** Исходный активный таб */
  const initialActiveTab = {
    index: 0,
    value: softlineTabs[0],
  };

  /** Активный таб */
  const [activeTab, setActiveTab]: HooksTyping<ActiveTab> = useState<ActiveTab>(
    initialActiveTab,
  );

  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [item]);

  /* Событие при изменении таба */
  const onChangeTab = (index: number, value: string) => {
    setActiveTab({ index, value });
  };

  const tabContent = {
    [TABS_SOFTLINE.PROMO]: (
      <>
        {trialInfo?.trialPrice}&#160;₽ на{' '}
        {pluralizeAll(trialInfo?.trialDays, ['день', 'дня', 'дней'])}
      </>
    ),
    [TABS_SOFTLINE.DAY]: <>{formattedPrice}&#160;₽</>,
    [TABS_SOFTLINE.MONTH]: <>{formattedPricePerMonth}&#160;₽</>,
    [TABS_SOFTLINE.YEAR]: <>{formattedPrice}&#160;₽</>,
  };

  return (
    <StyledSoftlinePrice>
      <div>
        <Tabs
          value={generateTabs()}
          styleType={TabsStyleTypes.SECONDARY}
          onChange={onChangeTab}
          activeTabIndex={activeTab.index}
        />
      </div>
      <H3
        className="softline-price__tabs-price"
        color={defaultTheme.colors.black}
      >
        {tabContent[activeTab.value]}
      </H3>
    </StyledSoftlinePrice>
  );
};

export default observer(SoftlineTabs);
