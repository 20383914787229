import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
`;

export const Header = styled.span`
  text-align: center;
  margin-bottom: 20px;
`;

export const WrapperBlock = styled.span`
  display: inline-grid;

  > button {
    width: fit-content;
  }
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'space-between' : 'flex-start'};
  width: 100%;

  ${Header} {
    ${({ $isMobile }) =>
      !$isMobile ? 'margin-top: 214px;' : 'text-align: left;'}
  }

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            text-align: left;
          `
        : css`
            margin-bottom: 40px;
          `}
  }
`;
