/** libraries */
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  Text,
  Input,
  defaultTheme,
  Autocomplete,
  H3,
  LinkButton,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledNewAppeal } from './styles';
/** interfaces */
import { SelectProps } from '~/components/Blocks/Templates/Pab2c/Settings/interfaces';
import { AutocompleteProps } from '~/components/Blocks/Templates/Pab2c/Help/interfaces';
/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';
import { maskPhone } from '~/components/Blocks/Templates/Pab2c/Help/utils';
import { getClientName } from '~/utils/utils';
/** api */
import { getContacts } from '~/api/apiPab2c';
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

interface NewAppealProps {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  nameError: string;
  setNameError: Dispatch<SetStateAction<string>>;
  phoneNumber: AutocompleteProps;
  setPhoneNumber: Dispatch<SetStateAction<AutocompleteProps>>;
  phoneNumberError: string;
  setPhoneNumberError: Dispatch<SetStateAction<string>>;
}

const NewAppealChangeAContract: FC<NewAppealProps> = ({
  name,
  setName,
  nameError,
  setNameError,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
}: NewAppealProps): JSX.Element => {
  const {
    authStore: { auth },
  } = useRootStore();
  const { toggleVisible } = useMakeAuthStore();
  /** Имя */
  const onChangeName = (event) => {
    setNameError('');
    setName(event.target.value);
  };
  const onBlurName = () => {
    if (!name.length) setNameError('Введите имя');
  };

  /** Номер телефона */
  const onChangeInputPhoneNumber = (event) => {
    setPhoneNumberError('');
    setPhoneNumber(maskPhone(event));
  };
  const onChangePhoneNumber = (event) => {
    setPhoneNumberError('');
    setPhoneNumber(event);
  };

  /** Контакты */
  const [phoneContacts, setPhoneContacts] = useState<SelectProps[]>(null);

  /** Получает контакты */
  const getAllContacts = async () => {
    try {
      const res = await getContacts();
      const phones = res
        .filter((item) => item.contactValueClassId === 2)
        .map((item) => {
          return maskPhone(item.value);
        });
      if (phones?.length) setPhoneNumber(phones[0]);
      setPhoneContacts(phones);
    } catch (e) {
      console.error('getContacts', e);
    }
  };

  useEffect(() => {
    getAllContacts();
  }, []);

  useEffect(() => {
    setName(getClientName(auth.clientName));
  }, [auth]);

  return (
    <StyledNewAppeal>
      <div className="new__block">
        <Text lineHeight="24px">Ваш договор</Text>
        <div className="new__block__contractName">
          <H3 color={defaultTheme.colors.black}>{auth.contractName}</H3>
          <LinkButton onClick={() => toggleVisible()}>
            Другой договор
          </LinkButton>
        </div>
      </div>
      <div className="new__block">
        <Text lineHeight="24px">Ваш телефон</Text>
        <Autocomplete
          placeholder="Введите номер"
          items={phoneContacts}
          text={phoneNumber.label}
          onInputChange={onChangeInputPhoneNumber}
          onChange={onChangePhoneNumber}
          outsideClickHelper={outsideClickHelper}
          error={phoneNumberError}
        />
      </div>
      <div className="new__block">
        <Text lineHeight="24px">Как вас зовут</Text>
        <Input
          type="text"
          placeholder="Введите имя"
          value={name}
          error={nameError}
          onChange={onChangeName}
          onBlur={onBlurName}
        />
      </div>
    </StyledNewAppeal>
  );
};

export default observer(NewAppealChangeAContract);
