import { Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { FieldsModel } from '~/stores/models/SoftlineModel';
import { FieldsProps } from '../interfaces';

const Pab2cSoftlineModel = types
  .model('Pab2cSoftlineModel', {
    fieldsData: types.maybeNull(FieldsModel),
  })
  .views((self) => ({
    get fields() {
      return toJS(self.fieldsData);
    },
    get bannerData() {
      return toJS(self.fieldsData.banner);
    },
    getSoftlineSubscriptionsExtendedFields(
      list,
      addAdditionalProperties,
      simIdFromBind,
    ) {
      return addAdditionalProperties(
        list,
        self.fieldsData.features,
        self.fieldsData.imageSizes,
        simIdFromBind,
      );
    },
  }));

const createStore = (fieldsData: FieldsProps) => {
  return Pab2cSoftlineModel.create({
    fieldsData,
  });
};
export type IPab2cSoftlineStore = Instance<typeof Pab2cSoftlineModel>;
export default createStore;
