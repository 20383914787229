/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H2,
  H3,
  Inset,
  Text,
} from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledTvScreen, StyledGadgetsBG } from './TvScreen.style';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

/** константы */
import {
  desktop280,
  desktop340,
  desktop400,
  desktop650,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

const TvScreen: FC<AdvantagesContentProps> = ({ content }) => {
  const TITLE = `Смотрите на${'\u00A0'}всех девайсах`;
  const TITLE400 = `Смотрите на${'\u00A0'}больших и${'\u00A0'}малых экранах`;
  const TEXT = `Портал Цифрового телевидения Планеты работает на${'\u00A0'}всех домашних устройствах`;
  const TEXT_MOBILE_WIDE = `Ноутбук или компьютер, смартфон или планшет: портал Цифрового телевидения Планеты работает на${'\u00A0'}всех домашних устройствах`;
  const clickHandler = (): void => {
    window.location.href = content.fields.link;
  };

  // Вычисление ширины экрана
  const isDesktop280 = useMediaQuery({
    query: `(min-width: ${desktop280}px)`,
  });
  const isDesktop340 = useMediaQuery({
    query: `(min-width: ${desktop340}px)`,
  });
  const isDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });
  const isDesktop650 = useMediaQuery({
    query: `(min-width: ${desktop650}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  // размер при котором текст меняет line-height на 21
  const sizeLineHeight21 =
    (isDesktop280 && !isDesktop340) || (isDesktop940 && !isDesktop1100);

  return (
    <StyledTvScreen>
      <div className="tvScreen__content">
        {(!isDesktop400 || (isDesktop940 && !isDesktop1280)) && (
          <H3 className="title">{TITLE}</H3>
        )}
        {((isDesktop400 && !isDesktop650) || isDesktop1280) && (
          <H3 className="title__400">{TITLE400}</H3>
        )}
        {isDesktop650 && !isDesktop940 && (
          <H2 className="title__650">{TITLE400}</H2>
        )}
        {!isDesktop280 && (
          <Text className="text__mobile" color={defaultTheme.colors.shadow}>
            {TEXT}
          </Text>
        )}
        {((isDesktop280 && !isDesktop650) || isDesktop940) && (
          <Inset
            className={`text ${sizeLineHeight21 && 'text__line-height21'}`}
            color={defaultTheme.colors.shadow}
          >
            {TEXT}
          </Inset>
        )}
        {isDesktop650 && !isDesktop940 && (
          <Inset className="text__650" color={defaultTheme.colors.shadow}>
            {TEXT_MOBILE_WIDE}
          </Inset>
        )}
        <StyledGadgetsBG />
      </div>
      <div className="tvScreen__buttons">
        <Button
          styleType={ButtonStyleTypes.ROUNDED_SMALL}
          onClick={clickHandler}
        >
          tv.planeta.tc
        </Button>
      </div>
    </StyledTvScreen>
  );
};

export default TvScreen;
