/** библиотеки */
import styled from '@emotion/styled';
import { desktop940 } from '~/components/Grid/constants';

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  min-height: 400px;
  & li {
    margin: 0 0 2rem 0;
    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    & li {
      margin: 0 2rem 0 0;
    }
  }
`;
