import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованная ссылка на категорию */
export const StyledCategory = styled.div`
  h3 {
    margin-bottom: 0;
    &:hover {
      color: ${defaultTheme.colors.planeta};
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .category-subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
  }
`;
