/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop300,
  desktop380,
  desktop600,
  desktop650,
  desktop940,
  desktop1100,
} from '~/components/Grid/constants';

import { IMAGES } from '../constants';

export const StyledPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  padding: 32px;
  padding-top: 28px;
  background-color: ${defaultTheme.colors.whiteSmoke};

  h3 {
    font-weight: 700;
  }

  .sub_title {
    display: none;
    margin-bottom: 22px;
  }

  .title {
    max-width: 177px;
    margin-bottom: 14px;
  }

  .title__mobile-wide {
    display: none;
    margin-bottom: 26px;
  }

  .text {
    display: none;
  }

  .text__mobile {
    max-width: 167px;
  }

  @media (min-width: ${desktop300}px) {
    .text__mobile {
      max-width: 200px;
    }
  }

  @media (min-width: ${desktop380}px) {
    .title {
      max-width: unset;
    }

    .text__mobile {
      display: none;
    }

    .text {
      display: inline-block;
      max-width: 348px;
    }
  }

  @media (min-width: ${desktop600}px) {
    .sub_title {
      display: inline-block;
    }

    .title__mobile-wide {
      display: block;
    }

    .title {
      display: none;
    }
  }

  @media (min-width: ${desktop650}px) {
    flex-direction: row;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: column;

    .sub_title {
      display: none;
    }

    .title__mobile-wide {
      display: none;
    }

    .title {
      display: block;
      max-width: 177px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    .title {
      margin-bottom: 26px;
    }

    .text {
      max-width: 268px;
    }
  }
`;

export const StyledImg = styled.div`
  height: 142px;
  background: url(${IMAGES.doublecheck}) no-repeat;
  background-size: contain;
  background-position: center;

  @media (min-width: ${desktop650}px) {
    flex-shrink: 0;
    align-self: center;
    width: 290px;
    height: 290px;
    margin-left: 30px;
    background: url(${IMAGES.doublecheckBig}) no-repeat;
  }

  @media (min-width: ${desktop940}px) {
    height: 142px;
    background-size: contain;
    background-position: center;
    margin-left: 0;
  }

  @media (min-width: ${desktop1100}px) {
    height: 162px;
    width: 100%;
    background-position: right;
  }
`;
