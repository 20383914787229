/** Библиотеки */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

/** Компоненты */
import {
  Button,
  defaultTheme,
  Link,
  Text,
  Checkbox,
} from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledCallbackWizardConfirm } from './CallbackWizardConfirm.style';

/** константы */
import { ERROR_AGREEMENT_TEXT } from '~/constants/common';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useCallbackStore from './store/useCallbackStore';

/** количество минут для отображения sidepage с успешным заказом обратного звонка */
const SIDEPAGE_VISIBLE_DELAY = 30;
/** время для отображения Sidepage в мс */
const VISIBLE_SUCCESS_DELAY = 5000;

interface CallbackWizardConfirmProps {
  closeBtnHandler: () => void;
}
/**
 * Блок с кнопкой для заказа обратного звонка
 * @returns {React.FC<CallbackWizardConfirmProps>}
 */
const CallbackWizardConfirm: FC<CallbackWizardConfirmProps> = ({
  closeBtnHandler,
}) => {
  const {
    isLoading,
    isCompleted,
    isVisibleTimeout,
    serverError,
    getCallback,
    setIsCompleted,
    setIsVisibleTimeout,
    isAgreementChecked,
    handleAgreementChecked,
    isErrorAgreement,
  } = useCallbackStore();

  const {
    agreementStore: { setIsShowAgreement },
  } = useRootStore();

  /** скроет SidePage через VISIBLE_SUCCESS_DELAY мс */
  useEffect(() => {
    let timerSuccessId;
    if (isVisibleTimeout) {
      timerSuccessId = setTimeout(() => {
        setIsVisibleTimeout(false);
        closeBtnHandler();
      }, VISIBLE_SUCCESS_DELAY);
    }
    return (): void => {
      if (timerSuccessId) clearTimeout(timerSuccessId);
    };
  }, [isVisibleTimeout]);

  /** показать Sidepage об успешном заказе обратного звонка в течении SIDEPAGE_VISIBLE_DELAY минут */
  useEffect(() => {
    const timeLastCallback = localStorage.getItem('timeCallback');
    if (timeLastCallback) {
      const minutesFromLastCallback =
        (new Date().getTime() - parseInt(timeLastCallback, 10)) / (1000 * 60);
      setIsCompleted(minutesFromLastCallback <= SIDEPAGE_VISIBLE_DELAY);
    }
  }, []);

  return (
    <>
      {!isCompleted && (
        <StyledCallbackWizardConfirm>
          {serverError !== null && (
            <Text className="server-error" color={defaultTheme.colors.planeta}>
              {serverError}
            </Text>
          )}
          <Text className="agreement__description">
            <Checkbox
              checked={isAgreementChecked}
              onChange={handleAgreementChecked}
              {...(isErrorAgreement && {
                error: ERROR_AGREEMENT_TEXT,
              })}
            >
              <Link
                color={defaultTheme.colors.planeta}
                onClick={() => setIsShowAgreement(true)}
              >
                Согласие
              </Link>
              &nbsp;на обработку персональных данных
            </Checkbox>
          </Text>
          <Button type="button" onClick={getCallback} loading={isLoading}>
            Заказать звонок
          </Button>
        </StyledCallbackWizardConfirm>
      )}
    </>
  );
};

export default observer(CallbackWizardConfirm);
