/** libraries */
import { FC } from 'react';
import { Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledWarning } from './styles';

interface WarningProps {
  text: string | React.ReactNode;
  className?: string;
}

const Warning: FC<WarningProps> = ({ text, className }: WarningProps) => {
  return (
    <StyledWarning className={className}>
      <Text lineHeight="24px">{text}</Text>
    </StyledWarning>
  );
};

export default Warning;
