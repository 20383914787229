export const ALL_TAG = {
  code: 'all',
  name: 'Все',
  productsList: [],
  sort: undefined,
  tag: 'all',
};

export const DEFAULT_TAG = 'sale';

export const ACTION_MAP = {
  Connect: {
    desktop: 'Подключить продукт',
    mobile: 'Подключить',
  },
  Change: {
    desktop: 'Изменить продукт',
    mobile: 'Изменить',
  },
};

/** Ошибки смены продукта */
export enum PRODUCT_CHANGE_ERRORS {
  /** Профилактические работы. ЛК не доступен */
  ERROR_MAINTENANCE = 'ErrorMaintenanceException',
  /** Невозможен переход на оптический продукт */
  ERROR_FTTH = 'FtthErrorException',
  /** Уже есть открытая заявка на смену продукта */
  ALREADY_EXIST = 'DemandAlreadyExistException',
  /** Недостаточно денег для перехода на выбранный продукт */
  NOT_ENOUGH_MONEY = 'TariffNotEnoughMoneyException',
  /** Сегодня уже выполнялась смена продукта. */
  TARIFF_SWITCHED_TODAY = 'TariffSwitchedTodayException',
  /** Договор не обслуживается */
  CONTRACT_NOT_SERVICED = 'ContractNotServicedException',
  /** Запрет перехода на продукт */
  TARIFF_SWITCH_DENY_FOR_CLIENT = 'TariffSwitchDenyForClientException',
  RETURN_STB = 'ReturnStbException',
  /** Запрет перехода с промопродукта на продукт, на который запланирован переход после окончания промопродукта */
  FROM_PROMO_TO_DEFAULT = 'TariffSwitchDenyFromPromoForClientCityException',
}
