import { checkClientIdAndBackUrl } from '~/api/api';

export const checkIgrovoy = async (
  clientId: string,
  backUrl: string,
  onValid: () => void,
  onFail: () => void,
): Promise<void> => {
  const isValid = await checkClientIdAndBackUrl(clientId, backUrl);
  if (!isValid) {
    onFail();
    return;
  }
  onValid();
};

export default checkIgrovoy;
