/** libraries */
import { useMemo } from 'react';
import { Text } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledSuspensionForUnknownReason } from './style';
/** utils */
import { getHeaderPhone } from '~/components/Header/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** components */
import SuspensionPaymentsList from '../SuspensionPaymentsList/SuspensionPaymentsList';

const SuspensionForUnknownReason = (): JSX.Element => {
  const {
    cityStore: { city, contactPhones },
    summaryDataStore: { summaryData },
    allowedTariffStore: { allowedTariff },
  } = useRootStore();
  // Номер телефона
  const phone = useMemo(() => {
    return getHeaderPhone(contactPhones, city);
  }, [contactPhones, city]);
  const tariff = useMemo(() => {
    return allowedTariff?.length
      ? allowedTariff.find(
          (item) => item.tariffTo.seriesCode === summaryData.seriesCode,
        )
      : null;
  }, [allowedTariff, summaryData]);
  return (
    <StyledSuspensionForUnknownReason>
      <Text lineHeight="24px">
        Если проблема сохраняется более 1 часа, пожалуйста, обратитесь в службу
        технической поддержки по телефону{' '}
        <Text lineHeight="24px" fontWeightBold>
          {phone}
        </Text>
        .
      </Text>
      {tariff && (
        <SuspensionPaymentsList linePriceOff={tariff.tariffTo.linePriceOff} />
      )}
    </StyledSuspensionForUnknownReason>
  );
};

export default observer(SuspensionForUnknownReason);
