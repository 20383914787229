/** библиотеки */
import { FC } from 'react';

/** компоненты библиотеки */
import { defaultTheme, H2, H3, Inset, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledPaymentInfo, StyledImg } from './PaymentInfo.style';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

const PaymentInfo: FC<AdvantagesContentProps> = ({ content }) => {
  const SUB_TITLE = 'Теперь можно';
  const TITLE = `Всегда быть на${'\u00A0'}связи`;
  const TEXT = 'Планета работает всегда! Даже при отрицательном балансе.';
  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledPaymentInfo>
        <div>
          <Inset className="sub_title">{SUB_TITLE}</Inset>
          <H2 className="title__mobile-wide">{TITLE}</H2>
          <H3 className="title">{TITLE}</H3>
          <Text className="text__mobile" color={defaultTheme.colors.shadow}>
            {TEXT}
          </Text>
          <Inset className="text" color={defaultTheme.colors.shadow}>
            {TEXT}
          </Inset>
        </div>
        <StyledImg />
      </StyledPaymentInfo>
    </LinkWrapper>
  );
};

export default PaymentInfo;
