/** Библиотеки */
import React, { FC } from 'react';
/** Стили */
import { StyledFooter } from './OperatingStateWizard.style';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** Типы */
import { OperatingStateWizardFooterProps } from './OperatingStateWizard.types';
import { VIEW_TYPES } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/constants';

const OperatingStateWizardFooter: FC<OperatingStateWizardFooterProps> = ({
  handleSubmit,
  viewType,
  isActionLoading,
  isTemporaryTokenAuth,
}: OperatingStateWizardFooterProps) => {
  return (
    <StyledFooter className="wizard__button">
      <Button
        type="button"
        onClick={handleSubmit}
        loading={isActionLoading}
        disabled={isTemporaryTokenAuth}
      >
        {viewType === VIEW_TYPES.PAUSE
          ? 'Приостановить'
          : 'Возобновить обслуживание'}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default React.memo(OperatingStateWizardFooter);
