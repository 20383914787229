/** libraries */
import { useEffect } from 'react';
/** constants */
import checkDataFetching from '~/components/Feed/useBlockDataFetching/config';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useConfig } from './useConfig';
import { useStatesResetAfterLogout } from './useStatesResetAfterLogout';

/** Хук загрузки данных для ленты  */
export const useBlockDataFetching = (blockTemplates: string[]): void => {
  const {
    authStore: { isAuth, isRemovedAuth },
  } = useRootStore();

  /** Получаем параметры вызова методов из сторов  */
  const dataFetchingSettings = useConfig(blockTemplates);
  const fetchData = async () => {
    await Promise.allSettled(
      dataFetchingSettings.map((settings) =>
        checkDataFetching(
          settings.condition,
          settings.blocks,
          blockTemplates,
          settings.action,
        ),
      ),
    );
  };

  /** Получаем коллекцию методов для сброса состояний сторов, после сброса авторизации  */
  const resetStateCallbacks = useStatesResetAfterLogout(blockTemplates);
  const resetStates = () => {
    resetStateCallbacks.map((callback) => callback());
  };

  useEffect(() => {
    if (isAuth) {
      (async () => {
        await fetchData();
      })();
    }
    if (isRemovedAuth) {
      resetStates();
    }
  }, [isAuth, blockTemplates, isRemovedAuth]);
};
