import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop300 } from '~/components/Grid/constants';

/** константы */
export const AuthWizardContainer = styled.div<{ isInCTA?: boolean }>`
  ${() => `
  .link__register {
    text-align: right;
    justify-content: flex-start;
  }
    
  .wizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: ${defaultTheme.colors.white};
    
    &__wrapper {
      width: 100%;
    }
    
    &__input {
      margin: 8px 0 16px;
      max-width: 100%;
      & input {
        max-width: 100%;
      }
    }

    &__input-password {
      margin: 8px 0 0;
    }
    
    &__label {
      width: 320px;
      max-width: 100%;
      justify-content: space-between;
      display: flex;
      label {
        justify-content: flex-start;
      }
    }
    
    &__button {
      text-align: center;
      margin-top: 8px;

      button {
        width: 320px;
        max-width: 100%;
        background: transparent;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      margin: 8px 0 8px;
    }

    &__error {
      margin-bottom: 16px;
    }

    &__server-error {
      display: block;
      width: 100%;
      margin-top: 16px;
    }

    &__server-error-extension {
      max-width: 370px;
      margin-top: 8px;
    }
  }
  

  @media (min-width: ${desktop300}px) {
    .wizard {      
      &__input {
       max-width: 100%;
      }
      
      &__label {
        display: flex;
      }
    }
  }
`}
`;
