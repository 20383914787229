/* Библиотеки */
import * as React from 'react';

/* Типы */
import { Text, H3, Icons, defaultTheme } from 'cordis-core-ui-planeta';
import { CallToActionAnnualProps } from './CallToActionAnnual.types';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';

/* Стили */
import {
  StyledLeftColumn,
  StyledRightColumn,
} from './CallToActionAnnual.style';

/* Утилиты */
import { formatNumber } from '~/utils/utils';

/* Дочерний компонент "Подключение на год", компонента CallToAction */
const CallToActionAnnual: React.FC<CallToActionAnnualProps> = ({
  annual,
}: CallToActionAnnualProps) => {
  return (
    <CallToActionRow title={<Text>Подключение на год</Text>}>
      <StyledLeftColumn>
        <H3>
          {annual === 0 ? 'Бесплатно' : `${formatNumber(annual)}\u0020\u20bd`}
        </H3>
      </StyledLeftColumn>
      <StyledRightColumn>
        <H3>
          {annual === 0 ? 'Бесплатно' : `${formatNumber(annual)}\u0020\u20bd`}
        </H3>
        <div>
          <Icons.OkIcon />
          {/* yaspeller ignore:start */}
          <Text color={defaultTheme.colors.shadow}>
            {'Один платёж в год\nи никаких забот'}
          </Text>
          {/* yaspeller ignore:end */}
        </div>
      </StyledRightColumn>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionAnnual);
