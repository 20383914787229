/** libraries */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Text,
  defaultTheme,
  Link,
  Checkbox,
} from 'cordis-core-ui-planeta';
/** styles */
import { WizardConfirmWrapper } from './WizardConfirm.style';
/** constants */
import { CALL_FROM_SIDEPAGE_KEY, CONNECTION_TYPE } from './constants';
import { ERROR_AGREEMENT_TEXT } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';

/** количество минут для отображения sidepage с успешным заказом обратного звонка */
const SIDEPAGE_VISIBLE_DELAY = 30;
/** время для отображения Sidepage в мс */
const VISIBLE_SUCCESS_DELAY = 5000;

const WizardConfirm: FC<{
  comment?: string;
  horizontal?: boolean;
  tariff?: string;
}> = ({ comment, horizontal, tariff }) => {
  const {
    createDemand,
    isDemandLoading,
    isTPAvailable,
    isVisibleTimeout,
    setIsVisibleTimeout,
    setCompleted,
    completed,
    serverError,
    isAgreementChecked,
    handleAgreementChecked,
    isErrorAgreement,
    setSelect,
    setStreet,
    setHouse,
    setApartment,
    select,
    connectionType,
    connectionWizardMoving,
    isAgreementNeed,
    setIsVisible,
  } = useConnectionStore();
  const {
    agreementStore: { setIsShowAgreement },
    cityStore: { city },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  /** скроет SidePage через VISIBLE_SUCCESS_DELAY мс */
  useEffect(() => {
    let timerSuccessId;
    if (isVisibleTimeout) {
      timerSuccessId = setTimeout(() => {
        setIsVisibleTimeout(false);
        setIsVisible(false);
      }, VISIBLE_SUCCESS_DELAY);
    }
    return (): void => {
      if (timerSuccessId) clearTimeout(timerSuccessId);
    };
  }, [isVisibleTimeout]);

  /** показать Sidepage об успешном заказе обратного звонка в течении SIDEPAGE_VISIBLE_DELAY минут */
  useEffect(() => {
    const timeLastDemand = localStorage.getItem(
      tariff ? `timeDemand:${tariff}` : 'timeDemand',
    );
    if (timeLastDemand) {
      const minutesFromLastDemand =
        (new Date().getTime() - parseInt(timeLastDemand, 10)) / (1000 * 60);
      setCompleted(
        tariff ?? CALL_FROM_SIDEPAGE_KEY,
        minutesFromLastDemand <= SIDEPAGE_VISIBLE_DELAY,
      );
    }
  }, []);

  useEffect(() => {
    if (completed?.get(tariff ?? CALL_FROM_SIDEPAGE_KEY)) return;
    /** обновит значение для select */
    if (String(city.id) !== select.value) {
      setSelect({ value: String(city.id), label: city.name });
    }
    /** очистит поля улицы, дома, квартиры */
    setStreet('');
    setHouse('');
    setApartment('');
  }, [city.id, completed]);

  const buttonHandler = () => {
    /** Создаем заявку в РИС на переезд */
    if (connectionType === CONNECTION_TYPE.moving)
      return connectionWizardMoving(comment);

    return createDemand(isTPAvailable, comment, tariff);
  };

  return (
    <>
      {completed && !completed?.get(tariff ?? CALL_FROM_SIDEPAGE_KEY) && (
        <WizardConfirmWrapper horizontal={horizontal}>
          {serverError !== null && (
            <Text className="server-error" color={defaultTheme.colors.planeta}>
              {serverError}
            </Text>
          )}
          {isAgreementNeed && (
            <Text className="agreement__description">
              <Checkbox
                checked={isAgreementChecked}
                onChange={handleAgreementChecked}
                {...(isErrorAgreement && {
                  error: ERROR_AGREEMENT_TEXT,
                })}
              >
                {connectionType === CONNECTION_TYPE.friend ? (
                  `Друг уведомлен и${'\u00A0'}согласен на${'\u00A0'}передачу его контактов и${'\u00A0'}иных необходимых данных в${'\u00A0'}Планету. Вы согласны на${'\u00A0'}сообщение другу своих ФИО и/или${'\u00A0'}номера договора.`
                ) : (
                  <Text>
                    <Link
                      color={defaultTheme.colors.planeta}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsShowAgreement(true);
                      }}
                    >
                      Согласие
                    </Link>
                    &nbsp;на обработку персональных данных
                  </Text>
                )}
              </Checkbox>
            </Text>
          )}
          <Button
            type="button"
            loading={isDemandLoading}
            onClick={buttonHandler}
            disabled={isTemporaryTokenAuth}
          >
            Отправить заявку
          </Button>
          {isTemporaryTokenAuth && (
            <Text
              style={{ marginTop: '10px', alignSelf: 'start' }}
              color={defaultTheme.colors.warning}
            >
              Действие доступно только клиенту
            </Text>
          )}
        </WizardConfirmWrapper>
      )}
    </>
  );
};

export default observer(WizardConfirm);
