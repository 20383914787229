/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';

/** Стилизованные компоненты */
import { StyledSidePage } from '../Pab2cStyle';

/** Компоненты  */
import DevicePurchasesWizard from '../Components/DevicePurchasesWizard/DevicePurchasesWizard';
import DevicePurchasesWizardFooter from '../Components/DevicePurchasesWizard/DevicePurchasesWizardFooter';

/** Константы */
import {
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../stores/useDevicesStore';

/**
 * Блок pab2cDevices. SidePage покупки оборудования за полную стоимость/в рассрочку
 */
const DevicePurchasesSP: FC = () => {
  const {
    result,
    resetState,
    isPurchaseSucceeded,
    requestsState: { devicePurchase },
    detailedDeviceStore: { detailedDevice, deviceOwnership },
    devicePurchasesStore: {
      isLease,
      isDevicePurchasesWizardShow,
      resetPurchaseStore,
    },
  } = useDevicesStore();

  const {
    pab2cDevicesStore: { updatePab2cDevices },
    pab2cBalanceStore: { getBalance },
  } = useRootStore();

  const headerText = () => {
    const postFix = isLease ? 'в рассрочку' : '';
    if (deviceOwnership === OWNERSHIP_CODES.LEASING)
      return `Купить ${DEVICE_TYPES_GENITIVE[
        detailedDevice?.deviceTypeCode
      ]?.toLowerCase()}  ${detailedDevice.modelName} ${postFix}`;
    return '';
  };

  const handleClick = async () => {
    resetState();
    resetPurchaseStore();
    /** Обновить список оборудования и баланс при успешной покупке */
    if (isPurchaseSucceeded) {
      await updatePab2cDevices();
      setTimeout(getBalance, 4000);
      devicePurchase.reset();
    }
  };

  return (
    <StyledSidePage
      $isHideHeader={result.isResult}
      show={isDevicePurchasesWizardShow}
      headerText={headerText()}
      onCloseClick={handleClick}
      footerContainer={
        !result.isResult ? <DevicePurchasesWizardFooter /> : <></>
      }
      isOnlyMobileFooter
    >
      <DevicePurchasesWizard />
    </StyledSidePage>
  );
};

export default observer(DevicePurchasesSP);
