import styled from '@emotion/styled';
import { desktop940 } from '~/components/Grid/constants';

export const StyledNewAppeal = styled.div`
  margin: 32px 0 0;
  .new {
    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 8px;
      }
      > span[data-test-select] {
        width: 100%;
      }

      textarea {
        width: -webkit-fill-available;
        height: 176px;
      }

      &__textarea-error {
        margin-top: 14px;
      }

      input {
        width: -webkit-fill-available;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .new {
      &__block {
        > span[data-test-select] {
          width: 330px;
        }
        input {
          width: 330px;
        }
      }
    }
  }
`;
