/** libraries */
import { createContext, useContext } from 'react';
/** components */
import DeviceAfterAuthModel, {
  IDeviceAfterAuthStore,
} from './DeviceAfterAuthStore';

const store = DeviceAfterAuthModel.create({
  deviceId: null,
  devicePurchaseState: null,
});

const StoreContext = createContext<IDeviceAfterAuthStore>(store);

const useDeviceAfterAuthStore = () => {
  return useContext(StoreContext);
};

export default useDeviceAfterAuthStore;
