/** библиотеки */
import * as React from 'react';

/** компоненты библиотеки */
import { Link, LinkStyleTypes } from 'cordis-core-ui-planeta';

/** типы */
import { LegalInfo } from '../Legal.types';

/** константы */
import { DOCUMENTS_LINK } from '~/constants/common';

interface BusinessTariffsTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона BusinessTariffsText
 * @param {BusinessTariffsTextProps} content
 * @returns {React.FC}
 */
const BusinessTariffsText: React.FC<BusinessTariffsTextProps> = ({
  content,
}: BusinessTariffsTextProps) => {
  return (
    <>
      {content.isBundleB2B && (
        <>
          <p>
            Подробное описание продукта в{' '}
            <Link
              styleType={LinkStyleTypes.SECONDARY}
              href={DOCUMENTS_LINK}
              target="_blank"
            >
              Прейскуранте
            </Link>
            .
          </p>
          <p>
            Ознакомьтесь с нашими продуктами для среднего бизнеса и корпораций
            на портале{' '}
            <Link styleType={LinkStyleTypes.SECONDARY}>MiraLogic</Link>.
          </p>
        </>
      )}
    </>
  );
};

export default BusinessTariffsText;
