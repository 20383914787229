/** libraries */
import React, { ReactNode, useContext } from 'react';
import { Provider, MobXProviderContext, useLocalObservable } from 'mobx-react';
/** interfaces */
import { FieldsProps } from '../interfaces';
/** components */
import createStore, { IDevicesStore } from './DevicesStore';

interface DevicesProviderProps {
  fields: FieldsProps;
  children: ReactNode;
  size?: number;
}

export const DevicesStoreProvider = ({
  fields,
  children,
  size,
}: DevicesProviderProps): JSX.Element => {
  const store = useLocalObservable(() => createStore(fields, size));
  return <Provider DevicesStore={store}>{children}</Provider>;
};

export const useDevicesStore = (): IDevicesStore => {
  const { DevicesStore } = useContext(MobXProviderContext);
  return DevicesStore;
};
