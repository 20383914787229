/** константы */
import {
  BONUS_SLUG,
  CONTACTS_LINK,
  DEVICES,
  DOCUMENTS_LINK,
  GIGABIT_SLUG,
  HELP_PLANETA_URL,
  INTERNET_SLUG,
  JOB_PLANETA_URL,
  NEWS_SLUG,
  OVERINTERNET_SLUG,
  OVERTV_SLUG,
  PAYMENT_SLUG,
  Pab2cSlugs,
  SMALL_BUSINESS_SLUG,
  SOFT_SLUG,
  TVNET_SLUG,
  TV_PLANETA_URL,
  TV_SLUG,
  VOICE_SLUG,
  WINK_PLANETA_URL,
} from '~/constants/common';
/** типы */
import { ProductCard, Folder } from './MenuPopup.types';

/** список ссылок на категории */
export const PRODUCTS_LIST: ProductCard[] = [
  {
    title: 'СверхИнтернет',
    subTitile: 'Оптический Гигабит',
    href: `/${OVERINTERNET_SLUG}`,
    descrption:
      'Флагманская технология Планеты - будь в сети на рекордной скорости до 1 Гбит/с!',
  },
  {
    title: 'СверхТелевидение',
    subTitile: 'Оптический Гигабит + 4K',
    href: `/${OVERTV_SLUG}`,
    descrption:
      'Сверхчёткое ТВ и сверхбыстрый интернет – сверхвозможности передовых технологий!',
  },
  {
    title: 'Интернет + ТВ',
    subTitile: '',
    href: `/${TVNET_SLUG}`,
    descrption:
      'Максимальный выбор телеканалов и надёжный интернет на скорости до 100 Мбит/с',
  },
  {
    title: 'Интернет',
    subTitile: '',
    href: `/${INTERNET_SLUG}`,
    descrption: 'Безлимитный интернет до 100 Мбит/с по комфортной цене',
  },
  {
    title: 'Малый бизнес',
    subTitile: '',
    href: `/${SMALL_BUSINESS_SLUG}`,
    descrption: 'Продукты для малого бизнеса, стартапа и домашнего офиса',
  },
];

export const FOLDERS: Folder[] = [
  {
    name: 'Планета',
    subFolders: [
      {
        name: 'Твои СверхВозможности',
        href: '/sverh',
      },
      {
        name: 'Пополнить баланс',
        href: `/${PAYMENT_SLUG}`,
      },
      {
        name: 'Акции и скидки',
        href: `/${NEWS_SLUG}#actions`,
      },
      {
        name: 'Новости',
        href: `/${NEWS_SLUG}`,
      },
      {
        name: 'Подобрать продукт',
        href: `/${Pab2cSlugs.CHANGE_PLAN_SLUG}`,
      },
      {
        name: 'Бонусная программа',
        href: `/${BONUS_SLUG}`,
      },
      {
        name: 'Условия оказания услуг',
        href: DOCUMENTS_LINK,
      },
      {
        name: 'Справка и помощь',
        href: HELP_PLANETA_URL,
      },
      {
        name: 'Контакты',
        href: CONTACTS_LINK,
      },
      {
        name: 'Вакансии',
        href: JOB_PLANETA_URL,
      },
    ],
  },
  {
    name: 'Телевидение',
    subFolders: [
      {
        name: 'Цифровое телевидение 4К',
        href: `/${TV_SLUG}`,
      },
      {
        name: 'Телеканалы',
        href: `/${TV_SLUG}/channels`,
      },
      {
        name: 'Телеприставки',
        href: `/${DEVICES}#stb`,
      },
      {
        name: 'Портал ЦТВ',
        href: TV_PLANETA_URL,
      },
      {
        name: 'Wink',
        href: WINK_PLANETA_URL,
      },
    ],
  },
  {
    name: 'Интернет',
    subFolders: [
      {
        name: 'Оптический Гигабит',
        href: `/${GIGABIT_SLUG}`,
      },
      {
        name: 'Wi-Fi маршрутизаторы',
        href: `/${DEVICES}#router`,
      },
      {
        name: 'Антивирусы',
        href: `/${SOFT_SLUG}`,
      },
      {
        name: 'Умный дом',
        href: `/${DEVICES}#home`,
      },
    ],
  },
  {
    name: 'Голосовая связь',
    subFolders: [
      {
        name: 'Городской номер',
        href: `/${VOICE_SLUG}`,
      },
      {
        name: 'SIP-телефон',
        href: `/${DEVICES}#phone`,
      },
    ],
  },
];
