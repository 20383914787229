/** Константы */
import { PLANETA_PHONE, contactPhoneType } from './constants';
/** Интерфейсы */
import { City } from '~/interfaces/CityInterface';
import { ContactPhone } from '~/interfaces/ContactPhone.interface';

/** Формирует номер телефона из списка переданных из контекста номеров */
export const getHeaderPhone = (
  contactPhones: ContactPhone[],
  city: City,
): string =>
  !contactPhones?.length
    ? PLANETA_PHONE
    : contactPhones.filter(
        (item: ContactPhone) =>
          item.cityId === city.id &&
          item.isDefault === true &&
          item.type === contactPhoneType.MANAGER,
      )[0].phone;
