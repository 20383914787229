import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledPhones = styled.div`
  display: flex;
  flex-direction: column;

  .phone {
    &__not-found {
      > span {
        display: block;
        margin-bottom: 16px;
      }
    }
    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 !important;
    }

    &__error,
    &__not-found,
    &__loader {
      margin-top: 24px;
    }

    &__mobile-select {
      height: 574px;
      position: relative;
      margin-top: -50px;
      background: ${defaultTheme.colors.white};
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__tag-button {
        height: 52px;
      }

      button {
        width: 100%;
        position: absolute;
        bottom: 32px;
      }
    }

    &__info {
      &__price-block {
        margin-bottom: 16px;
        &__header {
          margin-bottom: 6px;
          > span:first-of-type {
            margin-right: 16px;
          }
        }
        h3 {
          margin: 0;
        }
      }

      &__instructions {
        > span:first-of-type {
          margin-bottom: 8px;
        }
      }

      &__select {
        width: 100%;
        > div {
          width: 100%;
        }
        .select-wrapper {
          width: 100%;
        }
        .select-overlay {
          width: inherit;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    > div:first-of-type {
      margin-right: 32px;
    }
    .phone {
      &__loader {
        height: 255px;
      }
      &__info {
        width: 248px;
        &__select {
          width: 248px;
          .select-wrapper {
            width: 248px;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .phone {
      &__info {
        width: 307px;
        &__select {
          width: 307px;
          .select-wrapper {
            width: 307px;
          }
        }
      }
      &__settingUp {
        margin-top: 10px;
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .phone {
      &__info {
        width: 367px;
        &__price-block {
          margin-bottom: 20px;
        }
      }

      &__settingUp {
        margin-top: 11px;
      }
    }
  }
`;

export const StyledNumbers = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 16px 0 0;
  .numbers {
    &__block {
      display: flex;
      flex-direction: column;
      margin-right: 12px;
      margin-bottom: 16px;

      > span:first-of-type {
        border-bottom: 1px solid ${defaultTheme.colors.gray};
        padding-bottom: 4px;
        margin-bottom: 4px;
        line-height: 20px;
        font-size: 16px;
      }

      > span:nth-of-type(2) {
        letter-spacing: -0.01em;
      }
    }

    &__block:last-of-type {
      margin-right: 0;
    }

    &__block-without-border {
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      margin-bottom: 16px;
      width: 107px;
      > span:first-of-type {
        margin-bottom: 4px;
        line-height: 20px;
        font-size: 16px;
      }
    }

    &__block-without-border:last-of-type {
      margin-right: 0;
    }
  }

  .local {
    width: 108px;
  }

  .russia:first-of-type {
    width: 64px;
  }

  .russia:nth-of-type(2) {
    width: 80px;
  }

  .russia:nth-of-type(3) {
    width: 143px;
  }

  .international:first-of-type {
    width: 64px;
  }

  .international:nth-of-type(2) {
    width: 60px;
  }

  .international:nth-of-type(3) {
    width: 80px;
  }

  .international:nth-of-type(4) {
    width: 143px;
  }

  @media (min-width: ${desktop940}px) {
    height: 60px;
    margin: 8px 0 8px;
    .numbers {
      &__block {
        margin-bottom: 0;
      }
      &__block-without-border {
        width: 53px;
        margin-right: 12px;
        margin-bottom: 0;
      }
    }
    .local {
      width: 108px;
    }

    .russia:nth-of-type(2) {
      width: 60px;
    }

    .russia:nth-of-type(3) {
      width: 88px;
    }

    .international:first-of-type {
      width: 40px;
    }

    .international:nth-of-type(2) {
      width: 39px;
    }

    .international:nth-of-type(3) {
      width: 64px;
    }

    .international:nth-of-type(4) {
      width: 66px;
    }
  }
  @media (min-width: ${desktop1100}px) {
    .numbers {
      &__block-without-border {
        width: 57px;
      }
    }
  }
  @media (min-width: ${desktop1280}px) {
    height: 73px;
    margin-top: 16px;

    .numbers {
      &__block {
        > span:first-of-type {
          line-height: 25px;
          font-size: 21px;
          padding-bottom: 9px;
          margin-bottom: 8px;
        }
      }

      &__block-without-border {
        width: 82px;
        > span:first-of-type {
          margin-bottom: 8px;
          line-height: 25px;
          font-size: 21px;
        }
      }
    }

    .local {
      width: 108px;
    }

    .russia:first-of-type {
      width: 64px;
    }

    .russia:nth-of-type(2) {
      width: 60px;
    }

    .russia:nth-of-type(3) {
      width: 108px;
    }

    .international:first-of-type {
      width: 64px;
    }

    .international:nth-of-type(2) {
      width: 39px;
    }

    .international:nth-of-type(3) {
      width: 79px;
    }

    .international:nth-of-type(4) {
      width: 88px;
    }
  }
`;

export const StyledConnectedNumbers = styled.div`
  margin: 24px 0 40px;
  .connected {
    &__header {
      display: block;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      border-bottom: 1px solid ${defaultTheme.colors.line};
      padding-bottom: 16px;
      margin-bottom: 16px;
    }

    &__phones-block {
      &__item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid ${defaultTheme.colors.line};
        padding-bottom: 16px;
        margin-bottom: 16px;

        > div {
          margin-bottom: 4px;
        }
        &__phone {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          > span:first-of-type {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-right: 14px;
            letter-spacing: -0.03em;
          }

          > span:nth-of-type(2) {
            display: -webkit-inline-box;
            cursor: pointer;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &__not-unbind {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0;
          > span:first-of-type {
            margin-right: 8px;
          }
        }
      }
    }

    &__cursor-pointer {
      cursor: pointer;
    }
  }

  @media (min-width: ${desktop940}px) {
    width: 248px;
    margin: 0;
    .connected {
      &__header {
        line-height: 24px;
        font-weight: 700;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 22px;
      }

      &__phones-block {
        height: 218px;
        overflow-x: hidden;
        &__item {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 22px;

          > span {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.03em;
          }
          &__phone {
            justify-content: start;
            flex-wrap: nowrap;

            > span:first-of-type {
              width: 143px;
              line-height: 20px;
              font-weight: 500;
            }

            > span:nth-of-type(2) {
              line-height: 24px;
              font-size: 16px;
              letter-spacing: -0.03em;
            }
          }

          &__not-unbind {
            > span {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.03em;
            }
          }
        }
      }

      &__phones-block::-webkit-scrollbar {
        width: 2px;
      }

      &__phones-block::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 6px;
      }

      &__phones-block::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: ${defaultTheme.colors.disable};
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    width: 307px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 367px;

    .connected {
      &__phones-block {
        &__item {
          &__phone {
            > span:first-of-type {
              width: 170px;
              font-size: 21px;
              line-height: 25px;
            }

            > span:nth-of-type(2) {
            }
          }
        }
      }
    }
  }
`;
