import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';
import Td from '~/components/Blocks/Templates/Pab2c/Contract/Components/Table/Td';

export const StyledFinancialCalculations = styled.div`
  min-height: 528px;
  .finance {
    &__select {
      display: flex;
      flex-direction: column;
      margin: 32px 0;

      > div {
        margin: 0 0 16px;
        & > div {
          display: block;
        }
      }

      .select-wrapper,
      .select-overlay {
        width: 100%;
      }
    }

    &__test-button {
      margin-top: 32px;
    }

    &__mobile-select {
      height: 85vh;
      position: relative;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__tag-button {
        height: 52px;
      }

      input {
        &:disabled ~ .tagButton__text {
          color: ${defaultTheme.colors.lightGrey};
        }
      }

      button {
        width: 100%;
        position: absolute;
        bottom: 32px;
      }
    }

    &__table {
      &__date {
        margin-bottom: 4px;
      }
      &__mobile {
        &__total {
          border-top: 1px solid ${defaultTheme.colors.disable};
          padding: 24px 0;
          &__header {
            margin-bottom: 8px;
          }
          &__balance {
            margin-top: 8px;
          }
        }
      }

      &__total {
        margin-bottom: 8px;
      }
    }

    &__select-period {
      display: flex;
      flex-direction: column;

      > span:first-of-type {
        margin-bottom: 12px;
      }
    }

    &__cursor {
      cursor: pointer;

      > span:hover {
        text-decoration: underline;
      }
    }

    &__cursor-default {
      cursor: default;

      > span:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .finance {
      &__select {
        display: flex;
        flex-direction: row;

        > div {
          margin: 0 16px 0 0;
        }

        .select-wrapper {
          width: 225.5px;
        }
      }
    }
  }
`;

export const StyledLineName = styled(Td)`
  padding: 12px 20px 12px 0;
`;

export const StyledReceipts = styled.div`
  min-height: 528px;
  .table__border {
    border-bottom: 0px;
    margin: 0;
  }
`;
