/** библиотеки */
import * as React from 'react';

/** компоненты библиотеки */
import { Link, LinkStyleTypes } from 'cordis-core-ui-planeta';

/** типы */
import { LegalInfo } from '../Legal.types';

/** утилиты */
import { getDevicesString } from './utils';

/** константы */
import { DOCUMENTS_LINK } from '~/constants/common';

interface DeviceLeaseTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона DeviceLeaseText
 * @param {DeviceLeaseTextProps} content
 * @returns {React.FC}
 */
const DeviceLeaseText: React.FC<DeviceLeaseTextProps> = ({
  content,
}: DeviceLeaseTextProps) => {
  return (
    <>
      {content.hasLease && (
        <p>
          Предоставление оборудования в рассрочку на данном продукте возможно в
          соответствии с условиями{' '}
          <Link
            styleType={LinkStyleTypes.SECONDARY}
            href={DOCUMENTS_LINK}
            target="_blank"
          >
            Прейскуранта
          </Link>
          . одновременно в рассрочку можно приобрести{' '}
          {getDevicesString(content.itemMaxQuantityList)}
        </p>
      )}
    </>
  );
};

export default DeviceLeaseText;
