/** Библиотеки */
import styled from '@emotion/styled';

/** Константы */
import { desktop940 } from '~/components/Grid/constants';

/** Props компонента Row */
interface RowProps extends React.HTMLProps<HTMLDivElement> {
  /** без отступа */
  noPadding?: boolean;
  /** цвет фона */
  backgroundColor?: string;
}

const paddingNextToRows = '20px';
const paddingBetweenRows = '16px';

/**
 * Контейнер для отображения компонентов Row
 */
export const Row = styled.div<RowProps>`
  display: block;
  padding: ${paddingBetweenRows}
    ${(props) => (props.noPadding ? 0 : paddingNextToRows)};
  width: 100vw;
  background-color: ${(props) => props.backgroundColor};

  @media (min-width: ${desktop940}px) {
    display: flex;
    justify-content: center;
  }
`;
