/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

/** компоненты библиотеки */
import { H2, H3, defaultTheme, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import {
  StyledAvatar,
  StyledAvatarImg,
  StyledBubble,
  StyledImg,
} from './Avatar.style';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { AvatarContentProps } from './Avatar.types';

/** константы */
import { desktop450 } from '~/components/Grid/constants';

/**
 * Блок "Аватар"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=607360731
 * @param content
 */
const Avatar: FC<AvatarContentProps> = ({ content }) => {
  // Вычисление ширины экрана
  const isMinDesktop450 = useMediaQuery({
    query: `(min-width: ${desktop450}px)`,
  });

  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledAvatar>
        <StyledBubble isQuote={content.fields?.personal}>
          {content.fields?.personal ? (
            <>
              {(content.size === 1 ||
                (content.size === 2 && !isMinDesktop450)) && (
                <H3
                  className="title"
                  isStories
                  color={defaultTheme.colors.white}
                  dangerouslySetInnerHTML={{ __html: content.fields?.quote }}
                />
              )}
              {content.size === 2 && isMinDesktop450 && (
                <H2
                  isStories
                  color={defaultTheme.colors.white}
                  dangerouslySetInnerHTML={{ __html: content.fields?.quote }}
                />
              )}

              <div className="personal">
                <div className="personal__avatar">
                  <StyledAvatarImg img={content.fields.imgLink} />
                </div>
                <div className="personal__body">
                  <Text className="head" color={defaultTheme.colors.white}>
                    {content.fields.header}
                  </Text>
                  <Text
                    className="text__mobile"
                    color={defaultTheme.colors.white}
                    dangerouslySetInnerHTML={{ __html: content.fields.text }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <Text className="head" color={defaultTheme.colors.white}>
                {content.fields.header}
              </Text>
              <Text className="text__mobile" color={defaultTheme.colors.white}>
                {content.fields.text}
              </Text>
            </>
          )}
        </StyledBubble>
        {!content.fields?.personal && (
          <StyledImg img={content.fields.imgLink} />
        )}
      </StyledAvatar>
    </LinkWrapper>
  );
};

export default Avatar;
