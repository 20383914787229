import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export default styled.div`
  background: ${defaultTheme.colors.white};
  padding: 32px;
  display: flex;
  flex-direction: column;

  svg {
    width: 125px;
    height: 125px;
  }

  span + h3 {
    margin: 32px 0;
  }

  span + a,
  div:last-of-type {
    margin-top: 32px;
  }
`;
