/** libraries */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
import {
  H3,
  Icons,
  LinkButton,
  defaultTheme,
  Text,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledHelp } from './styles';
/** utils */
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import {
  CONTACTS_LINK,
  DOCUMENTS_LINK,
  HELP_PLANETA_URL,
} from '~/constants/common';
import { DEMAND_QP } from '~/components/Blocks/Templates/Pab2c/Help/constants';
/** api */
import { getDemandUnreadCount } from '~/api/apiPab2c';
/** store */
import useMyAppealsStore from '~/components/MyAppealsSidePage/store/useMyAppealsStore';
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/** Блок «ЛК. Помощь» */
const Help = (): JSX.Element => {
  const {
    authStore: { isTemporaryTokenAuth, isAuth },
  } = useRootStore();
  const { toggleVisible, isVisible } = useMakeAuthStore();
  const { setIsShowMyAppeals, isRead } = useMyAppealsStore();
  const router = useRouter();

  /**
   * вызывает клик по кнопке открытия чата
   */
  const openChat = (): void => {
    if (isTemporaryTokenAuth) return;
    const button = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (button) button.click();
  };

  /** Число непрочитанных комментариев */
  const [unreadDemandCount, setUnreadDemandCount] = useState<number>(0);
  useEffect(() => {
    if (!isAuth) return;
    (async () => {
      const res = await getDemandUnreadCount();
      setUnreadDemandCount(res);
    })();
  }, [isRead, isAuth]);

  useEffect(() => {
    if (!router.isReady || !router.query?.[DEMAND_QP]) return;
    if (isVisible) toggleVisible();
    setIsShowMyAppeals(true);
  }, [router.isReady]);

  return (
    <StyledHelp>
      <H3>Помощь</H3>
      <div className="help__blocks">
        <div className="help__action-block">
          <Icons.ListIcon />
          <LinkWrapper href={HELP_PLANETA_URL}>
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              Справочный портал
            </Text>
          </LinkWrapper>
        </div>
        <div className="help__action-block">
          <Icons.OkIcon />
          <LinkButton onClick={() => setIsShowMyAppeals(true)}>
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              Мои обращения{' '}
              {unreadDemandCount !== 0 && `(${unreadDemandCount})`}
            </Text>
          </LinkButton>
        </div>
        <div className="help__action-block">
          <div className="help__chat-icon">
            <Icons.ChatIcon />
          </div>
          <LinkButton className="help__chat-link" onClick={openChat}>
            <Text
              lineHeight="24px"
              color={
                isTemporaryTokenAuth
                  ? defaultTheme.colors.disable
                  : defaultTheme.colors.planeta
              }
            >
              Онлайн чат
            </Text>
          </LinkButton>
        </div>
        <div className="help__action-block">
          <Icons.TelephoneIcon />
          <LinkWrapper href={CONTACTS_LINK}>
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              Контакты Планеты
            </Text>
          </LinkWrapper>
        </div>
        <div className="help__action-block">
          <Icons.DocumentIcon />
          <LinkWrapper href={DOCUMENTS_LINK}>
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              Условия обслуживания
            </Text>
          </LinkWrapper>
        </div>
      </div>
    </StyledHelp>
  );
};

export default observer(Help);
