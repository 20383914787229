/** библиотеки */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledSidePageResult = styled.div`
  .result {
    &-icon {
      margin: -51px 0 26.5px;

      & svg,
      svg * {
        stroke: ${defaultTheme.colors.planeta};
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .result {
    }
  }
`;
