/** libraries */
import { Dispatch, FC, SetStateAction } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import {
  Button,
  H3,
  LeadingText,
  Snoska,
  defaultTheme,
  Text,
  Link,
  Loader,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import {
  HeaderDesktop,
  HeaderMobile,
  StyledSbpBindingSuggestion,
} from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { DOCUMENTS_LINK } from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';
/** interfaces */
import { SbpInfo, SbpRegisterBindingInfo } from '../../interfaces';
/** components */
import Timer from '~/components/Blocks/Shared/Timer/Timer';
/** stores */
import useListOfSbpBanksStore from '~/components/Blocks/Shared/ListOfSbpBanks/store/useListOfSbpBanksStore';

interface SbpBindingSuggestionProps {
  contractNumber: string;
  bind: () => void;
  sbpBinding: SbpInfo | SbpRegisterBindingInfo;
  isError?: boolean;
  timeIsUp: boolean;
  setTimeIsUp: Dispatch<SetStateAction<boolean>>;
}

/** Привязать счёт */
const SbpBindingSuggestion: FC<SbpBindingSuggestionProps> = ({
  contractNumber,
  bind,
  sbpBinding,
  isError,
  timeIsUp,
  setTimeIsUp,
}: SbpBindingSuggestionProps) => {
  const { setIsListOfSbpBanksOpen } = useListOfSbpBanksStore();
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  return (
    <StyledSbpBindingSuggestion>
      {isError || timeIsUp ? (
        <>
          {isDesktop940 ? (
            <H3 className="header error">
              Не удалось привязать счёт СБП. Попробуйте ещё раз
            </H3>
          ) : (
            <LeadingText
              className="header error"
              color={defaultTheme.colors.black}
            >
              Не удалось привязать счёт СБП. Попробуйте ещё раз
            </LeadingText>
          )}
        </>
      ) : (
        <>
          {isDesktop940 ? (
            <HeaderDesktop className="header">
              Привяжите счёт СБП для будущих оплат договора{' '}
              {contractNumber ?? ''}
            </HeaderDesktop>
          ) : (
            <HeaderMobile className="header" color={defaultTheme.colors.black}>
              Привяжите счёт для будущих оплат договора {contractNumber ?? ''}
            </HeaderMobile>
          )}
        </>
      )}
      {sbpBinding && !timeIsUp ? (
        <div className="result__bind">
          <div className="result__bind__qr">
            <div>
              <Image
                src={`data:image/png;base64, ${sbpBinding.qrImage}`}
                alt="qrcode"
                width={212}
                height={212}
                quality={100}
              />
            </div>
            <div className="result__bind__qr__text-block">
              <Text lineHeight="24px">
                Отсканируйте QR-код камерой телефона
                <br />
                и&nbsp;завершите оплату в&nbsp;мобильном приложении банка.
              </Text>
              <div className="result__bind__qr__text-block__timer">
                <Text lineHeight="24px">Ожидаем ответ от вашего банка:</Text>
                <div className="result__bind__qr__text-block__timer__loader">
                  <Loader small />
                  <Timer setTimeIsUp={setTimeIsUp} />
                </div>
              </div>
            </div>
          </div>
          <div className="result__bind__qr__warning">
            <Text lineHeight="24px">
              <Text lineHeight="24px" fontWeightBold>
                Нельзя привязать счёт&nbsp;СБП
              </Text>{' '}
              в&nbsp;банковских приложениях некоторых банков.
              <br />
              Ознакомьтесь со{'\u00A0'}
              <Text
                className="result__bind__qr__warning__banks"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={() => setIsListOfSbpBanksOpen(true)}
              >
                списком банков
              </Text>
              , поддерживающих подписки СБП.
            </Text>
          </div>
          <div className="snoska">
            <Snoska>
              Сканируя QR-код, вы&nbsp;подтверждаете согласие с&nbsp;условиями{' '}
              привязки счёта, указанными в{'\u00A0'}
              <Link href={DOCUMENTS_LINK} target="_blank">
                Регламенте
              </Link>
              .
            </Snoska>
          </div>
        </div>
      ) : (
        <>
          <div className="button-block">
            <Button onClick={bind}>Привязать счёт СБП</Button>
            {isDesktop940 && (
              <div className="info">
                <Snoska>
                  Нажатием кнопки вы подтверждаете согласие
                  <br />
                  с&nbsp;условиями привязки счёта, указанными в{'\u00A0'}
                  <Link href={DOCUMENTS_LINK} target="_blank">
                    Регламенте
                  </Link>
                  .
                </Snoska>
              </div>
            )}
          </div>
          <div className="warning">
            <Text lineHeight="24px">
              <Text lineHeight="24px" fontWeightBold>
                Нельзя привязать счёт&nbsp;СБП
              </Text>{' '}
              в&nbsp;банковских приложениях некоторых банков.
              <br />
              Ознакомьтесь со{'\u00A0'}
              <Text
                className="warning__banks"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={() => setIsListOfSbpBanksOpen(true)}
              >
                списком банков,
              </Text>{' '}
              поддерживающих подписки СБП.
            </Text>
          </div>

          {!isDesktop940 && (
            <Snoska className="snoska">
              Нажатием кнопки вы подтверждаете согласие с{'\u00A0'}условиями
              привязки счёта, указанными в{'\u00A0'}
              <Link href={DOCUMENTS_LINK} target="_blank">
                Регламенте.
              </Link>{' '}
              Вы будете перенаправлены в{'\u00A0'}приложение банка.
            </Snoska>
          )}
        </>
      )}
    </StyledSbpBindingSuggestion>
  );
};

export default observer(SbpBindingSuggestion);
