/** libraries */
import React, { FC, useMemo, useState } from 'react';
import {
  H1,
  Icons,
  Input,
  LeadingText,
  SidePage,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledPasswordSidePage, StyledResult } from './styles';
/** components */
import PasswordFooter from './PasswordFooter';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';
/** interfaces */
import { ResultProps } from '../../interfaces';
/** api */
import { generatePassword, setPhonePassword } from '~/api/apiPab2c';
/** constants */
import { DEFAULT_ERROR, DEFAULT_RESULT } from '~/constants/common';
/** utils */
import { maskHomePhone } from '../../utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from '../../store/useVoiceStore';

const PasswordSidePage: FC = () => {
  const {
    pab2cVoiceStore: { getPhones },
  } = useRootStore();
  const {
    isShowPassword,
    setIsShowPassword,
    isCreatePassword,
    phonePassword: phone,
  } = useVoiceStore();

  /** Результат сайдпейджа */
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);
  const zeroing = () => {
    setIsShowPassword(false);
    setResult(DEFAULT_RESULT);
  };

  const headerText = () => {
    if (result.isResult) return '';
    if (isCreatePassword) return 'Создать пароль';
    return 'Изменить пароль';
  };

  /** Новый пароль */
  const [newPassword, setNewPassword] = useState<string>('');
  /** Ошибка нового пароля */
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  /** Событие смены нового пароля */
  const onNewPasswordChange = (event) => {
    const { value } = event.target;
    setNewPassword(value);
    checkRules(value);
    const re = /[!@#$%^&*()_\-+=[{\]};:<>|.\/?]/;
    setNewPasswordError(
      re.test(value)
        ? 'Пароль не должен содержать символы <!@#$%^&*()_-+=[{]};:<>|./?>'
        : '',
    );
  };
  /** Событие фокуса на новом пароле */
  const onFocusNew = () => {
    setIsShowRules(true);
    setNewPasswordError('');
  };

  /** Показать правила */
  const [isShowRules, setIsShowRules] = useState<boolean>(false);
  /** Восемь символов */
  const [isEightSymbols, setIsEightSymbols] = useState<boolean>(false);
  /** Одна цифра */
  const [isOneDigit, setIsOneDigit] = useState<boolean>(false);
  /** Одна прописная буква */
  const [isOneCapitalLetter, setIsOneCapitalLetter] = useState<boolean>(false);
  /** Одна строчная буква */
  const [isOneLowercaseLetter, setIsOneLowercaseLetter] = useState<boolean>(
    false,
  );
  /** Значение шкалы */
  const scaleValue = useMemo(() => {
    const trueRuleLength = [
      isEightSymbols,
      isOneDigit,
      isOneCapitalLetter,
      isOneLowercaseLetter,
    ].filter((item) => item).length;
    if (!trueRuleLength) return 25;
    return 25 * trueRuleLength;
  }, [isEightSymbols, isOneDigit, isOneCapitalLetter, isOneLowercaseLetter]);

  /** Цвет шкалы */
  const fillerColor = (value: number) => {
    if (value <= 25) return defaultTheme.colors.warning;
    if (value <= 75) return defaultTheme.colors.graphite;
    return defaultTheme.colors.green;
  };

  /** Проверка на правила */
  const checkRules = (value) => {
    setIsEightSymbols(value.replace(/ /g, '').length >= 8);
    setIsOneDigit(/[0-9]/.test(value));
    setIsOneCapitalLetter(!/^[^A-ZА-ЯЁ]*$/.test(value));
    setIsOneLowercaseLetter(/[a-zа-яё]/.test(value));
  };

  /** Генерация пароля */
  const generatePass = async () => {
    try {
      const res = await generatePassword();
      checkRules(res);
      setIsShowRules(true);
      setNewPassword(res);
    } catch (e) {
      setNewPasswordError(DEFAULT_ERROR);
    }
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const changePassword = async () => {
    setIsLoading(true);
    try {
      await setPhonePassword(
        `8${phone.prefix}${phone.phoneNumber}`,
        newPassword,
      );
      setResult({
        isResult: true,
        isCorrect: true,
      });
      if (isCreatePassword) getPhones();
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /** Disable кнопки "Изменить/Создать" */
  const disableChange = () => {
    const re = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
    const symbols = /[!@#$%^&*()_\-+=[{\]};:<>|.\/?]/;
    return !re.test(newPassword) || symbols.test(newPassword);
  };

  const resultJSX = () => {
    if (!result.isResult) return <></>;
    if (result.isCorrect) {
      return (
        <StyledResult>
          <Icons.OkBigIcon />
          <H1>Пароль {isCreatePassword ? 'создан' : 'изменён'}</H1>
        </StyledResult>
      );
    }
    return (
      <StyledResult>
        <Icons.NotOkBigIcon />
        <H1>{result.text ?? DEFAULT_ERROR}</H1>
      </StyledResult>
    );
  };

  return (
    <StyledPasswordSidePage>
      <SidePage
        show={isShowPassword}
        width="832px"
        headerText={headerText()}
        onCloseClick={zeroing}
        footerContainer={
          !result.isResult && (
            <PasswordFooter
              changePassword={changePassword}
              isLoading={isLoading}
              isDisabled={disableChange()}
            />
          )
        }
        removeScrollBar
      >
        {resultJSX()}
        {!result.isResult && (
          <>
            <div className="password__block">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Пароль для номера
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {maskHomePhone(phone)}
              </LeadingText>
            </div>
            <div className="password__input-block">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Пароль
              </Text>
              <div className="password__input-block__block">
                <Input
                  type="password"
                  placeholder="Пароль"
                  value={newPassword}
                  error={newPasswordError}
                  onChange={onNewPasswordChange}
                  onFocus={onFocusNew}
                />
                <Text
                  className="password__input-block__block__action"
                  lineHeight="24px"
                  color={defaultTheme.colors.planeta}
                  onClick={generatePass}
                >
                  Сгенерировать
                </Text>
              </div>
            </div>
            {isShowRules && (
              <div className="password__security">
                <Text lineHeight="24px">Надёжность пароля</Text>
                <FillableScale value={scaleValue} fillerColor={fillerColor} />
                <>
                  {[
                    { condition: isEightSymbols, text: 'Не менее 8 символов' },
                    { condition: isOneDigit, text: 'Как минимум 1 цифра' },
                    {
                      condition: isOneCapitalLetter,
                      text: 'Как минимум 1 прописная буква',
                    },
                    {
                      condition: isOneLowercaseLetter,
                      text: 'Как минимум 1 строчная буква',
                    },
                  ].map((item) => (
                    <Text
                      key={item.text}
                      className={`${item.condition ? 'line-through' : ''}`}
                      lineHeight="24px"
                    >
                      {item.text}
                    </Text>
                  ))}
                </>
                <div>
                  <Text
                    className="password__security__warning"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    Не должен содержать символы {'<!@#$%^&*()_-+=[{]};:<>|./?>'}
                  </Text>
                  <Text
                    className="password__security__warning"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    Постарайтесь не&nbsp;использовать пароль, который уже
                    используется Вами на&nbsp;других сайтах или который можно
                    легко разгадать
                  </Text>
                </div>
              </div>
            )}
          </>
        )}
      </SidePage>
    </StyledPasswordSidePage>
  );
};

export default observer(PasswordSidePage);
