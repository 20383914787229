/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';

/** Стилизованные компоненты */
import { StyledSidePage } from '../Pab2cStyle';

/** Компоненты  */
import DeviceCardWizardPurchaseFooter from '../Components/DeviceCardWizard/DeviceCardWizardPurchaseFooter';
import DeviceCardWizardPurchase from '../Components/DeviceCardWizard/DeviceCardWizardPurchase';

/** Константы */
import {
  ACTION_MAP,
  DEVICE_TYPES_GENITIVE,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../stores/useDevicesStore';

/**
 * Блок pab2cDevices. SidePage передачи оборудования в собственность
 */
const DeviceCardPurchaseWizardSP: FC = () => {
  const {
    result,
    resetState,
    detailedDeviceStore: { detailedDevice },
    devicePurchasesStore: { isPurchaseDeviceShow, resetPurchaseStore },
  } = useDevicesStore();

  const {
    pab2cDevicesStore: { updatePab2cDevices },
  } = useRootStore();

  const handleClick = async () => {
    resetState();
    resetPurchaseStore();
    await updatePab2cDevices();
  };

  return (
    <StyledSidePage
      $isHideHeader={result.isResult}
      show={isPurchaseDeviceShow}
      headerText={`${ACTION_MAP[detailedDevice.ownership].desktop} ${
        detailedDevice?.deviceTypeCode
          ? DEVICE_TYPES_GENITIVE[detailedDevice?.deviceTypeCode]?.toLowerCase()
          : ''
      }  ${detailedDevice.modelName}`}
      onCloseClick={handleClick}
      footerContainer={
        !result.isResult ? <DeviceCardWizardPurchaseFooter /> : <></>
      }
      isOnlyMobileFooter
      removeScrollBar
    >
      <DeviceCardWizardPurchase />
    </StyledSidePage>
  );
};

export default observer(DeviceCardPurchaseWizardSP);
