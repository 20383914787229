/** библиотеки */
import styled from '@emotion/styled';

/** Константы */
import { desktop940, desktop500 } from '~/components/Grid/constants';

export const StyledVoiceControl = styled.div`
  display: block;
  min-height: 400px;
  background: linear-gradient(180deg, #030750 0%, #5400ad 100%);
  padding: 30px;


  .voiceControl__animation-block {
    min-height: 142px;

    > h3 {
      padding-top: 24px;
    }
  }

  .voiceControl__word {
    opacity: 0.2;

    &.active {
      transition: opacity 1s;
      opacity: 1;
    }
  }

  h2 {
    font-size: 35px;
    max-width: 350px;
    margin-top: 32px;
  }

  h3 {
    margin-bottom: 16px;
  }

  .voiceControl__text {
    max-width: 340px;
  }

  @media (min-width: ${desktop500}px) {
    h2 {
      font-size: 44px;
    }
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
    display: grid;
    -webkit-box-sizing: border-box;

    h2 {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

}
`;
