import React from 'react';

/**
 * hoc для ошибок рендера блока
 * https://reactjs.org/docs/error-boundaries.html
 * - на классе, так как жизненный цикл componentDidCatch ещё не появился в FC
 */
export default class ErrorBoundary extends React.Component<
  unknown,
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <></>;
    }

    return children;
  }
}
