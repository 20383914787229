/* Библиотеки */
import style from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop400 } from '~/components/Grid/constants';

/* Стилизованная часть левой колонки строки с настройками wi-fi роутера */
export const StyledLeftColumn = style.div`
  ${() => `
      display: none;
      flex-direction: row;
      white-space: pre-line;
      margin: 0 10px 0 0;
      min-width: 35%;

      .imageWrapper {
        width: 71px;
        height: 50px;
        position: relative;
        margin-right: 6px;
      }

      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: flex;
      }
    `}
`;

/* Стилизованная часть правой колонки строки с настройками wi-fi роутера */
export const StyledRightColumn = style.div`
  ${() => `
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      > div:first-of-type {
        display: flex;
        flex-direction: row;
        margin-right: 24px;
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          display: none;
        }
      }
      > span: first-of-type {
        display: flex;
        align-items: center;
        div[data-test-tag] {
          margin: 0 0 15px 10px;
        }
      }
      h3 {
        margin: 8px 0 0 0;
        
        & + span {
          margin-top: 16px;  
        }
      }
      span {
        white-space: pre-line;
      }
      .imageWrapper {
        width: 71px;
        height: 50px;
        position: relative;
        margin-right: 6px;
      }
      .device__selectors {
        display: flex;
        flex-direction: column;
        margin-top: 26px;
        margin-bottom: 16px;
        align-items: baseline;
        > div {
          margin-right: 24px;
        }
        div + div {
          margin-top: 8px;
        }
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          margin-top: 0;
        }
        @media (min-width: ${desktop400}px) {
          flex-direction: row;
          div + div {
            margin-top: 0;
          }
        }
      }
    `}
`;
