/** libraries */
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** components */
import {
  Header,
  StyledButton,
} from '~/components/Blocks/Templates/ChatlesExp/styles';
import { defaultTheme, LeadingText, Text } from 'cordis-core-ui-planeta';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const NotAuth: FC = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { toggleVisible } = useMakeAuthStore();

  return (
    <>
      <Header
        color={defaultTheme.colors.black}
        as={isDesktop ? LeadingText : Text}
      >
        Для участия в&nbsp;розыгрыше{'\n'}
        авторизуйтесь в&nbsp;Планете
      </Header>
      <StyledButton
        onClick={() => toggleVisible()}
        border={defaultTheme.colors.redViolet}
        background={defaultTheme.colors.redViolet}
      >
        Войти
      </StyledButton>
    </>
  );
};

export default observer(NotAuth);
