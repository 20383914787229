/** Библиотеки */
import React from 'react';
import { observer } from 'mobx-react';
/** Компоненты */
import {
  Button,
  ButtonStyleTypes,
  Icon,
  Icons,
  LinkButton,
  Popup,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { StyledPopupWrapper, StyledList, StyledConfirm } from './style';
/** интерфейсы */
import { City } from '~/interfaces/CityInterface';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface CityPopupProps {
  /** состояние блока выбора города */
  isConfirmShow: boolean;
  /** скрыть блок выбора города */
  closeConfirmHandler: () => void;
  /** состояние popup со списком городов */
  isCitiesPopupOpen: boolean;
  /** Открывает/закрывает popup со списком городов */
  toggleCitiesPopup: () => void;
}
/**
 * Блок с popup выбора городов
 * @returns {React.FC<CityPopupProps>}
 */
const CityPopup: React.FC<CityPopupProps> = ({
  isConfirmShow,
  closeConfirmHandler,
  isCitiesPopupOpen,
  toggleCitiesPopup,
}: CityPopupProps) => {
  const {
    cityStore: { city, cities, setCity },
  } = useRootStore();

  /**
   * Открывает панель подтверждения после
   * выбора города из списка
   * @param {City} item - выбранный город
   * @returns {JSX.Element}
   */
  const CityItem = (item: City): JSX.Element => (
    <LinkButton
      color={defaultTheme.colors.black}
      onClick={(): void => {
        toggleCitiesPopup();
        closeConfirmHandler();
        setCity(item);
      }}
    >
      {item.name}
    </LinkButton>
  );

  /** подтвердить город */
  const onConfirmCity = (): void => {
    closeConfirmHandler();
    setCity(city);
  };

  /** сменить выбранный город */
  const onCancelCity = (): void => {
    toggleCitiesPopup();
    closeConfirmHandler();
  };

  return (
    <StyledPopupWrapper>
      {!isConfirmShow && (
        <LinkButton
          color={defaultTheme.colors.pink}
          onClick={toggleCitiesPopup}
        >
          {city.name}
        </LinkButton>
      )}
      {isConfirmShow && city && (
        <StyledConfirm>
          <Text
            color={defaultTheme.colors.light}
          >{`Ваш город ${city.name}?`}</Text>
          <Button
            styleType={ButtonStyleTypes.SECONDARY}
            onClick={onConfirmCity}
            background="inherit"
            border="1px solid #FFFFFF"
            color="#FFFFFF"
          >
            Да
          </Button>
          <LinkButton color={defaultTheme.colors.light} onClick={onCancelCity}>
            Выбрать другой
          </LinkButton>
          <span
            className="confirm__close-btn"
            onClick={() => closeConfirmHandler()}
            onKeyPress={() => closeConfirmHandler()}
            role="button"
            tabIndex={0}
            style={{ outline: 'none' }}
            data-test-button=""
          >
            <Icon icon={<Icons.CloseIcon />} />
          </span>
        </StyledConfirm>
      )}
      <Popup
        show={isCitiesPopupOpen}
        title="Выберите свой город"
        onCloseClick={toggleCitiesPopup}
        onOutsideClick={toggleCitiesPopup}
        width="710px"
        removeScrollBar={false}
      >
        {cities?.length > 0 && (
          <StyledList data-test-list="">
            {cities.map((item) => (
              <li key={item.shortName} data-test-li="">
                {CityItem(item)}
              </li>
            ))}
          </StyledList>
        )}
      </Popup>
    </StyledPopupWrapper>
  );
};

export default observer(CityPopup);
