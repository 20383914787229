/* eslint-disable no-nested-ternary */
/** библиотеки */
import { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
/** api */
import {
  checkAuth,
  getSbpPaymentCheckOrderState,
  getStaticSbpPaymentByContractName,
  sbpRegisterOrderByBinding,
  sbpRegisterPaymentWithBinding,
  setCardPaymentRegisterOrder,
  setRegisterCardPaymentWithCardBinding,
  setRegisterOrderForContractByBindingPaymentCard,
  setSbpPaymentRegisterOrderByContractName,
} from '~/api/api';
/** константы */
import {
  CHECK_URL_AMOUNT,
  DEFAULT_AMOUNT,
  ERROR_MESSAGES,
  NO_PROMOTIONS,
  CHECK_PAYMENT_SLUG,
  CONTRACT_NOT_FOUND_TYPE,
  NEW_CARD,
  PAYMENT_OPTION,
} from './constants';
import { PAYMENT_SLUG } from '~/constants/common';
import { CASHBACK_CONFIG } from '~/components/Blocks/Templates/Payment/Promo/config';
import { NEW_ACCOUNT } from '../PaymentResult/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';

/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { checkUrlAccess, checkUrlAccessMobile } from './utils';
import { removeLeadingZero } from '~/utils/utils';
/** интерфейсы */
import {
  AmountProps,
  SbpPaymentProps,
  UsePaymentProps,
  UsePaymentReturnProps,
} from './types';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';
import { SbpInfo, SbpInfoByBinding } from '../PaymentResult/interfaces';
import { PaymentTypeSelectProps } from '../../Shared/CardAndSbpPaymentSelect/interfaces';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileSBPStore from './MobileSBP/store/useMobileSBPStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import usePinWizardStore from '../../Shared/PinWizard/store/usePinWizardStore';

export const usePayment = ({
  promoListContent,
  activePromotion,
  setActivePromotion,
  getPromoInfo,
  cardLimit,
  sbpLimit,
}: UsePaymentProps): UsePaymentReturnProps => {
  const router = useRouter();
  const {
    authStore: { auth, isAuth, isTemporaryTokenAuth, setIsMenuOpen },
    paymentInfoStore: { haveLinkedCards },
  } = useRootStore();
  const {
    setIsOpenSBPCards,
    fetchAmount,
    waitingPage,
    setFetchAmount,
    setPayload,
    isZeroing,
    resetStore,
  } = useMobileSBPStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const {
    isShowPinSidePage,
    setIsShowPinSidePage,
    setContractNumberForPin,
  } = usePinWizardStore();

  // Номер договора
  const [contractNumber, setContractNumber] = useState<string>(
    isAuth ? removeLeadingZero(auth.contractName) : '',
  );
  // Номер договора ошибка
  const [contractNumberError, setContractNumberError] = useState<boolean>(
    false,
  );
  // Сумма
  const [amount, setAmount] = useState<AmountProps>(DEFAULT_AMOUNT);
  // Сумма ошибка
  const [amountError, setAmountError] = useState<boolean>(false);
  // Текст ошибки
  const [errorMessage, setErrorMessage] = useState<string>('');
  // Флаг загрузки
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Сайдпейдж QR
  const [isSidePageOpen, setIsSidePageOpen] = useState<boolean>(false);
  // Изображение qr-кода
  const [QRcode, setQRcode] = useState<string>('');
  // id qr-кода
  const [QRId, setQRId] = useState<string>('');
  // id подписки на сбп счёт
  const [
    sbpSubscriptionInfo,
    setSbpSubscriptionInfo,
  ] = useState<SbpPaymentProps>(null);
  // Статус оплаты СБП
  const [sbpStatus, setSbpStatus] = useState<{
    contractName: string;
    qrId: string;
  }>(null);
  // id таймера для проверки платежа по СБП
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  /** Сайдпейдж с промо-акцией */
  const [isOpenPromo, setIsOpenPromo]: HooksTyping<boolean> = useState<boolean>(
    false,
  );
  // Выбранный метод оплаты
  const [paymentSelect, setPaymentSelect] = useState<PaymentTypeSelectProps>(
    null,
  );
  // Оплатить другой договор
  const [isChangeContract, setIsChangeContract] = useState<boolean>(false);
  // Привязать платёжные данные
  const [isRememberPaymentData, setIsRememberPaymentData] = useState<boolean>(
    false,
  );
  // Скрыть привязанные счета
  const [isHideBindings, setIsHideBindings] = useState<boolean>(false);
  // Количество попыток проверок доступа до эквайринга
  const fetchAmountRef = useRef<number>(CHECK_URL_AMOUNT);
  // Хранение состояние клика по кнопке оплаты (предотвращает лишний вызов хука эффекта на контекст акций, если есть несколько блоков на странице)
  // todo: после появления ещё какой-нибудь акции пристально посмотреть на структуру контекста
  const clicked = useRef<boolean>(false);
  /** Временный токен (получает через пин-код) */
  const token = window.sessionStorage.getItem(contractNumber);
  /** Выбрана новая карта в качестве метода оплаты */
  const isNewCard = useMemo(() => {
    return paymentSelect?.label === NEW_CARD;
  }, [paymentSelect]);
  /** Выбран новый СБП счёт в качестве метода оплаты */
  const isNewSbpAccount = useMemo(() => {
    return paymentSelect?.label === NEW_ACCOUNT;
  }, [paymentSelect]);
  const isNewAccount = useMemo(() => {
    return isNewCard || isNewSbpAccount;
  }, [isNewCard, isNewSbpAccount]);
  const isSBP = useMemo(() => {
    return !paymentSelect?.isCard;
  }, [paymentSelect]);
  // Флаг работы СБП // Change when sbp is gonna available
  const isAvailableSBP = true;

  const switchToPayment = () => {
    if (router.query?.contractNameFromShortLink)
      router.push(`/${PAYMENT_SLUG}`, `/${PAYMENT_SLUG}`, {
        shallow: true,
      });
  };

  const redirectToCheckPaymentCard = (isCorrect?: boolean) => {
    const routerAmount = router?.query?.amount
      ? `&amount=${router.query?.amount}`
      : '';

    router.push(
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${routerAmount}`,
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${routerAmount}`,
    );
  };

  const redirectToCheckPayment = (isCorrect?: boolean) => {
    /** Оплата с привязанного счёта сбп */
    const linkedAccount =
      isSBP && !isNewSbpAccount ? paymentSelect.value : null;

    const sbpParams = `${
      sbpStatus
        ? `&contractNumber=${removeLeadingZero(sbpStatus.contractName)}&qrId=${
            sbpStatus.qrId
          }&isRememberPaymentData=${isRememberPaymentData}&sbpSubscriptionId=${
            sbpSubscriptionInfo?.sbpSubscriptionId
          }&linkedAccount=${linkedAccount}&amount=${amount.forSend}`
        : ''
    }`;
    router.replace(
      {
        query: {},
      },
      undefined,
      {
        shallow: true,
      },
    );
    router.push(
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${sbpParams}`,
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${sbpParams}`,
    );
  };

  /** Действие по доступности адреса оплаты */
  const onUrlAccess = () => {
    switchToPayment();
    setIsOpenSBPCards(true);
    setIsLoading(false);
  };

  const onCloseClickSbpDesktop = () => {
    clearInterval(timerId);
    setQRId('');
    setQRcode('');
    setSbpStatus(null);
    setIsSidePageOpen(false);
    setActivePromotion(null);
    setSbpSubscriptionInfo(null);
  };

  // При вводе номера договора
  const handleOnChangeInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length > 8 || (newValue && /\D/g.test(newValue))) return;
    setContractNumber(newValue);
    setContractNumberForPin(newValue);
    if (!(isAuth && !isChangeContract))
      setIsHideBindings(
        ![
          removeLeadingZero(auth.contractName),
          ...auth.otherClientContractNames,
        ].includes(newValue),
      );
  };
  /** Произвести оплату после авторизации */
  const isPaymentAfterAuthorization = useMemo(
    () => openSPAfterAuthorizationState === SIDE_PAGES.PAYMENT && isAuth,
    [openSPAfterAuthorizationState, isAuth],
  );

  // Оплата через СБП
  const paymentViaSBP = async (
    contractNameFromShortLink?: string,
    newAmount?: number,
  ) => {
    /** открыть сп пин-кода */
    if (!token && isRememberPaymentData && !isAuth && setIsShowPinSidePage) {
      setIsShowPinSidePage(true);
      return;
    }
    setFetchAmount(CHECK_URL_AMOUNT);
    try {
      const res =
        isSBP && !isNewSbpAccount
          ? await sbpRegisterOrderByBinding(
              contractNumber,
              newAmount ?? +amount.forSend,
              paymentSelect.value,
            )
          : isRememberPaymentData
          ? await sbpRegisterPaymentWithBinding(
              contractNumber,
              newAmount ?? +amount.forSend,
              token,
            )
          : contractNameFromShortLink
          ? await getStaticSbpPaymentByContractName(contractNameFromShortLink)
          : await setSbpPaymentRegisterOrderByContractName(
              contractNumber,
              newAmount ?? +amount.forSend,
            );

      if ((res as SbpInfoByBinding)?.paymentStatus === 'InProgress') {
        setQRId(res.qrId);
      }
      if (isMobile && (res as SbpInfo).payload) {
        setQRId(res.qrId);
        setPayload((res as SbpInfo).payload);
        await checkUrlAccessMobile(
          (res as SbpInfo).payload,
          fetchAmount,
          setFetchAmount,
          redirectToCheckPayment,
          onUrlAccess,
        );
      }
      if (!isMobile && (res as SbpInfo).qrImage) {
        setQRcode((res as SbpInfo).qrImage);
        setQRId(res.qrId);
        setIsSidePageOpen(true);
        setIsLoading(false);
      }
      if (isRememberPaymentData) {
        setSbpSubscriptionInfo(res as SbpInfo);
      }
      setActivePromotion(null);
      setIsMenuOpen(false);
    } catch (e) {
      const err = e.errorMessage ? JSON.parse(e.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      setErrorMessage(ERROR_MESSAGES.ERROR);
    } finally {
      setOpenSPAfterAuthorizationState(null);
    }
  };

  // Возвращает информацию о состоянии платежа с использованием СБП
  const sbpPaymentCheckOrderState = async (
    contractNumberProp: string,
    currentAccount?: number,
  ) => {
    try {
      // Порешаем проблемы с привязанным СБП на странице проверке платежа
      if (currentAccount && currentAccount !== 1) {
        setSbpStatus({ contractName: contractNumberProp, qrId: QRId });
        clearInterval(timerId);
        return;
      }
      const res = await getSbpPaymentCheckOrderState(contractNumberProp, QRId);
      if (res.statusCode === 'Success')
        setSbpStatus({ contractName: contractNumberProp, qrId: QRId });
    } catch (error) {
      const err = JSON.parse(error?.errorMessage);
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        clearInterval(timerId);
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      clearInterval(timerId);
      setIsSidePageOpen(false);
      setErrorMessage(ERROR_MESSAGES.ERROR);
    }
  };

  // Проверка платежа СБП каждые 3 секунды
  useEffect(() => {
    if (
      QRId &&
      !sbpStatus &&
      (waitingPage || !isMobile || (isMobile && isSBP && !isNewSbpAccount))
    ) {
      (async () => {
        const id = setInterval(
          () => sbpPaymentCheckOrderState(contractNumber, paymentSelect?.value),
          3000,
        );
        setTimerId((id as unknown) as NodeJS.Timeout);
      })();
    }
    if (!waitingPage && isMobile && timerId) clearInterval(timerId);
    if (sbpStatus) {
      clearInterval(timerId);
      setIsSidePageOpen(false);
      redirectToCheckPayment(true);
    }
  }, [QRId, sbpStatus, waitingPage]);

  // Оплата картой
  const payByCreditCard = async (newAmount?: number) => {
    fetchAmountRef.current = CHECK_URL_AMOUNT;
    const baseUrl = `${window.location.origin}/${CHECK_PAYMENT_SLUG}`;

    // Если пользователь выбирает "Новая карта" или у него нет привязанных карт, но стоит checkbox "запомнить карту"
    const isPayByNewCard =
      (isNewCard || !haveLinkedCards) && isRememberPaymentData;
    // Если оплачивают привязанной картой
    const isPayByLinkedCard = !isSBP && !isNewCard;

    // Вариант оплаты
    const paymentOption = () => {
      switch (true) {
        case isPayByNewCard &&
          (isAuth ||
            !!(token || window.sessionStorage.getItem(contractNumber))):
          return PAYMENT_OPTION.LINK_NEW_CARD_IS_AUTH;
        case isPayByNewCard &&
          !token &&
          !window.sessionStorage.getItem(contractNumber):
          return PAYMENT_OPTION.LINK_NEW_CARD;
        case isPayByLinkedCard:
          return PAYMENT_OPTION.LINKED_CARD_CONTRACT_NUMBER;
        case !isRememberPaymentData:
          return PAYMENT_OPTION.NEW_CARD_WITHOUT_BINDING;
        default:
          return null;
      }
    };

    // Привязка карты у не авторизованного клиента или другому номеру договору
    if (paymentOption() === PAYMENT_OPTION.LINK_NEW_CARD) {
      // открывается сп пин-кода
      setIsShowPinSidePage(true);
    }

    // Если привязывает карту с авторизацией или по временному токену
    if (paymentOption() === PAYMENT_OPTION.LINK_NEW_CARD_IS_AUTH) {
      try {
        const registerCardPaymentWithCardBinding = await setRegisterCardPaymentWithCardBinding(
          contractNumber,
          newAmount ?? +amount.forSend,
          encodeURIComponent(
            `${baseUrl}?hasCardBinding=${true}&size=2&contractNumber=${contractNumber}&amount=${
              newAmount ?? +amount.forSend
            }`,
          ),
          token ?? window.sessionStorage.getItem(contractNumber),
        );
        if (registerCardPaymentWithCardBinding.authCode === 'Ok') {
          await checkUrlAccess(
            registerCardPaymentWithCardBinding.formUrl,
            fetchAmountRef,
            redirectToCheckPaymentCard,
            null,
            setIsLoading,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }

    // Если оплачивает привязанной картой номер договора
    if (paymentOption() === PAYMENT_OPTION.LINKED_CARD_CONTRACT_NUMBER) {
      try {
        const registerOrderByBindingPaymentCard = await setRegisterOrderForContractByBindingPaymentCard(
          contractNumber,
          newAmount ?? +amount.forSend,
          encodeURIComponent(
            `${baseUrl}?size=2&contractNumber=${contractNumber}&amount=${
              newAmount ?? +amount.forSend
            }&linkedCardValue=${paymentSelect.value}`,
          ),
          paymentSelect.value,
        );

        if (registerOrderByBindingPaymentCard.authCode === 'None') {
          await checkUrlAccess(
            registerOrderByBindingPaymentCard.formUrl,
            fetchAmountRef,
            redirectToCheckPaymentCard,
            null,
            setIsLoading,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
          setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
          return;
        }
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }

    // Оплачивает новой картой без привязки
    if (paymentOption() === PAYMENT_OPTION.NEW_CARD_WITHOUT_BINDING) {
      try {
        const paymentRegisterOrder = await setCardPaymentRegisterOrder(
          contractNumber,
          newAmount ?? +amount.forSend,
          encodeURIComponent(
            `${baseUrl}?size=2&contractNumber=${contractNumber}&amount=${
              newAmount ?? +amount.forSend
            }`,
          ),
        );
        if (paymentRegisterOrder.authCode === 'None') {
          await checkUrlAccess(
            paymentRegisterOrder.formUrl,
            fetchAmountRef,
            redirectToCheckPaymentCard,
            null,
            setIsLoading,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
          setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
          return;
        }
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }
    setActivePromotion(null);
    setOpenSPAfterAuthorizationState(null);
    setIsLoading(false);
  };

  // Валидация
  const handleCheckIsValid = (): boolean => {
    if (!contractNumber) {
      setContractNumberError(true);
      setErrorMessage(ERROR_MESSAGES.CONTRACT_NUMBER);
      return false;
    }

    const isCardAmountError =
      !isSBP && cardLimit.minAmount && +amount.forSend < cardLimit.minAmount;
    const isSbpAmountError =
      isSBP && sbpLimit.minAmount && +amount.forSend < sbpLimit.minAmount;

    if (isCardAmountError || isSbpAmountError) {
      setAmountError(true);
      setErrorMessage(
        `Сумма менее ${
          isCardAmountError ? cardLimit.minAmount : sbpLimit.minAmount
        } рублей`,
      );
      return false;
    }

    if (haveLinkedCards && !paymentSelect && !isSBP) {
      setErrorMessage(ERROR_MESSAGES.CARD_NOT_FOUND);
      return false;
    }

    // Если карта просрочена
    if (haveLinkedCards && paymentSelect?.bottomText && !isSBP) {
      setErrorMessage(ERROR_MESSAGES.CARD_HAS_EXPIRED);
      return false;
    }

    setErrorMessage('');
    return true;
  };

  // Кнопка "Оплатить"
  const payHandler = async () => {
    setActivePromotion(null);
    clicked.current = true;
    setIsLoading(true);

    if (!handleCheckIsValid()) {
      setIsLoading(false);
      return;
    }

    try {
      if (token) {
        const res = await checkAuth(token);
        if (res.status !== 200) {
          window.sessionStorage.removeItem(contractNumber);
        }
      }
      /** Получает информацию о промо-акциях */
      await getPromoInfo(
        contractNumber,
        +amount.forSend,
        isSBP ? PAYMENT_TYPE.SBP : PAYMENT_TYPE.CARD_PAYMENT,
      );
    } catch (error) {
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
      }
      setErrorMessage(ERROR_MESSAGES.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!clicked.current) return;
    if (activePromotion) clicked.current = false;

    const exceededCashback =
      promoListContent[activePromotion]?.isCashbackBottoms &&
      +amount.forSend >= CASHBACK_CONFIG.max.amount;

    if (activePromotion === NO_PROMOTIONS || exceededCashback) {
      if (isTemporaryTokenAuth) {
        setErrorMessage(ERROR_MESSAGES.NOT_AVAILABLE_TO_EMPLOYEE);
        return;
      }
      if (isSBP) {
        paymentViaSBP();
        return;
      }

      payByCreditCard();
      return;
    }

    if (activePromotion) {
      setIsOpenPromo(true);
      setIsLoading(false);
    }
  }, [activePromotion]);

  useEffect(() => {
    if (
      (token || (isPaymentAfterAuthorization && isAuth)) &&
      isSBP &&
      +amount.forSend &&
      contractNumber
    ) {
      paymentViaSBP();
      return;
    }
    if (
      (token ||
        (isPaymentAfterAuthorization && isAuth) ||
        window.sessionStorage.getItem(contractNumber)) &&
      !isSBP
    ) {
      payByCreditCard();
    }
  }, [token, isPaymentAfterAuthorization, isAuth]);

  /** Сбрасываем состояния, при закрытии СП MobileSBP */
  useEffect(() => {
    if (isZeroing) {
      setQRId('');
      setSbpStatus(null);
      setActivePromotion(null);
      resetStore();
    }
  }, [isZeroing]);

  useEffect(() => {
    return () => {
      if (timerId) clearInterval(timerId);
      clicked.current = false;
    };
  }, []);

  /**
   * Обработка авторизации
   */
  useEffect(() => {
    const contractName = isAuth
      ? removeLeadingZero(auth.contractName)
      : (router?.query?.contractNameFromShortLink as string) ?? '';
    setContractNumber(contractName);
    setContractNumberForPin(contractName);
    setIsHideBindings(false);
  }, [isAuth]);

  // Выключает иконку загрузки при появлении ошибки
  useEffect(() => {
    if (!errorMessage) return;
    setIsLoading(false);
    fetchAmountRef.current = CHECK_URL_AMOUNT;
  }, [errorMessage]);

  // Выключает иконку загрузки при закрытии СП ввода пин-кода
  useEffect(() => {
    if (isShowPinSidePage) return;
    setIsLoading(false);
  }, [isShowPinSidePage]);

  /** Сбрасываем состояние чекбокса привязки */
  useEffect(() => {
    if (isRememberPaymentData) setIsRememberPaymentData(false);
  }, [paymentSelect]);

  return {
    contractNumber,
    amount,
    setAmount,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    isSidePageOpen,
    QRcode,
    payHandler,
    paymentViaSBP,
    payByCreditCard,
    isAvailableSBP,
    handleOnChangeInputText,
    onCloseClickSbpDesktop,
    isOpenPromo,
    setIsOpenPromo,
    contractNumberError,
    setContractNumberError,
    amountError,
    setAmountError,
    isRememberPaymentData,
    setIsRememberPaymentData,
    isHideBindings,
    isChangeContract,
    setIsChangeContract,
    paymentSelect,
    setPaymentSelect,
    isNewAccount,
    isSBP,
  };
};
