/** библиотеки */
import React, { FC } from 'react';

/** компоненты библиотеки */
import { Text, defaultTheme, LeadingText } from 'cordis-core-ui-planeta';

/** стили */
import { DeviceReturnWizardStyled } from './style';

/** типы */
import { DeviceReturnWizardProps } from './types';

/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/**
 * Блок pab2cDevices. SidePage возврата оборудования
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=597419200
 */
const DeviceReturnWizard: FC<DeviceReturnWizardProps> = ({
  device,
  agreement,
}: DeviceReturnWizardProps) => {
  return (
    <DeviceReturnWizardStyled>
      <div className="device-return-wizard__serial-block">
        <Text color={defaultTheme.colors.shadow} lineHeight="24px">
          Серийный номер
        </Text>
        <LeadingText color={defaultTheme.colors.black}>
          {device?.serial}
        </LeadingText>
      </div>
      <div className="device-return-wizard__agreement">
        <Text lineHeight="24px" fontWeightBold>
          Согласие на возврат оборудования
        </Text>
        <Text lineHeight="24px">{parseHtml(agreement)}</Text>
      </div>
    </DeviceReturnWizardStyled>
  );
};

export default DeviceReturnWizard;
