/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { useRouter } from 'next/router';
/** components */
import {
  H1,
  H3,
  defaultTheme,
  LeadingText,
  ButtonStyleTypes,
} from 'cordis-core-ui-planeta';
import Wrapper, {
  Header,
  MainText,
  StyledImageWrapper,
  StyledButton,
} from './styles';
import { WrapperBlock } from '../Itv/styles';
/** config */
import CONFIG from '../../config';
/** constants */
import { desktop940 } from '~/components/Grid/constants';

/** Слайд Хит */
const Hit: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const imageLoader = ({ src, width: imgWidth, quality }) => {
    return `${src}?w=${imgWidth}&q=${quality || 75}`;
  };

  const router = useRouter();
  const redirect = () =>
    router.push(
      'news/30-03-2023?utm_source=planeta&utm_medium=20year&utm_campaign=hit11',
      'news/30-03-2023?utm_source=planeta&utm_medium=20year&utm_campaign=hit11',
    );

  if (!isDesktop940)
    return (
      <Wrapper $isMobile>
        <Header as={H3} color={defaultTheme.colors.black}>
          {CONFIG[6].header}
        </Header>
        <WrapperBlock>
          <MainText
            dangerouslySetInnerHTML={{ __html: CONFIG[6].mainTextMobile }}
            as={LeadingText}
            color={defaultTheme.colors.black}
          />
          <StyledButton
            onClick={redirect}
            styleType={ButtonStyleTypes.DARK_BACKGROUND}
          >
            Познакомиться
          </StyledButton>
        </WrapperBlock>
        <StyledImageWrapper>
          <Image
            loader={imageLoader}
            src={`${process.env.STATIC_SERVER}/${CONFIG[6].mainImage}`}
            alt={CONFIG[6].mainText}
            width={401}
            height={395}
            quality={100}
          />
        </StyledImageWrapper>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Header as={H1} color={defaultTheme.colors.black}>
        {CONFIG[6].header}
      </Header>
      <MainText
        dangerouslySetInnerHTML={{ __html: CONFIG[6].mainText }}
        as={LeadingText}
        color={defaultTheme.colors.black}
      />
      <StyledButton
        onClick={redirect}
        styleType={ButtonStyleTypes.DARK_BACKGROUND}
      >
        Познакомиться
      </StyledButton>
      <StyledImageWrapper>
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${CONFIG[6].mainImage}`}
          alt={CONFIG[6].mainText}
          width={613}
          height={585}
          quality={100}
        />
      </StyledImageWrapper>
    </Wrapper>
  );
};

export default Hit;
