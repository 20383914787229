/** libraries */
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import {
  defaultTheme,
  LeadingText,
  Loader,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
/** styles */
import { StyledAppeal } from './styles';
/** interfaces */
import {
  APPEAL_STATUS,
  AppealData,
  CommentData,
} from '~/components/Blocks/Templates/Pab2c/Help/interfaces';
/** utils */
import { getDate, parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { removeLeadingZero } from '~/utils/utils';
/** components */
import Comment from '~/components/Blocks/Templates/Pab2c/Help/Components/Comment/Comment';
/** api */
import { demandMarkCommentsAsRead } from '~/api/apiPab2c';
import { useRootStore } from '~/stores/RootStore';

interface AppealProps {
  appeal: AppealData;
  comments: CommentData[];
  isLoadingComments: boolean;
  isRead: boolean;
  setIsRead: Dispatch<SetStateAction<boolean>>;
}

/** СП Созданное обращение  */
const Appeal: FC<AppealProps> = ({
  appeal,
  comments,
  isLoadingComments,
  isRead,
  setIsRead,
}: AppealProps) => {
  const { stateCode, unreadCommentCount, createdDate, mem } = appeal;

  const {
    authStore: { auth },
  } = useRootStore();

  /** Генерация ключей для списка */
  const ids = useMemo(
    () => (comments.length ? comments.map(() => nanoid(5)) : []),
    [comments],
  );

  const statusBackColor = () => {
    if (unreadCommentCount > 0) return defaultTheme.colors.green;
    switch (stateCode) {
      case APPEAL_STATUS.IN_WORK:
        return defaultTheme.colors.green;
      case APPEAL_STATUS.RAW:
        return defaultTheme.colors.planeta;
      case APPEAL_STATUS.CLOSED:
        return defaultTheme.colors.light;
      case APPEAL_STATUS.REJECT:
        return defaultTheme.colors.light;
      default:
        return defaultTheme.colors.white;
    }
  };
  const statusColor = () => {
    if (unreadCommentCount > 0) return defaultTheme.colors.white;
    switch (stateCode) {
      case APPEAL_STATUS.IN_WORK:
        return defaultTheme.colors.green;
      case APPEAL_STATUS.CLOSED:
        return defaultTheme.colors.shadow;
      case APPEAL_STATUS.REJECT:
        return defaultTheme.colors.shadow;
      default:
        return defaultTheme.colors.white;
    }
  };
  const statusName = () => {
    if (unreadCommentCount > 0) return 'Получен ответ';
    switch (stateCode) {
      case APPEAL_STATUS.IN_WORK:
        return 'В работе';
      case APPEAL_STATUS.CLOSED:
        return 'Закрыто';
      case APPEAL_STATUS.REJECT:
        return 'Закрыто';
      case APPEAL_STATUS.RAW:
        return 'Не обработано';
      default:
        return 'Получен ответ';
    }
  };

  /** Сообщения прочитаны */
  const markCommentsAsRead = async () => {
    try {
      await demandMarkCommentsAsRead(appeal.id);
      setIsRead(!isRead);
    } catch (e) {
      console.error('demandMarkCommentsAsRead', e);
    }
  };
  useEffect(() => {
    if (appeal.unreadCommentCount > 0) {
      markCommentsAsRead();
    }
  }, [appeal]);

  return (
    <StyledAppeal>
      <div className="appeal__tag-block">
        <Tag
          color={statusColor()}
          colorTag={statusBackColor()}
          backgroundColor={
            !(stateCode === APPEAL_STATUS.IN_WORK && unreadCommentCount <= 0) &&
            statusBackColor()
          }
        >
          {statusName()}
        </Tag>
        <Snoska className="appeal__date" color={defaultTheme.colors.gray}>
          {getDate(createdDate)} г. в {getDate(createdDate, 'HH:mm')}
        </Snoska>
      </div>
      <div className="appeal__contract-number">
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Договор
        </Text>
        <LeadingText color={defaultTheme.colors.black}>
          № {removeLeadingZero(auth.contractName)}
        </LeadingText>
      </div>
      <div className="appeal__text">
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Текст обращения
        </Text>
        <Text lineHeight="24px">{parseHtml(mem)}</Text>
      </div>
      {isLoadingComments ? (
        <div className="appeal__loader">
          <Loader />
        </div>
      ) : (
        <>
          {comments.length > 0 && (
            <div className="appeal__comments-block">
              <LeadingText color={defaultTheme.colors.black}>
                Комментарии
              </LeadingText>
              <div className="appeal__comments-block__comment">
                {comments.map((item, index) => {
                  return (
                    <Comment
                      key={ids[index]}
                      comment={item.content}
                      backgroundColor={
                        item.isManager
                          ? defaultTheme.colors.greyPlacidity
                          : defaultTheme.colors.pink
                      }
                      isClient={!item.isManager}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </StyledAppeal>
  );
};

export default observer(Appeal);
