/** список вкладок цен */
export const PRICE_TABS = {
  usage: 'В пользование',
  credit: 'Рассрочка',
  purchase: 'Покупка',
};

/** названия устройств */
export const DEVICE_NAMES = {
  Router: 'Wi-Fi-роутер',
  STB: 'ТВ приставка',
  Phone: 'Телефон',
  FTTH: 'Оптический Wi-Fi-роутер',
};

/** заголовки */
export enum DEVICE_TITLES {
  Router = 'Wi-Fi-роутер',
  STB = '4К-телеприставка',
  Phone = 'Современные технологии интернет-телефонии',
  FTTH = 'Оптический Wi-Fi-роутер',
}

/** типы устройств */
export enum DEVICE_TYPES {
  Router = 'Router',
  STB = 'STB',
  Phone = 'Phone',
  FTTH = 'FTTH',
  PDY = 'Pdy',
}

/** типы подключения */
export enum CONNECTION_METHODS {
  FTTH = 'FTTH',
  LAN = 'LAN',
  UTP = 'UTP',
}

/** список периодов списания */
export enum FORGIVENESS {
  daily = 'в день',
  monthly = 'в месяц',
  year = 'в год',
}

/** крайняя точка для блоков среднего размера */
export const desktop520 = 520;

/** Статичный задний фон */
export const BACKGROUND = {
  [DEVICE_TYPES.Router]: {
    desktop: '/static/images/device/router_background.jpg',
    mobile: '/static/images/device/router_background_mobile.jpg',
  },
  [DEVICE_TYPES.STB]: {
    desktop: '/static/images/device/stb-background.png',
    mobile: '/static/images/device/stb-background-mobile.png',
  },
};
