/** телефон для справок */
export const PLANETA_PHONE = '8-800-7-555-051';

/** высоты меню */
export const MENU_OFFSET_HIDDEN = 120;
export const MENU_OFFSET_SMALL = 60;

/** Тип контактного телефона */
export enum contactPhoneType {
  /* ОТП */
  SUPPORT = 'support',
  /* ОКП */
  MANAGER = 'manager',
  /* Личный кабинет пользователя */
  CLIENT_INTERFACE = 'client_interface',
  /* Офис */
  OFFICE = 'office',
}
