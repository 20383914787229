import { addDays, format } from 'date-fns';

export const getSupportAnswerTime = (): string => {
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];
  const weekends = [6, 7];
  const answerHour = 18;

  let answerDate = addDays(new Date(), 1);
  const answerDay = format(answerDate, 'i');
  if (weekends.includes(Number(answerDay))) {
    answerDate = addDays(answerDate, 8 - Number(answerDay));
  }

  const day = format(answerDate, 'd');
  const month = months[Number(format(answerDate, 'M')) - 1];

  return `${day} ${month}, ${answerHour}:00`;
};

/**
 * Форматирует телефон для вывод на экран +7 (999) 999-99-99
 * @param phoneNumber
 * @returns {{value: string, forSend: string, errorText: string}}
 */
export const maskPhone = (number: string): { label: string; value: string } => {
  // получаем телефон формата 9990009999 (без 8 или +7 чисто цифры)
  const phone = number
    .replace(/^8/, '')
    .replace('+7', '')
    .replace(/[^0-9]/g, '');

  // дробление телефона на части
  const phonePart = phone.match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/) || '';
  return {
    label: `${'+7'} ${
      !phonePart[2]
        ? phonePart[1]
        : `${phonePart[1]} ${phonePart[2]}${
            phonePart[3] ? `-${phonePart[3]}` : ''
          }${phonePart[4] ? `-${phonePart[4]}` : ''}`
    }`,
    value: `8${phone.substring(0, 10)}`,
  };
};
