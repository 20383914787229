/** библиотеки */
import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isMacOs, isWindows, isIOS } from 'react-device-detect';
/** константы */
import { ANNUAL_SUBSCRIPTION } from '../constants';
import {
  INFO_KEYS,
  INSTRUCTION,
  OS_KEYS,
  PLATFORM,
  TABS,
} from '../DescriptionOfSoftlineSubscription/constants';
/** интерфейсы */
import { ActiveTab, SoftlineFields, SoftlineHookReturnProps } from '../types';
import {
  DistrList,
  KeyList,
  ShippingForSubscription,
} from '../DescriptionOfSoftlineSubscription/types';
/** компоненты библиотеки */
import { OptionProp } from 'cordis-core-ui-planeta';
/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { formatNumber } from '~/utils/utils';
/** api */
import { getShippingForSubscription } from '~/api/api';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useSoftlineStateModelStore from '../stores/useSoftlineStateStore';

/** Специальный хук логики компонента softline */
export const useSoftline = (item?: SoftlineFields): SoftlineHookReturnProps => {
  const { softlineDescription } = useSoftlineStateModelStore();
  const softlineData = item ?? softlineDescription;
  const { trialInfo, durationDays, price, simId } = softlineData;

  // Контекст авторизации
  const {
    softlineStore: { shippingsForSubscriptions, setShippingsForSubscriptions },
  } = useRootStore();

  /** Округление до сотых, замена точки на запятую и деление разрядов чисел */
  const formattedPrice = useMemo(
    () => formatNumber(price, Number.isInteger(price)),
    [price],
  );
  const formattedPricePerMonth = useMemo(
    () => formatNumber(price * 30, Number.isInteger(price * 30)),
    [price],
  );

  /** Годовая подписка */
  const isAnnual = useMemo(() => durationDays === ANNUAL_SUBSCRIPTION, [
    durationDays,
  ]);

  /** Промо-период */
  const isPromo = useMemo(
    () => trialInfo?.isEnabled || trialInfo?.trialDays > 0,
    [trialInfo],
  );

  /** Список табов */
  const tabs = [TABS.DAY, TABS.MONTH];

  /** Исходный активный таб */
  const initialActiveTab = {
    index: 0,
    value: tabs[0],
  };

  /** Активный таб */
  const [activeTab, setActiveTab]: HooksTyping<ActiveTab> = useState<ActiveTab>(
    initialActiveTab,
  );

  /* Событие при изменении таба */
  const onChangeTab = (index: number, value: string) => {
    setActiveTab({ index, value });
  };

  /** Данные о дистрибутивах для подписки */
  const [shipping, setShipping] = useState<ShippingForSubscription>(null);

  /** Выбранный select ОС дистрибутива  */
  const [
    selectDistributionOS,
    setSelectDistributionOS,
  ]: HooksTyping<OptionProp> = useState<OptionProp>(null);

  /** Список дистрибутивов для select */
  const [distributionList, setDistributionList]: HooksTyping<
    OptionProp[]
  > = useState<OptionProp[]>(null);

  /** Ссылка на инструкцию */
  const [
    linkToInstallInstructions,
    setLinkToInstallInstructions,
  ]: HooksTyping<string> = useState<string>(null);

  /** Получение данных о дистрибутивах */
  const getShipping = async (id: number) => {
    try {
      const res = await getShippingForSubscription(id);
      setShipping(res);

      if (
        shippingsForSubscriptions &&
        !shippingsForSubscriptions.find((ship) => ship.simId === res.simId)
      )
        setShippingsForSubscriptions([...shippingsForSubscriptions, res]);
    } catch (e) {
      console.error('getShippingForSubscription', e);
    }
  };

  useEffect(() => {
    if (shippingsForSubscriptions) {
      const foundShipping = shippingsForSubscriptions.find(
        (ship) => ship.simId === simId,
      );
      setShipping((foundShipping as unknown) as ShippingForSubscription);
    } else {
      setShipping(null);
    }
  }, [shippingsForSubscriptions]);

  /** Устанавливает select согласно версии и разрядности ОС пользователя */
  const setDistributionOS = (list: DistrList[]) => {
    // Разрядность ОС
    const isX64 =
      navigator.userAgent.indexOf('WOW64') !== -1 ||
      navigator.userAgent.indexOf('Win64') !== -1;

    const getKey = (): OS_KEYS => {
      switch (true) {
        case isAndroid:
          return OS_KEYS.ANDLINK;
        case isMacOs:
          return OS_KEYS.MACLINK;
        case isIOS:
          return OS_KEYS.IOSLINK;
        case isWindows && isX64:
          return OS_KEYS.WINLINK64;
        case isWindows && !isX64:
          return OS_KEYS.WINLINK32;
        case window.navigator.platform === PLATFORM.LINUX32:
          return OS_KEYS.LINLINK32;
        case window.navigator.platform === PLATFORM.LINUX64:
          return OS_KEYS.LINLINK64;
        default:
          return OS_KEYS.WINLINK32;
      }
    };

    // Отображаем первым в списке либо файл соответствующий операционной системе,
    // либо Windows X32, либо первый из полученной коллекции
    const selectedDistr =
      list.find((listItem: DistrList) => listItem.code === getKey()) ?? list[0];

    setSelectDistributionOS({
      label: selectedDistr.name,
      value: selectedDistr.url,
    });
  };

  /** Логин */
  const [login, setLogin]: HooksTyping<string> = useState<string>(null);

  /** Пароль */
  const [password, setPassword]: HooksTyping<string> = useState<string>(null);

  /** Ключ активации */
  const [
    activationKey,
    setActivationKey,
  ]: HooksTyping<string> = useState<string>(null);

  useEffect(() => {
    /** Устанавливает список дистрибутивов для select */
    if (shipping?.distrList) {
      setDistributionOS(shipping.distrList);
      const list = shipping.distrList.map((distr: DistrList) => {
        return {
          label: distr.name,
          value: distr.url,
        };
      });
      setDistributionList(list);
    }

    /** Устанавливает ключ активации, логин, пароль */
    if (shipping?.keyList) {
      shipping.keyList.forEach((key: KeyList) => {
        if (key.key === INFO_KEYS.KEY) setActivationKey(key.value);
        if (key.key === INFO_KEYS.LOGIN) setLogin(key.value);
        if (key.key === INFO_KEYS.PASS) setPassword(key.value);
      });
    }

    /** Устанавливает ссылку на инструкцию */
    if (shipping?.guideList) {
      Object.keys(shipping?.guideList).forEach((guide) => {
        if (guide === INSTRUCTION)
          setLinkToInstallInstructions(shipping?.guideList[guide]);
      });
    }
  }, [shipping]);

  return {
    isAnnual,
    isPromo,
    tabs,
    activeTab,
    onChangeTab,
    selectDistributionOS,
    setSelectDistributionOS,
    distributionList,
    linkToInstallInstructions,
    getShipping,
    login,
    password,
    activationKey,
    formattedPrice,
    formattedPricePerMonth,
  };
};
