import styled from '@emotion/styled';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

// TODO удалить height, когда будет сделано меню
export const InnerFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  padding-bottom: 64px;

  .agreement {
    z-index: 10001;
  }

  /* отступ для шапки */
  .content {
    max-width: 100%;
    padding-top: 60px;

    .row:first-of-type {
      padding-top: 32px;
    }
  }

  @media (min-width: ${desktop940}px) {
    .content {
      padding-top: 120px;
    }
  }
`;
