/** библиотеки */
import styled from '@emotion/styled';
/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';
/** константы */
import { COLORS } from '../constants';
import {
  desktop1100,
  desktop700,
  desktop940,
} from '~/components/Grid/constants';

export const StyledSoftlineCard = styled.li<{
  $backgroundColor: COLORS;
  $borderColor: COLORS;
}>`
  ${({ $backgroundColor, $borderColor }) => {
    return `
      padding: 29px 27px 32px 32px;
      min-height: 213px;
      width: 100%;
      background: ${defaultTheme.colors[$backgroundColor]};
      border: 1px ${defaultTheme.colors[$borderColor]} solid;
      position: relative;
      display: flex;
      justify-content: left;
      flex-direction: column;

      @media (min-width: ${desktop940}px) {
        flex-direction: row;
      }

      .logo-block {
        width: 100%;
        margin-right: 32px;
        white-space: pre-wrap;

        &__image-wrapper {
          position: relative;
          max-width: 104px;
          margin-top: 6px;
        }

        &__content-wrapper {
          position: absolute;
          bottom: 32px;
          right: 32px;
          left: 32px;
        }

        &__tags {
          display: flex;
          margin-bottom: 16px;
          > div {
            margin-right: 11px;
          }
        }

        &__name {
          white-space: break-spaces;
          margin-top: 3px;
        }

        @media (min-width: ${desktop940}px) {
          width: 298px;
        }
      }

      .description-block {
        margin-right: 0;

        &__license {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 6px;

          &__col {
            width: 100%;

            span {
              display: block;
              margin-bottom: 9px;
            }

              h3 {
                font-size: 21px;
                margin-bottom: 12px;
              }

            @media (min-width: ${desktop1100}px) {
              h3 {
                font-size: 28px;
                margin-bottom: 30px;
              }
            }
          }


        }

        &__carousel-wrapper {
          margin-top: -2px;

          > div > div:last-of-type {
            width: auto;
          }

          .paginator-body {
            display: none;
          }

          .paginator-arrow {
            min-width: 16px;
            position: relative;
            top: -18px;
            left: 10px;

            svg {
              height: 14px;
            }
          }

          &__slide {
            width: 500px;
          }

          &__tags {
            margin-bottom: 8px;
          }
        }
        
        @media (min-width: ${desktop700}px) {
          margin-right: 63px;

          &__license {
            flex-direction: row;
            width: 566px;
            margin-top: 0;

            &__col {
              width: 283px;
            }
            &__col:first-of-type {
              margin-right: 19px;
            }
            span:nth-of-type(2) {
              margin-top: 11px;
            }
          }
        }
      }

      .price-block {
        width: 218px;
        margin-top: 18px;
        display: block;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__button {
          margin-top: 18px;
        }

        @media (min-width: ${desktop940}px) {
          margin-top: 34px;
          display: flex;

           &__button {
          margin-top: 0;
          }
        }

        @media (min-width: ${desktop1100}px) {
          margin-top: -2px;
          display: block;

           &__button {
          margin-top: 34px;
          }
        }
      }
      
      .x2-col {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: ${desktop1100}px) {
          flex-wrap: nowrap;
          margin-top: 4px;
        }
      }

    `;
  }}
`;
