/** библиотеки */
import Link from 'next/link';
import { H1, H3, Text, Link as CordisLink } from 'cordis-core-ui-planeta';

/** компоненты */
import { Row } from '~/components/Grid/Row';
import { Col } from '~/components/Grid/Col';

/** стилевые компоненты */
import { NotFoundStyled } from './NotFound.style';

/** Компонент-обёртка для формирования внутренней ссылки */
const NotFound: React.FC = () => (
  <>
    <Row>
      <Col size={1.5}>
        <NotFoundStyled>
          <H1>404</H1>
          <H3>Увы, но эта страница где-то затерялась в галактике Интернета</H3>
          <Text>
            Скорее всего, это случилось по одной из следующих причин: страница
            переехала или запрашиваемой страницы больше нет
          </Text>
          <div className="suggestions">
            <Text>
              Начните путешествие по сайту с{' '}
              <Link href="/">
                <span>
                  <CordisLink href="/">главной страницы</CordisLink>
                </span>
              </Link>
            </Text>
          </div>
        </NotFoundStyled>
      </Col>
      <Col size={1.5} />
    </Row>
  </>
);

export default NotFound;
