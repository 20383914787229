import { createContext, useContext } from 'react';
import MyAppealsModel, { IMyAppealsStore } from './MyAppealsModel';
import { MY_APPEALS_STATE } from '~/components/Blocks/Templates/Pab2c/Help/interfaces';

const store = MyAppealsModel.create({
  isShowMyAppeals: false,
  /** Клиент прочитал новые комментарии */
  isRead: false,
  typeOfApplication: null,
  state: MY_APPEALS_STATE.MAIN,
});

const MyAppealsStoreContext = createContext<IMyAppealsStore>(store);

const useMyAppealsStore = () => {
  return useContext(MyAppealsStoreContext);
};

export default useMyAppealsStore;
