/** Libraries */
import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
/* Constants */
import AUTH_QS from '~/components/Auth/Igrovoy/constants';
import { IGROVOY_SLUG } from '~/constants/common';
/** Auth checkers */
import checkIgrovoy from '~/components/Auth/Igrovoy';
/** Types */
import { OAuthServices } from '~/components/Auth/interfaces';
/** root */
import { useRootStore } from '~/stores/RootStore';

// Так как мы используем SSG, то QS и getServerSideProps нам недоступны
/** Обёртка для проверки авторизации сторонних приложений */
const Auth: FC = () => {
  const router = useRouter();
  const {
    authStore: { setIsOAuth },
  } = useRootStore();

  useEffect(() => {
    if (!router.isReady) return;

    const igrovoyClientId = router.query?.[AUTH_QS.CLIENT_ID];
    const igrovoyRedirectUri = router.query?.[AUTH_QS.REDIRECT_URI];

    if (igrovoyClientId && igrovoyRedirectUri)
      checkIgrovoy(
        igrovoyClientId as string,
        igrovoyRedirectUri as string,
        () => {
          setIsOAuth(OAuthServices.IGROVOY, true);
          router.push(
            `/${IGROVOY_SLUG}?redirect_uri=${igrovoyRedirectUri}`,
            `/${IGROVOY_SLUG}?redirect_uri=${igrovoyRedirectUri}`,
          );
        },
        () => router.push(`/${IGROVOY_SLUG}`, `/${IGROVOY_SLUG}`),
      );
  }, [router.isReady]);

  return <></>;
};

export default Auth;
