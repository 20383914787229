/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { useRouter } from 'next/router';
/** components */
import {
  defaultTheme,
  LeadingText,
  ButtonStyleTypes,
} from 'cordis-core-ui-planeta';
import Wrapper, {
  MainText,
  StyledImageWrapper,
  StyledButton,
  StyledFragmentsWrapper,
  StyledFrontsWrapper,
} from './styles';
/** config */
import CONFIG from '../../config';
/** constants */
import { desktop940 } from '~/components/Grid/constants';

/** Слайд Гигабит */
const Gigabit: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const imageLoader = ({ src, width: imgWidth, quality }) => {
    return `${src}?w=${imgWidth}&q=${quality || 75}`;
  };

  const router = useRouter();
  const redirect = () =>
    router.push(
      'news/25-03-2023?utm_source=planeta&utm_medium=20year&utm_campaign=gigabit-new',
      'news/25-03-2023?utm_source=planeta&utm_medium=20year&utm_campaign=gigabit-new',
    );

  if (!isDesktop940)
    return (
      <Wrapper $isMobile>
        <MainText
          dangerouslySetInnerHTML={{ __html: CONFIG[7].mainTextMobile }}
          as={LeadingText}
          color={defaultTheme.colors.white}
        />
        <StyledButton
          onClick={redirect}
          styleType={ButtonStyleTypes.DARK_BACKGROUND}
        >
          Подключить
        </StyledButton>
        <StyledImageWrapper>
          <Image
            loader={imageLoader}
            src={`${process.env.STATIC_SERVER}/${CONFIG[7].mainImageMobile}`}
            alt={CONFIG[7].mainText}
            objectFit="cover"
            layout="fill"
            quality={100}
          />
        </StyledImageWrapper>
      </Wrapper>
    );

  return (
    <Wrapper>
      <MainText
        dangerouslySetInnerHTML={{ __html: CONFIG[7].mainText }}
        as={LeadingText}
        color={defaultTheme.colors.white}
      />
      <StyledButton
        onClick={redirect}
        styleType={ButtonStyleTypes.DARK_BACKGROUND}
      >
        Подключить Гигабит
      </StyledButton>
      <StyledImageWrapper>
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${CONFIG[7].mainImage}`}
          alt={CONFIG[7].mainText}
          objectFit="cover"
          layout="fill"
          quality={100}
        />
      </StyledImageWrapper>
      <StyledFragmentsWrapper>
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${CONFIG[7].extraImage}`}
          alt={CONFIG[7].mainText}
          objectFit="cover"
          layout="fill"
          quality={100}
        />
      </StyledFragmentsWrapper>
      <StyledFrontsWrapper>
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${CONFIG[7].frontImage}`}
          alt={CONFIG[7].mainText}
          objectFit="cover"
          layout="fill"
          quality={100}
        />
      </StyledFrontsWrapper>
    </Wrapper>
  );
};

export default Gigabit;
