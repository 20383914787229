/** libraries */
import { flow, getParent, types } from 'mobx-state-tree';
/** interfaces */
import {
  ContactsModel,
  ExtendedContactTypeModel,
  InputValueModel,
} from './models';
import { Contacts } from '../interfaces';
import {
  ExtendedContactType,
  InputValue,
} from '../../../Templates/Pab2c/Settings/interfaces';
import { IContactsAndNotificationsStore } from './ContactsAndNotificationsModel';
/** conctants */
import {
  ADD_CONTACT_SP_STATE,
  CONTACT_TYPES,
  DEFAULT_ERROR,
  initialPhoneNumber,
} from '../../../Templates/Pab2c/Settings/constants';
/** api */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { sendVerificationMessage } from '~/api/apiPab2c';

const RequestsStateModel = types.model('State', {
  sendingCode: createApiPathModel(
    'GET /Contacts/Contact/SendVerificationMessage',
  ),
});

export const AddingContactStore = types
  .model('DeletingContact', {
    requestsState: RequestsStateModel,
    /** Сайдпейдж добавления контакта */
    isShowAddingContact: types.boolean,
    /** Информация по добавленному номеру */
    contactData: types.maybeNull(ContactsModel),
    /** Состояние сайдпейджа */
    addContactState: types.enumeration(Object.values(ADD_CONTACT_SP_STATE)),
    /** Тип контакта */
    contactType: types.maybeNull(ExtendedContactTypeModel),
    /** Подтверждаемый контакт */
    confirmContact: types.maybeNull(ContactsModel),
    /** Номер телефона для добавления */
    phoneNumber: InputValueModel,
    phoneNumberError: types.string,
    /** Email для добавления */
    email: types.string,
    emailError: types.string,
    /** Уведомления компании планета */
    isNotification: types.boolean,
    /** СМС код */
    code: types.string,
    codeError: types.string,
  })
  .volatile(() => ({
    /** Коллбэк для продолжения процесса смены продукта, в случае добавления номера */
    connectTariffCallback: null,
  }))
  .actions((self) => ({
    setIsShowAddingContact: (isShow: boolean, callback?: () => void) => {
      self.isShowAddingContact = isShow;
      self.connectTariffCallback = callback;
    },
    setAddContactState: (state: ADD_CONTACT_SP_STATE) => {
      self.addContactState = state;
    },
    setContactData: (contact: Contacts) => {
      self.contactData = contact;
    },
    setContactType: (contactType: ExtendedContactType) => {
      self.contactType = contactType;
    },
    setConfirmContact: (contact: Contacts) => {
      self.confirmContact = contact;
    },
    setPhoneNumber: (phone: InputValue) => {
      self.phoneNumber = phone;
    },
    setPhoneNumberError: (error: string) => {
      self.phoneNumberError = error;
    },
    setEmail: (email: string) => {
      self.email = email;
    },
    setEmailError: (error: string) => {
      self.emailError = error;
    },
    setIsNotification: (isNotification: boolean) => {
      self.isNotification = isNotification;
    },
    setCode: (code: string) => {
      self.code = code;
    },
    setCodeError: (error: string) => {
      self.codeError = error;
    },
    /** Выслать код */
    sendingCode: flow(function* () {
      self.requestsState.sendingCode.reset();
      self.requestsState.sendingCode.setLoading();
      try {
        yield sendVerificationMessage(self.contactData.id);
        self.addContactState =
          self.contactType.code === CONTACT_TYPES.PHONE
            ? ADD_CONTACT_SP_STATE.CODE
            : ADD_CONTACT_SP_STATE.RESULT;
        self.requestsState.sendingCode.setSuccess();
      } catch (e) {
        console.error(e, 'sendingCode');
        self.codeError = DEFAULT_ERROR;
        self.requestsState.sendingCode.setFail();
      }
    }),
    resetState: () => {
      self.connectTariffCallback = null;
      self.isShowAddingContact = false;
      self.phoneNumber = initialPhoneNumber;
      self.phoneNumberError = '';
      self.email = '';
      self.emailError = '';
      self.code = '';
      self.codeError = '';
      self.confirmContact = null;
      self.addContactState = ADD_CONTACT_SP_STATE.CONTACT;
      self.contactData = null;
      /** Обращаемся к родительскому стору */
      const ContactsAndNotificationsStoreInstance = getParent(
        self,
      ) as IContactsAndNotificationsStore;
      self.contactType = ContactsAndNotificationsStoreInstance.contactTypes.find(
        (type) => type.code === CONTACT_TYPES.PHONE,
      );
      ContactsAndNotificationsStoreInstance.getAllContacts();
      ContactsAndNotificationsStoreInstance.getAllSubscriptions();
    },
  }));

export const AddingContactStoreInstance = AddingContactStore.create({
  requestsState: {
    sendingCode: defaultModelState,
  },
  isShowAddingContact: false,
  addContactState: ADD_CONTACT_SP_STATE.CONTACT,
  contactData: null,
  contactType: null,
  confirmContact: null,
  phoneNumber: initialPhoneNumber,
  phoneNumberError: '',
  email: '',
  emailError: '',
  isNotification: true,
  code: '',
  codeError: '',
});
