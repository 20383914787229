/** библиотеки */
import { Dispatch, SetStateAction, MutableRefObject } from 'react';

/** api */
import {
  checkUrl,
  getCardPaymentLimit,
  getFeedBySlug,
  getSbpPaymentLimit,
} from '~/api/api';

/** типы */
import {
  BanksProps,
  BanksPropsData,
  PaymentContent,
} from '~/components/Blocks/Templates/Payment/types';
import { Block } from '~/interfaces/BlockInterface';

/** константы */
import { NO_CHECK_URL_AMOUNT } from './constants';
import { getImageSize } from '../../Shared/Shared.utils';
import { PAYMENT_SLUG } from '~/constants/common';

/**
 * Проверяет доступ до эквайринга и при его появлении осуществляет переход
 * @param url - url
 * @param fetchAmountCounter - счётчик
 * @param setResult - функция перехода на страницу с ошибкой
 * @param onUrlAccess - функция по доступности адреса оплаты
 * @param setIsLoading - dispatch of loader
 */
export const checkUrlAccess = async (
  url: string,
  fetchAmountCounter: MutableRefObject<number>,
  setResult?: (isCorrect) => void,
  onUrlAccess?: () => void,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
): Promise<void> => {
  if (fetchAmountCounter.current === NO_CHECK_URL_AMOUNT) return;
  if (!fetchAmountCounter.current) {
    if (setResult) setResult(false);
    return;
  }

  try {
    const res = await checkUrl(url);
    if (res === 0) {
      fetchAmountCounter.current = NO_CHECK_URL_AMOUNT;
      if (onUrlAccess) {
        onUrlAccess();
        return;
      }
      window.location.assign(url);
      if (setIsLoading) setIsLoading(false);
    } else {
      fetchAmountCounter.current -= 1;
    }
    // Рекурсивно допустимо
    setTimeout(
      checkUrlAccess,
      3000,
      url,
      fetchAmountCounter,
      setResult,
      onUrlAccess,
      setIsLoading,
    );
  } catch (e) {
    fetchAmountCounter.current -= 1;
    setTimeout(
      checkUrlAccess,
      3000,
      url,
      fetchAmountCounter,
      setResult,
      onUrlAccess,
      setIsLoading,
    );
  }
};

/**
 * Проверяет доступ до эквайринга и при его появлении осуществляет переход для мобильной версии
 * @param url - url
 * @param fetchAmount - счётчик
 * @param setFetchAmount - установщик счетчика
 * @param setResult - функция перехода на страницу с ошибкой
 * @param onUrlAccess - функция по доступности адреса оплаты
 */
export const checkUrlAccessMobile = async (
  url: string,
  fetchAmount: number,
  setFetchAmount: (num: number) => void,
  setResult?: (isCorrect) => void,
  onUrlAccess?: () => void,
): Promise<void> => {
  if (fetchAmount === NO_CHECK_URL_AMOUNT) return;
  if (!fetchAmount) {
    if (setResult) setResult(false);
    return;
  }

  try {
    const res = await checkUrl(url);
    if (res === 0) {
      setFetchAmount(NO_CHECK_URL_AMOUNT);
      if (onUrlAccess) {
        onUrlAccess();
        return;
      }
      window.location.assign(url);
    } else {
      setFetchAmount(fetchAmount - 1);
    }
    // Рекурсивно допустимо
    setTimeout(checkUrlAccess, 3000, url, fetchAmount, setResult, onUrlAccess);
  } catch (e) {
    setFetchAmount(fetchAmount - 1);
    setTimeout(checkUrlAccess, 3000, url, fetchAmount, setResult, onUrlAccess);
  }
};

// todo: исследовать масштабирование для SSG
// Масштабирует размеры логотипа банка
export const banksListImageConverter = async (
  res: BanksPropsData[],
): Promise<BanksProps[]> => {
  const getBase64 = (objItem) => {
    return new Promise((resolved) => {
      if (!objItem?.logo) {
        resolved({ width: 0, height: 0, ...objItem });
        return;
      }
      const newImg = new Image();
      newImg.src = `data:image/png;base64, ${objItem.logo}`;
      newImg.onload = () => {
        const { newHeight, newWidth } = getImageSize(
          newImg.height,
          newImg.width,
          32,
        );
        // Масштабируем по ширине, сознательно меняем высоту и ширину местами
        resolved({ width: newHeight, height: newWidth, ...objItem });
      };
    });
  };
  const promises = [];
  if (res?.length) res.forEach((item) => promises.push(getBase64(item)));

  const result = await Promise.all(promises);
  return result;
};

/**
 * возвращает блок payment дополненный полями
 * @param {Block} block
 */
export const getDataForPayment = async (
  block: Block,
  preview?: boolean,
): Promise<PaymentContent> => {
  const [promo, cardLimit, sbpLimit] = await Promise.all([
    getFeedBySlug(PAYMENT_SLUG, preview),
    getCardPaymentLimit(),
    getSbpPaymentLimit(),
  ]);
  const fields = {
    ...block.fields,
    promo: promo.fields.paymentPromo,
    cardLimit,
    sbpLimit,
  };
  return {
    ...block,
    fields,
  };
};
