/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** Константы */
import {
  desktop450,
  desktop940,
  desktop1280,
} from '~/components/Grid/constants';

export const StyledResiliency = styled.div`
  min-height: 400px;
  padding: 30px;
  background: ${defaultTheme.colors.planeta};

  h3 {
    margin-bottom: 16px;
  }

  .resiliencyBanner {
    &__wrapper-animation {
      max-width: 333px;
    }

    &__animation-block {
      position: relative;
      min-height: 226px;
    }

    &__list {
      padding: 0;
      list-style-type: none;

      &--animation-text {
        display: flex;

        & > li + li {
          margin-left: 4px;
          margin-top: 0;
        }
      }
    }

    &__character {
      flex-shrink: 0;
      position: relative;
      width: 160px;
      height: 196px;
      overflow: hidden;
    }

    &__title-number {
      position: absolute;
      right: 2px;
      bottom: 5px;
      text-align: right;
      color: #24ff00;
    }

    &__text {
      max-width: 350px;
    }

    @media (min-width: ${desktop940}px) and (max-width: ${desktop1280}px),
      (max-width: ${desktop450}px) {
      &__animation-block {
        width: 232px;
        height: 163px;
        min-height: unset;
      }

      &__list {
        &--animation-text {
          position: relative;
          right: 30px;
          bottom: 30px;
          transform: scale(0.7);
        }
      }

      &__title-number {
        right: 0;
        bottom: 0;
        font-size: 20px;
      }
    }
  }
`;
