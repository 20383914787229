/** libraries */
import styled from '@emotion/styled';

/** constants */
import { desktop500 } from '~/components/Grid/constants';

/** styles */
import { StyledOptionBlock } from '../../common/OptionBlock/styles';

export const StyledViewControlConnectionWizard = styled.div`
  .view-control-connection {
    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      &__toggle-tabs {
        > div {
          > div:nth-of-type(2) {
            width: auto;
            .colorContainer {
              width: 100%;
            }
          }
        }
      }

      > div:first-of-type {
        margin-bottom: 16px;
      }

      ${StyledOptionBlock} {
        padding: 0;
      }
    }

    &__block {
      margin-bottom: 32px;

      > span:nth-of-type(1) {
        margin-bottom: 8px;
      }
    }

    &__shutdown-time {
      margin-bottom: 32px;
    }
  }

  @media (min-width: ${desktop500}px) {
    .view-control-connection {
      &__wrapper {
        flex-direction: row;

        > div:first-of-type {
          margin-bottom: 0;
        }

        ${StyledOptionBlock} {
          padding-left: 60px;
        }
      }
    }
  }
`;
