/** libraries */
import { FC } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** utils */
import { getHeaderPhone } from '~/components/Header/utils';
/** types */
import { PHONE_TEXT } from '~/components/Blocks/Templates/Pab2c/ContractState/config';
/** components */
import { LeadingText, Link, defaultTheme, Text } from 'cordis-core-ui-planeta';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
  max-width: 250px;
`;

export type PhoneConnectionType = {
  text?: PHONE_TEXT;
};

const PhoneConnection: FC<PhoneConnectionType> = ({
  text = PHONE_TEXT.SUSPENDED,
}) => {
  const {
    cityStore: { city, contactPhones },
  } = useRootStore();
  const headerPhone = getHeaderPhone(contactPhones, city);
  const planetaPhone = headerPhone.replace(/\D/g, '');

  return (
    <Root>
      <Link href={`tel:${planetaPhone}`}>
        <LeadingText color={defaultTheme.colors.white}>
          {headerPhone}
        </LeadingText>
      </Link>
      <Text color={defaultTheme.colors.disable}>{text}</Text>
    </Root>
  );
};

export default observer(PhoneConnection);
