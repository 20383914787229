/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop650,
  desktop750,
  desktop900,
  desktop940,
} from '~/components/Grid/constants';
import { ServiceCardStyled } from '../../Cards/ServiceCards/styles';
import {
  StyledRowContentBody,
  StyledRowContentTitle,
} from '../../common/RowContent/styles';

export const StyledPackageDescription = styled.div`
  .package-description {
    &__header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid ${defaultTheme.colors.line};
      margin-bottom: 24px;
      padding-bottom: 24px;

      &__block {
        display: flex;
        justify-content: space-between;

        h3 {
          letter-spacing: -0.03em;
          margin-bottom: 0;
        }
      }

      &__close-icon {
        cursor: pointer;
      }

      > div[data-test-tag] {
        display: flex;
        margin-top: 6px;
        line-height: 0;
        > div {
          padding: 1px 8px;
        }
      }
    }

    &__subcription-cost {
      h3 {
        margin-bottom: 0px;
      }

      &__button-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 19px;

        > span {
          width: 193px;
          margin: 16px 0 0 0;
        }
      }
    }

    &__transformer {
      &__button-block {
        margin-top: 32px;

        .scale {
          margin: 0 0 7px;
        }

        &__refusal-block {
          display: flex;
          flex-direction: column;
          margin-top: 16px;

          > span {
            margin-top: 8px;
          }
          button {
            height: 54px;
          }
        }
      }
    }

    &__channels-n-services {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
      margin-bottom: 36px;
      width: auto;
      padding-right: 2px;

      ${ServiceCardStyled} {
        cursor: default;
      }
    }

    &__channels-n-services::-webkit-scrollbar {
      width: 5px;
    }

    &__channels-n-services::-webkit-scrollbar-track {
      background-color: ${defaultTheme.colors.white};
      border-radius: 10px;
    }

    &__channels-n-services::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${defaultTheme.colors.lightGrey};
    }

    &__add-all-channels {
      button {
        height: 54px;
        margin-top: 16px;
      }
    }
  }

  ${StyledRowContentBody} {
    max-width: -webkit-fill-available;
  }

  @media (min-width: ${desktop500}px) {
    .package-description {
      &__transformer {
        &__button-block {
          &__refusal-block {
            flex-direction: row;
            align-items: center;

            > span {
              margin: 0 0 0 16px;
            }
          }
        }
      }

      &__channels-n-services {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media (min-width: ${desktop650}px) {
    .package-description {
      &__channels-n-services {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  @media (min-width: ${desktop750}px) {
    .package-description {
      &__channels-n-services {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }

  @media (min-width: ${desktop900}px) {
    .package-description {
      &__channels-n-services {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .package-description {
      &__subcription-cost {
        &__button-block {
          flex-direction: row;
          align-items: center;

          > span {
            width: 192px;
            margin: 0 0 0 16px;
          }
        }
      }

      &__channels-n-services {
        display: flex;
        gap: 24px;
        width: 670px;
        > div {
          width: 307px;
        }
      }
    }
    ${StyledRowContentTitle} {
      flex-direction: column;
      min-width: 170px;
      max-width: 170px;
      padding: 0;
      margin-right: 32px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    .package-description {
      &__channels-n-services {
        width: 805px;
        > div {
          width: 241px;
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 215px;
      max-width: 215px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    .package-description {
      &__channels-n-services {
        width: 940px;
        > div {
          width: 287px;
        }
      }
    }
    ${StyledRowContentTitle} {
      min-width: 260px;
      max-width: 260px;
    }
  }
`;
