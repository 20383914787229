/** libraries */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ColorsType, defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledFillableScale = styled.div<{
  completed: number;
  fillerColor: ColorsType;
}>`
  ${({ completed, fillerColor }) => {
    return css`
      display: flex;
      flex-direction: column;
      width: auto;
      margin: 0 0 16px 0;

      .scale {
        height: 12px;
        width: 100%;
        ${completed !== 100 && `border: 1px solid ${defaultTheme.colors.line};`}
        border-radius: 20px;
        margin: 5px 0 11px;

        &__filler {
          height: 100%;
          width: ${completed}%;
          background-color: ${fillerColor};
          border-radius: 50px;
          text-align: right;
        }
      }

      .text {
        width: max-content;
      }

      @media (min-width: ${desktop940}px) {
        width: 282px;
        margin: 0 24px 0 0;
      }
    `;
  }}
`;
