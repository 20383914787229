import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop400,
  desktop600,
  desktop940,
} from '~/components/Grid/constants';

export const StyledCreatingRule = styled.div`
  h3 {
    margin-bottom: 0;
  }
  .rules {
    &__info-block {
      display: flex;
      flex-wrap: wrap;
      &__block {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        > span:first-of-type {
          margin-bottom: 8px;
        }
      }
      &__block:first-of-type {
        margin-right: 24px;
      }
    }

    &__checkbox-block {
      display: flex;
      flex-direction: column;
      margin: 16px 0 0;
      > span:first-of-type {
        margin-bottom: 8px;
      }
      &__block {
        display: flex;
        &__checkbox {
          margin-right: 16px;
          tr {
            display: flex;
            flex-direction: column;
            > td:first-of-type {
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    &__time {
      display: flex;
      flex-direction: column;
      > span:first-of-type {
        margin-bottom: 8px;
      }
      &__block {
        display: flex;
        flex-direction: column;
        &__selects {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > div {
            width: 43%;
            & > div {
              width: 100%;
              .select-wrapper {
                width: 100%;
              }
            }
          }
        }

        &__checkbox {
          display: block;
          margin: 0 0 24px;
        }
      }
    }

    &__mobile-select {
      height: 100vh;
      width: 100%;
      background: ${defaultTheme.colors.white};
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__tag-button {
        height: 52px;
      }

      button {
        width: 100%;
        margin-top: 32px;
      }
    }
  }

  .sidepage {
    &__left-icon-block {
      &__icon {
        margin-top: 28px;

        svg {
          margin-top: 0;
        }
      }
    }
  }

  @media (min-width: ${desktop400}px) {
    .rules {
      &__time {
        &__block {
          &__selects {
            > div {
              width: 47%;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop600}px) {
    .rules {
      &__time {
        &__block {
          &__selects {
            > div {
              width: 48%;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .rules {
      &__time {
        &__block {
          flex-direction: row;
          align-items: center;
          &__selects {
            justify-content: start;
            > div {
              width: 123px;
            }

            > div,
            > span {
              margin-right: 8px;
            }
          }

          &__checkbox {
            margin: 0 0 0 16px;
          }
        }
      }
    }
  }
`;

export const StyledCreatingRuleFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
    margin: 0 0 16px;
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    > span {
      width: fit-content;
    }
  }
  .cursor {
    cursor: pointer;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    justify-content: space-between;
    button {
      width: fit-content;
      margin: 0;
    }
    .delete {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
    }
  }
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;

  > svg {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  button {
    width: fit-content;
  }

  .result {
    &__time {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }
    }
    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      width: 100%;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    h1 {
      font-size: 44px;
      line-height: 52px;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;
