/* Библиотеки */
import * as React from 'react';

/* Типы */
import {
  Button,
  ButtonIconPosition,
  ButtonStyleTypes,
  defaultTheme,
  H2,
  Icons,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import {
  CallToActionProductProps,
  TagsProps,
} from './CallToActionProduct.types';
import { ConnectionMethod } from '~/constants/common';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';

/* Стили */
import { StyledLeftCol, StyledRightCol } from './CallToActionProduct.styles';

/* Дочерний компонент "Продукт", компонента CallToAction */
const CallToActionProduct: React.FC<CallToActionProductProps> = ({
  seriesName,
  tags,
  marketingGroupName,
  marketingText,
  showSettings,
  setShowSettings,
  connectionType,
  connectionPrice,
  isAnnual,
  isSwitchOffTV,
  isSwitchOffRouter,
  isSwitchOffSTB,
  isSwitchOffRemote,
  setEnabledTimeShift,
  setEnabledRouter,
  setEnabledSTB,
  setEnabledRemote,
  customHeader,
}: CallToActionProductProps) => {
  /* Сортирует тэги */
  const sortTags = (prev: TagsProps, next: TagsProps): number => {
    if (prev.sort > next.sort) return 1;
    if (prev.sort < next.sort) return -1;
    return 0;
  };

  /* Показывает настройки продукта */
  const openSettings = () => {
    setShowSettings(true);
    setEnabledTimeShift(!isSwitchOffTV);
    setEnabledRouter(!isSwitchOffRouter);
    setEnabledSTB(!isSwitchOffSTB);
    setEnabledRemote(!isSwitchOffRemote);
  };

  const showBenefit: boolean =
    connectionType === ConnectionMethod.LAN &&
    !isAnnual &&
    connectionPrice > 0 &&
    showSettings;

  return (
    <CallToActionRow title={<Text>Продукт</Text>}>
      <StyledLeftCol>
        {tags?.length > 0 && (
          <div>
            {tags.sort(sortTags).map((item) => (
              <Tag
                // имя тега уникально, повторений не будет
                key={item.name}
                color={defaultTheme.colors.planeta}
                colorTag={defaultTheme.colors.pink}
              >
                {item.name.toUpperCase()}
              </Tag>
            ))}
          </div>
        )}
        {customHeader && (
          <H2 dangerouslySetInnerHTML={{ __html: customHeader }} />
        )}
        {!customHeader && <H2>{seriesName}</H2>}
        {/* yaspeller ignore:start */}
        <Text color={defaultTheme.colors.gray}>
          {showBenefit
            ? `Стоимость подключения\nк продукту ${connectionPrice}\u0020\u20bd`
            : marketingGroupName}
        </Text>
        {/* yaspeller ignore:end */}
        <Text dangerouslySetInnerHTML={{ __html: marketingText }} />
        {!showSettings && (
          <Button
            styleType={ButtonStyleTypes.OPENED}
            icon={<Icons.OpenIcon />}
            iconPosition={ButtonIconPosition.RIGHT}
            onClick={openSettings}
          >
            Настроить
          </Button>
        )}
      </StyledLeftCol>
      <StyledRightCol>
        <Text dangerouslySetInnerHTML={{ __html: marketingText }} />
      </StyledRightCol>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionProduct);
