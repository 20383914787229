/** библиотеки */
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** компоненты */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H1,
  H2,
  Text,
} from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import * as Banner from '../Banner.types';

/** стили */
import { StyledBannerContent, StyledBannerImgDiv, StyledImage } from './style';

/** размеры экрана */
import { desktop940 } from '~/components/Grid/constants';

/** constants */
import { PLANETA_URL } from '~/constants/common';
import { CHANNELS_DECLINATION } from '../../Advantages/UltraHD/UltraHD';
import { AUTO_TEXT } from '../constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
/** utils */
import { pluralizeAll } from '~/utils/utils';

/**
 * Компонент "Страница баннера"
 * */
const SingleBanner: React.FC<Banner.BannerFields> = ({
  link,
  body,
  header,
  imgLink,
  smallImgLink,
  isCenterImg,
  title,
  size,
  borderRadius,
  textColor,
  isStoriesHeader,
  target,
  headerImgLink,
  headerImgSize,
  buttonSettings,
  autoText,
  channelsLength,
}) => {
  /* Уникальный идентификатор для элемента. */
  const [id] = useState<string>(nanoid);

  const { toggleConnectionWizardVisible } = useConnectionStore();
  const { toggleCallbackWizardVisible } = useCallbackStore();
  const {
    authStore: { isMenuOpen, setIsMenuOpen },
  } = useRootStore();

  /** Вычисление стиля в зависимости от размеров блока */
  const createBlockStyle = (
    blockSize: number,
  ): Banner.bannerBlockStyleTypes => {
    let style;
    switch (blockSize) {
      default:
      case 1:
        style = Banner.bannerBlockStyleTypes.SMALL;
        break;
      case 1.5:
        style = Banner.bannerBlockStyleTypes.HALF;
        break;
      case 2:
        style = Banner.bannerBlockStyleTypes.MEDIUM;
        break;
      case 3:
        style = Banner.bannerBlockStyleTypes.LARGE;
        break;
    }
    return style;
  };

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const router = useRouter();
  // Урл страницы
  const url = new URL(document.location.href);

  const redirectToConnectionParam = (): void => {
    if (!buttonSettings?.connectionParam) return;
    router.push(
      `${url.origin}${url.pathname}?${buttonSettings.connectionParam}`,
      `${url.origin}${url.pathname}?${buttonSettings.connectionParam}`,
      {
        shallow: true,
      },
    );
  };

  const onBannerClick = () => {
    if (isMenuOpen) setIsMenuOpen(false);
    if (
      buttonSettings?.buttonWidget !== Banner.buttonWidgetTypes.ConnectWidget ||
      isDesktop940
    )
      return;
    redirectToConnectionParam();
    toggleConnectionWizardVisible();
  };

  /**
   * Обработка клика по кнопке или слайду
   * @param {Banner.buttonWidgetTypes | undefined} widgetCode код виджета
   * */
  const handlerClick = (
    widgetCode: Banner.buttonWidgetTypes | undefined,
  ): void => {
    switch (widgetCode) {
      case Banner.buttonWidgetTypes.CallbackWidget:
        toggleCallbackWizardVisible();
        break;
      case Banner.buttonWidgetTypes.ConnectWidget:
        redirectToConnectionParam();
        toggleConnectionWizardVisible();
        break;
      default:
        break;
    }
  };

  /** Событие клика по кнопке
   * @param {React.MouseEvent} e
   */
  const handlerClickButton = (e: React.MouseEvent): void => {
    if (!buttonSettings?.buttonLink) e.preventDefault();
    handlerClick(buttonSettings?.buttonWidget);
  };

  /** Для проверки, потом удалить */
  const img = `${PLANETA_URL}${imgLink}`;
  const smallimg = `${PLANETA_URL}${smallImgLink}`;

  /** Ссылка на баннере */
  const href = (): string => {
    if (link) return link;
    if (buttonSettings?.buttonLink && !isDesktop940)
      return buttonSettings?.buttonLink;
    return null;
  };

  /** Загрузчик изображения */
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <LinkWrapper href={href()} target={target}>
      <div role="button" title={title} tabIndex={0} onClick={onBannerClick}>
        <StyledBannerImgDiv
          key={id}
          url={img}
          smallUrl={smallimg}
          isCenterImg={isCenterImg}
          borderRadius={borderRadius}
        >
          <div className={`banner ${createBlockStyle(size)}`}>
            <StyledBannerContent isITV={autoText === AUTO_TEXT.ITV}>
              {headerImgLink && isDesktop940 && (
                <StyledImage>
                  <Image
                    loader={imageLoader}
                    src={`${process.env.STATIC_SERVER}${headerImgLink}`}
                    width={headerImgSize?.width ?? 110}
                    height={headerImgSize?.height ?? 30}
                    quality={100}
                    layout="fixed"
                    objectFit="fill"
                    objectPosition="left"
                  />
                </StyledImage>
              )}
              {autoText === AUTO_TEXT.ITV ? (
                <>
                  {!isDesktop940 ||
                  createBlockStyle(size) ===
                    Banner.bannerBlockStyleTypes.SMALL ? (
                    <H2
                      color={textColor || defaultTheme.colors.white}
                      dangerouslySetInnerHTML={{
                        __html: `Смотрите ${pluralizeAll(
                          channelsLength,
                          CHANNELS_DECLINATION,
                        )}`,
                      }}
                      isStories={isStoriesHeader}
                    />
                  ) : (
                    <H1
                      color={textColor || defaultTheme.colors.white}
                      dangerouslySetInnerHTML={{
                        __html: `Смотрите ${pluralizeAll(
                          channelsLength,
                          CHANNELS_DECLINATION,
                        )}`,
                      }}
                      isStories={isStoriesHeader}
                    />
                  )}
                </>
              ) : (
                <>
                  {header &&
                    (!isDesktop940 ||
                    createBlockStyle(size) ===
                      Banner.bannerBlockStyleTypes.SMALL ? (
                      <H2
                        color={textColor || defaultTheme.colors.white}
                        dangerouslySetInnerHTML={{ __html: header }}
                        isStories={isStoriesHeader}
                      />
                    ) : (
                      <H1
                        color={textColor || defaultTheme.colors.white}
                        dangerouslySetInnerHTML={{ __html: header }}
                        isStories={isStoriesHeader}
                      />
                    ))}
                  {body && (
                    <Text
                      color={textColor || defaultTheme.colors.white}
                      dangerouslySetInnerHTML={{ __html: body }}
                    />
                  )}
                </>
              )}
              {buttonSettings?.buttonText && isDesktop940 && (
                <LinkWrapper href={buttonSettings?.buttonLink} target={target}>
                  <Button
                    styleType={ButtonStyleTypes.SECONDARY}
                    border={`1px solid ${
                      buttonSettings?.buttonBorderColor ||
                      defaultTheme.colors.white
                    }`}
                    background={
                      buttonSettings?.buttonBackgroundColor || 'inherit'
                    }
                    color={
                      buttonSettings?.buttonTextColor ||
                      defaultTheme.colors.white
                    }
                    onClick={handlerClickButton}
                  >
                    {buttonSettings?.buttonText}
                  </Button>
                </LinkWrapper>
              )}
            </StyledBannerContent>
          </div>
        </StyledBannerImgDiv>
      </div>
    </LinkWrapper>
  );
};

export default observer(SingleBanner);
