/** библиотеки */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** типы */
import { StyledDevicesItemImageProps } from './interfaces';

/** константы */
import { FIXED_CARD_WIDTH } from './constants';

/** константы */
import { desktop500, desktop940 } from '~/components/Grid/constants';

export const StyledDevicesItemImage = styled.div<StyledDevicesItemImageProps>`
  ${({ imgSrc, imgSizes, isPab2cView }: StyledDevicesItemImageProps) => css`
    height: ${imgSizes && isPab2cView ? imgSizes.newHeight : '100'}px;
    width: ${imgSizes && isPab2cView ? imgSizes.newWidth : '100'}px;
    background: url(${imgSrc}) center center no-repeat;
    background-size: contain;
    ${!isPab2cView &&
    `@media (min-width: ${desktop940}px) {
        height: 142px;
        width: 142px;
    }`}
  `}
`;

export const StyledDevicesItem = styled.li<{
  isFixedWidth: string;
  isPab2cView: boolean;
  isArchive: boolean;
}>`
  ${({ isFixedWidth, isPab2cView, isArchive }) => css`
    background: ${defaultTheme.colors.white};
    cursor: pointer;
    position: relative;
    ${isPab2cView
      ? css`
          width: ${FIXED_CARD_WIDTH[isFixedWidth].MOBILE}px;
          padding: 0 95px 0 0;
        `
      : css`
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 16px;
        `}

    .devices {
      &__item-image-wrapper {
        margin-bottom: 16px;
        display: flex;
        align-items: ${isPab2cView ? 'end' : 'center'};
        ${!isPab2cView && 'height: 100px; justify-content: center;'}
      }

      &__item-tag {
        height: 20px;
        display: flex;
        justify-content: center;
        width: 100%;
        ${isPab2cView &&
        css`
          position: absolute;
          justify-content: start;
          top: 0;
        `}
        > div {
          width: max-content;
          margin-right: 8px;
        }
      }

      &__item-title {
        display: flex;
        flex-direction: column;
        ${isPab2cView
          ? css`
              height: 73px;
              margin: 3px 0 8px 0;
              justify-content: flex-end;
            `
          : css`
              width: fit-content;
              justify-content: center;
              align-items: center;
              margin: 4px 0 12px 0;
              > span {
                width: fit-content;
              }
              &__name-block {
                display: flex;
                align-items: baseline;
                justify-content: center;
                flex-wrap: wrap;
                > span {
                  margin-right: 8px;
                  width: max-content;
                }
              }
            `}
      }

      &__color-bullets {
        display: flex;
        width: fit-content;
        height: 25px;
        align-items: center;
        > div {
          margin-right: 3px;
        }
      }

      &__item-tabs {
        height: 24px;
        white-space: nowrap;
        overflow: auto;
        overflow-y: hidden;

        > div[data-test-tabs] {
          span {
            font-size: 11px;
            line-height: 16px;
            letter-spacing: -0.01em;
          }
          > div:nth-of-type(n + 2) {
            margin-left: 8px;
          }
        }

        > span {
          line-height: 24px;
          letter-spacing: -0.03em;
        }

        &__button-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 8px 0 0 0;
          > button {
            margin: 8px 0 0 0;
          }
          span {
            letter-spacing: -0.03em;
          }
        }
      }

      &__item-ownership {
        display: block;
      }

      &__item-payment {
        display: flex;
        align-items: center;
        height: 25px;
        > span {
          margin-right: 10px;
        }
      }
    }

    @media (min-width: ${desktop500}px) {
      .devices {
        &__item-tabs {
          > div[data-test-tabs] {
            span {
              font-size: 16px;
              line-height: 21px;
            }
          }
        }
      }
    }

    @media (min-width: ${desktop940}px) {
      ${!isPab2cView
        ? css`
            margin: 0 32px 32px 0;
            padding: 32px 32px 32px 30px;
            height: 400px;
            width: calc((100% - 64px) / 3);
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
            .devices {
              &__item-image-wrapper {
                height: 142px;
              }
              &__item-title {
                justify-content: start;
                align-items: start;
                margin-top: ${isArchive ? '38px' : 'auto'};
                margin-bottom: ${isArchive ? '8px' : '0'};
                &__name-block {
                  justify-content: start;
                  > span {
                    width: auto;
                  }
                }
              }
              &__item-tabs {
                margin: 3px 0 0 0;
                width: 100%;
                overflow: hidden;

                &__button-block {
                  flex-direction: row;
                  align-items: baseline;
                  margin: 10px 0 0 0;
                  > span:first-of-type {
                    width: max-content;
                    margin: 0 16px 0px 0;
                  }
                  > button {
                    margin: 0;
                  }
                }
              }
              &__item-tag {
                display: flex;
                justify-content: start;
                position: absolute;
                top: 30px;
                > div {
                  width: max-content;
                  margin-right: 8px;
                }
              }
            }
          `
        : css`
            width: ${FIXED_CARD_WIDTH[isFixedWidth].DESKTOP}px;
            height: 320px;
            padding: 0 30px 29px 0;
          `}
    }
  `}
`;

export const StyledBullet = styled.div<{ hex: string }>`
  ${({ hex }) => {
    return css`
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: ${hex};
    `;
  }}
`;
