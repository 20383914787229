import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledBasicPackage = styled.div`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  > span:first-of-type {
    padding-top: 40px;
    border-top: 1px solid ${defaultTheme.colors.disable};
  }
  > span {
    margin-bottom: 25px;
    padding: 0 16px;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 550px;
  }
`;
