/** библиотеки */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** типы */
import { StyledAnnouncementProps } from './Announcements.types';

/** константы */
import { desktop450, desktop940 } from '~/components/Grid/constants';

export const StyledAnnouncement = styled.div<StyledAnnouncementProps>`
  ${({ blockWidth, isStories }) => {
    const imageBorderRadius = `
    img {
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
    }`;
    return css`
      display: block;
      width: ${blockWidth ? `${blockWidth}px` : 'auto'};
      height: ${blockWidth ? '400px' : 'auto'};
      cursor: pointer;

      .announcement {
        &__image {
          position: relative;
          width: 100%;
          height: ${isStories ? '240px' : '200px'};
          ${isStories && imageBorderRadius}
        }

        &__default-image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px;
          width: 100%;
          background: ${defaultTheme.colors.disable};
        }

        &__content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 200px;
          padding: ${isStories ? '32px' : '16px 27px 39px 32px'};
          background: ${isStories
            ? defaultTheme.colors.pinkSelect
            : defaultTheme.colors.white};
          ${isStories &&
          `
            border-radius: 50px;
            position: relative;
            bottom: 40px;
          `}
        }

        &__text {
          > span:first-of-type {
            display: ${isStories ? 'none' : 'block'};
          }
        }

        &__date {
          display: ${isStories ? 'none' : 'block'};
        }

        &__title {
          ${!isStories
            ? `
          overflow: hidden;
          text-overflow: ellipsis;
          height: 65px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        `
            : `
          height: auto;
          word-break: break-word;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        `}
        }
      }

      h3 {
        margin-top: ${isStories ? 0 : '8px'};
        color: ${isStories
          ? defaultTheme.colors.white
          : defaultTheme.colors.black};
      }

      @media (min-width: ${desktop450}px) {
        .announcement {
          &__image,
          &__default-image {
            height: calc(50vw - 40px);
            ${isStories && imageBorderRadius}
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .announcement {
          &__image,
          &__default-image {
            height: ${isStories ? '240px' : '200px'};
            ${isStories && imageBorderRadius}
          }
        }
      }
    `;
  }}
`;
