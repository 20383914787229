import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledFooter = styled.div`
  .legal {
    width: 100%;
    margin: 16px 0 0 0;
    font-size: 11px;

    a {
      color: ${defaultTheme.colors.planeta};
    }
    &__link {
      cursor: pointer;
    }
  }

  button {
    width: 100%;
  }

  .button-block {
    display: flex;
    flex-direction: column;

    > span {
      margin: 8px 0 0 0;
    }
  }

  @media (min-width: ${desktop940}px) {
    button {
      width: fit-content;
    }
    .button-block {
      flex-direction: row;
      align-items: center;

      > span {
        width: 200px;
        margin: 0 0 0 16px;
      }
    }
  }
`;

export const StyledSnoska = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
