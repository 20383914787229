/** библиотеки */
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

/** размеры экрана */
import { Text, defaultTheme, H3, Link } from 'cordis-core-ui-planeta';
import { desktop940, desktop1100 } from '~/components/Grid/constants';

/** компоненты библиотеки */

/** стили */
import {
  StyledFeedbackBlock,
  StyledRatingPointsList,
  StyledSourceLinkBlock,
} from '../style';

/**
 * компонент Отзыв с Яндекса
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=403095610
 */
const FeedbackYandex4: React.FC = () => {
  /** Вычисление ширины экрана */
  const is1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const is940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const FeedbackTextComponents = {
    H3,
    Text,
  };

  const FeedbackTextComponent =
    !is1100 && is940 ? FeedbackTextComponents.Text : FeedbackTextComponents.H3;

  const ratingPoints = ['*', '*', '*', '*', '*'];

  const Star = (): JSX.Element => (
    <svg
      width="31"
      height="31"
      viewBox="2 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0L21.6964 10.536L33.168 11.7467L24.5989 19.469L26.9923 30.7533L17 24.99L7.00765 30.7533L9.40106 19.469L0.832039 11.7467L12.3036 10.536L17 0Z"
        fill="#F0C845"
      />
    </svg>
  );

  const IconYandex = (): JSX.Element => (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="13.0909" height="24" fill="url(#pattern-feedback-yandex)" />
      <defs>
        <pattern
          id="pattern-feedback-yandex"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image-feedback-yandex"
            transform="translate(-0.015625) scale(0.0572917 0.03125)"
          />
        </pattern>
        <image
          id="image-feedback-yandex"
          width="18"
          height="32"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAADP0lEQVRIDZWWz2sVVxTHP+dO3jNGaUKN2hir8UeeaGkWddEWSXwIbrqxUBQKbRFxIUrL+xeKqBvBlVDQLtpNUTdd1U0poU0RClJoxZVRqaWYvKbGH8/4kpl7yp2ZO8xk1Pc8MNx7zj3f75x75txzR3iBKJiHEzs2h6IfK7ofYafAGqBnGaQVVleuX26Mfe6Pja2aG1hoqLENUV4XwSwDl9QS0YP6yMCSLnyNcEAgQGKMAouARVGECsRPRlh4k+7eXVmi56yBD3EkiTSttacErQtai3rCrRF6GnDkmRQimnnt4Qc9Vj4l3Yoot9uRPTQ0Nf27uGhSaU5se4xJQvW2LCI9uKsaqDQQquli21q+2DA1fT1P4oHLx4zoQbNdE3jXO6jqr/Or9UevdxozogizB2VFClBEro5evdXuRODXYyIFsao1nxsgNFb/8E7djElEB+PUDeYANgzMTE7vOE2ImnVRxNWGFw0idXXTtSREayfViDzNoUQD25fTO04ToitYY+29XJEFgtncEZ1ziInEVWkgN4AoXQsE3nMfIef70mkSEbDUtr8ozKfeguWjx/WaO+1dSUa0fnh6ToTv/PYURp6p/fJOfaS3G6ZC6P/t2brJVoKfgG0pOAS+D4Wz9lHrz9a6vnjr/S0axsgZkq3H/ahA5MCze2vjRtRFNpyS+RbSRJlVCAXeQNiUrj+fyCX47/Etb/ea4Jwg9Vy1p7jS8Hwi7/bvxOhODL8Bq1NbGyU5exKfSX8uY6Is2Z7Aja5fq9rDwKrUHkURjcUnrSH3YDnpP4rHFRqbN87tqw2J5TNQn8M7K7R9aeD6P3H1N/eOLvoFjylF5HIkkR5BdV3spFiEC/1Tf/ka89jCWCK6P759UOFolmRhxurSt3H1F6BFpUDkoqkaPgE2pm5qkW/WTt6dLcLKWoFovj7Sr3DMR6PI/DNjL75Sz3bRhBocQsRXtRr08ptrbt0tv79sySKaGRvrM2KO5+6zpwvwlVzJOkIZnbNkRAwsHFB4K1tT+WGYYddaupKY6N77G1caw+e5H4S2GnteJifdoe1KYqJKpXe/Ud7JIX4eHKxey+kdp8bd90bkRO6GDSPrcnPzlZr//7EOE/0kJRVFAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );

  return (
    <StyledFeedbackBlock>
      <StyledRatingPointsList data-test-list="">
        {ratingPoints.map((item, index) => (
          <li key={String(index)} data-test-li="">
            <Star />
          </li>
        ))}
      </StyledRatingPointsList>
      <div className="feedback-block__message">
        <FeedbackTextComponent fontWeightBold>
          &laquo;Это компания, где уважают клиентов и развивают сервисы&raquo;
        </FeedbackTextComponent>
      </div>
      <div className="feedback-block__author">
        <Text color={defaultTheme.colors.shadow}>Игорь В.</Text>
      </div>
      <div>
        <Link
          href="https://yandex.ru/maps/org/planeta/129160777386/reviews/?ll=60.625487%2C56.842855&z=17"
          color={defaultTheme.colors.disable}
          target="_blank"
        >
          <StyledSourceLinkBlock>
            <div className="feedback-block__icon feedback-block__icon--yandex">
              <IconYandex />
            </div>
            <Text color={defaultTheme.colors.disable}>Отзыв на Яндекс</Text>
          </StyledSourceLinkBlock>
        </Link>
      </div>
    </StyledFeedbackBlock>
  );
};

export default FeedbackYandex4;
