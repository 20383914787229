/** libraries */
import { types, Instance } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { toJS } from 'mobx';
/** interfaces */
import { Channel, ChannelPackage } from '../interfaces';
/** constants */
import { CardStates } from '../constants';

const MyTVX2ModelStore = types
  .model('TVX2', {
    /** Активная карточка канала */
    channel: types.maybeNull(types.string),
    /** Активная карточка пакета */
    package: types.maybeNull(types.string),
    /** Состояние блока */
    cardState: types.maybeNull(types.enumeration(Object.values(CardStates))),
    /** Индекс активного таба */
    activeTabIndex: types.number,
    /** LightBox */
    isPopupOpen: types.boolean,
  })
  .views((self) => ({
    get activeChannelCard() {
      return toJS(JSON.parse(self.channel));
    },
    get activePackageCard() {
      return toJS(JSON.parse(self.package));
    },
    /** Популярные пакеты */
    getPopularPackages(packages: ChannelPackage[]) {
      if (packages?.length) {
        const packs = packages
          .filter((item) => !item?.isOrdered)
          .sort((a, b) => b.price - a.price);
        packs.length = 3;
        return packs;
      }
      return [];
    },
    /** Популярные каналы */
    getPopularChannels(channelList: Channel[]) {
      if (channelList.length) {
        const canals = channelList
          .filter((item) => !item.isAccessible && item.singleChannelPacks)
          .sort(
            (a, b) =>
              b.singleChannelPacks[0].price - a.singleChannelPacks[0].price,
          );
        canals.length = 5;
        return canals;
      }
      return [];
    },
  }))
  .actions((self) => ({
    setActiveChannelCard: (channel: Channel) => {
      self.channel = JSON.stringify(channel);
    },
    setActivePackageCard: (pack: ChannelPackage) => {
      self.package = JSON.stringify(pack);
    },
    setCardState: (card: CardStates | null) => {
      self.cardState = card;
    },
    setActiveTabIndex: (index: number) => {
      self.activeTabIndex = index;
    },
    setIsPopupOpen: (isOpen: boolean) => {
      self.isPopupOpen = isOpen;
    },
  }))
  .actions((self) => ({
    /** Обработчик клика на карточку канала */
    onClickChannelCard: (channel: Channel) => {
      self.setActiveChannelCard(channel);
      self.setCardState(CardStates.CHANNEL_DESCRIPTION);
      self.setIsPopupOpen(true);
    },
    /** Обработчик клика на карточку пакета */
    onClickPackageCard: (packet: ChannelPackage) => {
      self.setActivePackageCard(packet);
      self.setCardState(CardStates.PACKAGE_DESCRIPTION);
      self.setIsPopupOpen(true);
    },
  }));

export type IMyTVX2ModelStore = Instance<typeof MyTVX2ModelStore>;

const store = MyTVX2ModelStore.create({
  channel: null,
  package: null,
  cardState: null,
  activeTabIndex: 0,
  isPopupOpen: false,
});

const MyTVX2StoreContext = createContext<IMyTVX2ModelStore>(store);

const useMyTVX2Store = () => {
  return useContext(MyTVX2StoreContext);
};

export default useMyTVX2Store;
