import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop500, desktop940 } from '~/components/Grid/constants';

export const WizardContainer = styled.div<{ isInCTA?: boolean }>`
  ${({ isInCTA }): string => `
  .wizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 16px;
    background: ${
      isInCTA ? defaultTheme.colors.light : defaultTheme.colors.white
    };

    &__type {
      display: flex;
      flex-direction: column;
      align-items: normal;
      margin-top: -16px;
      margin-bottom: 16px;

      &__input {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }

    &__wrapper {
      width: 100%;
    }

    &_done {
      justify-content: flex-start;
      background: ${
        isInCTA ? defaultTheme.colors.light : defaultTheme.colors.white
      };

      h1 {
        margin-top: 36px;
        margin-bottom: 32px;
      }
    }
    
    &__input {
      margin: 8px 0 16px;
      
      &-house,
      &-flat {
        max-width: 120px;
      }
    }
    
    &__button {
      text-align: center;
      margin-top: 8px;

      button {
        background: transparent;
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 8px;
      }
      button {
        align-self: center;
        margin-top: 5px;
        width: 327px;
        height: 52px;
        white-space: nowrap;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      margin: 8px 0 8px;
    }

    &__error {
      margin-bottom: 16px;

      &-address {
        margin-bottom: 8px;
      }
    }

    &__status-container {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      .wizard__error {
        margin: 0 0 0 8px;
      }
    }

    &__adress {
      display: flex;
      flex-direction: column;
    }

    &__adress__container {
      margin: 8px 0 8px;
    }

    &__adress-field {
      display: flex;
      margin-top: 8px;
    }

    &__adress-item {
      display: flex;
      flex-direction: column;
      margin-right: 17px;
      max-width: 388px;
    }
  }
  
  @media (min-width: ${desktop500}px) {
    .wizard {      
      &__input {
        
        &-phone,
        &-name {
          max-width: 400px;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .wizard {
      justify-content: space-between;
      align-items: flex-start;

      &__type {
        flex-direction: row;
        align-items: center;
        margin-bottom: 34px;

          &__input {
            margin-bottom: 0;
          }
      }

      ${
        isInCTA &&
        `.wizard__container + .wizard__container {
          margin: 8px 0 8px 17px;
        }`
      }
      &__input {
        
        &-phone {
          width: 295px;
        }
        
        &-name {
          width: 418px;
        }
        
        &-street {
          width: 323px;
        }
      }

      &__contacts {
        flex-direction: ${isInCTA ? 'row' : 'column'};
        
        button {
          align-self: flex-start;
        }
      }
      
      &__button {
        text-align: left;
      }

      &__adress {
        flex-direction: row;
        align-items: flex-start;
      }

      &__adress-field {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
`}
`;
