/** libraries */
import { FC, Fragment } from 'react';
import { Link, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledReceipts } from './styles';
/** utils */
import { formatNumber } from '~/utils/utils';
/** components */
import Table from '../Components/Table/Table';
import Tr from '../Components/Table/Tr';
import Th from '../Components/Table/Th';
import Td from '../Components/Table/Td';
import MobileTable from '../Components/MobileTable/MobileTable';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** interfaces */
import { ReceiptsState, ReceiptsInfos } from './interfaces';

const Receipts: FC<ReceiptsState> = ({ links }: ReceiptsState): JSX.Element => {
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledReceipts>
      {isDesktop940 ? (
        <Table>
          <thead>
            <Tr>
              <Th width={250}>Ссылка на чек</Th>
              <Th width={200} textAlign="right">
                Сумма, ₽
              </Th>
            </Tr>
          </thead>
          <tbody>
            {links.map((link: { receiptHyperLink: string; debit: number }) => {
              return (
                <Fragment key={link.receiptHyperLink}>
                  <Tr>
                    <Td>
                      <Link href={link.receiptHyperLink} target="_blank">
                        Пополнение счёта
                      </Link>
                    </Td>
                    <Td textAlign="right">
                      <Text lineHeight="24px" fontWeightBold>
                        {formatNumber(link.debit, false, ',', true)}
                      </Text>
                    </Td>
                  </Tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <MobileTable
          content={{
            lines: links.map((link: ReceiptsInfos) => {
              return {
                amount: link.debit,
                amountColor: defaultTheme.colors.black,
                info: link ? (
                  <Link href={link.receiptHyperLink} target="_blank">
                    Пополнение счёта
                  </Link>
                ) : (
                  'Чек'
                ),
                isFormatDate: false,
              };
            }),
          }}
        />
      )}
    </StyledReceipts>
  );
};

export default Receipts;
