/* eslint-disable camelcase */
/** libraries */
import { FC, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icon,
  Icons,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import noop from 'lodash/noop';
import { observer } from 'mobx-react';

/** styles */
import { StyledPackageDescription } from './styles';

/** interfaces */
import { Channel, ChannelPackage, ServiceCode } from '../../../interfaces';
import { PackageConnectionStates } from '../../Cards/ChannelPackageCard/interfaces';

/** constants */
import {
  CardStates,
  CHANNEL,
  CONNECTION,
  SERVICE,
  ServiceNames,
  TEXT,
} from '../../../constants';
import { desktop940 } from '~/components/Grid/constants';

/** utils */
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber, pluralizeAll } from '~/utils/utils';

/** components */
import Row from '../../common/RowContent';
import ToggleTabs from '../../common/ToggleTabs';
import ServiceCardWrapper from '../../Cards/ServiceCards/ServiceCardWrapper';
import ChannelCard from '../../Cards/ChannelCard/ChannelCard';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** stores */
import { useMyTvStore } from '../../../stores/useMyTvStore';
import { useRootStore } from '~/stores/RootStore';

interface PackageDescriptionProps {
  /** Пакеты включённые в продукт */
  packagesIncludedInProduct: ChannelPackage[];
  /** Каналы включённые в продукт */
  allChannelsIncludedInProduct: Channel[];
  /** Трансформер в блоке Телевидение */
  isTransformer: boolean;
}

/** Описание пакета для блока Television */
const PackageDescription: FC<PackageDescriptionProps> = ({
  packagesIncludedInProduct,
  allChannelsIncludedInProduct,
  isTransformer,
}: PackageDescriptionProps) => {
  const {
    tvStore: {
      idsAddedPacketsToTransformer,
      setIdsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
      setIdsAddedChannelsToTransformer,
      editOrderSum,
      setEditOrderSum,
    },
  } = useRootStore();

  const {
    transformationLimit,
    filteredPackages: packages,
    openCardBlockId,
    setCardState,
    setIsEditTransformer,
    activePackageCard,
    onClickChannelCard,
    channelsList,
  } = useMyTvStore();

  const {
    id,
    channelPackName,
    price,
    channelPackChannels,
    isTimeshiftIncluded,
  } = activePackageCard;

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Подключённые каналы */
  const connectedChannels = useMemo(() => {
    return channelPackChannels.filter((item) =>
      allChannelsIncludedInProduct.find((channel) => channel.id === item.id),
    );
  }, [allChannelsIncludedInProduct]);

  const services = isTimeshiftIncluded ? [isTimeshiftIncluded] : [];

  /** Статус подключения */
  const connection = useMemo(() => {
    if (
      packagesIncludedInProduct.find(
        (item) => item.id === activePackageCard.id,
      ) &&
      !isTransformer
    ) {
      return PackageConnectionStates.IncludedInTheProduct;
    }

    /** Добавлен в трансформер */
    if (
      isTransformer &&
      idsAddedPacketsToTransformer?.length &&
      idsAddedPacketsToTransformer.find((item) => item === id)
    )
      return PackageConnectionStates.AddedToTransformer;

    return PackageConnectionStates.NotConnected;
  }, [isTransformer, idsAddedPacketsToTransformer, packagesIncludedInProduct]);

  /** Каналы в пакете */
  const channelsInPackage = useMemo(() => {
    if (channelsList?.length) {
      return channelsList.filter((item) =>
        channelPackChannels.find(
          (channelItem) => item.idRis === channelItem.idRis,
        ),
      );
    }
    return [];
  }, [channelsList]);

  /** Заголовок каналов */
  const rowTitle = () => {
    if (channelsInPackage?.length && services?.length) {
      return `В пакете ${pluralizeAll(
        channelsInPackage.length,
        CHANNEL,
      )} и ${pluralizeAll(services.length, SERVICE)}`;
    }
    if (channelsInPackage?.length)
      return `В пакете ${pluralizeAll(channelsInPackage.length, CHANNEL)}`;
    if (services?.length)
      return `В пакете ${pluralizeAll(services.length, SERVICE)}`;
    return undefined;
  };

  /** Описание подключённых каналов */
  const rowDescription = (): string => {
    if (connectedChannels?.length)
      return pluralizeAll(connectedChannels?.length, CHANNEL, CONNECTION);
    return undefined;
  };

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (connection === PackageConnectionStates.AddedToTransformer)
        return defaultTheme.colors.planeta;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (connection === PackageConnectionStates.AddedToTransformer)
        return defaultTheme.colors.pink;
      return defaultTheme.colors.green;
    };

    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          ![PackageConnectionStates.AddedToTransformer].includes(connection) &&
          colorTag()
        }
      >
        {text}
      </Tag>
    );
  };

  /** Дополнительная цена за трансформер */
  const additionalPriceForTransformer = useMemo(() => {
    if (
      !isTransformer ||
      connection === PackageConnectionStates.AddedToTransformer
    )
      return undefined;
    /** Остаток лимита трансформера */
    const residue = transformationLimit - editOrderSum;
    if (residue > price) {
      return null;
    }
    if (transformationLimit > editOrderSum && residue < price) {
      return price - residue;
    }
    return isTransformer ? price : undefined;
  }, [transformationLimit]);

  /** Событие клика трансформера */
  const transformerClick = () => {
    setIsEditTransformer(true);

    /** Сумма подключённых и добавленных в трансформер каналов, которые находятся в составе этого пакета */
    const sum = idsAddedChannelsToTransformer?.reduce((acc, item) => {
      const canal = channelPackChannels?.find((channel) =>
        channel?.singleChannelPacks?.length
          ? item === channel.singleChannelPacks[0].id
          : null,
      );
      return canal ? acc + canal.singleChannelPacks[0].price : acc;
    }, 0);

    switch (connection) {
      case PackageConnectionStates.NotConnected:
        setIdsAddedPacketsToTransformer(
          idsAddedPacketsToTransformer?.length
            ? [...idsAddedPacketsToTransformer, id]
            : [id],
        );
        setEditOrderSum(editOrderSum + price - sum);
        break;
      case PackageConnectionStates.AddedToTransformer:
        setIdsAddedPacketsToTransformer(
          idsAddedPacketsToTransformer.filter((item) => item !== id),
        );
        setEditOrderSum(editOrderSum - price + sum);
        break;
      default:
        break;
    }
  };

  /** Текст кнопки трансформера */
  const transformerButtonText = useMemo(() => {
    switch (connection) {
      case PackageConnectionStates.NotConnected:
        return 'Добавить в Трансформер';
      case PackageConnectionStates.AddedToTransformer:
        return 'Убрать пакет';
      default:
        return null;
    }
  }, [connection]);

  /** Текст статуса подключения */
  const connectionStatusText = (): string | boolean => {
    switch (connection) {
      case PackageConnectionStates.AddedToTransformer:
        return TEXT.ADDED;
      case PackageConnectionStates.IncludedInTheProduct:
        return TEXT.INCLUDED_IN_PRODUCT;
      default:
        return false;
    }
  };

  /** Все каналы использованы в объединяющем пакете */
  const allChannelsUsed = useMemo(() => {
    if (price !== null) return undefined;

    /** Добавленные в трансформер пакеты (без подключённых) */
    const addedPackages = idsAddedPacketsToTransformer?.length
      ? packages.filter((item) =>
          idsAddedPacketsToTransformer.includes(item.id),
        )
      : [];

    /** Все пакеты "используются" */
    return channelPackChannels?.length
      ? channelPackChannels.every((item) => {
          /** Добавлен */
          return (
            (item?.singleChannelPacks?.length &&
              idsAddedChannelsToTransformer.includes(
                item?.singleChannelPacks[0].id,
              )) ||
            /** Включён в продукт */
            item.includedToProduct ||
            /** Добавлен в пакете */
            addedPackages.filter((pack) =>
              pack.channelPackChannels.find((canal) => canal.id === item.id),
            ).length
          );
        })
      : [];
  }, [idsAddedPacketsToTransformer, idsAddedChannelsToTransformer, packages]);

  /** Добавить все каналы в объединяющих пакетах */
  const addAllChannels = () => {
    setIsEditTransformer(true);
    const ids = channelPackChannels.map((item) =>
      item?.singleChannelPacks?.length ? item.singleChannelPacks[0].id : null,
    );
    const sum = channelPackChannels.reduce((acc, item) => {
      return item?.singleChannelPacks?.length
        ? acc + item?.singleChannelPacks[0].price
        : acc;
    }, 0);
    setIdsAddedChannelsToTransformer(
      idsAddedChannelsToTransformer?.length
        ? [...idsAddedChannelsToTransformer, ...ids]
        : [...ids],
    );
    setEditOrderSum(editOrderSum + sum);
  };

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    if (transformationLimit > editOrderSum) {
      return `доступно ${formatNumber(
        transformationLimit - editOrderSum,
      )} ₽ для трансформации`;
    }
    return `${formatNumber(
      editOrderSum - transformationLimit,
    )} ₽ в день за доп. каналы`;
  }, [editOrderSum, transformationLimit]);

  return (
    <StyledPackageDescription>
      <div className="package-description__header">
        <div className="package-description__header__block">
          <H3>Пакет каналов {channelPackName}</H3>
          <div
            className="package-description__header__close-icon"
            onClick={() => {
              setCardState(CardStates.MAIN);
              if (!isDesktop940)
                setTimeout(() => {
                  scrollToBlockById(openCardBlockId, 'auto', 'center');
                }, 0);
            }}
          >
            <Icons.CloseIcon />
          </div>
        </div>
        {![PackageConnectionStates.NotConnected].includes(connection) &&
          customTag(connectionStatusText())}
      </div>

      {connection !== PackageConnectionStates.IncludedInTheProduct &&
        price !== null && (
          <Row title="Стоимость подписки">
            <div className="package-description__subcription-cost">
              <ToggleTabs
                cost={price}
                additionally={additionalPriceForTransformer}
              />
              {isTransformer && (
                <div className="package-description__transformer__button-block">
                  <FillableScale
                    value={editOrderSum}
                    maxValue={transformationLimit}
                  >
                    <Text className="text" lineHeight="24px">
                      {textUnderScale}
                    </Text>
                  </FillableScale>
                  {[
                    PackageConnectionStates.NotConnected,
                    PackageConnectionStates.AddedToTransformer,
                  ].includes(connection) && (
                    <div className="package-description__transformer__button-block__refusal-block">
                      <Button
                        onClick={() => transformerClick()}
                        icon={
                          [PackageConnectionStates.NotConnected].includes(
                            connection,
                          ) && <Icon icon={<Icons.PlusIcon />} />
                        }
                        styleType={
                          [PackageConnectionStates.AddedToTransformer].includes(
                            connection,
                          )
                            ? ButtonStyleTypes.SECONDARY
                            : undefined
                        }
                      >
                        {transformerButtonText}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Row>
        )}

      {isTransformer && price === null && !allChannelsUsed && (
        <Row title="Управление подпиской">
          <div className="package-description__add-all-channels">
            <FillableScale value={editOrderSum} maxValue={transformationLimit}>
              <Text className="text" lineHeight="24px">
                {textUnderScale}
              </Text>
            </FillableScale>
            <Button
              icon={<Icon icon={<Icons.PlusIcon />} />}
              onClick={addAllChannels}
            >
              Добавить каналы
            </Button>
          </div>
        </Row>
      )}

      <Row title={rowTitle()} description={rowDescription()} removeLine>
        <div className="package-description__channels-n-services">
          {isTimeshiftIncluded && (
            <ServiceCardWrapper
              service={{
                name: ServiceNames.VIEW_CONTROL,
                code: ServiceCode.viewControl,
              }}
              onClick={noop}
            />
          )}
          {channelsInPackage?.length > 0 &&
            channelsInPackage.map((channel) => {
              return (
                <ChannelCard
                  key={channel.name}
                  channel={channel}
                  onClick={() => onClickChannelCard(channel)}
                  allChannelsIncludedInProduct={allChannelsIncludedInProduct}
                  isTransformer={isTransformer}
                />
              );
            })}
        </div>
      </Row>
    </StyledPackageDescription>
  );
};

export default observer(PackageDescription);
