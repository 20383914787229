/** libraries */
import { createContext, useContext } from 'react';
/** model */
import CallbackModel, { ICallbackStore } from './CallbackModel';
/** constants */
import { INIT_PHONE_VALUE } from '~/constants/common';

const store = CallbackModel.create({
  phone: INIT_PHONE_VALUE,
  phoneError: '',
  serverError: '',
  isLoading: false,
  isCompleted: false,
  isVisibleTimeout: false,
  isVisible: false,
  isAgreementChecked: false,
  isErrorAgreement: false,
});

const CallbackStoreContext = createContext<ICallbackStore>(store);

const useCallbackStore = () => {
  return useContext(CallbackStoreContext);
};

export default useCallbackStore;
