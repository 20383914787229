/** библиотеки */
import { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';
import Image from 'next/image';

/** Компоненты библиотеки */
import { Icon, Icons, Popup } from 'cordis-core-ui-planeta';

/** типы */
import { VideoContentProps } from './Video.types';

/** стили */
import { StyledVideo } from './Video.style';

/** утилиты */
import { isExternal } from '~/utils/utils';

/**
 * Блок "Видео"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=607690370
 * @param content
 */
const Video: FC<VideoContentProps> = ({ content }): JSX.Element => {
  const {
    fields: { link, imgLink, source },
  } = content;

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(false);

  useEffect(() => {
    if (!isPopupOpen) setIsPlay(false);
  }, [isPopupOpen]);

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Высота экрана */
  const windowInnerHeight = document.documentElement.clientHeight;

  /** Ширина popup */
  const popupWidth = windowInnerHeight * 0.8;

  /** 0.5625 - соотношение 360/640 */
  const playerHeight = popupWidth * 0.5625;

  const playerUrl = isExternal(source)
    ? source
    : `${process.env.STATIC_SERVER}/${source}`;

  return (
    <StyledVideo>
      {!source && !link ? (
        <></>
      ) : (
        <LinkWrapper href={source ? undefined : link}>
          <Image
            loader={imageLoader}
            src={`${process.env.STATIC_SERVER}/${imgLink}`}
            alt="video"
            width={240}
            height={400}
            quality={100}
          />
          <Icon
            className="play-icon"
            icon={<Icons.PlayIcon />}
            onClick={() => setIsPopupOpen(true)}
          />
          {source && (
            <Popup
              show={isPopupOpen}
              onCloseClick={() => setIsPopupOpen(false)}
              onOutsideClick={() => setIsPopupOpen(false)}
              width={!isMobile && `${popupWidth.toString()}px`}
            >
              <ReactPlayer
                playing={isPlay}
                onPlay={() => setIsPlay(true)}
                url={playerUrl}
                height={playerHeight}
                width="100%"
                controls
              />
            </Popup>
          )}
        </LinkWrapper>
      )}
    </StyledVideo>
  );
};

export default Video;
