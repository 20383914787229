/** компоненты библиотеки */
import { Link, H3, Text, defaultTheme } from 'cordis-core-ui-planeta';

import { AgreementStyled } from './Agreement.style';

/** Константы */
import { URL_PRIVACY_POLICY } from '../ConnectionWizard/constants';

export const Agreement = (): JSX.Element => (
  <AgreementStyled>
    <Text
      className="agreementPopup__text__companyDetails"
      color={defaultTheme.colors.gray}
    >
      Руководителю Оператора связи ООО &laquo;Комтехцентр&raquo;
      <br />
      Коммерческое обозначение и товарный знак &laquo;Планета&raquo;
      <br />
      ИНН&nbsp;6670019135, ОГРН&nbsp;1026604951086
      <br />
      Юр. адрес: 620075, г. Екатеринбург, Бажова,&nbsp;79-211
    </Text>
    <H3>Согласие на обработку персональных данных</H3>
    <Text className="agreementPopup__text__description">
      <p>
        Настоящим&nbsp;я, являясь Субъектом персональных данных,
        в&nbsp;соответствии с&nbsp;требованиями Федерального закона
        от&nbsp;27&nbsp;июля 2006&nbsp;г. &#8470;&nbsp;152-ФЗ
        &laquo;О&nbsp;персональных данных&raquo;, для участия
        в&nbsp;стимулирующих мероприятиях (акциях), проводимых ООО
        &laquo;Комтехцентр&raquo;, для заключения и&nbsp;исполнения договора
        об&nbsp;оказании услуг связи и&nbsp;в&nbsp;целях обеспечения защиты
        персональных данных, действуя в&nbsp;своей воле и&nbsp;в&nbsp;своём
        интересе, даю ООО &laquo;Комтехцентр&raquo; (далее&nbsp;&mdash;
        Оператор) согласие на&nbsp;обработку моих персональных данных путём
        регистрации в&nbsp;информационных системах и&nbsp;на&nbsp;бумажных
        носителях. Подтверждаю, что с&nbsp;
        <Link href={URL_PRIVACY_POLICY}>
          Политикой в&nbsp;отношении обработки персональных данных{' '}
        </Link>
        ознакомлен и&nbsp;согласен.
      </p>
      <p>
        Под персональными данными я&nbsp;понимаю информацию, относящуюся
        ко&nbsp;мне как к&nbsp;Субъекту персональных данных и&nbsp;указанную
        мной при регистрации путём заполнения веб-формы на&nbsp;сайте Оператора
        и/или при заключении договора об&nbsp;оказании услуг связи,
        а&nbsp;именно: мои фамилию, имя, отчество; дату рождения; адрес
        регистрации, адрес места жительства; паспортные данные (номер, серию,
        дату выдачи и&nbsp;выдавший орган); контактные данные (номер телефона,
        адрес электронной почты), идентификационный номер налогоплательщика
        (ИНН), номер в&nbsp;системе социального страхования (СНИЛС), моё
        изображение, а&nbsp;также документы и&nbsp;их&nbsp;копии, как бумажные
        так и&nbsp;электронные, содержащие персональные данные (в&nbsp;том числе
        документы, удостоверяющие личность и&nbsp;их&nbsp;копии, в&nbsp;том
        числе, но&nbsp;не&nbsp;ограничиваясь: копии паспорта, свидетельства
        ИНН).
      </p>
      <p>
        Настоящее согласие предоставляется мной на&nbsp;осуществление
        в&nbsp;отношении моих персональных данных действий, которые необходимы
        для достижения указанных выше целей, включая (без ограничения) любое
        действие (операцию) или совокупность действий (операции), совершаемых
        с&nbsp;использованием средств автоматизации или без использования таких
        средств с&nbsp;персональными данными, в&nbsp;том числе сбор, запись,
        систематизацию, накопление, хранение, уточнение (обновление, изменение),
        копирование, извлечение, использование, передачу (распространение,
        предоставление, включая предоставление третьим лицам, доступ),
        обезличивание, блокирование, удаление, проверку достоверности,
        уничтожение персональных данных.
      </p>
      <p>
        В&nbsp;случае заказа и&nbsp;получения мной услуг междугородной,
        международной и&nbsp;внутризоновой телефонной связи
        с&nbsp;использованием кода выбора оператора, я&nbsp;даю согласие
        на&nbsp;передачу моих персональных данных выбранному мной оператору
        телефонной связи. Также даю согласие на&nbsp;передачу моих персональных
        данных ПАО &laquo;Ростелеком&raquo; в&nbsp;целях как связанных, так
        и&nbsp;не&nbsp;связанных с&nbsp;оказанием услуг связи.
      </p>
      <p>
        Датой выдачи мной как Субъектом персональных данных настоящего согласия
        на&nbsp;обработку персональных данных является дата отправки
        регистрационной веб-формы с&nbsp;сайта Оператора либо дата заключения
        договора об&nbsp;оказании услуг связи (в&nbsp;зависимости от&nbsp;того,
        какое из&nbsp;действий было совершено раньше).
      </p>
      <p>
        Настоящее согласие действует с&nbsp;момента его выдачи
        до&nbsp;достижения целей обработки персональных данных
        и&nbsp;в&nbsp;течение срока хранения соответствующей информации,
        в&nbsp;том числе, но&nbsp;не&nbsp;ограничиваясь, в&nbsp;период
        проведения Оператором акции (акций) с&nbsp;моим участием&nbsp;и (или)
        в&nbsp;течение действия договора об&nbsp;оказании услуг связи,
        а&nbsp;после прекращения соответствующей акции или после прекращения
        действия договора&nbsp;&mdash; в&nbsp;течение срока, необходимого для
        исполнения Оператором обязанностей по&nbsp;хранению документации
        и&nbsp;сведений о&nbsp;пользователях, клиентах, участниках акций.
      </p>
      <p>
        Настоящее согласие может быть отозвано мной путём направления Оператору
        заявления в&nbsp;письменной форме, отправленного
        на&nbsp;welcome@planeta.tc. Обработка персональных данных в&nbsp;этом
        случае прекращается в&nbsp;порядке и&nbsp;в&nbsp;сроки, установленные
        законом.
      </p>
    </Text>
  </AgreementStyled>
);
