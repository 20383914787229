/** библиотеки */
import * as React from 'react';

/** компоненты */
import SingleBanner from '../SingleBanner';
import { Carousel, Indicator } from 'cordis-core-ui-planeta';

/** типы */
import * as Banner from '../Banner.types';

/** стили */
import { StyledBanner } from '../Banner.style';

/**
 * Компонент "Карусель"
 */
const BannerCarousel: React.FC<Banner.BannerContent> = ({
  blocks,
  size,
  fields,
}) => {
  const { borderRadius, channelsLength } = fields;
  if (blocks.length === 1)
    return (
      <StyledBanner>
        <SingleBanner
          {...blocks[0].fields}
          size={size}
          borderRadius={borderRadius}
          channelsLength={channelsLength}
        />
      </StyledBanner>
    );

  return (
    <StyledBanner>
      <Carousel duration={1000} widgets={[Indicator]} auto loop>
        {blocks.map((block) => (
          <SingleBanner
            {...block.fields}
            key={block.uId}
            size={size}
            borderRadius={borderRadius}
            channelsLength={channelsLength}
          />
        ))}
      </Carousel>
    </StyledBanner>
  );
};

export default React.memo(BannerCarousel);
