import styled from '@emotion/styled';

export const StyledContacts = styled.div`
  display: block;
  padding: 30px 0;

  .contacts__title {
    margin-bottom: 8px;
  }
`;

export const StyledContactsList = styled.ul`
  margin-bottom: 26px;
  padding: 0;
  list-style-type: none;
`;
