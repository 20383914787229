import { ReactNode, useContext } from 'react';
import { MobXProviderContext, Provider, useLocalStore } from 'mobx-react';
import createStore, { IContractStore } from './ContractStore';
import { ContractBlocksProps } from '../types';

interface ContractProviderProps {
  children: ReactNode;
  blocks: ContractBlocksProps[];
}

export const ContractProvider = ({
  children,
  blocks,
}: ContractProviderProps): JSX.Element => {
  const store = useLocalStore<IContractStore>(() => createStore(blocks));
  return <Provider ContractStore={store}>{children}</Provider>;
};

export const useContractStore = () => {
  const { ContractStore } = useContext(MobXProviderContext);
  return ContractStore;
};
