/* Библиотеки */
import * as React from 'react';

/* Стили */
import { StyledRowContent, StyledTitleContent } from './CallToActionRow.style';

/* Типы */
import { CallToActionRowProps } from './CallToActionRow.types';

/* Строка с элементами продукта */
const CallToActionRow: React.FC<CallToActionRowProps> = ({
  title,
  children,
}) => (
  <>
    <StyledTitleContent>{title}</StyledTitleContent>
    <StyledRowContent>{children}</StyledRowContent>
  </>
);

export default CallToActionRow;
