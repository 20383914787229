/** libraries */
import { Text, defaultTheme } from 'cordis-core-ui-planeta';
import { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledProductChangeHistory } from './style';
/** utils */
import { getDate } from '~/components/Blocks/Shared/Shared.utils';
/** constants */
import { DONE } from '../constants';
import { desktop940 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/** История смены продукта */
const ProductChangeHistory = (): JSX.Element => {
  const {
    productHistoryStore: { productHistory },
  } = useRootStore();

  const productHistoryWithDates = useMemo(() => {
    const history = productHistory.map((item) => {
      const dateRegex = /(\d{4}-\d{2}-\d{2})/;
      const mainRegex = new RegExp(`\\(до ${dateRegex.source}\\)`);
      const date = item.tariffName.match(dateRegex);
      const newName = item.tariffName.replace(mainRegex, '');
      return {
        ...item,
        dateBefore: mainRegex.test(item.tariffName)
          ? `(до ${getDate(date[0])})`
          : null,
        dateBeforeMobile: mainRegex.test(item.tariffName)
          ? `(до ${getDate(date[0], 'd MMM yyyy')})`
          : null,
        nameWithoutDate: newName,
      };
    });
    return history.reverse();
  }, [productHistory]);

  /** Генерация ключей для списка */
  const ids = useMemo(() => productHistoryWithDates.map(() => nanoid(5)), [
    productHistoryWithDates,
  ]);

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledProductChangeHistory>
      <div className="history__table history__table__header">
        {isDesktop940 && (
          <>
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Дата заявки
            </Text>
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Продукт
            </Text>
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Состояние
            </Text>
          </>
        )}
        {productHistoryWithDates.map((item, index) => {
          return (
            <div className="history__table" key={ids[index]}>
              <span className="history__table__name-block">
                <Text
                  lineHeight="24px"
                  color={
                    isDesktop940
                      ? defaultTheme.colors.black
                      : defaultTheme.colors.shadow
                  }
                >
                  {getDate(item.date)}&nbsp;
                </Text>
                {item.dateBeforeMobile && !isDesktop940 && (
                  <Text lineHeight="24px">
                    {item.dateBeforeMobile.replace('.', '')}
                  </Text>
                )}
              </span>
              <span className="history__table__name-block">
                <Text lineHeight="24px" fontWeightBold>
                  {item.nameWithoutDate}&nbsp;
                </Text>
                {isDesktop940 && (
                  <Text lineHeight="24px">{item.dateBefore}</Text>
                )}
              </span>
              <Text
                color={
                  isDesktop940
                    ? defaultTheme.colors.black
                    : defaultTheme.colors.shadow
                }
                lineHeight="24px"
              >
                {item.state === DONE ? 'выполнено' : ''}
              </Text>
            </div>
          );
        })}
      </div>
    </StyledProductChangeHistory>
  );
};

export default observer(ProductChangeHistory);
