/** Библиотеки */
import * as React from 'react';
import Link from 'next/link';

/** Типы */
import {
  LinkButton as CordisLinkButton,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { Category, ProductCard } from '../MenuPopup.types';

/** Компоненты */
import { StyledProductCard } from './style';

import CategoryItem from '../CategoryItem';

/**
 * Компонент карточки продукта
 * @returns {React.FC}
 */
const ProductItem: React.FC<ProductCard> = (productCard: ProductCard) => {
  const category = productCard as Category;

  return (
    <StyledProductCard>
      <CategoryItem {...category} />
      <div className="product-desc">
        <Text color={defaultTheme.colors.gray}>{productCard.descrption}</Text>
      </div>
      <div className="product-select">
        <div>
          <Link href={category.href}>
            <span>
              <CordisLinkButton
                href={category.href}
                onClick={category.toggleHandler}
                color={defaultTheme.colors.black}
              >
                Выбрать продукт
              </CordisLinkButton>
            </span>
          </Link>
        </div>
      </div>
    </StyledProductCard>
  );
};

export default ProductItem;
