/** libraries */
import {
  Dispatch,
  FC,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Icon, Icons } from 'cordis-core-ui-planeta';
/** styles */
import { StyledProductSelectorArrow } from './ProductTemplate.style';
/** interfaces */
import { MarketingTagsValues } from './ProductTemplate.interfaces';
/** constants */
import { PRODUCT_ITEM_WIDTH, SWIPE_AMOUNT } from './ProductTemplate.constants';
/* Утилиты */
import useWindowSize from '~/utils/useWindowSize';

interface ArrowsInterface {
  productListLength: number;
  activeProductTags: MarketingTagsValues;
  productRef: RefObject<HTMLDivElement> | null;
  xPosition: number;
  setXPosition: Dispatch<SetStateAction<number>>;
  children: ReactNode;
}

const ArrowsContainer: FC<ArrowsInterface> = ({
  productListLength,
  activeProductTags,
  productRef,
  xPosition,
  setXPosition,
  children,
}: ArrowsInterface) => {
  const [width] = useWindowSize();
  // Ссылка на блок со списком продуктов
  const selectorRef: RefObject<HTMLSpanElement> | null = useRef(null);
  // Флаг отображения стрелки справа
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  // Состояние при начале нажатия на экран
  const [touchStart, setTouchStart] = useState<number>(0);
  // Состояние при завершения нажатия на экран
  const [touchEnd, setTouchEnd] = useState<number>(0);
  // Событие при начале swipe
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };
  // Событие при самом swipe
  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  // Обработка завершения swipe
  const handleTouchEnd = () => {
    // Если пользователь сделал touch без swipe - touchEnd будет равна 0
    if (!touchEnd) return;
    if (touchStart - touchEnd > SWIPE_AMOUNT && showRightArrow) {
      onRightClick();
      setTouchEnd(0);
    }
    if (touchStart - touchEnd < -SWIPE_AMOUNT && xPosition < 0) {
      onLeftClick();
      setTouchEnd(0);
    }
  };

  // Вычисление необходимости отображения правого элемента прокручивания списка продуктов при изменении экрана или списка продуктов
  useEffect(() => {
    if (!productRef?.current || !selectorRef?.current) return;
    // Сколько карточек продуктов полностью влезает на экран
    const visibleAmount = Math.floor(
      productRef.current.offsetWidth / PRODUCT_ITEM_WIDTH,
    );

    // Позиция ленты продуктов, после которой необходимо скрыть правый переключатель
    const isMaxXPosition =
      Math.abs(xPosition) >=
      (productListLength - visibleAmount) * PRODUCT_ITEM_WIDTH;
    if (
      (selectorRef.current.offsetWidth > productRef.current.offsetWidth ||
        !isMaxXPosition) &&
      !showRightArrow
    )
      setShowRightArrow(true);
    if (
      (selectorRef.current.offsetWidth < productRef.current.offsetWidth ||
        isMaxXPosition) &&
      showRightArrow
    )
      setShowRightArrow(false);
  }, [width, activeProductTags, xPosition]);

  // Обработка клика по правой стрелке
  const onRightClick = () => {
    setXPosition((prevState) => prevState - PRODUCT_ITEM_WIDTH);
  };

  // Обработка клика по левой стрелке
  const onLeftClick = () => {
    setXPosition((prevState) => prevState + PRODUCT_ITEM_WIDTH);
  };

  // При изменении тегов скидывать позицию в начало
  useEffect(() => {
    setXPosition(0);
  }, [activeProductTags]);

  return (
    <>
      <StyledProductSelectorArrow
        onClick={onLeftClick}
        show={xPosition < 0}
        isLeft
      >
        <Icon icon={<Icons.ArrowIcon />} />
      </StyledProductSelectorArrow>
      <StyledProductSelectorArrow onClick={onRightClick} show={showRightArrow}>
        <Icon icon={<Icons.ArrowIcon />} />
      </StyledProductSelectorArrow>
      <span
        ref={selectorRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </span>
    </>
  );
};

export default ArrowsContainer;
