/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
/** components */
import {
  H1,
  H3,
  defaultTheme,
  LeadingText,
  Text,
} from 'cordis-core-ui-planeta';
import Wrapper, { Header, MainText } from './styles';
/** config */
import CONFIG from '../../config';
import { desktop940 } from '~/components/Grid/constants';

/** Слайд Каменск-Уральского */
const Kur: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  if (!isDesktop940)
    return (
      <Wrapper $isMobile>
        <Header as={H3} color={defaultTheme.colors.white} $isMobile>
          {CONFIG[0].header}
        </Header>
        <MainText
          dangerouslySetInnerHTML={{ __html: CONFIG[0].mainTextMobile }}
          as={Text}
          color={defaultTheme.colors.white}
        />
      </Wrapper>
    );

  return (
    <Wrapper>
      <Header as={H1} color={defaultTheme.colors.white}>
        {CONFIG[0].header}
      </Header>
      <MainText
        dangerouslySetInnerHTML={{ __html: CONFIG[0].mainText }}
        as={LeadingText}
        color={defaultTheme.colors.white}
      />
    </Wrapper>
  );
};

export default Kur;
