/** библиотеки */
import { useEffect, useRef, useState } from 'react';
import { keyframes } from '@emotion/react';
import Image from 'next/image';

/** стили */
import { StyledSpeedProgress } from './Speed.style';

/** типы */
import { PROGRESS_STYLE_TYPES, SpeedProgressProps } from './Speed.types';

const SpeedProgress: React.FC<SpeedProgressProps> = ({
  styleType,
  progress,
}: SpeedProgressProps) => {
  /** сработала ли анимация */
  const [
    progressWrapperAnimated,
    setProgressWrapperAnimated,
  ] = useState<boolean>(false);
  /** состояние цветного прогресса */
  const isColored = styleType === PROGRESS_STYLE_TYPES.colored;
  /** путь до картинки прогресса */
  const path = `${process.env.STATIC_SERVER}/static/images/progress-${
    isColored ? 'colored' : 'white'
  }.png`;
  const progressWrapper = useRef(null);
  const progressBlur = useRef(null);

  /** добавляет анимацию при прокрутке */
  const onScrollToProgress = (entries): void => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        setProgressWrapperAnimated(true);
        if (progressBlur.current) {
          progressBlur.current.classList.add('progress-blur_animation');
        }
      }
    });
  };

  /** добавляет обработчик прокрутки для анимации */
  useEffect(() => {
    const options = {
      root: document.querySelector('#scrollArea') || null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(onScrollToProgress, options);
    observer.observe(progressWrapper.current);
    return (): void => observer.disconnect();
  }, []);

  /** В styled(@emotion/styled) компоненте при изменении переданного props некорректно перерендеривается
   * анимация keyframes(html) (при переходах через next/link).
   * Прокидывание анимации keyframes(@emotion/react) в styled(@emotion/styled) через css(@emotion/react) так же не работает.
   * Поэтому сделано через атрибут css(@emotion/react). */
  const progressWrapperAnimation = (width: number) =>
    keyframes({
      from: {
        width: '0',
      },
      to: {
        width: `${width}%;`,
      },
    });

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <StyledSpeedProgress progress={progress}>
      <div
        className="progress-wrapper"
        {...(progressWrapperAnimated && {
          css: {
            animation: `${progressWrapperAnimation(progress)} 1.5s ease-out`,
          },
        })}
        ref={progressWrapper}
      >
        <div className="imageWrapper">
          <Image
            loader={imageLoader}
            src={path}
            alt="progress"
            layout="fill"
            quality={100}
          />
        </div>
        {!isColored && (
          <img
            ref={progressBlur}
            className="progress-wrapper__blur"
            src={`${process.env.STATIC_SERVER}/static/images/progress-blur.png`}
            alt="progress-blur"
          />
        )}
      </div>
      {isColored && (
        <div className="progress-helper">
          <div className="imageWrapper">
            <Image
              loader={imageLoader}
              src={`${process.env.STATIC_SERVER}/static/images/progress-gray.png`}
              alt="progress-gray"
              layout="fill"
              quality={100}
            />
          </div>
        </div>
      )}
    </StyledSpeedProgress>
  );
};

export default SpeedProgress;
