import * as React from 'react';
import { FeedContainer, InnerFeedContainer } from './FeedPage.style';
import { FeedProps } from './FeedPage.types';

const Feed: React.FC<FeedProps> = (props: FeedProps) => {
  const { children } = props;
  return (
    <FeedContainer>
      <InnerFeedContainer role="main">{children}</InnerFeedContainer>
    </FeedContainer>
  );
};

export default Feed;
