/** библиотеки */
import { useEffect, useState } from 'react';
import { format, isAfter, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react';
/** стили */
import { StyledSoftlineUnsubscribeWizard } from './style';
/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
/** компоненты библиотеки */
import { defaultTheme, LeadingText, Text } from 'cordis-core-ui-planeta';
/** api */
import { getSubscriptionUnbindAgreement } from '~/api/api';
/** store */
import useSoftlineStateModelStore from '../stores/useSoftlineStateStore';

/**
 * Сайдпейдж с отказом от подписки
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 */
const SoftlineUnsubscribeWizard = (): JSX.Element => {
  const { softlineDescription } = useSoftlineStateModelStore();
  const {
    pendingUnbindDate,
    trialInfo,
    banTrimDate,
    simId,
    availableUnbindDt,
  } = softlineDescription;
  /** Запланировано ли отключение */
  const formattedPendingUnbindDate =
    pendingUnbindDate &&
    format(parseISO(pendingUnbindDate), 'd MMMM Y', {
      locale: ru,
    });

  /** Дата, с которой подписка перестанет быть триальной и начнёт взиматься плата */
  const formattedTrialEndDate =
    trialInfo?.trialEndDate &&
    format(parseISO(trialInfo?.trialEndDate), 'd MMMM Y', {
      locale: ru,
    });

  /** Дата, с которой возможно отключение, т.е. дата окончания МСП (минимального срока действия подписки) */
  const formattedAvailableUnbindDate =
    availableUnbindDt &&
    format(parseISO(availableUnbindDt), 'd MMMM Y', {
      locale: ru,
    });

  const dateOutput = (): string => {
    // если запланировано отключение
    if (formattedPendingUnbindDate) return formattedPendingUnbindDate;
    // если промо-период
    if (trialInfo?.isEnabled ?? trialInfo?.trialDays > 0)
      return formattedTrialEndDate;
    // если период МСП
    if (formattedAvailableUnbindDate) return formattedAvailableUnbindDate;
    return '';
  };

  /** Текст соглашения */
  const [agreement, setAgreement]: HooksTyping<string> = useState<string>(null);

  /** Получает текст соглашения */
  const getAgreement = async () => {
    try {
      const res = await getSubscriptionUnbindAgreement(simId);
      setAgreement(res);
    } catch (e) {
      console.error('getSubscriptionUnbindAgreement', e);
    }
  };

  useEffect(() => {
    getAgreement();
  }, []);

  return (
    <StyledSoftlineUnsubscribeWizard>
      <div className="unsubscribe__content-wrapper">
        <div className="unsubscribe__text-block unsubscribe__text-block-warning">
          <div className="unsubscribe__date">
            <Text color={defaultTheme.colors.shadow} lineHeight="24px">
              Отключение произойдёт
            </Text>
            <div>
              <LeadingText color={defaultTheme.colors.black}>
                <strong>{dateOutput()}</strong>
              </LeadingText>
            </div>
          </div>

          {formattedTrialEndDate && (
            <p>
              Действует промопериод, мы&nbsp;выполним заявку на&nbsp;следующий
              день после его завершения.
            </p>
          )}

          {formattedAvailableUnbindDate &&
            !formattedTrialEndDate &&
            isAfter(parseISO(banTrimDate), new Date()) && (
              <p>
                Действует минимальный срок подписки, мы&nbsp;выполним заявку
                на&nbsp;следующий день после его завершения.
              </p>
            )}

          {!formattedTrialEndDate && !formattedAvailableUnbindDate && (
            <p>
              Добровольная приостановка не означает прекращение договорных
              отношений между Оператором и Клиентом. Для закрытия контракта вам
              необходимо обратиться в любой из&nbsp;офисов Планеты.
            </p>
          )}
        </div>
        {agreement && (
          <div className="unsubscribe__agreement-block">
            <Text
              lineHeight="24px"
              dangerouslySetInnerHTML={{ __html: agreement }}
            />
          </div>
        )}
      </div>
    </StyledSoftlineUnsubscribeWizard>
  );
};

export default observer(SoftlineUnsubscribeWizard);
