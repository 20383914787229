/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
/** Components */
import {
  Button,
  ButtonStyleTypes,
  H3,
  LinkButton,
  Text,
} from 'cordis-core-ui-planeta';
import StyledFooter from './style';
/** Types */
import {
  ConfigTypes,
  MainProps,
} from '~/components/Blocks/Templates/Igrovoy/types';
/** API */
import { getGamesAccountAuthCode } from '~/api/api';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/**
 * Основной контент
 */
const Main: FC<MainProps> = ({
  activeConfig,
  configCreator,
  setGameParams,
  gameParams,
}: MainProps) => {
  const { toggleVisible } = useMakeAuthStore();

  const getAuthCode = async () => {
    try {
      const code = await getGamesAccountAuthCode();
      setGameParams({
        redirectUri: `${gameParams.redirectUri}?code=${code}`,
      });
      configCreator(ConfigTypes.SUCCESS_CONFIG);
    } catch (e) {
      console.error(e);
      configCreator(ConfigTypes.FAILURE_CONFIG);
    }
  };

  const mainButtonClick = () => {
    if (activeConfig.name === ConfigTypes.UNAUTHORIZED_CONFIG) toggleVisible();
    if (activeConfig.name === ConfigTypes.APPROVE_CONFIG) getAuthCode();
  };

  const secondaryButtonClick = () => {
    if (activeConfig.name === ConfigTypes.APPROVE_CONFIG)
      configCreator(ConfigTypes.CANCEL_CONFIG);
  };

  const linkButtonClick = () => {
    if (activeConfig.name === ConfigTypes.APPROVE_CONFIG) toggleVisible();
  };

  return (
    <>
      <H3>{activeConfig.header}</H3>
      <Text>{activeConfig.content}</Text>
      <StyledFooter>
        <span>
          <Button styleType={ButtonStyleTypes.MAIN} onClick={mainButtonClick}>
            {activeConfig.mainButton}
          </Button>
          {activeConfig.secondaryButton && (
            <Button
              styleType={ButtonStyleTypes.SECONDARY}
              onClick={secondaryButtonClick}
            >
              {activeConfig.secondaryButton}
            </Button>
          )}
        </span>
        {activeConfig.link && (
          <LinkButton onClick={linkButtonClick}>
            {activeConfig.link.name}
          </LinkButton>
        )}
      </StyledFooter>
    </>
  );
};

export default observer(Main);
