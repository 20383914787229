/** libraries */
import { types, Instance } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { toJS } from 'mobx';
/** interfaces */
import { Channel, ChannelPackage } from '../interfaces';
/** constants */
import {
  CardStates,
  CHANNEL_DESCRIPTION_ID,
  PACKAGE_DESCRIPTION_ID,
} from '../constants';
/** utils */
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';

const MyTVX3ModelStore = types
  .model('TV', {
    /** Активная карточка канала */
    channel: types.maybeNull(types.string),
    /** Активная карточка пакета */
    package: types.maybeNull(types.string),
    /** id карточки открытого канала/пакета для scroll */
    openCardBlockId: types.maybeNull(types.string),
    /** Состояние блока */
    cardState: types.maybeNull(types.enumeration(Object.values(CardStates))),
    /** Режим поиска */
    searchMode: types.boolean,
    /** Индекс активного таба */
    activeTabIndex: types.number,
    /** Режим редактирования трансформера */
    isEditTransformer: types.boolean,
    isDesktop940: types.boolean,
  })
  .views((self) => ({
    get activeChannelCard() {
      return toJS(JSON.parse(self.channel));
    },
    get activePackageCard() {
      return toJS(JSON.parse(self.package));
    },
    /** Популярные пакеты */
    getPopularPackages(packages: ChannelPackage[]) {
      if (packages?.length) {
        const packs = packages
          .filter((item) => !item?.isOrdered)
          .sort((a, b) => b.price - a.price);
        packs.length = 3;
        return packs;
      }
      return [];
    },
    /** Популярные каналы */
    getPopularChannels(channelList: Channel[]) {
      if (channelList.length) {
        const canals = channelList
          .filter((item) => !item.isAccessible && item.singleChannelPacks)
          .sort(
            (a, b) =>
              b.singleChannelPacks[0].price - a.singleChannelPacks[0].price,
          );
        canals.length = 5;
        return canals;
      }
      return [];
    },
  }))
  .actions((self) => ({
    setActiveChannelCard: (channel: Channel) => {
      self.channel = JSON.stringify(channel);
    },
    setActivePackageCard: (pack: ChannelPackage) => {
      self.package = JSON.stringify(pack);
    },
    setOpenCardBlockId: (blockId: string) => {
      self.openCardBlockId = blockId;
    },
    setCardState: (card: CardStates | null) => {
      self.cardState = card;
    },
    setSearchMode: (mode: boolean) => {
      self.searchMode = mode;
    },
    setActiveTabIndex: (index: number) => {
      self.activeTabIndex = index;
    },
    setIsEditTransformer: (isEdit: boolean) => {
      self.isEditTransformer = isEdit;
    },
    setIsDesktop940: (isDesktop940: boolean) => {
      self.isDesktop940 = isDesktop940;
    },
    resetMyTvX3Store: () => {
      self.channel = null;
      self.package = null;
      self.openCardBlockId = null;
      self.searchMode = false;
      self.cardState = CardStates.MAIN;
      self.activeTabIndex = 0;
      self.isEditTransformer = false;
      self.isDesktop940 = false;
    },
  }))
  .actions((self) => ({
    /** Обработчик клика на карточку канала */
    onClickChannelCard: (channel: Channel) => {
      self.setActiveChannelCard(channel);
      self.setCardState(CardStates.CHANNEL_DESCRIPTION);
      self.setSearchMode(false);
      self.setOpenCardBlockId(channel.name);
      if (!self.isDesktop940)
        setTimeout(() => {
          scrollToBlockById(CHANNEL_DESCRIPTION_ID, 'auto');
        }, 0);
    },
    /** Обработчик клика на карточку пакета */
    onClickPackageCard: (packet: ChannelPackage) => {
      self.setActivePackageCard(packet);
      self.setCardState(CardStates.PACKAGE_DESCRIPTION);
      self.setSearchMode(false);
      self.setOpenCardBlockId(packet.channelPackName ?? packet.name);
      if (!self.isDesktop940)
        setTimeout(() => {
          scrollToBlockById(PACKAGE_DESCRIPTION_ID, 'auto');
        }, 0);
    },
  }));

export type IMyTVX3ModelStore = Instance<typeof MyTVX3ModelStore>;

const store = MyTVX3ModelStore.create({
  channel: null,
  package: null,
  openCardBlockId: null,
  searchMode: false,
  cardState: CardStates.MAIN,
  activeTabIndex: 0,
  isEditTransformer: false,
  isDesktop940: false,
});

const MyTVX3StoreContext = createContext<IMyTVX3ModelStore>(store);

const useMyTVX3Store = () => {
  return useContext(MyTVX3StoreContext);
};

export default useMyTVX3Store;
