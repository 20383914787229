/** Библиотеки */
import styled from '@emotion/styled';
/** Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';
/** Константы */
import { desktop940, desktop500 } from '~/components/Grid/constants';

export const WizardConfirmWrapper = styled.div<{ horizontal: boolean }>`
  ${({ horizontal }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${horizontal ? '32px 0 56px;' : '0'};
  
  button {
    align-self: center;
    width: 100%;
  }

  a {
    padding: 0;
  }
  
  .agreement__description {
    display: flex;
    margin-bottom: 16px;
  }
  
  .agreement--desktopBr {
    display: none;
    @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
      display: block;
    }
  }

  .server-error {
    display: block;
    width: 100%;
    padding-bottom: 16px;
  }
  
  @media (min-width: ${desktop500}px) {
    button {
      max-width: 400px;
    }
  }
  
  @media (min-width: ${desktop940}px) {
    flex-direction: column;
    
    button {
      width: 245px;
      align-self: start;
    }

    .agreement__description {
      width: auto;
      align-self: start;
      margin-bottom: 16px;
    }
`}
`;
