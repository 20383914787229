/** libraries */
import { FC, useRef, useState, RefObject, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  ButtonStyleTypes,
  H3,
  defaultTheme,
  Text,
  Icon,
  Icons,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledDevicesList } from '~/components/Blocks/Templates/Devices/style';
import { StyledDeviceCardGroup } from '~/components/Blocks/Templates/Pab2c/Devices/Pab2cStyle';
import {
  StyledBanner,
  StyledBannerText,
  StyledNoDevices,
  StyledProductSelectorArrow,
} from '~/components/Blocks/Templates/Pab2c/Devices/Components/DeviceSliderList/styles';
/** components */
import DevicesCard from '~/components/Blocks/Shared/DevicesCard/DevicesCard';
import LinkWrapper from '~/components/LinkWrapper';
/** interfaces */
import { ImageSizeData } from '~/components/Blocks/Templates/Devices/interfaces';
import { ContractDevice } from '../../interfaces';
/** utils */
import { isExternal } from '~/utils/utils';
import useWindowSize from '~/utils/useWindowSize';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** constants */
import {
  desktop900,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { DEVICES } from '~/constants/common';
import { FIXED_CARD_WIDTH } from '~/components/Blocks/Shared/DevicesCard/constants';
import { SWIPE_AMOUNT } from '../../constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../../stores/useDevicesStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useDeviceAfterAuthStore from '../../stores/useDeviceAfterAuthStore';

const DeviceSliderList: FC = () => {
  const {
    pab2cDevicesStore: { pab2cDevicesList },
    authStore: { isAuth },
  } = useRootStore();
  const {
    size,
    text,
    contractDevices,
    banner,
    detailedDeviceStore: { setDetailedDeviceShow, setDetailedDevice },
    deviceLeasePayStore: { checkLeasePay },
  } = useDevicesStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const { deviceId, setDeviceId } = useDeviceAfterAuthStore();
  // Ссылка на основной блок компонента
  const deviceListRef: RefObject<HTMLDivElement> | null = useRef(null);
  // Ссылка на основной блок компонента
  const containerRef: RefObject<HTMLDivElement> | null = useRef(null);
  const [width] = useWindowSize();
  // Минимальное расстояние, которое можно считать свайпом
  const minSwipeDistance = 6;
  // Состояние при завершения нажатия на экран
  const clientX = useRef(null);
  /** Начальные координаты клика при нажатии на карточку */
  const mouseDownCoords = (e) => {
    clientX.current = e.clientX;
  };

  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  const isDesktop900 = useMediaQuery({
    query: `(min-width: ${desktop900}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Флаг отображения баннера */
  const isShowBanner = contractDevices.length <= 1 && isDesktop940;

  /** Если не свайп открыть карточку */
  const clickOrDrag = (e, item) => {
    const mouseUp = e.clientX;
    if (
      mouseUp < clientX.current + minSwipeDistance &&
      mouseUp > clientX.current - minSwipeDistance
    ) {
      handleDeviceCardClick(e, item);
    }
    clientX.current = null;
  };

  // Событие при клике на карточку
  const handleDeviceCardClick = (e, item: ContractDevice): void => {
    checkLeasePay(setOpenSPAfterAuthorizationState, setDeviceId);
    setDetailedDevice(item);
    setDetailedDeviceShow(true);

    e.preventDefault();
  };

  const getImageInfo = (imageSize: ImageSizeData): ImageSizeData => {
    if (!imageSize?.url) return imageSize;
    const url = isExternal(imageSize.url)
      ? imageSize.url
      : `${process.env.STATIC_SERVER}${imageSize.url}`;
    return { ...imageSize, url };
  };

  const noDevices = (): JSX.Element => {
    return (
      <StyledNoDevices>
        <LinkWrapper href={DEVICES}>Выбрать оборудование</LinkWrapper>
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          {parseHtml(text)}
        </Text>
      </StyledNoDevices>
    );
  };

  // Ширина карточки продукта
  const PRODUCT_ITEM_WIDTH = isDesktop900
    ? FIXED_CARD_WIDTH.X2.DESKTOP
    : FIXED_CARD_WIDTH.X2.MOBILE;

  // Состояние позиции списка продуктов по оси X
  const [xPosition, setXPosition] = useState<number>(0);

  // Обработка клика по правой стрелке
  const onRightClick = () => {
    setXPosition((prevState) => prevState - PRODUCT_ITEM_WIDTH);
  };
  // Обработка клика по левой стрелке
  const onLeftClick = () => {
    setXPosition((prevState) => prevState + PRODUCT_ITEM_WIDTH);
  };
  // Флаг отображения стрелки справа
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  /** Возобновляем действия после повторной авторизации */
  useEffect(() => {
    if (
      !isAuth ||
      !contractDevices.length ||
      !openSPAfterAuthorizationState ||
      !deviceId
    )
      return;

    const item = contractDevices.find((device) => device.id === deviceId);
    if (!item) return;

    setDetailedDevice(item);
    checkLeasePay(setOpenSPAfterAuthorizationState, setDeviceId);
    setDetailedDeviceShow(true);
    setDeviceId(null);

    if (openSPAfterAuthorizationState === SIDE_PAGES.DEVICES) {
      setOpenSPAfterAuthorizationState(null);
    }
  }, [isAuth, contractDevices]);

  useEffect(() => {
    if (
      !deviceListRef.current?.offsetWidth ||
      !containerRef.current?.offsetWidth ||
      pab2cDevicesList.length <= 1
    )
      return;
    // Сколько карточек продуктов полностью влезает на экран
    const visibleAmount = Math.floor(
      containerRef.current.offsetWidth / PRODUCT_ITEM_WIDTH,
    );

    // Позиция ленты продуктов, после которой необходимо скрыть правый переключатель
    const isMaxXPosition =
      Math.abs(xPosition) >=
      (pab2cDevicesList.length - visibleAmount) * PRODUCT_ITEM_WIDTH;

    if (
      (deviceListRef.current.offsetWidth > containerRef.current.offsetWidth ||
        !isMaxXPosition) &&
      !showRightArrow
    )
      setShowRightArrow(true);
    if (
      (deviceListRef.current.offsetWidth < containerRef.current.offsetWidth ||
        isMaxXPosition) &&
      showRightArrow
    )
      setShowRightArrow(false);
  }, [xPosition, width, pab2cDevicesList]);

  // Значения точек налача и конца swipe
  const touch = { start: 0, end: 0 };
  // Событие при начале swipe
  const handleTouchStart = (e) => {
    touch.start = e.targetTouches[0].clientX;
  };
  // Событие при самом swipe
  const handleTouchMove = (e) => {
    touch.end = e.targetTouches[0].clientX;
  };
  // Обработка завершения swipe
  const handleTouchEnd = () => {
    // Если пользователь сделал touch без swipe - touchEnd будет равна 0
    if (!touch.end) return;
    if (touch.start - touch.end > SWIPE_AMOUNT && showRightArrow) {
      onRightClick();
      touch.end = 0;
    }
    if (touch.start - touch.end < -SWIPE_AMOUNT && xPosition < 0) {
      onLeftClick();
      touch.end = 0;
    }
  };

  return (
    <span className="pab2cDevices__content">
      <StyledProductSelectorArrow
        onClick={onLeftClick}
        show={xPosition < 0}
        isLeft
      >
        <Icon icon={<Icons.ArrowIcon />} />
      </StyledProductSelectorArrow>
      <StyledDevicesList
        isPab2cView
        withBanner={isShowBanner}
        xPosition={xPosition}
        ref={containerRef}
      >
        {pab2cDevicesList?.length === 0 ? (
          noDevices()
        ) : (
          <span
            ref={deviceListRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <StyledDeviceCardGroup>
              {contractDevices.map((item) => {
                return (
                  <DevicesCard
                    key={item.sim}
                    isFixedWidth={`X${size}`}
                    isPab2cView
                    item={item}
                    onDeviceCardMouseDown={(e) => mouseDownCoords(e)}
                    onDeviceCardMouseUp={(e) => clickOrDrag(e, item)}
                    imageInfo={getImageInfo(item.imageSize)}
                  />
                );
              })}
            </StyledDeviceCardGroup>
          </span>
        )}
      </StyledDevicesList>
      <StyledProductSelectorArrow onClick={onRightClick} show={showRightArrow}>
        <Icon icon={<Icons.ArrowIcon />} />
      </StyledProductSelectorArrow>

      {isShowBanner && !banner?.url && <StyledBanner />}

      {isShowBanner && banner?.url && (
        <StyledBanner $imgUrl={banner.url}>
          <H3 color={defaultTheme.colors.white}>{banner.header}</H3>
          <StyledBannerText color={defaultTheme.colors.white}>
            {banner.mainText}
          </StyledBannerText>
          <LinkWrapper href={banner.link}>
            <Button styleType={ButtonStyleTypes.DARK_BACKGROUND}>
              {isDesktop1280 ? banner.buttonText : banner.buttonTextMobile}
            </Button>
          </LinkWrapper>
        </StyledBanner>
      )}
    </span>
  );
};

export default observer(DeviceSliderList);
