/** Библиотеки */
import { observer } from 'mobx-react';

/** Компоненты */
import {
  Icon,
  Icons,
  Text,
  Button,
  ButtonStyleTypes,
  ButtonIconPosition,
  LinkButton as CordisLinkButton,
} from 'cordis-core-ui-planeta';
import Link from 'next/link';
import {
  StyledMenuPopupMobile,
  StyledPopupHeaderMobile,
  StyledPopupContent,
  StyledContentColumnMobile,
  StyledMobileLogo,
} from './style';
import BalanceWidget from '../BalanceWidget';
import ProductItemMobile from '../ProductItemMobile';
import FolderItemMobile from '../FolderItemMobile';
import CitySelector from '~/components/Header/MenuPopup/MenuMobile/CitySelector';
import Contacts from '~/components/Header/MenuPopup/MenuMobile/Contacts';
import { BlockSelector } from '~/components/Blocks';
import { RemoveScroll } from 'react-remove-scroll';

/* Типы */
import { Folder, ProductCard } from '../MenuPopup.types';
import { MenuInfo } from '~/components/Feed/FeedPage.types';

/** Константы */
import { PRODUCTS_LIST, FOLDERS } from '../constants';
/** Утилиты */
import { getHeaderPhone } from '../../utils';
/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import { useRootStore } from '~/stores/RootStore';

/** интерфейс меню popup */
interface MenuPopupProps {
  toggleHandler: () => void;
  open: boolean;
  /* Состояние списка городов */
  isCitiesOpen: boolean;
  /* Открыть список городов */
  openCitiesHandler: () => void;
  /* Закрыть список городов */
  closeCitiesHandler: () => void;
  menu: MenuInfo;
}

/**
 * Компонент с мобильным меню
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=397992795
 * @returns {React.FC}
 */
const MenuMobile: React.FC<MenuPopupProps> = ({
  toggleHandler,
  open,
  openCitiesHandler,
  closeCitiesHandler,
  isCitiesOpen,
  menu,
}: MenuPopupProps) => {
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const {
    cityStore: { city, contactPhones },
    authStore: { isAuth },
  } = useRootStore();
  /**
   * вызывает клик по кнопке открытия чата
   */
  const openChat = (): void => {
    const button = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (button) button.click();
  };

  /** обработчик клика по кнопке CloseIcon   */
  const closeBtnClickHandler = (): void => {
    if (isCitiesOpen) closeCitiesHandler();
    toggleHandler();
  };

  return (
    <RemoveScroll enabled={open}>
      <StyledMenuPopupMobile className="menu-popup" open={open}>
        <div className="menu-popup-container" data-test-overlay="">
          <StyledPopupHeaderMobile>
            {isCitiesOpen && (
              <span
                onClick={closeCitiesHandler}
                onKeyPress={closeCitiesHandler}
                role="button"
                tabIndex={0}
                style={{ outline: 'none' }}
                data-test-button=""
              >
                <Icon icon={<Icons.BackIcon />} highlight />
              </span>
            )}
            <Link href="/">
              <div>
                <CordisLinkButton onClick={closeBtnClickHandler}>
                  <StyledMobileLogo
                    src={`${process.env.STATIC_SERVER}/static/images/planeta_logo_pink.svg`}
                    alt="Лого"
                  />
                </CordisLinkButton>
              </div>
            </Link>
            <span
              onClick={closeBtnClickHandler}
              onKeyPress={closeBtnClickHandler}
              role="button"
              tabIndex={isCitiesOpen ? 1 : 0}
              style={{ outline: 'none' }}
              data-test-button=""
            >
              <Icon icon={<Icons.CloseIcon />} highlight />
            </span>
          </StyledPopupHeaderMobile>
          <StyledPopupContent>
            {isCitiesOpen ? (
              <div className="menu-popup-container">
                <CitySelector onCloseClick={closeCitiesHandler} />
                <div className="menu-line-mobile" />
                <Contacts
                  contacts={contactPhones.filter(
                    (contactPhone) => contactPhone.cityId === city.id,
                  )}
                />
              </div>
            ) : (
              <StyledContentColumnMobile>
                {!isAuth ? (
                  <div className="column-title-mobile">
                    <div
                      className="column-links-mobile column-title"
                      onClick={openCitiesHandler}
                      onKeyPress={openCitiesHandler}
                      role="button"
                      tabIndex={0}
                      data-test-button=""
                    >
                      <Text>{city.name}</Text>
                      <Text fontWeightBold>
                        {getHeaderPhone(contactPhones, city)}
                      </Text>
                    </div>
                    <div className="menu-line-mobile" />
                    <div className="column-title-buttons">
                      <Button
                        styleType={ButtonStyleTypes.SECONDARY}
                        onClick={toggleConnectionWizardVisible}
                      >
                        Подключить Планету
                      </Button>
                      <Button
                        minWidth="50px"
                        styleType={ButtonStyleTypes.SECONDARY}
                        icon={<Icon icon={<Icons.ChatIcon />} />}
                        iconPosition={ButtonIconPosition.CENTER}
                        onClick={openChat}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <BalanceWidget toggleHandler={toggleHandler} />
                    <ul data-test-list="">
                      <li className="column-block" data-test-li="">
                        {menu?.blocks?.length > 0 && (
                          <BlockSelector block={menu.blocks[0]} />
                        )}
                      </li>
                    </ul>
                  </div>
                )}
                <ul data-test-list="">
                  {PRODUCTS_LIST.map((item: ProductCard) => (
                    <li key={item.title} data-test-li="">
                      <ProductItemMobile
                        toggleHandler={toggleHandler}
                        title={item.title}
                        subTitile={item.subTitile}
                        descrption={item.descrption}
                        href={item.href}
                      />
                    </li>
                  ))}
                </ul>
                <ul data-test-list="">
                  {FOLDERS.map((item: Folder) => (
                    <li key={item.name} data-test-li="">
                      <FolderItemMobile
                        name={item.name}
                        subFolders={item.subFolders}
                        toggleHandler={toggleHandler}
                      />
                    </li>
                  ))}
                </ul>
                {!isAuth && (
                  <ul data-test-list="">
                    <li className="column-block" data-test-li="">
                      {menu?.blocks?.length > 0 && (
                        <BlockSelector block={menu.blocks[0]} />
                      )}
                    </li>
                  </ul>
                )}
              </StyledContentColumnMobile>
            )}
          </StyledPopupContent>
        </div>
      </StyledMenuPopupMobile>
    </RemoveScroll>
  );
};

export default observer(MenuMobile);
