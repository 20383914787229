/** libraries */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';
/** interfaces */
import {
  STYLE,
  StyledTextProps,
} from '~/components/Blocks/Templates/SecondMenu/interfaces';
/** constants */
import { desktop700, desktop1280 } from '~/components/Grid/constants';
import StyledLinkWrapper from '~/components/LinkWrapper/style';

export default styled.div<{ backgroundColor: string; styleType: STYLE }>`
  ${({ backgroundColor, styleType }) => css`
    width: 100%;
    min-height: 351px;
    border-radius: ${styleType === STYLE.TWENTY_YEARS ? '50px' : '0'};
    background-color: ${backgroundColor};
    padding: ${styleType === STYLE.TWENTY_YEARS ? '36px 48px 0 51px' : '0'};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  `}
`;

/** Сверх планета десктоп */
export const StyledSuperPlanet = styled.div<{ backgroundImgLink: string }>`
  ${({ backgroundImgLink }) => {
    return css`
      background: no-repeat center url(${backgroundImgLink});
      background-size: contain;
      width: 100%;
      height: 345px;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: 500px;
        height: 70.09px;
        .carousel {
          &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          &__frame {
            width: min-content;
            color: white;
            font-weight: 700;
            font-size: 60px;
            line-height: 70.09px;
            letter-spacing: -0.03em;
            cursor: default;
          }
        }
      }
    `;
  }}
`;

/** Сверх планета мобилка */
export const StyledMobileSuperPlanet = styled.div<{
  backgroundImgLink: string;
}>`
  ${({ backgroundImgLink }) => {
    return css`
      background: no-repeat top url(${backgroundImgLink});
      background-size: contain;
      width: 100%;
      height: 372px;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: 500px;
        height: 70.09px;
        .carousel {
          &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          &__frame {
            width: min-content;
            color: white;
            font-weight: 700;
            font-size: 30px;
            line-height: 70.09px;
            letter-spacing: -0.03em;
            cursor: default;

            @media (min-width: ${desktop700}px) {
              font-size: 60px;
            }
          }
        }
      }
    `;
  }}
`;

export const MobileWrapper = styled.div<{
  styleType: STYLE;
  backgroundColor: string;
}>`
  width: 100%;
  min-height: 372px;
  border-radius: 50px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ styleType }) =>
    styleType === STYLE.TWENTY_YEARS ? '33px 34px' : '8px 0 0'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
`;

export const StyledMobileText = styled.div<StyledTextProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin: 15px 0 19px 0;
    color: ${({ textColor }) => textColor};
  }

  span {
    margin-bottom: 33px;
    color: ${({ textColor }) => textColor};
  }
`;

export const StyledText = styled.div<StyledTextProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;

  h1 {
    margin: 0 0 25px 0;
    color: ${({ textColor }) => textColor};
  }

  h3 {
    display: none;
    margin: 0;
    color: ${({ textColor }) => textColor};

    @media (min-width: ${desktop1280}px) {
      display: block;
    }
  }
`;

export const StyledImgWrapper = styled.div`
  width: 494px;
  height: 263px;
  position: relative;
`;

export const StyledMobileImgWrapper = styled.div`
  width: 195px;
  height: 115px;
  position: relative;
`;

export const StyledMenu = styled.div`
  min-width: 87.6%;
  height: 79px;
  background-color: ${defaultTheme.colors.white};
  border-radius: 270px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -39.5px;
  gap: 32px;
`;

export const MenuText = styled.span`
  cursor: pointer;
  &:hover {
    color: ${defaultTheme.colors.planeta};
  }
`;

export const ClosedCheckmark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 15px;
  width: 15px;
  border-bottom: 1px solid ${defaultTheme.colors.shadow};
  border-right: 1px solid ${defaultTheme.colors.shadow};
  position: relative;
  bottom: 3px;
`;

export const OpenedCheckmark = styled.div`
  display: inline-block;
  transform: rotate(-45deg);
  height: 15px;
  width: 15px;
  position: relative;
  top: 6.5px;
  border-right: 1px solid ${defaultTheme.colors.shadow};
  border-top: 1px solid ${defaultTheme.colors.shadow};
`;

export const MobileMenuWrapper = styled.div`
  width: 83.5%;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 89%;
  background-color: ${defaultTheme.colors.white};
  border-radius: 50px;
  z-index: 9;
  box-shadow: 1px 1px 5px 5px rgb(0 0 0 / 20%);

  ${ClosedCheckmark} {
    margin-left: 14.5px;
  }
`;

export const MobileMenuItem = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${ClosedCheckmark}, ${OpenedCheckmark} {
    margin-left: 14.5px;
  }

  ${StyledLinkWrapper} {
    width: 100%;

    a {
      display: block;
      width: 100%;
    }
  }
`;

export const StyledOpenedMenu = styled.div`
  width: 100%;

  ${MobileMenuItem} {
    &:first-of-type {
      margin-top: 32px;
    }

    margin-bottom: 32px;
  }
`;

export const StyledClosedMenu = styled.div`
  width: 100%;
`;
