/** libraries */
import { useMemo, useState } from 'react';
/* Утилиты */
import { formatTagsByObject } from './ProductTemplate.utils';
/** interfaces */
import {
  MarketingFieldsProps,
  MarketingTagsValues,
  ProductSelectorMiniSummaryProps,
  SummaryFieldsProps,
  useProductTemplateProps,
  useProductTemplateReturnProps,
} from './ProductTemplate.interfaces';
/** stores */
import { useRootStore } from '~/stores/RootStore';

export const useProductTemplate = ({
  marketingFields,
  summaryFields,
  blocks,
  isMiniProductSwitcher,
}: useProductTemplateProps): useProductTemplateReturnProps => {
  const {
    cityStore: { city },
  } = useRootStore();

  // Состояние позиции списка продуктов по оси X
  const [xPosition, setXPosition] = useState<number>(0);

  // Коды из marketingFields
  const codes = useMemo(() => marketingFields.map((item) => item.code), [
    marketingFields,
  ]);

  // Начальное состояние маркетинг тегов в виде объекта {'код маркетинг категории' : boolean}
  const initialActiveProductTags: MarketingTagsValues = formatTagsByObject(
    true,
    codes,
  );

  // Состояние маркетинг тегов
  const [
    activeProductTags,
    setActiveProductTags,
  ] = useState<MarketingTagsValues>(initialActiveProductTags);

  // Формирует список продуктов исходя из настроек фильтра
  const getProductList = (): SummaryFieldsProps[] => {
    // Активные фильтры
    const activeTags = Object.entries(activeProductTags)
      .filter((item) => item[1])
      .map((item) => item[0]);
    if (!marketingFields.length || !activeTags) return [];
    const miniProductSwitcherProducts = isMiniProductSwitcher
      ? marketingFields.find((tag) => activeTags.includes(tag.code))
          ?.productsList ?? []
      : [];
    const productSelectorProducts = !isMiniProductSwitcher
      ? summaryFields.filter((item: SummaryFieldsProps) => {
          return (
            activeTags.includes(item.marketingGroupCode) &&
            blocks.some(
              (blockItem) => blockItem.seriesCode === item.seriesCode,
            ) &&
            (item.cities.includes(city.id) || !item.cities.length)
          );
        })
      : [];
    // Массив продуктов для сортировки
    const sortProductsArray = blocks.map(
      (item: ProductSelectorMiniSummaryProps) => item.seriesCode,
    );

    // Сортирует массив продуктов в соответствии со списком сортировки
    const sortByProducts = (
      prev: SummaryFieldsProps,
      next: SummaryFieldsProps,
    ) =>
      sortProductsArray.indexOf(prev.seriesCode) -
      sortProductsArray.indexOf(next.seriesCode);

    return isMiniProductSwitcher
      ? [...miniProductSwitcherProducts].sort((a, b) => a.sort - b.sort)
      : [...productSelectorProducts].sort(sortByProducts);
  };

  const getMarketingNameByCode = (marketingGroupCode: string): string => {
    const marketingName = marketingFields.find(
      (item: MarketingFieldsProps) => item.code === marketingGroupCode,
    )?.name;
    return marketingName || '';
  };

  // Возвращает ссылку на маркетинг категорию и продукт по коду продукта
  const getMiniSummaryByCode = (
    seriesCode: string,
  ): ProductSelectorMiniSummaryProps[] => {
    return blocks.filter(
      (item: ProductSelectorMiniSummaryProps) => item.seriesCode === seriesCode,
    );
  };

  const productListLength = getProductList().length;

  return {
    getProductList,
    activeProductTags,
    setActiveProductTags,
    getMarketingNameByCode,
    getMiniSummaryByCode,
    productListLength,
    xPosition,
    setXPosition,
    codes,
  };
};
