import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledAutopayment = styled.div<{ isResult: boolean }>`
  ${({ isResult }) => {
    return css`
      .sidepage {
        &__content {
          ${isResult ? 'margin-top: 12px !important;' : ''}
        }
      }
    `;
  }}
`;
