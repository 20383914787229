import styled from '@emotion/styled';
import { H3, defaultTheme, Switcher } from 'cordis-core-ui-planeta';
import StyledLinkWrapper from '~/components/LinkWrapper/style';

export default styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;
  background: ${defaultTheme.colors.white};
  min-height: 400px;
  word-break: break-all;

  ${StyledLinkWrapper} {
    margin-top: -5px;
    span {
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

export const StyledHeader = styled(H3)`
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const StyledIPList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledLoaderWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSwitcher = styled(Switcher)`
  input:checked:disabled ~ .slider {
    border: 1px dashed ${defaultTheme.colors.disable};
  }
`;
