/* Библиотеки */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
/** компоненты библиотеки */
import { defaultTheme, SidePage } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledDevices = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${defaultTheme.colors.white};
  padding: 32px 32px 0 32px;

  .pab2cDevices {
    &__content {
      display: flex;
      position: relative;
    }
  }

  @media (min-width: ${desktop940}px) {
    min-height: 400px;
    height: auto;

    .pab2cDevices__content {
      height: 320px;
      width: 100%;
    }
  }
`;

export const StyledDeviceCardGroup = styled.ul`
  display: flex;
  justify-content: flex-start;
  background: ${defaultTheme.colors.white};
  list-style: none;
`;

export const StyledDevicesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > h3 {
    margin-bottom: 16px;
  }

  button {
    margin-bottom: 16px;
    width: 100%;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: baseline;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSidePage = styled(SidePage)<{ $isHideHeader?: boolean }>`
  ${({ $isHideHeader }) =>
    $isHideHeader &&
    css`
      .sidepage__header {
        visibility: hidden;
      }
    `}
`;
