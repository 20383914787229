/** Библиотеки */
import * as React from 'react';

/** Типы */
import { Icon, Icons, Text } from 'cordis-core-ui-planeta';
import { Folder, ProductCard } from '../MenuPopup.types';
import { MenuInfo } from '~/components/Feed/FeedPage.types';

/** Компоненты */
import {
  StyledMenuPopup,
  StyledPopupHeader,
  StyledPopupContent,
  StyledContentColumn,
} from './style';
import ProductItem from '../ProductItem';
import FolderItem from '../FolderItem';
import { BlockSelector } from '~/components/Blocks';
import { Col } from '~/components/Grid/Col';
import { RemoveScroll } from 'react-remove-scroll';

/** Константы */
import { PRODUCTS_LIST, FOLDERS } from '../constants';

/** интерфейс меню popup */
interface MenuPopupProps {
  toggleHandler: () => void;
  open: boolean;
  menu: MenuInfo;
}

/**
 * Компонент с обычной версией меню
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=397992795
 * @returns {React.FC}
 */
const MenuDesktop: React.FC<MenuPopupProps> = ({
  toggleHandler,
  open,
  menu,
}: MenuPopupProps) => {
  return (
    <RemoveScroll enabled={open}>
      <StyledMenuPopup className="menu-popup" open={open}>
        <div className="menu-popup-container" data-test-overlay="">
          <StyledPopupHeader>
            <span
              onClick={toggleHandler}
              onKeyPress={toggleHandler}
              role="button"
              tabIndex={0}
              style={{ outline: 'none' }}
              data-test-button=""
            >
              <Icon icon={<Icons.CloseIcon />} highlight />
            </span>
          </StyledPopupHeader>
          <StyledPopupContent>
            <StyledContentColumn>
              <div className="column-title">
                <Text fontWeightBold>Продукты</Text>
              </div>
              <ul className="column-item-1" data-test-list="">
                {PRODUCTS_LIST.map((item: ProductCard) => (
                  <li key={item.title} data-test-li="">
                    <ProductItem
                      toggleHandler={toggleHandler}
                      href={item.href}
                      descrption={item.descrption}
                      subTitile={item.subTitile}
                      title={item.title}
                    />
                  </li>
                ))}
              </ul>
            </StyledContentColumn>
            <StyledContentColumn>
              <ul className="column-item-2" data-test-list="">
                {FOLDERS.map((item: Folder) => (
                  <li key={item.name} data-test-li="">
                    <FolderItem
                      name={item.name}
                      subFolders={item.subFolders}
                      toggleHandler={toggleHandler}
                    />
                  </li>
                ))}
              </ul>
            </StyledContentColumn>
            <StyledContentColumn>
              <ul className="column-item-3" data-test-list="">
                <li data-test-li="">
                  <Col>
                    {menu?.blocks?.length > 0 && (
                      <BlockSelector block={menu.blocks[0]} />
                    )}
                  </Col>
                </li>
                <li data-test-li="">
                  <Col>
                    {menu?.blocks?.length > 0 && (
                      <BlockSelector block={menu.blocks[1]} />
                    )}
                  </Col>
                </li>
              </ul>
            </StyledContentColumn>
          </StyledPopupContent>
        </div>
      </StyledMenuPopup>
    </RemoveScroll>
  );
};

export default MenuDesktop;
