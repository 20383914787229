import styled from '@emotion/styled';

export const StyledAppeal = styled.div`
  .appeal {
    &__tag-block {
      display: flex;
      align-items: center;
      margin: 0 0 34px 48px;

      > div {
        margin-right: 5px;
      }
    }

    &__contract-number {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:nth-of-type(2) {
        font-size: 16px;
        line-height: 20px;
        margin-top: 4px;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }

      > span:nth-of-type(2) {
        white-space: pre-line;
      }
    }

    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__comments-block {
      > span {
        display: block;
        margin-bottom: 16px;
      }
      &__comment {
        > div {
          margin-bottom: 10px;
        }
        > div:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;
