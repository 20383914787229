// state amount по умолчанию
export const DEFAULT_AMOUNT = {
  value: '',
  forSend: '',
};

export const DOCUMENTS_LINK =
  '/static/upload-files/documents/topicals/ktc.reglament.pdf';

// Тип состояния отображение визарда
export enum VIEW_TYPES {
  /** Автоплатёж не подключён, отображается вёрстка подключения */
  BIND,
  /** Успешное подключение */
  BIND_SUCCESS,
  /** Ошибка подключения */
  BIND_FAILED,
  /** Автоплатёж подключён, отображается вёрстка настройки */
  UNBIND,
  /** Успешное отключение */
  UNBIND_SUCCESS,
  /** Ошибка отключения */
  UNBIND_FAILED,
  /** Привязка счёта */
  LINKING_ACCOUNT,
  /** Ошибка привязки сбп-счёта */
  LINKING_ACCOUNT_FAILED,
  /** Ошибка привязки карты */
  LINKING_CARD_FAILED,
}

/** идентификатор элемента блока AutoPayment */
export const AUTO_PAYMENT_BLOCK_ID = '#auto_payment';
