import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { StyledFillableScale } from '~/components/Blocks/Shared/FillableScale/style';
import { desktop940 } from '~/components/Grid/constants';

export const StyledPasswordSidePage = styled.div`
  .password {
    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }
    }
    &__input-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      > span {
        margin-bottom: 8px;
      }

      &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &__action {
          margin-top: 16px;
          cursor: pointer;
        }
        div[data-test-error] {
          width: auto;
        }
        > span {
          width: 100%;
          > div {
            width: 100%;
            & > div {
              width: 100%;

              input {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &__security {
      display: flex;
      flex-direction: column;
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 32px;

      > span:not(:last-of-type) {
        margin-bottom: 4px;
      }

      &__warning {
        margin-top: 16px;
      }

      .line-through {
        text-decoration-line: line-through;
        color: ${defaultTheme.colors.disable};
      }
    }
  }

  ${StyledFillableScale} {
    margin: 0 40px 0 0;

    .scale {
      margin: 4px 0 8px;
    }
  }

  @media (min-width: ${desktop940}px) {
    .password {
      &__input-block {
        &__block {
          flex-direction: row;
          &__action {
            margin: 12px 0 0 16px;
          }
          div[data-test-error] {
            width: 330px;
          }
          > span {
            width: 330px;
            > div {
              width: 330px;
              & > div {
                width: 330px;

                input {
                  width: 330px;
                }
              }
            }
          }
        }
      }
    }

    ${StyledFillableScale} {
      width: auto;
      margin: 0 40px 0 0;
    }
  }
`;

export const StyledFooter = styled.div`
  button {
    width: 100%;
  }
  @media (min-width: ${desktop940}px) {
    button {
      width: fit-content;
    }
  }
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;

  > svg {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  button {
    width: fit-content;
  }

  .result {
    &__time {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }
    }
    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      width: 100%;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    h1 {
      font-size: 44px;
      line-height: 52px;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;
