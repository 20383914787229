/** библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** интерфейсы */
import { SoftlineContentProps } from './types';
/** stores */
import { SoftlineProvider } from './stores/mainStore/useMainSoftlineStore';
/** components */
import SoftlineContent from './SoftlineContent';

/**
 * Лента «Подписки на антивирусы»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 * @param content
 */
const Softline: FC<SoftlineContentProps> = ({
  content,
}: SoftlineContentProps) => {
  return (
    <SoftlineProvider fields={content.fields}>
      <SoftlineContent />
    </SoftlineProvider>
  );
};

export default observer(Softline);
