/* eslint-disable no-bitwise */
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';

function createSha256(value) {
  const hash = sha256(value);
  const hex = Hex.stringify(hash);
  return hex;
}

export const getHash = (firstNumber: string, secondNumber: bigint): string => {
  const hash = createSha256(
    createSha256(firstNumber).toUpperCase() + secondNumber,
  );
  return hash;
};

export const F = (serverKey: string, randomKey: number): bigint => {
  const result = BigInt.asUintN(
    52,
    BigInt.asUintN(52, ~BigInt(serverKey)) ^
      (BigInt.asUintN(32, ~BigInt(randomKey)) * BigInt(randomKey)),
  );
  return result;
};

export const calculate = (
  serverKey: string,
  hash: string,
  hashRate: number,
): bigint => {
  for (let i = 1; i < hashRate; i++) {
    const funcResult = F(serverKey, i);
    const createHash = getHash(serverKey, funcResult);
    if (createHash.toUpperCase() === hash) {
      return funcResult;
    }
  }
  return null;
};

export const getRandomInt = (minNum, maxNum) => {
  const min = Math.ceil(minNum);
  const max = Math.floor(maxNum);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
