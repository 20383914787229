/** api */
import { getTechnicalPossibility } from '~/api/api';

/** типы */
import { TechnicalPossibility } from './ConnectionWizard.types';

/** константы */
import { TechnicalPossibilityType } from './constants';

/**
 * получает информацию о технической возможности
 * @param city Город
 * @param street Улица
 * @param house Дом
 * @param flat Квартира
 * @param isLogNeeded Необходимость записи статистики
 */
export const getPossibility = async (
  city: string,
  street: string,
  house: string,
  flat: string,
): Promise<TechnicalPossibility> => {
  const possibilityResult = await getTechnicalPossibility(
    city,
    street,
    house,
    flat,
  );
  const { tp } = possibilityResult;
  // для статистики
  window.dataLayer = window.dataLayer || [];
  if (tp === TechnicalPossibilityType.PRESENT) {
    window.dataLayer.push({ event: 'checkon' });
  } else if (tp === TechnicalPossibilityType.MISSING) {
    window.dataLayer.push({ event: 'checkoff' });
  }
  return possibilityResult;
};
