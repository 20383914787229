/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import {
  defaultTheme,
  PriceTag,
  Button,
  PriceTagBackgroundColor,
  ButtonStyleTypes,
  Icon,
  Icons,
  H2,
  H3,
  Loader,
  Text,
  LeadingText,
  SidePage,
} from 'cordis-core-ui-planeta';

/** styles */
import { StyledMaintenanceWork, StyledProductSwitcherWizard } from './style';

/** interfaces */
import { TariffProps } from '../../Templates/ProductSwitcher/interfaces';

/** components */
import LinkWrapper from '~/components/LinkWrapper';
import ProductSwitcherWizardFooter from './ProductSwitcherWizardFooter';

/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { PRODUCT_CHANGE_ERRORS } from '../../Templates/ProductSwitcher/constants';

/** utils */
import { formatNumber } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { dateString } from '../../Templates/Pab2c/Contract/utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useProductSwitcherWizardStore from './store/useProductSwitcherWizardStore';

/**
 * SidePage подключения/смены продукта.
 */
const ProductSwitcherWizard: FC = () => {
  const {
    authStore: { auth },
    connectionTariffStore: { toggleChangeTariffWizardVisible, connectTariff },
    summaryDataStore: { getSummariesByTariffIds },
    allowedTariffStore: { getTariffAllowed },
  } = useRootStore();

  const {
    result,
    agreement,
    productChangeError,
    resetProductSwitcherWizardStore,
  } = useProductSwitcherWizardStore();

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Разница между балансом и ценой оборудования */
  const differenceBetweenBalanceAndConnectionPrice =
    auth.balance - ((connectTariff as TariffProps)?.price ?? 0);

  const maintainceWork = () => {
    return (
      <StyledMaintenanceWork>
        <Icons.BlockedIcon />
        {isDesktop940 ? (
          <H2>Смена продукта временно недоступна</H2>
        ) : (
          <LeadingText color={defaultTheme.colors.black}>
            Смена продукта временно недоступна
          </LeadingText>
        )}
        <div className="yellow-block">
          <Text lineHeight="24px">
            Возможность смены продукта недоступна на время проведения
            технических работ. <br /> Повторите попытку позднее.
          </Text>
        </div>
        <Text lineHeight="24px">
          Остальные функции раздела «Моя Планета» доступны в полном объёме.
        </Text>
      </StyledMaintenanceWork>
    );
  };

  /** Текст на экране результата */
  const resultText = 'Заявка на смену продукта\nуспешно отправлена';

  const isSuccessAgreement = !productChangeError;

  const isNotEnoughMoney =
    productChangeError === PRODUCT_CHANGE_ERRORS.NOT_ENOUGH_MONEY;

  /** Профилактические работы. ЛК не доступен */
  const isMaintenanceWork =
    productChangeError === PRODUCT_CHANGE_ERRORS.ERROR_MAINTENANCE;

  const updateSummary = async () => {
    await getSummariesByTariffIds();
    await getTariffAllowed();
  };

  return (
    <SidePage
      show={!!connectTariff}
      headerText={
        connectTariff && !result.isResult && !isMaintenanceWork
          ? `Подключение продукта${'\u00A0'}${connectTariff.seriesName}`
          : ''
      }
      onCloseClick={() => {
        toggleChangeTariffWizardVisible(null);
        if (result.isResult && result.isCorrect) {
          updateSummary();
        }
        resetProductSwitcherWizardStore();
      }}
      footerContainer={!result.isResult && <ProductSwitcherWizardFooter />}
      removeScrollBar={false}
    >
      {isMaintenanceWork ? (
        maintainceWork()
      ) : (
        <StyledProductSwitcherWizard>
          {result.isResult && result.isCorrect && (
            <>
              <div className="product-change__result-icon">
                <Icon icon={<Icons.SuccessIconAnimated />} />
              </div>
              {isDesktop1100 ? <H2>{resultText}</H2> : <H3>{resultText}</H3>}
            </>
          )}

          {result.isResult && !result.isCorrect && (
            <>
              <div className="product-change__result-icon">
                <Icon icon={<Icons.NotOkBigIcon />} />
              </div>
              {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
            </>
          )}

          {!result.isResult && (
            <>
              {(connectTariff as TariffProps)?.price > 0 &&
                (isSuccessAgreement || isNotEnoughMoney) && (
                  <div className="product-change__block-wrapper">
                    <div className="product-change__block-wrapper__price-tag">
                      <PriceTag
                        header="Стоимость подключения"
                        value={`${formatNumber(
                          (connectTariff as TariffProps)?.price,
                        )}₽`}
                        backgroundColor={
                          differenceBetweenBalanceAndConnectionPrice > 0
                            ? PriceTagBackgroundColor.OK
                            : PriceTagBackgroundColor.WARNING
                        }
                        subscription={
                          differenceBetweenBalanceAndConnectionPrice > 0
                            ? `На вашем${'\u000A'}счету ${formatNumber(
                                auth.balance,
                              )} ₽`
                            : `Не хватает${'\u000A'}${formatNumber(
                                Math.abs(
                                  differenceBetweenBalanceAndConnectionPrice,
                                ),
                              )} ₽`
                        }
                        fontColor={
                          differenceBetweenBalanceAndConnectionPrice > 0
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.warning
                        }
                        headerType="H2"
                      />
                      {differenceBetweenBalanceAndConnectionPrice < 0 && (
                        <LinkWrapper href="/payment">
                          <Button styleType={ButtonStyleTypes.SECONDARY}>
                            {!isDesktop1100 ? 'Пополнить' : 'Пополнить баланс'}
                          </Button>
                        </LinkWrapper>
                      )}
                    </div>
                  </div>
                )}

              {(isSuccessAgreement || isNotEnoughMoney) && (
                <div className="product-change__date">
                  <div className="product-change__date__block">
                    <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                      Дата подключения Продукта
                    </Text>
                    <LeadingText color={defaultTheme.colors.black}>
                      {dateString(new Date(), 'd MMMM')}
                    </LeadingText>
                  </div>
                </div>
              )}

              <div className="product-change__agreement">
                {agreement ? (
                  <>
                    {isSuccessAgreement && (
                      <Text
                        className="product-change__agreement__header"
                        lineHeight="24px"
                        fontWeightBold
                      >
                        Согласие на подключение продукта «
                        {connectTariff?.seriesName}»
                      </Text>
                    )}
                    {parseHtml(agreement)}
                  </>
                ) : (
                  <div className="loader">
                    <Loader />
                  </div>
                )}
              </div>
            </>
          )}
        </StyledProductSwitcherWizard>
      )}
    </SidePage>
  );
};

export default observer(ProductSwitcherWizard);
