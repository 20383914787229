/** библиотеки */
import styled from '@emotion/styled';

/** константы */
import { IMAGES } from '../constants';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const StyledSberSMS = styled.div`
  height: auto;
  width: 100%;
  background: ${defaultTheme.colors.white};
  padding: 32px;

  h2,
  h3 {
    margin-bottom: 16px;
  }

  h2 {
    width: 288px;
  }

  h3 {
    width: 177px;
  }

  .mainText {
    margin-bottom: 16px;
  }

  .background {
    position: relative;
    width: 217px;
    height: 126px;
  }

  .greenBackground {
    position: relative;
    width: 194px;
    height: 48px;
    background: #64c866;
    border-radius: 60px;
  }

  .greenText {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .greenImage {
    position: absolute;
    background: url(${IMAGES.greenSMS}) no-repeat;
    width: 27.5px;
    height: 28px;
    left: 175px;
    top: 23.5px;
  }

  .line {
    position: absolute;
    width: 24px;
    height: 0px;
    top: 63px;
    background-color: ${defaultTheme.colors.gray};
    border: 1px solid ${defaultTheme.colors.gray};
    transform: rotate(90deg);
  }

  .firstLine {
    left: 93px;
  }

  .secondLine {
    left: 157px;
  }

  .text {
    position: absolute;
    height: 48px;
    top: 78px;
  }

  .contractNumber {
    width: 70px;
    left: 71px;
  }

  .amountOfPayment {
    width: 64px;
    left: 153px;
  }

  @media (min-width: ${desktop940}px) {
    height: 400px;
  }
`;
