export enum APPEAL_STATUS {
  /** Не обработано */
  RAW = 'open',
  /** В работе */
  IN_WORK = 'process',
  /** Закрыто */
  CLOSED = 'done',
  REJECT = 'reject',
}

export interface AppealData {
  id: number;
  stateCode: APPEAL_STATUS;
  isClosed: boolean;
  createdDate: string;
  mem: string;
  unreadCommentCount: number;
}

export interface CommentData {
  content: string;
  isManager: boolean;
}

/** Состояние сп "Мои обращения" */
export enum MY_APPEALS_STATE {
  MAIN,
  CREATE_NEW_APPEAL,
  CREATE_NEW_APPEAL_CHANGE_CONTRACT,
  APPEAL,
}

export interface InputValue {
  forSend: string;
  value: string;
}

export interface ResultProps {
  isResult: boolean;
  isCorrect: boolean;
}

export interface AutocompleteProps {
  label: string;
  value: string;
}
