import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledCancelingNumberDeactivation = styled.div`
  .number {
    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }
    }

    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 32px;
    }
  }
  @media (min-width: ${desktop940}px) {
    .number {
      &__block {
        margin-bottom: 16px;
      }
    }
  }
`;

export const StyledFooter = styled.div`
  button {
    width: 100%;
  }
  @media (min-width: ${desktop940}px) {
    button {
      width: fit-content;
    }
  }
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;

  > svg {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: ${desktop940}px) {
    h1 {
      font-size: 44px;
      line-height: 52px;
    }
  }
`;
