import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop300, desktop940 } from '~/components/Grid/constants';

export const StyledChatleWizardSuccess = styled.div`
  margin: -36px 0 0 0;
  & > h2 {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    margin: 24px 0 32px 0;
  }

  & strong {
    font-weight: 700;
  }

  @media (min-width: ${desktop940}px) {
    & > h2 {
      font-weight: 700;
      font-size: 44px;
      line-height: 45px;
    }
  }
`;
export const ChatleWizardContainer = styled.div`
  ${() => `
    
  .wizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: ${defaultTheme.colors.white};
    
    &__header {
      margin: 0 0 24px 0;
    }
    
    &__warning {
      margin: 16px 0;
      background: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      
      & p + p {
        margin: 16px 0 0 0;
      }
    }
    
    &__wrapper {
      width: 100%;
      padding: 0;
    }
    
    &__legal {
      & a {
        color: ${defaultTheme.colors.planeta};
        &:hover {
          text-decoration: none;
        }
      }
    }
    
    &__input {
      margin: 8px 0 16px;
      max-width: 100%;
      &-limit {
        font-size: 16px;
      }

      & input {
        max-width: 100%;
        border-radius: 6px;
        width: 100%;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button,
        &[type=number] {
          -webkit-appearance: none;
          -moz-appearance: textfield;
        }
      }
    }
    
    &__label {
      width: 320px;
      max-width: 100%;
      justify-content: space-between;
      display: flex;
      label {
        justify-content: flex-start;
        color: ${defaultTheme.colors.shadow};
      }
    }
    
    &__button {
      text-align: center;
      margin-top: 8px;

      button {
        width: 320px;
        max-width: 100%;
        background: transparent;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      margin: 8px 0 8px;
    }

    &__error {
      margin-bottom: 16px;
    }

    &__server-error {
      display: block;
      width: 100%;
      margin-top: 16px;
    }
  }
  
  @media (min-width: ${desktop940}px) {
    .wizard {
      &__wrapper {
        padding: 0 80px 0 0;
      }
      &__input {
        & input {
          width: 500px;
        }
      }
    }
  }
  

  @media (min-width: ${desktop300}px) {
    .wizard {      
      &__input {
       max-width: 100%;
      }
      
      &__label {
        display: flex;
      }
    }
  }
`}
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
