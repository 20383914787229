/** Библиотеки */
import React, { FC, useEffect, useRef, RefObject } from 'react';
import { observer } from 'mobx-react';

/** Компоненты  */
import { SidePage } from 'cordis-core-ui-planeta';
import DeviceCardWizard from '~/components/Blocks/Templates/Pab2c/Devices/Components/DeviceCardWizard/DeviceCardWizard';
import DeviceCardWizardFooter from '~/components/Blocks/Templates/Pab2c/Devices/Components/DeviceCardWizard/DeviceCardWizardFooter';

/** Константы */
import { DEVICE_TYPES } from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** Утилиты */
import { SidePageHandle } from 'cordis-core-ui-planeta/dist/components/SidePage/SidePage.types';

/** hooks */
import useWarranty from '../useWarranty';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../stores/useDevicesStore';

/**
 * Блок pab2cDevices. SidePage детальной информации об оборудовании
 */
const DetailedDeviceSP: FC = () => {
  const {
    detailedDeviceStore: {
      isDetailedDeviceShow,
      setDetailedDeviceShow,
      detailedDevice,
    },
  } = useDevicesStore();

  const {
    pab2cDevicesStore: { updatePab2cDevices },
  } = useRootStore();

  /** Родительский сайдпейдж */
  const parentSidePage: RefObject<SidePageHandle> = useRef(null);

  const warrantyState = useWarranty(
    detailedDevice,
    updatePab2cDevices,
    parentSidePage,
  );

  useEffect(() => {
    if (parentSidePage.current) parentSidePage.current.scrollToTop();
  }, [isDetailedDeviceShow]);

  return (
    <SidePage
      show={isDetailedDeviceShow}
      headerText={`
    ${
      detailedDevice?.deviceTypeCode
        ? DEVICE_TYPES[detailedDevice?.deviceTypeCode]
        : ''
    }  ${detailedDevice.modelName}`}
      onCloseClick={() => setDetailedDeviceShow(false)}
      footerContainer={<DeviceCardWizardFooter warrantyState={warrantyState} />}
      isOnlyMobileFooter
      removeScrollBar
      ref={parentSidePage}
    >
      <DeviceCardWizard warrantyState={warrantyState} />
    </SidePage>
  );
};

export default observer(DetailedDeviceSP);
