import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledCostByDestination = styled.div`
  .cost {
    &__switcher {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      > span:first-of-type {
        margin-right: 24px;
      }

      table {
        .wrapper {
          margin-right: 24px;

          .slider__button {
            background-color: ${defaultTheme.colors.planeta}!important;
            > span {
              visibility: hidden;
            }
          }
        }
      }

      &__text {
        font-weight: 500;
      }
    }
    &__block {
      display: grid;
      gap: 24px;

      &__tabs {
        max-height: 413px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding-right: 7px;

        @supports (scrollbar-gutter: stable) {
          scrollbar-gutter: stable;
          padding-right: 14px;
        }
        &__line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          cursor: pointer;
        }
      }

      &__tabs::-webkit-scrollbar {
        width: 2px;
      }

      &__tabs::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 7px;
      }

      &__tabs::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background: ${defaultTheme.colors.disable};
      }

      &__tabs:hover {
        overflow: auto;
      }

      &__select {
        width: 100%;
        > div {
          width: 100%;
        }
        &__header {
          margin-bottom: 8px;
        }
      }

      &__info {
        max-height: 413px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding-right: 7px;
        &__header {
          margin-bottom: 10px;
        }
      }

      &__info:hover {
        overflow: auto;
        padding-right: 5px;
      }

      &__info::-webkit-scrollbar {
        width: 2px;
      }

      &__info::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 6px;
      }

      &__info::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: ${defaultTheme.colors.disable};
      }
    }

    &__mobile-select {
      background: ${defaultTheme.colors.white};
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__tag-button {
        height: 52px;
      }

      button {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
  @media (min-width: ${desktop940}px) {
    .cost {
      &__block {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;
