/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  H1,
  H3,
  Icon,
  Icons,
  LeadingText,
  OptionProp,
  OutsideClickHelper,
  Select,
  SidePage,
  Snoska,
  TagButton,
  Text,
  defaultTheme,
  Tabs,
  TabsStyleTypes,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledConnectNumber, StyledResult, StyledPrices } from './styles';
/** interfaces */
import { SelectProps } from '../../interfaces';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR, DEFAULT_RESULT } from '~/constants/common';
import { SatelliteCodes } from '../../constants';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { maskHomePhone } from '../../utils';
import { formatNumber } from '~/utils/utils';
/** api */
import { bindPhone } from '~/api/apiPab2c';
/** components */
import ConnectNumberFooter from './ConnectNumberFooter';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from '../../store/useVoiceStore';

/** Подключить номер */
export const ConnectNumberSidePage: FC = () => {
  const {
    cityStore: { city },
    pab2cVoiceStore: {
      phones,
      satelliteCode,
      getPhones,
      maintainPrice,
      agreement,
      minRentalTime,
      rentalPrice,
      satellite,
      setSatelliteCode,
      getPhoneData,
    },
  } = useRootStore();
  const {
    isShowConnectNumber,
    setIsShowConnectNumber,
    result,
    setResult,
    setIsShowPassword,
    setIsCreatePassword,
    setPhonePassword,
  } = useVoiceStore();

  const selectCities = useMemo(() => {
    if (!satellite.length) return [];
    return satellite.map((item) => {
      return {
        label: item.prefixedName,
        value: item.satellite.toString(),
      };
    });
  }, [satellite]);

  /** город */
  const [citySelect, setCitySelect] = useState<SelectProps>(null);

  /** Активный таб цены за использование номера */
  const [priceIndex, setPriceIndex] = useState(0);
  const pricesForMaintain = [
    formatNumber(maintainPrice),
    formatNumber(maintainPrice * 30),
  ];

  useEffect(() => {
    if (selectCities.length && !citySelect) {
      const select = selectCities.find(
        (item) =>
          Number(item.value) === city.satellite ||
          Number(item.value) === satelliteCode,
      );
      setCitySelect(select);
      setCandidateForSelect(select);
    }
  }, [selectCities, satelliteCode]);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** id подключаемого номера */
  const [phoneId, setPhoneId] = useState<number>(null);

  /** Подключённый номер */
  const connectedPhone = useMemo(() => {
    return phoneId ? phones.find((item) => item.sim === phoneId) : undefined;
  }, [phoneId, phones]);

  useEffect(() => {
    if (connectedPhone) {
      setResult({
        isResult: true,
        isCorrect: true,
      });
      setIsLoading(false);
    }
  }, [connectedPhone]);

  /** Загрузка привязки номера */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Привязка номера */
  const bindNumber = async () => {
    setIsLoading(true);
    try {
      const res = await bindPhone(satelliteCode);
      getPhones();
      setPhoneId(res);
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resultJSX = () => {
    if (!result.isResult) return <></>;
    if (result.isCorrect) {
      return (
        <StyledResult>
          <Icons.OkBigIcon />
          <H1>Номер {maskHomePhone(connectedPhone)} будет подключён</H1>
          <div className="result__time">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Время подключения
            </Text>
            <H3>В течение 15 минут</H3>
          </div>
          <div className="result__yellow-block">
            <Text lineHeight="24px">
              Для совершения звонков используйте рекомендованные приложения и
              оборудование.
            </Text>
            <Text lineHeight="24px">
              В целях безопасности, для каждого подключённого телефонного номера
              мы рекомендуем использовать различные пароли.
            </Text>
          </div>
          <Button onClick={createPassword}>Создать пароль для номера</Button>
        </StyledResult>
      );
    }
    return (
      <StyledResult>
        <Icons.NotOkBigIcon />
        <H1>{result.text ?? DEFAULT_ERROR}</H1>
      </StyledResult>
    );
  };

  const zeroing = () => {
    setIsShowConnectNumber(false);
    setResult(DEFAULT_RESULT);
    setPhoneId(null);
  };

  const phoneCode = useMemo(() => {
    switch (Number(citySelect?.value)) {
      case SatelliteCodes.EKB:
        setSatelliteCode(SatelliteCodes.EKB);
        return '343';
      case SatelliteCodes.TAGIL:
        setSatelliteCode(SatelliteCodes.TAGIL);
        return '3435';
      case SatelliteCodes.KAMENSK:
        setSatelliteCode(SatelliteCodes.KAMENSK);
        return '3439';
      default:
        return '';
    }
  }, [citySelect]);

  /** Создать пароль */
  const createPassword = () => {
    zeroing();
    setIsShowPassword(true);
    setIsCreatePassword(true);
    setPhonePassword(connectedPhone);
  };

  /** Вид блока с select в мобильной версии */
  const [isMobileSelectOpen, setIsMobileSelectOpen] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый select начала периода в мобильной версии
  const [candidateForSelect, setCandidateForSelect] = useState<OptionProp>(
    null,
  );

  const applyMobileSelect = () => {
    setCitySelect(candidateForSelect);
    getPhoneData(candidateForSelect.value);
    setIsShowConnectNumber(true);
    setIsMobileSelectOpen(false);
  };

  /** Мобильный select */
  const mobileSelect = () => {
    return (
      <StyledConnectNumber>
        <div className="connect__mobile-select">
          <div className="connect__mobile-select__header">
            <LeadingText color={defaultTheme.colors.black}>Номера</LeadingText>
            <Icon
              icon={<Icons.CloseIcon />}
              onClick={() => {
                setIsMobileSelectOpen(!isMobileSelectOpen);
                setCandidateForSelect(citySelect);
              }}
              highlight
            />
          </div>
          {selectCities.map((item) => {
            return (
              <TagButton
                key={item.label}
                className="connect__mobile-select__tag-button"
                onChange={() => setCandidateForSelect(item)}
                checked={item.value === candidateForSelect.value}
              >
                {item.label}
              </TagButton>
            );
          })}
          <Button onClick={applyMobileSelect}>Применить</Button>
        </div>
      </StyledConnectNumber>
    );
  };

  const content = () => {
    if (isMobileSelectOpen && !isDesktop940) return mobileSelect();
    if (!result.isResult)
      return (
        <>
          <div className="connect__city-block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Город
            </Text>
            <div
              onClick={() => {
                if (!isDesktop940) {
                  setIsMobileSelectOpen(true);
                }
              }}
            >
              <Select
                width="330px"
                onOptionClick={(option) => {
                  getPhoneData(option.value);
                  setIsShowConnectNumber(true);
                  setCitySelect(option);
                }}
                value={citySelect?.value}
                outsideClickHelper={OutsideClickHelper()}
                data={selectCities}
              />
            </div>
          </div>
          <div className="connect__yellow-block">
            <Text lineHeight="24px">
              Вам будет предоставлен прямой городской номер в формате (
              {phoneCode}) XXX-XX-XX
            </Text>
          </div>
          <StyledPrices>
            <div className="price">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Получение местного номера
              </Text>
              <H3>{formatNumber(rentalPrice)} ₽</H3>
            </div>
            <div className="price">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Минимальный срок подключения
              </Text>
              <H3>{minRentalTime} дней</H3>
            </div>
            <div className="price">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Плата за использование номера
              </Text>
              <div className="price__tabs">
                <Tabs
                  value={['день', '30 дней']}
                  onChange={setPriceIndex}
                  activeTabIndex={priceIndex}
                  styleType={TabsStyleTypes.SECONDARY}
                />
                <H3>{pricesForMaintain[priceIndex]} ₽</H3>
              </div>
            </div>
          </StyledPrices>
          <div className="connect__agreement">
            <Text lineHeight="24px" fontWeightBold>
              Согласие на получение номера
            </Text>
            {isDesktop940 ? (
              <Text lineHeight="24px">{parseHtml(agreement)}</Text>
            ) : (
              <Snoska>{parseHtml(agreement)}</Snoska>
            )}
          </div>
        </>
      );
    return resultJSX();
  };

  return (
    <StyledConnectNumber>
      <SidePage
        show={isShowConnectNumber}
        width="832px"
        headerText={!result.isResult && 'Подключить номер'}
        onCloseClick={zeroing}
        footerContainer={
          !result.isResult &&
          !(isMobileSelectOpen && !isDesktop940) && (
            <ConnectNumberFooter bind={bindNumber} isLoading={isLoading} />
          )
        }
        removeScrollBar
      >
        {content()}
      </SidePage>
    </StyledConnectNumber>
  );
};

export default observer(ConnectNumberSidePage);
