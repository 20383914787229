/** табы цен */
export enum TABS {
  DAY = 'в день',
  MONTH = 'в месяц',
}

/** Ключи ОС пользователя */
export enum PLATFORM {
  WIN32 = 'Win32',
  MAC_INTEL = 'MacIntel',
  LINUX32 = 'Linux i686',
  LINUX64 = 'Linux x86_64',
}

/** Ключи ОС из метода */
export enum OS_KEYS {
  MACLINK = 'MACLINK',
  LINLINK64 = 'LINLINK64',
  LINLINK32 = 'LINLINK32',
  ANDLINK = 'ANDLINK',
  WINLINK32 = 'WINLINK32',
  WINLINK64 = 'WINLINK64',
  IOSLINK = 'IOSLINK',
}

export const INSTRUCTION = 'инструкция по установке';

/** Ключи доп. информации */
export enum INFO_KEYS {
  KEY = 'Key',
  LOGIN = 'Login',
  PASS = 'Pass',
}
