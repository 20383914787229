import styled from '@emotion/styled';

export const StyledCitySelector = styled.div`
  padding: 30px 0;
`;

/** стилизованный список городов */
export const StyledCityList = styled.ul`
  list-style-type: none;
  li + li {
    margin-top: 18px;
  }
`;
