import styled from '@emotion/styled';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledDeviceNotification = styled.div`
  .devices {
    display: flex;
    flex-wrap: wrap;

    &__info {
      margin-top: 16px;
      max-width: 527px;
      letter-spacing: -0.01em;
    }
  }

  .device {
    display: flex;
    flex-direction: column;
    position: relative;
    width: auto;
    margin-right: 50px;
    height: 288px;
    margin-top: 32px;

    &__item-image-wrapper {
      height: 120px;
      width: 170px;
      margin-bottom: 8px;

      > div {
        height: 120px;
        width: 170px;
      }
    }

    &__name {
      max-width: 400px;
      margin-right: 32px;
    }

    &__name:nth-of-type(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      margin: 8px 0 0;
    }

    &__button {
      position: absolute;
      bottom: 0;
      margin-top: 16px;
      width: fit-content;
    }
  }

  .device:last-of-type {
    margin-right: 0;
  }

  @media (min-width: ${desktop940}px) {
    .devices {
      flex-wrap: nowrap;
    }
    .device {
      margin-right: 0;
      width: 280px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    .device {
      width: 320px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    .device {
      width: 400px;
    }
  }
`;

export const StyledErrorSP = styled.div`
  svg {
    margin-bottom: 32px;
  }
`;
