/** Библиотеки */
import { FC } from 'react';

/** Компоненты */
import {
  Button,
  ButtonStyleTypes,
  Text,
  Link as CordisLink,
} from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledContacts, StyledContactsList } from './Contacts.style';

/** интерфейсы */
import { ContactPhone } from '~/interfaces/ContactPhone.interface';

/** stores */
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';
import { observer } from 'mobx-react';

/* props компонента */
interface ContactsProps {
  contacts: ContactPhone[];
}

/**
 * Блок с контактными данными
 * @returns {React.FC<ContactsProps>}
 */
const Contacts: FC<ContactsProps> = ({ contacts }: ContactsProps) => {
  const { toggleCallbackWizardVisible } = useCallbackStore();

  /* форматирует номер телефона */
  const getPhoneLink = (phone: string): string => phone.replace(/\D/g, '');

  /* представление номера телефона */
  const PhoneNumber = (contactPhone: ContactPhone): JSX.Element => (
    <CordisLink href={`tel:${getPhoneLink(contactPhone.phone)}`}>
      <Text fontWeightBold>{contactPhone.phone}</Text>
    </CordisLink>
  );

  return (
    <StyledContacts>
      <Text className="contacts__title">Подключение и консультации:</Text>
      <StyledContactsList>
        {contacts
          .filter((contactPhone) => contactPhone.type === 'manager')
          .map((item) => (
            <li key={item.id} data-test-li="">
              {PhoneNumber(item)}
            </li>
          ))}
      </StyledContactsList>
      <Text className="contacts__title">Техподдержка</Text>
      <StyledContactsList>
        {contacts
          .filter((contactPhone) => contactPhone.type === 'support')
          .map((item) => (
            <li key={item.id} data-test-li="">
              {PhoneNumber(item)}
            </li>
          ))}
      </StyledContactsList>
      <Button
        minWidth="184px"
        styleType={ButtonStyleTypes.SECONDARY}
        onClick={toggleCallbackWizardVisible}
      >
        Заказать звонок
      </Button>
    </StyledContacts>
  );
};

export default observer(Contacts);
