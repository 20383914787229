/** libraries */
import { flow, types } from 'mobx-state-tree';
/** constants */
import { DEFAULT_ERROR } from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { PASSWORD_ERRORS } from '../SidePages/ChangePassword/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
import { DEFAULT_RESULT } from '~/constants/common';
/** api */
import { changePinCode } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';

const ResultPropsModel = types.model({
  isResult: types.boolean,
  isCorrect: types.boolean,
});

const RequestsStateModel = types.model('State', {
  changePinCode: createApiPathModel('GET /Security/PasswordRecovery/ChangePin'),
});

export const PinModel = types
  .model('Pin', {
    requestsState: RequestsStateModel,
    /** Показать СП смены ПИН-кода */
    isShowPinWizard: types.boolean,
    /** Пароль */
    password: types.string,
    passwordError: types.string,
    /** Пин-код */
    code: types.string,
    codeError: types.string,
    /** Результат смены ПИН-кода */
    result: ResultPropsModel,
  })
  .views((self) => ({
    /** Проверка на условия отключения кнопки смены ПИН-кода */
    get isDisableButton() {
      if (self.code.length !== 5 || !self.password.length) return true;
      return false;
    },
  }))
  .actions((self) => ({
    setIsShowPinWizard: (isShow: boolean) => {
      self.isShowPinWizard = isShow;
    },
    setPassword: (password: string) => {
      self.password = password;
    },
    setPasswordError: (error: string) => {
      self.passwordError = error;
    },
    setCode: (code: string) => {
      self.code = code;
    },
    setCodeError: (error: string) => {
      self.codeError = error;
    },
    changePinCode: flow(function* (setOpenSPAfterAuthorizationState) {
      self.requestsState.changePinCode.reset();
      self.requestsState.changePinCode.setLoading();
      try {
        yield changePinCode(self.code, self.password);
        self.result = { isResult: true, isCorrect: true };
        self.requestsState.changePinCode.setSuccess();
        setOpenSPAfterAuthorizationState(null);
      } catch (e) {
        console.error(e, 'changePinCode');
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(SIDE_PAGES.CHANGE_PIN_ACTION);
          return;
        }
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.changePinCode.setFail();
        const error = JSON.parse(e.errorMessage);
        switch (error?.Type) {
          case PASSWORD_ERRORS.AUTH_COUNT:
            self.passwordError = `Неправильный пароль. По истечении попыток ввода пароля доступ к договору будет временно ограничен. Осталось попыток: ${error?.Extensions?.Remains}`;
            break;
          case PASSWORD_ERRORS.BLOCK:
            self.passwordError = `Доступ к договору ${error?.Extensions?.ContractName} заблокирован на ${error?.Extensions?.BlockMinutes} минут`;
            break;
          case PASSWORD_ERRORS.CONTRACT_NOT_SERVICED:
            self.passwordError = 'Договор не обслуживается';
            break;
          default:
            self.passwordError = DEFAULT_ERROR;
        }
      }
    }),
    restoreState: () => {
      self.isShowPinWizard = false;
      self.code = '';
      self.codeError = '';
      self.password = '';
      self.passwordError = '';
      self.result = DEFAULT_RESULT;
    },
  }));

export const PinStoreInstance = PinModel.create({
  requestsState: {
    changePinCode: defaultModelState,
  },
  isShowPinWizard: false,
  password: '',
  passwordError: '',
  code: '',
  codeError: '',
  result: DEFAULT_RESULT,
});
