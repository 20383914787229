/** api */
import { getSummaryBySeriesCode } from '~/api/api';
import { getContacts } from '~/api/apiPab2c';
/** типы */
import { Block } from '~/interfaces/BlockInterface';
import { ProductSwitcherProps, ProductChangeErrors } from './interfaces';
import { SummaryFields } from '~/components/Blocks/Templates/Summary/Summary.types';
/** утилиты */
import { maskPhone } from '~/utils/utils';
import { getDefaultDeviceImgs } from '../../Shared/Shared.utils';
/** Константы */
import { EMAIL_CLASS_ID } from '~/components/Blocks/Shared/ContactsAndNotifications/constants';
import { PRODUCT_CHANGE_ERRORS } from './constants';

/**
 * возвращает блок ProductSwitcher, дополненный полем с информацией о продуктах,
 * для отрисовки блока в неавторизованном состоянии
 * @param {Block} block
 */
export const getDataForProductSwitcher = async (
  block: Block,
): Promise<ProductSwitcherProps> => {
  const unionTariffNotAuth = await Promise.all(
    block.fields.products.map(
      async (item): Promise<SummaryFields> => {
        try {
          const extendedSummary = await getSummaryBySeriesCode(
            item.seriesCode,
            item?.promoCodes,
          );
          return {
            ...item,
            ...(extendedSummary ?? null),
          };
        } catch (e) {
          return { ...item };
        }
      },
    ),
  );

  const defaultImgs = await getDefaultDeviceImgs();

  const fields = { ...block.fields, unionTariffNotAuth };
  return {
    ...block,
    fields,
    defaultImgs,
  };
};

/** Формирование текстов ошибокок смены продукта */
export const getProductChangeErrorTexts = async (
  contractName: string,
  currentTariff: string,
  newTarif: string,
  message?: string,
): Promise<ProductChangeErrors> => {
  let onlyPhones;
  try {
    const res = await getContacts();
    onlyPhones = res
      .reduce((acc, contact) => {
        if (contact.contactValueClassId !== EMAIL_CLASS_ID) {
          acc.push(maskPhone(contact.value));
        }
        return acc;
      }, [])
      .join(', ');
  } catch (e) {
    console.error('getContacts', e);
  }

  const errorTexts = {
    [PRODUCT_CHANGE_ERRORS.NOT_ENOUGH_MONEY]:
      '<p>На вашем Лицевом Счете недостаточно средств для изменения Продукта.</p>',
    [PRODUCT_CHANGE_ERRORS.ALREADY_EXIST]: `<p>Ваша заявка на&nbsp;смену Продукта ещё находится в&nbsp;работе. Для&nbsp;согласования дальнейших действий наши специалисты будут связываться с&nbsp;вами по&nbsp;телефонам, указанным в&nbsp;договоре&nbsp;${contractName}:<br><strong>${
      onlyPhones ? `${onlyPhones}` : 'телефоны не зарегистрированы'
    }</strong>.</p><p>Внимание! Если в&nbsp;контактных данных есть неточности, пожалуйста, обязательно <a target='_blank' class='external-link' href='/contacts' rel='nofollow'>свяжитесь с&nbsp;нами</a> в&nbsp;рабочее время.</p>`,
    [PRODUCT_CHANGE_ERRORS.ERROR_FTTH]:
      'Невозможен переход на оптический продукт.',
    [PRODUCT_CHANGE_ERRORS.TARIFF_SWITCHED_TODAY]:
      '<p><strong>Внимание! Заявка не может быть принята.</strong></p><p>В&nbsp;соответствии с&nbsp;<a target="_blank" class="external-link" href="https://planeta.tc/static/upload-files/documents/topicals/ktc.reglament.pdf" rel="nofollow">Регламентом</a> заявка на&nbsp;смену Продукта принимается не более 1&nbsp;раза в&nbsp;сутки. Вы сможете направить новую заявку завтра.</p>',
    [PRODUCT_CHANGE_ERRORS.CONTRACT_NOT_SERVICED]: 'Договор не обслуживается',
    [PRODUCT_CHANGE_ERRORS.TARIFF_SWITCH_DENY_FOR_CLIENT]:
      'Переход на продукт невозможен',
    [PRODUCT_CHANGE_ERRORS.ERROR_MAINTENANCE]:
      '<p><strong>Смена продукта временно недоступна.</strong></p><p>Возможность смены продукта недоступна на время проведения технических работ. Повторите попытку позднее.</p><p>Остальные функции раздела «Моя Планета» доступны в полном объёме.</p>',
    [PRODUCT_CHANGE_ERRORS.RETURN_STB]: message,
    [PRODUCT_CHANGE_ERRORS.FROM_PROMO_TO_DEFAULT]: `<p>Вы будете автоматически переведены на продукт ${newTarif} по завершению срока действия продукта ${currentTariff}.</p>`,
  };

  return errorTexts;
};
