import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledAnnualProductRenewalWizard = styled.div`
  .renewal {
    &__price-tag {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;

      .colorContainer {
        flex-direction: column;
        align-items: start;
        > span {
          max-width: auto;
        }
      }

      button {
        width: 100%;
        height: 54px;
        margin: 16px 0 0 0;
      }
    }

    &__term {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }

    &__warning-block {
      padding: 16px;
      background-color: ${defaultTheme.colors.backgroundWarning};
      margin-bottom: 32px;
    }
  }

  .result-icon {
    margin-bottom: 16px;
  }

  @media (min-width: ${desktop940}px) {
    .renewal {
      &__price-tag {
        flex-direction: row;
        align-items: flex-end;
        .colorContainer {
          flex-direction: row;
          align-items: center;
          width: fit-content;
          > span {
            max-width: 111px;
          }
        }

        button {
          width: auto;
          margin: 0 0 0 32px;
        }
      }
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
