/** libraries */
import { FC } from 'react';
import {
  H3,
  LeadingText,
  Loader,
  defaultTheme,
  Text,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** components */
import Television from './Components/Television/Television';
import MyTVx2 from './Components/MyTVx2/MyTVx2';
import MyTVx3 from './Components/MyTVx3/MyTVx3';

/** interfaces */
import { MyTVProps } from './interfaces';

/** styles */
import { StyledLoader } from './styles';
import { StyledMyTVx2 } from './Components/MyTVx2/styles';

/** constants */
import { OPERATING_STATE } from '~/constants/common';
import { STORE_TYPE } from './constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { MyTvProvider } from './stores/useMyTvStore';

/**
 * Блок «ЛК. Моё телевидение»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=690527702
 */
const MyTV: FC<MyTVProps> = ({ content }: MyTVProps): JSX.Element => {
  const {
    authStore: { isLoadingAuth, isAuth },
    contractStateStore: { contractState, isLoading },
    summaryDataStore: { seriesCode },
    tvStore: { isLoading: isLoadingTv },
  } = useRootStore();

  /** Тип блока для передачи в провайдер */
  let MainTvComponent: FC;
  /** Тип используемого стора */
  let storeType: STORE_TYPE;

  if (
    (content.fields?.seriesCode && content.fields?.seriesCode !== seriesCode) ||
    !isAuth
  ) {
    /** Блок Телевидение 2.0 */
    storeType = STORE_TYPE.TELEVISION;
    MainTvComponent = Television;
  } else if (content.size === 2) {
    /** Блок Моё ТВ x2 */
    storeType = STORE_TYPE.MY_TV_X2;
    MainTvComponent = [OPERATING_STATE.STRAY, OPERATING_STATE.DREGS].includes(
      contractState,
    )
      ? () => (
          <StyledMyTVx2>
            <div>
              <H3 className="my-tv__header">Моё ТВ</H3>
              <div className="my-tv__not-available">
                <LeadingText color={defaultTheme.colors.black}>
                  Недоступно на вашем договоре
                </LeadingText>
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Чтобы воспользоваться сервисом, возобновите обслуживание
                </Text>
              </div>
            </div>
          </StyledMyTVx2>
        )
      : MyTVx2;
  } else {
    /** Блок Моё ТВ x3 */
    storeType = STORE_TYPE.MY_TV_X3;
    MainTvComponent = MyTVx3;
  }

  if (isLoadingAuth || isLoading || isLoadingTv) {
    return (
      <StyledLoader>
        <H3>Моё ТВ</H3>
        <Loader />
      </StyledLoader>
    );
  }

  return (
    <MyTvProvider fields={content.fields} storeType={storeType}>
      <MainTvComponent />
    </MyTvProvider>
  );
};

export default observer(MyTV);
