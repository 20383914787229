/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Textarea,
  Text,
  Snoska,
  defaultTheme,
  Select,
  OptionProp,
  LeadingText,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** interfaces */
import {
  APPEAL_STATUS,
  AppealData,
  MY_APPEALS_STATE,
} from '../Blocks/Templates/Pab2c/Help/interfaces';
/** styles */
import { StyledFooter, StyledSnoska } from './styles';
/** api */
import { createDemandComment, getDemandList } from '~/api/apiPab2c';
/** constants */
import { desktop500, desktop940 } from '~/components/Grid/constants';
import { WORLFLOW_NAMES } from './constants';
/** stores */
import useMyAppealsStore from './store/useMyAppealsStore';

interface MyAppealsFooterProps {
  state: MY_APPEALS_STATE;
  openSPDemand: () => void;
  appeal: AppealData;
  getCommentList: () => Promise<void>;
  setAppealsList: Dispatch<SetStateAction<AppealData[]>>;
  createApply: () => Promise<void>;
  isLoadingCreateApply: boolean;
  isTemporaryTokenAuth: boolean;
}

const MyAppealsFooter: FC<MyAppealsFooterProps> = ({
  state,
  openSPDemand,
  appeal,
  getCommentList,
  setAppealsList,
  createApply,
  isLoadingCreateApply,
  isTemporaryTokenAuth,
}: MyAppealsFooterProps) => {
  const { typeOfApplication, setTypeOfApplication } = useMyAppealsStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /** Значение textarea */
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  /** Обработчик textarea */
  const handleChangeTextarea = (e) => {
    setTextareaValue(e.target.value);
  };

  /** Отправляет комментарий */
  const sendComment = async () => {
    setIsLoading(true);
    setError('');
    if (/^[\s\n]*$/.test(textareaValue)) {
      setError('Укажите текст комментария');
      setIsLoading(false);
      return;
    }
    try {
      await createDemandComment(appeal.id, textareaValue.trim());
      getCommentList();
      setTextareaValue('');
      if (
        [APPEAL_STATUS.CLOSED, APPEAL_STATUS.REJECT].includes(appeal.stateCode)
      ) {
        const list = await getDemandList();
        setAppealsList(list);
      }
    } catch (e) {
      console.error('createDemandComment', e);
      setError('Ошибка создания комментария');
    } finally {
      setIsLoading(false);
    }
  };

  /** Комментирование не доступно */
  const isNotAvailableCommenting = appeal?.isClosed;

  // Вычисление ширины экрана
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const typeOfApplicationData = useMemo(() => {
    return Object.values(WORLFLOW_NAMES).map((item, key) => {
      return {
        label: item,
        value: key,
      };
    });
  }, []);

  // Выбранный счёт в select
  const [
    typeOfApplicationSelect,
    setTypeOfApplicationSelect,
  ] = useState<OptionProp>({
    label: '',
    value: '',
  });

  useEffect(() => {
    if (typeOfApplicationData.length) {
      setTypeOfApplicationSelect(
        typeOfApplication
          ? typeOfApplicationData.find(
              (item) => item.label === typeOfApplication,
            )
          : typeOfApplicationData[0],
      );
    }
  }, [typeOfApplicationData]);

  useEffect(() => {
    setTypeOfApplication(typeOfApplicationSelect.label);
  }, [typeOfApplicationSelect]);

  const selectWidth: string = useMemo(() => {
    if (isDesktop940) {
      return '310px';
    }
    if (!isDesktop940 && isDesktop500) {
      return '300px';
    }
    return '100%';
  }, [isDesktop500, isDesktop940]);

  const snoskaText = () => {
    switch (typeOfApplicationSelect.label) {
      case WORLFLOW_NAMES.CHANGE_THE_CONTRACT:
        return (
          <Snoska className="new-appeal__snoska">
            Используйте для подачи заявки для переоформления договора
            на&nbsp;другого клиента. Мы&nbsp;свяжемся с&nbsp;вами, чтобы
            уточнить детали.
          </Snoska>
        );
      case WORLFLOW_NAMES.MALFUNCTION:
        return (
          <Snoska className="new-appeal__snoska">
            Используйте в&nbsp;случае неисправности и&nbsp;для решения других
            технических вопросов.
          </Snoska>
        );
      case WORLFLOW_NAMES.ORDER_A_CONSULTATION:
        return (
          <Snoska className="new-appeal__snoska">
            Используйте, чтобы получить консультацию о&nbsp;списаниях, продукте,
            порядке обслуживания.
          </Snoska>
        );
      default:
        return <></>;
    }
  };

  const infoText = () => {
    switch (typeOfApplicationSelect.label) {
      case WORLFLOW_NAMES.CHANGE_THE_CONTRACT:
        return (
          <Text lineHeight="24px" className="new-appeal__text">
            Используйте для подачи заявки для переоформления договора
            на&nbsp;другого клиента. Мы&nbsp;свяжемся с&nbsp;вами, чтобы
            уточнить детали.
          </Text>
        );
      case WORLFLOW_NAMES.MALFUNCTION:
        return (
          <Text lineHeight="24px" className="new-appeal__text">
            Используйте в&nbsp;случае неисправности и&nbsp;для решения других
            технических вопросов.
          </Text>
        );
      case WORLFLOW_NAMES.ORDER_A_CONSULTATION:
        return (
          <Text lineHeight="24px" className="new-appeal__text">
            Используйте, чтобы получить консультацию о&nbsp;списаниях, продукте,
            порядке обслуживания.
          </Text>
        );
      default:
        return <></>;
    }
  };

  return (
    <StyledFooter>
      {state === MY_APPEALS_STATE.MAIN && (
        <div className="new-appeal">
          {isDesktop940 && (
            <LeadingText
              className="new-appeal__header"
              color={defaultTheme.colors.black}
            >
              Новое обращение
            </LeadingText>
          )}
          <div className="new-appeal__select">
            <Select
              onOptionClick={(option) => {
                setTypeOfApplicationSelect(option);
              }}
              value={
                typeOfApplicationSelect ? typeOfApplicationSelect.value : ''
              }
              data={typeOfApplicationData}
              width={selectWidth}
            />
            <Button onClick={openSPDemand}>
              {isDesktop940 ? 'Создать' : 'Создать обращение'}
            </Button>
          </div>
          {isDesktop940 ? infoText() : snoskaText()}
        </div>
      )}
      {state === MY_APPEALS_STATE.APPEAL && !isNotAvailableCommenting && (
        <div>
          <div className="textarea">
            <Text lineHeight="24px">Комментировать</Text>
            <Textarea
              onChange={(e) => handleChangeTextarea(e)}
              value={textareaValue}
              maxLength={400}
              resize="none"
              error={error}
            />
          </div>
          <StyledSnoska>
            <Button
              onClick={sendComment}
              loading={isLoading}
              disabled={isTemporaryTokenAuth}
            >
              Отправить
            </Button>
            {isTemporaryTokenAuth && (
              <Snoska className="snoska" color={defaultTheme.colors.gray}>
                Действие доступно только клиенту
              </Snoska>
            )}
          </StyledSnoska>
        </div>
      )}
      {[
        MY_APPEALS_STATE.CREATE_NEW_APPEAL,
        MY_APPEALS_STATE.CREATE_NEW_APPEAL_CHANGE_CONTRACT,
      ].includes(state) && (
        <StyledSnoska>
          <Button
            onClick={createApply}
            loading={isLoadingCreateApply}
            disabled={isTemporaryTokenAuth}
          >
            Отправить
          </Button>
          {isTemporaryTokenAuth && (
            <Snoska className="snoska" color={defaultTheme.colors.gray}>
              Действие доступно только клиенту
            </Snoska>
          )}
        </StyledSnoska>
      )}
    </StyledFooter>
  );
};

export default observer(MyAppealsFooter);
