import styled from '@emotion/styled';

/** Константы */
import { minWidth } from '~/components/Grid/constants';

/** константы */
import { desktop375 } from '~/components/Grid/constants';

export const StyledCallbackWizard = styled.div`
  .callbackWizard {
    &__label,
    &__input {
      margin-bottom: 8px;
    }

    &__input {
      width: 295px;
    }
  }

  @media (max-width: ${desktop375}px) {
    .callbackWizard {
      &__input {
        width: 100%;
        min-width: ${minWidth}px;
      }
    }
  }
`;
