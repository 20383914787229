/** библиотеки */
import { FC } from 'react';
import styled from '@emotion/styled';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ERROR_MESSAGE } from '~/components/AuthWizard/constants';
/** Компоненты библиотеки */
import {
  H2,
  H3,
  Icons,
  LeadingText,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledBlockedContract } from './styles';
/** constants */
import { desktop940, desktop1280 } from '~/components/Grid/constants';

interface BlockedContractProps {
  contractName: string;
  blockMinutes: string;
}

const StyledLeadingText = styled(LeadingText)`
  display: block;
`;

/**
 * Сайдпейдж блокировки «Моей Планеты»
 * отображается при попытке создания пароля для заблокированного договора
 */
const BlockedContract: FC<BlockedContractProps> = ({
  contractName,
  blockMinutes,
}: BlockedContractProps) => {
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  return (
    <StyledBlockedContract>
      <Icons.NotOkBigIcon />
      {!isDesktop940 && (
        <StyledLeadingText color={defaultTheme.colors.totalBlack}>
          {ERROR_MESSAGE.BlockException}
        </StyledLeadingText>
      )}
      {isDesktop940 && !isDesktop1280 && (
        <H3>{ERROR_MESSAGE.BlockException}</H3>
      )}
      {isDesktop1280 && <H2>{ERROR_MESSAGE.BlockException}</H2>}

      <Text
        className="yellow-block"
        lineHeight="24px"
        color={defaultTheme.colors.totalBlack}
      >
        Доступ к договору{' '}
        <Text lineHeight="24px" fontWeightBold>
          №{contractName}
        </Text>{' '}
        заблокирован на {blockMinutes} минут
      </Text>
    </StyledBlockedContract>
  );
};

export default BlockedContract;
