/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop320,
  desktop380,
  desktop530,
  desktop650,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

import { IMAGES } from '../constants';

export const StyledUltraHD = styled.div`
  min-height: 400px;
  padding: 32px;
  padding-top: 28px;
  background: ${defaultTheme.colors.stateGray} no-repeat;
  background-image: url(${IMAGES.fashion280});
  background-position: right bottom;

  h3 {
    font-weight: 700;
  }

  .title__240 {
    margin-bottom: 14px;
  }

  .title {
    margin-bottom: 12px;
  }

  .text__240 {
    max-width: 176px;
  }

  .title__wide {
    margin-bottom: 74px;
    max-width: 458px;
  }

  .text__wide {
    max-width: 304px;
  }

  .title,
  .title__wide,
  .text,
  .text__340,
  .text__wide {
    display: none;
  }

  @media (min-width: ${desktop320}px) {
    .title__240,
    .text__240 {
      display: none;
    }

    .title {
      display: block;
    }

    .text {
      display: inline-block;
      max-width: 218px;
    }
  }

  @media (min-width: ${desktop380}px) {
    background-image: url(${IMAGES.fashion355});
    background-position: bottom right;
    background-size: 70%;
  }

  @media (min-width: ${desktop530}px) {
    background-size: auto;
  }

  @media (min-width: ${desktop650}px) {
    .title,
    .text {
      display: none;
    }

    .title__wide {
      display: block;
    }

    .text__wide {
      display: inline-block;
    }

    background-image: url(${IMAGES.fashion355});
    background-position: right;
  }

  @media (min-width: ${desktop940}px) {
    .title__wide,
    .text__wide {
      display: none;
    }

    .title__240 {
      display: block;
      margin-bottom: 12px;
    }

    .text {
      display: inline-block;
    }

    background-image: url(${IMAGES.fashion280});
    background-position: right bottom;
  }

  @media (min-width: ${desktop1100}px) {
    .title__240,
    .text {
      display: none;
    }

    .title {
      display: block;
      margin-bottom: 16px;
    }

    .text__340 {
      display: block;
    }

    background-image: url(${IMAGES.fashion});
    background-position: bottom right -60px;
  }

  @media (min-width: ${desktop1280}px) {
    background-position: bottom right;
  }
`;
