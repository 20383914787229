/** libraries */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  defaultTheme,
  H3,
  Icon,
  Icons,
  LeadingText,
  OptionProp,
  Select,
  TabIconPosition,
  Tabs,
  TagButton,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { channelFormats } from '../constants';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMyTVX3Store from '../stores/MyTvX3Store';

/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';

/** components */
import ChannelsTab from './Tabs/ChannelsTab';
import ConnectedTab from './Tabs/ConnectedTab';
import PackagesTab from './Tabs/PackagesTab';
import ServicesTab from './Tabs/ServicesTab';
import TransformerTab from './Tabs/TransformerTab';
import Search from './Search/Search';

/** interfaces */
import { PictureClarityFormat } from './Cards/ChannelCard/interfaces';
import {
  ChannelPackage,
  ServiceInfoProps,
  SubjectWithChannels,
} from '../interfaces';

/** props */
interface MainPageProps {
  /** Клик по карточке сервиса */
  onClickServiceCard: (service: ServiceInfoProps) => void;
  /** Сервисы */
  serviceInfo: ServiceInfoProps[];
}

/** Главная страница с поиском */
const MainPage: FC<MainPageProps> = ({
  onClickServiceCard,
  serviceInfo,
}: MainPageProps) => {
  const {
    tvStore: {
      channels,
      tvChannelPackages,
      transformerInfo,
      idsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
    },
  } = useRootStore();
  const {
    searchMode,
    setSearchMode,
    activeTabIndex,
    setActiveTabIndex,
    isEditTransformer,
  } = useMyTVX3Store();

  /** Флаг мобильного фильтра по формату канала */
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState<boolean>(
    false,
  );

  /** Выбираемый, но не подтверждённый фильтр формата каналов в мобильной версии */
  const [
    candidateForActiveChannelFormat,
    setCandidateForActiveChannelFormat,
  ] = useState<OptionProp>(null);

  /** Отфильтрованный по формату список каналов */
  const [formatFilteredChannelList, setFormatFilteredChannelList] = useState<
    SubjectWithChannels[]
  >([]);

  /** Активный фильтр формата каналов */
  const [activeChannelFormat, setActiveChannelFormat] = useState<OptionProp>(
    channelFormats[0],
  );

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Количество каналов в пакетах */
  const numberOfConnectedChannelsInPackages = useMemo(() => {
    const packets = tvChannelPackages.filter((item) =>
      idsAddedPacketsToTransformer.includes(item.channelPackId ?? item.id),
    );
    return packets.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      const ids = item?.channelIds ?? item?.channelPackChannels;
      ids.forEach((id) => {
        const singleId = id?.single_channel_packs?.length
          ? id?.single_channel_packs[0].id
          : null;
        if (
          !acc.includes(id) &&
          !idsAddedChannelsToTransformer.includes(singleId)
        )
          acc.push(id);
      });
      return acc;
    }, []).length;
  }, [idsAddedPacketsToTransformer, idsAddedChannelsToTransformer]);

  /** Табы */
  const tabs = [
    {
      value:
        isDesktop940 &&
        (transformerInfo
          ? `Трансформер${'\u2007'}${
              numberOfConnectedChannelsInPackages +
              idsAddedChannelsToTransformer.length
            }`
          : 'Подключённые'),
      icon:
        transformerInfo && isEditTransformer ? (
          <Icon
            icon={<Icons.EditIcon />}
            iconColor={defaultTheme.colors.yellow}
          />
        ) : (
          !isDesktop940 && <Icons.ConnectedIcon white />
        ),
      inactiveIcon:
        transformerInfo && isEditTransformer ? (
          <Icons.EditIcon />
        ) : (
          !isDesktop940 && <Icons.ConnectedIcon />
        ),
      iconPosition:
        transformerInfo && isEditTransformer && TabIconPosition.RIGHT,
    },
    {
      value: isDesktop940 && 'Пакеты',
      icon: !isDesktop940 && <Icons.PackagesIcon white />,
      inactiveIcon: !isDesktop940 && <Icons.PackagesIcon />,
    },
    {
      value: isDesktop940 && 'Каналы',
      icon: !isDesktop940 && <Icons.ChannelsIcon white />,
      inactiveIcon: !isDesktop940 && <Icons.ChannelsIcon />,
    },
    {
      value: isDesktop940 && 'Сервисы',
      icon: !isDesktop940 && <Icons.ServicesIcon white />,
      inactiveIcon: !isDesktop940 && <Icons.ServicesIcon />,
    },
  ];

  /* Возвращает компонент при переключении табов */
  const getTabContent = (tabIndex: number): JSX.Element => {
    switch (tabIndex) {
      case 0:
        return transformerInfo ? <TransformerTab /> : <ConnectedTab />;
      case 1:
        return (
          <PackagesTab
            tvChannelPackages={
              (tvChannelPackages as unknown) as ChannelPackage[]
            }
          />
        );
      case 2:
        return (
          <ChannelsTab formatFilteredChannelList={formatFilteredChannelList} />
        );
      case 3:
        return (
          <ServicesTab
            serviceInfo={serviceInfo}
            onClickServiceCard={onClickServiceCard}
          />
        );
      default:
        return <></>;
    }
  };

  /** Обрабатывает клик по кнопке "Показать"  */
  const handleTagButtonClick = () => {
    setActiveChannelFormat(candidateForActiveChannelFormat);
    setIsMobileFiltersOpen(false);
  };

  /** Обрабатывает клик по тегу в мобильном фильтре по формату */
  const handleTagClick = (item: OptionProp) => {
    if (!isDesktop940) {
      setCandidateForActiveChannelFormat(item);
      return;
    }
    setActiveChannelFormat(item);
  };

  /** Фильтрует список каналов по формату */
  const filtersListOfChannelsByFormat = () => {
    switch (activeChannelFormat) {
      case channelFormats[1]:
        const filteredHDChannels = channels.map((category) => {
          return {
            ...category,
            channels: category.channels.filter(
              (channel) =>
                channel.channelDefinition === PictureClarityFormat.HD,
            ),
          };
        });
        setFormatFilteredChannelList(filteredHDChannels);
        break;
      case channelFormats[2]:
        const filtered4KChannels = channels.map((category) => {
          return {
            ...category,
            channels: category.channels.filter(
              (channel) =>
                channel.channelDefinition === PictureClarityFormat.FourK,
            ),
          };
        });
        setFormatFilteredChannelList(filtered4KChannels);
        break;
      default:
        setFormatFilteredChannelList(channels);
    }
  };
  useEffect(() => {
    filtersListOfChannelsByFormat();
  }, [channels, activeChannelFormat]);

  /** Ссылка на input */
  const searchInput = useRef(null);
  useEffect(() => {
    /** Фокус на input */
    if (searchInput.current) searchInput.current.focus();
  }, [searchInput.current, searchMode]);

  /** Изменение выбираемого, но не подтверждённого тега в мобильной версии  */
  useEffect(() => {
    setCandidateForActiveChannelFormat(activeChannelFormat);
  }, [activeChannelFormat]);

  return (
    <>
      {searchMode ? (
        <Search />
      ) : (
        <>
          {isMobileFiltersOpen ? (
            <div className="my-tv__mobile-filters">
              <div className="my-tv__mobile-filters__header">
                <LeadingText color={defaultTheme.colors.black}>
                  Фильтры
                </LeadingText>
                <Icon
                  className="my-tv__mobile-filters__header__icon"
                  icon={<Icons.CloseIcon />}
                  onClick={() => setIsMobileFiltersOpen(false)}
                  highlight
                />
              </div>
              <div className="my-tv__mobile-filters__tags">
                {channelFormats.map((format) => (
                  <div className="my-tv__mobile-filters__tags__tag">
                    <TagButton
                      className="my-tv__mobile-filters__tags__tag__tag-button"
                      onChange={() => handleTagClick(format)}
                      checked={
                        !isDesktop940
                          ? format === candidateForActiveChannelFormat
                          : format === activeChannelFormat
                      }
                    >
                      {format.label}
                    </TagButton>
                  </div>
                ))}
              </div>
              <div className="my-tv__mobile-filters__button-wrapper">
                <Button onClick={handleTagButtonClick}>Показать</Button>
              </div>
            </div>
          ) : (
            <>
              <div className="my-tv__header">
                <H3>Моё ТВ</H3>
                <div className="my-tv__header__tabs">
                  <Tabs
                    valueWithIcons={tabs}
                    onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
                    activeTabIndex={activeTabIndex}
                    width="650px"
                  />
                </div>

                <div
                  className="my-tv__header__channel-formats"
                  onClick={() => !isDesktop940 && setIsMobileFiltersOpen(true)}
                >
                  {activeTabIndex === 2 && (
                    <Select
                      placeholder="Выберите цвет радуги"
                      onOptionClick={(option) => setActiveChannelFormat(option)}
                      value={
                        activeChannelFormat ? activeChannelFormat.value : ''
                      }
                      outsideClickHelper={outsideClickHelper}
                      data={channelFormats}
                    />
                  )}
                </div>

                <div
                  className="my-tv__header__search-icon"
                  onClick={() => setSearchMode(true)}
                >
                  <Icons.GreySearchIcon />
                </div>
              </div>
              <div className="my-tv__tab-block">
                {getTabContent(activeTabIndex)}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default observer(MainPage);
