/** interfaces */
import { OptionProp } from 'cordis-core-ui-planeta';
import {
  ServiceCode,
  ServiceInfoProps,
} from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

export enum TEXT {
  CONNECTED = 'Подключён',
  CONNECTED_IN_PACKAGE = 'Подключён в пакете',
  CONNECTED_IN_PACKAGES = 'Подключён в пакетах',
  IN_PACKAGE = 'В пакете',
  IN_PACKAGES = 'В пакетах',
  CONNECTED_IN_PRODUCT = 'Подключён в продукте',
  INCLUDED_IN_PRODUCT = 'Включён в продукт',
  IN_PRODUCT = 'В продукте',
  CHANNEL_ABANDONMENT = 'Отказ от канала',
  REJECTION_OF_PACKAGE = 'Отказ от пакета',
  REJECTION_OF_PACKAGES = 'Отказ от пакетов',
  PROFITABLE = 'Выгодно',
  SERVICE_CANCELLATION = 'Отказ от сервиса',
  CANCELLATION = 'Отказ',
  ADDED = 'Добавлен',
  REMOVED = 'Убран',
  ADDED_IN_PACKAGE = 'Добавлен в пакете',
  ADDED_IN_PACKAGES = 'Добавлен в пакетах',
  REMOVED_PACKAGE = 'Убран пакет',
  REMOVED_PACKAGES = 'Убраны пакеты',
}

export const PACKAGE_NOMINATIVE = ['пакет', 'пакета', 'пакетов'];
export const PACKAGE = ['пакете', 'пакетах', 'пакетах'];
export const CHANNEL = ['канал', 'канала', 'каналов'];
export const TV_CHANNEL = ['телеканал', 'телеканала', 'телеканалов'];
export const ORDERED = ['заказан', 'заказано', 'заказано'];
export const SERVICE = ['сервис', 'сервиса', 'сервисов'];
export const CONNECTION = ['Подключён', 'Подключено', 'Подключено'];

export enum ServiceNames {
  VIEW_CONTROL = 'Управление просмотром',
  WINK = 'Wink',
}

export enum TRANSFORMER_TEXT {
  ADD_TO_TRANSFORMER = 'Добавить в Трансформер',
  ADD = 'Добавить',
  REMOVE_CHANNEL = 'Убрать канал',
  REMOVE_PACKAGE = 'Убрать пакет',
  RETURN_TO_TRANSFORMER = 'Вернуть в Трансформер',
}

/** Форматы телеканалов */
export const channelFormats: OptionProp[] = [
  {
    label: 'Все форматы',
    value: 0,
  },
  {
    label: 'HD',
    value: 1,
  },
  {
    label: '4K',
    value: 2,
  },
];

export enum CardStates {
  /** Главная */
  MAIN = 'MAIN',
  /** Карточка описания каналов */
  CHANNEL_DESCRIPTION = 'CHANNEL_DESCRIPTION',
  /** Карточка описания пакета */
  PACKAGE_DESCRIPTION = 'PACKAGE_DESCRIPTION',
  /** Карточка сервиса "Управление просмотром" */
  VIEW_CONTROL_SERVICE = 'VIEW_CONTROL_SERVICE',
  /** Карточка сервиса Wink */
  WINK_SERVICE = 'WINK_SERVICE',
}

export const serviceInfo: ServiceInfoProps[] = [
  { name: 'Управление просмотром', code: ServiceCode.viewControl },
  { name: 'Wink', code: ServiceCode.wink },
];

export const CHANNEL_DESCRIPTION_ID = 'CHANNEL_DESCRIPTION_ID';
export const PACKAGE_DESCRIPTION_ID = 'PACKAGE_DESCRIPTION_ID';

/** идентификатор элемента блока Television 2.0 */
export const TELEVISION_ID = '#tv';

/** Тип используемого стора */
export enum STORE_TYPE {
  TELEVISION = 'TELEVISION',
  MY_TV_X2 = 'MY_TV_X2',
  MY_TV_X3 = 'MY_TV_X3',
}
