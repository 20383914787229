/** библиотеки */
import styled from '@emotion/styled';

/** стилизованный компонент "Прелоадер" */
export const StyledGlobalPreloader = styled.div`
  .loader {
    position: fixed;
    mix-blend-mode: multiply;
    z-index: 999;
    top: 15px;
    right: 15px;
    text-align: right;
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.5;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.5;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.5;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.5;
    }
  }

  .overlay {
    position: fixed;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
  }
`;
