/** libraries */
import { FC } from 'react';
import { Button, Text, defaultTheme, Loader } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledCreatingRuleFooter } from './styles';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface CreatingRuleFooterProps {
  saveRule: () => Promise<void>;
  isLoading: boolean;
  saveError: string;
  deleteRule: () => Promise<void>;
  isLoadingDelete: boolean;
  isEdit: boolean;
  isDisabled: boolean;
}

const CreatingRuleFooter: FC<CreatingRuleFooterProps> = ({
  saveRule,
  isLoading,
  saveError,
  deleteRule,
  isLoadingDelete,
  isEdit,
  isDisabled,
}: CreatingRuleFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  if (isTemporaryTokenAuth) {
    return (
      <StyledCreatingRuleFooter>
        <Button disabled>{isEdit ? 'Применить' : 'Создать'}</Button>
        <Text color={defaultTheme.colors.warning}>
          Действия доступны только клиенту
        </Text>
        {isEdit && (
          <Text lineHeight="24px" color={defaultTheme.colors.disable}>
            Удалить правило
          </Text>
        )}
      </StyledCreatingRuleFooter>
    );
  }

  return (
    <StyledCreatingRuleFooter>
      <Button
        onClick={saveRule}
        loading={isLoading}
        disabled={isEdit && isDisabled}
      >
        {isEdit ? 'Применить' : 'Создать'}
      </Button>
      {saveError && (
        <Text
          className="cursor"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
          onClick={deleteRule}
        >
          {saveError}
        </Text>
      )}
      {isEdit && (
        <div className="delete">
          {isLoadingDelete ? (
            <Loader small />
          ) : (
            <Text
              className="cursor"
              lineHeight="24px"
              color={defaultTheme.colors.planeta}
              onClick={deleteRule}
            >
              Удалить правило
            </Text>
          )}
        </div>
      )}
    </StyledCreatingRuleFooter>
  );
};

export default observer(CreatingRuleFooter);
