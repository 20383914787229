/** libraries */
import { FC } from 'react';
import { defaultTheme, Icons, Snoska, Text } from 'cordis-core-ui-planeta';
import { useMyTvStore } from '../../stores/useMyTvStore';
import { CardStates, STORE_TYPE } from '../../constants';
import { observer } from 'mobx-react';
import { getStore } from '../../utils';

interface ChannelPropertiesProps {
  channelDefinition: string;
  audioStreams: string[];
  adultContent: boolean;
  isViewControlAvailable: boolean;
}

/** Свойства канала */
const ChannelProperties: FC<ChannelPropertiesProps> = ({
  channelDefinition,
  audioStreams,
  adultContent,
  isViewControlAvailable,
}: ChannelPropertiesProps) => {
  const { storeType } = useMyTvStore();
  const { setCardState } = getStore(storeType);
  const isTelevision = storeType === STORE_TYPE.TELEVISION;
  return (
    <div className="channel-description-card__properties">
      <div className="channel-description-card__properties__first">
        <div className="channel-description-card__properties__block">
          <Text color={defaultTheme.colors.gray}>Формат изображения</Text>
          <Text color={defaultTheme.colors.totalBlack}>
            {channelDefinition}
          </Text>
        </div>
        {audioStreams && (
          <div className="channel-description-card__properties__block">
            <Text color={defaultTheme.colors.gray}>Звуковые дорожки</Text>
            <Text color={defaultTheme.colors.totalBlack}>
              {audioStreams.map((stream) => `${stream} `)}
            </Text>
          </div>
        )}
      </div>
      <div className="channel-description-card__properties__second">
        {!adultContent && isViewControlAvailable && (
          <div className="channel-description-card__properties__block">
            <Text color={defaultTheme.colors.gray}>Управление просмотром</Text>
            <Text color={defaultTheme.colors.totalBlack}>
              <Icons.ViewControlIcon black />
              доступно для канала
            </Text>
            {!isTelevision && (
              <Snoska
                className="channel-description-card__properties__link"
                color={defaultTheme.colors.planeta}
                onClick={() => setCardState(CardStates.VIEW_CONTROL_SERVICE)}
              >
                Подключите сервис
              </Snoska>
            )}
          </div>
        )}
        <div className="channel-description-card__properties__block">
          <Text color={defaultTheme.colors.gray}>Возрастные ограничения</Text>
          <Text color={defaultTheme.colors.totalBlack}>
            {adultContent ? '18+' : '6+'}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default observer(ChannelProperties);
