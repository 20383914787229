/** libraries */
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  H3,
  Icon,
  Icons,
  LeadingText,
  Tabs,
  TabsStyleTypes,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledVoice } from './styles';
/** constants */
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { OPERATING_STATE } from '~/constants/common';
/** components */
import Phones from './Tabs/Phones/Phones';
import Forwarding from './Tabs/Forwarding/Forwarding';
import Statistics from './Tabs/Statistics/Statistics';
import Rates from './Tabs/Rates/Rates';
import Portal from '~/components/Portal/Portal';
/** api */
import { phoneBindCheck } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from './store/useVoiceStore';
/** SidePages */
import ConnectNumberSidePage from './SidePages/ConnectNumber/ConnectNumberSidePage';
import PasswordSidePage from './SidePages/Password/PasswordSidePage';
import NumberDeactivationSidePage from './SidePages/NumberDeactivation/NumberDeactivationSidePage';
import CancelingNumberDeactivationSidePage from './SidePages/CancelingNumberDeactivation/CancelingNumberDeactivationSidePage';
import CreatingRuleSidePage from './SidePages/CreatingRule/CreatingRuleSidePage';

const Voice = (): JSX.Element => {
  const {
    contractStateStore: { contractState },
    summaryDataStore: { tariffId },
    pab2cVoiceStore: { phones, isLoading, isFail, getPhoneData },
    authStore: { isAuth },
  } = useRootStore();
  const {
    setIsShowConnectNumber,
    setResult,
    forwarding,
    getForwarding,
    isCorrectForwarding,
    isLoadingConnectNumber,
    setIsLoadingConnectNumber,
    setIsShowCreatingRule,
    setIsCreate,
    getPrices,
  } = useVoiceStore();

  /** Индекс активного таба */
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  const TABS = [
    {
      icon: <Icons.TelephoneIcon color={defaultTheme.colors.white} />,
      inactiveIcon: <Icons.TelephoneIcon color={defaultTheme.colors.shadow} />,
    },
    {
      icon: <Icons.ArrowsIcon color={defaultTheme.colors.white} />,
      inactiveIcon: <Icons.ArrowsIcon />,
    },
    {
      icon: <Icons.StatIcon color={defaultTheme.colors.white} />,
      inactiveIcon: <Icons.StatIcon />,
    },
    {
      icon: <Icons.TariffIcon color={defaultTheme.colors.white} />,
      inactiveIcon: <Icons.TariffIcon />,
    },
  ];

  /* Возвращает компонент при переключении табов */
  const getTabContent = (tabIndex: number): JSX.Element => {
    switch (tabIndex) {
      case 0:
        return <Phones />;
      case 1:
        return <Forwarding />;
      case 2:
        return <Statistics />;
      case 3:
        return <Rates />;
      default:
        return <></>;
    }
  };

  /** Текст кнопки */
  const buttonText = () => {
    switch (activeTabIndex) {
      case 0:
        if (isDesktop1280) return 'Подключить номер';
        if (isDesktop940 && !isDesktop1100) return '';
        return 'Подключить';
      case 1:
        if (isDesktop1280) return 'Создать правило';
        if (isDesktop940 && !isDesktop1100) return '';
        return 'Создать';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (tariffId && isAuth) {
      getPrices(tariffId);
    }
  }, [tariffId, isAuth]);

  useEffect(() => {
    if (activeTabIndex === 1 && !isCorrectForwarding) {
      getForwarding();
    }
  }, [activeTabIndex]);

  /** Проверка возможнсти подключения номера к договору */
  const checkPhoneBind = async (): Promise<boolean> => {
    try {
      await phoneBindCheck();
      return true;
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
      return false;
    }
  };

  const [
    isDisabledConnectNumber,
    setIsDisabledConnectNumber,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsDisabledConnectNumber(
      (activeTabIndex === 1 && phones.length === forwarding.length) ||
        (activeTabIndex === 0 && phones.length === 5),
    );
  }, [activeTabIndex, phones, forwarding]);

  /** Подключить номер */
  const onClickButton = async () => {
    if (activeTabIndex === 0) {
      setIsLoadingConnectNumber(true);

      if (await checkPhoneBind()) {
        await getPhoneData();
        setIsShowConnectNumber(true);
        setIsLoadingConnectNumber(false);
      }
    }
    if (activeTabIndex === 1) {
      setIsCreate(true);
      setIsShowCreatingRule(true);
    }
  };

  useEffect(() => {
    if (isFail) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
    }
  }, [isFail]);

  return (
    <StyledVoice>
      <div className="voice__header">
        {isDesktop940 ? (
          <H3>Голосовая связь</H3>
        ) : (
          <LeadingText
            className="voice__header__lead"
            color={defaultTheme.colors.black}
          >
            Голосовая связь
          </LeadingText>
        )}
        {(!isLoading || isLoadingConnectNumber) && (
          <>
            <div className="voice__header__tabs">
              <Tabs
                valueWithIcons={TABS}
                onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
                styleType={TabsStyleTypes.BLACK}
                activeTabIndex={activeTabIndex}
              />
            </div>
            {[0, 1].includes(activeTabIndex) && (
              <Button
                icon={
                  isDesktop940 &&
                  !isDesktop1100 && <Icon icon={<Icons.PlusIcon />} />
                }
                onClick={onClickButton}
                loading={isLoadingConnectNumber}
                disabled={
                  isDisabledConnectNumber ||
                  contractState !== OPERATING_STATE.ON
                }
              >
                {buttonText()}
              </Button>
            )}
          </>
        )}
      </div>
      {getTabContent(activeTabIndex)}
      <Portal>
        <ConnectNumberSidePage />
        <PasswordSidePage />
        <CreatingRuleSidePage />
        <NumberDeactivationSidePage />
        <CancelingNumberDeactivationSidePage />
      </Portal>
    </StyledVoice>
  );
};

export default observer(Voice);
