/** libraries */
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import ConnectionWizard from '~/components/ConnectionWizard/ConnectionWizard';
import WizardConfirm from '~/components/ConnectionWizard/WizardConfirm';
/** constants */
import { CONNECTION_TYPE } from '~/components/ConnectionWizard/constants';
/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import { useRootStore } from '~/stores/RootStore';

const ConnectionSP = (): JSX.Element => {
  const {
    onCloseClickSidePageConnection,
    isVisible,
    connectionType,
    select,
    street,
    house,
    apartment,
    phone,
    contractPhone,
  } = useConnectionStore();
  const {
    authStore: { auth },
  } = useRootStore();

  /** Комментарий для заявки на подключение */
  const generateComment = (type): string => {
    const modifiedPhoneText =
      phone.forSend.replace('+7', '8') !==
      contractPhone.forSend.replace('+7', '8')
        ? ` Указан телефон ${phone.value}.`
        : '';
    switch (type) {
      case CONNECTION_TYPE.newAddress:
        return `Заявка на подключение по новому адресу, действующий клиент, договор ${auth.contractName}.${modifiedPhoneText}`;
      case CONNECTION_TYPE.moving:
        return `Номер договора: ${
          auth.contractName
        }. Заказан переезд с сайта, адрес: ${select.label}, ул. ${
          street || 'не указана'
        }, д. ${house || 'не указан'}, кв. ${
          apartment || 'не указана'
        }.${modifiedPhoneText}`;
      case CONNECTION_TYPE.friend:
        return `Заявка на подключение друга, подана с действующего договора ${auth.contractName} ${auth.clientName}`;
      default:
        return null;
    }
  };

  return (
    <SidePage
      show={isVisible}
      headerText={`Заявка на${'\u00A0'}подключение к${'\u00A0'}Планете`}
      onCloseClick={onCloseClickSidePageConnection}
      footerContainer={
        <WizardConfirm comment={generateComment(connectionType)} />
      }
      removeScrollBar={false}
    >
      <ConnectionWizard />
    </SidePage>
  );
};

export default observer(ConnectionSP);
