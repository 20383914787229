/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
/** interfaces */
import { VacationProps } from './interfaces';
/** store */
import { VacationProvider } from './store/MainVacationStore/useMainVacationStore';
/** components */
import VacationContent from './VacationContent';

/** Блок «ЛК. Каникулы»
 *  https://ckb.itmh.ru/pages/viewpage.action?pageId=669188148
 */
const Vacation: FC<VacationProps> = ({ content }: VacationProps) => {
  return (
    <VacationProvider content={content}>
      <VacationContent />
    </VacationProvider>
  );
};

export default observer(Vacation);
