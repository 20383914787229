/** Библиотеки */
import styled from '@emotion/styled';
/** Константы */
import { desktop940 } from '~/components/Grid/constants';

export const StyledTextHtml = styled.div`
  margin-left: 32px;

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3 {
    word-break: break-word;

    @media (min-width: ${desktop940}px) {
      word-break: normal;
    }
  }
`;
