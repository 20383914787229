import { FC } from 'react';
import { StyledTable } from './styles';

interface TableProps {
  children: React.ReactNode;
  width?: string;
}

const Table: FC<TableProps> = ({ children, width }: TableProps) => {
  return <StyledTable width={width}>{children}</StyledTable>;
};

export default Table;
