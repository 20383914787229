/* Библиотеки */
import style from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/* Стилизованная часть левой колонки строки с настройками управления просмотром */
export const StyledLeftColumn = style.div`
  ${() => `
      display: flex;
      flex-direction: column;
      white-space: pre-line;
      min-width: 35%;
      h3 {
        margin-bottom: 36px;
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          margin-bottom: 0;
        }
      }
      > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        svg {
          margin-right: 12px;
          flex-shrink: 0;
        }
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          display: none;
        }
      }
    `}
`;

/* Стилизованная часть правой колонки строки с настройками управления просмотром */
export const StyledRightColumn = style.div`
  ${() => `
      display: none;
      align-items: flex-start;
      flex-direction: row;
      div {
        display: flex;
        flex-direction: row;
        margin-right: 35px;
      }
      span {
        white-space: pre-line;
      }
      svg {
        margin-right: 12px;
        flex-shrink: 0;
      }
      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: flex;
      }
    `}
`;
