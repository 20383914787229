/** Библиотеки */
import React from 'react';
import { observer } from 'mobx-react';
/** Стили */
import { StyledFooter } from './style';
import { StyledSnoska } from '../../styles';
/** Компоненты */
import { Button, defaultTheme, Snoska, Text } from 'cordis-core-ui-planeta';
/** stores */
import useSoftlineStateModelStore from '../../stores/useSoftlineStateStore';
import { useRootStore } from '~/stores/RootStore';

const SoftlineUnsubscribeWizardFooter = (): JSX.Element => {
  const {
    authStore: { isTemporaryTokenAuth },
    softlineStore: { getSubscriptionInfos },
  } = useRootStore();
  const {
    isLoadingSoftlineState,
    errorMessage,
    unsubscribe,
  } = useSoftlineStateModelStore();
  return (
    <StyledFooter>
      <StyledSnoska>
        <Button
          type="button"
          onClick={() => unsubscribe(getSubscriptionInfos)}
          loading={isLoadingSoftlineState}
          disabled={isTemporaryTokenAuth}
        >
          Отключить
        </Button>
        {isTemporaryTokenAuth && (
          <Snoska className="snoska" color={defaultTheme.colors.gray}>
            Действие доступно только клиенту
          </Snoska>
        )}
      </StyledSnoska>
      {errorMessage && (
        <Text
          className="error"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
        >
          {errorMessage}
        </Text>
      )}
    </StyledFooter>
  );
};

export default observer(SoftlineUnsubscribeWizardFooter);
