import { createContext, useContext } from 'react';
import voiceModel, { IVoiceStore } from './VoiceModel';
import { DEFAULT_STATE } from '../constants';

const store = voiceModel.create(DEFAULT_STATE);

const VoiceStoreContext = createContext<IVoiceStore>(store);

const useVoiceStore = () => {
  return useContext(VoiceStoreContext);
};

export default useVoiceStore;
