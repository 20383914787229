/** библиотеки */
import styled from '@emotion/styled';
import { desktop940 } from '~/components/Grid/constants';

export const StyledSoftlineSubscribeWizardSuccess = styled.div`
  margin-top: -51px;

  .subscribe-wizard-success {
    &__icon {
      margin-bottom: 24px;
    }

    &__distribution-information-block {
      margin: 32px 0;

      &__link-to-instructions {
        margin-bottom: 24px;
      }

      &__info-wrapper {
        margin-bottom: 24px;
        > span {
          margin-bottom: 4px;
        }
        > h3 {
          margin-bottom: 0;
        }
      }

      &__os-wrapper {
        display: flex;
        flex-direction: column;

        > span {
          margin-bottom: 8px;
        }

        &__upload {
          display: flex;
          flex-direction: column;

          .select-wrapper {
            width: 100%;
            margin-bottom: 16px;
          }

          &__button {
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .subscribe-wizard-success {
      &__distribution-information-block {
        &__os-wrapper {
          &__upload {
            flex-direction: row;
            align-items: center;

            .select-wrapper {
              width: 355px;
              margin-bottom: 0;
            }

            &__button {
              margin-left: 16px;

              button {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
`;
