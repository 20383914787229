// https://iknpc-bg.ru/gadzhety/honor-bez-gugl-servisov.html
const huaweiLockedSeries = [
  'OXF-AN10',
  'BMH-AN10',
  'BMH-AN10',
  'EBG-AN10',
  'CDY-NX9A',
  'MOA-LX9N',
  'AKA-L29',
  'DUA-LX9',
  'LIO-L29',
  'TAH-N29m',
  'ELS-N39',
  'ELS-NX9',
  'ANA-NX9',
  'JNY-LX1',
  'ART-L29N',
  'AQM-LX1',
  'MED-LX9N',
  'DRA-LX9',
];

/** Wink redirect */
const winkDeviceRedirect = (
  model: string,
  isIOS: boolean,
  isAndroid: boolean,
): void => {
  if (isIOS) {
    window.location.assign('https://apps.apple.com/RU/app/id1294366633');
    return;
  }
  if (huaweiLockedSeries.includes(model)) {
    window.location.assign('https://appgallery.huawei.com/#/app/C101105503');
    return;
  }
  if (isAndroid) {
    window.location.assign(
      'https://play.google.com/store/apps/details?id=ru.rt.video.app.mobile',
    );
    return;
  }

  window.location.assign('https://wink.ru');
};

export default winkDeviceRedirect;
