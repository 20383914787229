/** библиотеки */
import styled from '@emotion/styled';

/** Константы */
import {
  desktop1100,
  desktop500,
  desktop940,
} from '~/components/Grid/constants';

/* Интерфейсы */
import * as Banner from '../Banner.types';

/* Стилизованный блок изображения Banner Single */
export const StyledBannerImgDiv = styled.div<Banner.StyledBannerImgDivProps>`
  ${({ url, smallUrl, isCenterImg, borderRadius }): string => `
  .banner {
    background-size: cover;
    background-position: center ${!isCenterImg ? 'right 40%' : ''};
    background-repeat: no-repeat;
    border-radius: ${borderRadius || '0'}
  }

  .small {
    background-image: url(${smallUrl});

    @media (min-width: ${desktop500 + 40}px) {
      background-image: url(${url});
    }

    @media (min-width: ${desktop940}px) {
      background-image: url(${smallUrl});
    }
  }

  .half {
    background-image: url(${url});

    @media (min-width: ${desktop940}px) and (max-width: ${desktop1100}px), (max-width: ${
    desktop500 + 40
  }px) {
      background-image: url(${smallUrl});
    }
  }

  .medium {
    background-image: url(${url});

    @media (max-width: ${desktop500 + 40}px) {
      background-image: url(${smallUrl});
    }
  }
  
  .large {
    background-image: url(${url});

    @media (max-width: ${desktop500 + 40}px) {
      background-image: url(${smallUrl});
    }
  }
`}
`;

/* Стилизованный блок контента Banner Single */
export const StyledBannerContent = styled.div<{ isITV }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ isITV }) => (isITV ? 'flex-start' : 'flex-end')};
  color: white;
  padding: 16px 32px 46px 32px;
  height: 400px;
  white-space: pre-line;
  & > span {
    flex-grow: 1;
  }

  h1 {
    margin-bottom: 20px;
  }
`;

export const StyledImage = styled.div`
  margin: 16px 0 8px 0;
`;
