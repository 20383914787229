/** libraries */
import { FC } from 'react';
import {
  Loader,
  LeadingText,
  defaultTheme,
  Text,
  Switcher,
  Icons,
  Snoska,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** constants */
import { DEFAULT_ERROR } from '../../../Devices/constants';
import {
  FORWARDING_STATES,
  DAY_OF_THE_WEEK,
  StateCondition,
  Weeks,
} from '../../constants';
import { HELP_PLANETA_URL, VOICE_SLUG } from '~/constants/common';
import { desktop940, desktop1280 } from '~/components/Grid/constants';
/** interfaces */
import { ForwardingRule } from '../../interfaces';
/** styles */
import { StyledForwarding } from './styles';
/** utils */
import { formatNumber, maskPhone } from '~/utils/utils';
import { maskHomePhone } from '../../utils';
import LinkWrapper from '~/components/LinkWrapper';
/** api */
import { saveForwardingRule } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from '../../store/useVoiceStore';

const Forwarding: FC = () => {
  const {
    pab2cVoiceStore: { phones },
  } = useRootStore();
  const {
    forwarding,
    getForwarding,
    isErrorForwarding,
    isLoadingForwarding,
    setEditableRule,
    setIsShowCreatingRule,
    setIsEdit,
  } = useVoiceStore();

  const onClickMoreDetails = (rule: ForwardingRule) => {
    setEditableRule(rule);
    setIsShowCreatingRule(true);
    setIsEdit(true);
  };

  const getWeekDays = (days) => {
    const daysKeys = Object.keys(days);
    let time = '';
    const str = daysKeys.reduce((acc: string[], day) => {
      if (days[day]) {
        acc.push(`${DAY_OF_THE_WEEK[day]}`);
        if (!time)
          time = `c ${days[day].interval.begin} до ${days[day].interval.end}`;
        return acc;
      }
      return acc;
    }, []);
    if (str.length === 7) {
      return 'Всегда';
    }
    return str.join(', ').concat(` ${time}`);
  };

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  /** Загрузка */
  if (isLoadingForwarding) {
    return (
      <StyledForwarding>
        <div className="forwarding__loader">
          <Loader />
        </div>
      </StyledForwarding>
    );
  }
  /** Ошибка */
  if (isErrorForwarding) {
    return (
      <StyledForwarding>
        <div className="forwarding__error">
          <Text lineHeight="24px">{DEFAULT_ERROR}</Text>
        </div>
      </StyledForwarding>
    );
  }
  if (!forwarding.length) {
    return (
      <StyledForwarding>
        <div className="forwarding__not-found">
          <LeadingText color={defaultTheme.colors.black}>
            Правила переадресации не добавлены
          </LeadingText>
          <Text lineHeight="24px" color={defaultTheme.colors.gray}>
            Настройте преадресацию звонков на другие номера. Эта услуга
            пригодится, если вы заняты и не можете ответить по данному номеру.
          </Text>
          <LinkWrapper href={`${HELP_PLANETA_URL}/cabinet/#voiceservice`}>
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              Описание услуги
            </Text>
          </LinkWrapper>
        </div>
      </StyledForwarding>
    );
  }

  const saveRules = async (item: ForwardingRule) => {
    const { timetable } = item;
    const keys = Object.keys(Weeks).filter((v) => Number.isNaN(Number(v)));
    const days = Object.keys(timetable).reduce((acc, table) => {
      if (!timetable[table]) return acc;
      acc.push(keys.findIndex((day) => day === table) + 1);
      return acc;
    }, []);
    const dayString = keys.find((day, index) => index + 1 === days[0]);
    const start = days.length ? timetable[dayString].interval.begin : '00:00';
    const end = days.length ? timetable[dayString].interval.end : '00:00';
    try {
      await saveForwardingRule(
        item.sim,
        !item.isEnabled,
        item.state as StateCondition,
        item.forwardNumber,
        item.timeout,
        item.id,
        days,
        start,
        end,
      );
      getForwarding();
    } catch (e) {
      console.error('saveRules', e);
    }
  };

  return (
    <StyledForwarding>
      <LinkWrapper href={VOICE_SLUG} className="forwarding__description">
        Описание услуги
      </LinkWrapper>
      <div className="forwarding__block">
        {forwarding.map((item) => {
          return (
            <div className="forwarding__block__line" key={item.id}>
              <Switcher
                checked={item.isEnabled}
                onChange={() => saveRules(item)}
              >
                <div className="forwarding__block__line__column">
                  <Text
                    className="forwarding__block__line__number"
                    lineHeight="20px"
                    color={defaultTheme.colors.black}
                  >
                    {maskHomePhone(
                      phones.find((phone) => phone.sim === item.sim),
                    )}
                  </Text>
                  {isDesktop940 && !isDesktop1280 ? (
                    <Snoska color={defaultTheme.colors.shadow}>
                      {`${FORWARDING_STATES[item.state]}, ${getWeekDays(
                        item.timetable,
                      )}`}
                    </Snoska>
                  ) : (
                    <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                      {`${FORWARDING_STATES[item.state]}, ${getWeekDays(
                        item.timetable,
                      )}`}
                    </Text>
                  )}
                </div>
              </Switcher>
              <div className="forwarding__block__line__column">
                <div className="forwarding__block__line__row">
                  <Icons.VoiceForwardingIcon />
                  <Text
                    className="forwarding__block__line__number"
                    lineHeight="20px"
                    color={defaultTheme.colors.black}
                  >
                    {maskPhone(item.forwardNumber)
                      .replace('+7', '8')
                      .replace(/[()]/g, '')}
                  </Text>
                </div>
                {isDesktop940 && !isDesktop1280 ? (
                  <Snoska color={defaultTheme.colors.shadow}>
                    {formatNumber(item.price[0].price)} ₽ в минуту
                  </Snoska>
                ) : (
                  <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                    {formatNumber(item.price[0].price)} ₽ в минуту
                  </Text>
                )}
              </div>
              <Text
                className="forwarding__pointer"
                onClick={() => onClickMoreDetails(item)}
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
              >
                Подробнее
              </Text>
            </div>
          );
        })}
      </div>
    </StyledForwarding>
  );
};

export default observer(Forwarding);
