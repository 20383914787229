/** библиотеки */
import styled from '@emotion/styled';

/** константы */
import {
  desktop240,
  desktop400,
  desktop650,
  desktop880,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

import { IMAGES } from '../constants';

export const StyledSmartTV = styled.div`
  min-height: 400px;
  padding: 32px;
  padding-top: 28px;
  background-image: url(${IMAGES.smartTvBg240});
  background-repeat: no-repeat;

  .title__mobile {
    margin-bottom: 18px;
    max-width: 180px;
    font-size: 24px;
    line-height: 24px;
  }

  .text__mobile {
    max-width: 120px;
  }

  .title {
    margin-bottom: 8px;
    max-width: 202px;
  }

  .title__400 {
    margin-bottom: 26px;
    max-width: 387px;
    font-size: 48px;
    font-weight: 800;
  }

  .title,
  .title__mobile {
    font-weight: 700;
  }

  .title,
  .title__400,
  .text,
  .text__line-height21 {
    display: none;
  }

  .text__line-height21 {
    line-height: 21px;
  }

  .text,
  .text__line-height21 {
    max-width: 155px;
  }

  @media (min-width: ${desktop240}px) {
    background-image: url(${IMAGES.smartTvBg650});
    background-position: bottom;
  }

  @media (min-width: ${desktop400}px) {
    .title__mobile,
    .text__mobile {
      display: none;
    }

    .title {
      display: block;
    }

    .text {
      display: inline-block;
      max-width: 284px;
    }
  }

  @media (min-width: ${desktop650}px) {
    .title {
      display: none;
    }

    .title__400 {
      display: block;
      max-width: 387px;
    }

    .text {
      display: inline-block;
      max-width: 160px;
    }

    background-image: url(${IMAGES.smartTvBg880});
    background-position: bottom left;
  }

  @media (min-width: ${desktop880 + 1}px) {
    background-position: center;
    background-size: cover;
  }

  @media (min-width: ${desktop940}px) {
    .title__400,
    .text {
      display: none;
    }

    .title {
      display: block;
    }

    .text__line-height21 {
      display: inline-block;
    }

    background-image: url(${IMAGES.smartTvBg});
    background-position: bottom;
    background-size: auto;
  }

  @media (min-width: ${desktop1100}px) {
    .text__line-height21 {
      display: none;
    }

    .title {
      display: block;
      margin-bottom: 18px;
    }

    .text {
      display: inline-block;
    }

    background-image: url(${IMAGES.smartTvBg340});
  }

  @media (min-width: ${desktop1280}px) {
    .title__mobile,
    .text__mobile {
      display: none;
    }
    background: url(${IMAGES.smartTvBg400}) no-repeat;
  }
`;
