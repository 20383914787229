import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme, H3, Loader, Text } from 'cordis-core-ui-planeta';

export default styled.main<{ $backgroundUrl?: string }>`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 400px;
  background: ${defaultTheme.colors.white};
  height: 100%;

  .description {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  a:hover {
    text-decoration: none;
  }

  ${({ $backgroundUrl }) =>
    $backgroundUrl &&
    css`
      background-image: url(${process.env.STATIC_SERVER}${$backgroundUrl});
      background-position: center;
      background-size: cover;
    `}
`;

export const StyledLoader = styled(Loader)`
  flex: 1;
`;

export const StyledHeader = styled(H3)`
  margin-bottom: 0;
  white-space: pre-line;
`;

export const StyledText = styled(Text)`
  width: 230px;
`;

export const StyledLink = styled(Text)`
  color: ${defaultTheme.colors.white};
  line-height: 24px;
  margin-top: 8px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
