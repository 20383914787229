/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
/** interfaces */
import { ProductSwitcherContentProps } from './interfaces';
/** components */
import { ProductSwitcherStoreProvider } from './store/useProductSwitcherStore';
import Main from './Main';

/**
 * Блок "Блок «ЛК. Смена продукта»"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=711869968
 */
const ProductSwitcher: FC<ProductSwitcherContentProps> = ({
  content,
}: ProductSwitcherContentProps) => {
  return (
    <>
      <ProductSwitcherStoreProvider
        fields={content.fields}
        defaultImgs={content.defaultImgs}
      >
        <Main />
      </ProductSwitcherStoreProvider>
    </>
  );
};

export default observer(ProductSwitcher);
