/** Libraries */
import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
/** Hooks */
import { useAutoPayment } from '~/components/Blocks/Templates/AutoPayment/AutoPaymentHook';
/** Components */
import { SidePage } from 'cordis-core-ui-planeta';
import AutoPaymentWizardFooter from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/AutoPaymentWizardFooter';
import AutoPaymentWizard from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard';
import Portal from '~/components/Portal/Portal';
/** Constants */
import { VIEW_TYPES } from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';
/** styles */
import { StyledAutopayment } from './styles';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import { useRootStore } from '~/stores/RootStore';

/** SidePage автоплатежа */
const Autopayment: FC = () => {
  const router = useRouter();
  const {
    authStore: { isAuth, setIsFirstAuth, isFirstAuth },
  } = useRootStore();
  const { setIsVisible } = useMakeAuthStore();

  const {
    isWizardShow,
    isLoading,
    viewType,
    setViewType,
    isLoadingFooter,
    amount,
    setAmount,
    errorMessage,
    setErrorMessage,
    isAutoCount,
    setIsAutoCount,
    getWizardTitle,
    unbindAutoPayment,
    bindAutoPayment,
    getDate,
    stateFormError,
    closeWizard,
    convertedSbpBanksList,
    sbpBindingInfo,
    setSbpBindingInfo,
    setSbpBindingCheckInfo,
    registerSbpPaymentWithBinding,
    setTimeIsUp,
    minAmountValue,
    setIsWizardShow,
    card,
    setCard,
    isOtherAmount,
    setIsOtherAmount,
  } = useAutoPayment();

  /** Меняет SidePage входа на SidePage автоплатежа */
  useEffect(() => {
    if (!router.isReady || !router?.query?.showAutopayment) return;
    if (!isAuth) {
      setIsVisible(true);
      setIsFirstAuth(true);
      return;
    }
    if (isAuth && isFirstAuth) {
      setIsFirstAuth(false);
    }
    setIsVisible(false);
    setIsWizardShow(true);
  }, [router.isReady, router.asPath, isAuth]);

  if (
    !router.isReady ||
    !router?.query?.showAutopayment ||
    (router?.query.isAutoPayment &&
      ![VIEW_TYPES.BIND_SUCCESS, VIEW_TYPES.BIND_FAILED].includes(viewType))
  )
    return null;

  return (
    <Portal>
      <StyledAutopayment
        isResult={[
          VIEW_TYPES.BIND_FAILED,
          VIEW_TYPES.BIND_SUCCESS,
          VIEW_TYPES.UNBIND_SUCCESS,
          VIEW_TYPES.UNBIND_FAILED,
          VIEW_TYPES.LINKING_ACCOUNT_FAILED,
          VIEW_TYPES.LINKING_CARD_FAILED,
        ].includes(viewType)}
      >
        <SidePage
          show={isWizardShow}
          width="832px"
          headerText={getWizardTitle()}
          onCloseClick={closeWizard}
          footerContainer={
            viewType !== VIEW_TYPES.BIND_SUCCESS &&
            viewType !== VIEW_TYPES.UNBIND_SUCCESS && (
              <AutoPaymentWizardFooter
                amount={amount}
                bindAutoPayment={bindAutoPayment}
                isLoading={isLoadingFooter}
                unbindAutoPayment={unbindAutoPayment}
                viewType={viewType}
                setViewType={setViewType}
                setSbpBindingInfo={setSbpBindingInfo}
                setSbpBindingCheckInfo={setSbpBindingCheckInfo}
                registerSbpPaymentWithBinding={registerSbpPaymentWithBinding}
                minAmountValue={minAmountValue}
              />
            )
          }
          removeScrollBar
        >
          <AutoPaymentWizard
            viewType={viewType}
            setViewType={setViewType}
            getDate={getDate}
            amount={amount}
            bindAutoPayment={bindAutoPayment}
            isLoading={isLoading}
            unbindAutoPayment={unbindAutoPayment}
            setAmount={setAmount}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            setIsAutoCount={setIsAutoCount}
            isAutoCount={isAutoCount}
            stateFormError={stateFormError}
            convertedSbpBanksList={convertedSbpBanksList}
            sbpBindingInfo={sbpBindingInfo}
            setSbpBindingInfo={setSbpBindingInfo}
            setTimeIsUp={setTimeIsUp}
            registerSbpPaymentWithBinding={registerSbpPaymentWithBinding}
            setSbpBindingCheckInfo={setSbpBindingCheckInfo}
            card={card}
            setCard={setCard}
            isOtherAmount={isOtherAmount}
            setIsOtherAmount={setIsOtherAmount}
          />
        </SidePage>
      </StyledAutopayment>
    </Portal>
  );
};

export default observer(Autopayment);
