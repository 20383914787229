/** libraries */
import { FC, useEffect, useMemo, useState, useRef } from 'react';
import {
  Checkbox,
  defaultTheme,
  H2,
  Icons,
  OptionProp,
  Select,
  Tabs,
  Text,
  Link as CordisLink,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** constants */
import { CardStates, channelFormats, TELEVISION_ID } from '../../constants';
import { desktop400, desktop940 } from '~/components/Grid/constants';

/** styles */
import { StyledLink, StyledSwitcher, StyledTelevision } from './styles';

/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';

/** interfaces */
import { ChannelPackage, SubjectWithChannels } from '../../interfaces';
import { PictureClarityFormat } from '../Cards/ChannelCard/interfaces';

/** components */
import ChannelsTab from '../Tabs/ChannelsTab';
import PackagesTab from '../Tabs/PackagesTab';
import Search from '../Search/Search';
import ChannelDescription from './ChannelDescription/ChannelDescription';
import PackageDescription from './PackageDescription/PackageDescription';
import ViewControl from '../Services/ViewControl/ViewControl';
import TranformerMode from './Components/TranformerMode/TranformerMode';

/** stores */
import { useMyTvStore } from '../../stores/useMyTvStore';
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок «Телевидение 2.0»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=711860596
 */
const Television: FC = (): JSX.Element => {
  const {
    tvStore: { idsAddedPacketsToTransformer, idsAddedChannelsToTransformer },
  } = useRootStore();
  const {
    augmentedChannelsByGroups,
    seriesCode,
    baseSlug,
    transformerSlug,
    filteredPackages: packages,
    cardState,
    searchMode,
    setSearchMode,
    setIsEditTransformer,
    channelsList,
    setIsDesktop940,
  } = useMyTvStore();

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });

  /** Отфильтрованный по формату список каналов */
  const [formatFilteredChannelList, setFormatFilteredChannelList] = useState<
    SubjectWithChannels[]
  >([]);

  /** Отфильтрованный по checkbox "Входят в стоимость продукта" список каналов */
  const [
    checkboxFilteredChannelList,
    setCheckboxFilteredChannelList,
  ] = useState<SubjectWithChannels[]>([]);

  /** Отфильтрованный по checkbox "Входят в стоимость продукта" список пакетов */
  const [checkboxFilteredPackages, setCheckboxFilteredPackages] = useState<
    ChannelPackage[]
  >(packages);

  /** Пакеты включённые в продукт */
  const [packagesIncludedInProduct, setPackagesIncludedInProduct] = useState<
    ChannelPackage[]
  >([]);

  /** Активный фильтр формата каналов */
  const [activeChannelFormat, setActiveChannelFormat] = useState<OptionProp>(
    channelFormats[0],
  );

  /** Индекс активного таба */
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  /** Чекбокс "Входит в стоимость продукта" */
  const [isIncludedInProduct, setIsIncludedInProduct] = useState<boolean>(
    false,
  );

  const tabs = [
    {
      value: isDesktop940 && 'Каналы',
      icon: !isDesktop940 && <Icons.ChannelsIcon white />,
      inactiveIcon: !isDesktop940 && <Icons.ChannelsIcon />,
    },
    {
      value: isDesktop940 && 'Пакеты',
      icon: !isDesktop940 && <Icons.PackagesIcon white />,
      inactiveIcon: !isDesktop940 && <Icons.PackagesIcon />,
    },
  ];

  /** Фильтрует список каналов по формату */
  const filtersListOfChannelsByFormat = () => {
    switch (activeChannelFormat) {
      case channelFormats[1]:
        const filteredHDChannels = augmentedChannelsByGroups.map((category) => {
          return {
            ...category,
            channels: category.channels.filter(
              (channel) =>
                channel.channelDefinition === PictureClarityFormat.HD &&
                ((isIncludedInProduct && channel.includedToProduct) ||
                  !isIncludedInProduct),
            ),
          };
        });
        setFormatFilteredChannelList(filteredHDChannels);
        break;
      case channelFormats[2]:
        const filtered4KChannels = augmentedChannelsByGroups.map((category) => {
          return {
            ...category,
            channels: category.channels.filter(
              (channel) =>
                channel.channelDefinition === PictureClarityFormat.FourK &&
                ((isIncludedInProduct && channel.includedToProduct) ||
                  !isIncludedInProduct),
            ),
          };
        });
        setFormatFilteredChannelList(filtered4KChannels);
        break;
      default:
        setFormatFilteredChannelList(
          augmentedChannelsByGroups.map((category) => {
            return {
              ...category,
              channels: category.channels.filter(
                (channel) =>
                  (isIncludedInProduct && channel.includedToProduct) ||
                  !isIncludedInProduct,
              ),
            };
          }),
        );
    }
  };
  useEffect(() => {
    filtersListOfChannelsByFormat();
  }, [augmentedChannelsByGroups, activeChannelFormat, isIncludedInProduct]);

  useEffect(() => {
    setCheckboxFilteredChannelList(formatFilteredChannelList);
  }, [formatFilteredChannelList]);

  /** Все каналы по группам включённые в продукт */
  const allChannelsByGroupsIncludedInProduct = useMemo(() => {
    return formatFilteredChannelList.map((item) => {
      const channels = item.channels.filter((canal) => canal.includedToProduct);
      return {
        ...item,
        channels,
      };
    });
  }, [formatFilteredChannelList]);

  /** Изменение чекбокса */
  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsIncludedInProduct(e.target.checked);
    setCheckboxFilteredChannelList(
      e.target.checked
        ? allChannelsByGroupsIncludedInProduct
        : formatFilteredChannelList,
    );
    setCheckboxFilteredPackages(
      e.target.checked ? packagesIncludedInProduct : packages,
    );
  };

  /* Возвращает компонент при переключении табов */
  const getTabContent = (tabIndex: number): JSX.Element => {
    switch (tabIndex) {
      case 0:
        return (
          <ChannelsTab
            formatFilteredChannelList={checkboxFilteredChannelList}
            allChannelsIncludedInProduct={allChannelsIncludedInProduct}
          />
        );
      default:
        return (
          <PackagesTab
            tvChannelPackages={checkboxFilteredPackages}
            packagesIncludedInProduct={packagesIncludedInProduct}
          />
        );
    }
  };

  /** Все каналы включённые в продукт */
  const allChannelsIncludedInProduct = useMemo(() => {
    return channelsList.filter((item) => item.includedToProduct);
  }, [channelsList]);

  /** Каналы вне пакетов включённые в продукт */
  const [channelsIncludedInProduct, setChannelsIncludedInProduct] = useState<
    ChannelPackage[]
  >([]);

  /** Пакеты и каналы вне пакетов включённые в продукт */
  const getPackagesAndChannelsIncludedInProduct = () => {
    const arr = new Set();
    allChannelsIncludedInProduct.forEach((item) => {
      item.availablePackets.forEach((pack) => {
        if (pack.price === 0) {
          arr.add(pack.name);
        }
      });
    });
    /** Пакеты включённые в продукт */
    const packs = packages
      .filter((item) => arr.has(item.channelPackName))
      .filter((item) => item.price === null);
    setPackagesIncludedInProduct(packs);
    packs.forEach((item) => {
      if (arr.has(item.channelPackName)) arr.delete(item.channelPackName);
    });
    /** Каналы вне пакетов включённые в продукт */
    const channelsOutsidePackages = channelsList.filter((item) => {
      return arr.has(item.name);
    });
    setChannelsIncludedInProduct(channelsOutsidePackages);
  };

  useEffect(() => {
    getPackagesAndChannelsIncludedInProduct();
  }, [allChannelsIncludedInProduct, packages, channelsList]);

  /** Switcher */
  const isTransformer = useMemo(() => Boolean(baseSlug), []);

  useEffect(() => {
    const matcher = new RegExp(`${TELEVISION_ID}`, 'g');
    if (matcher.test(window.location.href)) scrollToBlockById(TELEVISION_ID);
  }, []);

  /** Выходим из режима редактирования если все убрали из трансформера */
  useEffect(() => {
    if (
      !idsAddedPacketsToTransformer?.length &&
      !idsAddedChannelsToTransformer?.length
    ) {
      setIsEditTransformer(false);
    }
  }, [idsAddedPacketsToTransformer, idsAddedChannelsToTransformer]);

  /** Сброс скролла списков телеканалов и пакетов */
  const scrollBlock = useRef(null);
  useEffect(() => {
    if (scrollBlock.current) {
      scrollBlock.current.scrollTop = 0;
    }
  }, [activeTabIndex]);

  useEffect(() => {
    setIsDesktop940(isDesktop940);
  }, [isDesktop940]);

  return (
    <StyledTelevision id={TELEVISION_ID}>
      {searchMode ? (
        <Search
          packagesIncludedInProduct={packagesIncludedInProduct}
          allChannelsIncludedInProduct={allChannelsIncludedInProduct}
        />
      ) : (
        <>
          <div
            className={
              cardState === CardStates.MAIN ? '' : 'television__hidden'
            }
          >
            <div className="television__header">
              <div className="television__header__search">
                <H2>{isDesktop400 ? 'Сверхтелевидение' : 'СверхТВ'}</H2>
                {!isDesktop940 && (
                  <div
                    className="television__search-icon"
                    onClick={() => setSearchMode(true)}
                  >
                    <Icons.GreySearchIcon />
                  </div>
                )}
              </div>
              {(baseSlug || transformerSlug) && (
                <div className="television__header__switcher">
                  <Text lineHeight="20px">Базовый</Text>
                  <StyledLink
                    as={CordisLink}
                    href={transformerSlug ?? baseSlug}
                  >
                    <StyledSwitcher checked={isTransformer} notSwitch />
                  </StyledLink>
                  <Text lineHeight="20px">Трансформер</Text>
                </div>
              )}
            </div>
            {isDesktop940 && (
              <Text
                className="television__text"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Фантастическое качество изображения и объёмный звук, огромный
                выбор телеканалов для всей семьи
              </Text>
            )}
            {isTransformer ? (
              <TranformerMode
                channelsByGroups={formatFilteredChannelList}
                activeChannelFormat={activeChannelFormat}
                setActiveChannelFormat={setActiveChannelFormat}
              />
            ) : (
              <>
                <div className="television__underhead">
                  <div className="television__underhead__tabs">
                    <Tabs
                      valueWithIcons={tabs}
                      onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
                      activeTabIndex={activeTabIndex}
                    />
                  </div>
                  {seriesCode ? (
                    <Checkbox
                      className="television__underhead__checkbox"
                      checked={isIncludedInProduct}
                      onChange={onChangeCheckbox}
                    >
                      Входят в стоимость продукта
                    </Checkbox>
                  ) : (
                    <div className="television__underhead__checkbox" />
                  )}
                  {isDesktop940 && (
                    <div className="television__underhead__select">
                      {activeTabIndex === 0 && (
                        <Select
                          placeholder="Выберите цвет радуги"
                          onOptionClick={(option) =>
                            setActiveChannelFormat(option)
                          }
                          value={
                            activeChannelFormat ? activeChannelFormat.value : ''
                          }
                          outsideClickHelper={outsideClickHelper}
                          data={channelFormats}
                        />
                      )}
                    </div>
                  )}

                  {isDesktop940 && (
                    <div
                      className="television__search-icon"
                      onClick={() => setSearchMode(true)}
                    >
                      <Icons.GreySearchIcon />
                    </div>
                  )}
                </div>
                <div className="my-tv__tab-block" ref={scrollBlock}>
                  {getTabContent(activeTabIndex)}
                </div>
              </>
            )}
          </div>
          {cardState === CardStates.CHANNEL_DESCRIPTION && (
            <ChannelDescription
              // channels={augmentedChannelsByGroups}
              allChannelsIncludedInProduct={allChannelsIncludedInProduct}
              channelsIncludedInProduct={channelsIncludedInProduct}
              packagesIncludedInProduct={packagesIncludedInProduct}
              isTransformer={isTransformer}
            />
          )}
          {cardState === CardStates.PACKAGE_DESCRIPTION && (
            <PackageDescription
              packagesIncludedInProduct={packagesIncludedInProduct}
              allChannelsIncludedInProduct={allChannelsIncludedInProduct}
              isTransformer={isTransformer}
            />
          )}
          {cardState === CardStates.VIEW_CONTROL_SERVICE && <ViewControl />}
        </>
      )}
    </StyledTelevision>
  );
};

export default observer(Television);
