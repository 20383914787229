import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledConnectNumber = styled.div`
  h3 {
    margin-bottom: 0;
  }
  .connect {
    &__city-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 8px;
      }
      .select-overlay {
        width: 100% !important;
      }
    }

    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 24px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 32px;
      &__block {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        > span:first-of-type {
          margin-bottom: 4px;
        }
      }
      &__block:last-of-type {
        margin-bottom: 0;
        > span:first-of-type {
          margin-bottom: 8px;
        }
      }
    }

    &__agreement {
      > span:first-of-type {
        margin-bottom: 16px;
      }
    }

    &__mobile-select {
      height: 100vh;
      width: 100%;
      background: ${defaultTheme.colors.white};
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__tag-button {
        height: 52px;
      }

      button {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
  @media (min-width: ${desktop940}px) {
    .connect {
      &__city-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        > span:first-of-type {
          margin-bottom: 8px;
        }
        .select-overlay {
          width: 100%;
        }
      }

      &__yellow-block {
        background-color: ${defaultTheme.colors.backgroundWarning};
        padding: 16px;
        margin-bottom: 24px;
      }

      &__info {
        flex-direction: row;
        width: 528px;
        justify-content: space-between;
        &__block {
          > span:first-of-type {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
`;

export const StyledConnectNumberFooter = styled.div`
  button {
    width: 100%;
  }
  @media (min-width: ${desktop940}px) {
    button {
      width: fit-content;
    }
  }
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;

  > svg {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  button {
    width: fit-content;
  }

  .result {
    &__time {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }
    }
    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      width: 100%;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    h1 {
      font-size: 44px;
      line-height: 52px;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

export const StyledPrices = styled.div`
  display: flex;
  flex-wrap: wrap;

  h3 {
    font-size: 21px;
    line-height: 25px;
  }

  .price {
    margin-right: 48px;
    margin-bottom: 32px;
    &__tabs {
      span:first-of-type {
        line-height: 24px;
        margin-top: 8px;
      }
    }
  }
  @media (min-width: ${desktop940}px) {
    h3 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;
