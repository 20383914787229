import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { desktop500, desktop940 } from '~/components/Grid/constants';
import { MY_APPEALS_STATE } from '../Blocks/Templates/Pab2c/Help/interfaces';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledMyAppeals = styled.div`
  .appeals {
    &__block {
      margin-bottom: 32px;
      > span {
        display: block;
        margin-bottom: 24px;
      }

      &__cards {
        > div {
          margin-bottom: 24px;
        }
      }
    }
    &__no-hits {
      display: flex;
      flex-direction: column;

      > span:first-of-type {
        margin: 8px 0 12px;
      }
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledMyAppealsSP = styled.div<{ state: MY_APPEALS_STATE }>`
  ${({ state }) => {
    return css`
      .sidepage {
        &__header {
          font-size: 21px;
          line-height: 25px;
          font-weight: 500;
          letter-spacing: -0.03em;
        }
        &__content {
          > div {
            & > div {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
      }

      .result-icon {
        svg {
          margin-bottom: 24px;
        }
      }

      @media (min-width: ${desktop940}px) {
        .sidepage {
          &__header {
            font-size: 28px;
            line-height: 32px;
            font-weight: 600;
            letter-spacing: -0.05em;
          }
          &__content {
            margin-top: 18px !important;
            > div {
              & > div {
                ${state === MY_APPEALS_STATE.MAIN
                  ? 'flex-direction: column-reverse;'
                  : ''}
                ${state === MY_APPEALS_STATE.MAIN &&
                css`
                  .footer {
                    position: sticky;
                    top: 62px;
                    background-color: white;
                    padding: 40px 0 40px 0 !important;
                  }
                `}
              }
            }
          }
        }
      }
    `;
  }}
`;

export const StyledFooter = styled.div`
  .new-appeal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    &__header {
      margin-bottom: 16px;
      letter-spacing: -0.03em;
    }
    &__select {
      display: flex;
      flex-direction: column;
      width: 100%;
      > div {
        width: 100%;
        margin: 0 0 16px 0;
      }
      > button {
        width: 100%;
        min-width: fit-content;
        padding: 11px 39px;
        > span {
          letter-spacing: -0.03em;
        }
      }
      .select-overlay {
        width: 100% !important;
      }
    }
    &__text {
      margin: 16px 0 0;
      padding: 16px;
      background-color: ${defaultTheme.colors.lightBlue};
    }
    &__snoska {
      letter-spacing: -0.01em;
      margin: 16px auto 0;
      text-align: center;
    }
  }

  .textarea {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    > span:first-of-type {
      margin-bottom: 4px;
    }
    textarea {
      width: 100%;
      min-height: 102px;
    }
  }

  @media (min-width: ${desktop500}px) {
    .new-appeal {
      &__select {
        width: 300px;
        > div {
          width: 300px;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .new-appeal {
      align-items: start;
      &__select {
        display: flex;
        flex-direction: row;
        width: 300px;
        > div {
          width: 300px;
          margin: 0 16px 0 0;
        }
        > button {
          width: 154px;
        }
      }
    }

    button {
      width: fit-content;
    }
  }
`;

export const StyledSnoska = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .snoska {
    letter-spacing: -0.01em;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;

    button {
      width: auto;
      margin: 0 16px 0 0;
    }
  }
`;
