import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop940,
  desktop500,
} from '~/components/Grid/constants';

export const StyledNumberDeactivationSidePage = styled.div`
  .number {
    &__info-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > div {
        display: flex;
        flex-direction: column;
        margin: 0 0 16px 0;
        > span:first-of-type {
          margin-bottom: 4px;
        }
      }
      > div:last-of-type {
        margin: 0;
      }
    }

    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 32px;
    }

    &__agreement {
      > span:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: ${desktop500}px) {
    .number {
      &__info-block {
        flex-direction: row;
        > div {
          margin: 0 60px 0 0;
        }
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .number {
      &__info-block {
        flex-direction: column;
        margin-bottom: 16px;
        > div {
          margin: 0 0 16px 0;
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .number {
      &__info-block {
        flex-direction: row;
        > div {
          margin: 0 130px 0 0;
        }
      }
    }
  }
`;

export const StyledFooter = styled.div`
  button {
    width: 100%;
  }
  @media (min-width: ${desktop940}px) {
    button {
      width: fit-content;
    }
  }
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;

  > svg {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  .result {
    &__time {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      > span:first-of-type {
        margin-bottom: 4px;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    h1 {
      font-size: 44px;
      line-height: 52px;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;
