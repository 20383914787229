/* Библиотеки */
import style from '@emotion/styled';
/* Темы */
import { defaultTheme } from 'cordis-core-ui-planeta';

/* Стилизованная часть левой колонки строки с продуктом */
export const StyledLeftCol = style.div`
  ${() => `
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 35%;
      white-space: pre-line;
      > div:first-of-type {
        margin-bottom: 10px;
        div {
          margin: 0 18px 5px 0;
        }
      }
      h2 {
        margin: 0 0 5px 0;
        white-space: pre-line;
      }
      button {
        margin-top: 16px;
        max-width: 200px;
      }
      span + span {
        margin: 16px 0 0 0;
        @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
          display: none;
        }
      }
    `}
`;
/* Стилизованная часть правой колонки строки с продуктом */
export const StyledRightCol = style.div`
  ${() => `
      display: none;
      max-width: 400px;

      @media (min-width: ${defaultTheme.screenSizes.desktop940}px) {
        display: flex;
      }
    `}
`;
