import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledProductCard = styled.div<{ isTagsLength: boolean }>`
  ${({ isTagsLength }) => {
    return css`
      .product-card {
        &__block-wrapper {
          display: flex;
          flex-direction: column;
          padding: 24px 0;
          border-top: 1px solid ${defaultTheme.colors.disable};
        }

        &__left-side {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__header {
            margin-bottom: 16px;
          }
          &__tags {
            display: flex;
            margin-bottom: 4px;
            flex-wrap: wrap;
            gap: 8px;
            > div {
              display: flex;
              & > div {
                padding: 1px 8px;
              }
            }
          }
          &__product-name {
            margin-bottom: 8px;
          }
          &__marketing-group-name {
            margin-bottom: 16px;
          }

          &__price-block {
            h3 {
              margin: 4px 0 0;
            }
          }

          &__speed {
            margin-bottom: 16px;
          }
        }

        &__right-side {
          display: flex;
          flex-direction: column;
          width: 100%;

          &.info-block {
            min-height: auto;
            justify-content: space-between;
          }

          &__switcher {
            width: fit-content;
            margin-bottom: 16px;
          }

          &__status {
            margin-bottom: 2px;
          }

          &__suspend {
            cursor: pointer;
          }

          &__button-block {
            display: flex;
            flex-direction: column;

            > div {
              margin-bottom: 16px;
            }
            > span {
              margin-bottom: 16px;
            }
            > button {
              margin-bottom: 16px;
            }
          }

          &__button {
            width: 100%;
            height: 54px;
            &.prolongation {
              margin-bottom: 24px;
            }
          }

          &__change-button {
            width: 100%;
            height: 54px;
            margin: 8px 0 0;
          }

          &__history-button {
            cursor: pointer;
          }

          &__product-property {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            &__header {
              max-width: 200px;
              margin-right: 40px;
            }

            &__ip-list {
              display: flex;
              flex-direction: column;
              &__ip {
                width: 100%;
              }
            }
          }

          &__expiration-text {
            max-width: 300px;
            margin-bottom: 16px;
          }

          &__second-expiration-text {
            margin-bottom: 16px;
          }

          &__finance {
            margin: 16px 0 24px;
          }
        }
      }

      .popup {
        &__header {
          &__left-icon-block {
            &__icon {
              margin-right: 2px !important;
            }
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .product-card {
          &__block-wrapper {
            flex-direction: row;
          }

          &__left-side {
            width: 310px;

            &__button {
              width: auto;
            }
          }
          &__right-side {
            width: 466px;

            &__product-property {
              flex-direction: row;

              &__header {
                width: 200px;
              }

              &__ip-list {
                width: 230px;
              }
            }

            &__button {
              width: auto;
              &.prolongation {
                width: 243px;
                margin-bottom: 24px;
                > span {
                  letter-spacing: -0.03em;
                }
              }
            }

            &__expiration-text {
              margin-bottom: 24px;
            }

            &__second-expiration-text {
              margin-bottom: 24px;
            }

            &__finance {
              margin: 8px 0 0;
              cursor: pointer;
            }
          }
        }
      }
      .popup {
        &__container {
          > div:first-of-type {
            padding: 32px 32px 24px;
            h3 {
              font-size: 21px;
              font-weight: 500;
              line-height: 25px;
              letter-spacing: -0.03em;
              text-align: initial;
              padding-right: 35px;
            }
          }
          > div:nth-of-type(2) {
            padding: 0 32px 32px;
          }
        }

        &__header {
          &__left-icon-block {
            &__icon {
              margin-right: 24px !important;
            }
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .popup {
          &__container {
            > div:first-of-type {
              padding: 24px;

              > h3 {
                font-size: 28px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: -0.05em;
              }
            }
            > div:nth-of-type(2) {
              padding: 0 24px 32px;
            }
          }

          &__header {
            &__close {
              top: 24px;
            }
          }
        }
      }
    `;
  }}
`;
