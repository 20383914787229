import React, { useEffect, ReactNode, RefObject } from 'react';

interface ElementVisibilityObserverProps {
  onElementVisible: () => void;
  children: ReactNode;
  targetRef: RefObject<HTMLElement>;
  options?: IntersectionObserverInit;
}

/** Обёртка для отслеживания видимости компонента */
const VisibilityObserver: React.FC<ElementVisibilityObserverProps> = ({
  onElementVisible,
  children,
  targetRef,
  options,
}) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onElementVisible();
          }
        });
      },
      {
        // Область наблюдения (null - вся видимая область)
        root: null,
        // Отступ от границы области наблюдения
        rootMargin: '0px',
        // Порог видимости (0.5 означает, что половина элемента должна быть видимой)
        threshold: 0.5,
        ...options,
      },
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [onElementVisible, targetRef, options]);

  return <>{children}</>;
};

export default VisibilityObserver;
