/** библиотеки */
import { FC } from 'react';

/** компоненты библиотеки */
import { defaultTheme, H2, H3, Inset, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledBonusProgramm } from './BonusProgramm.style';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

const BonusProgramm: FC<AdvantagesContentProps> = ({ content }) => {
  const TITLE = 'Бонусная программа';
  const TEXT = 'Получайте выгоду, используя бонусную программу «Чатл»';
  const TEXT_WIDE = `Бонусная программа «Чатл»${'\u00A0\u2014'} простой и${'\u00A0'}выгодный способ получать дополнительные возможности, пользуясь услугами Планеты`;
  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledBonusProgramm>
        <H3 className="title">{TITLE}</H3>
        <H2 className="title__wide">{TITLE}</H2>
        <Text className="text__240" color={defaultTheme.colors.shadow}>
          {TEXT}
        </Text>
        <Inset className="text" color={defaultTheme.colors.shadow}>
          {TEXT}
        </Inset>
        <Inset className="text__wide" color={defaultTheme.colors.shadow}>
          {TEXT_WIDE}
        </Inset>
      </StyledBonusProgramm>
    </LinkWrapper>
  );
};

export default BonusProgramm;
