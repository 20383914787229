/** libraries */
import { FC, useEffect, useState } from 'react';
import { Loader, Text } from 'cordis-core-ui-planeta';
/** api */
import { getBasePricesItemsNames } from '~/api/apiPab2c';
/** styles */
import { StyledBasicPackage } from './styles';

interface BasicPackageProps {
  basicPackageTariffId: number;
}

/** Состав базового пакета */
const BasicPackage: FC<BasicPackageProps> = ({
  basicPackageTariffId,
}: BasicPackageProps): JSX.Element => {
  /** Загрузка */
  const [isLoading, setIsLoading] = useState<boolean>(true);
  /** Состав базового пакета */
  const [baseNames, setBaseNames] = useState<string[]>([]);
  /** Получает состав базового пакета */
  const getBaseNames = async () => {
    try {
      const res = await getBasePricesItemsNames(basicPackageTariffId);
      setBaseNames(res.map((item) => item.concat('.')));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBaseNames();
  }, []);

  if (isLoading) {
    return (
      <StyledBasicPackage>
        <div className="loader">
          <Loader />
        </div>
      </StyledBasicPackage>
    );
  }

  return (
    <StyledBasicPackage>
      {baseNames.length > 0 ? (
        baseNames.map((name) => {
          return (
            <Text key={name} lineHeight="24px">
              {name}
            </Text>
          );
        })
      ) : (
        <Text lineHeight="24px">Произошла ошибка. Повторите позже.</Text>
      )}
    </StyledBasicPackage>
  );
};

export default BasicPackage;
