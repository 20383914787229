/** libraries */
import styled from '@emotion/styled';

export const StyledVacationConnection = styled.div<{
  isSuccessConnection: boolean;
}>`
  ${({ isSuccessConnection }) => {
    return `
      margin-top: ${isSuccessConnection ? '-33px;' : '0;'}

      .connection {
        &__block {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          span:first-of-type {
            margin-bottom: 4px;
          }
        }

        &__success {
          &__header {
            display: block;
            margin: 26px 0 24px;
          }
        }

        &__agreement {
          > span:first-of-type {
            margin-bottom: 16px;
          }
        }
      }
    `;
  }}
`;
