import { ReactNode, useContext } from 'react';
import { MobXProviderContext, Provider, useLocalObservable } from 'mobx-react';
import { createDeviceListStore, IDeviceListStore } from './DeviceListStore';
import { DevicesFields } from '../../Devices/interfaces';
import { DeviceItemFilterType } from '../DeviceList.types';

interface DeviceProviderProps {
  list: DeviceItemFilterType[];
  allDevices: DevicesFields[];
  children: ReactNode;
}

export const DeviceListStoreProvider = ({
  list,
  allDevices,
  children,
}: DeviceProviderProps): JSX.Element => {
  const store = useLocalObservable(() =>
    createDeviceListStore(list, allDevices),
  );
  return <Provider DeviceListStore={store}>{children}</Provider>;
};

const useDeviceListStore = (): IDeviceListStore => {
  const { DeviceListStore } = useContext(MobXProviderContext);
  return DeviceListStore;
};

export default useDeviceListStore;
