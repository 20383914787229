/** библиотеки */
import React, { FC } from 'react';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';

/** стили */
import {
  StyledRowContent,
  StyledRowContentTitle,
  StyledRowContentBody,
} from './styles';

/** интерфейсы */
import { RowContentProps } from './interfaces';

const RowContent: FC<RowContentProps> = ({
  title,
  description,
  children,
  icon,
  removeLine,
}: RowContentProps) => {
  return (
    <StyledRowContent removeLine={removeLine}>
      {title && (
        <StyledRowContentTitle>
          {icon && icon}
          <Text lineHeight="24px">{title}</Text>
          {description && (
            <Text
              className="description"
              color={defaultTheme.colors.shadow}
              lineHeight="24px"
            >
              {description}
            </Text>
          )}
        </StyledRowContentTitle>
      )}
      <StyledRowContentBody>{children}</StyledRowContentBody>
    </StyledRowContent>
  );
};

export default RowContent;
