/** libraries */
import { FC, useState, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** interfaces */
import {
  SecondMenuContentProps,
  MenuProps,
  STYLE,
  isTwentyYearsStyle,
} from './interfaces';
/** components */
import Wrapper, {
  ClosedCheckmark,
  MenuText,
  MobileMenuWrapper,
  MobileWrapper,
  StyledImgWrapper,
  StyledMenu,
  StyledMobileImgWrapper,
  StyledMobileText,
  StyledText,
  MobileMenuItem,
  OpenedCheckmark,
  StyledOpenedMenu,
  StyledClosedMenu,
  StyledSuperPlanet,
  StyledMobileSuperPlanet,
} from './styles';
import {
  Carousel,
  defaultTheme,
  H1,
  H3,
  LeadingText,
  Text,
} from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { BECOME_A_CLIENT } from './constants';
/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';

/** Второе меню: https://ckb.itmh.ru/pages/viewpage.action?pageId=711867374 */
const SecondMenu: FC<SecondMenuContentProps> = ({
  content: {
    fields: { style, settings, menu },
  },
}) => {
  /** 20 лет */
  const isTwentyYear =
    style === STYLE.TWENTY_YEARS && isTwentyYearsStyle(settings);
  /** Сверх планета */
  const isSuperPlanet =
    style === STYLE.SUPER_PLANET && !isTwentyYearsStyle(settings);

  const router = useRouter();
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const openMenu = () => setShowMobileMenu(true);
  const closeMenu = () => setShowMobileMenu(false);

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  useEffect(() => {
    return () => closeMenu();
  }, [router]);

  const menuPositions = menu.slice(0, 5);
  const currentMenuSlug =
    menuPositions.find((item: MenuProps) => item.link === router?.asPath) ??
    menu[0];
  const othersMobileMenuItems = menuPositions.filter(
    (item: MenuProps) => item.link !== currentMenuSlug.link,
  );
  // Урл страницы
  const url = new URL(document.location.href);

  /** Меняет Url */
  const changeURL = (item): void => {
    router.push(
      `${url.origin}${url.pathname}${item.link}`,
      `${url.origin}${url.pathname}${item.link}`,
      {
        shallow: true,
      },
    );
  };

  const closedMenuToggle = (): JSX.Element => (
    <StyledClosedMenu onClick={openMenu}>
      <MobileMenuItem>
        <LeadingText color={defaultTheme.colors.black}>
          {currentMenuSlug.name}
        </LeadingText>
        <ClosedCheckmark />
      </MobileMenuItem>
    </StyledClosedMenu>
  );

  const openedMenuToggle = (): JSX.Element => (
    <StyledOpenedMenu>
      <MobileMenuItem onClick={closeMenu}>
        <LeadingText color={defaultTheme.colors.black}>
          {currentMenuSlug.name}
        </LeadingText>
        <OpenedCheckmark />
      </MobileMenuItem>
      {othersMobileMenuItems.map((item) => {
        return item.name === BECOME_A_CLIENT ? (
          <div key={item.name} onClick={toggleConnectionWizardVisible}>
            <MobileMenuItem>
              <LeadingText color={defaultTheme.colors.black}>
                {item.name}
              </LeadingText>
            </MobileMenuItem>
          </div>
        ) : (
          <MobileMenuItem key={item.name}>
            <LinkWrapper href={item.link}>
              <LeadingText color={defaultTheme.colors.black}>
                {item.name}
              </LeadingText>
            </LinkWrapper>
          </MobileMenuItem>
        );
      })}
    </StyledOpenedMenu>
  );

  // Мобильная версия
  if (!isDesktop940) {
    return (
      <MobileWrapper
        styleType={style}
        backgroundColor={settings.backgroundColor}
      >
        {isTwentyYear && (
          <>
            <StyledMobileImgWrapper>
              <Image
                loader={imageLoader}
                src={`${process.env.STATIC_SERVER}/${settings.imgLink}`}
                alt="second menu image"
                layout="fill"
                objectFit="contain"
                quality={100}
              />
            </StyledMobileImgWrapper>
            <StyledMobileText textColor={settings.textColor}>
              <H3
                dangerouslySetInnerHTML={{
                  __html: settings.headerText,
                }}
              />
              <Text
                dangerouslySetInnerHTML={{
                  __html: settings.mainText,
                }}
              />
            </StyledMobileText>
          </>
        )}
        {isSuperPlanet && (
          <StyledMobileSuperPlanet
            backgroundImgLink={`${process.env.STATIC_SERVER}/${settings.mobileBackgroundImgLink}`}
          >
            <div>
              <Carousel
                interval={2000}
                duration={300}
                axis="y"
                auto
                loop
                isNotSwipe
              >
                {settings.animationWords}
              </Carousel>
            </div>
          </StyledMobileSuperPlanet>
        )}
        <MobileMenuWrapper>
          {!showMobileMenu ? closedMenuToggle() : openedMenuToggle()}
        </MobileMenuWrapper>
      </MobileWrapper>
    );
  }

  return (
    <Wrapper styleType={style} backgroundColor={settings.backgroundColor}>
      {isTwentyYear && (
        <>
          <StyledText textColor={settings.textColor}>
            <H1
              dangerouslySetInnerHTML={{
                __html: settings.headerText,
              }}
            />
            <H3 dangerouslySetInnerHTML={{ __html: settings.mainText }} />
          </StyledText>
          <StyledImgWrapper>
            <Image
              loader={imageLoader}
              src={`${process.env.STATIC_SERVER}/${settings.imgLink}`}
              alt="second menu image"
              layout="fill"
              objectFit="contain"
              quality={100}
            />
          </StyledImgWrapper>
        </>
      )}
      {isSuperPlanet && (
        <StyledSuperPlanet
          backgroundImgLink={`${process.env.STATIC_SERVER}/${settings.backgroundImgLink}`}
        >
          <div>
            <Carousel
              interval={2000}
              duration={300}
              axis="y"
              auto
              loop
              isNotSwipe
            >
              {settings.animationWords}
            </Carousel>
          </div>
        </StyledSuperPlanet>
      )}
      <StyledMenu>
        {menuPositions.map((item) => {
          return item.name === BECOME_A_CLIENT ? (
            <div key={item.name} onClick={toggleConnectionWizardVisible}>
              <MenuText as={LeadingText} color={defaultTheme.colors.black}>
                {item.name}
              </MenuText>
            </div>
          ) : (
            <div key={item.name} onClick={() => changeURL(item)}>
              <MenuText as={LeadingText} color={defaultTheme.colors.black}>
                {item.name}
              </MenuText>
            </div>
          );
        })}
      </StyledMenu>
    </Wrapper>
  );
};

export default observer(SecondMenu);
