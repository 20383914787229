/** libraries */
import { types, Instance } from 'mobx-state-tree';
/** interfaces */
import { ChangePasswordModel } from './ChangePasswordModel';
import { PinModel } from './PinModel';
import { EmailConfirmModel } from './EmailConfirmModel';
import { AllowAccessWithoutPasswordModel } from './AllowAccessWithoutPasswordModel';

const SettingsModel = types.model({
  emailConfirmStore: EmailConfirmModel,
  changePasswordStore: ChangePasswordModel,
  pinStore: PinModel,
  allowAccessWithoutPasswordStore: AllowAccessWithoutPasswordModel,
});

export default SettingsModel;

export type ISettingsModel = Instance<typeof SettingsModel>;
