import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';

export const StyledStatistics = styled.div`
  margin-top: -16px;
  .statistics {
    &__select {
      display: flex;
      flex-direction: column;
      margin: 0 0 24px;

      > div {
        margin: 0 0 16px;
        & > div {
          display: block;
        }
      }

      .select-wrapper,
      .select-overlay {
        width: 100%;
      }
    }

    &__mobile-select {
      height: 85vh;
      position: relative;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__tag-button {
        height: 52px;
      }

      input {
        &:disabled ~ .tagButton__text {
          color: ${defaultTheme.colors.lightGrey};
        }
      }

      button {
        width: 100%;
        position: absolute;
        bottom: 32px;
      }
    }

    &__block {
      &__main-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${defaultTheme.colors.line};
        padding-bottom: 12px;
        margin-bottom: 12px;
        > span:first-of-type {
          margin-right: 8px;
        }
      }
      &__main-line:last-of-type {
        border-bottom: none;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 32px;
        > span {
          margin-top: 8px;
        }
        &__icon {
          display: flex;
          align-items: center;
          > svg {
            margin-right: 4px;
          }
        }
        &__phone {
          font-weight: 500;
        }
        &__price {
          font-weight: 500;
        }
      }
      &__total-mobile {
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${defaultTheme.colors.line};
        padding-top: 24px;
        > span:first-of-type {
          margin-bottom: 8px;
        }
      }
    }
  }
  @media (min-width: ${desktop940}px) {
    margin-top: 0;
    .statistics {
      &__select {
        display: flex;
        flex-direction: row;

        > div {
          margin: 0 16px 0 0;
        }

        .select-wrapper {
          width: 166.5px;
        }
        > button {
          width: 163px;
        }
      }

      &__block {
        max-height: 211px;
        overflow-x: hidden;
        padding-right: 8px;
        margin-right: -8px;
        &__line {
          display: grid;
          grid-template-columns: 164px 261px 61px;
          border-bottom: 1px solid ${defaultTheme.colors.line};
          padding-bottom: 12px;
          margin-bottom: 12px;
          &__date {
            text-align: center;
          }

          &__price {
            text-align: end;
          }
        }
      }

      &__block::-webkit-scrollbar {
        width: 2px;
      }

      &__block::-webkit-scrollbar-track {
        background-color: ${defaultTheme.colors.white};
        border-radius: 6px;
      }

      &__block::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: ${defaultTheme.colors.disable};
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .statistics {
      &__select {
        .select-wrapper {
          width: 226.5px;
        }
      }
      &__block {
        &__main-line {
        }
        &__line {
          display: grid;
          grid-template-columns: 164px 381px 61px;
        }
      }
    }
  }

  @media (min-width: ${desktop1280}px) {
    .statistics {
      &__select {
        .select-wrapper {
          width: 286.5px;
        }
      }
      &__block {
        &__main-line {
        }
        &__line {
          display: grid;
          grid-template-columns: 190px 501px 61px;
        }
      }
    }
  }
`;
