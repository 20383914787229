/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

/** стили */
import { StyledSidePageResult } from './style';

/** компоненты библиотеки */
import { Text, Icon, Icons, H2, H3 } from 'cordis-core-ui-planeta';

/** интерфейсы */
import { SidePageResultProps } from './types';

/** константы */
import { desktop1100 } from '~/components/Grid/constants';

/**
 * Блок результата. SidePage с содержимым ответа
 */
const SidePageResult: FC<SidePageResultProps> = ({
  result,
  children,
}: SidePageResultProps) => {
  /** Вычисление ширины экрана */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <StyledSidePageResult>
      {result.isResult && result.isCorrect && (
        <>
          <div className="result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? <H2>{result.title}</H2> : <H3>{result.title}</H3>}
          {result.text ? <Text>{result.text}</Text> : null}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <div className="result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{result.title}</H2> : <H3>{result.title}</H3>}
          {result.text ? <Text>{result.text}</Text> : null}
        </>
      )}

      {children}
    </StyledSidePageResult>
  );
};

export default SidePageResult;
