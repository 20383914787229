/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledFeedInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-left: 32px;

  .announcements__tag {
    width: fit-content;
    border: 2px solid ${defaultTheme.colors.gray};
    border-radius: 20px;
    padding: 8px 16px;
    margin-bottom: 13px;
    cursor: default;
  }
`;
