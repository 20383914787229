/** библиотеки */
import React, { FC } from 'react';

/** интерфейсы */
import { OptionBlockProps } from '~/components/Blocks/Templates/Pab2c/MyTV/Components/common/OptionBlock/interfaces';

/** компоненты библиотеки */
import { Text, defaultTheme, H3 } from 'cordis-core-ui-planeta';

/** стили */
import { StyledOptionBlock, StyledOptionBlockValue } from './styles';

const OptionBlock: FC<OptionBlockProps> = ({
  title,
  body,
}: OptionBlockProps) => {
  return (
    <StyledOptionBlock>
      <Text color={defaultTheme.colors.shadow} lineHeight="24px">
        {title}
      </Text>
      <StyledOptionBlockValue>
        <H3 color={defaultTheme.colors.black}>{body}</H3>
      </StyledOptionBlockValue>
    </StyledOptionBlock>
  );
};

export default OptionBlock;
