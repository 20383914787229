/** libraries */
import { FC } from 'react';
import { VKShareButton, OKShareButton } from 'react-share';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, Icons, LeadingText, Text } from 'cordis-core-ui-planeta';

/** constants */
import { SOCIAL_DATA } from '../config';
import { desktop940 } from '~/components/Grid/constants';

/** styles */
import { ShareWrapper } from '../styles';

/** Шаринг в социальных сетях */
const Share: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  return (
    <ShareWrapper>
      {isDesktop940 ? (
        <LeadingText className="share__text" color={defaultTheme.colors.black}>
          Поделиться с&nbsp;друзьями
        </LeadingText>
      ) : (
        <Text className="share__text" lineHeight="24px">
          Поделиться с&nbsp;друзьями
        </Text>
      )}
      <div className="share__icons">
        <VKShareButton
          className="share__icon-wrapper"
          url={SOCIAL_DATA.shareUrl}
          title={`${SOCIAL_DATA.title} ${SOCIAL_DATA.tag}`}
          description={SOCIAL_DATA.tag}
          image={SOCIAL_DATA.imgUrl}
          noparse
        >
          <Icons.VKIcon />
        </VKShareButton>
        <OKShareButton
          className="share__icon-wrapper share__ok"
          url={SOCIAL_DATA.shareUrl}
          title={SOCIAL_DATA.title}
          description={SOCIAL_DATA.tag}
          image={SOCIAL_DATA.imgUrl}
        >
          <Icons.ClassmatesIcon />
        </OKShareButton>
      </div>
    </ShareWrapper>
  );
};

export default Share;
