/** libraries */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
/** interfaces */
import { ProductSwitcherTemplate } from './interfaces';
/** constants */
import { ALL_TAG } from './constants';
import BigProductSwitcher from './Components/BigProductSwitcher/BigProductSwitcher';
import MiniProductSwitcher from './Components/MiniProductSwitcher/MiniProductSwitcher';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useProductSwitcherWizardStore from '../../Shared/ProductSwitcherWizard/store/useProductSwitcherWizardStore';
import useProductSwitcherStore from './store/useProductSwitcherStore';

const Main: FC = () => {
  const {
    authStore: { isAuth },
    cityStore: { city },
    summaryDataStore: { seriesCode },
    allowedTariffStore: { allowedTariff },
    connectionTariffStore: { connectTariff },
  } = useRootStore();
  const {
    sample,
    marketingGroupsProducts,
    tariffs,
    defineTariffInfo,
    getMarketingGroups,
    tagsStore: { tags, setActiveProductTagCode, defaultTag },
  } = useProductSwitcherStore();
  const { resetProductSwitcherWizardStore } = useProductSwitcherWizardStore();

  // Урл страницы
  const url = new URL(document.location.href);

  useEffect(() => {
    defineTariffInfo(isAuth, allowedTariff);
  }, [allowedTariff.length, isAuth, marketingGroupsProducts, seriesCode]);

  useEffect(() => {
    if (city) getMarketingGroups(city.id, isAuth);
  }, [city.id, isAuth, sample]);

  useEffect(() => {
    if (!tags?.length) return;
    const initial = tariffs.length && defaultTag ? defaultTag : ALL_TAG;
    const urlTag = tags.find((item) => `#${item.tag}` === url.hash);
    setActiveProductTagCode(urlTag?.code ?? initial?.code);
  }, [defaultTag, tariffs, tags, isAuth, url.hash]);

  useEffect(() => {
    if (!connectTariff) {
      resetProductSwitcherWizardStore();
    }
  }, [connectTariff]);

  return sample === ProductSwitcherTemplate.MINI ? (
    <MiniProductSwitcher />
  ) : (
    <BigProductSwitcher />
  );
};

export default observer(Main);
