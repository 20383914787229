/** Состояние карточки */
export enum PRODUCT_CARD_STATE {
  MAIN,
  /** История смены продукта */
  HISTORY,
  /** Состав базового продукта */
  BASIC_PACKAGE,
  /** Превышение лимита трансформации */
  TRANSFORM_LIMIT_EXCEEDED,
  /** Финансовые расчёты */
  FINANCE,
  /** Ежедневные списания */
  DAILY_WRITE_OFFS,
  /** Чеки поступления денежных средств */
  RECEIPTS,
}
