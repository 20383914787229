import { Dispatch, SetStateAction } from 'react';

export interface MainConfig {
  name: ConfigTypes;
  header: string;
  content: string;
  mainButton: string;
  secondaryButton?: string;
  link?: {
    name: string;
    href?: string;
  };
}

export interface ResultConfig {
  name: ConfigTypes;
  type: 'success' | 'failure';
  header: string;
  content: string;
  link: {
    name: string;
    href?: string;
  };
}
export interface MainProps {
  activeConfig: MainConfig;
  configCreator: (configType: ConfigTypes) => void;
  setGameParams: Dispatch<SetStateAction<GameProps>>;
  gameParams: GameProps;
}

export interface ResultProps {
  activeConfig: ResultConfig;
  redirectUri: string;
}

export interface GameProps {
  redirectUri: string;
}

export enum ConfigTypes {
  /** Блок неавторизованного клиента */
  UNAUTHORIZED_CONFIG = 'UnauthorizedConfig',
  /** Блок подтверждения */
  APPROVE_CONFIG = 'ApproveConfig',
  /** Подтверждение получено */
  SUCCESS_CONFIG = 'SuccessConfig',
  /** Подтверждение не получено */
  CANCEL_CONFIG = 'CancelConfig',
  /** Договор не обслуживается */
  SUSPENDED_CONFIG = 'SuspendedConfig',
  /** Договор уже привязан */
  LINKED_CONFIG = 'LinkedConfig',
  /** Экран ошибки */
  FAILURE_CONFIG = 'FailureConfig',
}

/** type guards */
export function isMainConfig(
  object: ResultConfig | MainConfig,
): object is MainConfig {
  return typeof object === 'object' && 'mainButton' in object;
}

export function isResultConfig(
  object: ResultConfig | MainConfig,
): object is ResultConfig {
  return typeof object === 'object' && 'type' in object;
}
