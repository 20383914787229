/** библиотеки */
import styled from '@emotion/styled';

/** константы */
import {
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';
import { css } from '@emotion/react';

export const StyledContract = styled.div<{
  isTransformer: boolean;
  isSubscription: boolean;
}>`
  ${({ isTransformer, isSubscription }) => {
    return css`
      height: 100%;
      position: relative;
      padding: 32px;
      background-color: ${defaultTheme.colors.white};

      .contract {
        &__header {
          h3 {
            max-width: 360px;
            margin-bottom: 16px;
          }

          &__logout-block {
            margin-bottom: 16px;

            &__contractName {
              white-space: nowrap;
              margin-right: 24px;
            }
          }
        }

        &__info-block {
          display: flex;
          flex-direction: column;

          &__balance {
            margin-bottom: 32px;

            &__top-up-button {
              margin: 16px 0;
              width: 100%;
            }

            &__auto-payment-button {
              width: 100%;
            }

            .header {
              margin-bottom: 4px;
            }
          }

          &__chatly {
            margin-bottom: 32px;

            &__number {
              margin: 4px 0 16px;
            }

            &__reservation-button {
              width: 100%;
            }

            &__reservation-link-disabled {
              color: ${defaultTheme.colors.disable};
              cursor: default;
              text-decoration: none;
              pointer-events: none;
            }

            &__reservation-info-snoska {
              width: 100px;
              margin-top: 4px;
            }

            &__reservation-info-text {
              margin-top: 16px;
            }
          }

          &__deferred-payment {
            min-width: 168px;
            margin-bottom: 32px;

            &__hours {
              margin: 4px 0 8px;
            }

            &__description {
              word-break: normal;
            }

            &__button {
              width: 100%;
            }
          }
        }

        &__product-block {
          &__product-card {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 5px;
            cursor: default;

            &__name {
              background-color: ${defaultTheme.colors.planeta};
              width: 100%;
              height: auto;
              padding: 12px 12px;

              &__seriesName {
                display: block;
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &__info {
              display: flex;
              justify-content: space-between;
              background-color: ${defaultTheme.colors.light};
              width: 100%;
              padding: 12px;

              &__connection-incomplete {
                width: 105px;
                display: flex;
                align-items: center;
              }

              &__suspended-by-operator-or-client {
                width: 124px;
              }

              &__out-of-service {
                width: 138px;
                display: flex;
                align-items: center;
              }

              &__speed-block {
                display: flex;
                flex-direction: column;

                span:first-of-type {
                  margin-bottom: 9px;
                }
              }

              &__price-block {
                display: none;
                h3 {
                  margin-bottom: 0;
                  margin-top: 6px;
                  font-size: 21px;
                  line-height: 25px;
                  font-weight: 500;
                }
              }

              &__icon {
                cursor: pointer;
              }
            }
          }
        }

        &__loader {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }

        &__update-icon {
          height: 45px;
          margin: 11px 0 14px;
          cursor: pointer;
          &__balance {
            height: 45px;
            margin: 11px 0 12px;
            cursor: pointer;
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        height: 400px;

        .contract {
          &__header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            h3 {
              order: 1;
            }

            &__logout-block {
              display: flex;
              align-items: baseline;
              order: 2;
            }
          }

          &__info-block {
            flex-direction: row;

            &__balance {
              margin-right: 32px;
              margin-bottom: 0;

              &__price-tag {
                min-height: 81px;
              }

              .colorContainer {
                .subscription {
                  flex: 10;
                  margin-left: ${isSubscription ? '0' : '16px'};
                }
                h3,
                h2 {
                  margin-right: ${isSubscription ? '16px' : '0'};
                }
                width: 100%;
              }
            }

            &__chatly {
              margin-right: 32px;
              margin-bottom: 0;
              display: flex;
              flex-direction: column;

              &__number {
                margin: 11px 0 14px;
              }
            }

            &__deferred-payment {
              margin-bottom: 0;

              &__hours {
                margin: 11px 0 14px;
              }

              &__description {
                word-break: break-all;
              }
            }
          }

          &__product-block {
            position: absolute;
            left: 32px;
            right: 32px;
            bottom: 32px;

            &__product-card {
              flex-direction: row;

              &__name {
                width: 170px;
              }

              &__info {
                width: 358px;
                height: auto;
                padding: 8px 9px 12px ${isTransformer ? '10px' : '33px'};

                &__price-block {
                  display: block;
                  margin-top: 5px;

                  ${isTransformer
                    ? `h3 {
                      margin-top: 10px;
                    }`
                    : ''}
                }

                &__speed-block {
                  > span:first-of-type {
                    margin: 6px 0 4px;
                  }
                }
              }
            }
          }
        }
      }

      @media (min-width: ${desktop1100}px) {
        .contract {
          &__info-block {
            &__deferred-payment {
              &__description {
                word-break: normal;
              }
            }
          }
          &__product-block {
            &__product-card {
              &__name {
                width: 247px;
              }

              &__info {
                width: 401px;
                padding: 8px 9px 12px 33px;
              }
            }
          }
        }
      }

      @media (min-width: ${desktop1280}px) {
        .contract {
          &__info-block {
            &__balance {
              &__price-tag {
                min-height: 94px;
              }
            }
          }
          &__product-block {
            &__product-card {
              &__name {
                width: 292px;
              }

              &__info {
                width: 476px;
              }
            }
          }
        }
      }
    `;
  }}
`;
