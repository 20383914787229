/** библиотеки */
import styled from '@emotion/styled';

export const StyledSoftlinePrice = styled.div`
  .softline-price {
    &__auth-price-block {
      > span:first-of-type {
        margin-right: 8px;
      }
    }

    &__info-text {
      margin-top: 4px;
    }

    &__tabs-price {
      display: block;
      margin: 8px 0 0;
    }
  }
`;
