/** libraries */
import { FC } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
/** components */
import {
  defaultTheme,
  Text,
  OrderedList,
  ListItem,
  Link,
  LeadingText,
} from 'cordis-core-ui-planeta';
/** utils */
import { getHeaderPhone } from '~/components/Header/utils';
import { formatNumber } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
`;

const StyledLeadingText = styled(LeadingText)`
  font-size: 16px;
  font-weight: 700;
`;

const StyledOrderList = styled(OrderedList)`
  color: ${defaultTheme.colors.white};

  li {
    &:first-of-type {
      margin-top: 16px;
    }

    &:before {
      padding-right: 5px;
    }

    margin-bottom: 16px;
  }
`;

const Stray: FC = () => {
  const {
    cityStore: { city, contactPhones },
    pab2cBalanceStore: { moneyRecommended },
  } = useRootStore();
  const headerPhone = getHeaderPhone(contactPhones, city);
  const planetaPhone = headerPhone.replace(/\D/g, '');

  return (
    <Root>
      <Text color={defaultTheme.colors.white}>
        Чтобы возобновить обслуживание:
      </Text>
      <StyledOrderList>
        <ListItem>
          Позвоните в отдел консультационной поддержки по тел.{' '}
          <Link href={`tel:${planetaPhone}`}>
            <StyledLeadingText color={defaultTheme.colors.white}>
              {headerPhone}
            </StyledLeadingText>
          </Link>
        </ListItem>
        <ListItem>
          Пополните счёт на&nbsp;сумму не&nbsp;менее{' '}
          <StyledLeadingText color={defaultTheme.colors.white}>
            {formatNumber(Math.abs(moneyRecommended))} ₽
          </StyledLeadingText>
        </ListItem>
        <ListItem>
          Обслуживание будет возобновлено в&nbsp;течение{' '}
          <StyledLeadingText color={defaultTheme.colors.white}>
            20&nbsp;дней
          </StyledLeadingText>
        </ListItem>
      </StyledOrderList>
    </Root>
  );
};

export default observer(Stray);
