/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop240,
  desktop280,
  desktop340,
  desktop520,
  desktop880,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';

import { IMAGES } from '../constants';

export const StyledTimeshift = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  padding: 32px;
  padding-top: 28px;
  background-color: ${defaultTheme.colors.stateGray};

  .timeshiftShadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.62;
  }

  .timeshiftContent {
    position: relative;
  }

  .timeshiftContent__bottom {
    position: relative;
    max-width: 121px;
  }

  .title__wide {
    margin-bottom: 24px;
    max-width: 303px;
  }

  .title {
    margin-bottom: 16px;
    max-width: 183px;
  }

  .text,
  .title__wide {
    display: none;
  }

  .text__240 {
    max-width: 157px;
  }

  background: ${defaultTheme.colors.stateGray} no-repeat;
  background-image: url(${IMAGES.timeshiftBg240});

  @media (min-width: ${desktop240}px) {
    .timeshiftShadow {
      display: none;
    }
    background-image: url(${IMAGES.timeshiftBg880});
    background-position: bottom right -200px;
  }

  @media (min-width: ${desktop280}px) {
    .title {
      margin-bottom: 18px;
    }
  }

  @media (min-width: ${desktop340}px) {
    .title {
      margin-bottom: 24px;
    }
  }

  @media (min-width: ${desktop520}px) {
    .title,
    .text__240 {
      display: none;
    }

    .title__wide {
      display: block;
    }

    .text {
      display: inline-block;
      max-width: 317px;
    }
    background-position: bottom;
  }

  @media (min-width: ${desktop880 + 1}px) {
    background-position: center;
    background-size: cover;
  }

  @media (min-width: ${desktop940}px) {
    .title__wide {
      display: none;
    }

    .title,
    .timeshiftShadow {
      display: block;
    }
    background-image: url(${IMAGES.timeshiftBg280});
    background-position: bottom;
    background-size: auto;
  }

  @media (min-width: ${desktop1100}px) {
    .title {
      margin-bottom: 18px;
    }
    background-image: url(${IMAGES.timeshiftBg340});
  }

  @media (min-width: ${desktop1280}px) {
    .title {
      margin-bottom: 24px;
    }
    background-image: url(${IMAGES.timeshiftBg400});
  }
`;
