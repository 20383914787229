/** libraries */
import { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** interfaces */
import {
  FormValidationType,
  ResultsInfoProps,
} from '~/components/Blocks/Templates/ChatlesExp/interfaces';
/** components */
import Wrapper, {
  CoinWrapper,
  Form,
  MainHeader,
  MainText,
  MobileCoins,
  MobileCoinWrapper,
} from './styles';
import {
  defaultTheme,
  H1,
  H3,
  LeadingText,
  Loader,
  Text,
} from 'cordis-core-ui-planeta';
import { IsAuth, NotAuth, Result } from './Components';
import Image from 'next/image';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** config */
import IMAGES, { ERRORS } from './config';
/** api */
import {
  getExperienceInfo,
  getExperienceParticipate,
  setExperienceParticipate,
} from '~/api/api';
/** utils */
import { getResultsInfo } from '~/components/Blocks/Templates/ChatlesExp/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/** Блок "Чатлы за стаж": https://ckb.itmh.ru/pages/viewpage.action?pageId=717033554 */
const ChatlesExp: FC = () => {
  const {
    authStore: { isAuth, isLoadingAuth },
  } = useRootStore();
  const [formState, setFormState] = useState<FormValidationType>(null);
  const [resultsInfo, setResultsInfo] = useState<ResultsInfoProps>(null);
  const [isLoadingExpData, setIsLoadingExpData] = useState<boolean>(true);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const imageLoader = ({ src, width: imgWidth, quality }) => {
    return `${src}?w=${imgWidth}&q=${quality || 75}`;
  };

  const coins = useMemo(() => {
    const data = isDesktop ? IMAGES.coins.desktop : IMAGES.coins.mobile;
    return Object.keys(data).map((k) => ({ coin: k, ...data[k] }));
  }, [isDesktop]);

  const octagon = useMemo(() => {
    return isDesktop ? IMAGES.octagons.octagon : IMAGES.octagons.octagonMobile;
  }, [isDesktop]);

  useEffect(() => {
    (async () => {
      if (!isAuth) {
        setResultsInfo(null);
        setIsLoadingExpData(false);
      }

      if (isAuth) {
        setIsLoadingExpData(true);

        try {
          const experience = await getExperienceParticipate();
          if (experience?.experienceYear)
            setResultsInfo(getResultsInfo(experience.experienceYear));
        } catch (e) {
          console.error(e);
          setFormState(ERRORS.COMMON);
        }

        setIsLoadingExpData(false);
      }
    })();
  }, [isAuth]);

  const getForm = () => {
    if (isLoadingAuth) return <Loader />;

    return isAuth ? (
      <IsAuth
        isLoadingButton={isLoadingButton}
        formState={formState}
        setFormState={setFormState}
      />
    ) : (
      <NotAuth />
    );
  };

  const onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formState !== 'valid') return;

    setIsLoadingButton(true);

    try {
      const experience = await getExperienceInfo();
      setResultsInfo(getResultsInfo(experience));
      await setExperienceParticipate();
    } catch (e) {
      console.error(e);
      setFormState(ERRORS.COMMON);
    }

    setIsLoadingButton(false);
  };

  return (
    <Wrapper>
      {!isDesktop && !resultsInfo && (
        <MobileCoins>
          {coins.map(({ path, width, height, coin }) => (
            <MobileCoinWrapper $coin={coin} key={coin}>
              <Image
                loader={imageLoader}
                src={path}
                alt="Бонусные рубли за годы доверия"
                width={width}
                height={height}
                quality={100}
              />
            </MobileCoinWrapper>
          ))}
        </MobileCoins>
      )}

      <MainHeader color={defaultTheme.colors.black} as={isDesktop ? H1 : H3}>
        Бонусные рубли <wbr />
        за&nbsp;годы доверия
      </MainHeader>
      <MainText
        color={defaultTheme.colors.black}
        as={isDesktop ? LeadingText : Text}
      >
        Дольше с&nbsp;Планетой&nbsp;&mdash; больше бонусов. <wbr />
        Выигрывай и&nbsp;используй <wbr />
        их&nbsp;для оплаты услуг!
      </MainText>

      {resultsInfo ? (
        <Result octagon={octagon} resultsInfo={resultsInfo} />
      ) : (
        <Form
          onSubmit={onFormSubmit}
          $isLoading={isLoadingAuth ?? isLoadingExpData}
          $isAuth={isAuth}
        >
          {getForm()}
        </Form>
      )}

      {isDesktop &&
        !resultsInfo &&
        coins.map(({ path, width, height, coin }) => (
          <CoinWrapper $coin={coin} key={coin}>
            <Image
              loader={imageLoader}
              src={path}
              alt="Бонусные рубли за годы доверия"
              width={width}
              height={height}
              quality={100}
            />
          </CoinWrapper>
        ))}
    </Wrapper>
  );
};

export default observer(ChatlesExp);
