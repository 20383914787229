/** библиотеки */
import * as React from 'react';

/** компоненты библиотеки */
import { Link, LinkStyleTypes } from 'cordis-core-ui-planeta';

/** типы */
import { LegalInfo } from '../Legal.types';

/** константы */
import { DOCUMENTS_LINK } from '~/constants/common';
import { SeriesCode } from '../Legal.const';

/** интерфейс компонента-шаблона HintText */
interface HintTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона HintText
 * @param {HintTextProps} content
 * @returns {React.FC}
 */
const HintText: React.FC<HintTextProps> = ({ content }: HintTextProps) => {
  // Скрываем шаблон по условию
  if (content && content.seriesCode === SeriesCode.SPECIAL) return null;
  /**
   * текст шаблона
   * @returns {JSX.Element}
   */
  const Text = (): JSX.Element => (
    <span>
      Стоимость подключения к продукту указана для Пояса обслуживания 0, для
      других поясов обслуживания стоимость подключения указана в{' '}
      <Link
        href={DOCUMENTS_LINK}
        target="_blank"
        styleType={LinkStyleTypes.SECONDARY}
      >
        Прейскуранте
      </Link>
      .
    </span>
  );

  return <p>{Text()}</p>;
};

export default HintText;
