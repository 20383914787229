/** библиотеки */
import { FC, useState } from 'react';
import { observer } from 'mobx-react';
/** стили */
import { StyledCashbackBottoms, StyledPromo } from './style';

/** утилиты */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber } from '~/utils/utils';

/** компоненты библиотеки */
import {
  Button,
  ButtonIconPosition,
  ButtonStyleTypes,
  Checkbox,
  defaultTheme,
  Icon,
  Icons,
  Text,
} from 'cordis-core-ui-planeta';

/** типы */
import { CASHBACK_TEXT, PromoProps } from './types';
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';

/** configs */
import { CASHBACK_CONFIG } from './config';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * SidePage промо-акций
 * @param {PromoProps} content контент для SidePage, приходит из Mongo
 * @param {PromoProps} payByCreditCard функция оплаты картой
 * @param {PromoProps} paymentViaSBP функция оплаты СБП
 * @param {PromoProps} contractName номер договора, введённый в поле
 * @param {PromoProps} amount сумма к оплате, введённая в поле
 * @param {PromoProps} paymentPromoType тип активной акции
 * @param {PromoProps} setAnswer функция для сохранения информации об участии в акции
 * @param {PromoProps} setIsOpenPromo изменяет флаг открытия данного сайдпейджа
 * @param {PromoProps} setIsLoading изменяет флаг загрузки кнопки "Оплатить"
 */
const Promo: FC<PromoProps> = ({
  content,
  payByCreditCard,
  paymentViaSBP,
  contractName,
  amount,
  paymentPromoType,
  setAnswer,
  setIsOpenPromo,
  setIsLoading,
  isCashbackBottoms,
  currentPaymentType,
}: PromoProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  /** Флаг checkbox */
  const [isChecked, setIsChecked]: HooksTyping<boolean> = useState<boolean>(
    false,
  );

  /** Клик по оплате через СБП */
  const onClickButton = (isSBP) => {
    setAnswer(
      contractName,
      amount,
      paymentPromoType,
      isSBP ? PAYMENT_TYPE.SBP : PAYMENT_TYPE.CARD_PAYMENT,
      isChecked,
    );
    setIsOpenPromo(false);
    setIsLoading(true);
    if (isSBP) {
      paymentViaSBP();
      return;
    }
    payByCreditCard();
  };

  const onClickCashbackButton = (newAmountValue) => {
    setAnswer(
      contractName,
      newAmountValue,
      paymentPromoType,
      currentPaymentType,
      isChecked,
    );
    setIsOpenPromo(false);
    setIsLoading(true);
    if (currentPaymentType === PAYMENT_TYPE.SBP) {
      paymentViaSBP(null, newAmountValue);
      return;
    }
    payByCreditCard(newAmountValue);
  };

  const getCashbackText = (type: CASHBACK_TEXT): string => {
    switch (type) {
      case CASHBACK_TEXT.DEFAULT:
        return `Оплатить ${formatNumber(amount)}\u0020\u20bd${
          amount < CASHBACK_CONFIG.low.amount ? '\n без кешбэка' : ''
        }`;
      case CASHBACK_TEXT.MAX:
        return `Оплатить ${formatNumber(
          CASHBACK_CONFIG.max.amount,
        )}\u0020\u20bd\n с кешбэком ${CASHBACK_CONFIG.max.cashback}%`;
      case CASHBACK_TEXT.MEDIUM:
        return `${formatNumber(
          CASHBACK_CONFIG.medium.amount,
        )}\u0020\u20bd\n с кешбэком ${CASHBACK_CONFIG.medium.cashback}%`;
      case CASHBACK_TEXT.LOW:
        return `${formatNumber(
          CASHBACK_CONFIG.low.amount,
        )}\u0020\u20bd\n с кешбэком ${CASHBACK_CONFIG.low.cashback}%`;
      default:
        return '';
    }
  };

  return (
    <StyledPromo>
      <Text className="promo__content">{parseHtml(content)}</Text>
      <div className="promo__checkbox">
        <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
          Не напоминать при следующей оплате
        </Checkbox>
      </div>
      {!isCashbackBottoms && (
        <>
          <Button
            className="promo__sbp-button"
            icon={<Icon icon={<Icons.BigSbpIcon />} />}
            iconPosition={ButtonIconPosition.RIGHT}
            background={defaultTheme.colors.haiti}
            border={`2px solid ${defaultTheme.colors.haiti}`}
            onClick={() => onClickButton(true)}
            disabled={isTemporaryTokenAuth}
          >
            Оплатить
          </Button>
          <Button
            className="promo__card-button"
            onClick={() => onClickButton(false)}
            styleType={ButtonStyleTypes.SECONDARY}
            disabled={isTemporaryTokenAuth}
          >
            Оплатить картой
          </Button>
        </>
      )}
      {isCashbackBottoms && (
        <StyledCashbackBottoms>
          <Button
            onClick={() => onClickCashbackButton(amount)}
            minWidth="168px"
            styleType={ButtonStyleTypes.SECONDARY}
            disabled={isTemporaryTokenAuth}
          >
            {getCashbackText(CASHBACK_TEXT.DEFAULT)}
          </Button>

          <Button
            onClick={() => onClickCashbackButton(CASHBACK_CONFIG.max.amount)}
            minWidth={`${CASHBACK_CONFIG.max.minWidth}px`}
            disabled={isTemporaryTokenAuth}
          >
            {getCashbackText(CASHBACK_TEXT.MAX)}
          </Button>

          {amount < CASHBACK_CONFIG.medium.amount && (
            <Button
              onClick={() =>
                onClickCashbackButton(CASHBACK_CONFIG.medium.amount)
              }
              minWidth={`${CASHBACK_CONFIG.medium.minWidth}px`}
              disabled={isTemporaryTokenAuth}
            >
              {getCashbackText(CASHBACK_TEXT.MEDIUM)}
            </Button>
          )}

          {amount < CASHBACK_CONFIG.low.amount && (
            <Button
              onClick={() => onClickCashbackButton(CASHBACK_CONFIG.low.amount)}
              minWidth={`${CASHBACK_CONFIG.low.minWidth}px`}
              disabled={isTemporaryTokenAuth}
            >
              {getCashbackText(CASHBACK_TEXT.LOW)}
            </Button>
          )}
        </StyledCashbackBottoms>
      )}
    </StyledPromo>
  );
};

export default observer(Promo);
