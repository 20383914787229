import styled from '@emotion/styled';
import { defaultTheme, Icon } from 'cordis-core-ui-planeta';

export const PlayerCircle = styled(Icon)`
  path {
    transition: fill 1s;
  }

  &:hover {
    path {
      fill: ${defaultTheme.colors.black};
    }
  }
`;

export default styled.div`
  position: relative;
  margin-top: 32px;

  ${PlayerCircle} {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 21px);
    left: calc(50% - 21px);
    z-index: 999;
  }
`;
