/* eslint-disable camelcase */
import { ActionType, SummaryFields } from '../Summary/Summary.types';
import { Block } from '~/interfaces/BlockInterface';
import { PRODUCT_CHANGE_ERRORS } from './constants';
import { DefaultImgs } from '../Device/Device.types';

/** свойства компонента */
export interface ProductSwitcherContentProps {
  content: ProductSwitcherProps;
}

/** контент блока */
export interface ProductSwitcherProps extends Block {
  fields: ProductSwitcherFields;
  defaultImgs: DefaultImgs[];
}

/** поля блока */
export interface ProductSwitcherFields {
  products: Products[];
  productsNotAvailableText: string;
  /** Массив продуктов для неавторизованного состояния */
  unionTariffNotAuth: SummaryFields[];
  sample: ProductSwitcherTemplate.BIG | ProductSwitcherTemplate.MINI;
}

export enum ProductSwitcherTemplate {
  BIG = 'bigSummary',
  MINI = 'miniSummary',
}

interface Products {
  seriesCode: string;
  productFeedLink?: string;
  action?: Action;
  cities?: number[];
  promoCodes?: string[];
}

interface Action {
  header: string;
  text: string;
  background: string;
  icon: string;
  iconSize: IconSize;
  color: string;
}

interface IconSize {
  width: number;
  height: number;
}

export interface MarketingGroups {
  id?: number;
  name: string;
  mem?: string;
  code: string;
  sort: number;
  marketingSeries?: MarketingSeries[];
  productList: TariffProps[];
}

export interface Tag {
  code: string;
  tag: string;
  name: string;
  sort: number;
  productsList: TariffProps[];
}

interface MarketingSeries {
  seriesId: number;
  seriesCode: string;
  seriesName: string;
  sort: number;
}

export interface TariffProps
  extends AllowedTariffProps,
    SummaryFields,
    ActionType {
  /* Признак сортировки из mongo */
  sort: number;
}

export interface AllowedTariffProps {
  tariffTo: TariffToProps;
}

export interface TariffToProps {
  linePriceOff: number;
  seriesCode: string;
  tariffId: number;
  tariffMigrationRefuseReason: string;
}

export type ProductChangeErrors = {
  [key in PRODUCT_CHANGE_ERRORS]: string;
};
