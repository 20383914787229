/** libraries */
import { FC, useEffect, useRef, useState } from 'react';
/** interfaces */
import { MiddleItemsProps } from '../interfaces';
/** components */
import Wrapper, { DateCircle, MiddleLine } from './style';
import { defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
import LeftSide from '~/components/Blocks/Templates/Timeline/MiddleRow/LeftSide';
import RightSide from '~/components/Blocks/Templates/Timeline/MiddleRow/RightSide';
import MainSide from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide';

/** Основной блок */
const MiddleRow: FC<MiddleItemsProps> = ({
  leftSide,
  rightSide,
  mainSide,
  date,
}: MiddleItemsProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  // Вынужденная мера для появления линии после мобильной вёрстки и её увеличения после загрузки контента
  useEffect(() => {
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(wrapperRef.current?.getBoundingClientRect().height);
    });
    resizeObserver.observe(wrapperRef.current);

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  if (mainSide >= 0) return <MainSide date={date} index={mainSide} />;

  return (
    <Wrapper ref={wrapperRef}>
      <LeftSide {...leftSide} />
      <MiddleLine $height={height}>
        <DateCircle>
          <LeadingText color={defaultTheme.colors.black}>{date}</LeadingText>
        </DateCircle>
      </MiddleLine>
      <RightSide {...rightSide} />
    </Wrapper>
  );
};

export default MiddleRow;
