/** libraires */
import { types, getParent } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { Tag } from '../interfaces';
import { BUSINESS_GROUP_CODE } from '../../Summary/Summary.types';
/** constants */
import { ALL_TAG, DEFAULT_TAG } from '../constants';
/** components */
import { IProductSwitcherStore } from './ProductSwitcherStore';

/**
 * Блок ProductSwitcher. Store для меню тегов
 */
export const TagsStoreModel = types
  .model('TagsStoreModel', {
    /** Состояние маркетинг тегов */
    activeProductTagCode: types.string,
    /** Вид блока с тегами в мобильной версии */
    isThemesOpen: types.boolean,
  })
  .views((self) => ({
    get tags() {
      const {
        tariffs,
        marketingGroupsData,
        extendedSummaryData,
      }: IProductSwitcherStore = getParent(self);

      if (!tariffs.length || !marketingGroupsData.length) {
        return [];
      }
      const marketingTags =
        extendedSummaryData?.marketingGroupCode === BUSINESS_GROUP_CODE
          ? []
          : marketingGroupsData.reduce((acc, group) => {
              const productsList = tariffs.reduce((filteredProducts, item) => {
                if (
                  +item.tariffId &&
                  group.marketingSeries.some(
                    (product) => product.seriesCode === item.seriesCode,
                  )
                ) {
                  filteredProducts.push(item);
                }
                return filteredProducts;
              }, []);

              if (productsList.length) {
                acc.push({
                  code: group.code,
                  tag: group.code,
                  name: group.name,
                  sort: group.sort,
                  productsList,
                });
              }

              return acc;
            }, []);

      /** Тег "Все" */
      const all = ALL_TAG;
      all.productsList = tariffs.filter((item) => +item.tariffId);
      marketingTags.push(all);

      const sortedMarketingTags: Tag[] = marketingTags.sort((a, b) => {
        if (!a.sort || a.sort < b.sort) return 1;
        return -1;
      });
      return toJS(sortedMarketingTags);
    },
  }))
  .views((self) => ({
    get defaultTag() {
      if (!self.tags.length) return null;
      return self.tags.find((item) => item.code === DEFAULT_TAG);
    },
    get activeProductTag() {
      if (!self.activeProductTagCode) return null;
      return self.tags.find((item) => item.code === self.activeProductTagCode);
    },
  }))
  .actions((self) => ({
    setActiveProductTagCode(code: string) {
      self.activeProductTagCode = code;
    },
    setIsThemesOpen(isOpen: boolean) {
      self.isThemesOpen = isOpen;
    },
  }));

export const createTagsStore = () =>
  TagsStoreModel.create({
    activeProductTagCode: '',
    isThemesOpen: false,
  });
