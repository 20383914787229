/** libraries */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
/** config */
import CONFIG, {
  MAIN_SIDE,
} from '~/components/Blocks/Templates/Timeline/MiddleRow/MainSide/config';
/** components */
import { MobileBall } from '~/components/Blocks/Templates/Timeline/MiddleRow/RightSide/style';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledDate = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 96px;
  user-select: none;
  text-align: center;
  z-index: 1;

  span {
    font-weight: 500;
    font-size: 21px;
    color: ${defaultTheme.colors.white};
  }

  @media (min-width: ${desktop940}px) {
    position: relative;
    margin: 2rem 0 2rem 0.5rem;
    top: 0;
  }
`;

export default styled.div<{ $index: MAIN_SIDE; $isMobile: boolean }>`
  position: relative;
  width: 100%;
  min-height: 536px;
  padding: 6rem 2rem 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-right-radius: 50px;
  &::before {
    content: '';
    position: absolute;
    top: 49px;
    background: ${defaultTheme.colors.light};
    clip-path: path('M 0 0 C 25 1 49 25 50 50 L 50 0');

    right: -1px;
    width: 50px;
    height: 50px;
    z-index: 3;
  }
  &::after {
    ${({ $index }) =>
      CONFIG[$index].backgroundColorMobile
        ? css`
            background-color: ${CONFIG[$index].backgroundColorMobile};
          `
        : ''}

    background-image: ${({ $index }) =>
      CONFIG[$index].linearGradient
        ? CONFIG[$index].linearGradient
        : ''} url(${({ $index, $isMobile }) =>
      CONFIG[$index].backgroundMobile && CONFIG[$index].background
        ? `${process.env.STATIC_SERVER}/${
            $isMobile
              ? CONFIG[$index].backgroundMobile
              : CONFIG[$index].background
          }`
        : 'none'});

    background-position-y: bottom;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    clip-path: path(
      'M 50 50 h 804 s 50 0 50 75 v 404 s 0 50 -50 50 h -804 s -50 0 -50 -50 v -404 s 0 0 0 -75 M 100 50 a 50 45 0 0 0 -100 0'
    );
    border-radius: 50px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-top-left-radius: 11px;
      margin-top: 12px;
    `}

  ${MobileBall} {
    background-color: ${({ $index }) => CONFIG[$index].mobileBallColor};
  }

  @media (min-width: ${desktop940}px) {
    padding: 0;

    &::before {
      display: none;
    }

    &::after {
      clip-path: path(
        'M 50 35 h 804 s 50 0 50 50 v 404 s 0 50 -50 50 h -804 s -50 0 -50 -50 v -404 s 0 -50 50 -50 M 506 35 a 50 45 0 0 0 -96 0'
      );

      ${({ $index }) =>
        CONFIG[$index].backgroundColor
          ? css`
              background-color: ${CONFIG[$index].backgroundColor};
            `
          : ''}
    }
  }
  @media (min-width: ${desktop1100}px) {
    &::after {
      clip-path: path(
        'M 50 35 h 984 s 50 0 50 50 v 404 s 0 50 -50 50 h -984 s -50 0 -50 -50 v -404 s 0 -50 50 -50 M 596 35 a 50 45 0 0 0 -96 0'
      );
    }
  }

  @media (min-width: ${desktop1280}px) {
    &::after {
      clip-path: path(
        'M 50 35 h 1164 s 50 0 50 50 v 404 s 0 50 -50 50 h -1164 s -50 0 -50 -50 v -404 s 0 -50 50 -50 M 685 35 a 50 45 0 0 0 -96 0'
      );
    }
  }

  ${({ $index }) => CONFIG[$index].css};
`;

export const Root = styled.div<{ $isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
