/** библиотеки */
import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

/** types */
import { AutoPaymentProps } from '~/components/Blocks/Templates/AutoPayment/types';

/** Компоненты */
import { ButtonStyleTypes, defaultTheme } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import Root, {
  StyledHeader,
  StyledMain,
  StyledButton,
  StyledBG,
  StyledText,
  MainText,
  MainHeader,
  StyledContent,
} from './style';

/** константы */
import { IMAGES } from './constants';
import {
  AUTO_PAYMENT_BLOCK_ID,
  VIEW_TYPES,
} from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';

/** Hooks */
import { useAutoPayment } from './AutoPaymentHook';

/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Компонент автоплатежа
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=592076145
 */
const AutoPayment: FC<AutoPaymentProps> = ({
  content: { fields },
}: AutoPaymentProps) => {
  /** хук автоплатежа */
  const { isLoading, viewType, getDate, openWizard } = useAutoPayment();
  const {
    autoPaymentInfoStore: { autoPaymentData },
    authStore: { isAuth },
  } = useRootStore();

  const isOnStyle = useMemo(() => {
    if (!isAuth || !autoPaymentData?.isActive) return false;
    return (
      viewType === VIEW_TYPES.UNBIND || viewType === VIEW_TYPES.BIND_SUCCESS
    );
  }, [isAuth, viewType, autoPaymentData]);

  if (isOnStyle)
    return (
      <Root $isOn={isOnStyle} id={`${AUTO_PAYMENT_BLOCK_ID}`}>
        <StyledHeader>Автоплатёж подключён</StyledHeader>
        <StyledMain>
          {autoPaymentData?.payment !== null && (
            <>
              <MainText color={defaultTheme.colors.shadow}>
                Сумма автоплатежа
              </MainText>
              <MainHeader>{`${autoPaymentData?.payment} ₽`}</MainHeader>
            </>
          )}
          {autoPaymentData?.payDate && (
            <>
              <MainText color={defaultTheme.colors.shadow}>
                Дата следующего списания
              </MainText>
              <MainHeader>
                {autoPaymentData?.payDate && getDate(autoPaymentData?.payDate)}
              </MainHeader>
            </>
          )}
        </StyledMain>
        <StyledButton
          styleType={ButtonStyleTypes.SECONDARY}
          loading={isLoading}
          onClick={openWizard}
        >
          Настроить
        </StyledButton>
      </Root>
    );

  return (
    <Root $isOn={isOnStyle} id={`${AUTO_PAYMENT_BLOCK_ID}`}>
      <StyledBG urlWomanPayBg={IMAGES.womanPayBg} />
      <StyledHeader>Подключить автоплатёж</StyledHeader>
      <StyledMain>
        <StyledText>
          Настройте один раз и&nbsp;не&nbsp;беспокойтесь о&nbsp;балансе
        </StyledText>
        {fields?.promoContent && (
          <StyledContent>{parseHtml(fields.promoContent)}</StyledContent>
        )}
      </StyledMain>
      <StyledButton loading={isLoading} onClick={openWizard}>
        Подключить
      </StyledButton>
    </Root>
  );
};

export default observer(AutoPayment);
