/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';

export const ServiceTagStyled = styled.span`
  margin: 0.5rem 0 0 0;
`;

export const AdvantageListStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AdvantageStyled = styled.div`
  width: 100%;
  color: ${defaultTheme.colors.shadow};
  margin: 0 0 1rem 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & svg {
    display: block;
    width: 30px;
    height: 30px;
    margin: 2px 0 1rem 0;
  }

  @media (min-width: 680px) {
    width: calc(50% - 2rem);
    margin: 0 2rem 1rem 0;
  }
`;

export const WinkStyled = styled.div`
  .service {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      h3 {
        margin-bottom: 0;
      }

      &__close-icon {
        cursor: pointer;
      }
    }
    &__tag {
      margin-top: -1.5rem;
      margin-bottom: 26px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      align-items: baseline;

      button {
        margin: 16px 0 0 0;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .service {
      &__tag {
        margin-top: -1.5rem;
      }
      &__block {
        flex-direction: row;

        > span {
          width: 94px;
        }

        button {
          margin: 0 0 0 108px;
        }
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    .service {
      &__block {
        > span {
          width: auto;
        }

        button {
          margin: 0 0 0 120px;
        }
      }
    }
  }
`;
