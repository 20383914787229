import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;

  p {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Header = styled.span`
  margin-bottom: 17px;
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '360px')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: space-between;
  width: 100%;

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        text-align: left;
        margin-bottom: 28px;
      `}
  }
`;
