/** библиотеки */
import { useMediaQuery } from 'react-responsive';

/** стили */
import { StyledSberATM } from './SberATM.style';

/** константы */
import { desktop1280, desktop940 } from '~/components/Grid/constants';

/** библиотека */
import { H2, H3, Text } from 'cordis-core-ui-planeta';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';

/**
 * Блок «Оплата. Сбербанк. В банкоматах и отделениях»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=595379177
 */
const SberATM = (): JSX.Element => {
  /** Вычисление ширины экрана */
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledSberATM isDesktop940={isDesktop940}>
      {isDesktop1280 ? (
        <H2>В банкоматах и отделениях Сбербанка</H2>
      ) : (
        <H3>В банкоматах и отделениях Сбербанка</H3>
      )}
      <Text className="mainText" lineHeight="24px">
        Для оплаты понадобится номер договора в&nbsp;Планете
      </Text>
      <LinkWrapper href="https://www.sberbank.ru/ru/oib">
        Найти на карте
      </LinkWrapper>
    </StyledSberATM>
  );
};

export default SberATM;
