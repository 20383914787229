import { ResourceNetProps } from '~/components/Blocks/Templates/Pab2c/Contract/types';

export type SubnetsType = {
  ipv4Subnets?: ResourceNetProps[];
  ipv6Subnets?: ResourceNetProps[];
};

/** Текст для title switcher */
export enum TITLE_TEXT {
  DREGS = 'Включить IPv6 вы сможете\nпосле возобновления обслуживания',
  NEW = 'Включить IPv6 вы сможете\nпосле подключения договора',
  VACATION = 'Недоступно при подключении услуги Каникулы.\nЧтобы воспользоваться, отключите услугу',
}
