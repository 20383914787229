/** библиотеки */
import * as React from 'react';

/** типы */
import { LegalInfo } from '../Legal.types';

/** утилиты */
import { formatNumber } from '~/utils/utils';

interface PhoneTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона Phone
 * @param {PhoneTextProps} content
 * @returns {React.FC}
 */
const PhoneText: React.FC<PhoneTextProps> = ({ content }: PhoneTextProps) => {
  return (
    <>
      {content.allowPhone && content.phoneNumberInPacket && (
        <p>
          Стоимость каждого дополнительного телефонного номера составляет{' '}
          {formatNumber(content.phonePrice)} руб./день и{' '}
          {formatNumber(content.rentalOnce)} руб. единовременно.
        </p>
      )}
    </>
  );
};

export default PhoneText;
