/* eslint-disable camelcase */
/** libraries */
import { FC, useMemo, useState } from 'react';
import {
  LeadingText,
  defaultTheme,
  Text,
  Button,
  LinkButton,
  SidePage,
  Snoska,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import { useMyTvStore } from '../../stores/useMyTvStore';

/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import Portal from '~/components/Portal/Portal';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';
import { getStore } from '../../utils';

/** components */
import ChannelCard from '../Cards/ChannelCard/ChannelCard';
import ChannelPackageCard from '../Cards/ChannelPackageCard/ChannelPackageCard';
import ApplyTransformerWizard from '../SidePages/ApplyTransformerWizard/ApplyTransformerWizard';
import ProductSwitcherWizardFooter from '../../../../../Shared/ProductSwitcherWizard/ProductSwitcherWizardFooter';
import ProductSwitcherWizard from '../../../../../Shared/ProductSwitcherWizard/ProductSwitcherWizard';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** constants */
import { PACKAGE_NOMINATIVE, CHANNEL, STORE_TYPE } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
import { INITIAL_RESULT } from '../common/SidePageResult/constants';
import { DEFAULT_RESULT } from '../../../../../Shared/ProductSwitcherWizard/constants';
import { CALL_TO_ACTION_BLOCK_ID } from '~/components/Blocks/Templates/CallToAction/CallToAction.constants';

/** interface */
import { Channel, ChannelPackage } from '../../interfaces';
import { ResultProps } from '../common/SidePageResult/types';
import { ResultConnect } from '~/components/Blocks/Templates/Summary/Summary.types';

/** api */
import { bindITvTimeshift, transformerApply } from '~/api/apiPab2c';

/** styles */
import { StyledFooter } from '../../styles';

/** Состояние трансформера */
export enum Transformer {
  /** трансформер пустой */
  EMPTY,
  /** режим редактирования */
  EDIT,
  /** трансформер применён */
  APPLIED,
}

/** props */
interface TransformerTabProps {
  /** Каналы включённые в продукт */
  allChannelsIncludedInProduct?: Channel[];
  /** Количество редактируемых каналов */
  numberOfChannels?: number;
  /** Трансформер в блоке Телевидение */
  isTransformer?: boolean;
  // /** Блок Television 2.0 витрина */
}

const TransformerTab: FC<TransformerTabProps> = ({
  allChannelsIncludedInProduct,
  numberOfChannels,
  isTransformer,
}: TransformerTabProps) => {
  const { toggleConnectionWizardVisible } = useConnectionStore();

  const {
    connectionTariffStore: { connectTariff, toggleChangeTariffWizardVisible },
    summaryDataStore: {
      tariffId,
      transformationLimit: transformationLimitFromSummary,
    },
    tvStore: {
      connectedTVPackages,
      tvChannelPackages,
      transformerInfo,
      connectedChannelsInPackages,
      connectedChannelsOutsidePackages,
      getTransformerStatus,
      updateChannelsAndPackages,
      channelsOutsidePackages,
      getViewControlInfo,
      editOrderSum,
      setEditOrderSum,
      idsAddedPacketsToTransformer,
      setIdsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
      setIdsAddedChannelsToTransformer,
      channelsList,
    },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const { storeType } = useMyTvStore();
  const {
    transformationLimit,
    filteredPackages,
    onClickPackageCard,
    onClickChannelCard,
    isEditTransformer,
    setIsEditTransformer,
    getPopularChannels,
    getPopularPackages,
    popularChannels,
    popularPackages,
    channelsList: channelsInTelevision,
  } = getStore(storeType);
  const isTelevision = storeType === STORE_TYPE.TELEVISION;

  /** Популярные каналы */
  const popularChannelsList: Channel[] = useMemo(
    () =>
      (isTelevision
        ? popularChannels
        : getPopularChannels(channelsList)) as Channel[],
    [isTelevision, channelsList, popularChannels],
  );
  /** Популярные пакеты */
  const popularPackagesList: ChannelPackage[] = useMemo(
    () =>
      (isTelevision
        ? popularPackages
        : getPopularPackages(tvChannelPackages)) as ChannelPackage[],
    [isTelevision, tvChannelPackages, popularPackages],
  );

  const connectedPackages: ChannelPackage[] = useMemo(
    () => (isTelevision ? [] : connectedTVPackages) as ChannelPackage[],
    [isTelevision, connectedTVPackages],
  );

  const tvPackages: ChannelPackage[] = useMemo(
    () =>
      (isTelevision ? filteredPackages : tvChannelPackages) as ChannelPackage[],
    [isTelevision, filteredPackages, tvChannelPackages],
  );

  const connectedChannelsInPack = useMemo(
    () => (isTelevision ? [] : connectedChannelsInPackages),
    [isTelevision, connectedChannelsInPackages],
  );

  const connectedChannelsOutPack = useMemo(
    () => (isTelevision ? [] : connectedChannelsOutsidePackages),
    [isTelevision, connectedChannelsOutsidePackages],
  );

  const tvChannels: Channel[] = useMemo(
    () => (isTelevision ? channelsInTelevision : channelsList) as Channel[],
    [isTelevision, channelsInTelevision, channelsList],
  );

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Сайдпейдж применения трансформера */
  const [isOpenApplySP, setIsOpenApplySP] = useState<boolean>(false);

  /** Результат подключения/отключения канала */
  const [result, setResult] = useState<ResultProps>(INITIAL_RESULT);

  /** Загрузчик кнопки подключения */
  const [isLoadingConnection, setIsLoadingConnection] = useState<boolean>(true);

  /** Disable кнопки */
  const [isDisableConnection, setIsDisableConnection] = useState<boolean>(
    false,
  );

  /** Галочка чекбокса управления просмотром */
  const [isViewControlCheckbox, setIsViewControlCheckbox] = useState<boolean>(
    false,
  );

  /** Пакеты, добавленные в трансформере */
  const connectedPackagesInTransformer: ChannelPackage[] = useMemo(() => {
    return idsAddedPacketsToTransformer?.length
      ? (idsAddedPacketsToTransformer
          .filter((item) => {
            return !connectedPackages.find(
              (pack) => pack.channelPackId === item,
            );
          })
          .map((item) => {
            return tvPackages.find(
              (channelPackId) => channelPackId.id === item,
            );
          }) as ChannelPackage[])
      : [];
  }, [tvPackages, connectedPackages, idsAddedPacketsToTransformer]);

  /** Пакеты */
  const packages = useMemo(
    () => [...connectedPackagesInTransformer, ...connectedPackages],
    [connectedPackagesInTransformer, connectedPackages],
  );

  /** Каналы, добавленные в трансформере */
  const connectedChannelsInTransformer = useMemo(() => {
    return idsAddedChannelsToTransformer?.length
      ? tvChannels.filter((item) => {
          return item?.singleChannelPacks?.length > 0
            ? idsAddedChannelsToTransformer.includes(
                item?.singleChannelPacks[0].id,
              ) &&
                !connectedChannelsOutPack.includes(item.weburgId ?? item.id) &&
                /** Канал есть в добавленном пакете */
                !connectedPackagesInTransformer.find((pack) =>
                  pack.channelPackChannels.find((canal) =>
                    canal?.singleChannelPacks?.length &&
                    item?.singleChannelPacks?.length
                      ? canal?.singleChannelPacks[0]?.id ===
                        item?.singleChannelPacks[0]?.id
                      : null,
                  ),
                )
            : null;
        })
      : [];
  }, [
    tvChannels,
    idsAddedChannelsToTransformer,
    connectedPackagesInTransformer,
  ]);

  /** Убирает подключённые каналы, которые находятся в составе добавленных пакетов */
  const outsideChannels = useMemo(
    () =>
      channelsOutsidePackages.filter(
        (item) =>
          !connectedPackagesInTransformer.find((pack) =>
            pack?.channelPackChannels.find((canal) =>
              item?.weburgId && canal?.weburgId
                ? item.weburgId === canal.weburgId
                : item.id === canal.id,
            ),
          ),
      ),
    [channelsOutsidePackages, connectedPackagesInTransformer],
  );

  /** Каналы */
  const channels = useMemo(
    () => [...connectedChannelsInTransformer, ...outsideChannels],
    [connectedChannelsInTransformer, outsideChannels],
  );

  /** Загрузка соглашения на изменение продукта */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingAgreement, setIsLoadingAgreement] = useState<boolean>(false);

  /** Результат подключения/смены продукта */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [connectResult, setConnectResult] = useState<ResultConnect>(
    DEFAULT_RESULT,
  );

  /** Статус трансформера */
  const transformer = useMemo(() => {
    if (isEditTransformer) return Transformer.EDIT;
    if (
      (!transformerInfo?.orderSum && !isTelevision) ||
      (isTelevision && editOrderSum === 0)
    )
      return Transformer.EMPTY;
    return Transformer.APPLIED;
  }, [transformerInfo, isEditTransformer]);

  /** Текст трансформера */
  const transformerText = (): string => {
    switch (transformer) {
      case Transformer.EMPTY:
        return 'Трансформер пустой';
      case Transformer.EDIT:
        return 'Режим редактирования';
      default:
        return 'Трансформер применён';
    }
  };

  /** Цвет трансформера */
  const transformerColor = () => {
    switch (transformer) {
      case Transformer.EMPTY:
        return defaultTheme.colors.down;
      case Transformer.EDIT:
        return defaultTheme.colors.warning;
      default:
        return defaultTheme.colors.planeta;
    }
  };

  /** Применить изменения трансформера */
  const applyTransformerChanges = async () => {
    setIsLoadingConnection(true);
    try {
      await transformerApply([
        ...idsAddedPacketsToTransformer,
        ...idsAddedChannelsToTransformer,
      ]);
      if (isViewControlCheckbox) {
        await bindITvTimeshift();
        if (!isTelevision) getViewControlInfo();
      }
      if (!isTelevision) {
        getTransformerStatus();
        updateChannelsAndPackages(tariffId);
      }
      setResult({
        isResult: true,
        isCorrect: true,
        title: `Заявка на${'\u00A0'}«Трансформацию» отправлена`,
      });
      setIsEditTransformer(false);
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'}так...`,
      });
    } finally {
      setIsLoadingConnection(false);
    }
  };

  /** Отменить изменения трансформера */
  const cancelChanges = () => {
    setIdsAddedPacketsToTransformer(
      isTelevision ? [] : connectedPackages.map((item) => item.channelPackId),
    );
    setIdsAddedChannelsToTransformer(
      isTelevision
        ? []
        : channelsOutsidePackages.map((item) => item.channelPackId),
    );
    setEditOrderSum(isTelevision ? 0 : transformerInfo?.orderSum);
    setIsEditTransformer(false);
  };

  /** Выключить кнопку, если нет изменений в трансформере */
  const isDisableButton = useMemo(() => {
    /** Пакеты */
    const isUnchangedPackages = idsAddedPacketsToTransformer?.length
      ? idsAddedPacketsToTransformer.every((item) =>
          connectedPackages.find((pack) => pack.channelPackId === item),
        ) && idsAddedPacketsToTransformer.length === connectedPackages.length
      : idsAddedPacketsToTransformer.length === connectedPackages.length;

    /** Каналы */
    const isUnchangedChannels = idsAddedChannelsToTransformer?.length
      ? idsAddedChannelsToTransformer.every((item) => {
          return tvChannels
            .filter((channel) =>
              connectedChannelsOutPack.includes(channel.weburgId ?? channel.id),
            )
            .find((canal) =>
              canal?.singleChannelPacks?.length > 0
                ? canal?.singleChannelPacks[0]?.id === item
                : null,
            );
        }) &&
        idsAddedChannelsToTransformer.length === connectedChannelsOutPack.length
      : idsAddedChannelsToTransformer.length ===
        connectedChannelsOutPack.length;

    return isUnchangedPackages && isUnchangedChannels;
  }, [
    idsAddedPacketsToTransformer,
    idsAddedChannelsToTransformer,
    connectedPackages,
  ]);

  const applyTransformer = () => {
    if (!isTelevision) {
      setIsOpenApplySP(true);
      return;
    }
    /** todo: временно, пока не доделаем смену продукта */
    // if (isAuth) {
    //   getAgreement();
    //   return;
    // }
    const cta = document.getElementById(CALL_TO_ACTION_BLOCK_ID);
    if (cta) {
      scrollToBlockById(CALL_TO_ACTION_BLOCK_ID);
    } else {
      toggleConnectionWizardVisible();
    }
  };

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    const limit = isTelevision
      ? transformationLimit
      : transformationLimitFromSummary;
    if (limit > editOrderSum) {
      return `доступно ${formatNumber(
        limit - editOrderSum,
      )} ₽ для трансформации`;
    }
    return `${formatNumber(editOrderSum - limit)} ₽ в день за доп. каналы`;
  }, [
    editOrderSum,
    isTelevision,
    transformationLimit,
    transformationLimitFromSummary,
  ]);

  return (
    <div className="my-tv__tab-block__block">
      <div className="my-tv__tab-block__block__transformer">
        <div className="my-tv__tab-block__block__transformer__block">
          <Text
            className="my-tv__tab-block__block__transformer__header"
            lineHeight="24px"
            color={transformerColor()}
          >
            {transformerText()}
          </Text>
          <Text color={defaultTheme.colors.shadow} lineHeight="24px">
            {pluralizeAll(
              isTelevision
                ? numberOfChannels
                : [...connectedChannelsInPack, ...connectedChannelsOutPack]
                    .length,
              CHANNEL,
            )}
          </Text>
        </div>
        <FillableScale
          value={editOrderSum}
          maxValue={
            isTelevision ? transformationLimit : transformationLimitFromSummary
          }
        >
          <Text className="text" lineHeight="24px">
            {textUnderScale}
          </Text>
        </FillableScale>
        {isEditTransformer && (
          <div>
            <Button
              onClick={applyTransformer}
              disabled={isDisableButton}
              loading={isLoadingAgreement}
            >
              {isTelevision ? 'Подключить' : 'Применить'}
            </Button>
            {!isTelevision && (
              <LinkButton onClick={cancelChanges}>Отменить</LinkButton>
            )}
          </div>
        )}
        {transformer === Transformer.APPLIED &&
          !isEditTransformer &&
          isDesktop940 && (
            <Text color={defaultTheme.colors.shadow} lineHeight="24px">
              Вы можете менять состав пакетов
              <br /> и каналов в Трансформере
            </Text>
          )}
      </div>
      {!packages?.length && !channels?.length && (
        <>
          <div className="my-tv__tab-block__block__not-found">
            <Text
              className="my-tv__tab-block__block__not-found__transformer-text"
              lineHeight="24px"
              color={defaultTheme.colors.shadow}
            >
              Добавьте каналы и&nbsp;пакеты в&nbsp;трансформер. Если лимит
              трансформации будет исчерпан, то&nbsp;добавление каналов
              и&nbsp;пакетов будет происходить за&nbsp;дополнительную плату.
            </Text>
          </div>
          <div className="my-tv__tab-block__block__count">
            <LeadingText
              className="my-tv__tab-block__block__category"
              color={defaultTheme.colors.black}
            >
              Популярные каналы и пакеты
            </LeadingText>
          </div>
          <div className="my-tv__tab-block__block__cards">
            {popularPackagesList?.length > 0 &&
              popularPackagesList.map((item) => {
                return (
                  <ChannelPackageCard
                    key={item.channelPackName}
                    packet={item}
                    onClick={() => onClickPackageCard(item)}
                    packagesIncludedInProduct={[]}
                    isTransformer={isTransformer}
                  />
                );
              })}
            {popularChannelsList.map((item: Channel) => (
              <ChannelCard
                key={item.name}
                channel={item}
                onClick={() => onClickChannelCard(item)}
                allChannelsIncludedInProduct={allChannelsIncludedInProduct}
                isTransformer={isTransformer}
              />
            ))}
          </div>
        </>
      )}
      {packages?.length !== 0 && (
        <>
          <div className="my-tv__tab-block__block__count">
            <LeadingText
              className="my-tv__tab-block__block__category"
              color={defaultTheme.colors.black}
            >
              Пакеты
            </LeadingText>
            <LeadingText
              className="my-tv__tab-block__block__count"
              color={defaultTheme.colors.shadow}
            >
              {pluralizeAll(packages?.length, PACKAGE_NOMINATIVE)}
            </LeadingText>
          </div>
          <div className="my-tv__tab-block__block__cards">
            {packages?.map((item) => {
              return (
                <ChannelPackageCard
                  key={item.channelPackName}
                  packet={item}
                  onClick={() => onClickPackageCard(item)}
                  packagesIncludedInProduct={[]}
                  isTransformer={isTransformer}
                />
              );
            })}
          </div>
        </>
      )}
      {channels?.length !== 0 && (
        <>
          <div className="my-tv__tab-block__block__count">
            <LeadingText
              className="my-tv__tab-block__block__category"
              color={defaultTheme.colors.black}
            >
              Каналы вне пакетов
            </LeadingText>
            <LeadingText
              className="my-tv__tab-block__block__count"
              color={defaultTheme.colors.shadow}
            >
              {pluralizeAll(channels.length, CHANNEL)}
            </LeadingText>
          </div>
          <div className="my-tv__tab-block__block__cards">
            {channels.map((item) => {
              return (
                <ChannelCard
                  key={item.name}
                  channel={(item as unknown) as Channel}
                  onClick={() =>
                    onClickChannelCard((item as unknown) as Channel)
                  }
                  allChannelsIncludedInProduct={allChannelsIncludedInProduct}
                  isTransformer={isTransformer}
                />
              );
            })}
          </div>
        </>
      )}
      <Portal>
        <SidePage
          show={isOpenApplySP}
          headerText={!result.isResult && 'Применение Трансформера'}
          onCloseClick={() => {
            setIsOpenApplySP(false);
            setResult(INITIAL_RESULT);
          }}
          footerContainer={
            !result.isResult && (
              <StyledFooter>
                <Button
                  onClick={applyTransformerChanges}
                  loading={isLoadingConnection}
                  disabled={isDisableConnection || isTemporaryTokenAuth}
                >
                  Применить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            )
          }
        >
          <ApplyTransformerWizard
            result={result}
            setIsLoadingConnection={setIsLoadingConnection}
            setIsDisableConnection={setIsDisableConnection}
            isViewControlCheckbox={isViewControlCheckbox}
            setIsViewControlCheckbox={setIsViewControlCheckbox}
          />
        </SidePage>
        <SidePage
          show={!!connectTariff}
          headerText={
            connectTariff && !connectResult.isResult
              ? `Подключение продукта${'\u00A0'}${connectTariff.seriesName}`
              : ''
          }
          onCloseClick={() => toggleChangeTariffWizardVisible(null)}
          footerContainer={
            !connectResult.isResult && <ProductSwitcherWizardFooter />
          }
          removeScrollBar={false}
        >
          <ProductSwitcherWizard />
        </SidePage>
      </Portal>
    </div>
  );
};

export default observer(TransformerTab);
