import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from 'cordis-core-ui-planeta';
import {
  desktop940,
  desktop880,
  desktop800,
  desktop700,
  desktop350,
} from '~/components/Grid/constants';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
`;

export const Header = styled.span<{ $isMobile?: boolean }>`
  margin-bottom: 0;
  margin-top: ${({ $isMobile }) => ($isMobile ? '0px' : '71px')};
  @media (min-width: ${desktop350}px) {
    margin-bottom: 33px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 46px;
  z-index: 2;
`;

export const StyledLine = styled.div`
  width: 100%;
  position: relative;
  margin-top: 80px;

  h1 {
    margin-bottom: 11px;
  }
  h2 {
    margin-bottom: 6px;
  }
  h3 {
    margin-bottom: 6px;
  }

  .lines {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-end;

    &__line {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 1050px;
      height: 19px;
      background: linear-gradient(
        0.25turn,
        #ffffff,
        #c34186 75%,
        #a31255 78%,
        #a72661
      );
      border-radius: 15px;

      @media (min-width: ${desktop940}px) {
        width: 95%;
      }
    }

    &__fade {
      position: absolute;
      width: 80%;
      right: 10%;
      height: 68px;
      background: linear-gradient(181deg, #fff 3.06%, #580028 96.93%);
      filter: blur(57px);
      z-index: 0;
    }

    &__point {
      position: absolute;
      top: -64px;
      right: -10px;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: ${desktop350}px) {
        top: -78px;
        right: -10px;
      }
      @media (min-width: ${desktop700}px) {
        right: 40px;
      }
      @media (min-width: ${desktop800}px) {
        right: 60px;
      }
      @media (min-width: ${desktop880}px) {
        right: 90px;
      }
      @media (min-width: ${desktop940}px) {
        top: -103px;
        right: 125px;
      }
    }

    .dates {
      position: absolute;
      opacity: 0.2;
      margin-top: 44px;
    }

    span {
      font-size: 16px;

      @media (min-width: ${desktop940}px) {
        font-size: 11px;
      }
    }
  }
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'space-between' : 'center'};
  width: 100%;

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        text-align: left;
        margin-top: 75px;
      `}
  }

  ${StyledButton} {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        margin-top: 32px;
      `}
  }
`;
