/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledAddingContact = styled.div`
  .add-contact {
    &__select {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      > span {
        margin-bottom: 8px;
      }
    }

    &__consent {
      margin-top: 32px;
    }
  }

  .confirmation-code {
    &__info {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 24px;
    }

    &__input {
      display: flex;
      flex-direction: column;
      &__block {
        display: flex;
        flex-direction: column;

        > span:first-of-type {
          margin-bottom: 8px;
        }
      }
    }

    &__action {
      height: auto;
      display: flex;
      align-items: center;
      margin: 16px 0 0 0;

      &__timer-text {
        width: 100%;
      }

      &__block {
        display: flex;
        flex-direction: column;
        > span {
          cursor: pointer;
        }
        > span:first-of-type {
          margin-bottom: 12px;
        }
      }
    }
  }

  .result {
    &__icon {
      margin-bottom: 24px;
    }

    &__yellow-block {
      background-color: ${defaultTheme.colors.backgroundWarning};
      padding: 16px;
      margin-bottom: 32px;
      > span:first-of-type {
        margin-bottom: 16px;
        word-break: break-word;
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .confirmation-code {
      &__input {
        flex-direction: row;
        align-items: start;
        &__block {
          &__code {
            > div[data-test-error] {
              > span {
                max-width: 320px;
              }
            }
          }
        }
      }

      &__action {
        height: 54px;
        margin: 32px 0 0 16px;

        &__block {
          > span:first-of-type {
            margin-bottom: 4px;
          }
        }
        &__timer-text {
          width: 188px;
        }
      }
    }
  }
`;
