/** libraries */
import { FC, useEffect, useState } from 'react';
import {
  defaultTheme,
  Input,
  LeadingText,
  Loader,
  Text,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledCreatePassword } from '../styles';
/** interfaces */
/** constants */
import { SP_STATE } from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { ATTEMPT, REMAINED } from '~/constants/common';
/** utils */
import { pluralizeAll } from '~/utils/utils';
/** stores */
import useCreatePasswordStore from '../store/useCreatePasswordStore';

const CodeCheck: FC = () => {
  const {
    contractNumber,
    phoneNumber,
    remains,
    setRemains,
    code,
    setCode,
    codeError,
    setCodeError,
    setState,
    sendingCode,
    sendingCodeByCall,
  } = useCreatePasswordStore();

  /** Изменение input */
  const onChangeCode = (event) => {
    setCode(event.target.value.replace(/[^0-9]/g, ''));
  };
  /** Фокус на input code */
  const onFocusCode = () => {
    setCodeError('');
  };

  const initialCounter = 30;
  /** Оставшееся время для повторной отправки кода */
  const [seconds, setSeconds] = useState<number>(initialCounter);

  /** Обратный отсчёт */
  const tick = () => {
    if (seconds === 0) return;
    setSeconds((prevSeconds) => prevSeconds - 1);
  };

  useEffect(() => {
    const timerID = setInterval(tick, 1000);
    return () => clearInterval(timerID);
  }, [seconds]);

  /** Loader "Продиктовать по телефону" */
  const [isDictateLoader, setIsDictateLoader] = useState<boolean>(false);

  /** Продиктовать по телефону */
  const dictateByPhone = () => {
    setIsDictateLoader(true);
    setTimeout(() => {
      setIsDictateLoader(false);
    }, 60000);
    sendingCodeByCall();
  };

  const sendCode = async () => {
    await sendingCode();
    setSeconds(initialCounter);
  };

  return (
    <StyledCreatePassword>
      <div className="code__info">
        <div className="code__info__block">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Номер договора
          </Text>
          <LeadingText color={defaultTheme.colors.black}>
            {contractNumber}
          </LeadingText>
        </div>
        <div className="code__info__block">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Код отправлен на номер
          </Text>
          <LeadingText color={defaultTheme.colors.black}>
            {phoneNumber.value}
          </LeadingText>
        </div>
      </div>
      <Text
        className="code__link"
        lineHeight="24px"
        onClick={() => {
          setState(SP_STATE.PHONE);
          setCode('');
          setCodeError('');
          setRemains(null);
        }}
        color={defaultTheme.colors.planeta}
      >
        Другой договор или номер телефона
      </Text>
      <div className="code__input">
        <div className="code__input__block">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Код
          </Text>
          <Input
            className="code__input__block__code"
            type="text"
            placeholder="Введите код"
            value={code}
            error={codeError}
            onChange={onChangeCode}
            onFocus={onFocusCode}
            isFocus
          />
        </div>
        <div className="code__action">
          {seconds !== 0 && !codeError && (
            <Text
              className="code__action__timer-text"
              lineHeight="24px"
              color={defaultTheme.colors.shadow}
            >
              Выслать ещё код можно через {seconds} секунд
            </Text>
          )}
          {(seconds === 0 || codeError) && (
            <div className="code__action__block">
              <Text
                onClick={sendCode}
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
              >
                Выслать ещё код
              </Text>
              {isDictateLoader ? (
                <Loader small />
              ) : (
                <Text
                  onClick={dictateByPhone}
                  lineHeight="24px"
                  color={defaultTheme.colors.planeta}
                >
                  Продиктовать по телефону
                </Text>
              )}
            </div>
          )}
        </div>
      </div>
      {remains && (
        <div className="code__warning">
          <Text lineHeight="24px">
            По истечении пяти попыток ввода доступ к договору будет временно
            ограничен.&nbsp;
          </Text>
          <Text lineHeight="24px" fontWeightBold>
            {pluralizeAll(remains, ATTEMPT, REMAINED)}.
          </Text>
        </div>
      )}
    </StyledCreatePassword>
  );
};

export default observer(CodeCheck);
