/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledZombie = styled.div`
  li::before {
    padding-right: 3px !important;
  }

  li {
    margin-bottom: 8px !important;
  }

  a {
    color: ${defaultTheme.colors.planeta};
  }

  .zombie {
    &__steps {
      margin: 8px 0 16px;
    }
    &__devices {
      li::before {
        color: ${defaultTheme.colors.shadow} !important;
      }
      li {
        display: flex !important;
        align-items: center !important;
        margin-bottom: 0 !important;
      }
    }
  }
`;
