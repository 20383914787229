/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
/** components */
import { H1, H3, defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
import Wrapper, { Header, MainText } from './styles';
/** config */
import CONFIG from '../../config';
import { desktop940 } from '~/components/Grid/constants';
import Image from 'next/image';

/** Слайд Планеты */
const Planeta: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const imageLoader = ({ src, width: imgWidth, quality }) => {
    return `${src}?w=${imgWidth}&q=${quality || 75}`;
  };

  if (!isDesktop940)
    return (
      <Wrapper $isMobile>
        <Header as={H3} color={defaultTheme.colors.white}>
          {CONFIG[2].header}
        </Header>
        <MainText
          dangerouslySetInnerHTML={{ __html: CONFIG[2].mainTextMobile }}
          as={LeadingText}
          color={defaultTheme.colors.white}
        />
      </Wrapper>
    );

  return (
    <Wrapper>
      <Header as={H1} color={defaultTheme.colors.white}>
        {CONFIG[2].header}
      </Header>
      <MainText
        dangerouslySetInnerHTML={{ __html: CONFIG[2].mainText }}
        as={LeadingText}
        color={defaultTheme.colors.white}
      />
      <Image
        loader={imageLoader}
        src={`${process.env.STATIC_SERVER}/${CONFIG[2].mainImage}`}
        alt={CONFIG[2].mainText}
        width={738}
        height={284}
        quality={100}
      />
    </Wrapper>
  );
};

export default Planeta;
