import { FC } from 'react';
import { StyledTr } from './styles';

interface TrProps {
  children: React.ReactNode;
  backgroundColor?: string;
}

const Tr: FC<TrProps> = ({ children, backgroundColor }: TrProps) => {
  return <StyledTr backgroundColor={backgroundColor}>{children}</StyledTr>;
};

export default Tr;
