/* eslint-disable camelcase */
/** libraries */
import { FC, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, Icons, Snoska, Tag, Text } from 'cordis-core-ui-planeta';
import { isBefore, parseISO } from 'date-fns';
import Highlighter from 'react-highlight-words';
import { observer } from 'mobx-react';

/** styles */
import { ChannelPackageCardStyled } from './styles';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { CHANNEL, ORDERED, STORE_TYPE, TEXT } from '../../../constants';

/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { getDate } from '../../../utils';

/** interfaces */
import {
  ChannelPackageFieldsProps,
  PackageConnectionStates,
} from './interfaces';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';

/**
 * Блок «ЛК. Моё телевидение»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=690527702
 */
const ChannelPackageCard: FC<ChannelPackageFieldsProps> = ({
  packet,
  onClick,
  highlight,
  packagesIncludedInProduct,
  isTransformer,
}: ChannelPackageFieldsProps) => {
  const {
    tvStore: {
      connectedChannelsInPackages,
      connectedChannelsOutsidePackages,
      idsAddedPacketsToTransformer,
      connectedTVPackages,
      transformerInfo,
    },
  } = useRootStore();
  const { channelsList, storeType } = useMyTvStore();

  const isTelevision = storeType === STORE_TYPE.TELEVISION;

  /** Статус подключения */
  const connection = useMemo(() => {
    if (isTelevision) {
      /** Добавлен в трансформер */
      if (
        isTransformer &&
        idsAddedPacketsToTransformer?.length &&
        idsAddedPacketsToTransformer.find((item) => item === packet?.id)
      )
        return PackageConnectionStates.AddedToTransformer;
      return packagesIncludedInProduct.find((item) => item.id === packet.id) &&
        !isTransformer
        ? PackageConnectionStates.IncludedInTheProduct
        : PackageConnectionStates.NotConnected;
    }
    /** Подключённый пакет */
    const connectedPacket = connectedTVPackages.find((item) => {
      if (packet?.channelPackId)
        return item.channelPackId === packet?.channelPackId;
      return item.channelPackId === packet?.id;
    });
    /** Добавлен в трансформер */
    if (
      transformerInfo &&
      idsAddedPacketsToTransformer?.length &&
      idsAddedPacketsToTransformer.find((item) => item === packet?.id) &&
      !connectedPacket
    )
      return PackageConnectionStates.AddedToTransformer;
    /** Убран из трансформера */
    if (
      transformerInfo &&
      connectedPacket &&
      !idsAddedPacketsToTransformer?.find(
        (item) => item === packet?.channelPackId,
      )
    )
      return PackageConnectionStates.RemovedFromTheTransformer;
    if (!connectedPacket) return PackageConnectionStates.NotConnected;
    if (packet?.unbindDt) return PackageConnectionStates.RefusalIssued;
    return connectedPacket.isFree
      ? PackageConnectionStates.IncludedInTheProduct
      : PackageConnectionStates.Connected;
  }, [connectedTVPackages]);

  /** Отказ от пакета */
  const isRejectionOfThePackage = useMemo(
    () => connection === PackageConnectionStates.RefusalIssued,
    [connection],
  );

  /** Дата отключения пакета, если оформлен отказ */
  const shutdownDate = useMemo(() => {
    if (!packet?.unbindDt) return null;
    if (transformerInfo) {
      return `${getDate(packet?.unbindDt)} года`;
    }
    return getDate(packet?.unbindDt, 'dd.MM.yyyy');
  }, [packet?.unbindDt]);

  /** Выгодный */
  const isProfitable = useMemo(() => packet?.isSale, []);

  // Вычисление ширины экрана
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Подключённые каналы */
  const connectedChannels = useMemo(() => {
    if (channelsList?.length && packet?.channelIds?.length) {
      return packet?.channelIds?.map((item) => {
        return channelsList.find((channel) =>
          channel?.weburgId ? channel.weburgId === item : channel.id === item,
        );
      });
    }
    if (channelsList?.length && packet?.channelPackChannels?.length) {
      return packet?.channelPackChannels.filter((item) => {
        return item
          ? [
              ...connectedChannelsInPackages,
              ...connectedChannelsOutsidePackages,
            ].includes(item.weburgId ?? item.id)
          : null;
      });
    }
    return [];
  }, [channelsList]);

  /** Дата, после которой возможен отказ от пакета */
  const refusalDate = useMemo(() => {
    if (!packet?.banTrimDate) return null;
    if (transformerInfo) {
      return `${getDate(packet?.banTrimDate)} года`;
    }
    return '';
  }, [packet?.banTrimDate]);

  /** Отключение доступно сразу */
  const isRejectionAvailable = useMemo(
    () => isBefore(new Date(parseISO(packet?.banTrimDate)), new Date()),
    [packet?.banTrimDate],
  );

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (connection === PackageConnectionStates.AddedToTransformer)
        return defaultTheme.colors.planeta;
      if (connection === PackageConnectionStates.RemovedFromTheTransformer)
        return defaultTheme.colors.black;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (connection === PackageConnectionStates.AddedToTransformer)
        return defaultTheme.colors.pink;
      if (connection === PackageConnectionStates.RemovedFromTheTransformer)
        return defaultTheme.colors.disable;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isMinDesktop940) return undefined;
      if (connection === PackageConnectionStates.AddedToTransformer) {
        return 'Будет подключён при применении трансформера';
      }
      if (shutdownDate) return `Оформлен отказ с ${shutdownDate}`;
      if (connection === PackageConnectionStates.RemovedFromTheTransformer) {
        return isRejectionAvailable
          ? 'Будет отключён при применении трансформера'
          : `Будет оформлен отказ с ${refusalDate} года при применении трансформера`;
      }
      return undefined;
    };
    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          !isRejectionOfThePackage &&
          ![
            PackageConnectionStates.AddedToTransformer,
            PackageConnectionStates.RemovedFromTheTransformer,
          ].includes(connection) &&
          colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = (): string | boolean => {
    switch (connection) {
      case PackageConnectionStates.AddedToTransformer:
        return TEXT.ADDED;
      case PackageConnectionStates.RemovedFromTheTransformer:
        return TEXT.REMOVED;
      case PackageConnectionStates.NotConnected && isProfitable:
        return TEXT.PROFITABLE;
      case PackageConnectionStates.NotConnected:
        return false;
      case PackageConnectionStates.Connected:
        return TEXT.CONNECTED;
      case PackageConnectionStates.RefusalIssued:
        return TEXT.CANCELLATION;
      case PackageConnectionStates.IncludedInTheProduct:
        return isMinDesktop940 ? TEXT.INCLUDED_IN_PRODUCT : TEXT.IN_PRODUCT;
      default:
        return false;
    }
  };

  const channelsInfo = () => {
    const channelDeclination = pluralizeAll(
      packet?.channelPackChannels?.filter((item) => item !== undefined)
        ?.length ?? packet?.channelIds?.length,
      CHANNEL,
    );
    return isMinDesktop940 &&
      connection === PackageConnectionStates.NotConnected
      ? `${channelDeclination}${
          connectedChannels?.length
            ? ` / ${pluralizeAll(
                connectedChannels?.length ?? packet?.channelIds?.length,
                ORDERED,
              )}`
            : ''
        }`
      : `${channelDeclination}`;
  };

  const onPackageCardClick = () => onClick(packet);

  return (
    <ChannelPackageCardStyled
      onClick={onPackageCardClick}
      id={packet?.channelPackName ?? packet?.name ?? ''}
    >
      <div className="channel-package-card">
        <div className="channel-package-card__block">
          {isMinDesktop940 && (
            <Snoska
              className="channel-package-card__definition"
              color={defaultTheme.colors.shadow}
            >
              пакет телеканалов
            </Snoska>
          )}
          {highlight ? (
            <Text
              className="channel-package-card__name-highlighted"
              lineHeight="20px"
            >
              <Highlighter
                searchWords={[highlight]}
                autoEscape
                textToHighlight={packet?.channelPackName ?? packet?.name}
              />
            </Text>
          ) : (
            <Text className="channel-package-card__name" lineHeight="20px">
              {packet?.channelPackName ?? packet?.name}
            </Text>
          )}
        </div>
        <div className="channel-package-card__block">
          <div className="channel-package-card__info-block">
            {((!(
              connection === PackageConnectionStates.NotConnected &&
              !isProfitable
            ) &&
              isMinDesktop940) ||
              [
                PackageConnectionStates.Connected,
                PackageConnectionStates.RefusalIssued,
                PackageConnectionStates.IncludedInTheProduct,
              ].includes(connection)) &&
              connection !== undefined && (
                <div className="channel-package-card__info-block__tag">
                  {customTag(connectionStatusText())}
                </div>
              )}
            {connection === PackageConnectionStates.RemovedFromTheTransformer &&
              !isRejectionAvailable && (
                <div className="channel-package-card__info-block__wait-icon">
                  <Icons.WaitIcon />
                </div>
              )}
            {packet?.price !== null &&
              packet?.price !== undefined &&
              !(
                !isMinDesktop940 &&
                [
                  PackageConnectionStates.Connected,
                  PackageConnectionStates.RefusalIssued,
                  PackageConnectionStates.IncludedInTheProduct,
                ].includes(connection)
              ) && (
                <Snoska lineHeight="16px" color={defaultTheme.colors.black}>
                  {formatNumber(packet?.price)} ₽ в&nbsp;день
                </Snoska>
              )}
          </div>
          <Snoska
            className="channel-package-card__channels-info"
            lineHeight="16px"
            color={defaultTheme.colors.shadow}
          >
            {channelsInfo()}
          </Snoska>
        </div>
      </div>
      <div className="channel-package-card__duplicate-wrapper">
        <div className="channel-package-card__duplicate" />
      </div>
    </ChannelPackageCardStyled>
  );
};

export default observer(ChannelPackageCard);
