/** libraries */
import { types } from 'mobx-state-tree';

export const ContactTypeModel = types.model({
  id: types.number,
  name: types.string,
  code: types.string,
});

export const ExtendedContactTypeModel = types.compose(
  ContactTypeModel,
  types.model({
    label: types.string,
    value: types.string,
  }),
);

export const ContactsModel = types.model({
  contactValueClassId: types.number,
  id: types.number,
  isVerified: types.boolean,
  value: types.string,
});

export const SubscriptionsModel = types.model({
  eventGroupId: types.number,
  contactId: types.number,
  isSubscribed: types.boolean,
});

export const InputValueModel = types.model({
  forSend: types.string,
  value: types.string,
});

export const ResultPropsModel = types.model({
  isResult: types.boolean,
  isCorrect: types.boolean,
});

export const SubscribeData = types.model({
  id: types.number,
  checked: types.boolean,
});

export const SelectProps = types.model({
  label: types.string,
  value: types.string,
});

export const MutePeriodData = types.model({
  hourFrom: SelectProps,
  hourTo: SelectProps,
  isImportantEnabled: types.boolean,
  isActive: types.boolean,
});
