/** поля блока */
import {
  Highlightable,
  ServiceInfoProps,
} from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

export interface ServiceCardFieldsProps extends Highlightable {
  service: ServiceInfoProps;
  /** Клик на карточку */
  onClick: () => void;
}

export enum ConnectionStates {
  // не подключён
  NotConnected,
  // Подключён
  Connected,
  // Оформлен отказ
  RefusalIssued,
  // Подключён в пакете
  ConnectedInAPackage,
  // Оформлен отказ от пакета, в котором находится сервис
  ThePackageInWhichTheServiceIsLocatedHasBeenRejected,
  // Добавлен в трансформер в пакете
  AddedToTransformerInPackage,
  // Убран из трансформера в пакете
  RemovedFromTheTransformerInPackage,
}
