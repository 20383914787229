import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledTransform = styled.div`
  min-height: 600px;
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 550px;
  }

  .transform {
    &__header {
      padding-top: 12px;
    }
    &__total {
      padding-top: 32px;
      border-top: 1px solid ${defaultTheme.colors.disable};
      &__header {
        margin-bottom: 8px;
      }
    }
  }
`;
