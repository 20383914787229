/** libraries */
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import CallbackWizard from '~/components/CallbackWizard/CallbackWizard';
import CallbackWizardConfirm from '~/components/CallbackWizard/CallbackWizardConfirm';
/** store */
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';

const CallbackSP = (): JSX.Element => {
  const {
    toggleCallbackWizardVisible,
    onCloseClickSidePageCallback,
    isVisible,
  } = useCallbackStore();
  return (
    <SidePage
      show={isVisible}
      headerText={`Заказать звонок от${'\u00A0'}Планеты`}
      onCloseClick={onCloseClickSidePageCallback}
      footerContainer={
        <CallbackWizardConfirm closeBtnHandler={toggleCallbackWizardVisible} />
      }
      removeScrollBar={false}
    >
      <CallbackWizard />
    </SidePage>
  );
};

export default observer(CallbackSP);
