import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** Стилизованный компонент отключения подписки */
export const StyledSoftlineUnsubscribeWizard = styled.div`
  margin-top: -41px;

  .unsubscribe {
    &__text-block {
      margin-top: 32px;
      &-warning {
        padding: 1rem;
        background: ${defaultTheme.colors.backgroundWarning};
      }
    }

    &__agreement-block {
      margin-top: 32px;

      a {
        color: ${defaultTheme.colors.planeta};
      }

      ol {
        list-style-type: none;
        li {
          margin-top: 16px;
        }
      }
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: auto;
    }

    &__date {
      margin-bottom: 16px;
    }
  }
`;
