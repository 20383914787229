/** библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { H2, H3, Icon, Icons, SidePage, Text } from 'cordis-core-ui-planeta';
/** стилевые компоненты */
import { StyledFailureSP } from '../../Templates/Devices/style';
/** константы */
import {
  RESULT_TEXT,
  TOO_MUCH_REQUESTS_ERROR_TEXT,
} from '../../Templates/Devices/constants';
import { desktop1100 } from '~/components/Grid/constants';
import { DEMAND_REQUEST_ERRORS } from '~/constants/common';
/** stores */
import useDeviceDetailedCardStore from './store/useDeviceDetailedCardStore';

/**
 * Сайдпейдж ошибки превышения лимита запросов заказа оборудования
 */
const DeviceRequestErrorSP: FC = () => {
  const { error, setError } = useDeviceDetailedCardStore();
  /** Ширина сайта */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <SidePage
      show={error === DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS}
      onCloseClick={() => setError('')}
      removeScrollBar
    >
      <StyledFailureSP>
        <Icon icon={<Icons.NotOkBigIcon />} />
        {isDesktop1100 ? (
          <H2>{RESULT_TEXT.FAILURE}</H2>
        ) : (
          <H3>{RESULT_TEXT.FAILURE}</H3>
        )}
        <Text lineHeight="24px">{TOO_MUCH_REQUESTS_ERROR_TEXT}</Text>
      </StyledFailureSP>
    </SidePage>
  );
};

export default observer(DeviceRequestErrorSP);
