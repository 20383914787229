/** libraries */
import { ChangeEvent, FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** components */
import {
  CheckboxAgreement,
  HeaderAuth,
  StyledButton,
  StyledCheckbox,
  StyledText,
} from '~/components/Blocks/Templates/ChatlesExp/styles';
import { defaultTheme, LeadingText, Link, Text } from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import {
  AGREEMENT_LINK,
  ERRORS,
} from '~/components/Blocks/Templates/ChatlesExp/config';
import { desktop940 } from '~/components/Grid/constants';
import { OPERATING_STATE, SERIES_CODE } from '~/constants/common';
/** interfaces */
import { IsAuthProps } from '~/components/Blocks/Templates/ChatlesExp/interfaces';
import { useRootStore } from '~/stores/RootStore';

const IsAuth: FC<IsAuthProps> = ({
  formState,
  setFormState,
  isLoadingButton,
}: IsAuthProps) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    agreementStore: { setIsShowAgreement },
    contractStateStore: { contractState },
    summaryDataStore: { seriesCode },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const [checked, setChecked] = useState<boolean>(false);

  const handlerAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    if (formState === ERRORS.AGREEMENT) setFormState(null);
    setChecked(e.target.checked);
  };

  const errorText = () => {
    if (formState === 'valid') return null;
    return formState;
  };

  const onButtonClick = () => {
    switch (true) {
      case !checked:
        setFormState(ERRORS.AGREEMENT);
        return;
      case contractState === OPERATING_STATE.NEW:
        setFormState(ERRORS.NOVICE);
        return;
      case contractState === OPERATING_STATE.STRAY:
        setFormState(ERRORS.STRAY);
        return;
      case seriesCode === SERIES_CODE.EMPLOYEE ||
        ![
          OPERATING_STATE.ON,
          OPERATING_STATE.CLIENT_BLOCK,
          OPERATING_STATE.PROVIDER_BLOCK_DEBT,
        ].includes(contractState):
        setFormState(ERRORS.COMMON);
        return;
      case isTemporaryTokenAuth:
        setFormState(ERRORS.EMPLOYEE);
        return;
      default:
        setFormState('valid');
    }
  };

  return (
    <>
      <HeaderAuth
        color={
          errorText() ? defaultTheme.colors.planeta : defaultTheme.colors.black
        }
        as={isDesktop ? LeadingText : Text}
      >
        {errorText() ?? (
          <>
            Подтвердите участие в розыгрыше{'\n'}
            бонусных рублей
          </>
        )}
      </HeaderAuth>
      <StyledButton
        type="submit"
        loading={isLoadingButton}
        onClick={onButtonClick}
        border={defaultTheme.colors.redViolet}
        background={defaultTheme.colors.redViolet}
      >
        Участвовать
      </StyledButton>
      <StyledCheckbox onChange={handlerAgreement} checked={checked}>
        <CheckboxAgreement>
          <StyledText>
            Я&nbsp;принимаю&nbsp;
            <LinkWrapper href={AGREEMENT_LINK}>правила&nbsp;</LinkWrapper>
          </StyledText>
          <StyledText>
            Акции и&nbsp;
            <Link
              color={defaultTheme.colors.planeta}
              onClick={() => setIsShowAgreement(true)}
            >
              Согласие
            </Link>
            &nbsp;на&nbsp;
          </StyledText>
          {!isDesktop && (
            <>
              обработку персональных&nbsp;<StyledText>данных</StyledText>
            </>
          )}
          {isDesktop && (
            <>
              обработку&nbsp;<StyledText>персональных данных</StyledText>
            </>
          )}
        </CheckboxAgreement>
      </StyledCheckbox>
    </>
  );
};

export default observer(IsAuth);
