export enum BLOCK_CONTRACT_ERRORS {
  /** Превышено количество попыток восстановления (блокировка). */
  ExceededRecoveryAttempts = 'PasswordRecoveryRecoverBlockedException',
  /** Превышен лимит отправки sms. */
  MessageSending = 'PasswordRecoveryMessageSendingBlockedException',
  /** Превышен лимит отправки голосовых сообщений. */
  DictationBlocked = 'PasswordRecoveryDictationBlockedException',
  /** Неверный номер договора */
  Password = 'PasswordRecoveryException',
  /** Введенный номер не указан в договоре. */
  BadPhone = 'PasswordRecoveryBadPhoneException',
  /** Неправильный временный код. */
  BadTemporaryPassword = 'PasswordRecoveryBadTemporaryPasswordException',
  /** Неверно указан код */
  AuthCountException = 'AuthCountException',
  /** Блокировка договора */
  BlockException = 'BlockException',
  /** Договор не обслуживается */
  ContractNotServicedException = 'ContractNotServicedException',
}
