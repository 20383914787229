/**  libraries */
import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
/** styles */
import { StyledCancelingNumberDeactivation, StyledResult } from './styles';
import {
  H1,
  Icons,
  LeadingText,
  SidePage,
  defaultTheme,
  Text,
} from 'cordis-core-ui-planeta';
/** constants */
import { ERRORS } from '../../constants';
import { DEFAULT_ERROR, DEFAULT_RESULT } from '~/constants/common';
/** interfaces */
import { ResultProps } from '../../interfaces';
/** components */
import CancelingNumberDeactivationFooter from './CancelingNumberDeactivationFooter';
/** api */
import { phoneUnbindDocumentClear } from '~/api/apiPab2c';
/** utils */
import { maskHomePhone } from '../../utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from '../../store/useVoiceStore';

const CancelingNumberDeactivationSidePage: FC = () => {
  const {
    pab2cVoiceStore: { getPhones },
  } = useRootStore();
  const {
    phonePassword: phone,
    sim,
    isShowCancelNumberDeactivation,
    setIsShowCancelNumberDeactivation,
  } = useVoiceStore();

  /** Результат сайдпейджа */
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const zeroing = () => {
    setIsShowCancelNumberDeactivation(false);
    setResult(DEFAULT_RESULT);
  };
  const resultJSX = () => {
    if (!result.isResult) return <></>;
    if (result.isCorrect) {
      return (
        <StyledResult>
          <Icons.OkBigIcon />
          <H1>Заявка отменена успешно</H1>
        </StyledResult>
      );
    }
    return (
      <StyledResult>
        <Icons.NotOkBigIcon />
        <H1>{result?.text ? result.text : DEFAULT_ERROR}</H1>
      </StyledResult>
    );
  };
  const content = () => {
    if (!result.isResult) {
      return (
        <>
          <div className="number__block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Номер
            </Text>
            <LeadingText color={defaultTheme.colors.black}>
              {maskHomePhone(phone)}
            </LeadingText>
          </div>
          <div className="number__yellow-block">
            <Text lineHeight="24px">
              В течение суток может быть отменено&nbsp;
              <Text lineHeight="24px" fontWeightBold>
                только одно
              </Text>
              &nbsp;отключение для данного номера
            </Text>
          </div>
        </>
      );
    }
    return resultJSX();
  };
  const disable = async () => {
    setIsLoading(true);
    try {
      await phoneUnbindDocumentClear(sim);
      await getPhones();
      setResult({
        isResult: true,
        isCorrect: true,
      });
    } catch (e) {
      const err = JSON.parse(e?.errorMessage);
      setResult({
        isResult: true,
        isCorrect: false,
        text:
          err?.Type === ERRORS.PHONE_UNBIND_LIMIT
            ? 'Ошибка. В течение суток может быть отменено только одно отключение для данного номера.'
            : '',
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <StyledCancelingNumberDeactivation>
      <SidePage
        show={isShowCancelNumberDeactivation}
        width="832px"
        headerText={!result.isResult && 'Отменить отключение номера'}
        onCloseClick={zeroing}
        footerContainer={
          !result.isResult && (
            <CancelingNumberDeactivationFooter
              disable={disable}
              isLoading={isLoading}
            />
          )
        }
        removeScrollBar
      >
        {content()}
      </SidePage>
    </StyledCancelingNumberDeactivation>
  );
};

export default observer(CancelingNumberDeactivationSidePage);
