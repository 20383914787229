import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const PlayerCircle = styled.div`
  width: 42px;
  height: 42px;
  cursor: pointer;
  background-color: ${defaultTheme.colors.gray};
  border-radius: 50%;
  position: relative;
  transition: background-color 1s;
  flex: none;

  &:hover {
    background-color: ${defaultTheme.colors.black};
  }
`;

export const PlayerButton = styled.div`
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 0 11px 15px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  top: 10px;
  left: 16px;
`;

export const PlayerWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  ${PlayerCircle} {
    margin-right: 12px;
  }
`;
