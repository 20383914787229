/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Link,
  ListItem,
  OrderedList,
  Snoska,
  Text,
  UnorderedList,
  defaultTheme,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledZombie } from './style';
/** constants */
import { DEVICE_TYPES } from '../../../Devices/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/** Договор не обслуживается */
const Zombie = (): JSX.Element => {
  const {
    pab2cBalanceStore: { moneyRecommended },
    pab2cDevicesStore: { pab2cDevicesList },
  } = useRootStore();

  const devices = useMemo(
    () =>
      pab2cDevicesList.map((item) =>
        DEVICE_TYPES[item.deviceTypeCode].toLowerCase(),
      ),
    [pab2cDevicesList],
  );

  return (
    <StyledZombie>
      <Text lineHeight="24px">Как возобновить обслуживание:</Text>
      <OrderedList className="zombie__steps">
        <ListItem>
          <Text lineHeight="24px">
            Позвоните в отдел консультационной поддержки.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px">
            Пополните счёт на сумму не менее{' '}
            <Text lineHeight="24px" fontWeightBold>
              {moneyRecommended} ₽
            </Text>
            .
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px">
            Обслуживание будет возобновлено в течение 20 дней.
          </Text>
        </ListItem>
      </OrderedList>
      <Snoska color={defaultTheme.colors.shadow}>
        Если вы хотите остановить обслуживание, расторгните договор{' '}
        <Link href="/contacts">в офисе Планеты</Link>.
      </Snoska>
      <br />
      {devices.length > 0 && (
        <>
          <Snoska color={defaultTheme.colors.shadow}>
            Не забудьте{' '}
            <Snoska color={defaultTheme.colors.shadow} fontWeightBold>
              вернуть оборудование
            </Snoska>
            , находящееся в пользовании:
          </Snoska>
          <UnorderedList className="zombie__devices" showBullet>
            {devices.map((item) => {
              return (
                <ListItem>
                  <Snoska color={defaultTheme.colors.shadow}>{item}</Snoska>
                </ListItem>
              );
            })}
          </UnorderedList>
        </>
      )}
    </StyledZombie>
  );
};

export default observer(Zombie);
