import { observer } from 'mobx-react';
/** стили */
import { StyledSoftlineSubscribeWizardFooter } from './style';
import { StyledSnoska } from '../../styles';
/** компоненты библиотеки */
import { Button, defaultTheme, Snoska, Text } from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** stores */
import useSoftlineStateModelStore from '../../stores/useSoftlineStateStore';

/** Подвал сайдпейджа подключения подписки */
const SoftlineSubscribeWizardFooter = (): JSX.Element => {
  const {
    authStore: { isTemporaryTokenAuth, balanceUpdate },
    softlineStore: { getSubscriptionInfos },
  } = useRootStore();
  const {
    isLoadingSoftlineState,
    errorMessage,
    subscribe,
  } = useSoftlineStateModelStore();

  return (
    <StyledSoftlineSubscribeWizardFooter>
      <StyledSnoska>
        <Button
          type="button"
          onClick={() => subscribe(getSubscriptionInfos, balanceUpdate)}
          loading={isLoadingSoftlineState}
          disabled={isTemporaryTokenAuth}
        >
          Подключить
        </Button>
        {isTemporaryTokenAuth && (
          <Snoska className="snoska" color={defaultTheme.colors.gray}>
            Действие доступно только клиенту
          </Snoska>
        )}
      </StyledSnoska>
      {errorMessage && (
        <Text
          className="error"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
        >
          {errorMessage}
        </Text>
      )}
    </StyledSoftlineSubscribeWizardFooter>
  );
};

export default observer(SoftlineSubscribeWizardFooter);
