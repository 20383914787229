/** libraries */
import { FC, memo } from 'react';
/** components */
import { defaultTheme, Snoska } from 'cordis-core-ui-planeta';
/** constants */
import { DOCUMENTS_LINK } from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';
import { StyledLink } from '~/components/Blocks/Templates/PaymentResult/Components/DeclinedSBPPayment/Components/style';

const QrLegal: FC = () => {
  return (
    <Snoska>
      Сканируя QR-код, вы&nbsp;подтверждаете согласие с&nbsp;условиями привязки
      счёта, указанными в{'\u00A0'}
      <StyledLink
        color={defaultTheme.colors.planeta}
        href={DOCUMENTS_LINK}
        target="_blank"
      >
        Регламенте
      </StyledLink>
      .
    </Snoska>
  );
};

export default memo(QrLegal);
