/** libraries */
import { FC, useEffect, useState } from 'react';
import {
  Button,
  defaultTheme,
  H3,
  Icon,
  Icons,
  TagButton,
  TagButtonIconPosition,
  Text,
} from 'cordis-core-ui-planeta';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/** utils */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import useWindowSize from '~/utils/useWindowSize';

/** interfaces */
import { Tag } from '../../interfaces';

/** constants */
import { ALL_TAG } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';

/** stores */
import useProductSwitcherStore from '../../store/useProductSwitcherStore';

const Tags: FC = () => {
  const {
    tariffs,
    tagsStore: {
      tags,
      activeProductTag,
      setActiveProductTagCode,
      isThemesOpen,
      setIsThemesOpen,
      defaultTag,
    },
  } = useProductSwitcherStore();
  const {
    authStore: { isAuth },
  } = useRootStore();

  /** Ширина сайта */
  const [width] = useWindowSize();

  // Объект router
  const router = useRouter();
  // Урл страницы
  const url = new URL(document.location.href);

  /** Выбираемый, но не подтверждённый тег в мобильной версии */
  const [
    candidateForActiveTag,
    setCandidateForActiveTag,
  ]: HooksTyping<Tag> = useState<Tag>(null);

  useEffect(() => {
    if (!tags?.length) return;
    const initial = tariffs.length && defaultTag ? defaultTag : ALL_TAG;
    setCandidateForActiveTag(initial);
  }, [defaultTag, tariffs, tags, isAuth, url.hash]);

  // Обработка события нажатия на checkbox с тегом
  const onCheckboxClick = (
    event: React.FormEvent<HTMLInputElement>,
    item: Tag,
  ): void => {
    if (!event) return;

    if (width < desktop940) {
      setCandidateForActiveTag(item);
      return;
    }
    // Обновляет состояние тега по коду маркетинг категории
    changeActiveTag(item);
  };

  /** Обрабатывает клик по кнопке "Показать" */
  const handleTagButtonClick = () => {
    setIsThemesOpen(false);
    // Обновляет состояние тега по коду маркетинг категории
    changeActiveTag(candidateForActiveTag);
  };

  /**
   * Меняет значение активного тега
   * @param item текущий тег
   */
  const changeActiveTag = (item): void => {
    if (item?.tag === activeProductTag?.tag) return;
    if (!item.tag) return;
    setActiveProductTagCode(item.code);
    changeURL(item);
  };

  /**
   * Меняет Url
   * @param item текущий тег
   */
  const changeURL = (item): void => {
    const valueHash = `#${item.tag}`;
    router.push(`${url.pathname}${valueHash}`, `${url.pathname}${valueHash}`, {
      shallow: true,
    });
  };

  useEffect(() => {
    if (width < desktop940 && isThemesOpen) setIsThemesOpen(false);
  }, [width]);

  /** Изменение выбираемого, но не подтверждённого тега в мобильной версии */
  useEffect(() => {
    setCandidateForActiveTag((activeProductTag as unknown) as Tag);
  }, [activeProductTag]);

  return (
    <div className="product-switcher__tags-block">
      {width >= desktop940 ? (
        <Text color={defaultTheme.colors.shadow}>Продукты</Text>
      ) : (
        <div className="product-switcher__tags-block__burger-menu">
          <H3>Продукты</H3>
          <Icon
            icon={isThemesOpen ? <Icons.CloseIcon /> : <Icons.ListIcon />}
            onClick={() => setIsThemesOpen(!isThemesOpen)}
            highlight
          />
        </div>
      )}
      {(width >= desktop940 || isThemesOpen) && activeProductTag && (
        <>
          <div className="product-switcher__tags-block__tags">
            {tags.map((item) => (
              <TagButton
                key={item.code}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  onCheckboxClick(e, item)
                }
                checked={
                  width < desktop940
                    ? item.code === candidateForActiveTag.code
                    : item.code === activeProductTag.code
                }
                iconPosition={TagButtonIconPosition.LEFT}
              >
                {item.name}
              </TagButton>
            ))}
          </div>
          {width < desktop940 && (
            <div className="product-switcher__tags-block__button-wrapper">
              <Button onClick={handleTagButtonClick}>Показать</Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default observer(Tags);
