/** libraries */
import { FC, useState } from 'react';
import Image from 'next/image';
/** components */
import Wrapper, {
  ImageWrapper,
} from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/ImageViewer/style';
import Portal from '~/components/Portal/Portal';
import { Popup } from 'cordis-core-ui-planeta';

interface ImageViewerProps {
  url: string;
  width: number;
  height: number;
  wUnits: string;
  hasImgZoom: boolean;
  headerText: string;
}

const ImageViewer: FC<ImageViewerProps> = ({
  url,
  hasImgZoom,
  wUnits,
  width,
  height,
  headerText,
}: ImageViewerProps) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const imageLoader = ({ src, width: imgWidth, quality }) => {
    return `${src}?w=${imgWidth}&q=${quality || 75}`;
  };

  const openPopup = () => {
    if (!hasImgZoom) return;
    setShowPopup(true);
  };

  const closePopup = () => setShowPopup(false);

  return (
    <Wrapper $hasImgZoom={Boolean(hasImgZoom)}>
      <ImageWrapper
        onClick={openPopup}
        loader={imageLoader}
        src={url}
        alt={headerText}
        width={width}
        height={height}
        quality={100}
      />

      <Portal>
        <Popup
          show={showPopup}
          onCloseClick={closePopup}
          onOutsideClick={closePopup}
          width={`${width * 2}${wUnits}`}
        >
          <Image
            loader={imageLoader}
            src={url}
            alt={headerText}
            width={width * 2}
            height={height * 2}
            quality={100}
          />
        </Popup>
      </Portal>
    </Wrapper>
  );
};

export default ImageViewer;
