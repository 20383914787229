import { defaultModelState } from '~/stores/models/createApiPathModel';
import TransformLimitExceededStore, {
  ITransformLimitExceededStore,
} from './TransformLimitExceededModel';
import { createContext, useContext } from 'react';

const store = TransformLimitExceededStore.create({
  requestsState: {
    getTransformerEstimateDetails: defaultModelState,
  },
  transformLimitDetailsData: null,
  totalAmount: 0,
});

const TransformLimitExceededStoreContext = createContext<ITransformLimitExceededStore>(
  store,
);

const useTransformLimitExceededStore = () => {
  return useContext(TransformLimitExceededStoreContext);
};

export default useTransformLimitExceededStore;
