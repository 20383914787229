/** libraries */
import { types, Instance } from 'mobx-state-tree';

const PinWizardModel = types
  .model({
    isShowPinSidePage: types.boolean,
    /** Номер договора */
    contractNumberForPin: types.string,
    /** Авторизационный токен */
    token: types.string,
  })
  .views((self) => ({
    /** Прошла авторизация по pin */
    get isPinAuth() {
      return !!self.token;
    },
  }))
  .actions((self) => {
    return {
      setIsShowPinSidePage: (isShow: boolean) => {
        self.isShowPinSidePage = isShow;
      },
      setContractNumberForPin: (contractNumber: string) => {
        self.contractNumberForPin = contractNumber;
      },
      setToken: (token: string) => {
        self.token = token;
      },
    };
  });

export default PinWizardModel;
export type IPinWizardStore = Instance<typeof PinWizardModel>;
