/** libraries */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** sidepages */
import DevicePurchasesSP from './SidePages/DevicePurchasesSP';
import DeviceLeasePaySP from './SidePages/DeviceLeasePaySP';
import DeviceCardPurchaseWizardSP from './SidePages/DeviceCardPurchaseWizardSP';
import DetailedDeviceSP from './SidePages/DetailedDeviceSP';
/** utils */
import DeviceSliderList from '~/components/Blocks/Templates/Pab2c/Devices/Components/DeviceSliderList/DeviceSliderList';
/** hooks */
import Portal from '~/components/Portal/Portal';
/** stores */
import { useDevicesStore } from './stores/useDevicesStore';

const Main: FC = () => {
  const {
    detailedDeviceStore: { detailedDevice },
  } = useDevicesStore();

  return (
    <>
      <DeviceSliderList />
      {detailedDevice && (
        <>
          <Portal>
            <DetailedDeviceSP />
          </Portal>
          <Portal wrapperId="portalPrimary">
            <DeviceCardPurchaseWizardSP />
            <DevicePurchasesSP />
            <DeviceLeasePaySP />
          </Portal>
        </>
      )}
    </>
  );
};

export default observer(Main);
