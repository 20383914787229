import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop1100, desktop1280 } from '~/components/Grid/constants';

/** стилизованный блок меню "Панель быстрых ссылок" */
export const StyledContentLayout = styled.div<{
  $isTemporaryTokenAuth: boolean;
}>`
  position: relative;
  width: 100%;

  .layout-wrapper {
    background: ${({ $isTemporaryTokenAuth }) =>
      $isTemporaryTokenAuth
        ? defaultTheme.colors.gray
        : defaultTheme.colors.planeta};
    display: flex;
    justify-content: center;
    height: 60px;
    width: 100%;

    .content {
      padding: 0 24px;
      width: 100%;
      max-width: ${desktop1280}px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

/** стилизованный список с быстрыми ссылками */
export const StyledFastLinks = styled.div`
  display: flex;

  a {
    padding: 0 16px;
    height: 60px;
    display: flex;
    align-items: center;

    &:hover {
      background: ${defaultTheme.colors.pinkSelect};
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

/** стилизованный блок с приветствием */
export const StyledGreeting = styled.div`
  display: flex;
  align-items: center;

  div + span {
    margin-left: 16px;
  }

  @media (min-width: ${desktop1100}px) {
    align-items: baseline;
  }
`;

interface StyledHumbuggerProps extends React.HTMLProps<HTMLDivElement> {
  isOpened?: boolean;
}

export const StyledHumbugger = styled.div<StyledHumbuggerProps>`
  ${({ isOpened = false }): string => `
    outline: none;
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0 14px;
    background: ${isOpened ? defaultTheme.colors.pinkSelect : 'inherit'};
    svg {
      stroke: ${defaultTheme.colors.white};
    }
    &:hover {
      background: ${defaultTheme.colors.pinkSelect};
      cursor: pointer;
    }
  `}
`;
/** Стилизованная версия мобильного "гамбургера" */
export const StyledHumbuggerMobile = styled.div<StyledHumbuggerProps>`
  ${({ isOpened = false }): string => `
    outline: none;
    display: flex;
    height: 60px;
    align-items: center;
    background: ${isOpened ? defaultTheme.colors.pinkSelect : 'inherit'};
    svg {
      stroke: ${defaultTheme.colors.white};
    }
    &:hover {
      background: ${defaultTheme.colors.pinkSelect};
      cursor: pointer;
    }
  `}
`;
