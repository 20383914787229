import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop900 } from '~/components/Grid/constants';

export const StyledResultSuccess = styled.div`
  & .bold {
    font-size: 20px;
    line-height: 24px;
    display: block;
  }
  & .paragraph {
    display: block;
    margin: 1rem 0 0 0;
    & a {
      color: ${defaultTheme.colors.planeta};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const StyledWizard = styled.div`
  .wizard {
    &__container {
      display: flex;
      flex-direction: column;
      margin: 1rem 0 0.5rem;
    }

    &__input {
      margin: 0.6rem 0 1rem 0;

      &-phone {
        width: 100%;
      }
    }
    &__footer {
      &-text {
        margin: 2rem 0 0 0;
        & a {
          text-decoration: underline;
          color: ${defaultTheme.colors.planeta};
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (min-width: ${desktop900}px) {
    .wizard {
      &__input {
        &-phone {
          width: 350px;
        }
      }
    }
  }
`;
export const StyledWizardWarning = styled.p`
  background: ${defaultTheme.colors.backgroundWarning};
  padding: 1rem;
  margin: 1rem auto 2rem auto;
`;
