/** библиотеки */
import React, { useState, useEffect } from 'react';

/** стилевые компоненты */
import { StyledTeaser, StyledImage } from './Teaser.style';

/** типы */
import { TeaserProps, ImageSizeProps } from './Teaser.types';

/* Утилиты */
import {
  getImageSize,
  parseHtml,
} from '~/components/Blocks/Shared/Shared.utils';

/**
 * Компонент Teaser
 */
const Teaser: React.FC<TeaserProps> = ({ content }: TeaserProps) => {
  const { fields } = content;

  /** Масштабируемые размеры изображения */
  const [resultSize, setResultSize] = useState<ImageSizeProps>({
    newWidth: 0,
    newHeight: 0,
  });

  /** Длина и ширина получаемого изображения fields.imgLink */
  const [metaHeight, setMetaHeight] = useState(0);
  const [metaWidth, setMetaWidth] = useState(0);

  /** Получает размеры изображения по url */
  const getMeta = (url: string) => {
    const img = new Image();
    img.onload = () => {
      setMetaHeight(img.height);
      setMetaWidth(img.width);
    };
    img.src = url;
  };

  useEffect(() => {
    getMeta(fields.imgLink);
    setResultSize(getImageSize(metaWidth, metaHeight, 240));
  }, [fields, metaHeight, metaWidth]);

  return (
    <StyledTeaser imgSize={resultSize}>
      {fields.imgLink && (
        <div className="wrapper-img">
          <StyledImage imgSrc={fields.imgLink} imgSize={resultSize} />
        </div>
      )}
      <div>{fields.content && parseHtml(fields.content)}</div>
    </StyledTeaser>
  );
};

export default Teaser;
