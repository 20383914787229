/* eslint-disable camelcase */
import { FC, ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react';
/** style */
import { StyledFillableScale } from './style';
import { ColorsType, defaultTheme } from 'cordis-core-ui-planeta';

export interface TransformerScaleProps {
  /** Текущее значение шкалы */
  value: number;
  /** Максимальное значение шкалы, по-умолчанию 100 */
  maxValue?: number;
  fillerColor?: (scale: number) => ColorsType;
  children?: ReactNode;
}

/** Заполняемая шкала */
const FillableScale: FC<TransformerScaleProps> = ({
  value,
  maxValue = 100,
  fillerColor,
  children,
}: TransformerScaleProps): JSX.Element => {
  const scale = useMemo(() => (value / maxValue) * 100, [value, maxValue]);

  const getFillerColor = () => {
    if (fillerColor) return fillerColor(scale);
    return scale < 100
      ? defaultTheme.colors.green
      : defaultTheme.colors.planeta;
  };

  return (
    <StyledFillableScale
      completed={scale > 100 ? 100 : scale}
      fillerColor={getFillerColor()}
    >
      <div className="scale">
        <div className="scale__filler" />
      </div>
      <span />
      {children}
    </StyledFillableScale>
  );
};

export default observer(FillableScale);
