/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  LeadingText,
  defaultTheme,
  Icon,
  Icons,
  TagButton,
  Button,
  Select,
  Text,
} from 'cordis-core-ui-planeta';
import {
  subMonths,
  eachMonthOfInterval,
  startOfTomorrow,
  addDays,
  endOfMonth,
  isBefore,
  parseISO,
} from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledStatistics } from './styles';
/** interfaces */
import { SelectProps } from '../../interfaces';
/** constants */
import { MOBILE_SELECT_TYPE } from '../../../Contract/FinancialCalculations/constants';
import { desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/constants/common';
/** utils */
import { dateString, withCapitalLetter } from '../../../Contract/utils';
import { formatNumber, getDate, maskPhone } from '~/utils/utils';
import { outsideClickHelper } from '~/utils/outsideClickHelper';
/** stores */
import useVoiceStore from '../../store/useVoiceStore';

const Statistics: FC = () => {
  const {
    statistics,
    beginning,
    end,
    isLoadingStatistics,
    isErrorStatistics,
    isShowStatistics,
    setBeginning,
    setEnd,
    getStatistics,
    setIsShowStatistics,
  } = useVoiceStore();

  /** Итого */
  const [total, setTotal] = useState<number>(0);
  // Выбираемый, но не подтверждённый select начала периода в мобильной версии
  const [
    candidateForBeginning,
    setCandidateForBeginning,
  ] = useState<SelectProps>(null);
  /** Выбираемый, но не подтверждённый select конца периода в мобильной версии */
  const [candidateForEnd, setCandidateForEnd] = useState<SelectProps>(null);
  /** Вид блока с select в мобильной версии */
  const [isMobileSelectOpen, setIsMobileSelectOpen] = useState<boolean>(false);
  /** Тип select */
  const [mobileSelectType, setMobileSelectType] = useState<MOBILE_SELECT_TYPE>(
    null,
  );
  /** Значения фильтров по месяцам */
  const periodData: SelectProps[] = useMemo(() => {
    const currentDate = new Date();
    const startDate = subMonths(currentDate, 3);

    const monthsInYear = eachMonthOfInterval({
      start: startDate,
      end: currentDate,
    });

    const tomorrow = startOfTomorrow();
    return monthsInYear.map((month, index) => {
      const label = dateString(month, 'LLLL yyyy');
      const value = `${dateString(month, 'yyyy-MM-dd')} ${dateString(
        index === monthsInYear?.length - 1
          ? tomorrow
          : addDays(endOfMonth(month), 1),
        'yyyy-MM-dd',
      )}`;
      return { label: withCapitalLetter(label), value };
    });
  }, []);
  /** Варианты select для начала периода */
  const beginningPeriodData = useMemo(() => {
    return end
      ? periodData.filter(
          (item) => !isBefore(parseISO(end.value), parseISO(item.value)),
        )
      : periodData;
  }, [periodData, end]);
  /** Варианты select для конца периода */
  const endPeriodData = useMemo(() => {
    return beginning
      ? periodData.filter(
          (item) => !isBefore(parseISO(item.value), parseISO(beginning.value)),
        )
      : periodData;
  }, [periodData, beginning]);
  useEffect(() => {
    if (periodData.length) {
      setBeginning(periodData[periodData.length - 2]);
      setEnd(periodData[periodData.length - 1]);
    }
  }, [periodData]);

  /** Select`ы заполнены */
  const isFullPeriod = beginning && end;

  const buttonHandler = async () => {
    await getStatistics();
    setIsShowStatistics(true);
  };

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  useEffect(() => {
    if (isDesktop940 && isMobileSelectOpen) setIsMobileSelectOpen(false);
  }, [isDesktop940]);

  const applyMobileSelect = () => {
    setBeginning(candidateForBeginning);
    setEnd(candidateForEnd);
    setIsMobileSelectOpen(false);
  };

  const statisticsData = useMemo(() => {
    let sum = 0;
    const data = statistics.reduce((acc, item) => {
      const clientNumber = item.isIncome ? item.phoneNumber : item.fromNumber;
      const prevPhone = acc.find((phone) => phone.phone === clientNumber);
      if (prevPhone) {
        const mapAcc = acc.map((prevItem) => {
          if (prevItem.phone === clientNumber) {
            sum += item.sum;
            return {
              ...prevItem,
              sum: prevItem.sum + item.sum,
              calls: [...prevItem.calls, item],
            };
          }
          return prevItem;
        });
        return mapAcc;
      }
      sum += item.sum;
      acc.push({
        phone: clientNumber,
        sum: item.sum,
        calls: [item],
      });
      return acc;
    }, []);
    setTotal(sum);
    return data;
  }, [statistics]);

  const mobileSelect = () => {
    return (
      <StyledStatistics>
        <div className="statistics__mobile-select">
          <div className="statistics__mobile-select__header">
            <LeadingText color={defaultTheme.colors.black}>
              {mobileSelectType === MOBILE_SELECT_TYPE.BEGIN
                ? 'Начало периода'
                : 'Окончание периода'}
            </LeadingText>
            <Icon
              icon={<Icons.CloseIcon />}
              onClick={() => {
                setIsMobileSelectOpen(!isMobileSelectOpen);
                setCandidateForBeginning(beginning);
                setCandidateForEnd(end);
              }}
              highlight
            />
          </div>
          {periodData?.map((item) => {
            return (
              <TagButton
                key={item.label}
                className="statistics__mobile-select__tag-button"
                onChange={() =>
                  mobileSelectType === MOBILE_SELECT_TYPE.BEGIN
                    ? setCandidateForBeginning(item)
                    : setCandidateForEnd(item)
                }
                checked={
                  mobileSelectType === MOBILE_SELECT_TYPE.BEGIN
                    ? item === candidateForBeginning
                    : item === candidateForEnd
                }
                disabled={
                  (mobileSelectType === MOBILE_SELECT_TYPE.BEGIN &&
                    end &&
                    isBefore(parseISO(end.value), parseISO(item.value))) ||
                  (mobileSelectType === MOBILE_SELECT_TYPE.END &&
                    beginning &&
                    isBefore(parseISO(item.value), parseISO(beginning.value)))
                }
              >
                {item.label}
              </TagButton>
            );
          })}
          <Button onClick={applyMobileSelect}>Применить</Button>
        </div>
      </StyledStatistics>
    );
  };

  const selectPeriod = () => {
    return (
      <LeadingText color={defaultTheme.colors.black}>
        Выберите период
      </LeadingText>
    );
  };

  const loader = () => {
    return (
      <Text lineHeight="24px">Загрузка данных. Пожалуйста, подождите...</Text>
    );
  };

  const noDataAvailable = () => {
    return <Text lineHeight="24px">Нет данных за выбранный период</Text>;
  };

  const error = () => {
    return <Text lineHeight="24px">{DEFAULT_ERROR}</Text>;
  };

  const table = () => {
    return (
      <StyledStatistics>
        <div className="statistics__block">
          {statisticsData.map((data) => {
            return (
              <>
                <div className="statistics__block__main-line">
                  <Text lineHeight="24px" fontWeightBold>
                    {data.phone}
                  </Text>
                  <Text lineHeight="24px" fontWeightBold>
                    {formatNumber(data.sum)} ₽
                  </Text>
                </div>
                {data.calls.map((item) => {
                  return (
                    <div className="statistics__block__line">
                      <div className="statistics__block__line__icon">
                        {item.isIncome ? <Icons.DownIcon /> : <Icons.UpIcon />}
                        <Text
                          className="statistics__block__line__phone"
                          lineHeight="20px"
                        >
                          {item.isIncome
                            ? maskPhone(item.fromNumber)
                            : maskPhone(item.phoneNumber)}
                        </Text>
                      </div>
                      {!isDesktop940 && (
                        <LeadingText color={defaultTheme.colors.black}>
                          {formatNumber(item.sum)} ₽
                        </LeadingText>
                      )}
                      <Text
                        className="statistics__block__line__date"
                        lineHeight="20px"
                        color={defaultTheme.colors.shadow}
                      >
                        {getDate(item.date, 'd MMM yyyy')} до{' '}
                        {getDate(item.date, 'HH:mm:ss')}, {item.duration} мин
                      </Text>
                      {isDesktop940 && (
                        <Text
                          className="statistics__block__line__price"
                          lineHeight="20px"
                        >
                          {formatNumber(item.sum)} ₽
                        </Text>
                      )}
                    </div>
                  );
                })}
              </>
            );
          })}
          {isDesktop940 && (
            <div className="statistics__block__main-line">
              <Text lineHeight="24px" fontWeightBold>
                Итого за период для всех номеров
              </Text>
              <Text lineHeight="24px" fontWeightBold>
                {formatNumber(total)} ₽
              </Text>
            </div>
          )}
          {!isDesktop940 && (
            <div className="statistics__block__total-mobile">
              <Text lineHeight="24px">
                ИТОГО ЗА&nbsp;ПЕРИОД ДЛЯ&nbsp;ВСЕХ НОМЕРОВ
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {formatNumber(total)} ₽
              </LeadingText>
            </div>
          )}
        </div>
      </StyledStatistics>
    );
  };

  const content = () => {
    if (isErrorStatistics) return error();
    if (isLoadingStatistics) return loader();
    if (isFullPeriod && isShowStatistics) {
      return statistics.length ? table() : noDataAvailable();
    }
    return selectPeriod();
  };

  /** Мобильный select */
  if (isMobileSelectOpen && !isDesktop940 && periodData?.length > 0) {
    return mobileSelect();
  }

  return (
    <StyledStatistics>
      <div className="statistics__select">
        <div
          onClick={() => {
            if (!isDesktop940) {
              setIsMobileSelectOpen(true);
              setMobileSelectType(MOBILE_SELECT_TYPE.BEGIN);
            }
          }}
        >
          <Select
            placeholder="Начало периода"
            onOptionClick={(option) => {
              setBeginning(option);
              setCandidateForBeginning(option);
            }}
            visibleOptionCount={4}
            value={beginning ? beginning.value : ''}
            outsideClickHelper={outsideClickHelper}
            data={beginningPeriodData}
          />
        </div>
        <div
          onClick={() => {
            if (!isDesktop940) {
              setIsMobileSelectOpen(true);
              setMobileSelectType(MOBILE_SELECT_TYPE.END);
            }
          }}
        >
          <Select
            placeholder="Окончание периода"
            onOptionClick={(option) => {
              setEnd(option);
              setCandidateForEnd(option);
            }}
            visibleOptionCount={4}
            value={end ? end.value : ''}
            outsideClickHelper={outsideClickHelper}
            data={endPeriodData}
          />
        </div>
        <Button onClick={buttonHandler} loading={isLoadingStatistics}>
          Показать
        </Button>
      </div>
      {content()}
    </StyledStatistics>
  );
};

export default observer(Statistics);
