/** библиотеки */
import React, { FC } from 'react';
import Image from 'next/image';
/** утилиты */
import { isExternal } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import LinkWrapper from '~/components/LinkWrapper';
/** компоненты библиотеки */
import { Text, defaultTheme } from 'cordis-core-ui-planeta';
/** стили */
import { StyledFeedbackCustomBlock, StyledRatingCustom } from './style';
/** константы */
import { DEFAULT_IMAGE, STAR_ICON } from './constants';
/** интерфейсы */
import { FeedbackProps } from './types';

/**
 * компонент Кастомный Отзыв
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=403095610
 */
const FeedbackCustom: FC<FeedbackProps> = ({ content }: FeedbackProps) => {
  const { imgLink, name, textName, rating, date, text, link } = content.fields;
  // Ссылка на изображение
  const imageSrc = isExternal(imgLink)
    ? imgLink
    : `${process.env.STATIC_SERVER}${imgLink || DEFAULT_IMAGE}`;

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <LinkWrapper href={link}>
      <StyledFeedbackCustomBlock>
        <div className="feedback-block">
          <Image
            loader={imageLoader}
            src={imageSrc}
            alt="Аватар пользователя"
            width={50}
            height={50}
            quality={100}
          />
          <div className="feedback-block__header">
            <Text>{name}</Text>
            <Text color={defaultTheme.colors.disable}>{textName}</Text>
          </div>
        </div>
        <div className="feedback-block__rating">
          <StyledRatingCustom>
            {Array.from({ length: rating }, (_, i) => (
              <li key={i}>
                <img src={STAR_ICON} alt="Star" />
              </li>
            ))}
          </StyledRatingCustom>
          <Text color={defaultTheme.colors.disable}>{date}</Text>
        </div>
        <div className="feedback-block__text">
          <Text lineHeight="24px">{parseHtml(text)}</Text>
        </div>
      </StyledFeedbackCustomBlock>
    </LinkWrapper>
  );
};

export default FeedbackCustom;
