/** libraries */
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import SoftlineSubscribeWizard from '~/components/Blocks/Templates/Softline/SoftlineSubscribeWizard/SoftlineSubscribeWizard';
import SoftlineSubscribeWizardFooter from '~/components/Blocks/Templates/Softline/SoftlineSubscribeWizard/SoftlineSubscribeWizardFooter/SoftlineSubscribeWizardFooter';
import SoftlineSubscribeWizardSuccess from '~/components/Blocks/Templates/Softline/SoftlineSubscribeWizard/SoftlineSubscribeWizardSuccess/SoftlineSubscribeWizardSuccess';
/** stores */
import useSoftlineStateModelStore from '~/components/Blocks/Templates/Softline/stores/useSoftlineStateStore';

/* Сайдпейдж подключения подписки Softline */
export const SoftlineSubscribeSP = (): JSX.Element => {
  const {
    softlineDescription,
    isOpenSubscribeWizard,
    isOpenSubscribeWizardSuccess,
    setIsOpenSubscribeWizardSuccess,
    onCloseSubscribeSP,
  } = useSoftlineStateModelStore();
  return (
    <>
      <SidePage
        show={isOpenSubscribeWizard}
        headerText={
          softlineDescription &&
          `Подключение подписки ${softlineDescription.name}`
        }
        headerTitleText={
          softlineDescription && softlineDescription.nameFromMethod
        }
        onCloseClick={onCloseSubscribeSP}
        removeScrollBar
        showMobileHeader
        footerContainer={<SoftlineSubscribeWizardFooter />}
        isOnlyMobileFooter
      >
        <SoftlineSubscribeWizard />
      </SidePage>
      <SidePage
        show={isOpenSubscribeWizardSuccess}
        onCloseClick={() => setIsOpenSubscribeWizardSuccess(false)}
        removeScrollBar
        showMobileHeader
      >
        <SoftlineSubscribeWizardSuccess />
      </SidePage>
    </>
  );
};

export default observer(SoftlineSubscribeSP);
