import * as React from 'react';
import { Row } from '~/components/Grid/Row';
import { Col } from '~/components/Grid/Col';
import { BlockSelector } from '~/components/Blocks';
import { BlocksRowProps } from './FeedPage.types';

/**
 * Показывает 1 строку с блоками.
 * В зависимости от размеров перечисляемых блоков, в неё может попасть от 1 до 3 блоков.
 * Внимание, максимальный размер строки 3. Если потребуется более гибкое поведение,
 * необходимо создать в CMS блок, в который добавить необходимое количество дочерних блоков, как поля блока.
 * В компоненте блока можно более гибко прописать правила отображения.
 * @param blocks блоки
 * @param noPadding без отступа
 * @param rowsColors цвет фона rows
 */
export const BlocksRow: React.FC<BlocksRowProps> = ({
  blocks,
  noPadding = false,
  rowsColors = {},
}: BlocksRowProps) => {
  const rows = [];

  /** цвет фона row */
  const getRowColor = () => {
    const index = Object.values(rowsColors).findIndex((item) =>
      item.includes(rows.length + 1),
    );
    if (index === -1) return '';
    return Object.keys(rowsColors)[index];
  };

  for (let i = 0; i < blocks.length; i += 1) {
    const { size } = blocks[i];
    const { size: size2 } = blocks[i + 1] || {};
    const { size: size3 } = blocks[i + 2] || {};
    const isRenderBlock2 = size + size2 <= 3;
    const isRenderBlock3 = size + size2 + size3 <= 3;

    rows.push(
      <Row
        className="row"
        noPadding={noPadding}
        key={i}
        backgroundColor={getRowColor()}
      >
        <Col size={size}>
          <BlockSelector key={i} block={blocks[i]} />
        </Col>
        {isRenderBlock2 && (
          <Col size={size2}>
            <BlockSelector key={i + 1} block={blocks[i + 1]} />
          </Col>
        )}
        {isRenderBlock3 && (
          <Col size={size3}>
            <BlockSelector key={i + 2} block={blocks[i + 2]} />
          </Col>
        )}
      </Row>,
    );
    if (isRenderBlock3) i += 2;
    else if (isRenderBlock2) i += 1;
  }
  return <>{rows}</>;
};
