/** libraries */
import { FC } from 'react';

/** components */
import ServiceViewControlCard from '~/components/Blocks/Templates/Pab2c/MyTV/Components/Cards/ServiceCards/ServiceViewControlCard';
import ServiceWinkCard from '~/components/Blocks/Templates/Pab2c/MyTV/Components/Cards/ServiceCards/ServiceWinkCard';

/** interfaces */
import { ServiceCardFieldsProps } from './interfaces';
import { ServiceCode } from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

const ServiceCardWrapper: FC<ServiceCardFieldsProps> = ({
  ...props
}: ServiceCardFieldsProps) => {
  switch (props.service.code) {
    case ServiceCode.wink:
      return <ServiceWinkCard {...props} />;

    case ServiceCode.viewControl:
      return <ServiceViewControlCard {...props} />;

    default:
      return <></>;
  }
};

export default ServiceCardWrapper;
