import styled from '@emotion/styled';
import { desktop1100, desktop1280 } from '~/components/Grid/constants';

export const StyledSuccessScreen = styled.div`
  svg {
    margin-bottom: 24px;
    height: 100px;
    width: 100px;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }

  .success {
    &__date {
      margin-bottom: 24px;
    }

    &__button {
      margin-top: 24px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    h1 {
      font-size: 44px;
      line-height: 52px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    svg {
      margin-bottom: 26.5px;
      height: 120px;
      width: 120px;
    }
  }
`;
