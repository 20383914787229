/** интерфейсы */
import { Block } from '~/interfaces/BlockInterface';
import { ConnectionMethod } from '~/constants/common';

/** поля блока */
export interface SpeedFields {
  seriesId: number;
  seriesCode: string;
  seriesName: string;
  speedBase: number;
  speedBaseText: string;
  connectionMethod: ConnectionMethod;
}

/** контент блока */
export interface SpeedContent extends Block {
  fields: SpeedFields;
}

/** Типы пропсов скорости */
export interface SpeedProps {
  content: SpeedContent;
}

export enum PROGRESS_STYLE_TYPES {
  'colored',
  'white',
}

/** типы пропсов прогресса */
export interface SpeedProgressProps {
  styleType: PROGRESS_STYLE_TYPES;
  progress: number;
}

/** значения по умолчанию если блок не на странице продукта */
export const DEFAULT_SPEED_CONTENT: SpeedFields = {
  connectionMethod: ConnectionMethod.FTTH,
  seriesCode: 'default',
  seriesId: 0,
  seriesName: 'default',
  speedBase: 1000000000,
  speedBaseText: '1 Гбит/c',
};

/** значение для преобразования базового значения скорости подключения */
export const READABLE_SPEED = 1000000;

/** описание и заголовки */
export const TEXT = {
  10: {
    title: 'Безлимитный интернет',
    description: `Быстрый интернет-сёрфинг, мгновенное скачивание документов и${'\u00A0'}музыки`,
  },
  20: {
    title: 'Безлимитный интернет',
    description: `Быстрый интернет-сёрфинг, мгновенное скачивание документов и${'\u00A0'}музыки`,
  },
  30: {
    title: 'Безлимитный интернет',
    description: `Быстрый интернет-сёрфинг, мгновенное скачивание документов и${'\u00A0'}музыки`,
  },
  50: {
    title: 'Безлимитный интернет',
    description: `Быстрый интернет-сёрфинг, мгновенное скачивание документов и${'\u00A0'}музыки`,
  },
  70: {
    title: 'Безлимитный интернет',
    description:
      'Быстрый интернет-сёрфинг, онлайн-игры без лагов, стабильная скорость при скачивании тяжёлого контента',
  },
  100: {
    title: 'Реактивный интернет',
    description: `Онлайн-игры, тяжёлый контент и${'\u00A0'}стриминговое видео в${'\u00A0'}высоком качестве. Все устройства в${'\u00A0'}доме скажут вам спасибо, даже умный холодильник`,
  },
  1000: {
    title: 'СверхИнтернет',
    description: `Флагманская технология от${'\u00A0'}Планеты для тех, кто не${'\u00A0'}идёт на${'\u00A0'}компромиссы. Гигабита хватит всей семье, даже если качать и${'\u00A0'}смотреть 4К одновременно`,
  },
};
