/** libraries */
import { FC } from 'react';
/** interfaces */
import { NozzleProps } from './interfaces';
/** components */
import Wrapper, { StyledCircle } from './style';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** Первый и последний элемент таймлайна */
const Nozzle: FC<NozzleProps> = ({ isEnd, content }: NozzleProps) => {
  return (
    <Wrapper>
      {parseHtml(content)}
      <StyledCircle isEnd={isEnd} />
    </Wrapper>
  );
};

export default Nozzle;
