/** libraries */
import { FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
/** components */
import Wrapper, { PlayerCircle } from './style';
import { Icons, Popup } from 'cordis-core-ui-planeta';
import Portal from '~/components/Portal/Portal';

// todo: объединить с Video
const VideoPlayer: FC<{ video: string }> = ({ video }) => {
  const [startPlayer, setStartPlayer] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const closePopup = () => {
    setShowPopup(false);
    setStartPlayer(false);
  };

  const onPlayClick = () => {
    setStartPlayer(true);
    setShowPopup(true);
  };

  /** Высота экрана */
  const windowInnerHeight = document.documentElement.clientHeight;

  /** Ширина popup */
  const popupWidth = windowInnerHeight * 0.8;

  /** 0.5625 - соотношение 360/640 */
  const playerHeight = popupWidth * 0.5625;

  return (
    <Wrapper>
      {!startPlayer && (
        <PlayerCircle icon={<Icons.PlayerButtonIcon />} onClick={onPlayClick} />
      )}
      <ReactPlayer
        url={`${process.env.STATIC_SERVER}/${video}`}
        height="100%"
        width="100%"
      />

      <Portal>
        <Popup
          show={showPopup}
          onCloseClick={closePopup}
          onOutsideClick={closePopup}
          width={!isMobile && `${popupWidth.toString()}px`}
        >
          <ReactPlayer
            url={`${process.env.STATIC_SERVER}/${video}`}
            height={playerHeight}
            width="100%"
            controls={startPlayer}
            playing={startPlayer}
          />
        </Popup>
      </Portal>
    </Wrapper>
  );
};

export default VideoPlayer;
