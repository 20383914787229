/** libraries */
import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  H3,
  Icon,
  Icons,
  LeadingText,
  Loader,
  OptionProp,
  Select,
  Snoska,
  Tabs,
  TabsStyleTypes,
  TagButton,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { isAfter, parseISO } from 'date-fns';
import { observer } from 'mobx-react';
/** styles */
import { StyledConnectedNumbers, StyledNumbers, StyledPhones } from './styles';
/** interfaces */
import { Phone } from '../../interfaces';
/** constants */
import { DEFAULT_ERROR, HELP_PLANETA_URL } from '~/constants/common';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { SELECTS, TABS } from '../../constants';
/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';
import { getDate, formatNumber } from '~/utils/utils';
import { maskHomePhone } from '../../utils';
import LinkWrapper from '~/components/LinkWrapper';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** api */
import { getPhoneUnbindAgreement, phoneUnbindCheck } from '~/api/apiPab2c';
import useVoiceStore from '../../store/useVoiceStore';

const Phones: FC = () => {
  const {
    pab2cVoiceStore: { phones, isLoading, isCorrect, phonesMaintainPrice },
    summaryDataStore: { summaryData },
    authStore: { isLoadingAuth },
  } = useRootStore();
  const {
    setIsShowPassword,
    setIsCreatePassword,
    setPhonePassword,
    setIsShowCancelNumberDeactivation,
    setSim,
    setIsShowNumberDeactivation,
    setResult,
    isLoadingConnectNumber,
    setAgreement,
  } = useVoiceStore();

  /** Индекс активного таба */
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [select, setSelect] = useState<OptionProp>(SELECTS[0]);
  /** Вид блока с select в мобильной версии */
  const [isMobileSelectOpen, setIsMobileSelectOpen] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый select начала периода в мобильной версии
  const [candidateForSelect, setCandidateForSelect] = useState<OptionProp>(
    SELECTS[0],
  );
  /** Загрузка отключения номера телефона */
  const [isLoadingDisableNumber, setIsLoadingDisableNumber] = useState<number>(
    null,
  );

  /** Соглашение для отключения номера */
  const getUnbindAgreement = async (simId: number) => {
    try {
      const res = await getPhoneUnbindAgreement(simId);
      if (res) {
        setAgreement(res);
        setIsShowNumberDeactivation(true);
      }
      setIsLoadingDisableNumber(null);
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
      setIsLoadingDisableNumber(null);
      setIsShowNumberDeactivation(true);
    }
  };

  /** Отключить номер */
  const disableNumber = async (phoneNumber: Phone) => {
    setIsLoadingDisableNumber(phoneNumber.sim);
    setSim(phoneNumber.sim);
    setPhonePassword(phoneNumber);
    const check = await checkPhoneUnbind(phoneNumber.sim);
    if (check) {
      getUnbindAgreement(phoneNumber.sim);
    }
  };

  /** Проверка возможности отключения номера */
  const checkPhoneUnbind = async (simId: number): Promise<boolean> => {
    try {
      await phoneUnbindCheck(simId);
      return true;
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
      setIsLoadingDisableNumber(null);
      setIsShowNumberDeactivation(true);
      return false;
    }
  };

  /** Отменить отключение номера */
  const cancelNumberDisconnection = (phoneNumber: Phone) => {
    setIsShowCancelNumberDeactivation(true);
    setSim(phoneNumber.sim);
    setPhonePassword(phoneNumber);
  };

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  /* Возвращает компонент при переключении табов */
  const getTabContent = (tabIndex: number): JSX.Element => {
    switch (tabIndex) {
      case 0:
        return (
          <StyledNumbers>
            <div className="numbers__block local">
              <LeadingText color={defaultTheme.colors.black}>
                379-00-00
              </LeadingText>
              <Snoska>Номер абонента</Snoska>
            </div>
          </StyledNumbers>
        );
      case 1:
        return (
          <StyledNumbers>
            <div className="numbers__block russia">
              <LeadingText color={defaultTheme.colors.black}>8</LeadingText>
              <Snoska>Код выбора соединения</Snoska>
            </div>
            <div className="numbers__block russia">
              <LeadingText color={defaultTheme.colors.black}>343</LeadingText>
              <Snoska>Код города</Snoska>
            </div>
            <div className="numbers__block russia">
              <LeadingText color={defaultTheme.colors.black}>
                379-00-00
              </LeadingText>
              <Snoska>Номер абонента</Snoska>
            </div>
          </StyledNumbers>
        );
      case 2:
        return (
          <StyledNumbers>
            <div className="numbers__block international">
              <LeadingText color={defaultTheme.colors.black}>810</LeadingText>
              <Snoska>
                Код{!isDesktop1280 && <br />} выбора{' '}
                {isDesktop1280 && 'соединения'}
              </Snoska>
            </div>
            <div className="numbers__block international">
              <LeadingText color={defaultTheme.colors.black}>509</LeadingText>
              <Snoska>Код страны</Snoska>
            </div>
            <div className="numbers__block international">
              <LeadingText color={defaultTheme.colors.black}>33</LeadingText>
              <Snoska>
                Код города{isDesktop1280 ? ' или' : ','} оператора
              </Snoska>
            </div>
            <div className="numbers__block international">
              <LeadingText color={defaultTheme.colors.black}>
                89-9090
              </LeadingText>
              <Snoska>Номер {!isDesktop1280 && <br />}абонента</Snoska>
            </div>
          </StyledNumbers>
        );
      case 3:
        return (
          <StyledNumbers>
            <div className="numbers__block-without-border">
              <LeadingText color={defaultTheme.colors.black}>01</LeadingText>
              <Snoska>
                Пожарная
                <br />
                служба
              </Snoska>
            </div>
            <div className="numbers__block-without-border">
              <LeadingText color={defaultTheme.colors.black}>02</LeadingText>
              <Snoska>
                Полиция{' '}
                {!isDesktop940 && (
                  <>
                    <br /> города
                  </>
                )}
              </Snoska>
            </div>
            <div className="numbers__block-without-border">
              <LeadingText color={defaultTheme.colors.black}>03</LeadingText>
              <Snoska>
                Скорая {isDesktop940 ? <br /> : 'медицинская'} помощь
              </Snoska>
            </div>
            <div className="numbers__block-without-border">
              <LeadingText color={defaultTheme.colors.black}>112</LeadingText>
              <Snoska>
                Единый {isDesktop940 && <br />} номер{' '}
                {!isDesktop940 && 'вызова экстренных служб'}
              </Snoska>
            </div>
          </StyledNumbers>
        );
      default:
        return <></>;
    }
  };

  const applyMobileSelect = () => {
    setSelect(candidateForSelect);
    setActiveTabIndex(Number(candidateForSelect.value));
    setIsMobileSelectOpen(false);
  };

  /** Мобильный select */
  const mobileSelect = () => {
    return (
      <StyledPhones>
        <div className="phone__mobile-select">
          <div className="phone__mobile-select__header">
            <LeadingText color={defaultTheme.colors.black}>Номера</LeadingText>
            <Icon
              icon={<Icons.CloseIcon />}
              onClick={() => {
                setIsMobileSelectOpen(!isMobileSelectOpen);
                setCandidateForSelect(select);
              }}
              highlight
            />
          </div>
          {SELECTS.map((item) => {
            return (
              <TagButton
                key={item.label}
                className="phone__mobile-select__tag-button"
                onChange={() => setCandidateForSelect(item)}
                checked={item.value === candidateForSelect.value}
              >
                {item.label}
              </TagButton>
            );
          })}
          <Button onClick={applyMobileSelect}>Применить</Button>
        </div>
      </StyledPhones>
    );
  };

  if (isMobileSelectOpen && !isDesktop940) {
    return mobileSelect();
  }

  const isAfterToday = (item) => isAfter(parseISO(item), new Date());

  const unbindDate = (item: Phone) => {
    if (item.unbindDate) {
      return 'Будет отключён в 00:00';
    }
    if (item.unbindBanTrimDate && isAfterToday(item.unbindBanTrimDate)) {
      return `Отключение возможно ${
        isDesktop940 && !isDesktop1100 ? '' : 'c'
      } ${getDate(
        item.unbindBanTrimDate,
        isDesktop940 ? `dd.MM.${!isDesktop1100 ? '' : 'yy'}yy` : 'd MMMM yyyy',
      )}`;
    }
    return '';
  };

  /** Создать/изменить пароль */
  const changePassword = (item: Phone) => {
    setIsShowPassword(true);
    setIsCreatePassword(!item.isPasswordSet);
    setPhonePassword(item);
  };

  /** Стоимость обслуживания номеров */
  const maintainPriceTag = () => {
    const price = phonesMaintainPrice(summaryData.phonesCount);
    return price > 0 ? `${formatNumber(price)} ₽ в день` : 'Бесплатно';
  };

  const connectedNumbers = () => {
    return (
      <StyledConnectedNumbers>
        <LeadingText
          className="connected__header"
          color={defaultTheme.colors.shadow}
        >
          Подключённые номера
        </LeadingText>
        <div className="connected__phones-block">
          {phones.map((item) => (
            <div key={item.sim} className="connected__phones-block__item">
              <div className="connected__phones-block__item__phone">
                <LeadingText color={defaultTheme.colors.black}>
                  {maskHomePhone(item)}
                </LeadingText>
                <Snoska
                  onClick={() => changePassword(item)}
                  color={defaultTheme.colors.planeta}
                >
                  {item.isPasswordSet ? 'Изменить пароль' : 'Создать пароль'}
                </Snoska>
              </div>
              {(item.unbindDate ||
                (item.unbindBanTrimDate &&
                  isAfterToday(item.unbindBanTrimDate))) && (
                <div className="connected__phones-block__item__not-unbind">
                  {item.unbindDate && (
                    <Snoska
                      className="connected__cursor-pointer"
                      onClick={() => cancelNumberDisconnection(item)}
                      color={defaultTheme.colors.planeta}
                    >
                      Отменить отключение
                    </Snoska>
                  )}
                  {unbindDate(item) && (
                    <Snoska color={defaultTheme.colors.shadow}>
                      {unbindDate(item)}
                    </Snoska>
                  )}
                </div>
              )}
              {item.canUnbind && !item.unbindDate && (
                <>
                  {isLoadingDisableNumber &&
                  item.sim === isLoadingDisableNumber ? (
                    <Loader small />
                  ) : (
                    <Snoska
                      className="connected__cursor-pointer"
                      onClick={() => disableNumber(item)}
                      color={defaultTheme.colors.planeta}
                    >
                      Отключить
                    </Snoska>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </StyledConnectedNumbers>
    );
  };

  /** Настройка подключения голосовой связи */
  const settingUp = () => {
    return (
      <div className="phone__settingUp">
        <LinkWrapper href={`${HELP_PLANETA_URL}/glavnaya-2/voip/`}>
          <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
            Настройка подключения{' '}
            {(isDesktop1280 || !isDesktop940) && 'голосовой связи'}
          </Text>
        </LinkWrapper>
      </div>
    );
  };
  /** Загрузка */
  if (isLoadingAuth || (isLoading && !isLoadingConnectNumber)) {
    return (
      <StyledPhones>
        <div className="phone__loader">
          <Loader />
        </div>
      </StyledPhones>
    );
  }

  /** Ошибка */
  if (!isCorrect) {
    return (
      <StyledPhones>
        <div className="phone__error">
          <Text lineHeight="24px">{DEFAULT_ERROR}</Text>
        </div>
      </StyledPhones>
    );
  }

  if (!phones.length) {
    return (
      <StyledPhones>
        <div className="phone__not-found">
          <LeadingText color={defaultTheme.colors.black}>
            Телефонные номера не добавлены
          </LeadingText>
          <Text lineHeight="24px" color={defaultTheme.colors.gray}>
            Подключите местный телефонный номер с возможностью доступа к
            междугородним и международным услугам телефонной связи
          </Text>
          <LinkWrapper href={`${HELP_PLANETA_URL}/glavnaya-2/voip/`}>
            Настройка подключения голосовой связи
          </LinkWrapper>
        </div>
      </StyledPhones>
    );
  }

  return (
    <StyledPhones>
      <div className="phone__info">
        <div className="phone__info__price-block">
          <div className="phone__info__price-block__header">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Обслуживание {(isDesktop1100 || !isDesktop940) && 'номеров'}
            </Text>
          </div>
          {isDesktop1280 ? (
            <H3>{maintainPriceTag()}</H3>
          ) : (
            <LeadingText color={defaultTheme.colors.black}>
              {maintainPriceTag()}
            </LeadingText>
          )}
        </div>
        {!isDesktop940 && (
          <>
            {settingUp()}
            {connectedNumbers()}
          </>
        )}

        <div className="phone__info__instructions">
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Как звонить?
          </Text>
          {isDesktop1280 ? (
            <div className="phone__info__tabs">
              <Tabs
                value={TABS}
                onChange={(tabIndex) => {
                  setActiveTabIndex(tabIndex);
                  setSelect(SELECTS[tabIndex]);
                }}
                styleType={TabsStyleTypes.SECONDARY}
                activeTabIndex={activeTabIndex}
              />
            </div>
          ) : (
            <div
              className="phone__info__select"
              onClick={() => {
                if (!isDesktop940) {
                  setIsMobileSelectOpen(true);
                }
              }}
            >
              <Select
                onOptionClick={(option) => {
                  setSelect(option);
                  setActiveTabIndex(Number(option.value));
                }}
                value={select ? select.value : ''}
                outsideClickHelper={outsideClickHelper}
                data={SELECTS}
              />
            </div>
          )}
          {getTabContent(activeTabIndex)}
          {isDesktop940 && settingUp()}
        </div>
      </div>
      {isDesktop940 && connectedNumbers()}
    </StyledPhones>
  );
};

export default observer(Phones);
