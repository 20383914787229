/** Библиотеки */
import React, { FC } from 'react';
/** Стили */
import { StyledFooter } from './IPv6StateWizard.style';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** Типы */
import { IPv6StateWizardFooterProps } from './IPv6StateWizard.types';

const IPv6StateWizardFooter: FC<IPv6StateWizardFooterProps> = ({
  handleSubmit,
  isActionLoading,
  isTemporaryTokenAuth,
}: IPv6StateWizardFooterProps) => {
  return (
    <StyledFooter>
      <Button
        type="button"
        onClick={handleSubmit}
        loading={isActionLoading}
        disabled={isTemporaryTokenAuth}
      >
        Продолжить
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default React.memo(IPv6StateWizardFooter);
