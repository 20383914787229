/** libraries */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
/** components */
import { defaultTheme, H3, Button, Text } from 'cordis-core-ui-planeta';
/** types */
import { StyledAutoPayBGProps, StyledAutoPayProps } from './types';
/** constants */
import {
  desktop1100,
  desktop1280,
  desktop420,
  desktop940,
} from '~/components/Grid/constants';

export const StyledHeader = styled(H3)`
  position: relative;
  font-size: 28px;
  font-weight: 700;
  line-height: 1em;
  margin: 0 0 16px 0;
`;

export const StyledText = styled(Text)`
  position: relative;
  max-width: 180px;
  letter-spacing: -0.03em;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px 0;

  @media (min-width: ${desktop420}px) {
    max-width: 220px;
  }

  @media (min-width: ${desktop940}px) {
    max-width: 180px;
  }
`;

export const MainText = styled(Text)`
  letter-spacing: -0.05em;
  line-height: 24px;
  margin: 0 0 10px 0;
`;

export const MainHeader = styled(H3)`
  letter-spacing: -0.05em;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 20px 0;
`;

export const StyledMain = styled.main``;

export const StyledButton = styled(Button)`
  width: 191px;
  height: 54px;
  display: flex;
`;

export default styled.div<StyledAutoPayProps>`
  height: 400px;
  width: 100%;
  background: ${defaultTheme.colors.white};
  position: relative;
  padding: 32px;
  display: grid;
  grid-auto-rows: auto 1fr auto;

  ${StyledHeader} {
    @media (min-width: ${({ $isOn }) =>
      $isOn ? desktop1280 : desktop1100}px) {
      font-size: 44px;
      font-weight: 700;
    }
  }

  ${StyledHeader}, ${StyledText} {
    color: ${({ $isOn }) =>
      $isOn ? defaultTheme.colors.black : defaultTheme.colors.white};
  }

  ${StyledButton} {
    ${({ $isOn }) =>
      $isOn &&
      css`
        position: absolute;
        bottom: 32px;
        left: 32px;
      `}
`;

export const StyledBG = styled.div<StyledAutoPayBGProps>`
  ${({ urlWomanPayBg }): string => `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: 50% 50% url(${urlWomanPayBg}) no-repeat;
  `}
`;

export const StyledContent = styled.div`
  position: relative;
  color: ${defaultTheme.colors.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
  width: 200px;

  @media (min-width: ${desktop420}px) {
    width: 250px;
  }

  @media (min-width: ${desktop940}px) {
    width: 200px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 230px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 250px;
  }

  img {
    margin-right: 8px;
    position: relative;
    top: 4px;
  }
`;
