/** Библиотеки */
import { useRef, useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RemoveScroll } from 'react-remove-scroll';
import { observer } from 'mobx-react';
/** Компоненты */
import BrandLayout from './BrandLayout';
import ContentLayout from './ContentLayout';
import MenuPopup from './MenuPopup';
import { StyledHeader, StyledHeaderWrapper } from './style';

/** интерфейсы */
import { CityData } from '~/interfaces/CityData.interface';
import { HeaderProps, MenuStyles } from './Header.types';

/** константы */
import { MENU_OFFSET_SMALL, MENU_OFFSET_HIDDEN } from './constants';
import { desktop940 } from '~/components/Grid/constants';
import { CITY_DATA } from '~/constants/common';

/** api */
import { cookies } from '~/api/api';
import { useRootStore } from '~/stores/RootStore';

/**
 * Компонент заголовка страницы
 * @returns {React.FC}
 */
const Header: React.FC<HeaderProps> = ({ menu }: HeaderProps) => {
  const {
    authStore: { auth, isMenuOpen, setIsMenuOpen, isTemporaryTokenAuth },
  } = useRootStore();
  const cityData: CityData = cookies.get(CITY_DATA);
  /* Состояние списка городов */
  const [isCitiesOpen, setIsCitiesOpen] = useState<boolean>(false);
  // Показать блок подтверждения города
  const [isConfirmShow, setIsConfirmShow] = useState<boolean>(!cityData);

  const headerBody = useRef(null);
  const scroll = useRef(0);
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** удаляет или добавляет класс в зависимости он статуса */
  const classSwitcher = (el, className, status): void => {
    if (status) {
      el.classList.add(className);
    } else if (el.classList.contains(className)) {
      el.classList.remove(className);
    }
  };

  /**
   * Добавляет анимацию скрытия меню при прокрутке страницы
   */
  const windowScrollHandler = (): void => {
    // В связи с тем, что в листенере наблюдается некорректное поведение при работе со state из useState,
    // получим состояние меню из useRef. Это позволит корректно обработать ситуацию, когда пользователь
    // с открытым меню решил изменить размеры окна до мобильного и наоборот.
    const isOpenedMenu = headerBody.current.children[0]
      .getElementsByClassName('menu-popup')[0]
      .hasAttribute('open');

    if (headerBody.current === null) return;
    const newScroll = Number(window.pageYOffset);
    const smallClassName = isDesktop940
      ? MenuStyles.small
      : MenuStyles.smallMobile;
    /** верхнее положение меню */
    if (newScroll < MENU_OFFSET_SMALL && isDesktop940) {
      headerBody.current.style.top = `-${newScroll}px`;
      classSwitcher(headerBody.current, smallClassName, false);
      classSwitcher(headerBody.current, MenuStyles.hidden, false);
      /** переход от полного к уменьшенному */
    } else if (
      newScroll >= MENU_OFFSET_SMALL &&
      newScroll < MENU_OFFSET_HIDDEN
    ) {
      headerBody.current.style.top = '';
      classSwitcher(headerBody.current, MenuStyles.small, isDesktop940);
      /** сокрытие при прокрутке вниз */
    } else if (newScroll > scroll.current && newScroll > 0) {
      headerBody.current.style.top = '';
      classSwitcher(headerBody.current, smallClassName, false);
      classSwitcher(headerBody.current, MenuStyles.hidden, true);
      /* если открыто окно подтверждения города скроем его */
      if (isConfirmShow) setIsConfirmShow(false);
      /** показывает при прокрутке вверх */
    } else if (newScroll < scroll.current) {
      classSwitcher(headerBody.current, MenuStyles.hidden, false);
      classSwitcher(headerBody.current, smallClassName, true);
    }

    /** если меню открыто */
    if (isOpenedMenu) {
      headerBody.current.style.top = '';
      classSwitcher(headerBody.current, MenuStyles.hidden, isDesktop940);
      classSwitcher(headerBody.current, MenuStyles.smallMobile, false);
      classSwitcher(headerBody.current, MenuStyles.small, true);
    }

    /** сохраняет состояние предыдущей прокрутки */
    scroll.current = newScroll;
  };

  useEffect(() => {
    setIsConfirmShow((Boolean(!auth.cityId) && !cityData) ?? isConfirmShow);
  }, [auth]);

  useEffect(() => {
    classSwitcher(headerBody.current, MenuStyles.smallMobile, false);
    window.addEventListener('scroll', windowScrollHandler);
    return (): void => {
      window.removeEventListener('scroll', windowScrollHandler);
    };
  }, [isDesktop940]);

  useEffect(() => {
    if (headerBody.current === null) return;
    if (!isMenuOpen && !isDesktop940) {
      classSwitcher(headerBody.current, MenuStyles.hidden, false);
      classSwitcher(headerBody.current, MenuStyles.small, false);
    }
    windowScrollHandler();
  }, [isMenuOpen]);

  /**
   * Открывает/закрывает меню
   */
  const onToggleMenuVisible = (): void => {
    setIsMenuOpen(!isMenuOpen);
    if (isConfirmShow) setIsConfirmShow(false);
  };

  /** Открывает список городов */
  const onOpenCities = (): void => {
    setIsCitiesOpen(true);
  };

  /** Закрывает список городов и меню */
  const onCloseCities = (): void => {
    setIsCitiesOpen(false);
    onToggleMenuVisible();
  };

  /** Открывает/закрывает список городов */
  const onToggleCitiesOpen = useCallback((): void => {
    setIsCitiesOpen(!isCitiesOpen);
  }, [isCitiesOpen]);

  /** Закрывает блок подтверждения города */
  const onCloseCitiesConfirm = useCallback((): void => {
    setIsConfirmShow(false);
  }, [isConfirmShow]);

  return (
    <RemoveScroll removeScrollBar={false} enabled={isMenuOpen}>
      <StyledHeaderWrapper
        $isTemporaryTokenAuth={isTemporaryTokenAuth}
        ref={headerBody}
      >
        <div className="wrapper">
          <BrandLayout
            toggleHandler={onToggleMenuVisible}
            hamburgerOpen={isMenuOpen}
            openCitiesHandler={onOpenCities}
            isConfirmShow={isConfirmShow}
            closeConfirmHandler={onCloseCitiesConfirm}
            isCitiesOpen={isCitiesOpen}
            toggleCitiesOpen={onToggleCitiesOpen}
          />
          {/* <div className="header-padding" /> */}
          {isDesktop940 && (
            <StyledHeader>
              <ContentLayout
                toggleHandler={onToggleMenuVisible}
                hamburgerOpen={isMenuOpen}
                fastLinks={menu.fastLinks}
              />
            </StyledHeader>
          )}
          <MenuPopup
            toggleHandler={onToggleMenuVisible}
            open={isMenuOpen}
            openCitiesHandler={onOpenCities}
            closeCitiesHandler={onCloseCities}
            isCitiesOpen={isCitiesOpen}
            menu={menu}
          />
        </div>
      </StyledHeaderWrapper>
    </RemoveScroll>
  );
};

export default observer(Header);
