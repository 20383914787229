/** interfaces */
import { SocialDataProps } from './interfaces';
/** constants */
import { PLANETA_URL } from '~/constants/common';

const BASE_PATH = `${process.env.STATIC_SERVER}/static/images/chatlesExp`;

export default {
  coins: {
    desktop: {
      coin1000: {
        path: `${BASE_PATH}/coin1000.png`,
        width: 321,
        height: 325,
      },
      coin1500: {
        path: `${BASE_PATH}/coin1500.png`,
        width: 435,
        height: 317,
      },
      coin500: {
        path: `${BASE_PATH}/coin500.png`,
        width: 297,
        height: 250,
      },
      coinHeads: {
        path: `${BASE_PATH}/coinHeads.png`,
        width: 436,
        height: 373,
      },
    },
    mobile: {
      coin1000Mobile: {
        path: `${BASE_PATH}/coin1000_mobile.png`,
        width: 135,
        height: 136,
      },
      coin1500Mobile: {
        path: `${BASE_PATH}/coin1500_mobile.png`,
        width: 206,
        height: 184,
      },
      coinHeadsMobile: {
        path: `${BASE_PATH}/coinHeads_mobile.png`,
        width: 214,
        height: 206,
      },
    },
  },
  octagons: {
    octagon: {
      path: `${BASE_PATH}/octagon.png`,
      width: 651,
      height: 651,
    },
    octagonMobile: {
      path: `${BASE_PATH}/octagonMobile.png`,
      width: 424,
      height: 424,
    },
  },
} as const;

// Вознаграждение в зависимости от годового стажа
export const REWARD = {
  more10Years: 1500,
  from7to9Years: 1000,
  from4to6Years: 500,
  from1to3Years: 300,
  less1Year: 200,
};

export const AGREEMENT_LINK = '/documents';
// Дата начала розыгрыша
export const DRAW_DATE = '25 мая';

export enum ERRORS {
  AGREEMENT = 'Подтвердите согласие\u00A0с правилами и\u00A0соглашением',
  NOVICE = 'Вы\u00A0сможете принять участие в\u00A0розыгрыше, как только будет завершено подключение',
  STRAY = 'Обслуживание по\u00A0договору приостановлено, для возобновления свяжитесь по\u00A0телефону 8\u00A0800 200-56-71',
  COMMON = 'Номер договора не\u00A0обслуживается',
  EMPLOYEE = 'Клиент может участвовать в\u00A0акции',
}

/* Данные для шаринга в социальных сетях */
export const SOCIAL_DATA: SocialDataProps = {
  shareUrl: `${PLANETA_URL}/20years`,
  tag: '#Планета20лет',
  title: 'Получай бонусные рубли на счёт в Планете',
  imgUrl: `${PLANETA_URL}/static/images/20years/repost.png`,
};
