/** libraries */
import { SidePage } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import SoftlineUnsubscribeWizard from '~/components/Blocks/Templates/Softline/SoftlineUnsubsribeWizard';
import SoftlineUnsubscribeWizardFooter from '~/components/Blocks/Templates/Softline/SoftlineUnsubsribeWizard/SoftlineUnsubsribeWizardFooter';
import SoftlineUnsubscribeWizardSuccess from '~/components/Blocks/Templates/Softline/SoftlineUnsubsribeWizard/SoftlineUnsubsribeWizardSuccess';
/** store */
import useSoftlineStateModelStore from '~/components/Blocks/Templates/Softline/stores/useSoftlineStateStore';

/* Сайдпейдж отключения подписки Softline */
export const SoftlineUnsubscribeSP = (): JSX.Element => {
  const {
    softlineDescription,
    isOpenUnsubscribeWizard,
    isOpenUnsubscribeWizardSuccess,
    setIsOpenUnsubscribeWizard,
    setIsOpenUnsubscribeWizardSuccess,
  } = useSoftlineStateModelStore();
  return (
    <>
      <SidePage
        show={isOpenUnsubscribeWizard}
        headerText={
          softlineDescription &&
          `Отключение подписки ${softlineDescription.name}`
        }
        headerTitleText={
          softlineDescription && softlineDescription.nameFromMethod
        }
        onCloseClick={() => setIsOpenUnsubscribeWizard(false)}
        removeScrollBar
        showMobileHeader
        footerContainer={<SoftlineUnsubscribeWizardFooter />}
        isOnlyMobileFooter
      >
        <SoftlineUnsubscribeWizard />
      </SidePage>
      <SidePage
        show={isOpenUnsubscribeWizardSuccess}
        onCloseClick={() => setIsOpenUnsubscribeWizardSuccess(false)}
        removeScrollBar
        showMobileHeader
      >
        <SoftlineUnsubscribeWizardSuccess />
      </SidePage>
    </>
  );
};

export default observer(SoftlineUnsubscribeSP);
