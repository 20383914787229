/** libraries */
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  LeadingText,
  defaultTheme,
  Tag,
  ColorsType,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledNotificationCard } from './styles';
/** constants */
import { desktop800 } from '~/components/Grid/constants';

interface NotificationCardProps {
  color: ColorsType;
  header: ReactNode | string;
  /** Функция при нажатии на Скрыть/Показать */
  func?: (isOpen: boolean) => void;
  /** Флаг состояния открытия */
  isOpenMessage?: boolean;
  /** Отображение Скрыть/Показать */
  isHide?: boolean;
  /** Находится в карусели */
  isCarousel?: boolean;
  isSpecialOfferTag?: boolean;
  children?: JSX.Element;
}

const NotificationCard = ({
  color,
  header,
  func,
  isOpenMessage,
  isHide,
  isCarousel,
  isSpecialOfferTag,
  children,
}: NotificationCardProps): JSX.Element => {
  /** Высота children */
  const [height, setHeight] = useState(0);
  const childrenRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setHeight(childrenRef.current.children[0].clientHeight);
  }, [childrenRef.current]);

  const [isOpen, setIsOpen] = useState<boolean>(
    isOpenMessage !== undefined ? isOpenMessage : true,
  );

  // Вычисление ширины экрана
  const isDesktop800 = useMediaQuery({
    query: `(min-width: ${desktop800}px)`,
  });

  const changeOpenState = () => {
    if (func) {
      func(isOpen);
      return;
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpenMessage !== undefined) setIsOpen(isOpenMessage);
  }, [isOpenMessage]);

  return (
    <StyledNotificationCard
      color={color}
      isOpen={isOpen}
      isCarousel={isCarousel}
      height={height}
      isHide={isHide}
    >
      <div className="notification-card__header">
        <LeadingText color={defaultTheme.colors.black}>{header}</LeadingText>
        {isDesktop800 && isHide && (
          <Text
            className="notification-card__open-hide"
            color={defaultTheme.colors.planeta}
            onClick={changeOpenState}
            lineHeight="24px"
          >
            {isOpen ? 'Скрыть' : 'Показать'}
          </Text>
        )}
      </div>
      {isSpecialOfferTag && (
        <div className="notification-card__tag">
          <Tag
            color={defaultTheme.colors.planeta}
            colorTag={defaultTheme.colors.pink}
          >
            СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ
          </Tag>
        </div>
      )}
      {!isDesktop800 && isHide && (
        <Text
          className="notification-card__open-hide"
          color={defaultTheme.colors.planeta}
          onClick={changeOpenState}
          lineHeight="24px"
        >
          {isOpen ? 'Скрыть' : 'Показать'}
        </Text>
      )}
      <div ref={childrenRef} className="notification-card__children">
        {children}
      </div>
    </StyledNotificationCard>
  );
};

export default NotificationCard;
