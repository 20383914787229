import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop400 } from '~/components/Grid/constants';

export const StyledAppeal = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid ${defaultTheme.colors.disable};
  padding: 24px;
  cursor: pointer;

  .appeal {
    &__header {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &__number {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 4px 0;
      }

      > div[data-test-tag] {
        width: fit-content;
        line-height: 20px;
        > div {
          display: flex;
          padding: 1px 8px;
        }
      }
    }
    &__date {
      margin-bottom: 10px;
    }
    &__text {
      width: -webkit-fill-available;
      margin-bottom: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__comments {
      display: flex;
      flex-direction: row;
      align-items: center;

      > svg {
        margin-right: 4px;
      }
    }
  }

  @media (min-width: ${desktop400}px) {
    height: 164px;
    .appeal {
      &__header {
        flex-direction: row;
        &__number {
          margin: 0 8px 0 0;
        }
      }
    }
  }
`;
