/** libraries */
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** constants */
import { desktop430, desktop940 } from '~/components/Grid/constants';

export const ChannelPackageCardStyled = styled.div`
  position: relative;
  cursor: pointer;

  .channel-package-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 114px;
    min-width: 103.5px;
    border: 1px solid ${defaultTheme.colors.disable};
    padding: 11px 8px 10px;
    background: ${defaultTheme.colors.white};
    position: inherit;
    z-index: 1;

    &__definition {
      letter-spacing: -0.01em;
      margin-bottom: 4px;
    }

    &__name,
    &__name-highlighted {
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      font-weight: 500;
      min-height: 32px;
      &-highlighted {
        color: ${defaultTheme.colors.gray};
        & mark {
          color: ${defaultTheme.colors.black};
          background-color: transparent;
        }
      }
    }

    &__info-block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      margin-bottom: 2px;

      &__tag {
        margin-right: 0;
      }

      > span {
        letter-spacing: -0.01em;
      }

      &__wait-icon {
        display: flex;
        margin-right: 4px;
      }
    }

    &__block {
      display: grid;
      margin: 0 auto;
      height: initial;
    }

    &__block:nth-of-type(1) {
      min-height: 32px;
      margin-bottom: 3px;
    }

    &__channels-info {
      margin: auto;
      letter-spacing: -0.01em;
    }

    &__duplicate-wrapper {
      position: absolute;
      height: 114px;
      left: 4px;
      right: -4px;
      top: 4px;
      bottom: -4px;
      z-index: 0;
      background: ${defaultTheme.colors.white};
    }

    &__duplicate {
      position: relative;
      height: 114px;
      border-right: 1px solid ${defaultTheme.colors.line};
      border-bottom: 1px solid ${defaultTheme.colors.line};
    }

    &__duplicate:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: -1px;
      width: 4px;
      border-top: 1px solid ${defaultTheme.colors.line};
    }
    &__duplicate:after {
      content: ' ';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 4px;
      border-left: 1px solid ${defaultTheme.colors.line};
      border-top-width: 0;
    }
  }

  div[data-test-tag] {
    letter-spacing: -0.03em;
    cursor: default;
    line-height: 0;

    > div {
      padding: 1px 8px;
    }
  }

  @media (min-width: ${desktop430}px) {
    width: auto;
    .channel-package-card {
      &__duplicate-wrapper {
      }

      &__duplicate {
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .channel-package-card {
      justify-content: space-between;
      height: 140px;
      width: 282px;
      padding: 12px 12px 14px 16px;

      &__info-block {
        justify-content: flex-start;
        margin-bottom: 4px;
        &__tag {
          margin-right: 6px;
        }
      }

      &__name,
      &__name-highlighted {
        text-align: inherit;
        line-height: 24px;
        font-size: 16px;
        height: auto;
      }

      &__block {
        height: auto;
        margin: inherit;
      }

      &__block:nth-of-type(1) {
        height: auto;
        margin-bottom: 0;
      }

      &__channels-info {
        margin: inherit;
      }

      &__duplicate-wrapper {
        height: 140px;
        width: 282px;
        left: 8px;
        right: -8px;
        top: 8px;
        bottom: -8px;
      }

      &__duplicate {
        height: 140px;
        width: 282px;
      }

      &__duplicate:before {
        width: 8px;
      }
      &__duplicate:after {
        height: 8px;
      }
    }
  }
`;
