import styled from '@emotion/styled';

/** константы */
import { desktop609 } from '~/components/Grid/constants';

export const StyledCallbackWizardSuccess = styled.div`
  .callbackWizardSuccess__icon {
    margin-bottom: 39px;
  }

  @media (max-width: ${desktop609}px) {
    .callbackWizardSuccess__icon {
      margin-bottom: 54px;
      text-align: center;
    }

    h3 {
      margin-top: 30px;
    }
  }
`;
