/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** типы */
import { StyledAutoPayBannerBGProps } from './AutoPayBanner.types';

/** Константы */
import {
  desktop370,
  desktop940,
  desktop1100,
} from '~/components/Grid/constants';

export const StyledAutoPayBanner = styled.div`
  position: relative;
  min-height: 400px;
  padding: 0 30px;
  background: ${defaultTheme.colors.white};
  overflow: hidden;

  h3 {
    margin-top: 30px;
  }

  .AutoPayBanner__text {
    max-width: 190px;
    margin-top: -14px;
  }

  @media (min-width: ${desktop940}px) and (max-width: ${desktop1100}px),
    (max-width: ${desktop370}px) {
    h3 {
      margin-top: 25px;
    }

    .AutoPayBanner__text {
      position: relative;
      max-width: 180px;
      margin-top: -16px;
      letter-spacing: -0.03em;
    }
  }
`;

export const StyledBG = styled.div<StyledAutoPayBannerBGProps>`
  ${({ urlPlus, urlWomanPlus }): string => `
    position: absolute;
    top: 0;
    left: 0;
    width: 928px;
    height: 400px;
    background: url(${urlPlus}) left bottom no-repeat, url(${urlWomanPlus}) no-repeat;

    @media (min-width: ${desktop940}px) and (max-width: ${desktop1100}px), (max-width: ${desktop370}px) {
      left: -77px;
    }
  `}
`;
