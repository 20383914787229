/** libraries */
import { types } from 'mobx-state-tree';
/** interfaces */
import { ShowEmailProps } from '../interfaces';

const ShowEmailPropsModel = types.model({
  show: types.boolean,
  hasError: types.boolean,
});

export const EmailConfirmModel = types
  .model('EmailConfirm', {
    isShowEmailConfirm: ShowEmailPropsModel,
  })
  .actions((self) => ({
    setIsShowEmailConfirm: (isShow: ShowEmailProps) => {
      self.isShowEmailConfirm = isShow;
    },
  }));

export const EmailConfirmStoreInstance = EmailConfirmModel.create({
  isShowEmailConfirm: { show: false, hasError: false },
});
