import { Advantage } from '~/components/Blocks/Templates/Pab2c/MyTV/Components/Services/Wink/types';
import { Icons } from 'cordis-core-ui-planeta';

export const advantages: Advantage[] = [
  {
    title: `Смотрите на${'\u00A0'}любых устройствах. Учётная запись для 5${'\u00A0'}устройств`,
    icon: <Icons.MultiDeviceIcon />,
  },
  {
    title: `Оплачивайте фильмы и${'\u00A0'}подписки Wink с${'\u00A0'}лицевого счёта Планеты`,
    icon: <Icons.CardIcon />,
  },
  {
    title: `Управление просмотром в${'\u00A0'}Wink при заказе подписки`,
    icon: <Icons.PlayPauseIcon />,
  },
  {
    title: `Обмен фильмов бесплатно, даже если уже купили и${'\u00A0'}начали смотреть`,
    icon: <Icons.ExchangeIcon />,
  },
  {
    title: `Телевидение Планеты из${'\u00A0'}любой точки${'\u00A0'}РФ через сервис Wink`,
    icon: <Icons.TvIcon />,
  },
];

export const WINK_URL = 'https://wink.ru';

export enum WINK_ERROR_TYPE {
  ACCOUNT_ALREADY_EXIST = 'WinkAccountAlreadyExistsException',
  PHONE_ALREADY_EXIST = 'WinkPhoneNumberAlreadyExistsException',
}
