/* Libraries */
import { FC, memo } from 'react';
import Image from 'next/image';
/* Types */
import { CallToActionFixedPriceProps } from '~/components/Blocks/Templates/CallToAction/CallToAction.types';
/* Components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
import { Text, H3, defaultTheme } from 'cordis-core-ui-planeta';
import Wrapper, { StyledHeader } from './CallToActionFixedPrice.style';

/** Блок "Фиксируем цены на год" */
const CallToActionFixedPrice: FC<
  CallToActionFixedPriceProps & { annual: number }
> = ({
  dueDate,
  imgLink,
  discount,
  annual,
}: CallToActionFixedPriceProps & { annual: number }) => {
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <CallToActionRow
      title={
        <Text>
          Фиксируем цены на&nbsp;год<sup>*</sup>
        </Text>
      }
    >
      <Wrapper>
        <StyledHeader>
          <span>
            <H3
              color={defaultTheme.colors.disable}
            >{`${annual}\u0020\u20bd`}</H3>
            <H3 color={defaultTheme.colors.planeta}>
              &#8722;&nbsp;{`${discount}%`}
            </H3>
          </span>
          <Text color={defaultTheme.colors.shadow}>{dueDate}</Text>
        </StyledHeader>
        {imgLink && (
          <Image
            loader={imageLoader}
            src={`${process.env.STATIC_SERVER}/${imgLink}`}
            alt="Фиксируем цены на год"
            width={126}
            height={70}
            quality={100}
          />
        )}
      </Wrapper>
    </CallToActionRow>
  );
};

export default memo(CallToActionFixedPrice);
