import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованный компонент "Бренд-панели" */
export const StyledBrandLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 0 24px 0 24px;
  width: 100%;
`;

/** стилизованный компонент мобильной "Бренд-панели" */
export const StyledBrandLayoutMobile = styled.div`
  display: block;
`;

export const StyledMobileCityConfirm = styled.div`
  display: block;
  position: relative;
  padding: 16px 23px;
  text-align: center;
  background: #9a2358;

  .mobileCityConfirm {
    &__title {
      margin-bottom: 27px;
    }

    &__btn-close {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 23px;
      text-align: right;

      svg {
        stroke: ${defaultTheme.colors.white} !important;
        cursor: pointer;
      }
    }
  }

  button {
    margin-right: 18px;
    padding: 10px 32px;

    span {
      font-size: 16px;
    }
  }

  a {
    letter-spacing: -0.03em;
  }
`;

export const StyledMobilePanelsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
`;

export const StyledLogo = styled.img`
  width: auto;
  height: 38px;
  margin-right: 32px;
  cursor: pointer;
`;
