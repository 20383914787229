/* eslint-disable camelcase */
/** libraries */
import { FC } from 'react';
import {
  defaultTheme,
  Icons,
  Link,
  Snoska,
  Text,
} from 'cordis-core-ui-planeta';

/** utils */
import LinkWrapper from '~/components/LinkWrapper';
import Row from '../common/RowContent';

/** constants */
import { HELP_PLANETA_URL } from '~/constants/common';
import { observer } from 'mobx-react';

interface PlatformsProps {
  /** Ссылка на ЦТВ */
  itv_channel_link?: string;
  isWink: boolean;
}

/** Платформы каналов */
const Platforms: FC<PlatformsProps> = ({
  itv_channel_link,
  isWink,
}: PlatformsProps) => {
  /** TODO: Убран блок Wink
   * https://ctms.itmh.ru/browse/DEPIT2JS-260
   */
  // const { storeType } = useMyTvStore();
  // const isTelevision = storeType === STORE_TYPE.TELEVISION;
  return (
    <div className="channel-description-card__platforms">
      <Row title="Телеканал доступен на платформах:">
        <div className="channel-description-card__platforms__digital-tv">
          <Icons.DigitalTVIcon />
          <div className="channel-description-card__platforms__digital-tv__name-block">
            <Text color={defaultTheme.colors.totalBlack} fontWeightBold>
              Цифровое ТВ Планеты
            </Text>
            {itv_channel_link && (
              <LinkWrapper href={itv_channel_link}>
                <Snoska
                  className="channel-description-card__platforms__digital-tv__link"
                  color={defaultTheme.colors.planeta}
                >
                  Посмотреть программу канала
                </Snoska>
              </LinkWrapper>
            )}
          </div>
        </div>
        <div className="channel-description-card__platforms__block">
          <div className="channel-description-card__platforms__block__first">
            {/* TODO: Убран блок Wink */}
            {/* <div className="channel-description-card__platforms__wink">
              <Icons.WinkIcon />
              {!isWink && !isTelevision && (
                <Snoska color={defaultTheme.colors.gray}>
                  <Snoska
                    color={defaultTheme.colors.planeta}
                    onClick={() => setCardState(CardStates.WINK_SERVICE)}
                  >
                    Подключите
                  </Snoska>
                  развлекательный видеосервис
                </Snoska>
              )}
            </div> */}
            <div className="channel-description-card__platforms__smart-tv">
              <Text color={defaultTheme.colors.gray} fontWeightBold>
                SMART TV
              </Text>
            </div>
            <div className="channel-description-card__platforms__set-top-box">
              <Text color={defaultTheme.colors.gray} fontWeightBold>
                4K телеприставка
              </Text>
            </div>
            <div className="channel-description-card__platforms__android-tv">
              <Icons.AndroidTVIcon />
            </div>
          </div>
          <div className="channel-description-card__platforms__block__second">
            <div className="channel-description-card__platforms__apple-tv">
              <Icons.AppleTVIcon />
              <Snoska color={defaultTheme.colors.gray}>
                для просмотра потребуется установка
                <Link
                  href={`${HELP_PLANETA_URL}/cifrovoe-televidenie-planety-na-storonnix-media-pleerax/`}
                  color={defaultTheme.colors.planeta}
                >
                  стороннего приложения
                </Link>
              </Snoska>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default observer(Platforms);
