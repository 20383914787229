/** libraries */
import React, { FC } from 'react';
import { Button, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** style */
import { StyledFooter } from './style';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface NumberDeactivationFooterProps {
  disable: () => Promise<void>;
  isLoading: boolean;
}

const NumberDeactivationFooter: FC<NumberDeactivationFooterProps> = ({
  disable,
  isLoading,
}: NumberDeactivationFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  if (isTemporaryTokenAuth) {
    return (
      <StyledFooter>
        <Button disabled>Отключить</Button>
        <Text
          style={{ marginLeft: '10px' }}
          color={defaultTheme.colors.warning}
        >
          Действие доступно только клиенту
        </Text>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter>
      <Button onClick={disable} loading={isLoading}>
        Отключить
      </Button>
    </StyledFooter>
  );
};

export default observer(NumberDeactivationFooter);
