/** libraries */
import { FC } from 'react';
/** interfaces */
import { LeftSideProps } from '~/components/Blocks/Templates/Timeline/interfaces';
/** components */
import Wrapper, { Header } from './style';
import { defaultTheme, LeadingText } from 'cordis-core-ui-planeta';
import AudioPlayer from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/AudioPlayer';
import VideoPlayer from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/VideoPlayer';
import ImageViewer from '~/components/Blocks/Templates/Timeline/MiddleRow/Molecules/ImageViewer';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** Карточка слева */
const LeftSide: FC<LeftSideProps> = ({
  audio,
  video,
  hasImgZoom,
  url,
  width,
  wUnits,
  height,
  mainText,
  headerText,
}: LeftSideProps) => {
  return (
    <Wrapper>
      {url && (
        <ImageViewer
          url={url}
          width={width}
          height={height}
          wUnits={wUnits}
          hasImgZoom={hasImgZoom}
          headerText={headerText}
        />
      )}
      {headerText && (
        <Header as={LeadingText} color={defaultTheme.colors.black}>
          {headerText}
        </Header>
      )}
      {mainText && parseHtml(mainText)}
      {audio && <AudioPlayer audio={audio} />}
      {video && <VideoPlayer video={video} />}
    </Wrapper>
  );
};

export default LeftSide;
