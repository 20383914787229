/** библиотеки */
import { useEffect, useMemo, useState } from 'react';
import AliasFunction from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { format, isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';
/** интерфейсы */
import { ImagesSizes } from './types';
/** стили */
import { StyledDescriptionOfSoftlineSubscription } from './style';
/** константы */
import { DEFAULT_IMAGE } from '../constants';
import { TABS } from './constants';
import { DEFAULT_LK_LINK, OPERATING_STATE } from '~/constants/common';
import { desktop450 } from '~/components/Grid/constants';
/** утилиты */
import { formatNumber, isExternal, pluralizeAll } from '~/utils/utils';
import { HooksTyping } from '~/utils/typeScriptHelpers';
import LinkWrapper from '~/components/LinkWrapper';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  LinkButton,
  Loader,
  PriceTag,
  PriceTagBackgroundColor,
  Select,
  Switcher,
  Tabs,
  TabsStyleTypes,
  Text,
} from 'cordis-core-ui-planeta';
/** hooks */
import { useSoftline } from '../hooks/SoftlineHook';
import { useBindData } from '../hooks/BindDataHook';
/** api */
import { getShippingForSubscription } from '~/api/api';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useSoftlineStateModelStore from '../stores/useSoftlineStateStore';

/**
 * Сайдпейдж с описанием подписки
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 */
const DescriptionOfSoftlineSubscription = (): JSX.Element => {
  const {
    authStore: { setIsFirstAuth, isAuth },
    contractStateStore: { contractState },
    softlineStore: {
      softlineSubscriptions,
      shippingsForSubscriptions,
      setShippingsForSubscriptions,
    },
  } = useRootStore();
  const { isLoading, toggleVisible } = useMakeAuthStore();
  const {
    softlineDescription,
    isOpenUnsubscribeWizard,
    setIsOpenSubscribeWizard,
    setIsOpenUnsubscribeWizard,
    setIsOpenUnsubscribeWizardSuccess,
  } = useSoftlineStateModelStore();

  const {
    isAnnual,
    isPromo,
    tabs,
    activeTab,
    onChangeTab,
    selectDistributionOS,
    setSelectDistributionOS,
    distributionList,
    linkToInstallInstructions,
    login,
    password,
    activationKey,
    formattedPrice,
    formattedPricePerMonth,
  } = useSoftline();
  const {
    isActiveSubscription,
    isInsufficientFunds,
    isAvailable,
    backgroundColor,
    fontColor,
    subscriptionText,
    requiredMoneyToBind,
    isVacation,
    isClientBlock,
    isNotServed,
    isNovice,
    isLoadingHook,
  } = useBindData();

  const {
    image,
    name,
    description,
    pendingUnbindDate,
    trialInfo,
    banTrimDate,
    chatlingCashback,
  } = softlineDescription;

  /** Пользователь авторизован и подписка подключена */
  const isMySubscriptions = !!(isAuth && softlineDescription?.statusString);

  /** Loader информации о дистрибутивах */
  const [
    isLoadingShipping,
    setIsLoadingShipping,
  ]: HooksTyping<boolean> = useState<boolean>(false);

  /** Устанавливает данные о дистрибутивах */
  const setShipping = async () => {
    setIsLoadingShipping(true);
    try {
      const shippings = await Promise.all(
        softlineSubscriptions
          .filter((softline) => softline.simId)
          .map(async (softline) => {
            try {
              const shipping = await getShippingForSubscription(softline.simId);
              return shipping;
            } catch (e) {
              console.error('getShippingForSubscription', e);
              return null;
            }
          }),
      );
      setShippingsForSubscriptions(shippings);
      setIsLoadingShipping(false);
    } catch (e) {
      console.error('setShipping', e);
      setIsLoadingShipping(false);
    }
  };
  useEffect(() => {
    if (
      isMySubscriptions &&
      softlineSubscriptions &&
      !shippingsForSubscriptions?.length
    ) {
      setShipping();
    }
  }, [softlineSubscriptions]);

  // Вычисление ширины экрана
  const isMinDesktop450 = useMediaQuery({
    query: `(min-width: ${desktop450}px)`,
  });

  /** Ссылка на изображение */
  const imageSrc = useMemo(
    () =>
      isExternal(image)
        ? image ?? DEFAULT_IMAGE
        : `${process.env.STATIC_SERVER}${image || DEFAULT_IMAGE}`,
    [image],
  );

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Кнопка "Войти" */
  const loginClick = () => {
    toggleVisible();
    setIsFirstAuth(true);
  };

  /** Колонки описания из монго */
  const descriptionColumns = (): JSX.Element => {
    return (
      <div className="description__content-wrapper">
        {description?.descriptionColumns?.length > 0 &&
          description.descriptionColumns.map((desc) => {
            return (
              <div
                className="description__content-wrapper__block"
                key={desc.header}
              >
                <Text
                  className="description__content-wrapper__block__header"
                  lineHeight="24px"
                  fontWeightBold
                  dangerouslySetInnerHTML={{ __html: desc.header }}
                />
                <ul>
                  {desc.lines.map((line) => {
                    return (
                      <li
                        className="description__content-wrapper__block__li"
                        key={line}
                      >
                        <Text
                          lineHeight="24px"
                          dangerouslySetInnerHTML={{ __html: line }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </div>
    );
  };

  /** Дата отключения подписки в формате dd.MM.yyyy */
  const [unbindDate, setUnbindDate]: HooksTyping<string> = useState<string>(
    null,
  );

  /** Дата окончания промо-периода в формате dd.MM.yyyy */
  const [promoEndDate, setPromoEndDate]: HooksTyping<string> = useState<string>(
    null,
  );

  /** Дата, с которой возможно отключение подписки в формате dd.MM.yyyy */
  const [
    canBeCanceledDate,
    setCanBeCanceledDate,
  ]: HooksTyping<string> = useState<string>(null);

  useEffect(() => {
    setUnbindDate(
      pendingUnbindDate
        ? format(new Date(pendingUnbindDate), 'dd.MM.yyyy')
        : null,
    );

    setPromoEndDate(
      trialInfo?.trialEndDate
        ? format(new Date(trialInfo?.trialEndDate), 'dd.MM.yyyy')
        : null,
    );

    setCanBeCanceledDate(
      banTrimDate && !isBefore(parseISO(banTrimDate), new Date())
        ? format(new Date(banTrimDate), 'dd.MM.yyyy')
        : null,
    );
  }, []);

  // Флаг включённого флага подписки
  const isChecked =
    isActiveSubscription &&
    !isNotServed &&
    !isVacation &&
    !isClientBlock &&
    // Если пользователь передумает - галка вернётся на место
    !isOpenUnsubscribeWizard;

  const handlerSwitcher = () => {
    /** если пользователь уже отключил подписку */
    if (isChecked && pendingUnbindDate) {
      setIsOpenUnsubscribeWizardSuccess(true);
      return;
    }
    if (isChecked) setIsOpenUnsubscribeWizard(true);
  };

  // Масштабируемые размеры изображения
  const [imgSizes, setImgSizes] = useState<ImagesSizes>({
    newWidth: 0,
    newHeight: 0,
  });

  /** Установка размеров изображения */
  useEffect(() => {
    const newImg = new Image();
    newImg.src = imageSrc;
    newImg.onload = () => {
      setImgSizes({ newWidth: newImg.width, newHeight: newImg.height });
    };
  }, [imageSrc]);

  /** Ширина priceTag */
  const priceTagWidth = isMinDesktop450 ? '289px' : 'auto';

  if (isLoadingHook) return <Loader />;

  return (
    <StyledDescriptionOfSoftlineSubscription>
      {imageSrc && (
        <div className="description__image-wrapper">
          <AliasFunction
            loader={imageLoader}
            src={imageSrc}
            alt={name}
            width={imgSizes.newWidth}
            height={imgSizes.newHeight}
            quality={100}
          />
        </div>
      )}
      <div className="description__auth-price-block">
        <div className="description__price-block">
          <Text
            className="description__price-block__subscription-cost-text"
            lineHeight="24px"
          >
            Стоимость подписки {isAnnual ? 'за год' : ''}
          </Text>
          {isAnnual ? (
            <div className="description__price-block__price-tag">
              <PriceTag
                header=""
                value={`${formattedPrice} ₽`}
                backgroundColor={backgroundColor}
                fontColor={fontColor}
                subscription={subscriptionText}
                width={
                  formattedPrice.length < 6 && !chatlingCashback
                    ? priceTagWidth
                    : 'auto'
                }
                headerType={isMinDesktop450 ? 'H2' : 'H3'}
              />
            </div>
          ) : (
            <>
              <Tabs
                value={tabs}
                styleType={TabsStyleTypes.SECONDARY}
                onChange={onChangeTab}
                activeTabIndex={activeTab.index}
              />
              {isPromo ? (
                <div className="description__price-block__price-tag">
                  <PriceTag
                    header=""
                    value={`${formatNumber(trialInfo?.trialPrice)} ₽`}
                    subscription={`Промопериод${'\u000A'}${pluralizeAll(
                      trialInfo?.trialDays,
                      ['день', 'дня', 'дней'],
                    )}`}
                    backgroundColor={PriceTagBackgroundColor.OK}
                    width={priceTagWidth}
                    headerType={isMinDesktop450 ? 'H2' : 'H3'}
                  />
                  <Text
                    className="description__price-block__promo-price__caption"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    После промопериода —{' '}
                    {activeTab.value === TABS.DAY
                      ? `${formattedPrice} ₽ в день`
                      : `${formattedPricePerMonth} ₽ в месяц`}
                  </Text>
                </div>
              ) : (
                <>
                  {activeTab.value === TABS.DAY && (
                    <div className="description__price-block__price-tag">
                      <PriceTag
                        header=""
                        value={`${formattedPrice} ₽`}
                        backgroundColor={backgroundColor}
                        fontColor={fontColor}
                        subscription={subscriptionText}
                        width={
                          formattedPrice.length < 6 && !chatlingCashback
                            ? priceTagWidth
                            : 'auto'
                        }
                        headerType={isMinDesktop450 ? 'H2' : 'H3'}
                      />
                    </div>
                  )}
                  {activeTab.value === TABS.MONTH && (
                    <div className="description__price-block__price-tag">
                      <PriceTag
                        header=""
                        value={`${formattedPricePerMonth} ₽`}
                        backgroundColor={backgroundColor}
                        fontColor={fontColor}
                        subscription={subscriptionText}
                        width={
                          formattedPricePerMonth.length < 6 && !chatlingCashback
                            ? priceTagWidth
                            : 'auto'
                        }
                        headerType={isMinDesktop450 ? 'H2' : 'H3'}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {isMySubscriptions && (
          <>
            <Switcher
              checked={isChecked}
              onClick={handlerSwitcher}
              className="description__auth-price-block__switcher"
              disabled={
                isAnnual ||
                isNotServed ||
                isVacation ||
                isClientBlock ||
                !isActiveSubscription ||
                !!pendingUnbindDate
              }
            >
              {isNotServed ||
              isVacation ||
              isClientBlock ||
              !isActiveSubscription
                ? 'Подписка неактивна'
                : 'Подписка подключена'}
              {/* пользователь отключил подписку */}
              {unbindDate && !isAnnual && contractState === OPERATING_STATE.ON && (
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  будет отключена {unbindDate}
                </Text>
              )}
              {/* годовая подписка */}
              {isAnnual && unbindDate && contractState === OPERATING_STATE.ON && (
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Действует до {unbindDate}
                </Text>
              )}
              {/* промо подписка */}
              {isPromo &&
                !unbindDate &&
                promoEndDate &&
                contractState === OPERATING_STATE.ON && (
                  <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                    первое списание будет {promoEndDate}
                  </Text>
                )}
              {/* обычная подписка(не годовая и не промо) и пользователь её не отключал */}
              {!isAnnual &&
                !isPromo &&
                !unbindDate &&
                canBeCanceledDate &&
                contractState === OPERATING_STATE.ON && (
                  <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                    отключение возможно с {canBeCanceledDate}
                  </Text>
                )}
              {isNotServed && (
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Ваш договор не обслуживается
                </Text>
              )}
              {isVacation && (
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Вы подключили услугу «Каникулы»
                </Text>
              )}
              {isClientBlock && (
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Вы приостановили обслуживание договора
                </Text>
              )}
            </Switcher>
          </>
        )}
      </div>
      {isLoadingShipping ? (
        <div className="description__loader">
          <Loader small />
        </div>
      ) : (
        <>
          {isMySubscriptions &&
            !isNotServed &&
            !isVacation &&
            !isClientBlock &&
            isActiveSubscription && (
              <div className="description__distribution-information-block">
                {linkToInstallInstructions && (
                  <div className="description__distribution-information-block__link-to-instructions">
                    <LinkWrapper
                      target="_blank"
                      href={linkToInstallInstructions}
                    >
                      Инструкция по установке
                    </LinkWrapper>
                  </div>
                )}
                {login && (
                  <div className="description__distribution-information-block__info-wrapper">
                    <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                      Логин
                    </Text>
                    <H3>{login}</H3>
                  </div>
                )}
                {password && (
                  <div className="description__distribution-information-block__info-wrapper">
                    <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                      Пароль
                    </Text>
                    <H3>{password}</H3>
                  </div>
                )}
                {activationKey && (
                  <div className="description__distribution-information-block__info-wrapper">
                    <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                      Ключ активации
                    </Text>
                    <H3>{activationKey}</H3>
                  </div>
                )}
                {distributionList && (
                  <div className="description__distribution-information-block__os-wrapper">
                    <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                      ОС дистрибутива
                    </Text>
                    <div className="description__distribution-information-block__os-wrapper__upload">
                      <Select
                        onOptionClick={(option) =>
                          setSelectDistributionOS(option)
                        }
                        visibleOptionCount={4}
                        value={
                          selectDistributionOS ? selectDistributionOS.value : ''
                        }
                        data={distributionList}
                        disabled={distributionList.length === 1}
                      />
                      <div className="description__distribution-information-block__os-wrapper__upload__button">
                        <LinkWrapper href={selectDistributionOS.value}>
                          <Button>Скачать</Button>
                        </LinkWrapper>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          {!isMySubscriptions ? (
            <>
              {isAuth ? (
                <div className="description__button-block">
                  <Button
                    onClick={() => setIsOpenSubscribeWizard(true)}
                    disabled={!isAvailable}
                  >
                    Подключить
                  </Button>
                  {isNovice && (
                    <Text
                      className="description__button-block__text"
                      lineHeight="24px"
                    >
                      Оформление подписки будет доступно после завершения
                      подключения договора.
                    </Text>
                  )}
                  {isClientBlock && (
                    <Text
                      className="description__button-block__text"
                      lineHeight="24px"
                    >
                      Обслуживание договора приостановлено.
                      <br />
                      <span className="description__link-wrapper">
                        <LinkWrapper target="_blank" href={DEFAULT_LK_LINK}>
                          Возобновите обслуживание
                        </LinkWrapper>
                      </span>
                      , чтобы подключить подписку.
                    </Text>
                  )}
                  {isInsufficientFunds && (
                    <Text
                      className="description__button-block__text"
                      lineHeight="24px"
                    >
                      {isAnnual ? (
                        <>
                          <span className="description__link-wrapper">
                            <LinkWrapper target="_blank" href="payment">
                              Пополните баланс
                            </LinkWrapper>
                          </span>{' '}
                          на сумму не&nbsp;менее{' '}
                          {formatNumber(requiredMoneyToBind)}
                          &nbsp;₽, чтобы подключить подписку.
                        </>
                      ) : (
                        <>
                          Подключение возможно при&nbsp;балансе лицевого счёта,
                          равном стоимости подписки за&nbsp;
                          {isAnnual ? '365' : '30'}
                          &nbsp;дней.{' '}
                          <span className="description__link-wrapper">
                            <LinkWrapper target="_blank" href="payment">
                              Внесите
                            </LinkWrapper>
                          </span>{' '}
                          не&nbsp;менее {formatNumber(requiredMoneyToBind)}
                          &nbsp;₽.
                        </>
                      )}
                    </Text>
                  )}
                  {isVacation && (
                    <Text
                      className="description__button-block__text"
                      lineHeight="24px"
                    >
                      Подключена услуга &laquo;Каникулы&raquo;.
                      <br />
                      <span className="description__link-wrapper">
                        <LinkWrapper target="_blank" href={DEFAULT_LK_LINK}>
                          Отключите&nbsp;её
                        </LinkWrapper>
                      </span>
                      , чтобы подключить подписку.
                    </Text>
                  )}
                  {isNotServed && (
                    <Text
                      className="description__button-block__text"
                      lineHeight="24px"
                    >
                      Ваш договор не&nbsp;обслуживается. Обратитесь{' '}
                      <span className="description__link-wrapper">
                        <LinkWrapper target="_blank" href="contacts">
                          в&nbsp;офис обслуживания
                        </LinkWrapper>{' '}
                      </span>
                      или{' '}
                      <span className="description__link-wrapper">
                        <LinkWrapper target="_blank" href="contacts">
                          позвоните нам
                        </LinkWrapper>
                      </span>
                      , чтобы восстановить его.
                    </Text>
                  )}
                </div>
              ) : (
                <div className="description__button-block-not-auth">
                  <Button
                    onClick={loginClick}
                    styleType={ButtonStyleTypes.SECONDARY}
                    loading={isLoading}
                  >
                    Войти
                  </Button>
                  <Text
                    className="description__button-block-not-auth__text"
                    lineHeight="24px"
                  >
                    чтобы оформить подписку
                  </Text>
                </div>
              )}
            </>
          ) : (
            <div className="description__inactive-subscription">
              {isInsufficientFunds && !isActiveSubscription && (
                <div className="description__inactive-subscription__button-block">
                  <span className="description__link-wrapper">
                    <LinkWrapper target="_blank" href="payment">
                      <Button styleType={ButtonStyleTypes.SECONDARY}>
                        Пополнить баланс
                      </Button>
                    </LinkWrapper>
                  </span>
                  <Text
                    className="description__inactive-subscription__button-block__text"
                    lineHeight="24px"
                  >
                    для возобновления или{' '}
                    <LinkButton
                      onClick={() => setIsOpenUnsubscribeWizard(true)}
                    >
                      отключить подписку
                    </LinkButton>
                    .
                  </Text>
                </div>
              )}
              {isNotServed && (
                <Text lineHeight="24px">
                  Обратитесь{' '}
                  <span className="description__link-wrapper">
                    <LinkWrapper target="_blank" href="contacts">
                      в&nbsp;офис обслуживания &laquo;Планеты&raquo;
                    </LinkWrapper>
                  </span>{' '}
                  или{' '}
                  <span className="description__link-wrapper">
                    <LinkWrapper target="_blank" href="contacts">
                      позвоните нам
                    </LinkWrapper>
                  </span>
                  , чтобы восстановить обслуживание договора и&nbsp;активировать
                  подписку. Либо{' '}
                  <LinkButton onClick={() => setIsOpenUnsubscribeWizard(true)}>
                    отключите&nbsp;её
                  </LinkButton>
                  .
                </Text>
              )}
              {isVacation && (
                <div className="description__inactive-subscription__button-block">
                  <span className="description__link-wrapper">
                    <LinkWrapper target="_blank" href={DEFAULT_LK_LINK}>
                      <Button styleType={ButtonStyleTypes.SECONDARY}>
                        Отключить «Каникулы»
                      </Button>
                    </LinkWrapper>
                  </span>
                  <Text
                    className="description__inactive-subscription__button-block__text"
                    lineHeight="24px"
                  >
                    для возобновления или{' '}
                    <LinkButton
                      onClick={() => setIsOpenUnsubscribeWizard(true)}
                    >
                      отключить подписку
                    </LinkButton>
                    .
                  </Text>
                </div>
              )}
              {isClientBlock && (
                <div className="description__inactive-subscription__button-block">
                  <span className="description__link-wrapper">
                    <LinkWrapper target="_blank" href={DEFAULT_LK_LINK}>
                      <Button styleType={ButtonStyleTypes.SECONDARY}>
                        Возобновить обслуживание
                      </Button>
                    </LinkWrapper>
                  </span>
                  <Text
                    className="description__inactive-subscription__button-block__text"
                    lineHeight="24px"
                  >
                    для активации или{' '}
                    <LinkButton
                      onClick={() => setIsOpenUnsubscribeWizard(true)}
                    >
                      отключить подписку
                    </LinkButton>
                    .
                  </Text>
                </div>
              )}
            </div>
          )}
          {description?.descriptionText && (
            <Text className="description__text-block" lineHeight="24px">
              {parseHtml(description?.descriptionText)}
            </Text>
          )}
          {description?.descriptionColumns?.length > 0 && descriptionColumns()}
        </>
      )}
    </StyledDescriptionOfSoftlineSubscription>
  );
};

export default observer(DescriptionOfSoftlineSubscription);
