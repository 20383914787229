/** библиотеки */
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';

export const StyledMobileSBP = styled.div<{
  $isSoftKeyShowed: boolean;
  waitingPage: boolean;
  ignoredPage: boolean;
  isLoadingPossibility: boolean;
}>`
  ${({
    $isSoftKeyShowed,
    waitingPage,
    ignoredPage,
    isLoadingPossibility,
  }): SerializedStyles => {
    return css`
      ${$isSoftKeyShowed &&
      `
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
      `}
      ${!isLoadingPossibility &&
      (!waitingPage || ignoredPage) &&
      'margin-top: -29px;'}

      .mobile-sbp {
        &__info-text {
          margin-bottom: 24px;
        }

        &__button-open-app {
          width: 100%;
          margin-top: 24px;
        }

        &__selected-bank {
          display: flex;
          align-items: center;
          margin: 8px 0 16px;

          > span {
            margin-left: 17px;
          }
        }

        &__search {
          width: 100%;

          > span {
            width: 100%;
          }
        }

        &__list-of-banks {
          display: flex;
          flex-direction: column;
          margin: 14px 0 32px;

          > a {
            margin-bottom: 8px;
          }
          &__bank-name {
            margin-left: 8px;
            width: 100%;
          }
        }

        &__img-wrapper {
          width: 32px;
          height: 32px;
          position: relative;

          > div {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        &__not-found-text {
          display: inline;
          margin-top: 16px;

          div:first-of-type {
            display: inline;
          }
        }

        &__loader {
          display: flex;
          justify-content: center;
          margin-top: 156px;
        }
      }
    `;
  }};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
