import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { StyledMdxParagraph } from '~/components/Blocks/Shared/Shared.style';

export default styled.div`
  width: 758px;
  border-radius: 50px;
  background: ${defaultTheme.colors.white};
  padding: 32px 26px 32px 39px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 0;

  h1,
  h2,
  h3 {
    word-break: break-word;

    @media (min-width: ${desktop940}px) {
      word-break: normal;
    }
  }

  span {
    color: ${defaultTheme.colors.black};
  }

  ${StyledMdxParagraph} {
    z-index: 3;
  }

  @media (min-width: ${desktop940}px) {
    width: 830px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 1070px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 1131px;
  }
`;

export const StyledCircle = styled.div<{ isEnd: boolean }>`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  position: absolute;
  background: ${defaultTheme.colors.white};
  z-index: 1;

  ${({ isEnd }) =>
    isEnd
      ? css`
          top: -32px;
        `
      : css`
          bottom: -32px;
        `}
`;
