/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  LeadingText,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { TEXT } from '../../../constants';

/** interfaces */
import { ServiceCardFieldsProps } from './interfaces';
import { WinkConnectionStatus } from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';

/** styles */
import { ServiceCardStyled } from './styles';

/** stores */
import { useRootStore } from '~/stores/RootStore';

const ServiceWinkCard: FC<ServiceCardFieldsProps> = ({
  service,
  onClick,
}: ServiceCardFieldsProps) => {
  const { name } = service;

  const {
    tvStore: { winkInfo },
  } = useRootStore();

  /** Wink */
  const { accountStatus } = winkInfo;

  // Вычисление ширины экрана
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Компонент Tag */
  const customTag = (text) => {
    return (
      <Tag
        color={defaultTheme.colors.white}
        colorTag={defaultTheme.colors.green}
        backgroundColor={defaultTheme.colors.green}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = (): string | boolean => {
    switch (accountStatus) {
      case WinkConnectionStatus.activated:
        return TEXT.CONNECTED;
      default:
        return false;
    }
  };

  return (
    <ServiceCardStyled onClick={onClick}>
      <div className="service-card__block">
        {isMinDesktop940 && (
          <Snoska
            className="service-card__definition"
            color={defaultTheme.colors.shadow}
          >
            сервис
          </Snoska>
        )}
        <LeadingText
          className="service-card__name"
          color={defaultTheme.colors.totalBlack}
        >
          {name}
        </LeadingText>
      </div>
      <div className="service-card__info-block">
        {accountStatus === WinkConnectionStatus.activated && (
          <div className="service-card__info-block__tag">
            {customTag(connectionStatusText())}
          </div>
        )}

        {accountStatus !== WinkConnectionStatus.activated && (
          <Text className="service-card__info-block__price" lineHeight="16px">
            бесплатно
          </Text>
        )}
      </div>
    </ServiceCardStyled>
  );
};

export default observer(ServiceWinkCard);
