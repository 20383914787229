/** библиотеки */
import * as React from 'react';

/** компоненты библиотеки */
import { Link, LinkStyleTypes } from 'cordis-core-ui-planeta';

/** типы */
import { LegalInfo } from '../Legal.types';

/** утилиты */
import { pluralizeAll } from '~/utils/utils';

/** константы */
import { DOCUMENTS_LINK } from '~/constants/common';

interface VoiceCallMinutesTextProps {
  content: LegalInfo;
}

/**
 * Блок шаблона VoiceCallMinutesText
 * @param {VoiceCallMinutesTextProps} content
 * @returns {React.FC}
 */
const VoiceCallMinutesText: React.FC<VoiceCallMinutesTextProps> = ({
  content,
}: VoiceCallMinutesTextProps) => {
  const minutesCount = pluralizeAll(content.freeVoiceCallMinutes, [
    'минуты ',
    'минут ',
    'минут ',
  ]);
  return (
    <>
      {content.freeVoiceCallMinutes && (
        <p>
          Продукт включает пакет из {minutesCount}
          местных исходящих телефонных соединений в день. Доступ к пакету
          открывается ежедневно в 00 часов 00 минут. Остаток неиспользованных
          минут не переносится на следующий месяц. Стоимость минуты сверх пакета
          и тарификацию услуг голосовой связи на остальные направления см. в
          разделе «Моя Планета» или{' '}
          <Link
            styleType={LinkStyleTypes.SECONDARY}
            href={DOCUMENTS_LINK}
            target="_blank"
          >
            Прейскуранте
          </Link>
          .
        </p>
      )}
    </>
  );
};

export default VoiceCallMinutesText;
