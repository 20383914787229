/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  LinkButton,
  Snoska,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledSuspendedForNonPayment } from './style';
/** constants */
import { desktop400 } from '~/components/Grid/constants';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** interfaces */
import { AllowedTariffProps } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';
/** components */
import SuspensionPaymentsList from '../SuspensionPaymentsList/SuspensionPaymentsList';

/** Обслуживание приостановлено за неуплату */
const SuspendedForNonPayment = (): JSX.Element => {
  const {
    pab2cBalanceStore: { moneyRecommended, tariffDayTax },
    summaryDataStore: { seriesCode },
    allowedTariffStore: { allowedTariff },
  } = useRootStore();

  const tariff = useMemo(() => {
    return allowedTariff?.length
      ? ((allowedTariff as unknown) as AllowedTariffProps[]).find(
          (item) => item.tariffTo.seriesCode === seriesCode,
        )
      : null;
  }, [allowedTariff, seriesCode]);

  // Вычисление ширины экрана
  const isDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });

  return (
    <StyledSuspendedForNonPayment>
      <div className="payment__button-block">
        <LinkButton href="/payment">
          <Button>{`Пополнить ${isDesktop400 ? 'баланс' : ''}`}</Button>
        </LinkButton>
        <Text lineHeight="24px">
          Для возобновления рекомендуем пополнить счет на сумму не менее{' '}
          <Text lineHeight="24px" fontWeightBold>
            {moneyRecommended} ₽
          </Text>
        </Text>
      </div>
      {tariff && (
        <SuspensionPaymentsList linePriceOff={tariff.tariffTo.linePriceOff} />
      )}
      <Snoska color={defaultTheme.colors.shadow}>
        Обслуживание будет восстановлено в течение одного часа с момента
        поступления средств на лицевой счёт. Порог включения{' '}
        <Snoska color={defaultTheme.colors.shadow} fontWeightBold>
          {formatNumber(tariffDayTax)} ₽
        </Snoska>
        .
      </Snoska>
    </StyledSuspendedForNonPayment>
  );
};

export default observer(SuspendedForNonPayment);
