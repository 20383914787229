/** компоненты библиотеки */
import { H3, Text } from 'cordis-core-ui-planeta';

/** стили */
import { AgreementStyled } from './Agreement.style';

/** типы */
import { CustomAgreementContent } from './types';

/** Индивидуальное соглашение */
export const CustomAgreement = ({
  title,
  body,
}: CustomAgreementContent): JSX.Element => (
  <AgreementStyled>
    <H3 dangerouslySetInnerHTML={{ __html: title }} />
    <Text
      className="agreementPopup__text__description"
      dangerouslySetInnerHTML={{ __html: body }}
    />
  </AgreementStyled>
);
