/** библиотеки */
import * as React from 'react';
/** Компоненты */
import BannerCarousel from './BannerCarousel';
/** типы */
import * as BannerTypes from './Banner.types';

/**
 * Блок "Баннер"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=420318550
 * @param content
 */
const Banner: React.FC<BannerTypes.BannerContentProps> = ({
  content,
}: BannerTypes.BannerContentProps) => {
  return <BannerCarousel {...content} />;
};

export default React.memo(Banner);
