/** библиотеки */
import { FC } from 'react';

/** компоненты библиотеки */
import { H3, Text } from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledAutoPayBanner, StyledBG } from './AutoPayBanner.style';
import LinkWrapper from '~/components/LinkWrapper';

/** константы */
import { IMAGES } from '../constants';

/** типы */
import { AdvantagesContentProps } from '../Advantages.types';

const AutoPayBanner: FC<AdvantagesContentProps> = ({ content }) => {
  return (
    <LinkWrapper href={content.fields?.link}>
      <StyledAutoPayBanner>
        <StyledBG urlPlus={IMAGES.plus} urlWomanPlus={IMAGES.womanPlus} />
        <H3>Забудьте забывать!</H3>
        <Text className="AutoPayBanner__text">
          Автоплатёж — это бесплатный сервис для автоматической оплаты услуг
          Планеты
        </Text>
      </StyledAutoPayBanner>
    </LinkWrapper>
  );
};

export default AutoPayBanner;
